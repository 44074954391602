import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AbstractTableColumns } from "@app/components/shared/AgGridTable/columns/AbstractTableColumns";
import { transactionFieldsDict } from "@banks/configs/constants/transactionFieldsDict";
import dayjs from "dayjs";
import { getDateFormat } from "@banks/scripts/helpers";

export class TransactionTableColumns extends AbstractTableColumns {
    static get transactionRowNumberColumn(): Readonly<ColDef> {
        return {
            ...this.rowNumberColumn,
            pinned: "left",
            width: 75,
        };
    }

    static get bookingDate(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: transactionFieldsDict.bookingDate }),
            headerTooltip: this.intl?.formatMessage({ id: transactionFieldsDict.bookingDate }),
            field: "bookingDate",
            width: 120,
            sortable: false,
            filter: false,
            cellRenderer: ({ value }: ICellRendererParams) =>
                value ? dayjs(value, getDateFormat(value)).format("DD.MM.YYYY") : null,
        };
    }

    static get transactionType(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: transactionFieldsDict.transactionType }),
            headerTooltip: this.intl?.formatMessage({ id: transactionFieldsDict.transactionType }),
            field: "transactionType",
            width: 220,
            sortable: false,
            filter: false,
        };
    }

    static get currencyCode(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: transactionFieldsDict.currencyCode }),
            headerTooltip: this.intl?.formatMessage({ id: transactionFieldsDict.currencyCode }),
            field: "currencyCode",
            width: 100,
            sortable: false,
            filter: false,
        };
    }

    static get purpose(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: transactionFieldsDict.purpose }),
            headerTooltip: this.intl?.formatMessage({ id: transactionFieldsDict.purpose }),
            field: "purpose",
            width: 300,
            sortable: false,
            filter: false,
        };
    }

    static get counterpartyName(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: transactionFieldsDict.counterpartyName }),
            headerTooltip: this.intl?.formatMessage({ id: transactionFieldsDict.counterpartyName }),
            field: "counterpartyName",
            width: 500,
            sortable: false,
            filter: false,
        };
    }

    static get counterpartyIbanOrAccountNum(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: transactionFieldsDict.counterpartyIbanOrAccountNum }),
            headerTooltip: this.intl?.formatMessage({ id: transactionFieldsDict.counterpartyIbanOrAccountNum }),
            field: "counterpartyIbanOrAccountNum",
            width: 200,
            sortable: false,
            filter: false,
        };
    }

    static get counterpartyBicOrBlz(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: transactionFieldsDict.counterpartyBicOrBlz }),
            headerTooltip: this.intl?.formatMessage({ id: transactionFieldsDict.counterpartyBicOrBlz }),
            field: "counterpartyBicOrBlz",
            width: 150,
            sortable: false,
            filter: false,
        };
    }
}
