import React, { FC, useContext, useMemo, useState } from "react";
import { Checkbox, Drawer, Form, message, Row } from "antd";
import { useIntl } from "react-intl";
import { ButtonsBlock } from "@pdf-tools/components/ButtonsBlock";
import { UploadPdfFile } from "@pdf-tools/components/UploadPdfFile";
import { PdfToolsContext, PdfToolsControlContext } from "../../context";
import { OcrLoadedFile, SplitMode } from "@pdf-tools/types";
import { isFileEncrypted } from "@pdf-tools/context/helpers/helpersPdf";
import { UserContext } from "scripts/context/UserProvider";
import "./styles.css";
import { PdfToolPreview } from "@pdf-tools/components/PdfToolPreview";
import { PdfToolsEditor } from "@pdf-tools/components/PdfToolsEditor";

// TODO compress
//  Quant
//     http://ibezkrovnyi.github.io/image-quantization/index.html
//     https://github.com/leeoniya/RgbQuant.js/
//       https://github.com/pedrofracassi/badgemaker/blob/master/index.js
//  GPU
//     https://github.com/gpujs/gpu.js#table-of-contents
//  image manipulations
//     https://github.com/publiclab/image-sequencer/blob/440c3e0ad0ab081bdcc7dfff0d000f52a0902035/src/modules/Brightness/Module.js

export const PdfToolView: FC = () => {
    const user = useContext(UserContext);
    const { files, modelVersion, ignoreEncryption, pages, viewDocuments } = useContext(PdfToolsContext);
    const actions = useContext(PdfToolsControlContext);
    const [skipQRCheck, setSkipQRCheck] = useState(false);
    const [showDebug, setShowDebug] = useState(false);
    const [debugHTMLImages, setDebugHTMLImages] = useState("");
    const { formatMessage } = useIntl();

    const firstFile: OcrLoadedFile | null = useMemo(() => (files.length ? files[0] : null), [files]);

    const decodePdf = async (mode: SplitMode) => {
        await actions.decodePdf(
            {
                mode,
                skipQRCheck,
                userEmail: user.fireUser?.email,
            },
            files
        );
    };

    const loadNewFile = async (newFiles: File[]) => {
        for (const newFile of newFiles) {
            const isEncrypted = await isFileEncrypted(newFile);
            if (isEncrypted) {
                message.error(formatMessage({ id: "app.ocr.error.encrypted" }));
            } else {
                await actions.loadNewFile(
                    {
                        mode: SplitMode.manual,
                        skipQRCheck,
                        userEmail: user.fireUser?.email,
                    },
                    newFile
                );
            }
        }
    };

    const onClear = () => {
        setDebugHTMLImages("");
        actions.clearAllFiles();
    };

    const resultHTML = useMemo(() => files.map(file => file.resultHTML ?? "").join("<br />"), [files]);

    const handleExport = async () => {
        await actions.exportFiles();
    };

    return (
        <div className="pdf-tools-FullScreenPage">
            <Row gutter={24} style={{ height: "100%", flexFlow: "row" }}>
                {!firstFile && <UploadPdfFile />}
                {firstFile?.decoded ? (
                    <PdfToolsEditor
                        loadNewFile={loadNewFile}
                        setDebugHTMLImages={(html: string) => setDebugHTMLImages(debugHTMLImages + html)}
                    >
                        <ButtonsBlock
                            file={firstFile}
                            onDownloadClick={() => actions.handleDownload()}
                            onExport={() => handleExport()}
                            onClear={onClear}
                            setShowDebug={setShowDebug}
                        />
                    </PdfToolsEditor>
                ) : firstFile?.decoded === false ? (
                    <PdfToolPreview decodePdf={decodePdf}>
                        <ButtonsBlock
                            file={firstFile}
                            onDownloadClick={() => actions.handleDownload()}
                            onExport={() => handleExport()}
                            onClear={onClear}
                            setShowDebug={setShowDebug}
                        />
                    </PdfToolPreview>
                ) : null}
            </Row>

            <Drawer
                width={520}
                title="OCR options"
                placement="right"
                closable
                onClose={() => setShowDebug(false)}
                open={showDebug}
            >
                <Form>
                    <h2>
                        OCR options <sup>{modelVersion}</sup>
                    </h2>
                    <Form.Item>
                        <Checkbox checked={skipQRCheck} onChange={v => setSkipQRCheck(v.target.checked)}>
                            Skip QR Check
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Checkbox
                            checked={ignoreEncryption}
                            onChange={v => actions.setIgnoreEncryption(v.target.checked)}
                        >
                            Ignore File Encryption
                        </Checkbox>
                    </Form.Item>
                </Form>
                <div id="canvas_container"></div>
                {resultHTML && <div dangerouslySetInnerHTML={{ __html: resultHTML }}></div>}
                <div dangerouslySetInnerHTML={{ __html: debugHTMLImages }}></div>
            </Drawer>
        </div>
    );
};
