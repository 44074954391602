import { useCallback, useContext } from "react";
import RecordFormUtils from "@app/components/recordform/utils/RecordFormUtils";
import { RecordFormStateControlContext } from "@app/components/recordform/context/RecordFormState";
import { RecordFormPropsContext } from "@app/components/recordform/context/RecordFormPropsContext";
import { useFormValidators } from "@app/components/recordform/hooks/useFormValidators";

export const useFormHandlerDate = () => {
    const { refsHtml, refsData, periodBound } = useContext(RecordFormPropsContext);
    const { setRecordFieldErrorState } = useFormValidators();
    const { setRecordDate } = useContext(RecordFormStateControlContext);
    return useCallback(
        (date: Date) => {
            setRecordDate(RecordFormUtils.getFormDate(date, periodBound));
            refsData.dateTouchedRef.current = true;
            setRecordFieldErrorState(refsHtml.REF_rDATE, false);
        },
        [refsHtml, refsData, periodBound, setRecordFieldErrorState, setRecordDate]
    );
};
