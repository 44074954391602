import React from "react";
import { Layout, Progress } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import fontColorContrast from "font-color-contrast";

import Indicator from "./Indicator";
import ModuleMenu from "./ModuleMenu";
import { AppsDrawer } from "./AppsDrawer";
import { CompanyContext } from "scripts/context/CompanyContext";
import { ReadinessContext } from "scripts/context/DataReadyness";
import { CompanyDropdown } from "./CompanyDropdown";
import { LangDropdown } from "./LangDropdown";
import { LoginLogout } from "./LoginLogout";
import "./Header.css";

const Loader = React.memo(function Loader() {
    const { isReady, percent } = React.useContext(ReadinessContext);
    return (
        <div className={"Header--Loader " + (isReady ? "Header--Loader__hidden" : "")}>
            {percent > 0 ? <Progress type="circle" percent={percent} size={20} /> : <LoadingOutlined />}
        </div>
    );
});

const Header: React.FC = () => {
    const { companyGQL } = React.useContext(CompanyContext);
    const style = companyGQL?.color
        ? { backgroundColor: companyGQL.color, color: fontColorContrast(companyGQL.color) }
        : undefined;

    return (
        <Layout.Header style={style} className="Header">
            {style?.color && (
                <style>.Header .ant-menu-item-selected .ant-menu-title-content {`{color: ${style.color}`}</style>
            )}
            <div className="Header--Navigation">
                <AppsDrawer />
                <ModuleMenu />
            </div>
            <div className="Header--SelectorsGroup">
                <CompanyDropdown />
                <LangDropdown />
                <LoginLogout />
                <Indicator />
                <Loader />
            </div>
        </Layout.Header>
    );
};

export default React.memo(Header);
