import React, { FC, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useProgramSettings } from "../hooks/useProgramSettings";
import { RechnungInputBlock } from "@ui-components/RechnungInputBlock";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { useIsItemFieldDisabledOutsideModal } from "../hooks/useIsItemFieldDisabledOutsideModal";
import { useFormHandlerItemBF2 } from "../hooks/handlers/useFormHandlerItemBF2";

type Props = {
    isModalMode?: boolean;
};
export const FormItemBelegfeld2: FC<Props> = ({ isModalMode }) => {
    const isModalOnly = useIsItemFieldDisabledOutsideModal(isModalMode);
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { useBelegfeld2 } = useProgramSettings();
    const isDisabled = useFormIsRefDisabled();
    const { editableRecordItem, itemValidationStates } = useContext(RecordFormStateContext);
    const onBF2Change = useFormHandlerItemBF2();

    if (!useBelegfeld2) {
        return null;
    }

    const validation = itemValidationStates.get(refsHtml.REF_iBELEGFELD2);
    return (
        <RechnungInputBlock
            ref={refsHtml.REF_iBELEGFELD2}
            labelProps={{
                label: <FormattedMessage id="app.fields.internal_num" tagName="span" />,
                fieldError: validation ? { type: "error", message: " " } : undefined,
            }}
            className="InputBlock__ShortBlock"
            value={isModalOnly ? undefined : editableRecordItem.itemBelegfeld2}
            onChange={onBF2Change}
            disabled={isModalOnly || isDisabled(refsHtml.REF_iBELEGFELD2)}
        />
    );
};
