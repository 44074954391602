import App from "./App";
import React, { ComponentProps, type FC, useContext, useEffect } from "react";
import { DmsModalModule } from "./modules/DmsModalModule";
import { UploadFileModal } from "./modules/DocumentUploader";

import "./index.css";
import { DmsAppControlContext } from "@dms/types/ContextTypes";

export const DMS: FC = () => {
    const { resetAppContext } = useContext(DmsAppControlContext);

    useEffect(() => {
        return resetAppContext;
    }, [resetAppContext]);

    return <App />;
};

export const DmsModal: FC<ComponentProps<typeof DmsModalModule>> = props => {
    return <DmsModalModule {...props} />;
};

export const UploaderModal: FC<ComponentProps<typeof UploadFileModal>> = props => {
    return <UploadFileModal {...props}></UploadFileModal>;
};

export * from "./scripts/utils/DmsUtils";
