import React from "react";
import { FieldLabel, FieldLabelProps } from "./FieldLabel";
import { FormattedMessage } from "react-intl";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Input, InputProps, InputRef, Tooltip } from "antd";

interface RechnungInputBlockProps extends Omit<InputProps, "onChange"> {
    labelProps?: FieldLabelProps;
    onChange?: (value: string) => void;
}

export const RechnungInputBlock = React.forwardRef<InputRef, RechnungInputBlockProps>(function RechnungInputBlock(
    { labelProps, ...props },
    ref
) {
    return (
        <FieldLabel label={<InvoiceNumberLabel />} {...labelProps}>
            <InvoiceNumberInput ref={ref} {...props} />
        </FieldLabel>
    );
});

export const InvoiceNumberLabel: React.FC<{ hideLabel?: boolean }> = ({ hideLabel }) => {
    return (
        <span>
            {hideLabel ? null : <FormattedMessage id="app.fields.rechnung_num" />}
            <Tooltip
                placement="top"
                title={
                    <span>
                        <FormattedMessage id="app.dms.tooltipDocumentNumber" />: <br />
                        <code>
                            <FormattedMessage id="app.dms.letters" />: aA - zZ
                        </code>
                        <br />
                        <code>
                            <FormattedMessage id="app.dms.number" />: 0 - 9
                        </code>
                        <br />
                        <code>
                            <FormattedMessage id="app.dms.specialSymbols" />: - / $ & % +
                        </code>
                    </span>
                }
            >
                <InfoCircleOutlined />
            </Tooltip>
        </span>
    );
};

interface InvoiceNumberInputProps extends Omit<InputProps, "onChange"> {
    onChange?: (value: string) => void;
}

export const InvoiceNumberInput = React.forwardRef<InputRef, InvoiceNumberInputProps>(function InvoiceNumberInput(
    { ...props },
    ref
) {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value || "";
        value = value.replace(/[^a-zA-Z0-9\-/$&%+]/g, "");
        if (props.onChange) {
            props.onChange(value);
        }
    };
    return (
        <Input
            showCount
            ref={ref}
            {...props}
            maxLength={36}
            onChange={handleOnChange}
            onFocus={e => e.target.select()}
        />
    );
});
