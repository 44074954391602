import React, { useContext, useEffect, useMemo, useState } from "react";
import { AutoComplete, Button, Form, FormInstance, Input, Modal, Select, Space, Spin } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { GQL, KontoNumUtils } from "@binale-tech/shared";

import Category from "scripts/models/Category";
import { CloseOutlined } from "@ant-design/icons";
import { CompanyContext, CompanyControlContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { useIsMounted } from "../../../scripts/infrastructure/hooks";
import { validation } from "scripts/infrastructure/helpers/validation";

type KBProps = {
    entity: GQL.ICompanyKasse | null;
    onHide: () => void;
    show: boolean;
    disabled: boolean;
};

type IKassenbuchForm = Pick<GQL.ICompanyKasse, "name"> & {
    accountNum: string;
} & Partial<Pick<GQL.ICompanyCreateKbInput, "yearFrom" | "idProposal">>;

export const KassenbuchFormModal: React.FC<KBProps> = ({ entity, show, disabled, onHide }) => {
    const [form] = Form.useForm<IKassenbuchForm>();
    const { onKBCreate, onKBUpdate } = useContext(CompanyControlContext);
    const { companyGQL, yearConfig } = useContext(CompanyContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const skr = yearConfig?.skr;
    const { year } = useContext(YearPeriodContext);
    const [loading, setLoading] = useState(false);
    const isEditing = Boolean(entity);

    useEffect(() => {
        if (entity && show) {
            form.setFieldsValue({
                name: entity.name,
                accountNum: String(entity.accountNum),
            });
        } else {
            form.resetFields();
        }
    }, [kontoExt, form, entity, show]);

    const handleSubmit = async (values: IKassenbuchForm) => {
        setLoading(true);
        if (entity) {
            await onKBUpdate({
                name: values.name,
                id: entity.id,
                num: String(entity.accountNum),
            });
        } else {
            await onKBCreate({
                name: values.name,
                accountNum: Number(values.accountNum),
                yearFrom: Number(values.yearFrom),
                idProposal: values.idProposal,
            });
        }
        onHide();
        setLoading(false);
    };

    const [min, max] =
        skr === 4
            ? [KontoNumUtils.KASSE_4_MIN, KontoNumUtils.KASSE_4_MAX]
            : [KontoNumUtils.KASSE_3_MIN, KontoNumUtils.KASSE_3_MAX];

    const uniqueNums = companyGQL.kasseList
        .filter(v => v.year === year)
        .map(({ accountNum }) => accountNum)
        .map(String);
    const nextYearEntities = React.useMemo(
        () =>
            companyGQL.kasseList
                .filter(v => v.year === year + 1)
                .map(({ name, accountNum, id }) => ({ name, accountNum, id })),
        [companyGQL.kasseList, year]
    );

    return (
        <Modal
            onCancel={onHide}
            style={{ top: 20 }}
            title={isEditing ? `${year} - ${entity.name}` : <FormattedMessage id="app.titles.KB" />}
            open={show}
            destroyOnClose
            okText={<FormattedMessage id="app.button.save" />}
            okButtonProps={{ htmlType: "submit" }}
            onOk={form.submit}
            confirmLoading={loading}
        >
            <Spin spinning={loading}>
                <Form onFinish={handleSubmit} form={form} layout="vertical">
                    <CommonFields
                        disabled={disabled}
                        defaultRange={[min, max]}
                        uniqueNums={uniqueNums}
                        isEditing={isEditing}
                        activeNum={entity?.accountNum}
                        form={form}
                        nextYearEntities={nextYearEntities}
                    />
                </Form>
            </Spin>
        </Modal>
    );
};

type BankProps = {
    entity: GQL.ICompanyBank | null;
    onHide: () => void;
    show: boolean;
    disabled: boolean;
};

type IBankForm = Pick<GQL.ICompanyBank, "name"> & {
    accountNum: string;
} & Partial<Pick<GQL.ICompanyCreateBankInput, "yearFrom" | "idProposal">>;
export const BankFormModal: React.FC<BankProps> = ({ disabled, show, entity, onHide }) => {
    const [form] = Form.useForm<IBankForm>();
    const { onBankCreate, onBankUpdate } = useContext(CompanyControlContext);
    const { companyGQL, yearConfig } = useContext(CompanyContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const skr = yearConfig?.skr;

    const { year } = useContext(YearPeriodContext);
    const [loading, setLoading] = useState(false);
    const isEditing = Boolean(entity);
    const isMounted = useIsMounted();

    useEffect(() => {
        if (entity && show) {
            form.setFieldsValue({
                ...entity,
                accountNum: String(entity.accountNum),
            });
        } else if (isMounted()) {
            form.resetFields();
        }
    }, [form, entity, show, kontoExt, isMounted]);

    const handleSubmit = async (values: IBankForm) => {
        setLoading(true);
        const { name, accountNum, yearFrom } = values;
        if (entity) {
            await onBankUpdate({
                name,
                id: entity.id,
                num: String(entity.accountNum),
            });
        } else {
            await onBankCreate({
                name,
                accountNum: Number(accountNum),
                yearFrom: Number(yearFrom),
                idProposal: values.idProposal,
            });
        }
        onHide();
        setLoading(false);
    };

    const [min, max] =
        skr === 4
            ? [KontoNumUtils.BANK_4_MIN, KontoNumUtils.BANK_4_MAX]
            : [KontoNumUtils.BANK_3_MIN, KontoNumUtils.BANK_3_MAX];

    const uniqueNums = companyGQL.bankList
        .filter(v => v.year === year)
        .map(({ accountNum }) => accountNum)
        .map(String);
    const nextYearEntities = useMemo(
        () =>
            companyGQL.bankList
                .filter(v => v.year === year + 1)
                .map(({ name, accountNum, id }) => ({ name, accountNum, id })),
        [companyGQL.bankList, year]
    );

    return (
        <Modal
            onCancel={onHide}
            style={{ top: 20 }}
            title={isEditing ? `${year} - ${entity.name}` : <FormattedMessage id="app.titles.Bank" />}
            open={show}
            destroyOnClose
            okText={<FormattedMessage id="app.button.save" />}
            okButtonProps={{ htmlType: "submit" }}
            onOk={form.submit}
            confirmLoading={loading}
        >
            <Spin spinning={loading}>
                <Form onFinish={handleSubmit} form={form} layout="vertical">
                    <CommonFields
                        disabled={disabled}
                        defaultRange={[min, max]}
                        uniqueNums={uniqueNums}
                        isEditing={isEditing}
                        activeNum={entity?.accountNum}
                        form={form}
                        nextYearEntities={nextYearEntities}
                    />
                </Form>
            </Spin>
        </Modal>
    );
};

type NNFProps = {
    disabled: boolean;
    isEditing: boolean;
    uniqueNums: string[];
    activeNum: number;
    defaultRange: [number, number];
    form: FormInstance;
    nextYearEntities: { accountNum: number; name: string; id: string }[];
};

const CommonFields: React.FC<NNFProps> = ({
    disabled,
    isEditing,
    uniqueNums,
    activeNum,
    defaultRange,
    form,
    nextYearEntities,
}) => {
    const intl = useIntl();
    const { companyGQL, yearConfig } = useContext(CompanyContext);
    const { year } = useContext(YearPeriodContext);
    const yearFrom = Form.useWatch("yearFrom", form);
    const idProposal = Form.useWatch("idProposal", form);
    const accountNum = Form.useWatch("accountNum", form);
    useEffect(() => {
        form.setFieldValue("yearFrom", year);
    }, [form, year]);
    useEffect(() => {
        form.validateFields(["accountNum"]);
    }, [form, yearFrom]);
    const kontoExt = isEditing
        ? yearConfig.kontoExt
        : (companyGQL.accountingConfigs.find(v => v.year === yearFrom)?.kontoExt ?? 0);

    uniqueNums = uniqueNums.map(num => KontoNumUtils.getExtNum(num, kontoExt, Category.DefaultLen));
    const options: { label: string; value: string; name: string; id: string }[] = useMemo(
        () =>
            nextYearEntities
                .filter(v => !uniqueNums.includes(String(v.accountNum)))
                .map(v => {
                    const thisYearNum =
                        String(v.accountNum).substring(0, 4) + String(v.accountNum).substring(4, 4 + kontoExt);
                    return {
                        value: thisYearNum,
                        label: String(v.accountNum) + " " + v.name + ` (${year + 1}: ${v.accountNum})`,
                        name: v.name,
                        id: v.id,
                    };
                }),
        [kontoExt, nextYearEntities, year]
    );

    const getExtKonto = (konto: number) => Number(String(konto) + "0".repeat(kontoExt));
    const numLen = Category.DefaultLen + kontoExt;

    const [min, max] = [getExtKonto(defaultRange[0]), getExtKonto(defaultRange[1])];
    const years = companyGQL?.accountingYears || [];
    console.log({ idProposal, yearFrom, accountNum });

    return (
        <>
            {!isEditing && (
                <Form.Item
                    label={"Seit (jahr)"}
                    name="yearFrom"
                    rules={[
                        { required: true, message: intl.formatMessage({ id: "app.validation.error.field_empty" }) },
                    ]}
                >
                    <Select disabled={disabled} options={years.map(y => ({ label: y, value: y }))} />
                </Form.Item>
            )}
            <div style={{ opacity: yearFrom || isEditing ? 1 : 0 }}>
                <Form.Item
                    label={<FormattedMessage id="app.fields.konto" />}
                    name="accountNum"
                    rules={[
                        ...validation.commonNumber({ intl, min: numLen, max: numLen }),
                        validation.required(intl),
                        validation.unique(uniqueNums, activeNum ? String(activeNum) : undefined),
                        {
                            message: `should be in [${min}, ${max}]`,
                            validator: (rule, value) => {
                                value = value || "";
                                if (value.length === numLen && /^\d+$/.test(value)) {
                                    const num = Number(value);
                                    if (num < min || num > max) {
                                        return Promise.reject("not in range");
                                    }
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Space.Compact block>
                        <AutoComplete
                            value={accountNum}
                            options={options}
                            disabled={disabled || isEditing || idProposal}
                            maxLength={numLen}
                            onSelect={(value, option) => {
                                form.setFieldValue("accountNum", option.value);
                                form.setFieldValue("name", option.name);
                                form.setFieldValue("idProposal", option.id);
                                form.validateFields(["accountNum"]);
                            }}
                        >
                            <Input type="number" />
                        </AutoComplete>
                        <Button
                            icon={<CloseOutlined />}
                            disabled={!idProposal}
                            onClick={() => {
                                form.setFieldValue("accountNum", "");
                                form.setFieldValue("name", "");
                                form.setFieldValue("idProposal", undefined);
                            }}
                        />
                    </Space.Compact>
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="app.fields.name" />}
                    name="name"
                    rules={[
                        { max: 100, message: "too long" },
                        { required: true, message: intl.formatMessage({ id: "app.validation.error.field_empty" }) },
                    ]}
                >
                    <Input disabled={disabled || idProposal} maxLength={100} />
                </Form.Item>
                <Form.Item label={"ID"} name="idProposal" hidden={!idProposal}>
                    <Input disabled maxLength={100} />
                </Form.Item>
            </div>
        </>
    );
};
