import React from "react";
import { Typography } from "antd";

import logo from "../../assets/binale_logo.svg";

export const LogoHeader: React.FC = () => (
    <div style={{ display: "flex", justifyContent: "center", margin: "16px 0", alignItems: "center" }}>
        <img src={logo} alt="logotype" style={{ width: 30 }} />
        <Typography.Title level={3} style={{ marginBottom: 0, marginLeft: 10 }}>
            Binale
        </Typography.Title>
    </div>
);
