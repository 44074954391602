import { DmsType, MovementType } from "@dms/types";

const TRANSLATION_KEY_SET_1 = {
    [MovementType.RECEIPT]: "app.fields.ertrag",
    [MovementType.SPENDING]: "app.fields.aufwand",
};

const TRANSLATION_KEY_SET_2 = {
    [MovementType.RECEIPT]: "app.fields.einnahme",
    [MovementType.SPENDING]: "app.fields.ausgabe",
};

export const movementTypeTranslationKey: Record<DmsType, typeof TRANSLATION_KEY_SET_1 | typeof TRANSLATION_KEY_SET_2> =
    {
        [DmsType.Deb]: TRANSLATION_KEY_SET_1,
        [DmsType.ER]: TRANSLATION_KEY_SET_1,
        [DmsType.FE]: TRANSLATION_KEY_SET_1,
        [DmsType.taxes]: TRANSLATION_KEY_SET_1,
        [DmsType.LA]: TRANSLATION_KEY_SET_1,
        [DmsType.company]: TRANSLATION_KEY_SET_1,
        [DmsType.contracts]: TRANSLATION_KEY_SET_1,

        [DmsType.Bank]: TRANSLATION_KEY_SET_2,
        [DmsType.KB]: TRANSLATION_KEY_SET_2,
        [DmsType.POS]: TRANSLATION_KEY_SET_2,

        [DmsType.new_documents]: TRANSLATION_KEY_SET_1,
        [DmsType.uncategorized]: TRANSLATION_KEY_SET_1,
        [DmsType.trash]: TRANSLATION_KEY_SET_1,
    };
