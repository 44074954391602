import { Input, type InputProps } from "antd";
import React, { FC, useEffect, useState } from "react";

import { decimalFormatter, numberInputBlocker, parseNumber } from "@dms/scripts/helpers";

interface IProps extends Omit<InputProps, "placeholder" | "onChange" | "disabled" | "type"> {
    placeholder?: string;
    disabled?: boolean;
    type: "integer" | "float";
    suffix?: string;
    onChange?: (val?: number | null) => void;
    value?: number;
}

export const IntInput: FC<IProps> = ({ placeholder, disabled, type, value, onChange, suffix, ...rest }) => {
    const [inputValue, setInputValue] = useState("");
    const [placeholderText, setPlaceholderText] = useState(placeholder);

    useEffect(() => {
        if (value === undefined) {
            return setInputValue("");
        }

        setInputValue(decimalFormatter(value));
    }, [type, value]);

    const sendBack = () => {
        if (onChange) {
            if (inputValue === "") {
                return onChange(undefined);
            }

            onChange(parseNumber(inputValue));
        }
    };

    useEffect(() => {
        if (placeholder === "Diverse" && !disabled && onChange) {
            onChange(null);
            setPlaceholderText(undefined);
        }
    }, [disabled, placeholder]);

    return (
        <Input
            {...rest}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onBlur={sendBack}
            onKeyDown={e => numberInputBlocker(e, true)}
            placeholder={placeholderText}
            disabled={disabled}
            suffix={suffix ?? ""}
        />
    );
};
