import React from "react";

import GenericTableViewCtxWrapper from "../productSharedComponents/GenericTableViewCtxWrapper";
import { GenericTableViewState } from "../productSharedComponents/GenericTableView";
import { KassenbuchViewClass } from "../productKB/KBView";
import { BankProvider } from "@app/views/productBank/BankProvider";

class BankViewClass extends KassenbuchViewClass<GenericTableViewState> {}

export const BankView: React.FC = () => {
    return (
        <BankProvider>
            <GenericTableViewCtxWrapper Component={BankViewClass} />
        </BankProvider>
    );
};
