import React, { FC, MouseEvent } from "react";
import cx from "classnames";
import { Button, Divider, Tooltip } from "antd";
import { EyeInvisibleOutlined, PlusOutlined } from "@ant-design/icons";

interface IProps {
    tooltip: string;
    unifyUp?: boolean;
    unifyDown?: boolean;
    unifyDocs: () => void;
}

export const InvisibleDoc: FC<IProps> = ({ tooltip, unifyDown, unifyUp, unifyDocs }) => {
    return (
        <div className="pdf-tools-Doc_hidden">
            <Tooltip placement="top" title={`Hidden documents: ${tooltip}`}>
                <Divider className="pdf-tools-Page_Divider_hidden">
                    <EyeInvisibleOutlined />
                </Divider>
            </Tooltip>
            <Button
                icon={<PlusOutlined />}
                shape="circle"
                type="primary"
                className={cx("pdf-tools-image__unify", {
                    "pdf-tools-image__unify--multiple": unifyDown || unifyUp,
                    "pdf-tools-image__unify--bottom": unifyDown,
                    "pdf-tools-image__unify--up": unifyUp,
                    "pdf-tools-image__unify--both": unifyUp && unifyDown,
                })}
                onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                    unifyDocs();
                }}
            />
        </div>
    );
};
