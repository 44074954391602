import { createContext, type Key } from "react";
import { DmsRecordsData } from "@dms/hooks/useDmsRecordsData";
import { IDocumentEnriched, IDocumentType, ITableDocument, TTableCols } from "@dms/types/Interfaces";
import { TBindSubType, TFilterConfigItem } from "@dms/types/Types";
import { SubTypeActions } from "@dms/types/Enums";
import { GenericRecord } from "../../scripts/models";

interface IDocumentsCtx {
    documentsKV: Record<string, IDocumentEnriched>;
    documents: IDocumentEnriched[];
    documentTypes: IDocumentType[];
    dmsRecordsData: DmsRecordsData;
    fetchTypes: () => Promise<void>;
}

export const DmsDataContext = createContext<IDocumentsCtx>({
    documentsKV: {},
    documents: [],
    documentTypes: [],
    dmsRecordsData: {
        recordsAssetsSet: new Set(),
        recordSnapshots: {},
    },
    fetchTypes: () => Promise.reject(),
});

interface IUserSettingsCtx {
    isTableSize: boolean;
    userConfig: {
        filterConfig: Map<string, TFilterConfigItem>;
        columnsTableConfig: Map<string, TTableCols[]>;
    };
    setFilterConfig: (typeKey: string[], configData: TFilterConfigItem) => void;
    setColumnsTableConfig: (typeKey: string[], configTableData: TTableCols[]) => void;
    deleteTypeConfig: (typeKey: string[]) => void;
}

/**
 Context for User config state
 */
export const DmsUserSettingsContext = createContext<IUserSettingsCtx>({
    isTableSize: false,
    userConfig: { filterConfig: new Map(), columnsTableConfig: new Map() },
    setFilterConfig: () => {},
    setColumnsTableConfig: () => {},
    deleteTypeConfig: () => {},
});

interface IDmsAppCtx {
    activeType: string[];
    view: "list" | "grid";
    isTypeChangeMode: boolean;
    selectedRowKeys: Key[];
    isDeleteMode: boolean;
    dragID: string;
    isDraggingRightNow: boolean;
    downloadDocumentsModalOpen: boolean;
    openedMenuItemKeys: string[];
    isOpenSettingsCol: boolean;
    searchValue: string;
    focusedRow: string;
    folderDocuments: ITableDocument[];
    bindModalData: { isOpen: boolean; id?: string; type?: TBindSubType; currentVal?: string };
    editTypeModalData: {
        isOpen: boolean;
        currentVal?: string;
        action?: SubTypeActions;
        typeKeyPath?: string[];
    };
    loadDataSource: boolean;
    templates: GenericRecord[];
}

export const DmsAppContext = createContext<IDmsAppCtx>({
    activeType: [],
    view: "list",
    isTypeChangeMode: false,
    selectedRowKeys: [],
    isDeleteMode: false,
    dragID: undefined,
    isDraggingRightNow: false,
    downloadDocumentsModalOpen: false,
    openedMenuItemKeys: [],
    isOpenSettingsCol: false,
    searchValue: "",
    focusedRow: null,
    folderDocuments: [],
    bindModalData: undefined,
    editTypeModalData: undefined,
    loadDataSource: false,
    templates: [],
});

interface IDmsAppControlCtx {
    selectRow: (key: Key) => void;
    selectRows: (keys: Key[]) => void;
    unselectRow: (keys: Key) => void;
    resetSelectedRows: () => void;
    setActiveType: (type: string[]) => void;
    resetAppContext: () => void;
    viewToggle: (arg: IDmsAppCtx["view"]) => void;
    typeChangeModeToggle: (arg: boolean) => void;
    deleteModeToggle: (arg: boolean) => void;
    setDragID: (arg: string) => void;
    toggleDraggingState: (arg: boolean) => void;
    toggleDownloadDocumentsModal: (arg: boolean) => void;
    setOpenedMenuItemKeys: (arg: string[]) => void;
    setSearchValue: (arg: string) => void;
    toggleIsOpenSettingsCol: (arg: boolean) => void;
    setFocusedRow: (id: string) => void;
    toggleOpenMenuItem: (arg: string) => void;
    bindModalDataAction: (arg: { isOpen: boolean; id?: string; type?: TBindSubType; currentVal?: string }) => void;
    editTypeModalDataAction: (arg: {
        isOpen: boolean;
        currentVal?: string;
        action?: SubTypeActions;
        typeKeyPath?: string[];
    }) => void;
    setLoadDataSource: (arg: boolean) => void;
}

export const DmsAppControlContext = createContext<IDmsAppControlCtx>({
    selectRow: () => {},
    selectRows: () => {},
    unselectRow: () => {},
    resetSelectedRows: () => {},
    setActiveType: () => {},
    resetAppContext: () => {},
    deleteModeToggle: () => {},
    setDragID: () => {},
    toggleDraggingState: () => {},
    toggleDownloadDocumentsModal: () => {},
    setOpenedMenuItemKeys: () => {},
    setSearchValue: () => {},
    viewToggle: () => {},
    typeChangeModeToggle: () => {},
    toggleIsOpenSettingsCol: () => {},
    setFocusedRow: () => {},
    toggleOpenMenuItem: () => {},
    bindModalDataAction: () => {},
    editTypeModalDataAction: () => {},
    setLoadDataSource: () => {},
});
