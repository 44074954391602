import React, { FC, PropsWithChildren } from "react";
import { CompanyContext } from "scripts/context/CompanyContext";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { convertGroupActions, RecordsControlContext } from "scripts/context/recordsContext/RecordsControlCtx";
import {
    TableViewContext,
    TableViewContextProvider,
    useDefaultYearPeriodFilters
} from "../../../scripts/context/tableViewContext/tableViewContext";
import { ProductBank } from "../../../scripts/core/Product";
import { RecordLogRepository } from "../../../scripts/infrastructure/repository/grRepositories";
import { GQL } from "@binale-tech/shared";
import { Navigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../../scripts/routing/routeConstants";

export const BankProvider: FC<PropsWithChildren> = ({ children }) => {
    const { uuid } = useParams<"uuid">();
    const { yearConfig, yearBanks, companyGQL } = React.useContext(CompanyContext);
    const hasBank = Boolean(yearBanks.find(v => v.id === uuid));
    const allRecords = React.useContext(RecordsContext);
    const allActions = React.useContext(RecordsControlContext);
    const defaultFilters = useDefaultYearPeriodFilters();

    const tableCtx = React.useMemo<React.ContextType<typeof TableViewContext>>(
        () => ({
            view: "bankOverview",
            product: new ProductBank(yearConfig.skr, companyGQL),
            productKey: GQL.IProductKey.Bank,
            moduleRecords: allRecords.recordsBank.groups.get(uuid)?.list || [],
            moduleActions: convertGroupActions(allActions.actionsBank, uuid),
            moduleLogLister: key => RecordLogRepository.Bank.list({ path: [companyGQL?.id, uuid, key] }),
            selectedRecordGroup: uuid,
        }),
        [allActions.actionsBank, allRecords.recordsBank.groups, uuid, companyGQL, yearConfig]
    );
    if (!hasBank) {
        return <Navigate to={AppRoutes.home} />;
    }

    return (
        <TableViewContextProvider tableCtx={tableCtx} defaultFilters={defaultFilters}>
            {children}
        </TableViewContextProvider>
    );
};
