import { AuthError } from "@firebase/auth-types";
import { BinaleErrorCodes } from "./ErrorCodes";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthErrorCodes as FirebaseAuthErrorCodes } from "@firebase/auth";
import { IMessagesType } from "../../intl/translations/de";
import { logger } from "../../infrastructure/logger";

const FirebaseErrorCodes = { ...FirebaseAuthErrorCodes, ...BinaleErrorCodes };
type KeyOfErrorCodesType = keyof typeof FirebaseErrorCodes;
export type FirebaseAuthErrorCodesType = (typeof FirebaseErrorCodes)[KeyOfErrorCodesType];
type ErrorTranslationKeys = {
    [K in FirebaseAuthErrorCodesType]?: keyof IMessagesType;
};

export const authErrorTranstalions: ErrorTranslationKeys = {
    [FirebaseAuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER]: "app.firebase.error.auth_too_many_requests",
    [FirebaseAuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN]: "app.firebase.error.auth_requires_recent_login",
    [FirebaseAuthErrorCodes.USER_DELETED]: "app.firebase.error.auth_user_not_found",
    [FirebaseAuthErrorCodes.EMAIL_EXISTS]: "app.firebase.error.duplicate_email",
    [FirebaseAuthErrorCodes.WEAK_PASSWORD]: "app.firebase.error.auth_weak_password",
    [FirebaseAuthErrorCodes.INVALID_EMAIL]: "app.firebase.error.auth_invalid_email",
    [BinaleErrorCodes.AUTH_INVALID_ID_TOKEN]: "app.2fa.token_error",
    [FirebaseAuthErrorCodes.INTERNAL_ERROR]: "app.global.error",
};

export const getFirebaseErrorMessageByError = (error: AuthError) => {
    const translatedErrorId = authErrorTranstalions[error.code];
    if (!translatedErrorId) {
        logger.log({ message: "no_translation_for_error_code", errorCode: error.code });
    }
    return translatedErrorId || authErrorTranstalions[FirebaseAuthErrorCodes.INTERNAL_ERROR];
};

export { FirebaseAuthErrorCodes, BinaleErrorCodes };
