import React, { FC, useCallback, useEffect, useState } from "react";
import { IDoesFilterPassParams } from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { Radio, RadioChangeEvent, Space } from "antd";
import { FormattedMessage } from "react-intl";

import styles from "../DocumentTable.module.scss";

export const AttachedFilter: FC<CustomFilterProps> = props => {
    const { model, getValue, onModelChange } = props;

    const [value, setValue] = useState<string | boolean>(model ?? "all");

    const doesFilterPass = useCallback(
        (params: IDoesFilterPassParams) => {
            const { node } = params;
            const nodeValue = Boolean(getValue(node));

            if (value === "all") {
                return true;
            }

            return value === nodeValue;
        },
        [value]
    );

    const onRadioChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
        onModelChange(e.target.value);
    };

    useGridFilter({
        doesFilterPass,
    });

    useEffect(() => {
        setValue(model ?? "all");
    }, [model]);

    return (
        <div className={styles.filterDropdown}>
            <Radio.Group value={value} onChange={onRadioChange}>
                <Space direction={"vertical"}>
                    <Radio value={"all"}>
                        <FormattedMessage id="app.dms.showAll" />
                    </Radio>
                    <Radio value={true}>
                        <FormattedMessage id="app.dms.isAttached" />
                    </Radio>
                    <Radio value={false}>
                        <FormattedMessage id="app.dms.isn'tAttached" />
                    </Radio>
                </Space>
            </Radio.Group>
        </div>
    );
};
