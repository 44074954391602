import Menu, { MenuProps } from "antd/es/menu";
import React from "react";
import { AppRoutes } from "scripts/routing/routeConstants";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";

type Props = { menuProps: MenuProps };
export const ModuleCommonKontoControlLinks: React.FC<Props> = ({ menuProps }) => {
    const { pathname = "" } = useLocation();
    return (
        <Menu
            selectedKeys={[pathname]}
            {...menuProps}
            items={[
                {
                    key: AppRoutes.manageAccountCategories,
                    label: (
                        <Link to={AppRoutes.manageAccountCategories}>
                            <FormattedMessage id="app.titles.category.pl" tagName="span" />
                        </Link>
                    ),
                },
                {
                    key: AppRoutes.manageAccountCreditors,
                    label: (
                        <Link to={AppRoutes.manageAccountCreditors}>
                            <FormattedMessage id="app.titles.creditor.pl" tagName="span" />
                        </Link>
                    ),
                },
                {
                    key: AppRoutes.manageAccountDebitor,
                    label: (
                        <Link to={AppRoutes.manageAccountDebitor}>
                            <FormattedMessage id="app.titles.debitor.pl" tagName="span" />
                        </Link>
                    ),
                },
                {
                    key: AppRoutes.manageAccountTag,
                    label: (
                        <Link to={AppRoutes.manageAccountTag}>
                            <FormattedMessage id="app.titles.tag.pl" tagName="span" />
                        </Link>
                    ),
                },
            ]}
        />
    );
};
