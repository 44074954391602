type TLengthRules = number[] | { min: number; max: number };
type TSymbols = "numeric" | "alphanumeric" | "alphanumeric_special";

interface ICountryRequirement {
    lengthRules: TLengthRules;
    symbols: TSymbols;
    specialChars?: string[];
}

const countryRequirements: Record<string, ICountryRequirement> = {
    BE: { lengthRules: [10], symbols: "numeric" },
    BG: { lengthRules: [9, 10], symbols: "numeric" },
    DK: { lengthRules: [8], symbols: "numeric" },
    DE: { lengthRules: [9], symbols: "numeric" },
    EE: { lengthRules: [9], symbols: "numeric" },
    FI: { lengthRules: [8], symbols: "numeric" },
    FR: { lengthRules: [11], symbols: "alphanumeric" },
    EL: { lengthRules: [9], symbols: "numeric" },
    IE: { lengthRules: [8, 9], symbols: "alphanumeric" },
    IT: { lengthRules: [11], symbols: "numeric" },
    HR: { lengthRules: [11], symbols: "numeric" },
    LV: { lengthRules: [11], symbols: "numeric" },
    LT: { lengthRules: [9, 12], symbols: "numeric" },
    LU: { lengthRules: [8], symbols: "numeric" },
    MT: { lengthRules: [8], symbols: "numeric" },
    NL: { lengthRules: [12], symbols: "alphanumeric_special", specialChars: ["+", "*"] },
    XI: { lengthRules: [9, 12], symbols: "numeric" },
    AT: { lengthRules: [9], symbols: "alphanumeric" },
    PL: { lengthRules: [10], symbols: "numeric" },
    PT: { lengthRules: [9], symbols: "numeric" },
    RO: { lengthRules: { min: 2, max: 10 }, symbols: "numeric" },
    SE: { lengthRules: [12], symbols: "numeric" },
    SK: { lengthRules: [10], symbols: "numeric" },
    SI: { lengthRules: [8], symbols: "numeric" },
    ES: { lengthRules: [9], symbols: "alphanumeric" },
    CZ: { lengthRules: { min: 8, max: 10 }, symbols: "numeric" },
    HU: { lengthRules: [8], symbols: "numeric" },
    CY: { lengthRules: [9], symbols: "alphanumeric" },
};

const isValidLength = (input: string, lengthRules: TLengthRules): boolean => {
    if (Array.isArray(lengthRules)) {
        return lengthRules.includes(input.length);
    } else if (lengthRules && typeof lengthRules === "object") {
        const { min, max } = lengthRules;
        return input.length >= min && input.length <= max;
    }
    return false;
};

const isNumeric = (input: string): boolean => /^\d+$/.test(input);

const isAlphanumeric = (input: string): boolean => /^[a-zA-Z0-9]+$/.test(input);

const isAlphanumericSpecial = (input: string, specialChars: string[] = []): boolean => {
    const specialCharsPattern = specialChars.map(char => `\\${char}`).join("");
    const alphanumericSpecialPattern = `^[a-zA-Z0-9${specialCharsPattern}]+$`;
    const regex = new RegExp(alphanumericSpecialPattern);
    return regex.test(input);
};

export const validateInput = (countryCode: string, input: string): boolean => {
    const requirements = countryRequirements[countryCode];

    if (!requirements) {
        return false;
    }

    const { lengthRules, symbols, specialChars } = requirements;

    if (!isValidLength(input, lengthRules)) {
        return false;
    }

    switch (symbols) {
        case "numeric":
            return isNumeric(input);
        case "alphanumeric":
            return isAlphanumeric(input);
        case "alphanumeric_special":
            return isAlphanumericSpecial(input, specialChars);
        default:
            return false;
    }
};
