import React from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { Debitor } from "scripts/models";
import { ExtNumCombobox, ExtNumComboboxProps } from "./ExtNumCombobox";
import { KontoContext } from "scripts/context/KontoEntitiesProvider";

export const DebitorCombobox: React.FC<Omit<ExtNumComboboxProps<Debitor>, "items">> = ({ inputProps, ...props }) => {
    const { debitors } = React.useContext(KontoContext);

    return (
        <ExtNumCombobox<Debitor>
            items={debitors}
            notFoundContent={
                <span>
                    <p>
                        <FormattedMessage id="app.messages.no_elements_found" />
                    </p>
                    <Link to={AppRoutes.manageAccountDebitor} target="_blank">
                        <Button type="primary" tabIndex={-1}>
                            <FormattedMessage id="app.items.create_debitor" />
                        </Button>
                    </Link>
                </span>
            }
            {...props}
        />
    );
};
