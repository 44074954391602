import React, { FC } from "react";
import { DmsType, MovementType } from "@dms/types";
import { ConfigProvider, Radio } from "antd";
import { green, red } from "@ant-design/colors";
import { FormattedMessage } from "react-intl";
import { movementTypeTranslationKey } from "@dms/configs/translationConst";

const greenColor = green[6];
const redColor = red[5];

type TProps = {
    documentType?: DmsType;
    movementType: MovementType;
    setMovementType: (arg: MovementType) => void;
    disabled?: boolean;
};

export const MovementTypeToggle: FC<TProps> = ({ documentType, movementType, setMovementType, ...props }) => {
    if (!documentType) {
        return null;
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: movementType === MovementType.RECEIPT ? greenColor : redColor,
                },
                components: {
                    Radio: {
                        buttonSolidCheckedActiveBg: movementType === MovementType.RECEIPT ? greenColor : redColor,
                        buttonSolidCheckedBg: movementType === MovementType.RECEIPT ? greenColor : redColor,
                        buttonSolidCheckedHoverBg: movementType === MovementType.RECEIPT ? greenColor : redColor,
                    },
                },
            }}
        >
            <Radio.Group
                {...props}
                value={movementType}
                onChange={e => {
                    setMovementType(e.target.value);
                }}
                size="small"
                buttonStyle={"solid"}
            >
                <Radio.Button tabIndex={-1} value={MovementType.RECEIPT}>
                    <div style={{ display: "block", minWidth: "65px", textAlign: "center" }}>
                        <FormattedMessage id={movementTypeTranslationKey[documentType]?.[MovementType.RECEIPT]} />
                    </div>
                </Radio.Button>
                <Radio.Button tabIndex={-1} value={MovementType.SPENDING}>
                    <div style={{ display: "block", minWidth: "65px", textAlign: "center" }}>
                        <FormattedMessage id={movementTypeTranslationKey[documentType]?.[MovementType.SPENDING]} />
                    </div>
                </Radio.Button>
            </Radio.Group>
        </ConfigProvider>
    );
};
