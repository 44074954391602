import React from "react";
import ReactDOMServer from "react-dom/server";
import dayjs from "dayjs";
import { Periods, Utils } from "@binale-tech/shared";
import { stripHtml } from "string-strip-html";

import { GenericRecord } from "../models";
import { GenericRecordColumns } from "../../appearance/columns/ColumnConfig";
import { GenericRecordProperties } from "../core/Product";
import { GenericRecordTableItem, TableColumn, TableItemExtra } from "../../appearance/components/shared/Table/Table";
import { IntlProvider } from "react-intl";
import { getLocaleMessages, Lang } from "../intl";

interface ExportOpts {
    columns: string[];
    tableItems: GenericRecordTableItem[];
    tableColumns: GenericRecordColumns;
    columnNames?: Map<string, string>;
    groupByColumns?: string[];
}

export interface ExportData {
    columns: string[];
    columnsOrder: string[];
    rows: string[][] | Map<string, string[][]>;
    rowsCount: number;
    styles?: Record<string, unknown>;
    widths?: (number | string)[];
}
const dateFromPeriod = (year: number, period: number) => {
    if (period === Periods.Period.FirstDayOfYear) {
        return "01.01." + year.toString();
    }
    if (period === Periods.Period.LastDayOfYear) {
        return "31.12." + year.toString();
    }
    return dayjs(new Date(year, period - 1, 1)).format("MM.YYYY");
};
export class ExportPreprocessor {
    protected static instance: ExportPreprocessor;
    protected constructor() {}
    static getInstance = () => {
        if (!this.instance) {
            this.instance = new ExportPreprocessor();
        }
        return this.instance;
    };

    getSuffix(year: number, periods: Set<number>) {
        const list = Array.from(periods);
        list.sort((a, b) => a - b);
        const isSeq = list.slice(1).reduce((caret, v, idx, array) => caret && array[idx] === list[idx] + 1, true);

        let suffix = "";
        if (list.length === 1) {
            suffix = dateFromPeriod(year, list[0]);
        } else if (isSeq) {
            suffix = dateFromPeriod(year, list[0]) + "-" + dateFromPeriod(year, list[list.length - 1]);
        } else {
            suffix = list.map(v => dateFromPeriod(year, v)).join("+");
        }
        return suffix;
    }
    getExpandedExportData = (exportOpts: ExportOpts & { groupFake?: string }): ExportData => {
        exportOpts.columnNames = exportOpts.columnNames || new Map();
        exportOpts.columns = exportOpts.columns.filter(Boolean);
        const { columnNames, columns, tableColumns, groupByColumns, tableItems } = exportOpts;
        const useGrouping = groupByColumns?.length > 0;

        const columnMapping = tableColumns.getMappedColumns();
        const expandedTableItems = tableItems.reduce((result: TableItemExtra<GenericRecord>[], tableItem) => {
            if (tableItem.children.length > 0) {
                return result.concat(
                    tableItem.children.map(i => {
                        i.falligkeit = tableItem.item.falligkeit;
                        return {
                            key: null,
                            item: i,
                            children: [],
                            extra: tableItem.extra,
                        } as TableItemExtra<GenericRecord>;
                    })
                );
            }
            return result.concat(tableItem);
        }, []);

        let rows: string[][] | Map<string, string[][]>;
        if (useGrouping) {
            const grouppingColumns = groupByColumns.map(c => columnMapping.get(c));
            rows = expandedTableItems.reduce((result: Map<string, string[][]>, tableItem) => {
                const grouppingColumnText = grouppingColumns.map(col => this.toText(col.getter(tableItem))).join(" ");
                let nr = result.has(grouppingColumnText) ? result.get(grouppingColumnText).length : 0;
                tableItem.key = nr++;
                const rowData = this.getItemRows(tableItem, columns, columnMapping);
                const row = result.has(grouppingColumnText) ? result.get(grouppingColumnText).concat(rowData) : rowData;
                result.set(grouppingColumnText, row);
                return result;
            }, new Map());
        } else {
            const rowsList = expandedTableItems.reduce(
                (rowsL: string[][], tableItem) => rowsL.concat(this.getItemRows(tableItem, columns, columnMapping)),
                []
            );
            if (exportOpts.groupFake) {
                rows = new Map([[exportOpts.groupFake, rowsList]]);
            } else {
                rows = rowsList;
            }
        }
        const rowsCount =
            rows instanceof Map ? Array.from(rows.values()).reduce((sum, list) => sum + list.length, 0) : rows.length;
        return {
            widths: this.getWidths(columns, columnMapping),
            columnsOrder: columns,
            columns: columns.reduce((p, c) => p.concat(columnNames.has(c) ? columnNames.get(c) : c), []),
            rows,
            rowsCount,
            styles: columns.reduce(
                (p, c, i) => {
                    const column = columnMapping.get(c);
                    p[i] = {
                        halign: column.isMoney ? "right" : "left",
                    };
                    return p;
                },
                {} as Record<string, any>
            ),
        } as ExportData;
    };

    getCollapsedExportData(exportOpts: ExportOpts): ExportData {
        exportOpts.columnNames = exportOpts.columnNames || new Map();
        exportOpts.columns = exportOpts.columns.filter(Boolean);
        const { columnNames, columns, tableColumns, groupByColumns, tableItems } = exportOpts;
        const useGrouping = groupByColumns?.length > 0;

        const columnMapping = tableColumns.getMappedColumns();

        let groupped = !useGrouping
            ? tableItems.reduce((rows: string[][], tableItem) => {
                  return rows.concat(columns.map(c => this.toText(columnMapping.get(c).getter(tableItem))));
              }, [])
            : tableItems.reduce((result: Map<string, string[][]>, tableItem) => {
                  const grouppingColumns = groupByColumns.map(c => columnMapping.get(c));
                  const grouppingColumnText = grouppingColumns.map(col => this.toText(col.getter(tableItem))).join(" ");
                  let nr = result.has(grouppingColumnText) ? result.get(grouppingColumnText).length : 0;
                  tableItem.key = nr++;
                  const stringCols = [columns.map(c => this.toText(columnMapping.get(c).getter(tableItem)))];
                  const row = result.has(grouppingColumnText)
                      ? result.get(grouppingColumnText).concat(stringCols)
                      : stringCols;
                  result.set(grouppingColumnText, row);
                  return result;
              }, new Map());

        if (groupped instanceof Map) {
            groupped.forEach(v => {
                let bruttoIdx: number = null;
                let skontoIdx: number = null;
                let offenIdx: number = null;
                columns.forEach((c, idx) => {
                    if (c === GenericRecordProperties.RecordBrutto) {
                        bruttoIdx = idx;
                    }
                    if (c === GenericRecordProperties.ComputedSkonto) {
                        skontoIdx = idx;
                    }
                    if (c === GenericRecordProperties.ComputedOffen) {
                        offenIdx = idx;
                    }
                });
                const sumObj = { brutto: 0, skonto: 0, offen: 0 };
                v.forEach((row: any[]) => {
                    if (bruttoIdx !== null) {
                        sumObj.brutto += Number(row[bruttoIdx].replace(",", "").replace(".", ""));
                    }
                    if (skontoIdx !== null) {
                        sumObj.skonto += Number(row[skontoIdx].replace(",", "").replace(".", ""));
                    }
                    if (offenIdx !== null) {
                        sumObj.offen += Number(row[offenIdx].replace(",", "").replace(".", ""));
                    }
                });
                const row = columns.map((c, idx) => {
                    if (c === GenericRecordProperties.RecordBelegfeld1) {
                        return { text: "Summe", bold: true };
                    }
                    if (idx === bruttoIdx) {
                        return { text: Utils.CurrencyUtils.currencyFormat(sumObj.brutto), bold: true };
                    }
                    if (idx === skontoIdx) {
                        return { text: Utils.CurrencyUtils.currencyFormat(sumObj.skonto), bold: true };
                    }
                    if (idx === offenIdx) {
                        return { text: Utils.CurrencyUtils.currencyFormat(sumObj.offen), bold: true };
                    }
                    return { text: "" };
                });
                v.push(row);
            });
            const sorted = new Map();
            Array.from(groupped.keys())
                .sort()
                .forEach(k => {
                    sorted.set(k, (groupped as Map<any, any>).get(k));
                });
            groupped = sorted;
        }
        const rowsCount =
            groupped instanceof Map
                ? Array.from(groupped.values()).reduce((sum, list) => sum + list.length, 0)
                : groupped.length;
        const s = {
            widths: this.getWidths(columns, columnMapping),
            columnsOrder: columns,
            columns: columns.reduce((p, c) => p.concat(columnNames.has(c) ? columnNames.get(c) : c), []),
            rows: groupped,
            rowsCount,
            styles: columns.reduce(
                (p, c, i) => {
                    const column = columnMapping.get(c);
                    p[i] = {
                        halign: column.isMoney ? "right" : "left",
                    };
                    return p;
                },
                {} as Record<string, any>
            ),
        };
        return s;
    }

    protected getWidths = (columns: string[], columnMap: Map<string, TableColumn<GenericRecord>>) => {
        return columns.map(c => {
            if ([GenericRecordProperties.ItemBuchungstext, GenericRecordProperties.ItemBuchungstext2].indexOf(c) > -1) {
                return "*";
            }
            return columnMap.get(c).width / 1.8;
        });
    };

    static nodeToString(v: any) {
        try {
            return stripHtml(ReactDOMServer.renderToString(v)).result;
        } catch (e) {
            const value = <IntlProvider {...getLocaleMessages(Lang.De)}>{v}</IntlProvider>;
            return stripHtml(ReactDOMServer.renderToString(value)).result;
        }
    }

    protected toText(v: any) {
        return ExportPreprocessor.nodeToString(v);
    }

    protected getItemRows = (
        tableItem: GenericRecordTableItem,
        columns: string[],
        columnMap: Map<string, TableColumn<GenericRecord>>
    ) => {
        return tableItem.item.items.map(() => {
            return columns.map(c => this.toText(columnMap.get(c).getter(tableItem)));
        });
    };
}
