import { Input } from "antd";
import React, { ChangeEvent, FC, useState } from "react";
import { CustomCellEditorProps } from "ag-grid-react";
import { TInvoiceFormLineItem, NavigationKeys } from "@inv/types";

import styles from "../editors.module.scss";

export const NameEditor: FC<CustomCellEditorProps<TInvoiceFormLineItem>> = ({ value, onValueChange, stopEditing }) => {
    const [inputValue, setInputValue] = useState<string>(value);

    const handleConfirm = (newValue: string) => {
        onValueChange(newValue);
        stopEditing();
    };

    const handleChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(target.value);
    };

    const handleFocus = ({ target }: React.FocusEvent<HTMLTextAreaElement>) => {
        target.select();
    };
    const handleBlur = ({ target }: React.FocusEvent<HTMLTextAreaElement>) => handleConfirm(inputValue);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === NavigationKeys.ESCAPE) {
            stopEditing();
        }

        if (e.shiftKey && e.key === NavigationKeys.ENTER) {
            handleConfirm(inputValue);
        }
    };

    return (
        <Input.TextArea
            className={styles.agEditor}
            value={inputValue}
            variant={"borderless"}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoSize
            autoFocus
        />
    );
};
