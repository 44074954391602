import React from "react";
import { IVatRowData } from "./types";

type VatRowConfig = Pick<IVatRowData, "title" | "titleBmg" | "titleSt" | "isGroupTitle">;

const emptyLine: VatRowConfig = { title: <div>&nbsp;</div>, isGroupTitle: true };

export const defaultVatRowConfig: VatRowConfig[] = [
    {
        title: <strong>A. Steuerpflichtige Lieferungen, sonstige Leistungen und unentgeltliche Wertabgaben</strong>,
        isGroupTitle: true,
    },
    {
        title: <div>Steuerpflichtige Umsätze 19 %</div>,
        titleBmg: "81",
        titleSt: "X1",
    },
    {
        title: <div>Steuerpflichtige Umsätze 7 %</div>,
        titleBmg: "86",
        titleSt: "X2",
    },
    {
        title: <div>Steuerpflichtige Umsätze 0 %</div>,
        titleBmg: "87",
    },
    {
        title: <div>Andere Steuersätze</div>,
        titleBmg: "35",
        titleSt: "36",
    },
    {
        title: <div>Landwirtschaftliche Betriebe mit USt-ID</div>,
        titleBmg: "77",
    },
    {
        title: (
            <div>
                Umsätze, für die eine Steuer nach § 24 UStG zu entrichten ist (Sägewerkserzeugnisse, Getränke und
                alkoholische Flüssigkeiten, z.B. Wein)
            </div>
        ),
        titleBmg: "76",
        titleSt: "80",
    },
    emptyLine,
    {
        title: <strong>B. Steuerfreie Lieferungen, sonstige Leistungen und unentgeltliche Wertabgaben</strong>,
        isGroupTitle: true,
    },
    {
        title: <div>Innergemeinschaftliche Lieferungen mit USt-ID</div>,
        titleBmg: "41",
    },
    {
        title: <div>Neue Fahrzeuge an Abnehmer ohne USt-ID</div>,
        titleBmg: "44",
    },
    {
        title: <div>Neue Fahrzeuge außerhalb eine Unternehmens (§ 2a UStG)</div>,
        titleBmg: "49",
    },
    {
        title: (
            <div>
                Weitere steuerfreie Umsätze mit Vorsteuerabzug (z.B. Ausfuhrlieferungen, Umsätze nach § 4 Nr. 2 bis 7
                UStG)
            </div>
        ),
        titleBmg: "43",
    },
    {
        title: <div>Steuerfreie Umsätze ohne Vorsteuerabzug (z.B. Umsätze nach § 4 Nr. 8 bis 29 UStG)</div>,
        titleBmg: "48",
    },
    emptyLine,
    {
        title: <strong>C. Innergemeinschaftliche Erwerbe</strong>,
        isGroupTitle: true,
    },
    {
        title: <div>Steuerfreie EU-Erwerbe von bestimmten Gegenständen und Anlagegold (§§ 4b und 25c UStG)</div>,
        titleBmg: "91",
    },
    {
        title: <div>Steuerpflichtige EU-Erwerbe 19 %</div>,
        titleBmg: "89",
        titleSt: "X3",
    },
    {
        title: <div>Steuerpflichtige EU-Erwerbe 7 %</div>,
        titleBmg: "93",
        titleSt: "X4",
    },
    {
        title: <div>Steuerpflichtige EU-Erwerbe 0 %</div>,
        titleBmg: "90",
    },
    {
        title: <div>Andere Steuersätze</div>,
        titleBmg: "95",
        titleSt: "98",
    },
    {
        title: <div>Neue Fahrzeuge (§ 1b Abs. 2 und 3 UStG) von Lieferern ohne USt-ID</div>,
        titleBmg: "94",
        titleSt: "96",
    },
    emptyLine,
    {
        title: <strong>D. Leistungsempfänger als Steuerschuldner (§ 13b UStG)</strong>,
        isGroupTitle: true,
    },
    {
        title: (
            <div>
                Sonstige Leistungen nach § 3a Abs. 2 UStG eines im übrigen Gemeinschaftsgebiet ansässigen Unternehmers
                (§ 13b Abs. 1 UStG)
            </div>
        ),
        titleBmg: "46",
        titleSt: "47",
    },
    {
        title: <div>Umsätze, die unter das GrEStG fallen (§ 13b Abs. 2 Nr. 3 UStG)</div>,
        titleBmg: "73",
        titleSt: "74",
    },
    {
        title: <div>Andere Leistungen (§ 13b Abs. 2 Nr. 1, 2, 4 bis 12 UStG)</div>,
        titleBmg: "84",
        titleSt: "85",
    },
    emptyLine,
    {
        title: <strong>E. Ergänzende Angaben zu Umsätzen</strong>,
        isGroupTitle: true,
    },
    {
        title: <div>Lieferungen des ersten Abnehmers bei innergemeinschaftlichen Dreiecksgeschäften (§ 25b UStG)</div>,
        titleBmg: "42",
    },
    {
        title: (
            <div>
                Steuerpflichtige Umsätze des leistenden Unternehmers, für die der Leistungsempfänger die Steuer nach §
                13b Abs. 5 UStG schuldet
            </div>
        ),
        titleBmg: "60",
    },
    {
        title: <div>Nicht steuerbare sonstige Leistungen gem. § 18b S. 1 Nr. 2 UStG</div>,
        titleBmg: "21",
    },
    {
        title: <div>Übrige nicht steuerbare Umsätze (Leistungsort nicht im Inland)</div>,
        titleBmg: "45",
    },
    emptyLine,
    {
        title: <strong>Umsatzsteuer</strong>,
        titleSt: "X5",
    },
    emptyLine,
    {
        title: <strong>F. Abziehbare Vorsteuerbeträge und Berichtigung des Vorsteuerabzugs</strong>,
        isGroupTitle: true,
    },
    {
        title: (
            <div>
                Vorsteuerbeträge aus Rechnungen von anderen Unternehmers (§ 15 Abs. 1 S. 1 Nr. 1 UStG), aus Leistungen
                im Sinne des § 13a Abs. 1 Nr. 6 UStG (§ 15 Abs. 1 S. 1 Nr. 5 UStG) und aus innergemeinschaftlichen
                Dreiecksgeschäften (§ 25b Abs. 5 UStG)
            </div>
        ),
        titleSt: "66",
    },
    {
        title: (
            <div>
                Vorsteuerbeträge aus dem innergemeinschaftlichen Erwerb von Gegenständen (§ 15 Abs. 1 S. 1 Nr. 3 UStG)
            </div>
        ),
        titleSt: "61",
    },
    {
        title: <div>Entstandene Einfuhrumsatzsteuer (§ 15 Abs. 1 S. 1 Nr. 2 UStG)</div>,
        titleSt: "62",
    },
    {
        title: <div>Vorsteuerbeträge aus Leistungen im Sinne des § 13b UStG (§ 15 Abs. 1 S. 1 Nr. 4 UStG)</div>,
        titleSt: "67",
    },
    {
        title: <div>Vorsteuerbeträge, die nach allgemeinen Durchschnittssätzen berechnet sind (§ 23a UStG)</div>,
        titleSt: "63",
    },
    {
        title: (
            <div>
                Vorsteuerabzug für innergemeinschaftliche Lieferungen neuer Fahrzeuge außerhalb eines Unternehmens (§ 2a
                UStG) sowie von Kleinunternehmern im Sinne des § 19 Abs. 1 UStG (§ 15 Abs. 4a UStG)
            </div>
        ),
        titleSt: "59",
    },
    {
        title: <div>Berichtigung des Vorsteuerabzugs (§ 15a UStG)</div>,
        titleSt: "64",
    },
    emptyLine,
    {
        title: <b>Vorsteuer</b>,
        titleSt: "X6",
    },
    emptyLine,
    {
        title: <b>Verbleibender Betrag</b>,
        titleSt: "X7",
    },
    emptyLine,
    {
        title: <strong>G. Andere Steuerbeträge</strong>,
        isGroupTitle: true,
    },
    {
        title: (
            <div>
                Steuer infolge des Wechsels der Besteuerungsform sowie Nachsteuer auf versteuerte Anzahlungen und
                ähnlichem wegen Steuersatzänderung
            </div>
        ),
        titleSt: "65",
    },
    {
        title: (
            <div>
                In Rechnungen unrichtig oder unberechtigt ausgewiesene Steuerbeträge (§ 14c UStG) sowie Steuerbeträge,
                die nach § 6a Abs. 4 S. 2, § 17 Abs. 1 S. 7, § 25b Abs. 2 UStG oder von einem Auslagerer oder
                Lagerhalter nach § 13a Abs. 1 Nr. 6 UStG geschuldet werden
            </div>
        ),
        titleSt: "69",
    },
    emptyLine,
    {
        title: <b>H. Umsatzsteuer-Vorausz./Überschuss</b>,
        titleSt: "X8",
    },
    emptyLine,
    {
        title: <div>Abzug der festgesetzten Sondervorauszahlung für Dauerfristverlängerung</div>,
        titleSt: "39",
    },
    emptyLine,
    {
        title: <strong>Verbleibende Umsatzsteuer-Vorauszahlung /-Überschuss (negativ)</strong>,
        titleSt: "83",
    },
    emptyLine,
    {
        title: (
            <strong>
                I. Ergänzende Angaben zu Minderungen nach § 17 Abs. 1 S. 1, 2 in V.m. Abs. 2 Nr. 1 S. 1 UStG
            </strong>
        ),
        isGroupTitle: true,
    },
    {
        title: "Minderung der Bemessungsgrundlage",
        titleBmg: "50",
    },
    {
        title: "Minderung der abziehbaren Vorsteuerbeträge",
        titleSt: "37",
    },
];
