import React, { FC, useContext, useState } from "react";
import { ConfigProvider, Dropdown } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { BanksApi } from "@banks/scripts/api";
import { BanksAppControlContext } from "@banks/scripts/context";
import { GQL } from "@binale-tech/shared";

type TProps = {
    bank: GQL.IBank;
    handleActionLoading: (arg: boolean) => void;
    children: React.ReactNode;
};

export const DropdownActions: FC<TProps> = ({ bank, handleActionLoading, children }) => {
    const [open, setOpen] = useState(false);

    const { setEditBankData } = useContext(BanksAppControlContext);

    const handleDeleteBank = async () => {
        await BanksApi.deleteBank({
            ...bank,
        });
    };

    const items = [
        {
            label: (
                <div>
                    <EditOutlined /> <FormattedMessage id="app.button.edit" />
                </div>
            ),
            key: "0",
            onClick: () => {
                if (bank) {
                    setEditBankData({
                        bank,
                        isModalOpen: true,
                    });

                    setOpen(false);
                }
            },
        },
        {
            label: (
                <div>
                    <DeleteOutlined /> <FormattedMessage id="app.button.delete" />
                </div>
            ),
            key: "1",
            onClick: async () => {
                setOpen(false);
                handleActionLoading(true);
                if (bank) {
                    await handleDeleteBank();
                }
                handleActionLoading(false);
            },
        },
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Dropdown: {
                        zIndexPopup: 10,
                    },
                },
            }}
        >
            <Dropdown
                menu={{ items }}
                trigger={["click"]}
                destroyPopupOnHide={true}
                open={open}
                onOpenChange={() => {
                    setOpen(!open);
                }}
                overlayStyle={{ minWidth: 150 }}
            >
                {children}
            </Dropdown>
        </ConfigProvider>
    );
};
