import React, { useContext, useMemo, useState } from "react";
import cn from "classnames";
import { Button, Popconfirm, Space, Table, TableColumnsType } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { ContactChild, ContactColumns, Filters, useContactHeader } from "./columns";
import { Contacts } from "@binale-tech/shared";
import { ContactsContext } from "scripts/context/ContactsContext";

import { FormattedMessage } from "react-intl";
import "./style.css";
import { getContactVatPrint } from "@app/views/productContacts/form/utils";

interface IProps {
    onEdit: (contact: Contacts.Contact) => void;
    onDelete: (contact: Contacts.Contact) => Promise<void>;
}

/*
- alphabet order - internalName
- sort all columns
*/

type DataType = Contacts.Contact & { children?: ContactChild[] };

export const filterContact = (contact: Contacts.Contact, filters: Filters) => {
    const filterConditions = {
        [ContactColumns.InternalName]: () =>
            contact.internalName.toLowerCase().includes(filters.InternalName.toLowerCase()),
        [ContactColumns.Type]: () => contact.type === filters.Type,
        [ContactColumns.TypeName]: () => {
            const label = Contacts.getLabelName(contact);
            return label.toLowerCase().includes(filters.TypeName.toLowerCase());
        },
        [ContactColumns.Classification]: () => contact.relations.some(r => filters.Classification.includes(r.type)),
        [ContactColumns.CustomerNumber]: () =>
            contact.relations.some(r => r.internalNumber?.toLowerCase().includes(filters.CustomerNumber.toLowerCase())),
        [ContactColumns.Countries]: () => contact.addresses?.some(address => address.countryCode === filters.Countries),
        [ContactColumns.Cities]: () =>
            contact.addresses?.some(address => address.city?.toLowerCase().includes(filters.Cities.toLowerCase())),
        [ContactColumns.VAT]: () => {
            const vat = getContactVatPrint(contact.legalInfo);
            return vat.toLowerCase().includes(filters.VAT.toLowerCase());
        },
        [ContactColumns.IBAN]: () =>
            contact.banks?.some(bank => bank?.IBAN?.toUpperCase().includes(filters.IBAN.toUpperCase())),
        [ContactColumns.ContactPersons]: () =>
            contact.contactPersons?.some(person =>
                `${person.firstName || ""} ${person.lastName || ""}`
                    .toLowerCase()
                    .includes(filters.ContactPersons.toLowerCase())
            ),
    };

    const filledColumnKeys = Object.keys(filters).filter((columnKey: keyof Filters) => {
        const value = filters[columnKey];
        return Array.isArray(value) ? value.length : Boolean(value);
    }) as (keyof Filters)[];

    return filledColumnKeys.every(columnKey => filterConditions[columnKey]());
};

export const ContactTable: React.FC<IProps> = ({ onEdit, onDelete }) => {
    const { contacts } = useContext(ContactsContext);
    const [selectedRow, setSelectedRow] = useState<string>();
    const { contactColumns, filters } = useContactHeader();

    const tableContacts = useMemo(() => {
        const filteredContacts = contacts.filter(contact => filterContact(contact, filters));

        return filteredContacts.map(contact => {
            const row: DataType = JSON.parse(JSON.stringify(contact));
            if (contact.relations?.length > 1) {
                row.children = contact.relations.map(r => new ContactChild(contact.uuid + r.type, [r]));
            }
            return row;
        });
    }, [contacts, filters]);

    const columns: TableColumnsType<Contacts.Contact> = useMemo(
        () => [
            contactColumns.InternalName,
            contactColumns.Type,
            contactColumns.TypeName,
            contactColumns.Classification,
            contactColumns.CustomerNumber,
            contactColumns.Countries,

            contactColumns.Cities,
            contactColumns.VAT,
            contactColumns.IBAN,
            contactColumns.ContactPersons,
            {
                ...contactColumns.Actions,
                render: (record: Contacts.Contact) => {
                    if (record.internalName) {
                        return (
                            <Space>
                                <Button
                                    type="text"
                                    shape="circle"
                                    onClick={() => {
                                        const contactToEdit = contacts.find(contact => contact.uuid === record.uuid);
                                        onEdit(contactToEdit);
                                    }}
                                >
                                    <EditOutlined />
                                </Button>
                                <Popconfirm
                                    title={<FormattedMessage id="app.contacts.confirmRemoveContact" />}
                                    onConfirm={() => {
                                        onDelete(record);
                                    }}
                                    placement="topRight"
                                    cancelButtonProps={{ autoFocus: true }}
                                >
                                    <Button type="text" shape="circle">
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>
                            </Space>
                        );
                    }
                    return null;
                },
            },
        ],
        [contactColumns, contacts, onDelete, onEdit]
    );

    return (
        <Table<DataType>
            columns={columns}
            scroll={{ y: window.innerHeight - 180, x: window.innerWidth - 48 }}
            dataSource={tableContacts}
            rowKey="uuid"
            size="small"
            className="ContactTable antd-table-with-selection"
            pagination={{ hideOnSinglePage: true, defaultPageSize: 50 }}
            rowClassName={(tableItem, index) =>
                cn({
                    "row--selected": selectedRow === tableItem.uuid,
                    "row--odd": index % 2 === 1,
                })
            }
            onRow={record => ({
                onClick: () => {
                    setSelectedRow(record.uuid);
                },
            })}
        />
    );
};
