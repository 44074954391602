import { IDocumentEnriched, IDocumentSuggestion } from "@dms/types";
import { GQL } from "@binale-tech/shared";
import { convertDocumentToUpdateInput } from "@dms/scripts/helpers/convertDocumentToUpdateInput";

export const applyDocumentSuggestions = (
    document: IDocumentEnriched,
    suggestion: IDocumentSuggestion
): GQL.IDocumentUpdateInput | null => {
    const input = convertDocumentToUpdateInput(document);

    const inputUpdates: Partial<GQL.IDocumentUpdateInput> = {};

    if (!input.documentAmount) {
        if (suggestion.currency) {
            inputUpdates.currency = suggestion.currency.currencyCode;
            inputUpdates.originalAmount = suggestion.currency?.originalAmount;
            inputUpdates.currencyRate = suggestion.currency?.currencyRate;
        } else {
            inputUpdates.currency = GQL.ICurrencyCode.Eur;
        }
    }

    Object.keys(suggestion).forEach(v => {
        const key = v as keyof IDocumentSuggestion;
        if (key === "currency") {
            // currency key is handled separately above
            return;
        }
        if (!input[key]) {
            inputUpdates[key] = suggestion[key] as never;
        }
    });

    if (Object.keys(inputUpdates).length !== 0) {
        return { ...input, ...inputUpdates } satisfies GQL.IDocumentUpdateInput;
    }
    return null;
};
