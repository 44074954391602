import React, { FC } from "react";
import { Col, ConfigProvider, Layout, Row } from "antd";
import { AllBanksStatistic, BankListHeader } from "@banks/components";
import { styleConst } from "./style";
import { BankListModule } from "@banks/modules";
import style from "./BankListLayout.module.scss";

const { Header, Content } = Layout;
const { layoutPadding, bankListColSpan, banksStatisticSpan } = styleConst;

export const BankListLayout: FC = () => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Layout: {
                        headerBg: "inherit",
                        headerHeight: "auto",
                        headerPadding: `0 ${layoutPadding}`,
                    },
                },
            }}
        >
            <Layout style={{ height: "100%" }}>
                <Header>
                    <BankListHeader />
                </Header>
                <Content style={{ padding: layoutPadding }}>
                    <Row gutter={[50, 16]} justify="space-between" wrap={true} style={{ height: "100%" }}>
                        <Col {...bankListColSpan} className={style.bankListInner}>
                            <BankListModule />
                        </Col>

                        <Col {...banksStatisticSpan}>
                            <AllBanksStatistic />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </ConfigProvider>
    );
};
