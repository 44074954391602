import { TDocumentInputs } from "@dms/types";

export function compareInputValues(
    inputValues: Record<string, any>,
    initialInputValues: Record<string, any>,
    ignoreInputs?: Record<string, true>
): boolean {
    let equaled = true;
    for (const el of Object.keys(inputValues)) {
        const key = el as keyof TDocumentInputs;
        if (ignoreInputs && ignoreInputs[key]) {
            continue;
        }
        if (Object.prototype.toString.call(inputValues?.[key]) === "[object Object]") {
            equaled = compareInputValues(inputValues?.[key], initialInputValues?.[key]);
            if (!equaled) {
                console.log("key", key);
                break;
            }
        } else {
            if (inputValues[key] !== initialInputValues?.[key]) {
                console.log("key", key);
                equaled = false;
                break;
            }
        }
    }

    return equaled;
}
