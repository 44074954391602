import React, { ComponentProps, forwardRef, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { BaseSelectRef } from "rc-select";
import { blue } from "@ant-design/colors";
import { Link } from "react-router-dom";
import { DefaultOptionType } from "rc-select/lib/Select";
import { Select, Tooltip } from "antd";

import { Contacts, GQL } from "@binale-tech/shared";
import { PlusOutlined } from "@ant-design/icons";
import { CompanyContext } from "scripts/context/CompanyContext";
import { UserContext } from "scripts/context/UserProvider";
import { ContactsContext } from "scripts/context/ContactsContext";
import { ProductAccessUtils } from "scripts/models/utils/ProductAccessUtils";

import styles from "./contactSelect.module.scss";
import { useCanWrite } from "../../../../../../scripts/infrastructure/hooks";

type TValue = {
    name: string;
    id?: string;
};

type TProps = {
    placeholder?: React.ReactNode;
    tooltipPlacement?: React.ComponentProps<typeof Tooltip>["placement"];
    onChange?: (v: TValue | undefined) => void;
    value?: TValue;
    disabled?: boolean;
};

export const ContactSelect = memo(
    forwardRef<BaseSelectRef, TProps>(
        ({ placeholder, onChange, value, tooltipPlacement = "top", disabled, ...restProps }, ref) => {
            const { contacts } = useContext(ContactsContext);
            const [contactValue, setContactValue] = useState<TValue>();
            const [isSelectOpen, setIsSelectOpen] = useState(false);
            const [placeholderText, setPlaceholderText] = useState(placeholder);
            const [searchValue, setSearchValue] = useState("");

            const hasContactsWriteAccess = useCanWrite(GQL.IProductKey.Contacts);

            const suggestCreateContext = Boolean(
                !disabled && hasContactsWriteAccess && !contactValue?.id && searchValue.length
            );

            const { contactMap, contactOptions } = useMemo(() => {
                const map = new Map<string, Contacts.Contact>();
                const options = (contacts || []).map((item: Contacts.Contact) => {
                    map.set(String(item.uuid), item);
                    return { label: Contacts.getLabelName(item), value: item.uuid };
                });
                return { contactMap: map, contactOptions: options };
            }, [contacts]);

            const handleChange = useCallback(
                (changedValue: string, option: DefaultOptionType | DefaultOptionType[]) => {
                    const contact = option as DefaultOptionType;
                    const newValue = {
                        name: (contact?.label ?? changedValue) as string,
                        id: (contact?.value ?? undefined) as string,
                    };

                    setSearchValue("");
                    setContactValue(newValue);
                    onChange?.(newValue);
                },
                [onChange]
            );

            const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLElement>) => {
                if (e.shiftKey && e.key === "Enter") {
                    setIsSelectOpen(false);
                }
                if (e.key === "Enter") {
                    setIsSelectOpen(false);
                }
            }, []);

            const handleSearch = useCallback(
                (val: string) => {
                    setSearchValue(val);
                },
                [contactOptions]
            );

            const handleClear = useCallback(() => {
                setSearchValue("");
                setContactValue(undefined);
                onChange?.(undefined);
            }, [onChange]);

            const filterOption = useCallback(
                (inputValue: string, option: DefaultOptionType) => {
                    if (!option) {
                        return false;
                    }
                    const item = contactMap.get(String(option.value));

                    if (!item) {
                        return false;
                    }

                    const internalName = item.internalName.toLowerCase();
                    const label = Contacts.getLabelName(item).toLowerCase();
                    const term = inputValue.toLowerCase();

                    return internalName.includes(term) || label.includes(term);
                },
                [contactMap]
            );

            useEffect(() => {
                if (placeholder === "Diverse" && !disabled && onChange) {
                    const emptyValue = {
                        name: "",
                    };

                    onChange(emptyValue);
                    setPlaceholderText(undefined);
                }
            }, [disabled, placeholder]);

            useEffect(() => {
                setContactValue(value);
            }, [value]);

            return (
                <Select
                    ref={ref}
                    {...restProps}
                    disabled={disabled}
                    placeholder={placeholderText ?? contactValue?.name}
                    options={contactOptions}
                    value={contactValue?.name}
                    onDropdownVisibleChange={setIsSelectOpen}
                    open={isSelectOpen}
                    onChange={handleChange}
                    onSearch={handleSearch}
                    onClear={handleClear}
                    onKeyDown={handleKeyDown}
                    filterOption={filterOption}
                    allowClear={Boolean(!searchValue.length && contactValue?.name)}
                    showSearch
                    suffixIcon={
                        suggestCreateContext ? (
                            <Tooltip
                                title="Add Contact with this name in the Contacts module"
                                color={blue[5]}
                                placement={tooltipPlacement}
                            >
                                <Link target={"_blank"} to={`/contacts?modal=${searchValue}`} className={styles.addBtn}>
                                    <PlusOutlined />
                                </Link>
                            </Tooltip>
                        ) : null
                    }
                />
            );
        }
    )
);
