import React from "react";
import RecordFormState from "../recordform/types/RecordFormState";

export class Validator {
    private rules = new Map<React.RefObject<any>, (v: any, state: RecordFormState) => boolean>();

    public addRule = <T>(key: React.RefObject<any>, rule: (v: T, state: RecordFormState) => boolean) => {
        this.rules.set(key, rule);
    };

    /**
     * Returns rules keys that did not passed validation.
     *
     * @param fields Map<string, any>
     *
     * @returns {string[]}
     */
    public validate = (fields: Map<React.RefObject<any>, any>, state: RecordFormState): React.RefObject<any>[] => {
        const errorFields: React.RefObject<any>[] = [];
        const rules = this.rules;
        fields.forEach((value, key, map) => {
            if (!(rules.has(key) && rules.get(key)(value, state))) {
                errorFields.push(key);
            }
        });
        return errorFields;
    };
}
