import { Base, Bu, KontoNumUtils } from "@binale-tech/shared";

/**
 * M, AM - USt
 * V, AV - VSt
 */
export type CategoryBuScope = "KU" | "AV" | "AM" | "SAV" | "SAM" | "V" | "M";
export default class Category implements Omit<Base.IExtNum, "key"> {
    // key: string;
    readonly buScope: CategoryBuScope;
    readonly buAuto: Bu.Bu;
    readonly sv13b?: Bu.USt13bOption;
    readonly marked?: boolean;
    readonly FNR?: number;
    static readonly DefaultLen: number = 4;

    protected static readonly autoScopes = new Set(["KU", "AV", "AM", "SAV", "SAM"]);

    /**
     * @param {string} num
     * @param {string} name
     * @param {number | string} key
     */
    constructor(
        public readonly num: string,
        public readonly name: string,
        key?: number | string
    ) {
        // this.key = key === undefined ? null : key.toString();
    }

    static unserialize(v: Record<string, unknown> | Category): Category {
        return Object.assign(new Category(null, null, undefined), v);
    }

    fixNum(ext: number): Base.IExtNum {
        return KontoNumUtils.fixNum(this, ext, Category.DefaultLen);
    }

    getExtNum(ext: number): string {
        return KontoNumUtils.getExtNum(this.num, ext, Category.DefaultLen);
    }

    getExtNumPrint(ext: number): string {
        return KontoNumUtils.getExtNumPrint(this.num, ext, Category.DefaultLen);
    }

    equalsTo(c: Base.IExtNum): boolean {
        return KontoNumUtils.equalsTo(this, c);
    }

    getDefaultLen() {
        return Category.DefaultLen;
    }

    isAutoBu() {
        return Category.autoScopes.has(this.buScope);
    }

    getAutoBu(): Bu.Bu {
        if (this.buScope === "KU") {
            return Bu.Bu.KU;
        }
        if (this.isAutoBu()) {
            return this.buAuto;
        }
        return null;
    }

    static copyWithFixedNum(cat: Category, ext: number, overrides: Partial<Pick<Category, "num" | "name">> = {}) {
        if (!cat) {
            return undefined;
        }
        const decoded = JSON.parse(JSON.stringify(cat));
        const { num, name } = overrides;
        return Category.unserialize({ ...decoded, ...(num ? { num } : {}), ...(name ? { name } : {}) }).fixNum(
            ext
        ) as Category;
    }

    static mergeCategories(
        defaultCat: Map<number, Category>,
        categoryOverrides: Base.INumName[] = [],
        ext: number
    ): { all: Category[]; user: Category[]; allMap: Map<string, Category> } {
        const allWithOverrides = new Map<string, Category>();
        defaultCat.forEach(cat => {
            const currentMaxLenNum = KontoNumUtils.getExtNum(cat.num, ext, Category.DefaultLen);
            allWithOverrides.set(currentMaxLenNum, this.copyWithFixedNum(cat, ext));
        });
        const onlyOverrides: Category[] = [];
        categoryOverrides.forEach(({ num, name }) => {
            const shortNum = Number(num.substring(0, 4));
            const maxLenNum = KontoNumUtils.getExtNum(num, ext, Category.DefaultLen);
            if (!defaultCat.has(shortNum)) {
                return;
            }
            const defCat = defaultCat.get(shortNum);
            const category = allWithOverrides.has(maxLenNum)
                ? this.copyWithFixedNum(defCat, ext, { name })
                : this.copyWithFixedNum(defCat, ext, { num, name });
            onlyOverrides.push(category);
            allWithOverrides.set(maxLenNum, category);
        });

        const allCats = Array.from(allWithOverrides.values());
        allCats.sort((a, b) => Number(a.num) - Number(b.num));
        onlyOverrides.sort((a, b) => Number(a.num) - Number(b.num));
        return {
            all: allCats,
            user: onlyOverrides,
            allMap: allWithOverrides,
        };
        // return [allCats, onlyOverrides, allWithOverrides];
    }
}

export interface CategoryDB {
    num: string;
    Konto: number;
    Status: number;
    ZF?: number;
    HF?: number;
    BU?: number;
    Bericht?: "Bilanz" | "GuV";
    Abschlussposten?: string;
    /**
     * @deprecated in favor of `name`
     */
    Kontenbeschrifung?: string;
    name?: string;
    sv13b?: number;
}

class CategoryDiv extends Category {
    getExtNum(ext: number) {
        return "Div.";
    }

    getExtNumPrint(ext: number) {
        return "Div.";
    }
}

export const CATEGORY_EMPTY = new Category("", "");
export const CATEGORY_DIV = new CategoryDiv("Div.", "Div.");
