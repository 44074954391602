import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from "react";
import RecordFormUtils from "../utils/RecordFormUtils";
import { Button, Card, Flex, Form, Input, Segmented, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { CloseOutlined } from "@ant-design/icons";
import BinaleForm from "../../shared/form/BinaleForm";
import cn from "classnames";
import { useFormStateDetails } from "../hooks/useFormStateDetails";
import { RecordFormStateContext, RecordFormStateControlContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useFormRecordLifecycle } from "../hooks/useFormRecordLifecycle";
import RecordFormState from "../types/RecordFormState";
import { useFormConfig } from "../hooks/useFormConfig";
import { Base, GQL, Utils } from "@binale-tech/shared";
import { useFormValidators } from "../hooks/useFormValidators";
import { useFormRecordComposer } from "../hooks/useFormRecordComposer";
import { CompanyContext } from "scripts/context/CompanyContext";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { focusRef } from "../../../../scripts/infrastructure/helpers/focus";
import { FormRecordNetto } from "../inputs/FormRecordNetto";
import { SplitCardLineItemForm } from "./SplitCardLineItemForm";
import { SplitCardTable } from "./SplitCardTable";
import { useFormKeyDown } from "../hooks/useFormKeyDown";
import { useFormHandlerItemBrutto } from "@app/components/recordform/hooks/handlers/useFormHandlerItemBrutto";
import { useFormHandlerNetto } from "@app/components/recordform/hooks/handlers/useFormHandlerNetto";

export const SplitCard: React.FC = () => {
    const formConfig = useFormConfig();
    const { itemValidator, setRecordFieldErrorState } = useFormValidators();
    const { composeFormItem } = useFormRecordComposer();
    const { isSaveActive, hasCurrency, restEuro, restNetto, rest } = useFormStateDetails();
    const onKeyDown = useFormKeyDown();
    const { product } = useContext(TableViewContext);
    const { yearConfig } = useContext(CompanyContext);
    const formState = useContext(RecordFormStateContext);
    const onBruttoItemChange = useFormHandlerItemBrutto();
    const onNettoChange = useFormHandlerNetto();
    const { isNettoMode, editableRecord, recordItems, recordDate, isSplitCardOpen: show } = formState;
    const { refsHtml, isUpdating, isTemplate } = useContext(RecordFormPropsContext);
    const { setIsNettoMode, setItemValidationStates, setRecordItems, setIsSplitCardOpen } =
        useContext(RecordFormStateControlContext);
    const [tableFocusIdx, setTableFocusIdx] = useState<number | null>(null);

    const { receiveItem, resetItem } = useFormRecordLifecycle();

    useEffect(() => {
        if (show && isUpdating) {
            setRecordItems(prevState => {
                if (prevState.length === 0) {
                    const item = composeFormItem();
                    resetItem();
                    return [item];
                }
                return prevState;
            });
        }
    }, [show, isUpdating, setRecordItems, composeFormItem, resetItem]);

    useEffect(() => {
        if (!show) {
            setIsNettoMode(false);
            setTableFocusIdx(null);
        }
    }, [setIsNettoMode, show]);

    const handleOnHide = useCallback(() => {
        setIsSplitCardOpen(false);
        if (recordItems?.length > 0) {
            if (recordItems.length === 1) {
                //todo preserve selected payment?
                receiveItem(recordItems[0]);
                setRecordItems([]);
            } else {
                resetItem();
                setRecordItems(recordItems);
            }
        }
    }, [setIsSplitCardOpen, receiveItem, recordItems, resetItem, setRecordItems]);

    const onClearItem = () => {
        resetItem();
        setItemValidationStates(new Map());
        refsHtml.REF_iBRUTTO.current?.focus();
        setTableFocusIdx(null);
    };

    const onItemClick = useCallback(
        (i: number) => {
            const item = recordItems[i];
            const nettoConfig = formState.isNettoMode
                ? {
                      netto: item.getNetto(
                          {
                              year: yearConfig.year,
                              period: recordDate.period,
                              product: product.productKey() as GQL.IProductKey,
                              recordKonto: editableRecord.recordCategoryCreditor,
                          },
                          yearConfig.skr
                      ),
                  }
                : undefined;
            receiveItem(recordItems[i], { nettoConfig });
            setTableFocusIdx(i);
        },
        [
            recordItems,
            formState.isNettoMode,
            receiveItem,
            yearConfig,
            recordDate.period,
            product,
            editableRecord.recordCategoryCreditor,
        ]
    );

    const onShiftCent = useCallback(
        (index: number, isPositive: boolean) => {
            setRecordItems(prevState => {
                const newItems = [...prevState];
                newItems[index].brutto = newItems[index].brutto + (isPositive ? 1 : -1);
                return newItems;
            });
        },
        [setRecordItems]
    );

    const onItemDelete = useCallback(
        (i: number) => {
            recordItems.splice(i, 1);
            setRecordItems([...recordItems]);
            setTableFocusIdx(prevState => {
                return prevState === i ? null : prevState;
            });
        },
        [recordItems, tableFocusIdx]
    );

    const validateModalItemForm = useCallback(() => {
        const { itemBrutto, itemCategoryCreditor, itemBu, itemTag, itemText, itemText2 } = formState.editableRecordItem;
        const fieldsItem = new Map<React.RefObject<unknown>, unknown>([
            [refsHtml.REF_iCATEGORYCREDITOR, itemCategoryCreditor],
            [refsHtml.REF_iBU, itemBu],
            [refsHtml.REF_iTAG, itemTag],
            [refsHtml.REF_iTEXT, itemText],
            [refsHtml.REF_iTEXT2, itemText2],
        ]);
        if (!isTemplate) {
            fieldsItem.set(refsHtml.REF_iBRUTTO, itemBrutto);
        }
        const errorsItem = itemValidator.validate(fieldsItem, formState);
        const itemValidationStates = new Map();
        errorsItem.forEach(field => itemValidationStates.set(field, "error"));
        setItemValidationStates(new Map(itemValidationStates));
        return errorsItem.length === 0;
    }, [
        isTemplate,
        formState,
        itemValidator,
        refsHtml.REF_iBRUTTO,
        refsHtml.REF_iBU,
        refsHtml.REF_iCATEGORYCREDITOR,
        refsHtml.REF_iTAG,
        refsHtml.REF_iTEXT,
        refsHtml.REF_iTEXT2,
        setItemValidationStates,
    ]);

    const onItemAdd = () => {
        if (!validateModalItemForm()) {
            return;
        }
        setRecordFieldErrorState(refsHtml.REF_rBRUTTO, false);
        const { recordItems = [] } = formState;
        const item = composeFormItem();
        if (!Number.isFinite(tableFocusIdx)) {
            recordItems.push(item);
        } else {
            recordItems[tableFocusIdx] = item;
            setTableFocusIdx(null);
        }
        setRecordItems([...recordItems]);

        const itemOverrides: Partial<RecordFormState["editableRecordItem"]> = {};
        // save last used recordNum for item (only for items)
        if (formConfig.useItemBelegfeld1 && recordItems.length > 0) {
            itemOverrides.itemBelegfeld1 = recordItems[recordItems.length - 1].belegfeld1;
        }
        resetItem(itemOverrides);

        const { skr } = yearConfig;
        const { isNettoMode, recordDate, recordTmpNetto, editableRecord } = formState;
        const { rest, hasCurrency, restEuro } = isNettoMode
            ? RecordFormUtils.getRestNetto(
                  editableRecord,
                  recordDate,
                  skr as Base.CompanySKR,
                  recordTmpNetto,
                  product.productKey() as GQL.IProductKey,
                  recordItems
              )
            : RecordFormUtils.getRest(editableRecord, recordItems);
        if (rest === 0) {
            focusRef(refsHtml.REF_cBUCHEN);
        } else {
            const originalAmountRest = hasCurrency ? rest : undefined;
            onBruttoItemChange(restEuro, originalAmountRest);
            refsHtml.REF_iBRUTTO.current?.focus();
        }
    };

    const isSaveDanger = isSaveActive && hasCurrency && restEuro !== 0;
    const shouldSubtractCent = isSaveDanger && restEuro < 0;
    const shouldAddCent = isSaveDanger && restEuro > 0;
    const { recordCurrency } = editableRecord;
    return (
        <>
            {show && (
                <Card
                    size={"small"}
                    title={<FormattedMessage id="app.components.split.title" />}
                    extra={<Button size={"small"} shape={"circle"} icon={<CloseOutlined />} onClick={handleOnHide} />}
                    style={{
                        height: "100%",
                        position: "relative",
                    }}
                    styles={{ body: { overflowY: "auto", height: "calc(100% - 37px)", position: "relative" } }}
                >
                    <BinaleForm onSubmit={onItemAdd} onKeyDown={onKeyDown}>
                        <Flex vertical gap={8}>
                            <Space>
                                <Form.Item
                                    label={<FormattedMessage id="app.fields.totalAmount" />}
                                    style={{ width: 150 }}
                                >
                                    <Input
                                        value={Utils.CurrencyUtils.currencyFormat(editableRecord.recordBrutto)}
                                        disabled
                                        suffix={recordCurrency?.code ?? GQL.ICurrencyCode.Eur}
                                    />
                                </Form.Item>
                                <Space
                                    className={cn("BillFormModal__NettoGroup", {
                                        "BillFormModal__NettoGroup--active": isNettoMode,
                                    })}
                                >
                                    <Form.Item label=" ">
                                        <Segmented
                                            options={[
                                                { value: 0, label: "Brutto" },
                                                { value: 1, label: "Netto" },
                                            ]}
                                            value={isNettoMode ? 1 : 0}
                                            disabled={!!editableRecord.recordCurrency}
                                            onChange={value => {
                                                setIsNettoMode(Boolean(value));
                                                onNettoChange(0);
                                            }}
                                            style={{ alignSelf: "center" }}
                                        />
                                    </Form.Item>
                                    {isNettoMode && (
                                        <>
                                            {<FormRecordNetto />}
                                            <Form.Item label="Restbetrag Netto" style={{ width: 150 }}>
                                                <Input
                                                    value={Utils.CurrencyUtils.currencyFormat(restNetto)}
                                                    disabled
                                                    suffix={
                                                        recordCurrency ? recordCurrency.code : GQL.ICurrencyCode.Eur
                                                    }
                                                />
                                            </Form.Item>
                                        </>
                                    )}
                                </Space>
                                <Form.Item label={"Restbetrag" + (isNettoMode ? " Brutto" : "")} style={{ width: 150 }}>
                                    <Input
                                        value={Utils.CurrencyUtils.currencyFormat(rest)}
                                        disabled
                                        suffix={recordCurrency ? recordCurrency.code : GQL.ICurrencyCode.Eur}
                                    />
                                </Form.Item>
                            </Space>
                            <SplitCardLineItemForm onClearItem={onClearItem} />
                            <SplitCardTable
                                onItemDelete={onItemDelete}
                                onItemClick={onItemClick}
                                tableFocusIdx={tableFocusIdx}
                                onShiftCent={onShiftCent}
                                warning={isSaveDanger ? { shouldSubtractCent, shouldAddCent } : undefined}
                            />
                        </Flex>
                    </BinaleForm>
                </Card>
            )}
        </>
    );
};
