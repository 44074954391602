import React from "react";
import { AuswertungAvisViewClass } from "../../productSharedComponents/auswertung/AuswertungViews";
import { AuswertungWrapper } from "../../productSharedComponents/auswertung/AuswertungWrapper";
import { CompanyContext } from "scripts/context/CompanyContext";
import { ProductAuswertungOposAvisDeb } from "scripts/core/Product";
import { useAvisDefaultFilters } from "../../productSharedComponents/auswertung/auswertungDefaultFilters";

export const AuswertungAvisViewDeb = React.memo(function AuswertungAvisViewDeb() {
    const df = useAvisDefaultFilters();
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const product = React.useMemo(
        () => new ProductAuswertungOposAvisDeb(yearConfig.skr, companyGQL),
        [yearConfig, companyGQL]
    );
    return (
        <AuswertungWrapper Component={AuswertungAvisViewClass} defaultFilters={df} product={product} view="debAVIS" />
    );
});
