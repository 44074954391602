import { BankModal } from "@banks/components/BankModal";
import React, { useContext, useMemo } from "react";
import { TransactionEditForm } from "@banks/modules/TransactionEditModule/components/TransactionEditForm";
import { EditBankContextProvider } from "@banks/modules/TransactionEditModule/context";
import { BanksAppContext } from "@banks/scripts/context";
import { ContactsContext } from "../../../scripts/context/ContactsContext";
import { Contacts } from "@binale-tech/shared";
import { FormattedMessage } from "react-intl";

export const TransactionEditModule = () => {
    const { editTransactionData } = useContext(BanksAppContext);
    const { contacts } = useContext(ContactsContext);
    const { transaction, isModalOpen } = editTransactionData;

    const { contactId, ...reduceTransaction } = transaction || {};

    const contactName = useMemo(() => {
        const contact = (contacts || []).find(el => el.uuid === contactId);
        return contact ? Contacts.getLabelName(contact) : undefined;
    }, [contactId, contacts]);

    return (
        <EditBankContextProvider>
            <BankModal
                title={<FormattedMessage id={"app.banks.editTransaction"} />}
                open={isModalOpen}
                footer={false}
                closable={false}
                style={{ maxWidth: "95vw" }}
                width={"800px"}
                destroyOnClose
            >
                {!!transaction && (
                    <TransactionEditForm
                        transaction={{
                            ...reduceTransaction,
                            contact: {
                                id: contactId,
                                name: contactName,
                            },
                        }}
                    />
                )}
            </BankModal>
        </EditBankContextProvider>
    );
};
