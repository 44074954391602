import { Form, Typography } from "antd";
import React, { type FC, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { VatInput } from "@dms/components/DocumentForm/DocumentInputs/VatInput";
import { ICountriesOption } from "../../../../appearance/views/productContacts/components/CountriesCombobox";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";
import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";

interface IProps {
    options: ICountriesOption[];
    disabled?: boolean;
    isFocus?: boolean;
}
export const SingleVatField: FC<IProps> = ({ options }) => {
    const { isReadOnly } = useContext(SingleFormContext);
    return (
        <Form.Item
            label={
                <Typography.Text strong ellipsis>
                    <FormattedMessage id="app.fields.vatNb" />
                </Typography.Text>
            }
            name="UStIdNr"
            style={{ marginBottom: 0 }}
        >
            <VatInput options={options} disabled={isReadOnly} />
        </Form.Item>
    );
};

export const GroupVatField: FC<IProps> = ({ options, disabled }) => {
    const { notEqualField, requiredFields } = useContext(GroupFormContext);

    const placeholder = notEqualField?.includes("discountDays") ? "Diverse" : undefined;

    return (
        <Form.Item
            label={
                <Typography.Text strong ellipsis>
                    <FormattedMessage id="app.fields.vatNb" />
                </Typography.Text>
            }
            name="UStIdNr"
            rules={[{ required: requiredFields?.includes("UStIdNr"), message: "" }]}
            style={{ marginBottom: 0 }}
        >
            <VatInput options={options} disabled={disabled} placeholder={placeholder} />
        </Form.Item>
    );
};

export const DocumentVatField: FC<IProps> = ({ options, disabled }) => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return isGroupEditor ? (
        <GroupVatField options={options} disabled={disabled} />
    ) : (
        <SingleVatField options={options} />
    );
};
