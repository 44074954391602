import React from "react";
import { RefSelectProps, Select, SelectProps } from "antd";

import { Contacts, GQL } from "@binale-tech/shared";

const { Option } = Select;

interface Props extends SelectProps<GQL.IGender> {
    value?: GQL.IGender;
    onChange?: (value: GQL.IGender) => void;
}

export const AppealCombobox = React.forwardRef<RefSelectProps, Props>(function AppealCombobox(props, ref) {
    return (
        <Select allowClear getPopupContainer={trigger => trigger.parentNode} {...props} ref={ref}>
            <Option value={GQL.IGender.Male}>{Contacts.ContactConstants.GenderTranslate[GQL.IGender.Male]}</Option>
            <Option value={GQL.IGender.Female}>{Contacts.ContactConstants.GenderTranslate[GQL.IGender.Female]}</Option>
        </Select>
    );
});
