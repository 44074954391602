import { IntlShape } from "react-intl";
import { ColDef } from "ag-grid-community";
import { DmsTableCols, TranslateDmsTableColumns } from "@dms/modules/DocumentTableModule/consts";
import { RequiredTableCols } from "@app/components/shared/AgGridTable/constants/tableCols";
import styles from "@dms/modules/DocumentTableModule/DocumentTable.module.scss";

export class AbstractTableColumns {
    protected constructor() {}

    static intl: IntlShape;

    static set setIntl(intl: IntlShape) {
        this.intl = intl;
    }

    static get rowNumberColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[RequiredTableCols.ROW_NUMBER] }),
            field: RequiredTableCols.ROW_NUMBER,
            valueGetter: params => params.node.rowIndex + 1,
            width: 60,
            resizable: false,
            filter: false,
            suppressHeaderMenuButton: true,
            suppressMovable: true,
            lockPosition: true,
            suppressNavigable: true,
        };
    }

    static get actions(): Readonly<ColDef> {
        return {
            headerComponent: "headerActions",
            field: DmsTableCols.ACTIONS,
            floatingFilter: false,
            resizable: false,
            suppressMovable: true,
            suppressNavigable: true,
            width: 180,
            pinned: "right",
            sortable: false,
            lockPosition: true,
            cellClass: [],
        };
    }
}
