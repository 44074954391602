import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactNode, useContext, useMemo } from "react";
import { useFormConfig } from "./useFormConfig";
import { CategoryCreditorModes } from "../../../../scripts/core/Product";
import { BuTaxesSKR } from "../../../../scripts/models/BuTaxUtils";
import { NumNameInfoBlock } from "../../shared/form/FormBlocks";
import { RecordFormStateContext } from "../context/RecordFormState";
import { useProgramSettings } from "./useProgramSettings";
import { BuContext } from "../../../../scripts/context/BuContext";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { Base } from "@binale-tech/shared";

export const useFormInfoBlock = () => {
    const intl = useIntl();
    const formConfig = useFormConfig();
    const programSettings = useProgramSettings();
    const { yearConfig } = useContext(CompanyContext);
    const { companyBuTimeframes } = React.useContext(BuContext);
    const { recordDate, editableRecord, editableRecordItem } = useContext(RecordFormStateContext);

    const recordCCLabel = formConfig.labels?.recordCategoryCreditor || intl.formatMessage({ id: "app.fields.konto" });
    const itemCCLabel = intl.formatMessage({ id: "app.fields.gkonto" });

    const { itemCategoryCreditor, itemBu, itemTag } = editableRecordItem;
    const { recordCategoryCreditor } = editableRecord;
    const year = recordDate.date?.getFullYear() ?? yearConfig.year;
    const period = recordDate.period ?? 1;

    const numNameItems = useMemo(() => {
        const items: [ReactNode, Base.IExtNum | string, number | undefined][] = [
            [itemCCLabel, itemCategoryCreditor, 2],
        ];
        const displayRCC =
            formConfig.recordAccountMode && formConfig.recordAccountMode !== CategoryCreditorModes.RESOLVER;
        if (displayRCC) {
            items.unshift([recordCCLabel, recordCategoryCreditor, 2]);
        }

        items.push([
            <FormattedMessage id="app.fields.vat" key={items.length} />,
            BuTaxesSKR.getBuTaxYearPeriod(itemBu, yearConfig?.skr, year, period, companyBuTimeframes).description,
            undefined,
        ]);
        if (programSettings.useKs) {
            items.push([<FormattedMessage id="app.titles.tag" key={items.length} />, itemTag, undefined]);
        }
        return items;
    }, [
        companyBuTimeframes,
        year,
        period,
        formConfig.recordAccountMode,
        itemBu,
        itemCCLabel,
        itemCategoryCreditor,
        itemTag,
        programSettings.useKs,
        recordCCLabel,
        recordCategoryCreditor,
        yearConfig?.skr,
    ]);

    return useMemo(
        () => ({
            recordCCLabel,
            itemCCLabel,
            infoBlock: <NumNameInfoBlock numNameItems={numNameItems as any} />,
        }),
        [recordCCLabel, itemCCLabel, numNameItems]
    );
};
