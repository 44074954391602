import { useCallback, useContext } from "react";
import { GenericRecord } from "../../../../../scripts/models";
import { PaymentPrototype } from "../../../../../scripts/models/Payment";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { useUpdatesOnPaymentConnection } from "../useFormIntegrations";
import { RecordFormStateControlContext } from "@app/components/recordform/context/RecordFormState";
import { useFormRecordStateUpdater } from "@app/components/recordform/hooks/useFormRecordStateUpdater";

export const useFormHandlerConnectedPayment = () => {
    const { refsData } = useContext(RecordFormPropsContext);
    const { setSelectedPayment } = useContext(RecordFormStateControlContext);
    const getUpdatesOnPaymentConnection = useUpdatesOnPaymentConnection();
    const { updateEditableRecord, updateEditableItem } = useFormRecordStateUpdater();

    return useCallback(
        (connection?: { paymentSourceRecord: GenericRecord; payment: PaymentPrototype }) => {
            const payment = connection?.payment;
            const paymentSourceRecord = connection?.paymentSourceRecord;
            const { editableRecord, editableRecordItem } = refsData.formStateRef.current;
            const { updatesRecord, updatesRecordItem } = getUpdatesOnPaymentConnection(
                editableRecord,
                editableRecordItem,
                paymentSourceRecord
            );
            setSelectedPayment(payment);
            updateEditableRecord(updatesRecord);
            updateEditableItem(updatesRecordItem);
        },
        [getUpdatesOnPaymentConnection, setSelectedPayment, updateEditableItem, updateEditableRecord, refsData]
    );
};
