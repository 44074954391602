import React, { FC } from "react";
import { KontoNumUtils } from "@binale-tech/shared";

import { DebitorCreditorForm, ICommonFormProps } from "../DebitorCreditorForm";

const DebitorForm: FC<ICommonFormProps> = props => {
    const getBounds = { min: KontoNumUtils.DEB_MIN, max: KontoNumUtils.DEB_MAX };
    return <DebitorCreditorForm bounds={getBounds} {...props} />;
};
export default React.memo(DebitorForm);
