import React, { FC } from "react";

import { Button, Flex } from "antd";
import { PageHeader } from "@inv/components/PageHeader/PageHeader";
import CreateInvoiceModule from "@inv/modules/InvoiceFormModule/CreateInvoiceModule";
import { AppRoutes } from "../../../scripts/routing/routeConstants";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export const CreateInvoicingLayout: FC = () => {
    return (
        <Flex
            vertical
            style={{
                margin: "0 auto",
                maxWidth: 1366,
                height: "100%",
            }}
        >
            <PageHeader
                title={<FormattedMessage id={"app.invoice.createInvoice"} />}
                button={
                    <Link to={AppRoutes.invoices}>
                        <Button type={"text"} icon={<ArrowLeftOutlined />}>
                            <FormattedMessage id={"app.invoice.invoicesList"} />
                        </Button>
                    </Link>
                }
            />
            <CreateInvoiceModule />
        </Flex>
    );
};
