import React from "react";
import { DocumentNode, useApolloClient } from "@apollo/client";
import { FetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { GQL } from "@binale-tech/shared";
import { message } from "antd";

interface HookOpts {
    query: DocumentNode;
    id: string;
    fetchPolicy?: FetchPolicy;
}

export const useGQLRetriever = <T extends keyof GQL.IQuery>() => {
    const client = useApolloClient();

    return React.useMemo(
        () => ({
            query: async ({ query, id, fetchPolicy = "network-only" }: HookOpts) => {
                try {
                    const data = await client.query<Pick<GQL.IQuery, T>>({
                        query,
                        fetchPolicy,
                        variables: { id },
                    });
                    return data.data;
                } catch (e) {
                    message.error(e.message);
                }
                return null;
            },
        }),
        [client]
    );
};
