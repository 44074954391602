import React, { useContext } from "react";

import { GenericRecord } from "scripts/models/GenericRecord";
import { PaymentBindingUtils } from "scripts/models/utils/PaymentBindingUtils";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { PaymentsContext } from "scripts/context/PaymentsProvider";

interface Opts {
    paymentRepresentationRecord: GenericRecord;
    useInvoiceNum?: boolean;
}

export const useGetMatchingPaymentSourceRecords = ({ paymentRepresentationRecord, useInvoiceNum }: Opts) => {
    const { recordsDeb, recordsER, recordsAZ } = React.useContext(RecordsContext);
    const payments = useContext(PaymentsContext);

    return PaymentBindingUtils.getMatchingPaymentSourceRecords({
        paymentRepresentationRecord,
        payments,
        recordsDeb,
        recordsER,
        recordsAZ,
        useInvoiceNum,
    });
};
