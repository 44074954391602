import React, { FC } from "react";
import { LAProvider } from "../LAProvider";
import { TemplatesViewContent } from "@app/views/productSharedComponents/templates/TemplatesViewContent";

export const LATemplatesView: FC = () => {
    return (
        <LAProvider>
            <TemplatesViewContent />
        </LAProvider>
    );
};
