import React, { useEffect, useState } from "react";
import { Button, Popover, Space, Tooltip } from "antd";
import { CurrencyValue } from "@ui-components/AmountInput/BruttoInput";
import { EditOutlined } from "@ant-design/icons";
import { GQL, Utils } from "@binale-tech/shared";

import BinaleForm from "../../BinaleForm";
import { focusFirst } from "scripts/infrastructure/helpers/focus";
import { CurrencyInputBlock } from "@ui-components/AmountInput/CurrencyInputBlock";

interface CurrencyProps extends Omit<React.ComponentProps<typeof CurrencyInputBlock>, "onEnter"> {
    showPopover: boolean;
    onVisibleChange: (visible: boolean) => void;
    recordDate?: Date;
}
const formatDefault = (v: number, precision = Utils.CurrencyUtils.CurrencyFormatter.PRECISION) =>
    Utils.CurrencyUtils.currencyFormat(v || 0, precision);
export const CurrencyPopover: React.FC<CurrencyProps> = props => {
    const { showPopover, onVisibleChange, currencyValue, onChange, allowConfigEdit } = props;

    const hasCurrency = Boolean(currencyValue.currency);
    const [innerValue, setInnerValue] = useState<CurrencyValue>(currencyValue);

    useEffect(() => {
        setInnerValue(currencyValue);
    }, [currencyValue]);

    useEffect(() => {
        if (showPopover) {
            setTimeout(() => {
                const popoverEl = document.querySelector(".CurrencyPopover");
                if (popoverEl) {
                    focusFirst(popoverEl as HTMLElement);
                }
            }, 100);
        }
    }, [showPopover]);

    const link = (
        <a
            href="/"
            style={{ padding: "0 2px" }}
            className="CurrencyPopover__link"
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {hasCurrency ? <EditOutlined /> : <small>EUR</small>}
        </a>
    );

    const popoverChildren = showPopover ? (
        link
    ) : (
        <Tooltip
            destroyTooltipOnHide
            title={
                <div>
                    {hasCurrency ? formatDefault(innerValue.originalAmount) : formatDefault(innerValue.amount)}
                    &nbsp;
                    {hasCurrency ? currencyValue.currency.code : GQL.ICurrencyCode.Eur}
                </div>
            }
        >
            {link}
        </Tooltip>
    );

    const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === "Escape") {
            onVisibleChange(false);
            e.preventDefault();
            e.stopPropagation();
        }
    };

    return (
        <Popover
            placement="top"
            trigger="click"
            open={showPopover}
            destroyTooltipOnHide
            onOpenChange={onVisibleChange}
            content={
                <BinaleForm
                    onKeyUp={handleKeyUp}
                    className="ContactForm__condensed Popover"
                    onSubmit={() => {
                        onChange(innerValue);
                        onVisibleChange(false);
                    }}
                >
                    <Space.Compact>
                        <CurrencyInputBlock
                            currencyValue={innerValue}
                            allowConfigEdit={allowConfigEdit}
                            onChange={setInnerValue}
                            recordDate={props.recordDate}
                        />
                        <Button type="primary" data-testid="submit-btn" htmlType="submit">
                            Ok
                        </Button>
                    </Space.Compact>
                </BinaleForm>
            }
        >
            {popoverChildren}
        </Popover>
    );
};
