import React, { FC } from "react";
import { Card, Space } from "antd";
import { Link } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { useIubendaCookieSolution } from "@app/views/auth/useIubendaCookieSolution";

interface Props {
    title?: React.ReactNode;
    width?: number;
}

export const AuthLayout: FC<React.PropsWithChildren<Props>> = ({ title, children, width }) => {
    useIubendaCookieSolution();
    return (
        <div className="auth__container">
            <div className="auth__background_image" />
            <div className="auth__container__card">
                <Card title={title} style={{ width: width ?? 500 }}>
                    {children}
                </Card>
            </div>
            <Space className="auth__legal_links">
                <Link to={AppRoutes.impressum}>Impressum</Link>
                <Link to={AppRoutes.haftungsausschluss}>Haftungsausschluss</Link>
                <Link to={AppRoutes.agb}>AGB</Link>
                <Link to="https://www.iubenda.com/privacy-policy/52953099" target="_blank" title="Datenschutzerklärung">
                    Datenschutzerklärung
                </Link>
                <Link
                    to="https://www.iubenda.com/privacy-policy/52953099/cookie-policy"
                    target="_blank"
                    title="Cookie-Richtlinie"
                >
                    Cookie-Richtlinie
                </Link>
                <a href="#" className="iubenda-cs-preferences-link">
                    Ihre Privatsphäre
                </a>
            </Space>
        </div>
    );
};
