import React, { FC } from "react";
import Table, { ColumnProps, TablePaginationConfig } from "antd/es/table";
import dayjs from "dayjs";
import { Col, Row } from "antd";
import { Filters } from "./components/Filters";
import { CompanyMetadata, IViewProps } from "../../types";
import { SplitModeNames } from "../../contants";

import "./styles.css";

const sortString = (a: string | undefined, b: string | undefined) => {
    if (!a && b) {
        return 1;
    }
    if (!b && a) {
        return -1;
    }
    if (!a || !b) {
        return 0;
    }
    return a < b ? -1 : a > b ? 1 : 0;
};

export const PdfStats: FC<IViewProps> = ({ data, loading, filters, onChangeFilter, onDownloadCsv }) => {
    const columns: ColumnProps<CompanyMetadata>[] = [
        {
            title: "Nr.",
            render: (text, record, index) => index + 1,
            key: "index",
        },
        {
            title: "Datum",
            key: "dt",
            render: (text, record) => dayjs(new Date(record.dt)).format("DD.MM.YYYY"),
            sorter: (a, b) => a.dt - b.dt,
            defaultSortOrder: "descend",
        },
        {
            title: "Seitenanzahl",
            dataIndex: "pages",
            sorter: (a, b) => a.pages - b.pages,
        },
        {
            title: "Modul",
            key: "mode",
            render: (text, record) => SplitModeNames[record.mode] || "",
            sorter: (a, b) => {
                const amode = a.mode ?? 100;
                const bmode = b.mode ?? 100;
                return bmode - amode;
            },
        },
        {
            title: "Benutzer",
            dataIndex: "userEmail",
            sorter: (a, b) => sortString(a.userEmail, b.userEmail),
        },
        {
            title: "Unternehmen",
            dataIndex: "companyName",
            sorter: (a, b) => sortString(a.companyName, b.companyName),
        },
    ];

    const pagination: TablePaginationConfig = {
        defaultPageSize: 20,
        showSizeChanger: true,
    };

    const allPages = data.reduce((acc, doc) => acc + doc.pages, 0);

    return (
        <div className="pdf-tools-FullScreenPage">
            <h3>Ocr Stats</h3>
            <Table
                className="pdf-tools-Statistic_table"
                columns={columns}
                dataSource={data}
                size="small"
                pagination={pagination}
                loading={loading}
                title={() => (
                    <Filters onChangeFilter={onChangeFilter} filters={filters} onDownloadCsv={onDownloadCsv} />
                )}
                footer={viewData =>
                    viewData.length > 0 && (
                        <Row gutter={16}>
                            <Col>
                                <b>Total:</b> {data.length}
                            </Col>
                            <Col>
                                <b>Seitenanzahl:</b> {allPages}
                            </Col>
                        </Row>
                    )
                }
            />
        </div>
    );
};
