import React, { FC, useContext } from "react";
import { FormattedMessage } from "react-intl";

import styles from "./ModalTitle.module.css";
import { ReadinessContext } from "scripts/context/DataReadyness";

export const ModalTitle: FC = () => {
    const { isConnected } = useContext(ReadinessContext);
    return (
        <div className={styles.titleContainer}>
            <span className={styles.title}>
                <FormattedMessage id="app.dms.add_documents" />
            </span>
            {!isConnected && (
                <span className={styles.stateConnection}>
                    <FormattedMessage id="app.dms.noInternetConnection" />
                </span>
            )}
        </div>
    );
};
