import React from "react";
import { AutoComplete, InputRef, Tooltip } from "antd";
import { Contacts } from "@binale-tech/shared";
import { DefaultOptionType } from "rc-select/lib/Select";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";

type TValue = {
    partnerName?: string;
    partnerId?: string;
};

type Props = {
    hasContactsWriteAccess: boolean;
    contacts?: Contacts.Contact[];
    placeholder?: React.ReactNode;
    tooltipPlacement?: React.ComponentProps<typeof Tooltip>["placement"];
    onChange: (v: TValue) => void;
    value: TValue;
    disabled?: boolean;
    ref?: Pick<HTMLButtonElement, "focus">;
};
export const ContactsAutocomplete = React.forwardRef<InputRef, Props>(
    ({ hasContactsWriteAccess, contacts, placeholder, onChange, value, tooltipPlacement = "top", disabled }, ref) => {
        const suggestCreateContect =
            !disabled && hasContactsWriteAccess && !value?.partnerId && value?.partnerName?.trim().length;

        const { contactMap, contactOptions } = React.useMemo(() => {
            const contactMap = new Map<string, Contacts.Contact>();
            const contactOptions = (contacts || []).map((item: Contacts.Contact) => {
                contactMap.set(String(item.uuid), item);
                return { label: Contacts.getLabelName(item), value: item.uuid };
            });
            return { contactMap, contactOptions };
        }, [contacts]);

        return (
            <div>
                <AutoComplete
                    ref={ref as never}
                    disabled={disabled}
                    placeholder={placeholder}
                    options={contactOptions}
                    onChange={(value, option) => {
                        const contact = option as DefaultOptionType;
                        onChange({
                            partnerName: (contact.label ?? value) as string,
                            partnerId: (contact.value ?? undefined) as string,
                        });
                    }}
                    value={value?.partnerName}
                    filterOption={(inputValue, option) => {
                        if (!option) {
                            return false;
                        }
                        const item = contactMap.get(String(option.value));
                        if (!item) {
                            return false;
                        }
                        const term = inputValue.toLowerCase();
                        const label = Contacts.getLabelName(item)?.toLowerCase() ?? "";

                        const internalName = item.internalName.toLowerCase() ?? "";

                        return Boolean(internalName.includes(term) || label.includes(term));
                    }}
                />
                {suggestCreateContect && (
                    <Tooltip
                        title="You can save Partner Name to the Contacts module"
                        color={blue[5]}
                        placement={tooltipPlacement}
                    >
                        <div style={{ position: "absolute", right: 5, top: 5, color: blue[5] }}>
                            <Link target={"_blank"} to={`/contacts?modal=${value?.partnerName}`}>
                                <PlusOutlined />
                            </Link>
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    }
);
