import { ProductAccessUtils } from "../../../../scripts/models/utils/ProductAccessUtils";
import { ContactInputBlock } from "../../shared/form/FormBlocks/ContactInputBlock";
import React, { FC, useContext, useEffect, useState } from "react";
import { useFormConfig } from "../hooks/useFormConfig";
import { useProgramSettings } from "../hooks/useProgramSettings";
import { GQL } from "@binale-tech/shared";
import { CompanyContext } from "scripts/context/CompanyContext";
import { RecordFormStateContext, RecordFormStateControlContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useFormProperties } from "../hooks/useFormProperties";
import { useFormHandlerRecordContact } from "../hooks/handlers/useFormHandlerRecordContact";
import { Button, Modal } from "antd";
import { ThunderboltOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { TemplatesTable } from "@app/views/productSharedComponents/templates/TemplatesTable";
import { GenericRecord } from "../../../../scripts/models";
import { useFormHandlerRecordTemplate } from "@app/components/recordform/hooks/handlers/useFormHandlerRecordTemplate";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";

export const FormRecordContact: FC = () => {
    const formConfig = useFormConfig();
    const programSettings = useProgramSettings();
    const { isContactFieldDisabled } = useFormProperties();
    const { companyGQL } = useContext(CompanyContext);
    const { moduleTemplates } = useContext(TableViewContext);
    const { isUpdating, refsHtml, isTemplate } = useContext(RecordFormPropsContext);
    const { editableRecord, recordValidationStates } = useContext(RecordFormStateContext);
    const { setIsModalTemplateOpen } = useContext(RecordFormStateControlContext);
    const onRecordContactChange = useFormHandlerRecordContact();

    if (!formConfig.useContact) {
        return null;
    }
    if (programSettings.hideContacts) {
        return null;
    }
    const hasContactsAccess = ProductAccessUtils.hasCompanyProductAccess(GQL.IProductKey.Contacts, companyGQL);
    if (isUpdating && !hasContactsAccess) {
        return null;
    }
    const validation = recordValidationStates.get(refsHtml.REF_rCONTACT);
    const contactTemplates =
        editableRecord.recordContact && !isTemplate
            ? moduleTemplates.filter(({ partner }) => partner.id === editableRecord.recordContact.id)
            : [];

    return (
        <>
            <ContactInputBlock
                labelProps={{
                    fieldError: validation ? { type: "error", message: " " } : undefined,
                    labelAddon: isTemplate ? undefined : (
                        <Button
                            shape={"circle"}
                            icon={<ThunderboltOutlined />}
                            size={"small"}
                            disabled={contactTemplates.length === 0}
                            onClick={() => setIsModalTemplateOpen(true)}
                            style={{
                                minWidth: 18,
                                width: 18,
                                height: 18,
                            }}
                        />
                    ),
                }}
                ref={refsHtml.REF_rCONTACT}
                value={editableRecord.recordContact}
                onChange={onRecordContactChange}
                disabled={isContactFieldDisabled}
            />
            <TemplateModal templates={contactTemplates} />
        </>
    );
};

const TemplateModal: FC<{ templates: GenericRecord[] }> = ({ templates }) => {
    const [selected, setSelected] = useState<GenericRecord>();
    const { isModalTemplateOpen } = useContext(RecordFormStateContext);
    const { setIsModalTemplateOpen } = useContext(RecordFormStateControlContext);
    const onTemplateChange = useFormHandlerRecordTemplate();
    useEffect(() => {
        if (!isModalTemplateOpen) {
            setSelected(undefined);
        }
    }, [isModalTemplateOpen]);
    return (
        <Modal
            width={"90vw"}
            style={{ top: 20 }}
            styles={{ body: { height: "60vh", position: "relative" } }}
            title={<FormattedMessage id="app.titles.templates" />}
            open={isModalTemplateOpen}
            onCancel={() => setIsModalTemplateOpen(false)}
            onOk={() => {
                onTemplateChange(selected);
                setIsModalTemplateOpen(false);
            }}
            cancelText={null}
            okButtonProps={{ disabled: !selected }}
        >
            {isModalTemplateOpen && <TemplatesTable templates={templates} onSelect={setSelected} />}
        </Modal>
    );
};
