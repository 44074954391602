import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Drag } from "@dms/components/DragAndDrop/Drag";
import { HolderOutlined } from "@ant-design/icons";

export const DragComponent = ({ data }: ICellRendererParams) => {
    if (!data) {
        return null;
    }
    return (
        <Drag id={data.key} typeDrag={"list"}>
            <HolderOutlined />
        </Drag>
    );
};
