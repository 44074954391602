import React, { type FC, useEffect, useRef } from "react";
import { Col, Row, Space } from "antd";
import { PickerRef } from "rc-picker";

import { DocumentContactField } from "../DocumentFormFields/DocumentContactField";
import { DocumentDateField } from "../DocumentFormFields/DocumentDateField";
import { DocumentDescriptionField } from "../DocumentFormFields/DocumentDescriptionField";
import { DocumentInvoiceCurrencyField } from "../DocumentFormFields/DocumentInvoiceCurrencyField";
import { DocumentInvoiceNumberField } from "../DocumentFormFields/DocumentInvoiceNumberField";
import { DocumentNotesField } from "../DocumentFormFields/DocumentNotesField";
import { DocumentTypeSelectField } from "../DocumentFormFields/DocumentTypeSelectField";
import { FormDivider } from "../../Divider/FormDivider";
import { DocumentInterneNumberField } from "../DocumentFormFields/DocumentInterneNumberField";
import { VatNumberInputs } from "@dms/components/DocumentForm/DocumentInputSets/VatNumberInputs";

export const OtherInputs: FC = () => {
    const dateFieldRef = useRef<PickerRef>(null);

    useEffect(() => {
        if (dateFieldRef.current) {
            dateFieldRef.current.focus();
        }
    }, []);

    return (
        <Space size={"middle"} direction={"vertical"} style={{ width: "100%" }}>
            <DocumentTypeSelectField />
            <FormDivider />
            <Row>
                <Col span={12}>
                    <DocumentDateField ref={dateFieldRef} />
                </Col>
                <Col span={12}>
                    <div style={{ marginLeft: 20 }}>
                        <DocumentInvoiceNumberField />
                    </div>
                </Col>
            </Row>

            <DocumentContactField />

            <Row>
                <Col span={24}>
                    <DocumentInvoiceCurrencyField />
                </Col>
            </Row>
            <VatNumberInputs />

            <DocumentInterneNumberField />
            <DocumentDescriptionField />
            <DocumentNotesField />
        </Space>
    );
};
