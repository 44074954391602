import React, { FC, useContext } from "react";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";

import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { RecordFormStateContext } from "../context/RecordFormState";
import { BruttoInputBlock } from "../../shared/form/FormBlocks";
import { focusNextFieldFromRef } from "../../../../scripts/infrastructure/helpers/focus";
import { useFormHandlerNetto } from "../hooks/handlers/useFormHandlerNetto";

export const FormRecordNetto: FC = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const isDisabled = useFormIsRefDisabled();
    const { recordTmpNetto, editableRecord, recordValidationStates } = useContext(RecordFormStateContext);
    const onNettoChange = useFormHandlerNetto();

    const { recordCurrency } = editableRecord;

    const validation = recordValidationStates.get(refsHtml.REF_rNETTO);

    const hasCurrency = Boolean(recordCurrency);
    return (
        <BruttoInputBlock
            labelProps={{
                label: "Gesamt Netto" + (hasCurrency ? ` (${recordCurrency.code})` : ""),
                fieldError: validation ? { type: "error", message: " " } : undefined,
            }}
            ref={refsHtml.REF_rNETTO}
            value={recordTmpNetto}
            onChange={({ amount }) => onNettoChange(amount)}
            disabled={isDisabled(refsHtml.REF_rNETTO)}
            focusNextElement={() => focusNextFieldFromRef(refsHtml.REF_rNETTO)}
        />
    );
};
