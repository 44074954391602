import CompanyQueriesGraphql from "./companyQueries.graphql";
import React, { useEffect, useState } from "react";
import { Button, Card, Divider, Drawer, Flex, Form, Input, Row, Select, Space, Spin, Tooltip } from "antd";
import { CountriesCombobox, ICountriesOption } from "@app/views/productContacts/components/CountriesCombobox";
import { FormattedMessage, useIntl } from "react-intl";
import { Contacts, GQL } from "@binale-tech/shared";
import { UserContext } from "scripts/context/UserProvider";
import { getNames } from "i18n-iso-countries";
import { useGQLRetriever } from "../../../scripts/graphql/gqlRetriever";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { validateStnrQuery, validation } from "../../../scripts/infrastructure/helpers/validation";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useApolloClient } from "@apollo/client";
import { useEuroCountryArray } from "../../../scripts/infrastructure/hooks/useEuroCountryArray";
import { RegisterTypeCombobox } from "@app/views/productContacts/components/RegisterTypeCombobox";
import { RegisterCourtCombobox } from "@app/views/productContacts/components/RegisterCourtCombobox";
import { BaseInput, InputIBAN, InputIBANLabelAddon } from "@app/components/shared/form/baseComponents";
import { InfoCircleOutlined } from "@ant-design/icons";

type InvoiceFormValues = {
    invoiceEmail: string;
    invoiceName: string;
    invoiceAddress: GQL.IContactAddress;
    invoiceTax: GQL.ICompanyTax;
    invoiceVat: GQL.ICompanyVat;
    invoiceRegister: GQL.ICompanyRegister;
    invoiceReceiver: string;
    IBAN: string;
    director: string;
};
type Props = {
    company: GQL.ICompany;
    onComplete: (v: GQL.ICompany) => void;
};

export const GermanStates: DefaultOptionType[] = [
    { label: "", value: null },
    { label: "Baden-Württemberg", value: "bw" },
    { label: "Bayern", value: "by" },
    { label: "Berlin", value: "be" },
    { label: "Brandenburg", value: "bb" },
    { label: "Bremen", value: "hb" },
    { label: "Hamburg", value: "hh" },
    { label: "Hessen", value: "he" },
    { label: "Mecklenburg-Vorpommern", value: "mv" },
    { label: "Niedersachsen", value: "nd" },
    { label: "Nordrhein-Westfalen", value: "nw" },
    { label: "Rheinland-Pfalz", value: "rp" },
    { label: "Saarland", value: "sl" },
    { label: "Sachsen", value: "sn" },
    { label: "Sachsen-Anhalt", value: "st" },
    { label: "Schleswig-Holstein", value: "sh" },
    { label: "Thüringen", value: "th" },
];

export const CompanyInvoiceData: React.FC<Props> = ({ onComplete, company }) => {
    const intl = useIntl();
    const client = useApolloClient();
    const { fireUser } = React.useContext(UserContext);
    const [form] = Form.useForm<InvoiceFormValues>();
    const Mutator = useGqlMutator();
    const euroCountriesArray = useEuroCountryArray();
    const bundesland = Form.useWatch(["invoiceTax", "bundesland"], form);
    const stnr = Form.useWatch(["invoiceTax", "stnr"], form);
    const euLand = Form.useWatch(["invoiceVat", "countryCode"], form);
    const vatNumber = Form.useWatch(["invoiceVat", "vatNumber"], form);
    const iban = Form.useWatch("IBAN", form);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (!bundesland && stnr) {
            form.setFieldValue(["invoiceTax", "stnr"], null);
        }
        form.validateFields([["invoiceTax", "stnr"]]);
    }, [form, bundesland, stnr]);

    useEffect(() => {
        if (!euLand && vatNumber) {
            form.setFieldValue(["invoiceVat", "vatNumber"], null);
        }
        form.validateFields([["invoiceVat", "vatNumber"]]);
    }, [form, euLand, vatNumber]);

    const dataValue: ICountriesOption[] = React.useMemo(() => {
        return Object.entries(getNames(intl.locale, { select: "official" })).map(([code, countryName]) => ({
            value: code,
            name: countryName,
        }));
    }, [intl.locale]);
    const handleSubmit = async (values: InvoiceFormValues) => {
        const {
            invoiceEmail,
            invoiceName,
            invoiceAddress,
            invoiceReceiver,
            invoiceTax,
            invoiceVat,
            invoiceRegister,
            IBAN,
            director,
        } = values;

        setLoading(true);
        await Mutator.mutate<"companySetInvoiceData", GQL.ICompanySetInvoiceInput>({
            mutation: CompanyQueriesGraphql.mutationSetInvoiceData,
            input: {
                id: company.id,
                email: invoiceEmail,
                name: invoiceName,
                address: invoiceAddress,
                receiver: invoiceReceiver,
                tax: invoiceTax?.bundesland ? invoiceTax : undefined,
                vat: invoiceVat?.countryCode ? invoiceVat : undefined,
                register: invoiceRegister?.type && invoiceRegister?.number ? invoiceRegister : undefined,
                IBAN,
                director,
            },
        })
            .then(res => {
                form.resetFields();
                onComplete(res.companySetInvoiceData);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const initialValues: InvoiceFormValues = {
        invoiceEmail: company?.invoiceEmail || fireUser.email,
        invoiceName: company.invoiceName,
        invoiceAddress: company?.invoiceAddress || { countryCode: "DE" },
        invoiceTax: company?.invoiceTax,
        invoiceVat: company?.invoiceVat,
        invoiceRegister: company?.invoiceRegister,
        invoiceReceiver: company?.invoiceReceiver,
        IBAN: company?.IBAN,
        director: company?.director,
    };
    return (
        <Spin spinning={loading}>
            <Form onFinish={handleSubmit} layout="vertical" form={form} initialValues={initialValues}>
                <Flex gap="middle" vertical>
                    <Row>
                        <Space>
                            <Form.Item
                                label={"Rechnung Email"}
                                name="invoiceEmail"
                                rules={[validation.required(intl), validation.max(intl, 200)]}
                                style={{ width: 200 }}
                            >
                                <Input maxLength={200} type="email" autoComplete="off" />
                            </Form.Item>
                            <Form.Item
                                label={"zu Händen von (optional)"}
                                name="invoiceName"
                                rules={[validation.max(intl, 200)]}
                                style={{ width: 200 }}
                            >
                                <Input maxLength={200} />
                            </Form.Item>
                            <Form.Item
                                label={"Rechnungsempfänger"}
                                name="invoiceReceiver"
                                rules={[validation.required(intl), validation.max(intl, 200)]}
                                style={{ width: 200 }}
                            >
                                <Input maxLength={200} />
                            </Form.Item>
                            {!["Einzelunternehmen", "Freiberufler"].includes(company?.legalForm) && (
                                <Form.Item
                                    label={"Geschäftsführer"}
                                    name="director"
                                    rules={[validation.max(intl, 200), validation.required(intl)]}
                                    style={{ width: 200 }}
                                >
                                    <Input maxLength={200} />
                                </Form.Item>
                            )}
                        </Space>
                    </Row>
                    <Row>
                        <Form.Item
                            validateDebounce={200}
                            label={
                                <Space>
                                    IBAN <InputIBANLabelAddon value={iban} />
                                </Space>
                            }
                            name={"IBAN"}
                            rules={[validation.iban(client)]}
                            hasFeedback
                        >
                            <InputIBAN style={{ minWidth: 320 }} />
                        </Form.Item>
                    </Row>

                    <Divider orientation={"left"}>
                        <FormattedMessage id="app.fields.address" />
                    </Divider>

                    <Flex gap={"middle"}>
                        <Form.Item
                            label={<FormattedMessage id="app.fields.countryCode" />}
                            rules={[validation.required(intl)]}
                            name={["invoiceAddress", "countryCode"]}
                        >
                            <CountriesCombobox optionsList={dataValue} onlyCountry />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id="app.fields.postcode" />}
                            rules={[validation.required(intl)]}
                            name={["invoiceAddress", "postCode"]}
                        >
                            <Input type="number" min={1000} max={999999} />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id="app.fields.city" />}
                            rules={[validation.required(intl)]}
                            name={["invoiceAddress", "city"]}
                        >
                            <Input />
                        </Form.Item>
                    </Flex>
                    <Flex gap={"middle"}>
                        <Form.Item
                            label={<FormattedMessage id="app.fields.street" />}
                            rules={[validation.required(intl)]}
                            name={["invoiceAddress", "street"]}
                        >
                            <Input maxLength={35} />
                        </Form.Item>
                        <Form.Item
                            label={
                                <Space>
                                    <FormattedMessage id="app.fields.house" />{" "}
                                    <Tooltip title={""}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </Space>
                            }
                            rules={[validation.required(intl)]}
                            name={["invoiceAddress", "house"]}
                        >
                            <BaseInput pattern="^[0-9]*$" maxLength={5} />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id="app.fields.additionalAddress" />}
                            name={["invoiceAddress", "addressLine2"]}
                        >
                            <Input maxLength={35} />
                        </Form.Item>
                    </Flex>

                    <Divider orientation="left">
                        <FormattedMessage id="app.fields.vatNb" />
                    </Divider>
                    <Space.Compact>
                        <Form.Item label={"EU Land"} name={["invoiceVat", "countryCode"]} style={{ maxWidth: 100 }}>
                            <CountriesCombobox optionsList={euroCountriesArray} />
                        </Form.Item>
                        <Form.Item
                            validateDebounce={200}
                            hasFeedback
                            label={<FormattedMessage id="app.fields.vatNb" />}
                            rules={[validation.vat(client, euLand)]}
                            name={["invoiceVat", "vatNumber"]}
                        >
                            <Input
                                maxLength={euLand ? Contacts.ContactConstants.CountriesEuVatLength[euLand] : 10}
                                disabled={!euLand}
                            />
                        </Form.Item>
                    </Space.Compact>

                    <Divider orientation={"left"}>DE: Steuerliche Informationen</Divider>
                    <Space.Compact>
                        <Form.Item label={"Bundesland"} name={["invoiceTax", "bundesland"]}>
                            <Select options={GermanStates} />
                        </Form.Item>
                        <Form.Item
                            validateDebounce={200}
                            hasFeedback
                            label={
                                <Space>
                                    <FormattedMessage id="app.fields.taxNumber" />
                                    <Tooltip title={<FormattedMessage id="app.global.onlyNumbersHint" />}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </Space>
                            }
                            rules={[validation.stnr(client, bundesland)]}
                            name={["invoiceTax", "stnr"]}
                        >
                            <StnrField />
                        </Form.Item>
                    </Space.Compact>

                    <Divider orientation="left"> DE Handelsregister</Divider>
                    <Space>
                        <Form.Item
                            label={<FormattedMessage id="app.fields.typeOfRegister" />}
                            name={["invoiceRegister", "type"]}
                            style={{ minWidth: 280 }}
                        >
                            <RegisterTypeCombobox />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id="app.fields.registerCourt" />}
                            name={["invoiceRegister", "court"]}
                            style={{ minWidth: 280 }}
                        >
                            <RegisterCourtCombobox />
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id="app.fields.registerNumber" />}
                            name={["invoiceRegister", "number"]}
                        >
                            <BaseInput maxLength={17} showCount />
                        </Form.Item>
                    </Space>

                    <Space>
                        <Button type={"primary"} htmlType={"submit"}>
                            <FormattedMessage id={"app.button.save"} />
                        </Button>
                    </Space>
                </Flex>
            </Form>
        </Spin>
    );
};
const StnrField = React.forwardRef<any, React.ComponentProps<typeof Input>>((props, ref) => {
    const [hint, setHint] = useState<string>();
    const form = Form.useFormInstance();
    const client = useApolloClient();
    const bundesland = Form.useWatch(["invoiceTax", "bundesland"], form);
    const stnr = Form.useWatch(["invoiceTax", "stnr"], form);
    const { status } = Form.Item.useStatus();
    useEffect(() => {
        if (bundesland && stnr && status === "success") {
            client
                .query<Pick<GQL.IQuery, "validateStnr">, GQL.IQueryValidateStnrArgs>({
                    query: validateStnrQuery,
                    fetchPolicy: "network-only",
                    variables: {
                        input: {
                            stnr,
                            bundesland,
                        },
                    },
                })
                .then(v => {
                    setHint(v.data.validateStnr.formattedTaxNumber);
                })
                .catch(console.error);
        } else {
            setHint(undefined);
        }
    }, [client, stnr, bundesland, status]);
    return (
        <Space>
            <Input
                {...props}
                ref={ref}
                minLength={10}
                maxLength={11}
                disabled={!bundesland}
                onKeyDown={event => {
                    if (event.key.length > 1) {
                        return;
                    }
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
            />
            {status === "validating" ? <div>...</div> : <div>{hint}</div>}
        </Space>
    );
});

type DrawerProps = {
    companyId?: string;
    onHide: () => void;
};
export const CompanyInvoiceDataDrawer: React.FC<DrawerProps> = ({ companyId, onHide }) => {
    const retriever = useGQLRetriever<"company">();
    const [company, setCompany] = React.useState<GQL.ICompany | null>();
    const refetch = React.useCallback(() => {
        retriever
            .query({ id: companyId, query: CompanyQueriesGraphql.queryCompanyForEdit })
            .then(data => setCompany(data?.company));
    }, [retriever, companyId]);

    React.useEffect(() => {
        if (companyId) {
            refetch();
        } else {
            setCompany(null);
        }
    }, [refetch, companyId]);
    return (
        <Drawer
            key="drawer"
            width={1000}
            title={<FormattedMessage id={"app.titles.legalInformation"} />}
            placement="right"
            open={Boolean(companyId)}
            onClose={onHide}
            destroyOnClose
        >
            {company ? <CompanyInvoiceData company={company} onComplete={onHide} /> : <Spin />}
        </Drawer>
    );
};
