import React, { FC, useState } from "react";
import { Base } from "@binale-tech/shared";
import { FormattedMessage, useIntl } from "react-intl";
import { Input } from "antd";

const searchWithPredicate = (num: string, query: string, kontoExt: number) => {
    let queryWithPredicate = query;
    const numLength = kontoExt + 4;
    while (queryWithPredicate.length <= numLength) {
        if (num.startsWith(queryWithPredicate)) {
            return true;
        }
        queryWithPredicate = "0" + queryWithPredicate;
    }
    return num.startsWith(query);
};

export function getFiltered(categories: Base.INumName[], query: string, kontoExt: number): Base.INumName[] {
    if (!query || categories.length === 0) {
        return categories;
    }
    const fNum = categories.filter(category => searchWithPredicate(category.num, query, kontoExt));
    if (fNum.length > 0) {
        return fNum;
    }
    return categories.filter(item => (item.name || "").toLowerCase().includes(query.toLowerCase()));
}

interface SearchFieldProps {
    onSearch: (query: string) => void;
}
export const SearchField: FC<SearchFieldProps> = ({ onSearch }) => {
    const intl = useIntl();
    const [query, setQuery] = useState("");

    return (
        <div style={{ minWidth: 500 }}>
            <Input
                value={query}
                placeholder={intl.formatMessage({ id: "app.fields.search" })}
                onChange={e => {
                    const value = e.target.value.trimStart();
                    setQuery(value);
                    onSearch(value);
                }}
            />
        </div>
    );
};

interface FormHeaderProps {
    isEditing: boolean;
    entityLabel: React.ReactNode;
}
export const FormHeader: FC<FormHeaderProps> = ({ isEditing, entityLabel }) => {
    return (
        <h4>
            {!isEditing && (
                <span>
                    {entityLabel} <FormattedMessage id="app.components.creating" />
                </span>
            )}
            {isEditing && (
                <span>
                    {entityLabel} <FormattedMessage id="app.components.editing" />
                </span>
            )}
        </h4>
    );
};
