import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import React, { type FC, useContext } from "react";
import { FormattedMessage } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";

import { FieldLabel } from "../../../../appearance/components/shared/form/baseComponents/FieldLabel/FieldLabel";
import { TextAreaInput } from "../DocumentInputs/TextArea";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";

const { Text } = Typography;

export const DocumentNotesField: FC = () => {
    const { isGroupEditor } = useContext(FormWrapperContext);
    const { isReadOnly } = useContext(SingleFormContext);
    if (isGroupEditor) {
        return null;
    }

    return (
        <FieldLabel
            className={"DMSForm__Item"}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id="app.fields.notes" />
                    <Tooltip title="Press Enter to move a new line." placement="top">
                        <InfoCircleOutlined style={{ marginLeft: "10px" }} />
                    </Tooltip>
                </Text>
            }
            name={"notes"}
        >
            <TextAreaInput disabled={isReadOnly} />
        </FieldLabel>
    );
};
