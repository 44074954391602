import React, { FC, useCallback, useContext, useMemo, useRef, useState } from "react";
import { TransactionsTable } from "@banks/components";
import { Flex } from "antd";
import { ParseDataViewToolbar } from "./ParseDataViewToolbar";
import { IRowNode, RowClassRules, RowSelectionOptions } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { TParseTransaction } from "@banks/types";
import dayjs from "dayjs";

import { gridOptions } from "./gridOptions";
import { ParseTransactionTableColumns } from "./parseTransactionTableColumns";

import styles from "./ParseData.module.scss";

import Container from "@app/components/shared/appearance/page/Container";
import { ParseDataContext, ParseDataControlContext } from "../../../context/ParseDataProvider";
import { useDebounce } from "../../../../../../scripts/infrastructure/hooks/useDebounce";

export const ParseDataTable: FC = () => {
    const [checkOnlySelected, setCheckOnlySelected] = useState<boolean>(false);
    const [hideIsAlreadyExist, setHideIsAlreadyExist] = useState(false);

    const { filteredRows, selectedRows, transactionBank, existingCsvLines } = useContext(ParseDataContext);
    const { selectRows } = useContext(ParseDataControlContext);

    const tableRef = useRef<AgGridReact>(null);

    const bankTransactionAndDate = useMemo(() => {
        return {
            existingCsv: existingCsvLines,
            openingBalanceDate: transactionBank?.openingBalanceDate
                ? dayjs(transactionBank.openingBalanceDate)
                : undefined,
        };
    }, [existingCsvLines, transactionBank.openingBalanceDate]);

    const { existingCsv, openingBalanceDate } = useDebounce(bankTransactionAndDate, 1000);

    const rowClassRules = useMemo<RowClassRules<TParseTransaction>>(() => {
        return {
            [styles.secondaryCell]: node => {
                if (!node?.data || !existingCsv.size) {
                    return false;
                }
                return existingCsv.has(node.data.csvLine);
            },
            [styles.beyondDatesCell]: node => {
                if (!node?.data || !openingBalanceDate) {
                    return false;
                }

                const bookingDate = dayjs(node.data.bookingDate);
                return dayjs(bookingDate).isBefore(openingBalanceDate, "day");
            },
        };
    }, [existingCsv, openingBalanceDate]);

    const handleSelectionChange = (rows: TParseTransaction[]) => {
        selectRows(rows);
    };

    const isExternalFilterPresent = useCallback((): boolean => {
        return checkOnlySelected || hideIsAlreadyExist;
    }, [checkOnlySelected, hideIsAlreadyExist]);

    const doesExternalFilterPass = useCallback(
        (node: IRowNode<TParseTransaction>): boolean => {
            if (node.data) {
                if (hideIsAlreadyExist && existingCsv.has(node?.data?.csvLine)) {
                    return false;
                }
                if (!checkOnlySelected) {
                    return true;
                }
                return !!selectedRows.find(el => node?.data?.csvLine === el.csvLine);
            }

            return false;
        },
        [selectedRows, checkOnlySelected, hideIsAlreadyExist, existingCsv]
    );

    const rowSelection = useMemo((): RowSelectionOptions<any, any> => {
        return {
            mode: "multiRow",
            isRowSelectable: (node: IRowNode) => {
                const bookingDate = dayjs(node?.data?.bookingDate);
                const isDateBefore = openingBalanceDate
                    ? dayjs(bookingDate).isBefore(openingBalanceDate, "day")
                    : false;
                const isSelectable = !!bookingDate && !isDateBefore;
                return node.data ? isSelectable : false;
            },
            hideDisabledCheckboxes: true,
        };
    }, [openingBalanceDate]);

    const handleSelectChecked = useCallback((checked: boolean) => {
        setCheckOnlySelected(checked);
        tableRef?.current?.api.onFilterChanged();
    }, []);

    const handleHideIsAlreadyExist = useCallback((checked: boolean) => {
        setHideIsAlreadyExist(checked);
        tableRef?.current?.api.onFilterChanged();
    }, []);

    return (
        <Flex vertical gap={20} style={{ height: "100%" }}>
            <ParseDataViewToolbar
                handleSelectChecked={handleSelectChecked}
                handleHideIsAlreadyExist={handleHideIsAlreadyExist}
            />
            <Container absolute flex>
                {(w, h) => (
                    <TransactionsTable
                        ref={tableRef}
                        tableKey={"parse-bank-data"}
                        rowData={filteredRows}
                        gridOptions={gridOptions}
                        rowClassRules={rowClassRules}
                        rowSelection={rowSelection}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                        handleSelectionChange={handleSelectionChange}
                        tableColumns={ParseTransactionTableColumns.tableColumnConfig}
                        viewHeight={h}
                    />
                )}
            </Container>
        </Flex>
    );
};
