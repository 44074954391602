import CompanyQueriesGraphql from "./companyQueries.graphql";
import React, { useEffect } from "react";
import { Button, Drawer, Form, Input, message, notification, Select, Spin } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { ProductAccessLevels } from "scripts/models/utils/ProductAccessUtils";
import { ProductKeys } from "scripts/models/Product";
import { UserAddOutlined } from "@ant-design/icons";
import { decodeKey } from "scripts/api/firebase/firebase";
import { useGqlMutator } from "scripts/graphql/useGqlMutator";

interface Props {
    company: GQL.ICompany | null;
    onClose: () => void;
    member: GQL.ICompanyMember | null;
}

type CompanyUserFormData = { email: string } & Record<GQL.IProductKey, GQL.IProductAccessLevel>;

const ALL_PRODUCT_KEYS = ProductKeys.all();
const CompanyUserDrawer: React.FC<Props> = ({ onClose, company, member }) => {
    const [loading, setLoading] = React.useState(false);
    const [form] = Form.useForm<CompanyUserFormData>();
    const Mutator = useGqlMutator();
    const intl = useIntl();
    const { setFieldsValue } = form;

    useEffect(() => {
        if (member) {
            const data = { email: decodeKey(member.emailKey) } as Partial<CompanyUserFormData>;

            ALL_PRODUCT_KEYS.forEach(pk => {
                data[pk] = member.products.find(p => p.productKey === pk)?.access || null;
            });

            setFieldsValue(data);
        }
    }, [setFieldsValue, member]);

    const handleSubmit = async (values: CompanyUserFormData) => {
        const { email, ...userAccess } = values;
        if (Object.values(userAccess).filter(Boolean).length === 0) {
            message.error("Select at least one product");
            return;
        }
        setLoading(true);
        await Mutator.mutate<"companySaveMember", GQL.ICompanySaveMemberInput>({
            mutation: CompanyQueriesGraphql.mutationSaveMember,
            input: {
                email,
                companyId: company.id,
                products: Object.entries(userAccess)
                    .map(([productKey, status]) => ({ productKey, status }))
                    .filter(v => Boolean(v.status)),
            },
        })
            .then(res => {
                if (res.companySaveMember.wasCreated) {
                    notification.info({ message: "New user was created" });
                }
            })
            .finally(() => {
                setLoading(false);
                onClose();
            });
    };

    const normalizeProductKey = (): React.ComponentProps<typeof Form.Item>["normalize"] => {
        return (value, prevValue, allValues) => {
            const m = new Map();
            Object.keys(allValues).forEach(k => {
                if (allValues[k] && ALL_PRODUCT_KEYS.includes(k as GQL.IProductKey)) {
                    m.set(k, allValues[k]);
                }
            });
            return value;
        };
    };

    return (
        <Drawer width={400} title="Create/Update company member" placement="right" open onClose={onClose}>
            <Spin spinning={loading}>
                <Form layout="vertical" onFinish={handleSubmit} form={form}>
                    <Form.Item
                        label={<FormattedMessage id="app.fields.user" />}
                        rules={[
                            { max: 200, message: "too long" },
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: "app.validation.error.field_empty",
                                }),
                            },
                            {
                                type: "email",
                                message: intl.formatMessage({
                                    id: "app.validation.error.invalid_email",
                                }),
                            },
                        ]}
                        name="email"
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    {ALL_PRODUCT_KEYS.map(productKey => {
                        const companyProduct = company.products.find(p => p.productKey === productKey);
                        const active = Boolean(companyProduct);
                        return (
                            <Form.Item
                                key={productKey}
                                label={<FormattedMessage id={"app.titles." + productKey} />}
                                initialValue={null}
                                normalize={normalizeProductKey()}
                                name={productKey}
                            >
                                <Select
                                    disabled={!active}
                                    options={[
                                        { value: null, label: <FormattedMessage id="app.companies.permission.no" /> },
                                        {
                                            value: ProductAccessLevels.Read,
                                            label: <FormattedMessage id="app.companies.permission.read" />,
                                        },
                                        {
                                            value: ProductAccessLevels.Write,
                                            label: <FormattedMessage id="app.companies.permission.write" />,
                                            disabled: ProductKeys.readOnlyUserAccessProducts().includes(productKey),
                                        },
                                    ]}
                                />
                            </Form.Item>
                        );
                    })}
                    <Form.Item label={<i>&nbsp;</i>}>
                        <Button type="primary" icon={<UserAddOutlined />} htmlType="submit">
                            <FormattedMessage id="app.button.done" />
                        </Button>
                        <Button style={{ marginLeft: 5 }} onClick={onClose}>
                            <FormattedMessage id="app.button.cancel" />
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    );
};
export default React.memo(CompanyUserDrawer);
