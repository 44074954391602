import { useEffect } from "react";

export const useIubendaCookieSolution = () => {
    useEffect(() => {
        if (!document.body) {
            return;
        }
        // const script = document.createElement("script");
        // script.type = "text/javascript";
        // script.innerHTML =
        //     'var _iub = _iub || [];_iub.csConfiguration = {"siteId":3778058,"cookiePolicyId":52953099,"lang":"de", "floatingPreferencesButtonDisplay": false};';
        // // script.async = true;
        // document.body.appendChild(script);

        // const script2 = document.createElement("script");
        // script2.type = "text/javascript";
        // script2.src = "https://cs.iubenda.com/autoblocking/3778058.js";
        // document.body.appendChild(script2);

        const script3 = document.createElement("script");
        script3.type = "text/javascript";
        script3.async = true;
        script3.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
        document.body.appendChild(script3);
    }, []);
};
