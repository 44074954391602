import React, { FC, PropsWithChildren, useMemo } from "react";
import { CompanyContext } from "scripts/context/CompanyContext";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { RecordsControlContext } from "scripts/context/recordsContext/RecordsControlCtx";
import {
    TableViewContext,
    TableViewContextProvider,
    useDefaultYearPeriodFilters,
} from "../../../../scripts/context/tableViewContext/tableViewContext";
import { RecordLogRepository } from "../../../../scripts/infrastructure/repository/grRepositories";
import { GQL } from "@binale-tech/shared";
import { ProductERAnzahlungen } from "../../../../scripts/core/Product";

export const ERAnzProvider: FC<PropsWithChildren> = ({ children }) => {
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const allRecords = React.useContext(RecordsContext);
    const allActions = React.useContext(RecordsControlContext);
    const defaultFilters = useDefaultYearPeriodFilters();
    const product = useMemo(() => new ProductERAnzahlungen(yearConfig.skr, companyGQL), [yearConfig.skr, companyGQL]);

    const tableCtx = React.useMemo<React.ContextType<typeof TableViewContext>>(
        () => ({
            view: "erAnzahlungen",
            product,
            productKey: GQL.IProductKey.ErA,
            moduleRecords: allRecords.recordsAZ.list || [],
            moduleActions: allActions.actionsAZ,
            moduleLogLister: key => RecordLogRepository.AZ.list({ path: [companyGQL?.id, key] }),
        }),
        [allActions.actionsAZ, allRecords.recordsAZ.list, companyGQL?.id, product]
    );
    return (
        <TableViewContextProvider tableCtx={tableCtx} defaultFilters={defaultFilters}>
            {children}
        </TableViewContextProvider>
    );
};
