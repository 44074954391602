import { useCallback } from "react";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";

export const useFormHandlerRecordLastschrift = () => {
    const { updateEditableRecord } = useFormRecordStateUpdater();
    return useCallback(
        (recordLastschrift: boolean) => {
            updateEditableRecord({ recordLastschrift });
        },
        [updateEditableRecord]
    );
};
