import React, { useRef } from "react";
import { Collapse, Typography } from "antd";

import { focusFirst } from "scripts/infrastructure/helpers/focus";

export enum CollapsePanelKey {
    Addresses = "addresses",
    LegalInfo = "legalInfo",
    Banks = "banks",
    Communication = "communications",
    ContactPerson = "contactPerson",
    TermsOfPayment = "termsOfPayment",
}

interface CollapsePanelProps {
    title: React.ReactNode;
    activeKeys: CollapsePanelKey[];
    panelId: CollapsePanelKey;
    onChange: (activeKeys: CollapsePanelKey[]) => void;
    forceRender?: boolean;
}

export const ContactCollapsePanel: React.FC<React.PropsWithChildren<CollapsePanelProps>> = ({
    title,
    children,
    panelId,
    activeKeys,
    onChange,
    forceRender,
}) => {
    const collapsePanelRef = useRef<HTMLDivElement>(null);

    const isActive = activeKeys.includes(panelId);

    const handleChange = (innerActiveKeys: string[]) => {
        if (innerActiveKeys.length) {
            onChange([...activeKeys, panelId]);
            new Promise(r => setTimeout(r, 10)).then(() => focusFirst(collapsePanelRef.current));
            focusFirst(collapsePanelRef.current);
        } else {
            onChange(activeKeys.filter(key => key !== panelId));
        }
    };

    return (
        <div ref={collapsePanelRef}>
            <Collapse
                style={{ marginTop: 12 }}
                onChange={handleChange}
                activeKey={isActive ? "1" : null}
                items={[
                    {
                        key: "1",
                        showArrow: false,
                        forceRender,
                        label: (
                            <Typography.Title
                                level={5}
                                style={{ marginBottom: 0, textAlign: "center" }}
                                data-testid={`collapse-pannel-${panelId}`}
                            >
                                {title}
                            </Typography.Title>
                        ),
                        children,
                    },
                ]}
            />
        </div>
    );
};
