import { CurrencyValue } from "@ui-components/AmountInput/BruttoInput";
import React, {
    createContext,
    type FC,
    type ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { necessaryFieldsForDocumentTypes } from "@dms/configs/constants";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { DmsType, IDocumentEnriched, MovementType, type TDocumentInputs } from "@dms/types";
import { useGqlMutator } from "../../../../scripts/graphql/useGqlMutator";
import { getMovementType } from "@dms/scripts/helpers";

interface IProps {
    children: ReactNode;
    documentInitialValue?: IDocumentEnriched;
    isReadOnly?: boolean;
}

interface TValue {
    singleFormInitialValue: TDocumentInputs | undefined;
    actionConfirm: (arg: IDocumentEnriched) => Promise<void>;
    necessaryFields?: readonly (keyof Partial<IDocumentEnriched>)[];
    handleSetNecessaryFields: (type: string) => void;
    movementType: MovementType;
    setMovementType: (arg: MovementType) => void;
    changedDocumentType: DmsType;
    setChangedDocumentType: (arg: DmsType) => void;
    isReadOnly?: boolean;
}

/**
 Context for Document Form
 */
export const SingleFormContext = createContext({} as TValue);

/**
 Context Provider for Document Form
 */

export const SingleFormProvider: FC<IProps> = ({ children, documentInitialValue, isReadOnly }) => {
    const [singleFormInitialValue, setSingleFormInitialValue] = useState<TDocumentInputs>();
    const [necessaryFields, setNecessaryFields] = useState<readonly (keyof Partial<IDocumentEnriched>)[]>([]);
    const [movementType, setMovementType] = useState<MovementType>(
        getMovementType(documentInitialValue.documentAmount, documentInitialValue.type as DmsType)
    );
    const [changedDocumentType, setChangedDocumentType] = useState<DmsType>(documentInitialValue.type as DmsType);

    const mutator = useGqlMutator();

    const initialDocument = useRef<IDocumentEnriched>(null);

    useEffect(() => {
        if (!initialDocument?.current) {
            initialDocument.current = documentInitialValue;
            return;
        }
    }, [documentInitialValue]);

    const updateFormInitialValue = useCallback(() => {
        if (!initialDocument?.current) {
            return;
        }

        const initialData = initialDocument.current;

        const type = initialData.subType ? initialData.subType : initialData.type;
        const { documentAmount, originalAmount, currency, currencyRate, ...restValue } = initialData;
        const currencyData: Partial<CurrencyValue> = {
            amount: documentAmount,
            originalAmount: originalAmount ?? undefined,
            currency: currency ? { code: currency, rate: currencyRate ?? 1 } : undefined,
        };

        setSingleFormInitialValue({
            ...restValue,
            type,
            currencyData,
        });

        setNecessaryFields(necessaryFieldsForDocumentTypes[initialData.type as DmsType] || []);
    }, []);

    useEffect(() => {
        updateFormInitialValue();
    }, [updateFormInitialValue]);

    const handleSetNecessaryFields = useCallback((type: string) => {
        setNecessaryFields(necessaryFieldsForDocumentTypes[type as DmsType] || []);
    }, []);

    const value: TValue = useMemo(
        () => ({
            isReadOnly,
            singleFormInitialValue,
            necessaryFields,
            movementType,
            changedDocumentType,
            actionConfirm: async (data: IDocumentEnriched): Promise<void> => {
                await DocumentsApi.updateDocuments({ documents: [data], mutator });
                initialDocument.current = { ...singleFormInitialValue, ...data };
                updateFormInitialValue();
            },
            handleSetNecessaryFields,
            setMovementType: (arg: MovementType) => setMovementType(arg),
            setChangedDocumentType: arg => setChangedDocumentType(arg),
        }),
        [
            changedDocumentType,
            handleSetNecessaryFields,
            isReadOnly,
            movementType,
            mutator,
            necessaryFields,
            singleFormInitialValue,
            updateFormInitialValue,
        ]
    );

    return <SingleFormContext.Provider value={value}>{children}</SingleFormContext.Provider>;
};
