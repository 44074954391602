import React, { memo } from "react";

import GenericTableViewCtxWrapper from "../productSharedComponents/GenericTableViewCtxWrapper";
import { FreieErfassungViewClass } from "../productFE/FEView";
import { POSProvider } from "./POSProvider";

class POSViewClass extends FreieErfassungViewClass {}

export const POSView: React.FC = memo(function POSView() {
    return (
        <POSProvider>
            <GenericTableViewCtxWrapper Component={POSViewClass} />
        </POSProvider>
    );
});
