import GenericTableViewCtxWrapper from "../GenericTableViewCtxWrapper";
import React from "react";
import { IViewsKeys } from "scripts/models/User";
import { Product } from "scripts/core/Product";
import {
    TableFiltersContext,
    TableViewContext,
    TableViewContextProvider
} from "scripts/context/tableViewContext/tableViewContext";
import { useModuleResolver } from "./useModuleResolver";

type Props = Pick<React.ComponentProps<typeof GenericTableViewCtxWrapper>, "Component"> & {
    view: keyof IViewsKeys;
    defaultFilters: React.ContextType<typeof TableFiltersContext>["filters"];
    product: Product;
};

export const AuswertungWrapper: React.FC<Props> = props => {
    const { Component, product, view, defaultFilters } = props;
    const { moduleActions, moduleRecords, moduleLogLister } = useModuleResolver(product.productKey());

    const tableCtx = React.useMemo<React.ContextType<typeof TableViewContext>>(
        () => ({
            view,
            product,
            productKey: product.productKey(),
            moduleActions,
            moduleRecords,
            moduleLogLister,
        }),
        [view, product, moduleActions, moduleRecords, moduleLogLister]
    );
    return (
        <TableViewContextProvider tableCtx={tableCtx} defaultFilters={defaultFilters}>
            <GenericTableViewCtxWrapper Component={Component} />
        </TableViewContextProvider>
    );
};
