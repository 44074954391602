import React, { FC } from "react";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { Form, Typography } from "antd";

import styles from "./FormFields.module.scss";
import { DigitInput } from "@app/components/shared/form/baseComponents/DigitInput/DigitInput";
import { FormattedMessage } from "react-intl";

const { Text } = Typography;

type TProps = {
    isDisabled?: boolean;
};

export const AccountNumberField: FC<TProps> = ({ isDisabled }) => {
    const form = Form.useFormInstance();
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id={"app.fields.bankAccountNumber"} />
                </Text>
            }
            name={"accountNumber"}
            rules={[{ required: true, message: "Required field" }]}
            required
        >
            <DigitInput disabled={isDisabled} maxLength={20} showCount></DigitInput>
        </FieldLabel>
    );
};
