import { useContext, useMemo } from "react";

import { PaymentBindingUtils } from "scripts/models/utils/PaymentBindingUtils";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";

export const useGetConnectedPaymentSourceRecord = (representationRecordKey: string) => {
    const { allRecords } = useContext(RecordsContext);
    const { recordRelation } = useContext(PaymentsContext);

    return useMemo(() => {
        return PaymentBindingUtils.getConnectedPaymentSourceRecord({
            allRecords,
            paymentsRecordRelation: recordRelation,
            representationRecordKey,
        });
    }, [allRecords, recordRelation, representationRecordKey]);
};
