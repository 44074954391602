import React, { FC } from "react";
import { ParseContextProvider } from "@banks/scripts/context";
import { CsvUploader } from "@banks/modules/UploadModule/components";

export const UploadModule: FC = () => {
    return (
        <ParseContextProvider>
            <CsvUploader />
        </ParseContextProvider>
    );
};
