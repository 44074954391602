import React from "react";
import { Button, ConfigProvider } from "antd";

type Props = React.ComponentProps<typeof Button>;
export const DatevButton: React.FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        defaultBg: "linear-gradient(45deg, #96d551, rgb(132 229 39 / 21%)) !important",
                        defaultShadow: "none",
                        defaultColor: "rgba(0, 0, 0, 0.88) !important",
                    },
                },
                token: {
                    colorBgContainerDisabled: "rgba(0, 0, 0, 0.04) !important",
                    colorTextDisabled: "rgba(0, 0, 0, 0.25) !important",
                },
            }}
        >
            <Button {...props}>{children}</Button>
        </ConfigProvider>
    );
};
