import { GenericRecord } from "../GenericRecord";
import { Contacts, GQL, Periods, Utils } from "@binale-tech/shared";
import dayjs from "dayjs";
import RecordFormState from "../../../appearance/components/recordform/types/RecordFormState";
import RecordFormUtils from "../../../appearance/components/recordform/utils/RecordFormUtils";
import { CurrencyValue } from "@ui-components/AmountInput/BruttoInput";
import { ContactAccountingConverter } from "./ContactAccountingConverter";
import { Product } from "../../core/Product";
import { BuTimeframe } from "../../context/BuContext";
import { ProductAccessUtils } from "../utils/ProductAccessUtils";
import { IDocumentSuggestion } from "@dms/types";
import { GenericRecordUtils } from "../utils/GenericRecordUtils";

type RecordForm = {
    recordDate?: RecordFormState["recordDate"];
    editableRecord: Partial<RecordFormState["editableRecord"]>;
    editableRecordItem: Partial<RecordFormState["editableRecordItem"]>;
};
type FormData = { isUpdating: boolean; yearBound: number; periodBound: number; recordFormData: RecordForm };

export class DmsAccountingConverter {
    constructor(
        protected company: GQL.ICompany,
        protected product: Product,
        protected contacts: Contacts.Contact[],
        protected templates: GenericRecord[],
        protected skr: number,
        protected buTimeframes: BuTimeframe[],
        protected recordGroup: string
    ) {}
    static convertRecordToDms(record: GenericRecord, pk: GQL.IProductKey): IDocumentSuggestion {
        const suggestion: IDocumentSuggestion = {};

        suggestion.documentAmount = record.getBrutto();
        suggestion.partner = record.partner;
        suggestion.documentDate = dayjs(record.date).format("DD.MM.YYYY");

        const text = record.items.find(item => item.text)?.text;
        if (text) {
            suggestion.description = text;
        }
        const belegfeld2 = record.items.find(item => item.belegfeld2)?.belegfeld2;
        if (belegfeld2) {
            suggestion.interneNumber = belegfeld2;
        }
        const recordNum = GenericRecordUtils.getInvoiceNumber(record, pk);
        if (recordNum) {
            suggestion.documentNumber = recordNum;
        }

        if (record.currency) {
            suggestion.currency = {
                originalAmount: record.getOriginalAmount(),
                currencyCode: record.currency.code,
                currencyRate: record.currency.rate,
            };
        }
        return suggestion;
    }

    /**
     * this method generates suggestions for both: record form and dms conversion.
     * Contact data automations must be handled separately
     *
     * @param productKey
     * @param documents
     * @param formData
     */
    static getRecordDocumentsSuggestion(productKey: GQL.IProductKey, documents: GQL.IDocument[], formData?: FormData) {
        if (!documents.length) {
            return {
                editableRecord: {},
                editableRecordItem: {},
            };
        }
        const useItemBelegfeld1 = Utils.ModuleUtils.useBelegfeld1Split(productKey);
        const result: RecordForm = formData?.recordFormData ?? {
            editableRecord: {},
            editableRecordItem: {},
        };

        documents.forEach(document => {
            if (document.documentDate) {
                this.updateRecordDate(result, document.documentDate, formData);
            }

            if (!result.editableRecord.recordContact && document.partner) {
                result.editableRecord.recordContact = document.partner;
            }
            if (!result.editableRecordItem.itemText && document.description) {
                result.editableRecordItem.itemText = document.description;
            }
            if (document.documentNumber) {
                if (useItemBelegfeld1) {
                    if (!result.editableRecordItem.itemBelegfeld1) {
                        result.editableRecordItem.itemBelegfeld1 = document.documentNumber;
                    }
                } else {
                    if (!result.editableRecord.recordNum) {
                        result.editableRecord.recordNum = document.documentNumber;
                    }
                }
            }
            if (!result.editableRecordItem.itemBelegfeld2 && document.interneNumber) {
                result.editableRecordItem.itemBelegfeld2 = document.interneNumber;
            }

            if (!result.editableRecord.recordBrutto && document.documentAmount) {
                const currencyValue: CurrencyValue = { amount: document.documentAmount };
                if (document.currency !== GQL.ICurrencyCode.Eur) {
                    currencyValue.originalAmount = document.originalAmount;
                    currencyValue.currency = {
                        code: document.currency,
                        rate: document.currencyRate,
                    };
                }
                Object.assign(result.editableRecord, RecordFormUtils.getFormCurrency(currencyValue));
                result.editableRecordItem.itemBrutto = currencyValue.amount;
                result.editableRecordItem.itemOriginalAmount = currencyValue.originalAmount;
            }
        });
        return result;
    }

    // this is used in dms. We only apply a template here if there is exactly 1 matching template for the contact
    static applyContactTemplateSuggestions(suggestion: RecordForm, templates: GenericRecord[]) {
        if (suggestion.editableRecord.recordContact) {
            const contactId = suggestion.editableRecord.recordContact.id;
            const contactTemplates = templates.filter(({ partner }) => partner.id === contactId);
            const template = contactTemplates.length === 1 ? contactTemplates[0] : undefined;
            const contactSuggestions = ContactAccountingConverter.applyContactTemplateToRecord(template, suggestion);
            Object.assign(suggestion.editableRecord, contactSuggestions.editableRecord);
            Object.assign(suggestion.editableRecordItem, contactSuggestions.editableRecordItem);
        }
    }

    convertDocumentToRecord(document: GQL.IDocument): GenericRecord | null {
        if (!ProductAccessUtils.hasCompanyAccounting(this.company)) {
            return null;
        }
        if (!document) {
            return null;
        }

        const productKey = this.product.productKey() as GQL.IProductKey;
        const suggestion = DmsAccountingConverter.getRecordDocumentsSuggestion(productKey, [document]);
        suggestion.editableRecord.recordDocuments = [{ id: document.key, url: document.fileUrl }];
        suggestion.editableRecord.recordDraft = true;

        DmsAccountingConverter.applyContactTemplateSuggestions(suggestion, this.templates);

        if (!suggestion.recordDate) {
            return null;
        }

        const { date, period } = suggestion.recordDate;

        if (!this.company.accountingYears.includes(date.getFullYear())) {
            return null;
        }
        if (!suggestion.editableRecord.recordBrutto) {
            return null;
        }

        suggestion.editableRecord.recordKey = Utils.ModuleUtils.generateKey(date, period, "DmsI");

        const record = RecordFormUtils.constructRecord(
            suggestion as RecordFormState,
            this.product.getConfig(),
            this.skr,
            this.buTimeframes,
            this.recordGroup
        );
        const item = RecordFormUtils.constructRecordItem(
            suggestion as RecordFormState,
            this.product.getConfig(),
            this.skr,
            this.buTimeframes,
            this.recordGroup
        );
        record.items = [item];

        record.calculateBrutto();
        return record;
    }

    protected static updateRecordDate(result: RecordForm, documentDate: string, formData?: FormData) {
        // Check if documentDate exists and can be parsed as a valid date
        const parsedDate = dayjs(documentDate, "DD.MM.YYYY").toDate();
        if (!RecordFormUtils.isDate(parsedDate)) {
            return;
        }

        // Decide based on formData existence and update logic
        if (!formData) {
            result.recordDate = RecordFormUtils.getFormDate(parsedDate);
        } else if (!formData.isUpdating && parsedDate.getFullYear() === formData?.yearBound) {
            this.handlePeriodBoundUpdate(result, parsedDate, formData);
        }
    }

    protected static handlePeriodBoundUpdate(result: RecordForm, date: Date, formData?: FormData) {
        if (!Number.isFinite(formData.periodBound)) {
            result.recordDate = RecordFormUtils.getFormDate(date);
            return;
        }

        const { month, day: strictDay } = Periods.getMonthAndDay(formData.periodBound);
        if (!strictDay && month === date.getMonth()) {
            result.recordDate = RecordFormUtils.getFormDate(date, formData.periodBound);
        }
    }
}
