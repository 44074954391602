import React, { FC, useContext, useMemo } from "react";
import { ProductFE } from "../../../../scripts/core/Product";
import { CCDComponent } from "../components";

import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { useFormConfig } from "../hooks/useFormConfig";
import { useFormInfoBlock } from "../hooks/useFormInfoBlock";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useIsItemFieldDisabledOutsideModal } from "../hooks/useIsItemFieldDisabledOutsideModal";
import { useCheckAutoPaymentConnection } from "../hooks/useCheckAutoPaymentConnection";
import { useFormHandlerItemAccount } from "../hooks/handlers/useFormHandlerItemAccount";

type Props = {
    isModalMode?: boolean;
};
export const FormItemAccount: FC<Props> = ({ isModalMode }) => {
    const isModalOnly = useIsItemFieldDisabledOutsideModal(isModalMode);
    const { refsHtml } = useContext(RecordFormPropsContext);
    const isDisabled = useFormIsRefDisabled();
    const formConfig = useFormConfig();
    const { itemCCLabel } = useFormInfoBlock();
    const { editableRecord, editableRecordItem, itemValidationStates } = useContext(RecordFormStateContext);
    const onItemAccountChange = useFormHandlerItemAccount();
    const checkAutoBinding = useCheckAutoPaymentConnection();

    const validation = itemValidationStates.get(refsHtml.REF_iCATEGORYCREDITOR);

    const { itemCategoryCreditor, itemBrutto } = editableRecordItem;
    const { recordBrutto } = editableRecord;

    const label = useMemo(() => {
        if (!formConfig.useSollHabenHints) {
            return itemCCLabel;
        }

        const suffix = ProductFE.getSHHint(isModalMode ? itemBrutto : recordBrutto).gkonto;
        return (
            <span>
                {itemCCLabel} {suffix}
            </span>
        );
    }, [formConfig.useSollHabenHints, itemCCLabel, recordBrutto, isModalMode, itemBrutto]);
    return (
        <CCDComponent
            labelProps={{ label, fieldError: validation }}
            mode={formConfig.itemAccountMode}
            inputRef={refsHtml.REF_iCATEGORYCREDITOR}
            value={isModalOnly ? undefined : itemCategoryCreditor}
            onChange={onItemAccountChange}
            disabled={isModalOnly || isDisabled(refsHtml.REF_iCATEGORYCREDITOR)}
            inputProps={{
                onBlur: checkAutoBinding,
            }}
        />
    );
};
