import React, { FC, memo, useContext } from "react";
import { Divider, Modal, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { CloseButton } from "@dms/components/CloseButton/CloseButton";
import { InvoiceModalContext, InvoiceModalControlContext } from "@inv/context/InvoiceModalProvider";

const InvoiceModal: FC = () => {
    const { modalData } = useContext(InvoiceModalContext);
    const { closeModal } = useContext(InvoiceModalControlContext);

    if (!modalData) {
        return null;
    }

    const { title, component } = modalData;

    return (
        <Modal
            open={!!modalData}
            onCancel={closeModal}
            destroyOnClose
            style={{
                top: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0",
            }}
            closable={false}
            footer={null}
            title={
                <Divider style={{ marginBottom: 0 }}>
                    <Typography.Title level={5}>
                        <FormattedMessage id={title} />
                    </Typography.Title>
                </Divider>
            }
            styles={{
                body: {
                    height: "calc(100vh - 100px)",
                    minWidth: "60vw",
                    backgroundColor: "white",
                    display: "flex",
                    paddingTop: 10,
                },
            }}
        >
            <CloseButton onClose={closeModal} />
            {component}
        </Modal>
    );
};

export default memo(InvoiceModal);
