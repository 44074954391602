import { Base, Currency, GQL, Utils } from "@binale-tech/shared";
import { CurrencyValue } from "@ui-components/AmountInput/BruttoInput";
import { Button, Form, Input, Select, Space } from "antd";
import React, { forwardRef, useEffect, useRef, useState } from "react";

import { formatDefault, parseNumber } from "@dms/scripts/helpers";
import {
    composeCurrencyValue,
    getEuroAmount,
    handleKeyDown,
    isRetrieveCurrencyRate,
} from "@dms/components/DocumentForm/utils";
import { useApolloClient } from "@apollo/client";
import { DownloadOutlined } from "@ant-design/icons";
import { retrieveCurrencyRate } from "@ui-components/AmountInput/retrieveCurrencyRate";

type CurrencyInputBlockProps = {
    value?: Partial<CurrencyValue>;
    allowConfigEdit: boolean;
    onChange?: (value: Partial<CurrencyValue>) => void;
    placeholders?: TGroupPlaceholders;
};

type TGroupPlaceholders = Record<
    keyof Partial<Omit<CurrencyValue, "currency"> & Partial<Base.CurrencyConfig>>,
    string | undefined
>;

export const CurrencyGroupInput = forwardRef<any, CurrencyInputBlockProps>(function CurrencyInputBlock(props, ref) {
    const client = useApolloClient();
    const form = Form.useFormInstance();
    const documentDate = form.getFieldValue("documentDate");
    const isRetrieve = isRetrieveCurrencyRate(documentDate);

    const { allowConfigEdit, value: currencyValue, placeholders } = props;
    const ccCode = currencyValue?.currency?.code;
    const [openOptions, setOpenOptions] = useState(false);
    const [isRateLoading, setIsRateLoading] = useState(false);
    const prevCcCode = useRef(ccCode);

    const [originalAmountStr, setOriginalAmountStr] = useState<string>("");
    const [rateStr, setRateStr] = useState<string>("");
    const [euroValueRes, setEuroValueRes] = useState<string>("");

    useEffect(() => {
        const originalAmount = currencyValue?.originalAmount ?? currencyValue?.amount;
        setOriginalAmountStr(originalAmount ? formatDefault(originalAmount) : "0,00");
        setRateStr(
            currencyValue?.currency?.rate ? Utils.CurrencyUtils.numberFormat(currencyValue.currency?.rate, 4) : "1,00"
        );
        setEuroValueRes(currencyValue?.amount ? formatDefault(currencyValue.amount) : "0,00");
    }, [currencyValue]);

    useEffect(() => {
        if (ccCode !== GQL.ICurrencyCode.Eur) {
            if (originalAmountStr.trim() === "") {
                return setEuroValueRes("");
            }
            const originalAmountNum = Math.round(parseNumber(originalAmountStr) * 100);

            const euroAmount = Utils.CurrencyUtils.getEuroFromCurrency(originalAmountNum, parseNumber(rateStr) ?? 1);
            setEuroValueRes(formatDefault(euroAmount));
        }
    }, [rateStr, originalAmountStr]);

    const numberInputHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const val: string = e.currentTarget.value;
        if (!/[0-9,-]|(Backspace|ArrowRight|ArrowLeft)/.test(e.key)) {
            e.preventDefault();
        }
        if (val.includes(",") && e.key === ",") {
            e.preventDefault();
        }
    };

    const sendBack = (code?: GQL.ICurrencyCode) => {
        const codeNow = code || ccCode;

        if (!codeNow) {
            return;
        }

        const value: Partial<CurrencyValue> = {
            currency: {
                rate: 1,
                code: GQL.ICurrencyCode.Eur,
            },
            amount: Math.round(parseNumber(euroValueRes) * 100),
        };

        if (codeNow !== GQL.ICurrencyCode.Eur) {
            value.currency = {
                rate: parseNumber(rateStr),
                code: codeNow,
            };

            value.originalAmount = Math.round(parseNumber(originalAmountStr) * 100);
        }

        prevCcCode.current = codeNow;
        if (props.onChange) {
            props.onChange(value);
        }
    };

    const handleGroupCurrencyClick = () => {
        setIsRateLoading(true);

        retrieveCurrencyRate({ client, documentDate, currencyCode: ccCode })
            .then(rate => {
                const stringRate = rate.toFixed(4).replace(".", ",");
                setRateStr(stringRate);

                const euroAmount = getEuroAmount(originalAmountStr, stringRate);
                const value = composeCurrencyValue(euroAmount, ccCode, originalAmountStr, stringRate);
                props?.onChange(value);
            })
            .finally(() => setIsRateLoading(false));
    };

    return (
        <Space.Compact block>
            <Select
                value={ccCode}
                onChange={sendBack}
                placeholder={placeholders?.code ?? "Currency"}
                style={{ width: "20%" }}
                disabled={!allowConfigEdit}
                data-testid="select-currency"
                id="currencySelectID"
                onBlur={() => setOpenOptions(false)}
                onDropdownVisibleChange={setOpenOptions}
                open={openOptions}
                onKeyDown={event => handleKeyDown(event, { ccCode, prevCcCode, openOptions })}
                options={Currency.CommonCurrencyList.map(code => ({
                    label: code,
                    value: code,
                }))}
            />
            <Input
                ref={ref}
                style={{ width: "30%", textAlign: "center" }}
                value={originalAmountStr}
                placeholder={placeholders?.amount ?? "Amount"}
                disabled={!ccCode || ccCode === GQL.ICurrencyCode.Eur || !allowConfigEdit}
                onChange={e => setOriginalAmountStr(e.target.value)}
                onBlur={() => sendBack()}
                onFocus={e => e.target.select()}
                onKeyDown={numberInputHandler}
                data-testid="amount"
                id="currencyAmountID"
            />
            <div style={{ position: "relative", width: "20%" }}>
                <Input
                    style={{ textAlign: "center" }}
                    placeholder={placeholders?.rate ?? "Rate"}
                    disabled={!ccCode || ccCode === GQL.ICurrencyCode.Eur || !allowConfigEdit}
                    value={rateStr}
                    onChange={e => {
                        setRateStr(e.target.value);
                    }}
                    onBlur={() => {
                        sendBack();
                    }}
                    onKeyDown={numberInputHandler}
                    data-testid="rate"
                    id="currencyRateID"
                />
                {ccCode !== GQL.ICurrencyCode.Eur && isRetrieve && (
                    <div
                        style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                            zIndex: 100,
                            borderRadius: "50%",
                            backgroundColor: "white",
                        }}
                    >
                        <Button
                            tabIndex={-1}
                            size="small"
                            icon={<DownloadOutlined />}
                            style={{ borderRadius: "50%" }}
                            disabled={!allowConfigEdit}
                            onClick={handleGroupCurrencyClick}
                            loading={isRateLoading}
                        />
                    </div>
                )}
            </div>
            <Input
                style={{
                    width: "30%",
                    textAlign: "center",
                }}
                placeholder={placeholders?.amount}
                value={euroValueRes}
                onChange={e => setEuroValueRes(e.target.value)}
                onBlur={() => sendBack()}
                disabled={!ccCode || ccCode !== GQL.ICurrencyCode.Eur || !allowConfigEdit}
                onKeyDown={numberInputHandler}
                suffix="EUR"
                data-testid="final-amount"
                id="currencyFinalAmountID"
            />
        </Space.Compact>
    );
});
