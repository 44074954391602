import React, { FC } from "react";

import styles from "./CarouselItem.module.scss";

interface IProps {
    previewUrl: string;
    id: string;
    isDraggable?: boolean;
}

export const CarouselItem: FC<IProps> = ({ previewUrl, id }) => {
    return (
        <div className={styles.item}>
            <div
                className={styles.itemBody}
                style={{
                    backgroundImage: `url(${previewUrl})`,
                }}
                id={id}
            />
            <a href={previewUrl}></a>
        </div>
    );
};
