import React, { FC, useContext, useState } from "react";
import { Col, Flex } from "antd";
import { ScrollPagesTool } from "../ScrollPagesTool";
import { ActionButtons } from "../ActionButtons";
import { ParkedDocumentContainer } from "../ParkedDocuments";
import { PreviewBox } from "../PreviewBox";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import styles from "../../views/pdfToolView/PdfToolView.module.scss";
import { ResultColumnFrame } from "../ResultColumnFrame";
import { UploadDocumentContainer } from "../UploadDocuments/UploadDocumentContainer";
import { PdfToolsContext, PdfToolsControlContext } from "../../context";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { DroppableIds } from "../../types";

type TProps = {
    children?: React.ReactNode;
    loadNewFile: (files: File[]) => Promise<void>;
    setDebugHTMLImages: (html: string) => void;
};

export const PdfToolsEditor: FC<TProps> = ({ children, loadNewFile, setDebugHTMLImages }) => {
    const [showDeletedPages, setShowDeletedPages] = useState(true);

    const { pages, viewDocuments } = useContext(PdfToolsContext);
    const actions = useContext(PdfToolsControlContext);

    const docsLength = viewDocuments.reduce((acc, doc) => (doc.visible ? acc + 1 : acc), 0);
    const isOneDoc = viewDocuments?.flatMap(el => el.pages).length < 2;

    const handleDragEnd = (e: DropResult) => {
        actions.saveDraggableId(null);
        if (!e.source || !e.destination) {
            return;
        }
        if (e.source.droppableId !== e.destination.droppableId) {
            if (
                e.destination.droppableId === DroppableIds.AllPages ||
                e.destination.droppableId === DroppableIds.InvisibleDnd
            ) {
                return actions.movePageToList(e);
            }

            if (e.destination.droppableId === DroppableIds.ParkedPages) {
                if (isOneDoc && e.source.droppableId !== DroppableIds.UploadedPages) {
                    return;
                }
                return actions.movePageToParking(e);
            }
        }
        if (e.source.index !== e.destination.index && e.destination.droppableId !== DroppableIds.ParkedPages) {
            actions.movePage(e.source.index - 1, e.destination.index - 1);
        }
    };

    return (
        <DragDropContext
            onDragEnd={handleDragEnd}
            onBeforeCapture={e => {
                actions.saveDraggableId(e.draggableId);
            }}
        >
            <>
                <Col span={4} className="FlexColumn">
                    <ScrollPagesTool showDeletedPages={showDeletedPages} />
                </Col>
                <Col
                    span={4}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <ActionButtons
                        setDebugInfo={debugInfo => setDebugHTMLImages(debugInfo)}
                        showDeletedPages={showDeletedPages}
                        toggleDeletedPages={show => setShowDeletedPages(show)}
                    />
                    <ParkedDocumentContainer />
                </Col>
                <Col span={9} style={{ height: "100%" }}>
                    <PreviewBox />
                </Col>
            </>

            <Col
                span={7}
                className="FlexColumn"
                style={{ position: "relative", height: "100%", padding: "5px 15px 5px 5px" }}
            >
                {children}
                <Flex className={cn({ "pdf-tools-scrollBlock-control": true })} justify={"space-between"} gap={10}>
                    <>
                        <div>
                            <strong className="pdf-tools-counter-title">
                                <FormattedMessage id="app.ocr.totalPages" />: {pages.length}
                            </strong>
                        </div>
                        <div>
                            <strong className="pdf-tools-counter-title">
                                <FormattedMessage id="app.ocr.documentsNumber" />: {docsLength}
                            </strong>
                        </div>
                    </>
                </Flex>

                <div className={styles.resultColumnFrameContainer}>
                    <div className={styles.resultColumnFrameWrapper}>
                        <ResultColumnFrame showDeletedPages={showDeletedPages} />
                        <UploadDocumentContainer uploadFile={loadNewFile} />
                    </div>
                </div>
            </Col>
        </DragDropContext>
    );
};
