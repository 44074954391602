import React from "react";

export const numberInputBlocker = (e: React.KeyboardEvent<HTMLInputElement>, positive?: boolean) => {
    const val: string = e.currentTarget.value;
    if (e.ctrlKey || e.key === "Tab") {
        return;
    }
    if (!/[0-9-]|(Backspace|ArrowRight|ArrowLeft)/.test(e.key)) {
        e.preventDefault();
    }
    if (val.includes("-") && e.key === "-") {
        e.preventDefault();
    }
    if (positive && e.key === "-") {
        e.preventDefault();
    }
};

export const floatInputBlocker = (e: React.KeyboardEvent<HTMLInputElement>, positive?: boolean) => {
    const val: string = e.currentTarget.value;

    if (e.ctrlKey || e.key === "Tab") {
        return;
    }
    if (!/[0-9,-]|(Delete|Backspace|ArrowRight|ArrowLeft)/.test(e.key)) {
        e.preventDefault();
    }
    if (val.includes(",") && e.key === ",") {
        e.preventDefault();
    }
    if (val.includes("-") && e.key === "-") {
        e.preventDefault();
    }

    if (positive && e.key === "-") {
        e.preventDefault();
    }
};
