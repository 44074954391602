import React, { ContextType, type Key, useContext, useEffect, useMemo, useState } from "react";
import { DmsDefaultSubType, DmsType, ITableDocument, TBindSubType } from "@dms/types";
import { ALL_DOCUMENTS } from "@dms/configs/constants";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { checkIsGroupType } from "@dms/scripts/helpers/checkIsGroupType";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { useTemplateData } from "../../../scripts/context/hooks/useTemplateData";
import { GQL } from "@binale-tech/shared";

export const DmsAppProvider = (props: { children: React.ReactNode | null | undefined }) => {
    const { documentTypes } = useContext(DmsDataContext);
    const initActiveType: string[] = useMemo(
        () => JSON.parse(localStorage.getItem("keyPath")) ?? [DmsType.new_documents],
        []
    );

    const initialOpenKey = useMemo(() => {
        const { type } = DmsUtils.getActiveTypeAndSubType(initActiveType);
        if (checkIsGroupType(type)) {
            return initActiveType;
        }
        return [""];
    }, [initActiveType]);
    const [isOpenSettingsCol, setIsOpenSettingsCol] = useState(false);
    const [activeType, setActiveType] = useState<string[]>([DmsType.new_documents]);
    const [view, viewToggle] = useState<ContextType<typeof DmsAppContext>["view"]>("list");
    const [isTypeChangeMode, setIsTypeChangeMode] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [focusedRow, setFocusedRow] = useState(null);
    const [dragID, setDragID] = useState("");
    const [isDraggingRightNow, setIsDraggingRightNow] = useState(false);
    const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
    const [downloadDocumentsModalOpen, setDownloadDocumentsModalOpen] = useState(false);
    const [bindModalData, setBindModalData] = useState<{
        isOpen: boolean;
        id?: string;
        type?: TBindSubType;
        currentVal?: string;
    }>({
        isOpen: false,
    });
    const [editTypeModalData, setEditTypeModalData] = useState<{
        isOpen: boolean;
        id?: string;
        type?: TBindSubType;
        currentVal?: string;
    }>({ isOpen: false });
    const [openedMenuItemKeys, setOpenedMenuItemKeys] = useState(initialOpenKey);
    const [searchValue, setSearchValue] = useState<string>("");
    const [loadDataSource, setLoadDataSource] = useState<boolean>(false);

    const { documents } = useContext(DmsDataContext);

    const { type, subType, groupId } = useMemo(() => {
        const { type: t, subType: st } = DmsUtils.getActiveTypeAndSubType(activeType);
        return {
            type: t,
            subType: st,
            groupId: DmsUtils.getGroupId(t as GQL.IProductKey, st, documentTypes),
        };
    }, [activeType, documentTypes]);
    const templates = useTemplateData(type as GQL.IProductKey, groupId);

    const folderDocuments: ITableDocument[] = useMemo(() => {
        return (documents ?? []).filter(el => {
            if (type === ALL_DOCUMENTS) {
                return true;
            }

            if (subType?.includes(DmsDefaultSubType.all_subTypes)) {
                return el.type === type;
            }

            if (subType?.includes(DmsDefaultSubType.no_subTypes)) {
                return el.type === type && !el.subType;
            }

            return el.type === type && el.subType === subType;
        });
    }, [documents, subType, type]);

    useEffect(() => {
        localStorage.removeItem("keyPath");
        setLoadDataSource(true);
    }, [activeType]);

    useEffect(() => {
        const folderDocumentKeys = new Set(folderDocuments.map(doc => doc.key));
        setSelectedRowKeys(prev => {
            return prev.filter(key => folderDocumentKeys.has(String(key)));
        });
    }, [folderDocuments]);

    const value: ContextType<typeof DmsAppContext> = useMemo(
        () => ({
            folderDocuments,
            view,
            activeType,
            isTypeChangeMode,
            selectedRowKeys,
            isDeleteMode,
            dragID,
            isDraggingRightNow,
            downloadDocumentsModalOpen,
            openedMenuItemKeys,
            isOpenSettingsCol,
            searchValue,
            focusedRow,
            bindModalData,
            editTypeModalData,
            loadDataSource,
            templates,
        }),
        [
            activeType,
            bindModalData,
            downloadDocumentsModalOpen,
            dragID,
            editTypeModalData,
            folderDocuments,
            isDeleteMode,
            isDraggingRightNow,
            isOpenSettingsCol,
            isTypeChangeMode,
            loadDataSource,
            openedMenuItemKeys,
            searchValue,
            selectedRowKeys,
            view,
            templates,
        ]
    );

    const action: ContextType<typeof DmsAppControlContext> = useMemo(
        () => ({
            resetAppContext: () => {
                setIsOpenSettingsCol(false);
                setActiveType([DmsType.new_documents]);
                viewToggle("list");
                setIsTypeChangeMode(false);
                setSelectedRowKeys([]);
                setDragID("");
                setIsDraggingRightNow(false);
                setIsDeleteMode(false);
                setDownloadDocumentsModalOpen(false);
                setOpenedMenuItemKeys([""]);
                setSearchValue("");
                setFocusedRow(null);
                setLoadDataSource(false);
            },
            selectRow: (key: Key) => {
                setSelectedRowKeys(prevState => {
                    return [...prevState, key];
                });
            },
            selectRows: setSelectedRowKeys,
            resetSelectedRows: () => {
                setSelectedRowKeys([]);
            },
            unselectRow: (key: Key) => {
                setSelectedRowKeys(prev => {
                    return prev.filter(el => el !== key);
                });
            },
            setActiveType,
            viewToggle,
            typeChangeModeToggle: setIsTypeChangeMode,
            deleteModeToggle: setIsDeleteMode,
            setDragID,
            toggleDraggingState: setIsDraggingRightNow,
            toggleDownloadDocumentsModal: setDownloadDocumentsModalOpen,
            setOpenedMenuItemKeys,
            toggleOpenMenuItem: (key: string) => {
                setOpenedMenuItemKeys(prev => {
                    if (prev.includes(key)) {
                        return [];
                    } else {
                        return [key];
                    }
                });
            },
            toggleIsOpenSettingsCol: setIsOpenSettingsCol,
            setSearchValue,
            setLoadDataSource,
            setFocusedRow,
            bindModalDataAction: setBindModalData,
            editTypeModalDataAction: setEditTypeModalData,
        }),
        []
    );

    return (
        <DmsAppContext.Provider value={value}>
            <DmsAppControlContext.Provider value={action}>{props.children}</DmsAppControlContext.Provider>
        </DmsAppContext.Provider>
    );
};
