import { PDFDocument } from "pdf-lib";

export async function createPdfFromImage(imageUrl: string) {
    const isPng = imageUrl.includes(".png?");
    const imageBuffer = await fetch(imageUrl).then(response => response.arrayBuffer());
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const image = isPng ? await pdfDoc.embedPng(imageBuffer) : await pdfDoc.embedJpg(imageBuffer);
    page.setSize(image.width, image.height);
    page.drawImage(image, {
        x: 0,
        y: 0,
        width: image.width,
        height: image.height,
    });

    return pdfDoc.save();
}
