import { gql } from "@apollo/client";

export const contactSave = gql`
    mutation contactSave($input: ContactUpdateInput!) {
        contactSave(input: $input)
    }
`;

export const contactDelete = gql`
    mutation contactDelete($input: CompanyEntityDeleteInput!) {
        contactDelete(input: $input)
    }
`;
