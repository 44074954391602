import React, { type FC, type ReactNode, useContext, useEffect, useState } from "react";
import { useDrop } from "react-dnd";

import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { DmsType, IDocumentEnriched } from "@dms/types";

interface IProps {
    children: ReactNode;
    id: string;
    onDropStyle: (isDrop: boolean) => void;
    typeKeyPath?: string[];
}

export const SidebarMenuItemDropWrapper: FC<IProps> = ({ children, id, onDropStyle, typeKeyPath }) => {
    const { documents } = useContext(DmsDataContext);
    const { dragID, selectedRowKeys } = useContext(DmsAppContext);
    const { resetSelectedRows, setDragID, toggleDraggingState } = useContext(DmsAppControlContext);

    const [dropType, setDropType] = useState<string[]>();

    const mutator = useGqlMutator();

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        item: id,
        accept: "ITEM",
        drop: () => {
            setDropType(typeKeyPath);
        },
        collect: monitor => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
        }),
    }));

    useEffect(() => {
        if (!dropType) {
            return;
        }

        if (dropType[0] === DmsType.trash) {
            handleDelete();
        } else {
            updateType();
        }

        setDropType(undefined);
        setDragID("");
        resetSelectedRows();
    }, [dragID]);

    useEffect(() => {
        toggleDraggingState(canDrop);
        onDropStyle(canDrop && isOver);
    }, [canDrop, isOver]);

    const handleUpdateDocument = async (updatedDocumentValue: IDocumentEnriched[]): Promise<void> => {
        await DocumentsApi.changeDocumentsType(updatedDocumentValue, mutator);
    };

    const updateType = async (): Promise<void> => {
        if (!dropType) {
            return;
        }

        const { type, subType } = DmsUtils.getActiveTypeAndSubType(dropType);

        if (selectedRowKeys.length === 0) {
            const document = documents
                .map(el => {
                    if (el.key === dragID) {
                        return { ...el, type, subType };
                    }
                    return el;
                })
                .filter(el => el.key === dragID);

            await handleUpdateDocument(document);
        } else {
            const newDocuments: IDocumentEnriched[] = documents.filter(document =>
                selectedRowKeys.includes(document.key)
            );
            const newTypeDocuments: IDocumentEnriched[] = newDocuments.map(el => {
                return { ...el, type, subType };
            });

            await handleUpdateDocument(newTypeDocuments);
        }
    };

    const handleDelete = async (): Promise<void> => {
        if (selectedRowKeys.length === 0) {
            const document = documents.filter(document => document.key === dragID);
            await DocumentsApi.sendToTrash(document, mutator);
            return;
        }

        const toTrashDocuments: IDocumentEnriched[] = documents.filter(document =>
            selectedRowKeys.includes(document.key)
        );
        await DocumentsApi.sendToTrash(toTrashDocuments, mutator);
    };

    return (
        <div style={{ width: "100%" }} ref={drop}>
            {children}
        </div>
    );
};
