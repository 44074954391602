import React, { FC } from "react";
import { Flex } from "antd";
import { PreviewParseDataToolbar } from "./components/PreviewParseDataToolbar";
import { PreviewParseDataTable } from "./components/PreviewParseDataTable";

export const PreviewParseDataModule: FC = () => {
    return (
        <Flex vertical gap={20} style={{ height: "100%" }}>
            <PreviewParseDataToolbar />
            <PreviewParseDataTable />
        </Flex>
    );
};
