import { BuTaxesSKR } from "../../../../scripts/models/BuTaxUtils";
import { UstSelectBlock } from "../../shared/form/FormBlocks";
import React, { FC, useContext, useMemo } from "react";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { GQL } from "@binale-tech/shared";
import { BuContext } from "../../../../scripts/context/BuContext";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { useProgramSettings } from "../hooks/useProgramSettings";
import { useFormProperties } from "../hooks/useFormProperties";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { useIsItemFieldDisabledOutsideModal } from "../hooks/useIsItemFieldDisabledOutsideModal";
import { useFormHandlerItemBu } from "../hooks/handlers/useFormHandlerItemBu";

type Props = {
    isModalMode?: boolean;
};
export const FormItemBu: FC<Props> = ({ isModalMode }) => {
    const isModalOnly = useIsItemFieldDisabledOutsideModal(isModalMode);
    const isDisabled = useFormIsRefDisabled();
    const { useExpertBu } = useProgramSettings();
    const { isUstDisabled, isUst13bDisabled } = useFormProperties();
    const { recordDate, editableRecordItem, itemValidationStates } = useContext(RecordFormStateContext);
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { onUstChange, onChange13b } = useFormHandlerItemBu();
    const { yearConfig } = useContext(CompanyContext);
    const { companyBuTimeframes } = useContext(BuContext);
    const { product } = useContext(TableViewContext);

    const validation = itemValidationStates.get(refsHtml.REF_iBU);
    const { skr, taxation } = yearConfig;

    const { itemCategoryCreditor, itemBu } = editableRecordItem;
    const { date, period } = recordDate;
    const year = date ? date.getFullYear() : yearConfig.year;

    const vatOptions = useMemo(() => {
        if (product.productKey() === GQL.IProductKey.ErA) {
            return BuTaxesSKR.getAnzahlungenBuTaxList(skr, year, period, companyBuTimeframes);
        }
        return BuTaxesSKR.getCategoryBuTaxListYear(itemCategoryCreditor, skr, year, companyBuTimeframes);
    }, [companyBuTimeframes, year, period, itemCategoryCreditor, product, skr]);

    if (taxation === GQL.ICompanyTaxation.Kust) {
        return null;
    }

    const isFieldDisabled = isModalOnly || isDisabled(refsHtml.REF_iBU);
    return (
        <UstSelectBlock
            ref={refsHtml.REF_iBU}
            options={vatOptions}
            value={isModalOnly ? undefined : itemBu}
            onChange={onUstChange}
            disabled={isFieldDisabled || isUstDisabled}
            disabled13b={isFieldDisabled || isUst13bDisabled}
            validationState={validation}
            useExpertBu={useExpertBu}
            value13b={isModalOnly ? undefined : editableRecordItem.itemUSt13b}
            onChange13b={onChange13b}
        />
    );
};
