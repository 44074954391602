import React from "react";
import { message } from "antd";

export const copyToClipboard = (target: HTMLElement) => {
    target.style.userSelect = "text";
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(target);
    selection.removeAllRanges();
    selection.addRange(range);
    navigator.clipboard.writeText(target.textContent);
};

export const handleCopyField = (e: React.MouseEvent<HTMLElement>, messageText: string) => {
    const text = e.currentTarget.textContent;
    if (text) {
        const target = e.currentTarget;
        const oldUserSelect = target.style.userSelect;
        copyToClipboard(target);
        message.info(messageText, 3, () => {
            target.style.userSelect = oldUserSelect;
        });
    }
};
