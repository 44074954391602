import Category from "./Category";
import { Base, Bu, GQL, Periods } from "@binale-tech/shared";
import { BuTaxDB } from "./Bu";
import { BuTimeframe } from "../context/BuContext";
import { logger } from "../infrastructure/logger";

export class BuTaxesSKR {
    protected static readonly TAX_BU_KU_DB: BuTaxDB = {
        bu: Bu.Bu.KU,
        text: "",
        scopes: Bu.BuScopes.ScopeAll,
        percent: 0,
    };
    static hasExtraTaxToCategory = (buTax: BuTaxDB) => {
        if (buTax.bu === Bu.Bu.BU92 || buTax.bu === Bu.Bu.BU95 || buTax.bu === Bu.Bu.BU120) {
            return true;
        }
        return false;
    };
    static isTaxOnTop = (buTax: BuTaxDB) => {
        if (BuTaxesSKR.hasExtraTaxToCategory(buTax)) {
            return true;
        }
        return Boolean(buTax.ust) && Boolean(buTax.vst);
    };

    protected static buTimeframesCache: {
        company?: GQL.ICompany;
        tf: BuTimeframe[];
    } = { tf: [] };

    static setBuTimeframesCacheValue(tf: BuTimeframe[], company: GQL.ICompany) {
        this.buTimeframesCache = { company, tf };
    }

    static dangerouslyGetBuTimeframesData(year: number) {
        const { tf: buTimeframes, company } = this.buTimeframesCache;
        const configs = company?.accountingConfigs || [];
        const config = configs.find(v => v.year === year);
        return {
            buTimeframes,
            taxation: config?.taxation,
            kontoExt: config?.kontoExt as Base.CompanyKontoExt,
        };
    }

    static getAnzahlungenBuTaxList(
        skr: number,
        year: number,
        period: number,
        buTimeframes: BuTimeframe[] = []
    ): BuTaxDB[] {
        const items = this.listBuTaxesForYearPeriod(skr, year, period, buTimeframes);
        return items.filter(value => [Bu.Bu.KU, Bu.Bu.BU8, Bu.Bu.BU9].includes(value.bu));
    }

    static getCategoryBuTaxListYear(
        v: Base.IExtNum,
        skr: number,
        year: number,
        buTimeframes: BuTimeframe[] = []
    ): BuTaxDB[] {
        const all = this.listBuTaxesForYearPeriod(skr, year, 1, buTimeframes);
        if (v instanceof Category) {
            switch (v.buScope) {
                case Bu.BuScope.M:
                    return all.filter(buTax => buTax.scopes.has(Bu.BuScope.M));
                case Bu.BuScope.V:
                    return all.filter(buTax => buTax.scopes.has(Bu.BuScope.V));
                case "AM":
                case "AV":
                case "SAV":
                case "SAM":
                    return all.filter(value => value.bu === v.buAuto);
                case "KU":
                    return all.filter(value => value.bu === Bu.Bu.KU);
            }
        }
        return all;
    }

    protected static EmptyMap = new Map();
    protected static EmptyList: BuTaxDB[] = [];

    static getBuTimeframeCategoriesByYear(
        skr: number,
        year: number,
        buTimeframes: BuTimeframe[]
    ): BuTimeframe["defaultCategories"] {
        const buTimeframe = this.getBuTimeframeYearPeriod(skr, buTimeframes, year, 1);
        return buTimeframe?.defaultCategories || this.EmptyMap;
    }

    static hasBuTimeframeCategoriesByYear(
        skr: number,
        year: number,
        buTimeframes: Pick<BuTimeframe, "skr" | "from" | "to">[]
    ) {
        const buTimeframe = this.getBuTimeframeYearPeriod(skr, buTimeframes as BuTimeframe[], year, 1);
        return Boolean(buTimeframe);
    }

    static getBuTimeframeYearPeriod(
        skr: number,
        buTimeframes: BuTimeframe[],
        year: number,
        period: number
    ): BuTimeframe {
        const { month } = Periods.getMonthAndDay(period);
        const date = new Date(year, month, 15);
        const timeFrame = buTimeframes.find((tf, idx) => {
            const { from, to } = tf;
            if (tf.skr !== skr) {
                return false;
            }
            if (date >= from && date < to) {
                return true;
            }
            return false;
        });
        return timeFrame;
    }

    static listBuTaxesForYearPeriod(
        skr: number,
        year: number,
        period: number,
        buTimeframes: BuTimeframe[] = []
    ): BuTaxDB[] {
        if (!buTimeframes?.length) {
            return this.EmptyList;
        }
        const buTimeframe = this.getBuTimeframeYearPeriod(skr, buTimeframes, year, period);
        if (!buTimeframe) {
            if (period !== undefined) {
                logger.error("No timeframe for this date", { skr, year, period, buTimeframes });
                console.trace();
            }
            return this.EmptyList;
        }

        return [this.TAX_BU_KU_DB, ...buTimeframe.items];
    }

    /**
     * @deprecated use getBuTaxYearPeriod instead
     * @param buToFind
     * @param skr
     * @param date
     * @param buTimeframes
     */
    static getBuTax(buToFind: Bu.Bu, skr: number, date: Date, buTimeframes: BuTimeframe[] = []): BuTaxDB {
        let month = 1;
        if (buTimeframes.length === 1 && ["202006-202100-3", "202006-2021000"].includes(buTimeframes[0].id)) {
            month = 7;
        }
        const items = this.listBuTaxesForYearPeriod(skr, date.getFullYear(), month, buTimeframes);
        const buTaxDb = items.find(v => v.bu === buToFind);
        if (!buTaxDb) {
            return this.TAX_BU_KU_DB;
        }
        return buTaxDb;
    }

    static getBuTaxYearPeriod(
        buToFind: Bu.Bu,
        skr: number,
        year: number,
        period: number,
        buTimeframes: BuTimeframe[] = []
    ): BuTaxDB {
        const items = this.listBuTaxesForYearPeriod(skr, year, period, buTimeframes);
        const buTaxDb = items.find(v => v.bu === buToFind);
        if (!buTaxDb) {
            return this.TAX_BU_KU_DB;
        }
        return buTaxDb;
    }
}
