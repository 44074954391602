import { DmsType } from "@dms/types";
import React, { type FC } from "react";

import { TAiResult } from "@dms/components/DocumentForm/types";

import { InvoicesInputs } from "./InvoicesInputs";
import { NewDocumentsInputs } from "./NewDocumentsInputs";
import { OtherInputs } from "./OtherInputs";

interface IProps {
    type: string;
    aiResult?: TAiResult;
}

export const DocumentInputs: FC<IProps> = ({ type, aiResult }) => {
    const inputSwitcher = (): React.ReactNode => {
        switch (type) {
            case DmsType.new_documents:
                return <NewDocumentsInputs />;

            case DmsType.ER:
            case DmsType.Deb:
                return <InvoicesInputs aiResult={aiResult} />;

            default:
                return <OtherInputs />;
        }
    };

    return <div style={{ width: "100%" }}>{inputSwitcher()}</div>;
};
