import React, { useContext } from "react";
import { Flex, Space } from "antd";

import { GlobalYearSelect } from "../../../../../components/shared/Toolbar/YearSelect";
import { VATContext, VATControlContext } from "../VatContext";
import { PeriodQuarterSelect } from "../../../../../components/shared/Toolbar/PeriodQuarterSelect";

const VatExportsToolbar: React.FC = () => {
    const { period } = useContext(VATContext);
    const { setPeriod } = useContext(VATControlContext);

    return (
        <Flex justify="space-between">
            <Space>
                <GlobalYearSelect />
                <PeriodQuarterSelect period={period} onChange={setPeriod} />
            </Space>
        </Flex>
    );
};

export default React.memo(VatExportsToolbar);
