import { Base, KontoNumUtils } from "@binale-tech/shared";

export default class Creditor implements Base.IExtNum {
    key?: string | null;
    static readonly DefaultLen: number = 5;

    readonly description?: string;

    constructor(
        public readonly num: string,
        public readonly name: string
    ) {}

    static unserialize(v: Record<string, unknown> | Creditor): Creditor {
        return Object.assign(new Creditor("", ""), v);
    }

    fixNum(ext: number): Base.IExtNum {
        return KontoNumUtils.fixNum(this, ext, Creditor.DefaultLen);
    }

    getExtNum(ext: number): string {
        return KontoNumUtils.getExtNum(this.num, ext, Creditor.DefaultLen);
    }

    getExtNumPrint(ext: number): string {
        return KontoNumUtils.getExtNumPrint(this.num, ext, Creditor.DefaultLen);
    }

    equalsTo(c: Creditor): boolean {
        return KontoNumUtils.equalsTo(this, c);
    }

    getDefaultLen() {
        return Creditor.DefaultLen;
    }
}

class CreditorDiv extends Creditor {
    getExtNum(ext: number) {
        return "Div.";
    }

    getExtNumPrint(ext: number): string {
        return "Div.";
    }
}

export const CREDITOR_DIV = new CreditorDiv("", "Div.");
export const CREDITOR_EMPTY = new Creditor("", "");

export class Debitor extends Creditor {
    static unserialize(v: Record<string, unknown> | Creditor): Creditor {
        return Object.assign(new Debitor("", ""), v);
    }
}

class DebitorDiv extends Debitor {
    getExtNum(ext: number) {
        return "Div.";
    }

    getExtNumPrint(ext: number): string {
        return "Div.";
    }
}

export const DEBITOR_DIV = new DebitorDiv("", "Div.");
export const DEBITOR_EMPTY = new Debitor("", "");
