import React, { FC } from "react";
import styles from "@banks/components/FormFields/FormFields.module.scss";
import { Typography } from "antd";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { DateInput } from "@banks/components/FormFields/components/DateInput";
import { FormattedMessage } from "react-intl";

const { Text } = Typography;

export const OpeningBalanceDateField: FC = () => {
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id={"app.fields.openingBalanceDate"} />
                </Text>
            }
            name={"openingBalanceDate"}
        >
            <DateInput />
        </FieldLabel>
    );
};
