import React, { FC, useContext, useState } from "react";
import { ApiOutlined, EyeInvisibleOutlined, SafetyCertificateOutlined, SettingOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Layout, Menu, Spin } from "antd";

import { ProfilePassword } from "./ProfilePassword";
import { ProfileSecurity } from "./ProfileSecurity";
import { ProfileSettings } from "./ProfileSettings";
import { UserContext } from "scripts/context/UserProvider";
import { DatevSettings } from "./DatevSettings/DatevSettings";
import { useSearchParams } from "react-router-dom";

enum Tabs {
    Settings = "settings",
    Datev = "datev",
    Password = "password",
    Security = "security",
}

export const ProfileView: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const user = useContext(UserContext);
    const [tabName, setTabName] = useState<Tabs>((searchParams.get("tab") as Tabs) || Tabs.Settings);
    const menuItems: React.ComponentProps<typeof Menu>["items"] = React.useMemo(
        () => [
            {
                key: Tabs.Settings,
                icon: <SettingOutlined />,
                label: <FormattedMessage id="app.titles.settings" />,
            },
            {
                key: Tabs.Datev,
                icon: <ApiOutlined />,
                label: "DATEV",
            },
            {
                key: Tabs.Password,
                icon: <EyeInvisibleOutlined />,
                label: <FormattedMessage id="app.global.password" />,
            },
            {
                key: Tabs.Security,
                icon: <SafetyCertificateOutlined />,
                label: <FormattedMessage id="app.titles.security" />,
            },
        ],
        []
    );
    return (
        <Layout style={{ height: "100%" }}>
            <Layout.Sider>
                <Menu
                    mode="inline"
                    onClick={e => {
                        const tab = e.key as Tabs;
                        setTabName(tab);
                        setSearchParams({ tab });
                    }}
                    style={{ height: "100%" }}
                    selectedKeys={[tabName]}
                    items={menuItems}
                />
            </Layout.Sider>
            <Layout.Content style={{ height: "100%", padding: 24 }}>
                <Spin spinning={user.isLoading}>
                    {tabName === Tabs.Settings && <ProfileSettings />}
                    {tabName === Tabs.Datev && <DatevSettings />}
                    {tabName === Tabs.Password && <ProfilePassword />}
                    {tabName === Tabs.Security && <ProfileSecurity />}
                </Spin>
            </Layout.Content>
        </Layout>
    );
};
