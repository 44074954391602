import { Base } from "@binale-tech/shared";
import { child, DatabaseReference, DataSnapshot, onValue, push, set, setWithPriority, update } from "firebase/database";
import { GenericRecord } from "../../models/GenericRecord";
import { LogItem } from "../../models/LogItem";
import { PathOpts } from "./Opts";
import { logger } from "../logger";

export interface IRepository<E extends Base.IKey, O extends PathOpts> {
    getByUid(uid: string): Promise<any>;

    setByUid(uid: string, v: E, opts?: O): Promise<any>;
}

export abstract class Repository<E, O extends PathOpts> implements IRepository<E, O> {
    constructor(protected readonly ref: DatabaseReference) {}

    protected getRef(path: string[] = []) {
        let ref = this.ref;
        path.forEach(p => {
            ref = child(ref, p);
        });
        return ref;
    }

    /**
     * getByUid
     * @param uid
     * @returns {Promise}
     */
    getByUid(uid: string): Promise<DataSnapshot> {
        return new Promise(resolve => onValue(child(this.ref, uid), snap => resolve(snap), { onlyOnce: true }));
    }

    /**
     * setByUid
     * @param uid
     * @param v
     * @param opts
     * @returns {Promise<any>}
     */
    setByUid(uid: string, v: E, opts?: O) {
        const enc = JSON.parse(JSON.stringify(v));
        opts = opts || ({} as any);

        const ref = child(this.getRef(opts.path), uid);
        if (opts.priority) {
            return setWithPriority(ref, enc, opts.priority);
        }
        return set(ref, enc);
    }
}

export interface IRepositoryKey<E, O extends PathOpts> extends IRepository<E, O> {
    save(v: E, opts?: O): Promise<string>;

    saveAll(v: E[], opts?: O): Promise<void>;

    removeSome(v: string[], opts?: O): Promise<void>;
}

export abstract class RepositoryKey<E extends Base.IKey, O extends PathOpts>
    extends Repository<E, O>
    implements IRepositoryKey<E, O>
{
    public save(v: E, opts?: O): Promise<string> {
        logger.log(v, opts);
        if (v.key) {
            return this.setByUid(v.key, v, opts).then(() => v.key);
        }

        const o = JSON.parse(JSON.stringify(v));
        const ref = push(this.getRef(opts.path));
        o.key = ref.key;
        if (opts.priority) {
            return setWithPriority(ref, o, opts.priority).then(_ => o.key);
        }
        return set(ref, o).then(_ => o.key);
    }

    public saveAll(v: E[], opts?: O): Promise<void> {
        const updates: Record<string, any> = {};
        v.forEach(o => {
            o = JSON.parse(JSON.stringify(o));
            updates[o.key] = o;
        });
        return update(this.getRef(opts.path), updates);
    }

    public removeSome(v: string[], opts?: O): Promise<void> {
        if (typeof v === "string") {
            v = [v];
        }
        const updates: Record<string, any> = {};
        v.forEach(o => {
            updates[o] = null;
        });
        return update(this.getRef(opts.path), updates);
    }
}

export class LogItemRepository<O extends PathOpts> extends RepositoryKey<GenericRecord, O> {
    list(opts: O) {
        return this.getByUid(opts.path.join("/")).then(v => {
            const obj = v.val() || {};
            return Object.keys(v.val() || {}).map(k => {
                const l = LogItem.unserialize(obj[k]);
                l.key = k;
                return l;
            });
        });
    }
}
