import React from "react";
import { VATProvider } from "./VatContext";
import { VatTable } from "./VatTable";
import { VatToolbar } from "./toolbar/VatToolbar";
import "./styles.css";
import VatExportsToolbar from "./toolbar/VatExportsToolbar";
import { Route, Routes } from "react-router-dom";
import { AppRoutes } from "../../../../../scripts/routing/routeConstants";
import { VatExports } from "./VatExports";

const VatView: React.FC = () => (
    <VATProvider>
        <div className="vat-table-wrapper">
            <div style={{ padding: 12, boxShadow: "0px 2px 5px 0px rgba(34, 60, 80, 0.4)" }}>
                <Routes>
                    <Route path={AppRoutes.UStVAOverview} element={<VatToolbar />} />
                    <Route path={AppRoutes.UStVAExports} element={<VatExportsToolbar />} />
                </Routes>
            </div>
            <Routes>
                <Route path={AppRoutes.UStVAOverview} element={<VatTable />} />
                <Route path={AppRoutes.UStVAExports} element={<VatExports />} />
            </Routes>
        </div>
    </VATProvider>
);
export default React.memo(VatView);
