const parse = (prop: string): number => parseFloat(prop) || 0;

export function getElementHeight(element: Element) {
    if (element === null) {
        return 0;
    }

    const elementStyle = window.getComputedStyle(element);
    const bounds = element.getBoundingClientRect();

    let height = bounds.height || 0;

    const ua = window.navigator.userAgent || "";
    const ie10orOlder = ua.includes("MSIE ");
    const ie11 = ua.includes("Trident");

    if (ie10orOlder || ie11) {
        const borderTop = parse(elementStyle.getPropertyValue("border-top-width"));
        const borderBottom = parse(elementStyle.getPropertyValue("border-bottom-width"));
        const marginBottom = parse(elementStyle.getPropertyValue("margin-bottom"));
        const paddingTop = parse(elementStyle.getPropertyValue("padding-top"));
        const paddingBottom = parse(elementStyle.getPropertyValue("padding-bottom"));

        height += borderTop + borderBottom + marginBottom + paddingTop + paddingBottom;
        return height;
    } else {
        return height;
    }
}
