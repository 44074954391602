import React, { useMemo } from "react";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { RecordsContext } from "../../../../scripts/context/recordsContext/RecordsCtx";
import { RecordsControlContext } from "../../../../scripts/context/recordsContext/RecordsControlCtx";
import { GQL } from "@binale-tech/shared";
import { ProductKey } from "../../../../scripts/models/Product";
import { RecordLogRepository } from "../../../../scripts/infrastructure/repository/grRepositories";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";

type Resolver = Pick<React.ContextType<typeof TableViewContext>, "moduleRecords" | "moduleActions" | "moduleLogLister">;
export const useModuleResolver = (pk: ProductKey): Resolver => {
    const { companyGQL } = React.useContext(CompanyContext);
    const allRecords = React.useContext(RecordsContext);
    const allActions = React.useContext(RecordsControlContext);
    return useMemo((): Resolver => {
        if (pk === GQL.IProductKey.Er) {
            return {
                moduleRecords: allRecords.recordsER.list || [],
                moduleActions: allActions.actionsER,
                moduleLogLister: key => RecordLogRepository.ER.list({ path: [companyGQL.id, key] }),
            };
        }
        if (pk === GQL.IProductKey.ErA) {
            return {
                moduleRecords: allRecords.recordsAZ.list || [],
                moduleActions: allActions.actionsAZ,
                moduleLogLister: key => RecordLogRepository.AZ.list({ path: [companyGQL.id, key] }),
            };
        }
        if (pk === GQL.IProductKey.Deb) {
            return {
                moduleRecords: allRecords.recordsDeb.list || [],
                moduleActions: allActions.actionsDeb,
                moduleLogLister: key => RecordLogRepository.Deb.list({ path: [companyGQL.id, key] }),
            };
        }
        throw new Error(`product ${pk} is not supported yet`);
    }, [allActions.actionsER, allRecords.recordsER.list, companyGQL.id, pk]);
};
