import React, { KeyboardEvent, useState } from "react";
import { CustomCellEditorProps } from "ag-grid-react";
import { Select } from "antd";
import { TInvoiceFormLineItem, TSelectEditorValue } from "@inv/types";

import styles from "@inv/modules/InvoiceTableModule/Editors/editors.module.scss";

interface IProps extends CustomCellEditorProps<TInvoiceFormLineItem> {
    values: TSelectEditorValue[];
    openSelect: boolean;
}

export const SelectEditor: React.FC<IProps> = ({ values, value, onValueChange, stopEditing, openSelect }) => {
    const [open, setOpen] = useState(openSelect);

    const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    const handleChange = (selectValue: string | number) => {
        onValueChange(selectValue);
        stopEditing();
        setOpen(false);
    };

    const onDropdownVisibleChange = (isOpen: boolean) => {
        if (isOpen) {
            return;
        }

        stopEditing();
        setOpen(false);
    };

    return (
        <Select
            open={open}
            style={{ height: "100%" }}
            variant="borderless"
            className={styles.agEditor}
            dropdownStyle={{ borderRadius: 4 }}
            value={value}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            options={values}
            onDropdownVisibleChange={onDropdownVisibleChange}
            autoFocus
        />
    );
};
