import { Select } from "antd";
import React, { type FC, useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { DmsAppContext, DmsDataContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { IDocumentEnriched } from "@dms/types";
import dayjs from "dayjs";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

const calculateDocumentYears = (docs: IDocumentEnriched[], accountingYears: number[] = []): number[] => {
    const docYears = new Set(docs.map(doc => dayjs(doc.createdAt).year()));
    const last5years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);
    const yearsSet = new Set([...last5years, ...accountingYears, ...docYears]);

    return [...yearsSet];
};

export const YearSelectBtn: FC = () => {
    const [yearOptions, setYearOptions] = useState<{ value: string; label: string }[]>([]);

    const { companyGQL } = useContext(CompanyContext);
    const { activeType, selectedRowKeys } = useContext(DmsAppContext);
    const { documents } = useContext(DmsDataContext);
    const { userConfig, setFilterConfig } = useContext(DmsUserSettingsContext);

    const documentYears = useMemo(
        () => calculateDocumentYears(documents, companyGQL?.accountingYears),
        [documents, companyGQL?.accountingYears]
    );

    const typeConfig = DmsUtils.getFilterConfig(userConfig.filterConfig, activeType);

    const intl = useIntl();

    useEffect(() => {
        const yearOptionsArr = documentYears.map(el => {
            return {
                value: el.toString(),
                label: el.toString(),
            } as {
                value: string;
                label: string;
            };
        });
        yearOptionsArr.unshift(
            { value: "all", label: intl.formatMessage({ id: "app.components.all" }) },
            { value: "empty", label: intl.formatMessage({ id: "app.dms.emptyDate" }) }
        );
        setYearOptions(yearOptionsArr);
    }, [documentYears, intl]);

    const handleChangeYear = (value: string): void => {
        const yearConfig: { year: string; month?: number } = {
            year: value.toString(),
        };
        if (value === "all" || value === "empty") {
            yearConfig.month = -1;
        }

        setFilterConfig(activeType, yearConfig);
    };

    return (
        <Select
            value={typeConfig?.year ?? "all"}
            onChange={handleChangeYear}
            options={yearOptions}
            style={{ minWidth: "120px" }}
            disabled={selectedRowKeys.length > 0}
        ></Select>
    );
};
