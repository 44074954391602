import React from "react";
import { AppRoutes } from "../routing/routeConstants";
import {
    BankOutlined,
    BookOutlined,
    ContactsOutlined,
    DeliveredProcedureOutlined,
    DownloadOutlined,
    EuroCircleOutlined,
    FileProtectOutlined,
    ScanOutlined,
    SolutionOutlined,
    UploadOutlined
} from "@ant-design/icons";
import { GQL } from "@binale-tech/shared";

export type ProductKey = GQL.IProductKey | "AccountingCommonOverview";
export class ProductKeys {
    static readonly ER: ProductKey = GQL.IProductKey.Er;
    static readonly ER_A: ProductKey = GQL.IProductKey.ErA; // virtual key, subset of ER: ER->Anzahlungen
    static readonly Deb: ProductKey = GQL.IProductKey.Deb;
    static readonly KB: ProductKey = GQL.IProductKey.Kb;
    static readonly Bank: ProductKey = GQL.IProductKey.Bank;
    static readonly FE: ProductKey = GQL.IProductKey.Fe;
    static readonly POS: ProductKey = GQL.IProductKey.Pos;
    static readonly LA: ProductKey = GQL.IProductKey.La;
    static readonly OCR: ProductKey = GQL.IProductKey.Ocr;
    static readonly Contacts: ProductKey = GQL.IProductKey.Contacts;
    static readonly DMS: ProductKey = GQL.IProductKey.Dms;
    static readonly Banks: ProductKey = GQL.IProductKey.Banks;
    static readonly Invoices: ProductKey = GQL.IProductKey.Invoices;
    static readonly AccountingCommon: ProductKey = "AccountingCommonOverview"; // virtual key for combined records overview like KontenAnsicht

    static all(): GQL.IProductKey[] {
        return [
            GQL.IProductKey.Er,
            GQL.IProductKey.Deb,
            GQL.IProductKey.Kb,
            GQL.IProductKey.Bank,
            GQL.IProductKey.Fe,
            GQL.IProductKey.Pos,
            GQL.IProductKey.La,
            GQL.IProductKey.Ocr,
            GQL.IProductKey.Contacts,
            GQL.IProductKey.Dms,
            GQL.IProductKey.DmsAi,
            GQL.IProductKey.Banks,
            GQL.IProductKey.Invoices,
        ];
    }
    static readOnlyUserAccessProducts(): GQL.IProductKey[] {
        return [GQL.IProductKey.Dms, GQL.IProductKey.DmsAi];
    }
}

const GroupList = ["buch", "pdf", "contacts", "dms", "banks", "invoices"];
export type GroupKey = (typeof GroupList)[number];

export class ProductGroups {
    static readonly Buch: GroupKey = "buch";
    static readonly PDF: GroupKey = "pdf";
    static readonly Contacts: GroupKey = "contacts";

    static readonly DMS: GroupKey = "dms";
    static readonly Banks: GroupKey = "banks";
    static readonly Invoices: GroupKey = "invoices";
}

interface ProductInfo {
    icon: React.ReactNode;
    link: string;
    group: GroupKey;
    pk?: ProductKey;
}

export class ProductDetails {
    static details: Record<ProductKey, ProductInfo> = {
        [ProductKeys.Deb]: { icon: <UploadOutlined />, link: AppRoutes.debitor, group: ProductGroups.Buch },
        [ProductKeys.ER]: { icon: <DownloadOutlined />, link: AppRoutes.invoice, group: ProductGroups.Buch },
        [ProductKeys.Bank]: { icon: <BankOutlined />, link: AppRoutes.bank, group: ProductGroups.Buch },
        [ProductKeys.KB]: { icon: <BookOutlined />, link: AppRoutes.kassenbuch, group: ProductGroups.Buch },
        [ProductKeys.FE]: {
            icon: <DeliveredProcedureOutlined />,
            link: AppRoutes.freieErfassung,
            group: ProductGroups.Buch,
        },
        [ProductKeys.POS]: { icon: <EuroCircleOutlined />, link: AppRoutes.pos, group: ProductGroups.Buch },
        [ProductKeys.LA]: { icon: <SolutionOutlined />, link: AppRoutes.paySlip, group: ProductGroups.Buch },

        [ProductKeys.OCR]: { icon: <ScanOutlined />, link: AppRoutes.ocr, group: ProductGroups.PDF },
        [ProductKeys.Contacts]: { icon: <ContactsOutlined />, link: AppRoutes.contacts, group: ProductGroups.Contacts },

        [ProductKeys.DMS]: { icon: <FileProtectOutlined />, link: AppRoutes.dms, group: ProductGroups.DMS },
        [ProductKeys.Banks]: { icon: <FileProtectOutlined />, link: AppRoutes.banks, group: ProductGroups.Banks },
        [ProductKeys.Invoices]: {
            icon: <FileProtectOutlined />,
            link: AppRoutes.invoices,
            group: ProductGroups.Invoices,
        },
    } as Record<ProductKey, ProductInfo>;

    static getDetails(pk: ProductKey): ProductInfo {
        if (!this.details[pk]) {
            throw new Error("invalid product key " + pk);
        }
        const res = this.details[pk];
        res.pk = pk;
        return res;
    }

    static getGroup(gk: GroupKey): ProductInfo[] {
        const productKeys = Object.keys(this.details) as ProductKey[];
        return productKeys.filter(k => this.details[k].group === gk).map(k => this.getDetails(k));
    }
}
