import React, { forwardRef, useEffect, useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { PickerRef } from "rc-picker";

type TProps = {
    onChange?: (v: string | undefined) => void;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
};

export const DateInput = forwardRef<PickerRef, TProps>(
    ({ value, onChange, placeholder, disabled, ...restProps }, ref) => {
        const [placeholderText, setPlaceholderText] = useState(placeholder);

        const handleOnChange = (date: dayjs.Dayjs | null): void => {
            onChange?.(date ? date.format("DD.MM.YYYY") : undefined);
        };
        const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
            if (e.key === "Enter" || (e.shiftKey && e.key === "Enter")) {
                e.preventDefault();
            }
        };

        useEffect(() => {
            if (placeholder === "Diverse" && !disabled && onChange) {
                onChange("");
                setPlaceholderText(undefined);
            }
        }, [disabled, placeholder]);

        return (
            <DatePicker
                {...restProps}
                format={"DD.MM.YYYY"}
                style={{ width: "100%" }}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
                value={value ? dayjs(value, "DD.MM.YYYY") : null}
                placeholder={placeholderText}
                disabled={disabled}
                ref={ref}
            />
        );
    }
);
