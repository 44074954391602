import React, { useCallback, useEffect, useState } from "react";
import { Base, KontoNumUtils } from "@binale-tech/shared";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { Category, CCDCombinedType } from "scripts/models";
import { CategoryUtils } from "../../../../../../scripts/models/utils/CategoryUtils";
import { CompanyContext } from "scripts/context/CompanyContext";
import { ExtNumCombobox, ExtNumComboboxProps } from "./ExtNumCombobox";

export interface CombinedComboboxProps<T extends Base.IExtNum> extends ExtNumComboboxProps<T> {
    altItems?: T[];
    disableAutoCategories?: boolean;
    disableSaldoCategories?: boolean;
    showAggregationCategories?: boolean;
}

const filterByName = (item: CCDCombinedType, val: string): boolean => {
    val = (val || "").toLowerCase();
    const subNum = val.substring(0, item.num.length);
    if (item.num.startsWith(subNum)) {
        return true;
    }
    const nameMatch = (item.name || "").toLowerCase().includes(val);
    if (/^\d+$/.test(val) && nameMatch) {
        return false;
    }
    return nameMatch;
};

export const CombinedCombobox: React.FC<CombinedComboboxProps<CCDCombinedType>> = ({
    value,
    onChange,
    altItems,
    items,
    disableAutoCategories,
    disableSaldoCategories,
    showAggregationCategories,
    ...props
}) => {
    const { yearConfig } = React.useContext(CompanyContext);
    const [innerValue, setInnerValue] = useState<CCDCombinedType>(undefined);
    const [innerItems, setInnerItems] = useState([]);

    const categoryFilter = useCallback(
        (item: CCDCombinedType) => {
            if (!(item instanceof Category)) {
                return true;
            }
            if (!showAggregationCategories && CategoryUtils.isAggregationCategory(item, yearConfig?.skr)) {
                return false;
            }
            if (disableAutoCategories) {
                if (item.buScope !== "KU" && item.isAutoBu()) {
                    return false;
                }
            }
            if (disableSaldoCategories) {
                const num = Number(item.getExtNum(0));
                if (num >= KontoNumUtils.SALDO_MIN && num <= KontoNumUtils.SALDO_MAX) {
                    return false;
                }
            }
            return true;
        },
        [disableAutoCategories, disableSaldoCategories, yearConfig?.skr]
    );

    const handleSearch = (query: string) => {
        if (!value && query && altItems) {
            const foundInItems = items.filter(category => filterByName(category, query)).length > 0;

            // console.log("handleSearch", { query, foundInItems, disableAutoCategories, disableSaldoCategories });
            if (!foundInItems && altItems?.length && query.length > 1) {
                const globalFilter = altItems.filter(item => filterByName(item, query) && categoryFilter(item));
                setInnerItems(globalFilter.slice(0, 100));
            } else {
                setInnerItems(items.filter(categoryFilter));
            }
        }
    };

    useEffect(() => {
        if (innerItems !== items && innerItems !== altItems) {
            setInnerItems(items.filter(categoryFilter));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, altItems]);

    useEffect(() => {
        if (value !== innerValue) {
            setInnerValue(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <ExtNumCombobox<CCDCombinedType>
            items={innerItems}
            value={innerValue}
            onChange={val => {
                setInnerValue(val);
                onChange(val);
            }}
            notFoundContent={
                <span>
                    <p>
                        <FormattedMessage id="app.messages.no_elements_found" />
                    </p>
                    <p>
                        <Link to={AppRoutes.manageAccountCategories}>
                            <Button type="primary" tabIndex={-1}>
                                <FormattedMessage id="app.items.create_category" />
                            </Button>
                        </Link>
                    </p>
                    <p>
                        <Link to={AppRoutes.manageAccountCreditors}>
                            <Button type="primary" tabIndex={-1}>
                                <FormattedMessage id="app.items.create_creditor" />
                            </Button>
                        </Link>
                    </p>
                    <p>
                        <Link to={AppRoutes.manageAccountDebitor}>
                            <Button type="primary" tabIndex={-1}>
                                <FormattedMessage id="app.items.create_debitor" />
                            </Button>
                        </Link>
                    </p>
                </span>
            }
            onSearch={handleSearch}
            {...props}
        />
    );
};
