import React, {
    ContextType,
    type FC,
    type PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { AppConfigUtils } from "@dms/scripts/utils/AppConfigUtils";
import { TFilterConfigItem, TTableCols } from "@dms/types";
import { DmsDataContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { UserContext } from "../../../scripts/context/UserProvider";
import ProgramSettings from "../../../scripts/models/ProgramSettings";

/**
 Context Provider for User config state
 */
export const DmsUserProvider: FC<PropsWithChildren> = ({ children }) => {
    const [columnsTableConfig, setColumnsTableConfig] = useState<
        ContextType<typeof DmsUserSettingsContext>["userConfig"]["columnsTableConfig"]
    >(new Map());
    const [filterConfig, setFilterConfig] = useState<
        ContextType<typeof DmsUserSettingsContext>["userConfig"]["filterConfig"]
    >(new Map());
    const [isTableSize, setIsTableSize] = useState<boolean>(false);
    const { documentTypes } = useContext(DmsDataContext);
    const { companyGQL } = useContext(CompanyContext);
    const { fireUser } = useContext(UserContext);

    // on mount/documentsType re-fetch column config and merge it with default
    useEffect(() => {
        if (!companyGQL?.id || !fireUser?.uid || documentTypes?.length === 0) {
            return;
        }

        ProgramSettings.getDmsConfig({
            userId: fireUser.uid,
            companyId: companyGQL.id,
        }).then(res => {
            const concatColumnConf = AppConfigUtils.concatColumnConfig(documentTypes, res.columnConfig);
            setColumnsTableConfig(concatColumnConf);

            const concatFilterConf = AppConfigUtils.concatFilterConfig(documentTypes, res.filterConfig);
            setFilterConfig(concatFilterConf);
        });
    }, [companyGQL?.id, documentTypes, fireUser?.uid]);

    const persistTableColumns = useCallback(
        async (config: Map<string, TTableCols[]>) => {
            const keys = {
                userId: fireUser.uid,
                companyId: companyGQL.id,
            };
            await ProgramSettings.updateDmsColumnConfig(keys, Object.fromEntries(config));
        },
        [companyGQL?.id, fireUser?.uid]
    );

    useEffect(() => {
        const handleResize = () => {
            setIsTableSize(document.documentElement.clientWidth < 1401);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const onSetFilterConfig = useCallback(
        async (typeKey: string[], configData: TFilterConfigItem) => {
            if (!companyGQL?.id || !fireUser?.uid) {
                return;
            }

            const keys = {
                userId: fireUser.uid,
                companyId: companyGQL.id,
            };

            const configKey = typeKey.toString();

            setFilterConfig(prev => {
                const typeConfig = prev.get(configKey);
                prev.set(configKey, { ...typeConfig, ...configData });

                const paginationConfig = new Map();

                prev.forEach((value, key) => {
                    if (value.paginationState) {
                        paginationConfig.set(key, {
                            paginationState: {
                                pageSize: value.paginationState.pageSize,
                                currentPage: value.paginationState.currentPage,
                            },
                        });
                    }
                });

                ProgramSettings.updateDmsFilterConfig(keys, Object.fromEntries(paginationConfig));
                return new Map(prev);
            });
        },
        [companyGQL?.id, fireUser?.uid]
    );

    const onSetColumnsTableConfig = useCallback(
        (typeKey: string[], configTableData: TTableCols[]) => {
            const configKey = typeKey.toString();
            setColumnsTableConfig(prev => {
                const typeConfig = prev.get(configKey);
                prev.set(configKey, { ...typeConfig, ...configTableData });
                persistTableColumns(prev);
                return new Map(prev);
            });
        },
        [persistTableColumns]
    );

    const deleteTypeConfig = useCallback((typeKey: string[]) => {
        const configKey = typeKey.toString();
        setFilterConfig(prev => {
            prev.delete(configKey);
            return new Map(prev);
        });
        setColumnsTableConfig(prev => {
            prev.delete(configKey);
            return new Map(prev);
        });
    }, []);

    const value: ContextType<typeof DmsUserSettingsContext> = useMemo(
        () => ({
            userConfig: { columnsTableConfig, filterConfig },
            setColumnsTableConfig: onSetColumnsTableConfig,
            setFilterConfig: onSetFilterConfig,
            deleteTypeConfig,
            isTableSize,
        }),
        [columnsTableConfig, deleteTypeConfig, filterConfig, isTableSize, onSetColumnsTableConfig, onSetFilterConfig]
    );

    return <DmsUserSettingsContext.Provider value={value}>{children}</DmsUserSettingsContext.Provider>;
};
