import { IDocumentEnriched } from "@dms/types";
import { Col, Row } from "antd";
import React, { type FC } from "react";

import { SingleFormModule } from "@dms/modules/SingleFormModule/SingleFormModule";

import { DocumentData } from "../DocumentData/DocumentData";
import { DocumentPdfViewer } from "@app/components/shared/PdfViewer/DocumentPdfViewer";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

interface IProps {
    documentData: IDocumentEnriched;
}

export const SingleDocumentLayout: FC<IProps> = React.memo(({ documentData }) => {
    return (
        <Row>
            <Col span={16} style={{ padding: "0 15px", display: "flex", justifyContent: "center" }}>
                <DocumentPdfViewer url={documentData?.fileUrl} filename={DmsUtils.getDownloadName(documentData)} />
            </Col>
            <Col span={8} style={{ paddingRight: 5 }}>
                <DocumentData documentData={documentData}>
                    <SingleFormModule documentInitialValue={documentData} />
                </DocumentData>
            </Col>
        </Row>
    );
});
