import React, { FC } from "react";
import dayjs from "dayjs";
import { Button, Col, DatePicker, Input, Row, Select } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { IFilterViewProps, SplitMode } from "../../../types";
import { SplitModeNames } from "../../../contants";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export const Filters: FC<IFilterViewProps> = ({ onDownloadCsv, onChangeFilter, filters }) => {
    const handleChangeDates = (dates: [dayjs.Dayjs, dayjs.Dayjs] | null) => {
        if (dates) {
            onChangeFilter({ ...filters, dates: dates[0] && dates[1] ? dates : undefined });
        } else {
            onChangeFilter({ ...filters, dates: undefined });
        }
    };

    const handleChangeModes = (modes: string[]) => {
        const splitModes = modes.map(mode => (mode === "null" ? null : Number(mode)));
        onChangeFilter({ ...filters, modes: modes.length ? splitModes : undefined });
    };

    const onSearchMail = (query: string) => {
        const queryStr = query.trim();
        onChangeFilter({ ...filters, email: queryStr ? queryStr : undefined });
    };

    const onSearchCompany = (query: string) => {
        const queryStr = query.trim();
        onChangeFilter({ ...filters, company: queryStr ? queryStr : undefined });
    };

    return (
        <Row justify="space-between" align="middle">
            <Col>
                <Row justify="start" gutter={8}>
                    <Col>
                        <div>Datum</div>
                        <RangePicker
                            onChange={handleChangeDates}
                            format="DD.MM.YYYY"
                            disabledDate={current => dayjs().isBefore(current)}
                            defaultValue={[dayjs().subtract(1, "month"), dayjs()]}
                            presets={[
                                { label: "This Month", value: [dayjs().startOf("month"), dayjs().endOf("month")] },
                                {
                                    label: "Last Month",
                                    value: [
                                        dayjs().subtract(1, "month").startOf("month"),
                                        dayjs().subtract(1, "month").endOf("month"),
                                    ],
                                },
                                {
                                    label: "Last Week",
                                    value: [
                                        dayjs().subtract(1, "week").startOf("week"),
                                        dayjs().subtract(1, "week").endOf("week"),
                                    ],
                                },
                            ]}
                        />
                    </Col>
                    <Col>
                        <div>Modul</div>
                        <Select
                            mode="multiple"
                            style={{
                                maxWidth: 400,
                                width: "100%",
                                minWidth: 180,
                            }}
                            onChange={handleChangeModes}
                            allowClear
                        >
                            {Object.keys(SplitModeNames).map(mode => (
                                <Option key={mode} label={SplitModeNames[mode as unknown as SplitMode]} value={mode}>
                                    {SplitModeNames[mode as unknown as SplitMode]}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col>
                        <div>Benutzer</div>
                        <Search
                            onSearch={onSearchMail}
                            style={{ width: 200 }}
                            onBlur={e => {
                                onSearchMail(e.target.value);
                            }}
                        />
                    </Col>
                    <Col>
                        <div>Unternehmen</div>
                        <Search
                            onSearch={onSearchCompany}
                            style={{ width: 200 }}
                            onBlur={e => {
                                onSearchCompany(e.target.value);
                            }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col>
                <Button icon={<DownOutlined />} onClick={onDownloadCsv}>
                    CSV
                </Button>
            </Col>
        </Row>
    );
};
