import { Col, ColorPicker, ColorPickerProps, Divider, Row } from "antd";
import React, { FC } from "react";
import { cyan, gold, lime, magenta, presetPalettes, purple, volcano, yellow } from "@ant-design/colors";
import type { AggregationColor } from "antd/es/color-picker/color";

interface IProps extends Omit<ColorPickerProps, "onChange" | "value"> {
    onChange?: (v: string) => void;
    value?: string;
}

type Presets = Required<ColorPickerProps>["presets"][number];

const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map<Presets>(([label, colors]) => ({ label, colors }));

const customPanelRender: ColorPickerProps["panelRender"] = (_, { components: { Picker, Presets } }) => (
    <Row justify="space-between" wrap={false}>
        <Col span={12}>
            <Presets />
        </Col>
        <Divider type="vertical" style={{ height: "auto" }} />
        <Col flex="auto">
            <Picker />
        </Col>
    </Row>
);

export const BankColorPicker: FC<IProps> = ({ onChange, value, ...rest }) => {
    const handleOnChange = (arg: AggregationColor) => {
        if (typeof arg !== "string") {
            onChange?.(arg.toHexString());
        }
    };

    const presets = genPresets({ volcano, gold, yellow, lime, cyan, purple, magenta });

    return (
        <ColorPicker
            defaultValue={value}
            presets={presets}
            defaultFormat={"hex"}
            styles={{ popupOverlayInner: { width: 700 } }}
            onChangeComplete={handleOnChange}
            panelRender={customPanelRender}
            //allowClear
            destroyTooltipOnHide
            {...rest}
        />
    );
};
