import React from "react";
import { Base } from "@binale-tech/shared";
import { FormattedMessage } from "react-intl";

import { FlexRow } from "../../../appearance/page/Scaffold";
import "./NumNameInfoBlock.css";

interface NumNameInfoBlockProps {
    numNameItems: [string | React.ReactNode, Base.INumName | string, number][];
}

export class NumNameInfoBlock extends React.PureComponent<NumNameInfoBlockProps> {
    private getNumAndName = (numname: Base.INumName | string) => {
        if (typeof numname === "string" && numname.length > 0) {
            return numname;
        }
        if (Boolean(numname) && ((numname as Base.INumName).num || "").length > 0) {
            return (numname as Base.INumName).name;
        }
        return <FormattedMessage id="app.components.not_selected" />;
    };

    render() {
        const items = this.props.numNameItems
            .filter(v => !!v)
            .map(([label, numName, flex], i) => (
                <div
                    key={"item-" + i}
                    className="NumNameInfoBlock__item"
                    style={{ flex: Number.isFinite(flex) ? flex : 1 }}
                >
                    <span className="NumNameInfoBlock__name">{label}:&nbsp;</span>
                    <span className="NumNameInfoBlock__value">{this.getNumAndName(numName)}</span>
                </div>
            ));
        return <FlexRow className="NumNameInfoBlock">{items}</FlexRow>;
    }
}
