import qrcode from "qrcode";
import { authenticator } from "@otplib/preset-default";
import { useContext, useEffect, useState } from "react";

import { UserContext } from "scripts/context/UserProvider";
import { getEnvCode } from "../../../../scripts/infrastructure/getEnvCode";
import { logger } from "scripts/infrastructure/logger";

export const useSecretQrCode = (secret: string) => {
    const user = useContext(UserContext);
    const [qrCode, setQrCode] = useState<string | null>(null);
    useEffect(() => {
        if (!user.fireUser || !secret) {
            setQrCode(null);
        }
    }, [user.fireUser, secret]);

    useEffect(() => {
        if (!user.fireUser) {
            return;
        }
        if (secret) {
            const issuer = getEnvCode() === "production" ? "Binale" : "Binale testing";
            const authKey = authenticator.keyuri(user.fireUser.email, issuer, secret);

            qrcode
                .toDataURL(authKey)
                .then(url => setQrCode(url))
                .catch(err => logger.crit(new Error("Error while using qrcode library"), { err }));
        }
    }, [secret, user.fireUser]);

    return qrCode;
};
