import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useDocumentTitle = (): Dispatch<SetStateAction<string>> => {
    const [documentTitle, setDocumentTitle] = useState<string>(document.title);

    useEffect(() => {
        document.title = documentTitle;
    }, [documentTitle]);

    useEffect(() => {
        if (document.title !== documentTitle) {
            setDocumentTitle(document.title);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document.title]);

    return setDocumentTitle;
};
