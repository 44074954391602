import React, { FC, useContext, useState } from "react";
import { Button, Col, Form, Row, Space, Tooltip } from "antd";
import { Contacts, GQL } from "@binale-tech/shared";
import {
    ControlBlock,
    CustomerInformationBlock,
    FooterBlock,
    HeaderBlock,
    InvoiceInputBlocks,
    LineItemsBlock,
} from "@inv/components/InvoiceForm/components/InvoiceInputBlocks";
import { InvoiceFormInputTranslate, InvoiceInputs, TContactFormFields, TInvoicesValues } from "@inv/types";
import { ContactsContext } from "scripts/context/ContactsContext";
import { InvoicesApi } from "@inv/scripts/api";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { useCanWrite } from "../../../scripts/infrastructure/hooks";
import { ModalSaveContact } from "@inv/components/InvoiceForm/components/InvoiceContact/ModalSaveContact";
import { ContactBlock } from "@inv/components/InvoiceForm/components/InvoiceInputBlocks/ContactBlock";

const autocompleteContactData = (contact?: Contacts.Contact): TContactFormFields => {
    const formData: TContactFormFields = {
        [InvoiceInputs.CITY]: null,
        [InvoiceInputs.COUNTRY_CODE]: null,
        [InvoiceInputs.STREET]: null,
        [InvoiceInputs.HOUSE_NUMBER]: null,
        [InvoiceInputs.ZIP_CODE]: null,
        [InvoiceInputs.ADDRESS]: null,
        [InvoiceInputs.CUSTOMER_NUMBER]: null,
        [InvoiceInputs.CUSTOMER_VAT]: null,
        [InvoiceInputs.CUSTOMER_TAX]: null,
        [InvoiceInputs.CUSTOMER_EMAIL]: null,
        [InvoiceInputs.CUSTOMER_NAME]: null,
        [InvoiceInputs.CURRENCY_CODE]: GQL.ICurrencyCode.Eur,
    };
    if (!contact) {
        return formData;
    }
    formData[InvoiceInputs.CUSTOMER_NAME] = Contacts.getLabelName(contact);

    if (contact.addresses && contact.addresses.length > 0) {
        const address = contact.addresses[0];
        if (address?.city) {
            formData[InvoiceInputs.CITY] = address.city;
        }
        if (address?.countryCode) {
            formData[InvoiceInputs.COUNTRY_CODE] = address.countryCode;
        }
        if (address?.street) {
            formData[InvoiceInputs.STREET] = address.street;
        }
        if (address?.house) {
            formData[InvoiceInputs.HOUSE_NUMBER] = address.house;
        }
        if (address?.postCode) {
            formData[InvoiceInputs.ZIP_CODE] = address.postCode;
        }
        if (address?.addressLine2) {
            formData[InvoiceInputs.ADDRESS] = address.addressLine2;
        }
    }
    if (contact.defaultCurrencyCode) {
        formData[InvoiceInputs.CURRENCY_CODE] = contact.defaultCurrencyCode;
    }

    const contactClient = contact.relations?.find(r => r.type === GQL.IContactRelation.Client);
    if (contactClient?.internalNumber) {
        formData[InvoiceInputs.CUSTOMER_NUMBER] = contactClient.internalNumber;
    }

    const customerEmail = contact.communications?.find(c => c.type === GQL.ICommunicationType.Email);
    if (customerEmail) {
        formData[InvoiceInputs.CUSTOMER_EMAIL] = customerEmail?.value;
    }
    const { legalInfo } = contact;
    if (legalInfo?.vat) {
        formData[InvoiceInputs.CUSTOMER_VAT] = legalInfo.vat;
    }
    if (legalInfo?.tax) {
        formData[InvoiceInputs.CUSTOMER_TAX] = legalInfo.tax;
    }

    return formData;
};

interface IProps {
    initialValues: TInvoicesValues;
}

export const InvoiceForm: FC<IProps> = ({ initialValues }) => {
    const [form] = Form.useForm<TInvoicesValues>();
    const { contactsMap } = useContext(ContactsContext);

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [contactSaveOpen, setContactSaveOpen] = useState(false);

    const values = Form.useWatch([], form);
    const shouldSuggestSaveContact =
        Boolean(values) &&
        !values.contactId &&
        Boolean(values.city && values.street && values.countryCode && values.zipCode && values.houseNumber);
    const hasContactsWriteAccess = useCanWrite(GQL.IProductKey.Contacts);

    const handleFinish = async (inputValues: TInvoicesValues) => {
        setIsLoading(true);
        try {
            const id = await InvoicesApi.invoiceSave({ ...inputValues });
            form.setFieldsValue({ id });
            setSuccess(true);
        } catch (error) {
            console.error(error);
            setSuccess(false);
        } finally {
            setIsLoading(false);
            setTimeout(() => setSuccess(false), 1000);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setSuccess(false);
    };

    const handleValuesChange: React.ComponentProps<typeof Form<TInvoicesValues>>["onValuesChange"] = (
        changedValues: Partial<TInvoicesValues>
    ) => {
        const contactId = changedValues[InvoiceInputs.CONTACT];
        if (contactId) {
            const contact = contactsMap.get(contactId);
            const autoCompletedData = autocompleteContactData(contact);
            form.setFieldsValue(autoCompletedData);
            form.validateFields([[InvoiceInputs.CUSTOMER_VAT, "vatNumber"]]);
        }
        const invoiceDate = changedValues[InvoiceInputs.DATE];
        if (invoiceDate) {
            const serviceDate = form.getFieldValue(InvoiceInputs.SERVICE_DATE);
            if (!serviceDate) {
                form.setFieldValue(InvoiceInputs.SERVICE_DATE, invoiceDate);
            }
        }
        const customerVat = changedValues[InvoiceInputs.CUSTOMER_VAT];
        if (customerVat) {
            if ("countryCode" in customerVat) {
                form.setFieldValue([InvoiceInputs.CUSTOMER_VAT, "vatNumber"], null);
                form.validateFields([[InvoiceInputs.CUSTOMER_VAT, "vatNumber"]]);
            }
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
            style={{ padding: "20px 0" }}
        >
            <Form.Item name="id" style={{ height: 0, margin: 0 }} />
            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <ContactBlock />
                </Col>
                <Col span={12} />
                <Col span={12}>
                    <CustomerInformationBlock />
                </Col>
                <Col span={12}>
                    <InvoiceInputBlocks />
                </Col>
                <Col span={12}>
                    {hasContactsWriteAccess && shouldSuggestSaveContact && (
                        <Space>
                            <Button onClick={() => setContactSaveOpen(true)}>Als Kontakt speichern</Button>
                            <Tooltip title={<FormattedMessage id={InvoiceFormInputTranslate.SAVE_CONTACT_HINT} />}>
                                <Button icon={<InfoCircleOutlined />} shape={"circle"} type={"text"} />
                            </Tooltip>
                            <ModalSaveContact open={contactSaveOpen} setOpen={setContactSaveOpen} />
                        </Space>
                    )}
                </Col>
                <Col span={24}>
                    <HeaderBlock />
                </Col>
                <Col span={24}>
                    <LineItemsBlock />
                </Col>
                <Col span={24}>
                    <FooterBlock />
                </Col>
                <ControlBlock success={success} isLoading={isLoading} handleCancel={handleCancel} />
            </Row>
        </Form>
    );
};
