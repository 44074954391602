export class CsvConverter {
    static getCsv(vs: string[][]) {
        let file = "";
        vs.forEach(line => {
            file += CsvConverter.toCsvRow(line);
        });
        return file;
    }

    static toCsvRow(row: any[]) {
        let finalVal = "";
        for (let j = 0; j < row.length; j++) {
            let innerValue = row[j] == null ? "" : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            }
            let result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0 || (typeof row[j] === "string" && /^\d+$/.test(innerValue))) {
                result = '"' + result + '"';
            }
            if (j > 0) {
                finalVal += ";";
            }
            finalVal += result;
        }
        return finalVal + "\n";
    }
}
