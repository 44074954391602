import React, { CSSProperties, FC, useContext, useEffect, useState } from "react";
import { Draggable, DraggableProvidedDraggableProps } from "react-beautiful-dnd";
import { OcrPage } from "../../../types";

import styles from "../../UploadDocuments/components/UploadDocumentItem.module.scss";
import { PdfToolsContext } from "../../../context";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import { POSITION } from "../../PagesCarousel/constants";
import { sizeCalcFromParent } from "../../../tools/helpers";

interface IProps {
    pages: OcrPage[];
    draggableId: string;
    idx: number;
    style?: CSSProperties;
    onClick?: (id: string) => void;
    afterChange?: (id: string) => void;
    position?: POSITION;
}

export const ParkedDocumentItem: FC<IProps> = ({ draggableId, pages, idx, onClick, style, position, afterChange }) => {
    const [size, setSize] = useState({ width: 0, height: 0 });
    const { draggableId: draggable, activePageId } = useContext(PdfToolsContext);

    const page = pages[0];

    useEffect(() => {
        function onResize() {
            const { width, height } = sizeCalcFromParent("carouselWrapper", 0.8);
            setSize({ width, height });
        }

        onResize();
        addEventListener("resize", onResize);

        if (position === POSITION.CURRENT) {
            afterChange?.(page.id);
        }

        return () => {
            removeEventListener("resize", onResize);
        };
    }, [position]);

    const isActiveFrontDocument = pages.map(el => el.id).includes(activePageId);
    const boxShadow = isActiveFrontDocument ? "0 0 2px 2px rgb(64, 169, 255)" : "unset";

    const draggablePropsTransform = (draggableProps: DraggableProvidedDraggableProps) => {
        return !draggable
            ? {
                  ...draggableProps,
                  style: { ...style, boxShadow, width: size.width, height: size.height },
              }
            : draggable === draggableId
              ? { ...draggableProps, style: { ...draggableProps.style, boxShadow } }
              : {
                    ...draggableProps,
                    style: {
                        ...style,
                        boxShadow,
                        width: size.width,
                        height: size.height,
                    } as CSSProperties,
                };
    };

    return position === POSITION.CURRENT ? (
        <Draggable draggableId={draggableId} index={idx}>
            {provided => {
                const draggableProps = draggablePropsTransform(provided.draggableProps);

                return (
                    <div
                        ref={provided.innerRef}
                        {...draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.uploadDocumentItemWrapper}
                        id={draggableId}
                        onClick={() => onClick(pages[0].id)}
                    >
                        <div
                            className={styles.uploadDocumentItemImg}
                            style={{
                                backgroundImage: `url(${page.imageUrl})`,
                            }}
                        ></div>
                        <Tooltip
                            destroyTooltipOnHide
                            placement="top"
                            className={styles.uploadDocumentItemTotalPagesWrapper}
                            title={
                                <>
                                    <FormattedMessage id="app.ocr.totalPages" /> {pages.length}
                                </>
                            }
                        >
                            {" "}
                            <div className={styles.uploadDocumentItemTotalPages}>
                                <FormattedMessage id="app.dms.pages" values={{ count: pages.length }} />
                            </div>
                        </Tooltip>
                    </div>
                );
            }}
        </Draggable>
    ) : (
        <div
            className={styles.uploadDocumentItemWrapper}
            style={{ ...style, width: size.width, height: size.height, transition: "all 0.6s" }}
        >
            <div
                className={styles.uploadDocumentItemImg}
                style={{
                    backgroundImage: `url(${page.imageUrl})`,
                }}
            ></div>
        </div>
    );
};
