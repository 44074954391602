import { DmsType, TBindSubType } from "@dms/types";
import { useCallback, useContext } from "react";
import { CompanyContext } from "../../scripts/context/CompanyContext";
import { DmsDataContext } from "@dms/types/ContextTypes";

export const useBindSubtype = () => {
    const { companyGQL } = useContext(CompanyContext);
    const { documentTypes } = useContext(DmsDataContext);
    return useCallback(
        (id: string, type: TBindSubType) => {
            if (type === DmsType.Bank) {
                if (!companyGQL?.bankList || !companyGQL?.bankList.length) {
                    return;
                }

                const bankId: string = companyGQL.bankList.filter(el => el.id === id)[0]?.id;

                if (!bankId) {
                    return;
                }

                const bank = documentTypes.find(el => el.id === type);
                return bank.subTypes.find(el => el.bankId === bankId)?.id;
            }

            if (type === DmsType.KB) {
                if (!companyGQL?.kasseList || !companyGQL?.kasseList.length) {
                    return;
                }

                const kbId: string = companyGQL.kasseList.filter(el => el.id === id)[0]?.id;

                if (!kbId) {
                    return;
                }

                const kb = documentTypes.find(el => el.id === type);
                return kb.subTypes.find(el => el.kbId === kbId)?.id;
            }
        },
        [companyGQL.bankList, companyGQL.kasseList, documentTypes]
    );
};
