import { useCallback, useContext } from "react";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { useFormValidators } from "../useFormValidators";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";

export const useFormHandlerItemBF2 = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setItemFieldErrorState } = useFormValidators();
    const { updateEditableItem } = useFormRecordStateUpdater();
    return useCallback(
        (itemBelegfeld2: string) => {
            updateEditableItem({ itemBelegfeld2 });
            setItemFieldErrorState(refsHtml.REF_iBELEGFELD2, false);
        },
        [refsHtml.REF_iBELEGFELD2, setItemFieldErrorState, updateEditableItem]
    );
};
