import React, { FC } from "react";
import { BankListContent } from "@banks/components";
import { BanksLitContextProvider } from "@banks/modules/BankListModule/context/BankListContext";

export const BankListModule: FC = () => {
    return (
        <BanksLitContextProvider>
            <BankListContent />
        </BanksLitContextProvider>
    );
};
