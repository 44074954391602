import React from "react";
import { AuswertungOposViewClass } from "../../productSharedComponents/auswertung/AuswertungViews";
import { AuswertungWrapper } from "../../productSharedComponents/auswertung/AuswertungWrapper";
import { CompanyContext } from "scripts/context/CompanyContext";
import { ProductAuswertungOposAvisDeb } from "scripts/core/Product";
import { useOposDefaultFilters } from "../../productSharedComponents/auswertung/auswertungDefaultFilters";

export const AuswertungOposViewDeb = React.memo(function AuswertungOposViewDeb() {
    const defaultFilters = useOposDefaultFilters();
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const product = React.useMemo(
        () => new ProductAuswertungOposAvisDeb(yearConfig.skr, companyGQL),
        [yearConfig, companyGQL]
    );
    return (
        <AuswertungWrapper
            Component={AuswertungOposViewClass}
            view="debOPOS"
            defaultFilters={defaultFilters}
            product={product}
        />
    );
});
