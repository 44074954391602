import React, { FC, useContext } from "react";
import { FormModalTable } from "../components/FormModalTable/FormModalTable";
import { useFormConfig } from "../hooks/useFormConfig";
import { RecordFormStateContext } from "../context/RecordFormState";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { GQL } from "@binale-tech/shared";
import { useProgramSettings } from "../hooks/useProgramSettings";
import { BuContext } from "../../../../scripts/context/BuContext";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";

type Props = {
    onItemDelete: (idx: number) => void;
    onItemClick: (idx: number) => void;
    tableFocusIdx: number | null;
    warning: React.ComponentProps<typeof FormModalTable>["showEuroBruttoWarning"];
    onShiftCent: React.ComponentProps<typeof FormModalTable>["onShiftCent"];
};
export const SplitCardTable: FC<Props> = ({ onItemDelete, warning, tableFocusIdx, onItemClick, onShiftCent }) => {
    const formConfig = useFormConfig();
    const programSettings = useProgramSettings();
    const { product } = useContext(TableViewContext);
    const { yearConfig } = useContext(CompanyContext);
    const { companyBuTimeframes } = useContext(BuContext);
    const { recordItems, recordDate, editableRecord } = useContext(RecordFormStateContext);
    return (
        <FormModalTable
            onDelete={onItemDelete}
            productFormConfig={formConfig}
            items={recordItems}
            recordData={{
                period: recordDate.period,
                year: recordDate.date.getFullYear(),
                recordKonto: editableRecord.recordCategoryCreditor,
                product: product.productKey() as GQL.IProductKey,
            }}
            recordCurrency={editableRecord.recordCurrency}
            activeIdx={tableFocusIdx}
            programSettings={programSettings}
            yearConfig={yearConfig}
            onItemClick={onItemClick}
            buTimeframes={companyBuTimeframes}
            showEuroBruttoWarning={warning}
            onShiftCent={onShiftCent}
        />
    );
};
