import Menu, { MenuProps } from "antd/es/menu";
import React from "react";
import { AppRoutes } from "scripts/routing/routeConstants";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

type Props = { menuProps: MenuProps };
const ModuleUStVALinks: React.FC<Props> = ({ menuProps }) => {
    const { pathname = "" } = useLocation();
    const [searchParams] = useSearchParams();

    return (
        <Menu
            selectedKeys={[pathname]}
            {...menuProps}
            items={[
                {
                    key: AppRoutes.UStVA + AppRoutes.UStVAOverview,
                    label: (
                        <Link to={AppRoutes.UStVA + AppRoutes.UStVAOverview + "?" + searchParams}>
                            <FormattedMessage id={"app.titles.Vat"} />
                        </Link>
                    ),
                },
                {
                    key: AppRoutes.UStVA + AppRoutes.UStVAExports,
                    label: (
                        <Link to={AppRoutes.UStVA + AppRoutes.UStVAExports + "?" + searchParams}>
                            <FormattedMessage id={"app.titles.data_export"} />
                        </Link>
                    ),
                },
            ]}
        ></Menu>
    );
};

export default React.memo(ModuleUStVALinks);
