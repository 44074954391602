import React, { FC, useContext } from "react";
import dayjs from "dayjs";
import { BuContext } from "scripts/context/BuContext";
import { Card, List } from "antd";
import { Button as NUIButton } from "@nextui-org/button";
import { PageFullScreen } from "../components/shared/appearance/page";

export const TestView: FC = () => {
    const { companyBuTimeframes } = useContext(BuContext);
    return (
        <PageFullScreen style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 10, paddingBottom: 5 }}>
            <h3>Ocr Test page</h3>
            <div>
                <NUIButton size="lg" variant="light">
                    btn
                </NUIButton>
            </div>

            <List
                grid={{ gutter: 16, column: 2 }}
                dataSource={companyBuTimeframes}
                renderItem={buTf => (
                    <List.Item>
                        <Card
                            title={`SKR ${buTf.skr} [${dayjs(buTf.from).format("DD.MM.YYYY")}, ${dayjs(buTf.to).format(
                                "DD.MM.YYYY"
                            )})`}
                        >
                            <List
                                bordered
                                dataSource={buTf.items}
                                renderItem={({ scopes, ...item }) => (
                                    <List.Item>
                                        <pre>{JSON.stringify(item, null, 2)}</pre>
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </List.Item>
                )}
            />
        </PageFullScreen>
    );
};
