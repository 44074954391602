import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

const getNormalizedTime = (time: number): string => {
    if (time < 10) {
        return `0${time}`;
    }
    return time.toString();
};

export const Clock: React.FC = () => {
    const [showSeparator, setShowSeparator] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowSeparator(show => !show);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <span className="Clock">
            <span className="Clock__time">
                {getNormalizedTime(new Date().getHours())}
                <span className="Clock__separator" style={{ opacity: showSeparator ? 1 : 0 }}>
                    :
                </span>
                {getNormalizedTime(new Date().getMinutes())}
            </span>
            <span className="Clock__date">{dayjs().format("dddd, DD.MM.YYYY")}</span>
        </span>
    );
};
