import React, { FC } from "react";

import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { DocumentNumberInput } from "@app/components/shared/form/baseComponents/DocumentNumberInput/DocumentNumberInput";
import styles from "@banks/components/FormFields/FormFields.module.scss";
import { Typography } from "antd";
import { FormattedMessage } from "react-intl";

const { Text } = Typography;

const INPUT_MAX_LENGTH = 36;

export const TransactionRechnungNumberField: FC = () => {
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id={"app.fields.rechnung_num"} />
                </Text>
            }
            name={"belegfeld1"}
        >
            <DocumentNumberInput maxLength={INPUT_MAX_LENGTH} showCount />
        </FieldLabel>
    );
};
