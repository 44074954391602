import jsQR, { Options } from "jsqr";
import * as tf from "@tensorflow/tfjs";
import { Subject } from "rxjs";
import { aiScan, applyContrast } from "./functions";
import { logger } from "scripts/infrastructure/logger";

export interface SearchResult {
    validation: string;
    caseCodes?: SearchResultCase[];
}
interface SearchResultCase {
    rect: { x: number; y: number; height: number; width: number };
    details: CodeValidation;
}
interface CodeValidation {
    imgData: ImageData;
    ai?: boolean;
    qr?: boolean;
    ocr?: boolean;
    score: number;
    validation: string;
}
export class AISearch {
    protected worker: Worker;
    protected promMap = new Map<string, Subject<Map<ImageData, string>>>();

    protected model: tf.LayersModel = null;
    protected version = "";

    constructor(version: string) {
        this.version = version;
        this.load();
    }
    protected static instance: AISearch;
    static getInstance(version: string) {
        if (!this.instance) {
            this.instance = new AISearch(version);
        }
        return this.instance;
    }

    protected load() {
        const name = this.version ? "model-" + this.version : "model";
        tf.loadLayersModel(`//${document.location.host}/mlres/${name}.json`).then(model => (this.model = model));
    }

    dispose() {
        try {
            if (this.model) {
                this.model.dispose();
                logger.log("disposed model");
            }
            if (this.worker) {
                this.worker.terminate();
                logger.log("disposed worker");
            }
        } catch (e) {
            logger.log("some error on cleanup", e);
        }
    }

    canvasSegmentSearch = async (
        origCanvas: HTMLCanvasElement,
        useQR?: boolean,
        IMG_SIZE = 256
    ): Promise<SearchResult> => {
        const t = Date.now();
        const { height, width } = origCanvas;
        // 150dpi
        const PAGE_MAX_W = 1240;
        // const PAGE_MAX_H = 1754;
        // void PAGE_MAX_W;
        // void PAGE_MAX_H;
        const ratio = height / width;
        const w = PAGE_MAX_W;
        const h = Math.floor(w * ratio);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d", { alpha: false });

        canvas.height = h;
        canvas.width = w;
        ctx.drawImage(origCanvas, 0, 0, width, height, 0, 0, w, h);
        logger.log("descaled from", { height, width }, "to", { w, h }, "in", Date.now() - t);

        const step = IMG_SIZE - IMG_SIZE / 4;
        interface IRow {
            x: number;
            y: number;
            width: number;
            height: number;
        }
        const rows: IRow[][] = [];
        for (let top = 0; top < canvas.height - step; top += step) {
            const row: IRow[] = [];
            for (let left = 0; left < canvas.width - step; left += step) {
                if (top > canvas.height - IMG_SIZE) {
                    top = canvas.height - IMG_SIZE;
                }
                if (left > canvas.width - IMG_SIZE) {
                    left = canvas.width - IMG_SIZE;
                }
                const rect = { x: left, y: top, width: IMG_SIZE, height: IMG_SIZE };
                row.push(rect);
            }
            rows.push(row);
        }
        for (const row of rows) {
            const cases = row.map(rect => ({
                rect,
                imgData: ctx.getImageData(rect.x, rect.y, rect.width, rect.height),
            }));
            const aiCodes = await this.aiScan(cases.map(c => c.imgData));
            const valRes = this.validateAiCodes(aiCodes, useQR, false);

            logger.log("valRes", valRes);
            const caseCodes: SearchResultCase[] = cases.map(c => ({
                rect: c.rect,
                details: valRes.codeMap.get(c.imgData),
            }));
            const hasValidations = caseCodes.find(v => v.details && v.details.score > 0.5);
            const validation = hasValidations ? hasValidations.details.validation : null;
            if (validation) {
                return { caseCodes, validation };
            }
        }
        return { validation: null };
    };

    protected validateAiCodes = (aiCodes: Map<ImageData, boolean>, useQR: boolean, useOcr: boolean) => {
        const imageDatas = Array.from(aiCodes.keys());
        const res = {
            codeMap: new Map<ImageData, CodeValidation>(),
        };
        for (const i in imageDatas) {
            const imgData = imageDatas[i];
            const aiCode = aiCodes.get(imgData);
            const codeValidation: CodeValidation = {
                imgData,
                score: 0,
                validation: "",
            };
            if (aiCode) {
                codeValidation.ai = true;
                let qrCode = "";
                if (useQR) {
                    qrCode = this.sequentialQrScan(imgData);
                    if (qrCode === "binale") {
                        codeValidation.qr = true;
                    }
                }
                codeValidation.score = this.getScore(codeValidation, useQR, useOcr);
                logger.log("codeValidation", codeValidation);

                if (codeValidation.score > 0) {
                    codeValidation.validation = [
                        codeValidation.ai ? "ai" : null,
                        codeValidation.ocr ? "ocr." + qrCode : null,
                        codeValidation.qr ? "qr" : null,
                    ]
                        .filter(v => !!v)
                        .join("/");
                }
            }
            res.codeMap.set(imgData, codeValidation);
            if (codeValidation.score > 0) {
                break;
            }
        }
        return res;
    };

    protected getScore(v: CodeValidation, useQR: boolean, useOcr: boolean) {
        let score = v.ai ? 1 : 0;
        let metrics = 1;
        if (useQR) {
            metrics++;
            if (v.qr) {
                score++;
            }
        }
        if (useOcr) {
            metrics++;
            if (v.ocr) {
                score++;
            }
        }
        return +(score / metrics).toFixed(2);
    }

    // aiScan ~400ms
    protected aiScan = (images: ImageData[]) => {
        const t = Date.now();
        const res = new Map<ImageData, boolean>();
        aiScan(this.model, images).forEach((v, k) => {
            res.set(k, !!v);
        });
        logger.log("sync scan", "in", Date.now() - t);
        return res;
    };

    // aiScanWorker - slow ~4s
    protected aiScanWorker = (images: ImageData[]): Promise<Map<ImageData, string>> => {
        const t = Date.now();
        const uuid = Math.random().toString();
        const subj = new Subject<Map<ImageData, string>>();
        this.promMap.set(uuid, subj);
        this.worker.postMessage({ uuid, images });
        logger.log("set to subject", uuid);
        return new Promise<Map<ImageData, string>>(resolve => {
            const subs = subj.subscribe(data => {
                subs.unsubscribe();
                subj.unsubscribe();
                this.promMap.delete(uuid);
                logger.log("received from subject", "in", Date.now() - t, data);
                resolve(data);
            });
        });
    };

    protected sequentialQrScan = (imgData: ImageData) => {
        const firstRes = this.scanJsQr(imgData);
        logger.log({ firstRes });
        if (firstRes) {
            return firstRes;
        }
        const data = applyContrast(imgData, 50);
        // const canvas = document.createElement("canvas");
        // canvas.height = imgData.height;
        // canvas.width = imgData.width;
        // const ctx = canvas.getContext("2d");
        // ctx.putImageData(imgData, 0, 0, 5, 5, imgData.width - 10, imgData.height - 5);
        // const data = ctx.getImageData(0, 0, imgData.height, imgData.width);
        const secondRes = this.scanJsQr(data);
        logger.log({ secondRes });
        return secondRes;
    };

    protected scanJsQr = (imgData: ImageData, options?: Options) => {
        options = options || { inversionAttempts: "dontInvert" };
        const code = jsQR(imgData.data, imgData.width, imgData.height, options);

        if (!code) {
            return null;
        }
        logger.log("_scanJsQr", code);
        return code.data;
    };
}
