import React from "react";
import { DEFAULT_FILTER_KEY, TableFiltersContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { GenericRecord } from "../../../../scripts/models";
import { PaymentsContext } from "scripts/context/PaymentsProvider";

export const useAvisDefaultFilters = () => {
    const { recordRelation } = React.useContext(PaymentsContext);
    return React.useMemo<React.ContextType<typeof TableFiltersContext>["filters"]>(() => {
        return new Map([
            [
                DEFAULT_FILTER_KEY,
                {
                    predicate: (record: GenericRecord) =>
                        record.avis && record.getOpenBrutto(recordRelation.get(record.key) || []) !== 0,
                    value: true,
                },
            ],
        ]);
    }, [recordRelation]);
};

export const useFalligkeitDefaultFilters = () => {
    const { recordRelation } = React.useContext(PaymentsContext);
    return React.useMemo<React.ContextType<typeof TableFiltersContext>["filters"]>(() => {
        return new Map([
            [
                DEFAULT_FILTER_KEY,
                {
                    predicate: (record: GenericRecord) =>
                        Boolean(record.falligkeit) && record.getOpenBrutto(recordRelation.get(record.key) || []) !== 0,
                    value: true,
                },
            ],
        ]);
    }, [recordRelation]);
};

export const useOposDefaultFilters = () => {
    const { recordRelation } = React.useContext(PaymentsContext);
    return React.useMemo<React.ContextType<typeof TableFiltersContext>["filters"]>(() => {
        const d = new Date();
        d.setHours(23, 59, 59, 999);
        return new Map([
            [
                DEFAULT_FILTER_KEY,
                {
                    predicate: (record: GenericRecord) =>
                        record.getOpenBrutto(recordRelation.get(record.key) || []) !== 0,
                    value: true,
                },
            ],
            ["dateTo", { predicate: (record: GenericRecord) => record.date < d, value: d }],
        ]);
    }, [recordRelation]);
};
