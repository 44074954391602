import React, { FC, useRef } from "react";
import { Typography } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { CsvParser } from "@banks/scripts/utils";
import styles from "./BankDataUploader.module.scss";
import { TParseTransaction } from "@banks/types";

const { Text } = Typography;

type TProps = {
    setParseResult: (arg: Record<string, string>[]) => void;
};

export const BankDataUploader: FC<TProps> = ({ setParseResult }) => {
    const ref = useRef(null);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];
        const result = await CsvParser.fileParser(file);
        setParseResult(result as TParseTransaction[]);
        event.target.value = null;
    };

    const handleDrag = async (event: React.DragEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];

        if (file.type === "text/csv" || file.type === "application/vnd.ms-excel") {
            const result = await CsvParser.fileParser(file);
            setParseResult(result as TParseTransaction[]);
        } else {
            throw "File is not supported";
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClick = () => {
        ref.current.click();
    };

    return (
        <div
            onClick={handleClick}
            onDrop={event => handleDrag(event)}
            onDragOver={event => handleDragOver(event)}
            role={"button"}
            className={styles.uploaderWrapperInner}
        >
            <InboxOutlined style={{ color: "#1677ff", fontSize: 48 }} />
            <Text style={{ fontSize: "1.1rem", marginTop: 16 }}>
                <FormattedMessage id="app.dms.clickOrDrug" />
            </Text>
            <Text type={"secondary"} style={{ fontSize: "1rem" }}>
                only CSV format file
            </Text>
            <input
                ref={ref}
                type="file"
                style={{ display: "none" }}
                accept={".csv"}
                onChange={event => handleChange(event)}
            />
        </div>
    );
};
