import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, message, Modal, Row, Space } from "antd";
import { Contacts } from "@binale-tech/shared";
import { FormattedMessage, useIntl } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";

import { ContactForm } from "./form";
import { ContactTable } from "./table";
import { ContactsControlContext } from "scripts/context/ContactsContext";
import { logger } from "scripts/infrastructure/logger";
import "./style.css";

export const EditContactView = React.memo(function EditContactView() {
    const [showContactForm, setShowContactForm] = useState(false);
    const [activeContact, setActiveContact] = useState<Contacts.Contact>(null);
    const [defaultInternalName, setDefaultInternalName] = useState("");
    const { saveContact, deleteContact } = useContext(ContactsControlContext);

    const intl = useIntl();

    const closeModal = React.useCallback(() => {
        setShowContactForm(false);
        setActiveContact(null);
        setDefaultInternalName("");
    }, []);

    const handleSave = React.useCallback(
        async (values: Contacts.Contact) => {
            try {
                await saveContact(values);
                closeModal();
            } catch (e) {
                logger.debug("An error occures while saving contact: ", e);
                message.error("Error while saving contact!");
            }
        },
        [closeModal, saveContact]
    );

    const modalTitle = useMemo(() => {
        if (!activeContact) {
            return intl.formatMessage({ id: "app.contacts.new" });
        }
        return Contacts.getLabelName(activeContact);
    }, [activeContact, intl]);

    // this useEffect for dms module
    useEffect(() => {
        const url = new URL(window.location.href);
        const modalParam = url.searchParams.get("modal");

        if (modalParam) {
            setDefaultInternalName(modalParam);
            setShowContactForm(true);
            url.searchParams.delete("modal");
            setTimeout(() => window.history.pushState({}, "", url.toString()), 1000);
        }
    }, []);

    return (
        <div className="Contacts__container">
            <Row align="middle" gutter={24}>
                <Col>
                    <Space style={{ marginTop: 12, marginBottom: 12 }}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setShowContactForm(true)}
                            data-testid="addContact"
                        >
                            <FormattedMessage id="app.titles.Contact" tagName="span" />
                        </Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <ContactTable
                        onEdit={contact => {
                            setActiveContact(contact);
                            setShowContactForm(true);
                        }}
                        onDelete={contact => deleteContact(contact.uuid)}
                    />
                </Col>
            </Row>

            <Row gutter={32} style={{ flexGrow: 1 }}>
                <Modal
                    onCancel={closeModal}
                    width="90%"
                    style={{ maxWidth: "1000px" }}
                    open={showContactForm}
                    centered
                    destroyOnClose={true}
                    maskClosable={false}
                    styles={{
                        body: { backgroundColor: "#f5f5f5" },
                    }}
                    footer={null}
                    data-testid="contactModal"
                    title={<b>{modalTitle}</b>}
                >
                    <ContactForm
                        item={activeContact}
                        onSave={handleSave}
                        onCancel={closeModal}
                        defaultInternalName={defaultInternalName}
                    />
                </Modal>
            </Row>
        </div>
    );
});
