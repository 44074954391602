import React, { FC, useContext } from "react";
import { Button, Flex, Popconfirm } from "antd";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import styles from "./TableActions.module.scss";
import { BanksAppControlContext } from "@banks/scripts/context";
import { BanksApi } from "@banks/scripts/api";

import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";

interface IProps {
    transaction: GQL.ITransaction;
}

export const TableActions: FC<IProps> = ({ transaction }) => {
    const { bankId, id, companyId } = transaction;
    const { setEditTransactionData } = useContext(BanksAppControlContext);
    const transactionData = id && bankId ? { transactionId: id, bankId } : null;

    const handleConfirmDelete = () => {
        BanksApi.transactionDelete({ bankId, ids: [id], companyId });
    };

    return (
        <Flex align={"center"} justify={"center"} className={styles.actions}>
            <Button
                icon={<FormOutlined />}
                style={{ padding: 0 }}
                className={styles.actionsItem}
                type="link"
                onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    setEditTransactionData({
                        transactionData,
                        isModalOpen: true,
                    });
                }}
            />
            <Popconfirm
                placement="topRight"
                title={<FormattedMessage id="app.confirmation.header" />}
                onConfirm={handleConfirmDelete}
                okText={<FormattedMessage id="app.button.confirm" />}
                cancelText={<FormattedMessage id="app.button.cancel" />}
            >
                <Button icon={<DeleteOutlined />} style={{ padding: 0 }} className={styles.actionsItem} type="link" />
            </Popconfirm>
        </Flex>
    );
};
