import React from "react";
import classNames from "classnames";
import "./Scaffold.css";

export const Page = (props: React.HTMLProps<HTMLDivElement>) => (
    <div {...props} className={classNames("Page", props.className)}>
        {props.children}
    </div>
);
export const PageFullScreen = (props: React.HTMLProps<HTMLDivElement>) => (
    <div {...props} className={classNames("PageFullScreen", props.className)}>
        {props.children}
    </div>
);
export const PageHeader = (props: React.HTMLProps<HTMLHeadingElement>) => (
    <h4 {...props} className={classNames("PageHeader", props.className)}>
        {props.children}
    </h4>
);

export const FlexFillBlock = (props: React.HTMLProps<HTMLDivElement>) => {
    return (
        <div {...props} className={classNames("FlexFillBlock", props.className)}>
            {props.children}
        </div>
    );
};

export const FlexColumn = (props: React.HTMLProps<HTMLElement>) => (
    <section {...props} className={classNames("FlexColumn", props.className)}>
        {props.children}
    </section>
);
export const FlexRow = (props: React.HTMLProps<HTMLElement>) => (
    <section {...props} className={classNames("FlexRow", props.className)}>
        {props.children}
    </section>
);
