import { useCallback, useContext } from "react";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";
import { RecordFormStateControlContext } from "../../context/RecordFormState";

export const useFormHandlerRecordDraft = () => {
    const { updateEditableRecord } = useFormRecordStateUpdater();
    const { setRecordValidationStates, setItemValidationStates } = useContext(RecordFormStateControlContext);
    return useCallback(
        (recordDraft: boolean) => {
            updateEditableRecord({ recordDraft });
            setRecordValidationStates(new Map());
            setItemValidationStates(new Map());
        },
        [setItemValidationStates, setRecordValidationStates, updateEditableRecord]
    );
};
