import { useFormProperties } from "./useFormProperties";
import React, { useCallback, useContext, useMemo } from "react";
import { InputRef, RefSelectProps } from "antd";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { GenericItem, GenericRecord } from "../../../../scripts/models";

export const AllowedPartialEditItemFields: (keyof GenericItem | keyof GenericRecord)[] = [
    "text",
    "text2",
    "belegfeld2",
    "tag",
    "documents",
];

export const useFormIsRefDisabled = () => {
    const { disabled, refsHtml, isTemplate } = useContext(RecordFormPropsContext);
    const { isPartialEditMode } = useFormProperties();
    const partialEditFields: React.RefObject<InputRef | RefSelectProps | Pick<HTMLButtonElement, "focus">>[] = useMemo(
        () => [
            refsHtml.REF_iTEXT,
            refsHtml.REF_iTEXT2,
            refsHtml.REF_cASSET,
            refsHtml.REF_iBELEGFELD2,
            refsHtml.REF_iTAG,
        ],
        [refsHtml]
    );
    const templateDisabledFields: React.RefObject<any>[] = useMemo(
        () => [refsHtml.REF_rDATE, refsHtml.REF_rDRAFT, refsHtml.REF_cASSET],
        [refsHtml]
    );

    return useCallback(
        (ref: React.RefObject<any>) => {
            if (disabled) {
                return true;
            }
            if (isTemplate && templateDisabledFields.includes(ref)) {
                return true;
            }
            return isPartialEditMode && !partialEditFields.includes(ref);
        },
        [disabled, isPartialEditMode, isTemplate, partialEditFields, templateDisabledFields]
    );
};
