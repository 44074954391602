import React, { useContext } from "react";
import { Col, Row } from "antd";
import { Contacts } from "@binale-tech/shared";
import { DocumentDeadlineField } from "../DocumentFormFields/DocumentDeadlineField";
import { DocumentDiscountAmountField } from "../DocumentFormFields/DocumentDiscountAmountField";
import { DocumentDiscountDateField } from "../DocumentFormFields/DocumentDiscountDateField";
import { DocumentDiscountDaysField } from "../DocumentFormFields/DocumentDiscountDaysField";
import { DocumentDiscountPercentField } from "../DocumentFormFields/DocumentDiscountPercentField";
import { DocumentDueDateField } from "../DocumentFormFields/DocumentDueDateField";
import { DocumentPaymentTypeField } from "../DocumentFormFields/DocumentPaymentTypeField";
import { FormDivider } from "../../Divider/FormDivider";
import { FormattedMessage } from "react-intl";
import { GroupFormContext } from "../../../modules/GroupFormModules/context/GroupFormContext";

const { PaymentTermTypes } = Contacts.ContactConstants;
export const PaymentInputs = () => {
    const { groupFormInitialValue } = useContext(GroupFormContext);

    const options = [
        {
            label: <FormattedMessage id="app.fields.uberweisung" />,
            value: PaymentTermTypes.Überweisung,
        },
        {
            label: <FormattedMessage id="app.fields.lastschrift" />,
            value: PaymentTermTypes.Lastschrift,
        },
        {
            label: <FormattedMessage id="app.fields.barzahlung" />,
            value: PaymentTermTypes.Barzahlung,
        },
        {
            label: <FormattedMessage id="app.fields.sonstiges" />,
            value: PaymentTermTypes.Sonstiges,
        },
    ];

    return (
        <Row>
            <Col span={24}>
                <FormDivider>
                    <FormattedMessage id="app.titles.termsOfPayment" />
                </FormDivider>
                <Row gutter={[10, 15]}>
                    <Col span={12} xxl={{ span: groupFormInitialValue ? 12 : 7 }}>
                        <DocumentDeadlineField />
                    </Col>
                    <Col span={12} xxl={{ span: groupFormInitialValue ? 12 : 7 }}>
                        <DocumentDueDateField />
                    </Col>
                    <Col span={24} xxl={{ span: groupFormInitialValue ? 24 : 10 }}>
                        <DocumentPaymentTypeField options={options} />
                    </Col>
                    <Col span={12} xxl={{ span: groupFormInitialValue ? 12 : 7 }}>
                        <DocumentDiscountDaysField />
                    </Col>
                    <Col span={12} xxl={{ span: groupFormInitialValue ? 12 : 7 }}>
                        <DocumentDiscountDateField />
                    </Col>
                    <Col span={12} xxl={{ span: groupFormInitialValue ? 12 : 5 }}>
                        <DocumentDiscountPercentField />
                    </Col>
                    <Col span={12} xxl={{ span: groupFormInitialValue ? 12 : 5 }}>
                        <DocumentDiscountAmountField />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
