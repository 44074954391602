import React, { FC } from "react";
import { BankCreateModule, BankUpdateModule, UploadModule } from "@banks/modules";
import { TransactionEditModule } from "@banks/modules/TransactionEditModule/TransactionEditModule";

export const BankModalList: FC = () => {
    return (
        <>
            <BankCreateModule />
            <BankUpdateModule />
            <UploadModule />
            <TransactionEditModule />
        </>
    );
};
