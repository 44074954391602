import React, { FC, useContext } from "react";
import { DatePickerBlock } from "../../shared/form/FormBlocks";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { useFormHandlerDate } from "../hooks/handlers/useFormHandlerDate";

export const FormRecordDate: FC = () => {
    const isDisabled = useFormIsRefDisabled();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { recordDate } = useContext(RecordFormStateContext);
    const { yearBound, periodBound } = useContext(RecordFormPropsContext);
    const onDateChange = useFormHandlerDate();

    if (!recordDate.date) {
        return null;
    }
    return (
        <DatePickerBlock
            hint={<span>{recordDate.period}</span>}
            ref={refsHtml.REF_rDATE}
            value={recordDate.date}
            onChange={onDateChange}
            yearBound={yearBound}
            periodBound={periodBound}
            disabled={isDisabled(refsHtml.REF_rDATE)}
        />
    );
};
