import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import Creditor, { Debitor } from "scripts/models/Creditor";
import { CompanyContext } from "scripts/context/CompanyContext";
import { validation } from "scripts/infrastructure/helpers/validation";

type DebitorCreditorFormType = Creditor | Debitor;
export interface ICommonFormProps {
    item: DebitorCreditorFormType | null;
    onSave: (v: DebitorCreditorFormType) => Promise<void>;
    onClear: () => void;
    disabled: boolean;
    uniqueNums: string[];
}

interface IDebitorCreditorProps extends ICommonFormProps {
    bounds: { min: number; max: number };
}

export const DebitorCreditorForm: React.FC<IDebitorCreditorProps> = ({
    uniqueNums,
    item,
    disabled,
    bounds,
    ...actions
}) => {
    const [form] = Form.useForm<DebitorCreditorFormType>();
    const intl = useIntl();
    const { yearConfig } = React.useContext(CompanyContext);
    const [saving, setSaving] = useState(false);

    const handleClear = () => {
        actions.onClear();
        form.resetFields();
    };

    const handleSubmit = async (values: DebitorCreditorFormType) => {
        const { description = "", ...rest } = values;
        setSaving(true);
        await actions.onSave({ description, ...rest } as DebitorCreditorFormType);
        handleClear();
        setSaving(false);
    };

    useEffect(() => {
        form.setFieldsValue({ description: "", ...item });
    }, [form, item]);

    const kontoExt = yearConfig?.kontoExt ?? 0;
    const numLen = Creditor.DefaultLen + kontoExt;
    const isEditing = Boolean(item?.key);

    const min = bounds.min + "0".repeat(kontoExt);
    const max = bounds.max + "0".repeat(kontoExt);

    return (
        <Form onFinish={handleSubmit} layout="vertical" form={form}>
            <Form.Item name="key" hidden>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item
                label={<FormattedMessage id="app.fields.konto" />}
                name="num"
                rules={[
                    ...validation.commonNumber({ intl, min: numLen, max: numLen }),
                    validation.required(intl),
                    validation.inRange(+min, +max),
                    validation.unique(uniqueNums, item?.num),
                ]}
            >
                <Input
                    placeholder={intl.formatMessage({ id: "app.fields.konto" })}
                    autoFocus
                    disabled={disabled || isEditing}
                    maxLength={numLen}
                    data-testid="num"
                />
            </Form.Item>
            <Form.Item
                label={intl.formatMessage({ id: "app.fields.bezeichnung" })}
                name="name"
                rules={[validation.max(intl, 60), validation.required(intl)]}
            >
                <Input
                    placeholder={intl.formatMessage({ id: "app.fields.bezeichnung" })}
                    disabled={disabled}
                    maxLength={60}
                    data-testid="name"
                />
            </Form.Item>
            <Form.Item
                label={intl.formatMessage({ id: "app.fields.description" })}
                name="description"
                rules={[validation.max(intl, 100)]}
            >
                <Input
                    placeholder={intl.formatMessage({ id: "app.fields.description" })}
                    disabled={disabled}
                    maxLength={100}
                />
            </Form.Item>
            <Row>
                <Col span={24}>
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={disabled || saving}
                            loading={saving}
                            data-testid="submit"
                        >
                            <FormattedMessage id="app.button.save" tagName="span" />
                        </Button>
                        <Button onClick={handleClear} disabled={saving}>
                            {intl.formatMessage({ id: "app.button.cancel" })}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
