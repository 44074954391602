import React from "react";
import { Button, Checkbox, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
import { useIntl } from "react-intl";

interface Props extends React.PropsWithChildren {
    show?: boolean;
    onHide?: () => void;
    onConfirm?: (selected?: boolean) => void;
    title?: string | React.ReactNode;
    confirmText?: string | React.ReactNode;
    closeText?: string | React.ReactNode;
    checkboxTitle?: React.ReactNode;
    content?: React.ReactNode;
}

export const ConfirmationDialog: React.FC<Props> = props => {
    const intl = useIntl();
    const [selected, setSelected] = React.useState(false);

    const onConfirm = () => {
        props.onConfirm && props.onConfirm(selected);
    };
    const onCheckbox = (e: CheckboxChangeEvent) => {
        setSelected(e.target.checked);
    };

    return (
        <Modal
            onCancel={props.onHide || null}
            open={props.show || false}
            width={700}
            destroyOnClose
            title={props.title || intl.formatMessage({ id: "app.confirmation.header" })}
            footer={
                <>
                    <Button danger onClick={onConfirm}>
                        {props.confirmText || intl.formatMessage({ id: "app.button.confirm" })}
                    </Button>
                    <Button onClick={props.onHide}>
                        {props.closeText || intl.formatMessage({ id: "app.button.cancel" })}
                    </Button>
                    ,
                </>
            }
        >
            {props.content || <span>{intl.formatMessage({ id: "app.confirmation.body" })}</span>}
            {props.checkboxTitle && (
                <section>
                    <p>
                        <Checkbox onChange={onCheckbox} checked={selected}>
                            {props.checkboxTitle}
                        </Checkbox>
                    </p>
                </section>
            )}
        </Modal>
    );
};
