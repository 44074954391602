import { Typography } from "antd";
import React, { type FC } from "react";

import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { ContactSelect } from "@app/components/shared/form/baseComponents/ContactSelect/ContactSelect";

import styles from "./FormFields.module.scss";
import { FormattedMessage } from "react-intl";

const { Text } = Typography;

export const TransactionContactField: FC = () => {
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id={"app.fields.contact"} />
                </Text>
            }
            name={"contact"}
        >
            <ContactSelect />
        </FieldLabel>
    );
};
