import { Base, GQL } from "@binale-tech/shared";
import { ProductDetails, ProductGroups, ProductKey, ProductKeys } from "../Product";
import { encodeKey } from "../../api/firebase/firebase";

export class ProductAccessLevels {
    static readonly Read: GQL.IProductAccessLevel = GQL.IProductAccessLevel.Read;
    static readonly Write: GQL.IProductAccessLevel = GQL.IProductAccessLevel.Write;

    static all() {
        return [this.Read, this.Write];
    }

    static writers() {
        return [this.Write];
    }
}

export class ProductAccessUtils {
    private static accountingProductKeys: Set<ProductKey>;
    static getAccountingKeys(): Set<ProductKey> {
        if (!this.accountingProductKeys) {
            this.accountingProductKeys = new Set(ProductDetails.getGroup(ProductGroups.Buch).map(d => d.pk));
        }
        return this.accountingProductKeys;
    }
    static hasCompanyAccounting(companyData: Pick<GQL.ICompany, "products">) {
        const accountingProductKeys = this.getAccountingKeys();
        return companyData?.products?.some(p => accountingProductKeys.has(p.productKey));
    }

    static hasCompanyInvoiceData(
        companyGQL: Pick<GQL.ICompany, "invoiceAddress" | "invoiceEmail" | "invoiceReceiver" | "invoiceTax">
    ) {
        return (
            companyGQL.invoiceReceiver &&
            companyGQL.invoiceEmail &&
            Object.values(companyGQL.invoiceAddress || {}).filter(Boolean).length >= 5 &&
            Object.values(companyGQL.invoiceTax || {}).filter(Boolean).length > 1
        );
    }

    static canRead(pk: ProductKey, companyGQL: GQL.ICompany, user: Base.UserInterface) {
        if (pk === ProductKeys.ER_A) {
            pk = ProductKeys.ER;
        }
        return (
            this.preCheck(companyGQL, user) &&
            this.hasCompanyProductAccess(pk, companyGQL) &&
            Boolean(this.getUserProductAccess(pk, companyGQL, user))
        );
    }

    static canWrite(pk: ProductKey, companyGQL: GQL.ICompany, user: Base.UserInterface) {
        if (pk === ProductKeys.ER_A) {
            pk = ProductKeys.ER;
        }
        return (
            this.preCheck(companyGQL, user) &&
            this.hasCompanyProductAccess(pk, companyGQL) &&
            this.getUserProductAccess(pk, companyGQL, user) === ProductAccessLevels.Write
        );
    }

    static canReviewRecord(pk: ProductKey, companyGQL: GQL.ICompany, user: Base.UserInterface) {
        return this.canRead(pk, companyGQL, user) && this.hasUserRole(companyGQL, user, GQL.IUserRole.RecordReviewer);
    }

    static canExportDatev(companyGQL: GQL.ICompany, user: Base.UserInterface) {
        return this.hasUserRole(companyGQL, user, GQL.IUserRole.DatevExporter);
    }

    protected static preCheck(companyGQL: GQL.ICompany, user: Base.UserInterface) {
        return Boolean(companyGQL) && user.isUserDataLoaded;
    }

    protected static getUserProductAccess(
        pk: ProductKey,
        companyGQL: GQL.ICompany,
        user: Base.UserInterface
    ): GQL.IProductAccessLevel {
        if (user.isAdmin && user.isAdminWrite) {
            return ProductAccessLevels.Write;
        }
        if (user.isAdmin) {
            return ProductAccessLevels.Read;
        }
        if (pk === ProductKeys.AccountingCommon) {
            const accountingAccessLevels = ProductDetails.getGroup(ProductGroups.Buch)
                .map(d => d.pk)
                .map(accountingPk => this.getUserProductAccess(accountingPk, companyGQL, user));
            const levelsSet = new Set(accountingAccessLevels);
            if (levelsSet.has(ProductAccessLevels.Write)) {
                return ProductAccessLevels.Write;
            }
            if (levelsSet.has(ProductAccessLevels.Read)) {
                return ProductAccessLevels.Read;
            }
            return undefined;
        }
        return companyGQL?.members
            ?.find(v => v.emailKey === encodeKey(user.fireUser.email))
            ?.products?.find(v => v.productKey === pk)?.access;
    }

    static hasUserRole(companyGQL: GQL.ICompany, user: Base.UserInterface, role: GQL.IUserRole): boolean {
        if (user.isAdmin && user.isAdminWrite) {
            return true;
        }
        const roles = companyGQL?.members?.find(v => v.emailKey === encodeKey(user.fireUser.email))?.roles || [];
        return roles.includes(role);
    }

    static hasCompanyProductAccess(pk: ProductKey, companyGQL: GQL.ICompany) {
        if (pk === ProductKeys.AccountingCommon) {
            return true;
        }
        return Boolean(companyGQL?.products?.find(v => v.productKey === pk));
    }
}
