import {
    IDecodeOptions,
    IDecoderResponse,
    OcrDocument,
    OcrLoadedFile,
    OcrPage,
    OcrResult,
    PdfToolsMetadata,
    SplitMode
} from "../types";
import { fileToArrayBuffer, toImageUrl } from "../context/helpers/helpers";
import { PDFJS, renderPdfPage } from "../context/helpers/helpersPdf";
import { logger } from "../../scripts/infrastructure/logger";
import { AISearch, SearchResult } from "./ai/AISearch";
import { sanitize } from "./sanitizeFilename";
import { PdfUtils } from "@dms/scripts/utils/PdfUtils";

export const createOcrFile = async (file: File): Promise<OcrLoadedFile> => {
    const fileUrl = URL.createObjectURL(file);
    const previewUrl = await PdfUtils.getPreviewDataUrl(fileUrl);
    const fileNumPage = await PdfUtils.getPdfNumPages(fileUrl);

    return {
        file,
        id: crypto.randomUUID(),
        fileName: sanitize(file.name),
        decoded: false,
        fileUrl,
        previewUrl,
        fileNumPage,
    };
};

class CancellationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "CancellationError";
    }
}

export class PdfDecoder {
    protected isCancelling: boolean = false;
    constructor(
        protected readonly engine: AISearch,
        protected readonly onSetProgress: (v: number) => void
    ) {}

    dispose() {
        this.engine?.dispose();
    }

    cancel() {
        this.isCancelling = true;
    }

    protected setProgress(progress: number) {
        if (this.isCancelling) {
            throw new CancellationError("cancellation requested");
        }
        this.onSetProgress(progress);
    }
    async decodeMultiplePdfs(options: IDecodeOptions, ocrFiles: OcrLoadedFile[]): Promise<IDecoderResponse[]> {
        const { mode, skipQRCheck, userEmail } = options;
        const results: IDecoderResponse[] = [];
        for (const ocrFile of ocrFiles) {
            try {
                const res = await this.runDecoder({ mode, skipQRCheck, userEmail }, ocrFile);
                if (res) {
                    results.push(res);
                }
            } catch (error) {
                if (error instanceof CancellationError) {
                    this.isCancelling = false;
                    return [];
                }
                console.error("Error decoding PDF:", error);
            } finally {
                this.setProgress(null);
            }
            await new Promise(resolve => setTimeout(resolve, 100));
        }
        return results;
    }
    async decodePdf(options: IDecodeOptions, ocrFile: OcrLoadedFile): Promise<Required<IDecoderResponse> | null> {
        let res: Required<IDecoderResponse> = null;
        try {
            res = await this.runDecoder(options, ocrFile);
        } catch (error) {
            if (error instanceof CancellationError) {
                this.isCancelling = false;
                return null;
            }
            console.error("Error decoding PDF:", error);
        } finally {
            this.setProgress(null);
        }
        await new Promise(resolve => setTimeout(resolve, 100));
        return res;
    }

    protected async runDecoder(
        options: IDecodeOptions,
        ocrFile: OcrLoadedFile
    ): Promise<Required<IDecoderResponse> | null> {
        const { mode, skipQRCheck, userEmail } = options;

        this.setProgress(1);

        const buffer = await fileToArrayBuffer(ocrFile.file);
        const dstBuffer = new ArrayBuffer(buffer.byteLength);
        new Uint8Array(dstBuffer).set(new Uint8Array(buffer));
        const uint8Array = new Uint8Array(dstBuffer);
        this.setProgress(5);

        const pdf = await PDFJS.getDocument({ data: new Uint8Array(buffer), isEvalSupported: false }).promise;

        this.setProgress(7);
        logger.log("loaded pdf", pdf);

        const metadata: PdfToolsMetadata = {
            name: ocrFile.fileName,
            dt: new Date().getTime(),
            pages: pdf.numPages,
            decoding: [],
            mode,
            userEmail,
        };
        let imgDebug = "";

        const PRINT_RESOLUTION = 150;
        const resultMap = new Map<number, OcrResult>();

        for (let i = 1; i <= pdf.numPages; i++) {
            const t = Date.now();
            // eslint-disable-next-line no-console
            console.group(">>> starting page ", i);
            const page = await pdf.getPage(i);
            const tGetPage = Date.now() - t;

            const canvas = await renderPdfPage(page, PRINT_RESOLUTION);

            const tRenderPage = Date.now() - t;

            let code = null;
            if (mode === SplitMode.every) {
                code = "split";
            }
            if (mode === SplitMode.second) {
                code = (i - 1) % 2 === 0 ? "split" : null;
            }
            if (mode === SplitMode.qr) {
                const t0 = Date.now();
                let feedback: SearchResult = { validation: null };
                if (!feedback.validation) {
                    feedback = await this.engine.canvasSegmentSearch(canvas, !skipQRCheck);
                }
                logger.log(feedback);
                const { validation } = feedback;
                const aiTime = Date.now() - t0;
                if (validation) {
                    let debugStr = `<br/><b>Page ${i}</b><br/>`;
                    feedback.caseCodes.forEach(c => {
                        if (c.details && c.details.score > 0) {
                            debugStr += `<code>${c.details.validation}</code>${c.details.score.toFixed(2)}<br/>`;
                            debugStr += `<img src="${toImageUrl(
                                c.details.imgData
                            )}" alt="" class="pdf-tools-img-border"/><br/>`;
                        }
                    });
                    imgDebug += debugStr + `<br/>`;
                }
                metadata.decoding.push({ i, validation, aiTime });
                code = validation;
            }
            const dataUrl = canvas.toDataURL("image/jpeg", 0.75);
            logger.log("rendered page", i, {
                tGetPage,
                tRenderPage,
            });

            // eslint-disable-next-line no-console
            console.groupEnd();

            resultMap.set(i, { code, dataUrl, canvas });
            const progress = 10 + Math.floor((resultMap.size / pdf.numPages) * 85);
            this.setProgress(progress);
        }
        this.setProgress(100);

        const parsedDocuments: OcrDocument[] = [];
        const parsedPages: OcrPage[] = [];

        let res = "";

        for (let i = 0; i < pdf.numPages; i++) {
            const userFriendlyIdx = i + 1;
            const v = resultMap.get(userFriendlyIdx);
            if (userFriendlyIdx === 1 || (!!v && v.code)) {
                parsedDocuments.push({
                    id: crypto.randomUUID(),
                    pageIds: [],
                });
            }

            // https://github.com/bpampuch/pdfmake/blob/master/src/standardPageSizes.js#L8
            // [595.28, 841.89],

            const parsedPage: OcrPage = {
                canvas: v.canvas,
                imageUrl: v.dataUrl,
                fileId: ocrFile.id,
                filePageIndex: i,
                id: `${ocrFile.id}.${i.toString()}`,
                deleted: false,
                rotate: 0,
                checked: false,
                isCalculated: false,
            };
            parsedPages.push(parsedPage);
            parsedDocuments[parsedDocuments.length - 1].pageIds.push(parsedPage.id);
            res = res + "Page " + userFriendlyIdx + (v.code ? " " + v.code.toString() : " - ") + "<br/>";
        }
        // todo contents.push(content);
        logger.log("scan done", { parsedDocuments, metadata });

        // update decoded file
        const newFile = {
            ...ocrFile,
            dpi: PRINT_RESOLUTION,
            pdf,
            uint8Array,
            metadata,
            decoded: true,
            resultHTML: res,
            imgDebug,
        };

        return { newFile, parsedPages, parsedDocuments };
    }
}
