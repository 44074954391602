import React from "react";
import cn from "classnames";
import { Flex, Form, FormItemProps } from "antd";

export interface FieldLabelProps extends FormItemProps {
    labelAddon?: React.ReactNode;
}

export const FieldLabel: React.FC<FormItemProps & FieldLabelProps> = ({ children, labelAddon, ...rest }) => {
    const labelProps: FormItemProps = {};
    if (labelAddon) {
        labelProps.label = (
            <Flex justify={"space-between"} align={"center"}>
                <div>{rest.label}</div>
                <div style={{ marginLeft: 8 }}>{labelAddon}</div>
            </Flex>
        );
    }

    return (
        <Form.Item
            {...rest}
            {...labelProps}
            className={cn("BinaleForm__Item", rest.className, { BinaleForm__Item__Addon: !!labelAddon })}
        >
            {children}
        </Form.Item>
    );
};
