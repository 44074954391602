import React, { type FC, useContext } from "react";
import dayjs from "dayjs";
import { type ColumnsType } from "antd/es/table";
import { FormattedMessage } from "react-intl";
import { Table } from "antd";
import { RecordSnapshot } from "@dms/hooks/useDmsRecordsData";
import { DmsDataContext } from "@dms/types/ContextTypes";

interface IProps {
    url: string;
}

export const RecordDataTable: FC<IProps> = ({ url }) => {
    const { dmsRecordsData } = useContext(DmsDataContext);
    const data = dmsRecordsData.recordSnapshots[url] ?? [];

    const tableData = data.map((el, i) => {
        return { ...el, key: i };
    });

    const columns: ColumnsType<RecordSnapshot> = [
        {
            title: <FormattedMessage id="app.fields.date" />,
            dataIndex: "date",
            key: "date",
            render: (_, el) => dayjs(el.date).format("DD.MM.YYYY"),
        },
        {
            title: <FormattedMessage id="app.fields.konto" />,
            key: "konto",
            render: (_, el) => (
                <div>
                    <div>{el.recordAccountNum}</div>
                    <small>{el.recordAccountName}</small>
                </div>
            ),
        },
        {
            title: <FormattedMessage id="app.fields.gkonto" />,
            key: "gkonto",
            render: (_, el) => (
                <div>
                    <div>{el.itemAccountNum}</div>
                    <small>{el.itemAccountName}</small>
                </div>
            ),
        },
        {
            title: <FormattedMessage id="app.fields.module" />,
            dataIndex: "productKey",
            key: "productKey",
        },
    ];

    return (
        <div
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <Table
                pagination={false}
                columns={columns}
                size={"small"}
                dataSource={tableData}
                style={{ maxWidth: 700, cursor: "pointer" }}
            ></Table>
        </div>
    );
};
