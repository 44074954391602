import React, { useCallback, useEffect, useState } from "react";
import { AutoCompleteProps, Input, InputProps, InputRef } from "antd";
import { Base } from "@binale-tech/shared";
import { DefaultOptionType } from "antd/es/select";
import { DownOutlined } from "@ant-design/icons";

import { BaseAutocomplete } from "../BaseSelect";
import { CompanyContext } from "scripts/context/CompanyContext";

interface ItemComponentProps {
    key: string;
    item: Base.IExtNum;
    kontoExt: Base.CompanyKontoExt;
}

const ComboboxItem: React.FC<ItemComponentProps> = props => {
    return (
        <span className="ComboboxListItem">
            <span className="ComboboxListItem__number">{props.item.getExtNumPrint(props.kontoExt)}</span>
            {props.item.name}
        </span>
    );
};

export interface ExtNumComboboxProps<T extends Base.IExtNum> extends Omit<AutoCompleteProps, "value" | "onChange"> {
    items?: T[];
    itemComponent?: React.FC<ItemComponentProps>;
    onChange?: (value: T) => void;
    value?: T;
    transformSelectedValue?: (value: T, kontoExt?: Base.CompanyKontoExt) => string;
    inputProps?: InputProps;
    inputRef?: React.Ref<InputRef>;
    debug?: boolean;
}

export const ExtNumCombobox = <T extends Base.IExtNum>({
    items = [],
    itemComponent = ComboboxItem,
    value,
    inputProps,
    onChange,
    transformSelectedValue = (val, kontoExt) => (val ? val.getExtNumPrint(kontoExt) : ""),
    inputRef,
    ...props
}: ExtNumComboboxProps<T>) => {
    const [options, setOptions] = useState<DefaultOptionType[]>([]);
    const [valueMap, setValueMap] = useState<Map<string, T>>(new Map());
    const [innerValue, setInnerValue] = useState("");
    const [isInnerChange, setIsInnerChange] = useState(false);

    const { yearConfig } = React.useContext(CompanyContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;

    useEffect(() => {
        const newOptions: DefaultOptionType[] = [];
        const newValueMap: Map<string, T> = new Map();
        const ItemComponent = itemComponent;
        items.forEach((v: T) => {
            if (v) {
                newValueMap.set(v.getExtNumPrint(kontoExt), v);
                newOptions.push({
                    key: v.getExtNum(kontoExt),
                    label: (
                        <ItemComponent
                            item={v}
                            key={v.getExtNum(kontoExt)}
                            kontoExt={kontoExt as Base.CompanyKontoExt}
                        />
                    ),
                    value: v.getExtNumPrint(kontoExt),
                });
            }
        });
        setOptions(newOptions);
        setValueMap(newValueMap);
    }, [items, itemComponent, kontoExt]);

    useEffect(() => {
        if (!isInnerChange) {
            setInnerValue(transformSelectedValue(value, kontoExt as Base.CompanyKontoExt));
        } else {
            setIsInnerChange(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, kontoExt]);

    const filterFn = useCallback(
        (num: string, numPrint: string) => {
            let inputValue = innerValue;
            if (value?.num === num) {
                return true;
            }
            inputValue = inputValue.trim();
            if (inputValue.length < 1) {
                return true;
            }
            const isNumber = /^[\d\s]+$/.test(inputValue);
            if (isNumber) {
                const numWithoutZeros = num.replace(/^(0)+/, "");
                const numPrintWithoutZeros = numPrint.replace(/^(0)+/, "");
                return (
                    num.startsWith(inputValue) ||
                    numPrint.startsWith(inputValue) ||
                    numWithoutZeros.startsWith(inputValue) ||
                    numPrintWithoutZeros.startsWith(inputValue)
                );
            }
            const valueMapItem = valueMap.get(numPrint);
            if (!valueMapItem?.name) {
                return false;
            }
            return valueMapItem.name.toLowerCase().includes(inputValue.toLowerCase());
        },
        [valueMap, value, innerValue]
    );

    const handleChangeAnt = useCallback(
        (e: string) => {
            if (valueMap.has(e)) {
                onChange(valueMap.get(e));
            } else {
                // to prevent setting innerValue to ""
                if (value) {
                    setIsInnerChange(true);
                }
                setInnerValue(e);
                onChange(undefined);
            }
        },
        [onChange, value, valueMap]
    );

    const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (innerValue && !value) {
            const itemsCountInSearch = options.filter(option =>
                filterFn(option.key.toString(), option.value.toString())
            );
            if (itemsCountInSearch?.length === 1 && valueMap.get(itemsCountInSearch[0].value.toString())) {
                onChange(valueMap.get(itemsCountInSearch[0].value.toString()));
            } else {
                setInnerValue("");
            }
        }
        inputProps?.onBlur && inputProps.onBlur(e);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (inputProps?.onKeyDown) {
            if (e.key === "Enter") {
                setTimeout(() => inputProps.onKeyDown(e), 100);
            }
        }
    };

    return (
        <BaseAutocomplete
            showSearch
            autoClearSearchValue={false}
            // getPopupContainer={trigger => trigger.parentNode}
            filterOption={(inputValue, option) => {
                // todo: use inputValue after resolving bug: https://github.com/ant-design/ant-design/issues/35809
                return filterFn(option.key.toString(), option.value.toString());
            }}
            popupClassName="NumNameComboboxDropDown"
            options={options}
            defaultActiveFirstOption={true}
            onChange={handleChangeAnt}
            value={innerValue}
            onClear={() => onChange(undefined)}
            {...props}
        >
            <Input
                suffix={<DownOutlined />}
                onFocus={handleInputFocus}
                {...inputProps}
                ref={inputRef}
                onBlur={handleBlur}
                onKeyDown={onKeyDown}
            />
        </BaseAutocomplete>
    );
};
