import React, { useEffect, useState } from "react";
import { IHeaderParams } from "ag-grid-community";
import { FormattedMessage, useIntl } from "react-intl";
import { Flex, Tooltip } from "antd";
import { FilterTwoTone } from "@ant-design/icons";
import { IColDocument } from "@dms/modules/DocumentTableModule/interfaces";
import { DmsTableCols, TableRowColors, TranslateDmsTableColumns } from "@dms/modules/DocumentTableModule/consts";
import { GQL } from "@binale-tech/shared";

export const HeaderActions: React.FC<IHeaderParams<IColDocument>> = props => {
    const intl = useIntl();
    const { showColumnMenu, api } = props;
    const [color, setColor] = useState<string>("");
    const [tooltipText, setTooltipText] = useState(intl.formatMessage({ id: "app.dms.filterColor" }));

    useEffect(() => {
        const handleFilterChanged = () => {
            const newModel = api.getFilterModel().actions;

            if (!newModel) {
                setTooltipText("Color filter");
                setColor("");
                return;
            }

            const color = newModel as string;
            setTooltipText(
                `${intl.formatMessage({ id: "app.dms.filterColor" })}: ${intl.formatMessage({ id: `app.button.color.${color}` })}`
            );
            setColor(color);
        };

        api.addEventListener("filterChanged", handleFilterChanged);

        if (api.isDestroyed()) {
            api.removeEventListener("filterChanged", handleFilterChanged);
        }
    }, [api]);

    return (
        <Flex align="center" style={{ width: "calc(100% - 50px)", justifyContent: "space-between" }}>
            <FormattedMessage tagName={"span"} id={TranslateDmsTableColumns[DmsTableCols.ACTIONS]} />
            <Tooltip title={tooltipText}>
                <button
                    onClick={e => showColumnMenu(e.currentTarget)}
                    style={{ padding: 2, outline: 0, border: "none", backgroundColor: "transparent" }}
                >
                    {color ? (
                        <FilterTwoTone
                            twoToneColor={[
                                color === GQL.IDocumentColors.Yellow ? "SandyBrown" : color,
                                TableRowColors[color as GQL.IDocumentColors],
                            ]}
                        />
                    ) : (
                        <FilterTwoTone twoToneColor={["rgba(0, 0, 0, 0.85)", "transparent"]} />
                    )}
                </button>
            </Tooltip>
        </Flex>
    );
};
