import React from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Utils } from "@binale-tech/shared";
import { TransactionTableColumns } from "@banks/components/TransactionsTable/transactionTableColumns";
import { TableActions } from "@banks/modules/BankTransactionsModule/components";
import { transactionFieldsDict } from "@banks/configs/constants/transactionFieldsDict";
import { ActionControlColumns } from "@banks/modules/BankTransactionsModule/ActionControlColumn";
import dayjs from "dayjs";

export class BankTransactionTableColumns extends TransactionTableColumns {
    static get bookingDateWithFilter(): Readonly<ColDef> {
        return {
            ...this.bookingDate,
            width: 150,
            filter: "agDateColumnFilter",
            filterParams: {
                comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
                    if (!cellValue) {
                        return 0;
                    }

                    const filterDateString = dayjs(filterLocalDateAtMidnight).format("DD.MM.YYYY");

                    if (cellValue !== filterDateString) {
                        return -1;
                    }
                    return 0;
                },
            },
        };
    }
    static get amount(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: transactionFieldsDict.amount }),
            headerTooltip: this.intl?.formatMessage({ id: transactionFieldsDict.amount }),
            field: "amount",
            valueGetter: params => Utils.CurrencyUtils.currencyFormat(params?.data?.amount),
            width: 150,
            sortable: false,
            filter: true,
            cellStyle: params => {
                const cellStyles = { textAlign: "right" };
                if (params?.data?.amount !== undefined && params?.data?.amount < 0) {
                    return {
                        ...cellStyles,
                        color: "#f5222d",
                        fontWeight: "500",
                        textAlign: "right",
                    };
                }
                return {
                    ...cellStyles,
                    color: "#52c41a",
                };
            },
        };
    }

    static get dynamicBalance(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: "app.fields.saldo" }),
            headerTooltip: this.intl?.formatMessage({ id: "app.fields.saldo" }),
            field: "dynamicBalance",
            valueGetter: params => Utils.CurrencyUtils.currencyFormat(params?.data?.dynamicBalance),
            width: 120,
            sortable: false,
            filter: false,
            headerClass: "transaction-table-wrapper",
            cellStyle: params => {
                const cellStyles = { fontWeight: "500", textAlign: "right" };
                if (params?.data?.dynamicBalance !== undefined && params?.data?.dynamicBalance < 0) {
                    return {
                        ...cellStyles,
                        color: "#f5222d",
                    };
                }
                return {
                    ...cellStyles,
                    color: "#52c41a",
                };
            },
        };
    }

    static get transactionTypeWithFilter(): Readonly<ColDef> {
        return {
            ...this.transactionType,
            filter: true,
        };
    }

    static get purposeWithFilter(): Readonly<ColDef> {
        return {
            ...this.purpose,
            filter: true,
        };
    }

    static get counterpartyNameWithFilter(): Readonly<ColDef> {
        return {
            ...this.counterpartyName,
            filter: true,
        };
    }

    static get counterpartyIbanOrAccountNumWithFilter(): Readonly<ColDef> {
        return {
            ...this.counterpartyIbanOrAccountNum,
            filter: true,
        };
    }

    static get counterpartyBicOrBlzWithFilter(): Readonly<ColDef> {
        return {
            ...this.counterpartyBicOrBlz,
            filter: true,
        };
    }

    static get contact(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: "app.fields.contact" }),
            headerTooltip: this.intl?.formatMessage({ id: "app.fields.contact" }),
            field: "contact",
            width: 220,
            sortable: false,
            filter: true,
        };
    }

    static get belegFeld1(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: "app.fields.rechnung_num" }),
            headerTooltip: this.intl?.formatMessage({ id: "app.fields.rechnung_num" }),
            field: "belegfeld1",
            width: 220,
            sortable: false,
            filter: true,
        };
    }

    static get belegFeld2(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: "app.fields.internal_num" }),
            headerTooltip: this.intl?.formatMessage({ id: "app.fields.internal_num" }),
            field: "belegfeld2",
            width: 220,
            sortable: false,
            filter: true,
        };
    }

    static get text(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: "app.fields.buchtext" }),
            headerTooltip: this.intl?.formatMessage({ id: "app.fields.buchtext" }),
            field: "text",
            width: 220,
            sortable: false,
            filter: true,
        };
    }

    static get text2(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: "app.fields.buchtext2" }),
            headerTooltip: this.intl?.formatMessage({ id: "app.fields.buchtext2" }),
            field: "text2",
            width: 220,
            sortable: false,
            filter: true,
        };
    }

    static get transactionActions(): Readonly<ColDef> {
        return {
            ...this.actions,
            filter: false,
            width: 120,
            cellRenderer: ({ node }: ICellRendererParams) => {
                return <TableActions transaction={node.data} />;
            },
            cellStyle: () => ({ textAlign: "center", border: "none" }),
            headerComponent: ActionControlColumns,
        };
    }

    static get tableColumnConfig(): Readonly<ColDef>[] {
        return [
            this.transactionRowNumberColumn,
            this.bookingDateWithFilter,
            this.amount,
            this.currencyCode,
            this.dynamicBalance,
            this.transactionTypeWithFilter,
            this.purposeWithFilter,
            this.counterpartyNameWithFilter,
            this.counterpartyIbanOrAccountNumWithFilter,
            this.counterpartyBicOrBlzWithFilter,
            this.contact,
            this.belegFeld1,
            this.belegFeld2,
            this.text,
            this.text2,
            this.transactionActions,
        ];
    }
}
