import React, { useState } from "react";
import { Alert, Button, Form, Input } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { logger } from "scripts/infrastructure/logger";

interface ExternalProps {
    onSubmit: (newPassword: string) => Promise<{ redirect: boolean } | void>;
}

interface NewPasswordFormType {
    password: string;
    password_repeat: string;
}

export const NewPasswordForm = ({ onSubmit }: ExternalProps) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm<NewPasswordFormType>();
    const [error, setError] = useState<string | null>(null);

    const isFormValid = () => form.getFieldsError().some(item => item.errors.length > 0);

    const handleSubmit = async (values: NewPasswordFormType) => {
        try {
            const result = await onSubmit(values.password);

            // redirect happened, if do not terminate then react:error
            if (result && result.redirect) {
                return;
            }

            form.resetFields();
        } catch (err) {
            logger.error({ err });
            setError(err.message);
        }
    };

    const handlePasswordRepeat = (rule: any, value: string, cb: any) => {
        const { getFieldValue } = form;
        if (value && value !== getFieldValue("password")) {
            cb(formatMessage({ id: "app.validation.error.passwords_not_match" }));
        }
        cb();
    };

    const resetError = () => {
        setError(null);
    };
    return (
        <>
            {error && (
                <Alert
                    type="error"
                    message={error || <FormattedMessage id="app.global.error" data-cy="error-alert" />}
                />
            )}
            <FormattedMessage id="app.new_password.description" tagName="div" />
            <Form onFinish={handleSubmit} layout="vertical" form={form}>
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: formatMessage({ id: "app.validation.error.field_empty" }) },
                        {
                            min: 6,
                            message: formatMessage(
                                { id: "app.validation.error.short_password" },
                                {
                                    length: 6,
                                }
                            ),
                        },
                    ]}
                >
                    <Input.Password
                        size="large"
                        placeholder={formatMessage({ id: "app.global.password" })}
                        autoComplete="new-password"
                        onChange={resetError}
                    />
                </Form.Item>
                <Form.Item
                    name="password_repeat"
                    rules={[
                        { required: true, message: formatMessage({ id: "app.validation.error.field_empty" }) },
                        { validator: handlePasswordRepeat },
                    ]}
                >
                    <Input.Password
                        placeholder={formatMessage({ id: "app.global.password_repeat" })}
                        size="large"
                        autoComplete="new-password"
                        onChange={resetError}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isFormValid()}
                        loading={isFormValid()}
                        block
                        size="large"
                    >
                        <FormattedMessage id="app.button.send" />
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};
