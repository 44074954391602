import { ColDef, ICellRendererParams, SuppressKeyboardEventParams, ValueGetterParams } from "ag-grid-community";
import { AbstractTableColumns } from "@app/components/shared/AgGridTable/columns/AbstractTableColumns";
import {
    InvoicesListColumn,
    InvoicesListColumnTranslate,
    NavigationKeys,
    TInvoicesListColumnsConfig,
    TInvoiceTableItem,
} from "@inv/types";
import { GQL } from "@binale-tech/shared";
import { decimalFormatter, formatDefault } from "@dms/scripts/helpers";
import { documentSorter } from "@dms/modules/DocumentTableModule/helpers";

import styles from "../invoicesList.module.scss";

export class InvoicesListTableColumns extends AbstractTableColumns {
    static get invoiceRowNumberColumn(): Readonly<ColDef> {
        return {
            ...this.rowNumberColumn,
            field: InvoicesListColumn.INVOICE_ROW_NUMBER,
            filter: false,
            width: 70,
            pinned: "left",
            cellStyle: { fontWeight: "500", textAlign: "center" },
            valueGetter: ({ api, data, node }: ValueGetterParams) => {
                if (node.rowPinned) {
                    return this?.intl?.formatMessage({
                        id: "app.invoice.total",
                    });
                }

                if (data.isChildRow) {
                    return "·";
                }

                let rowIndex = 0;
                let currentIndex = 0;

                api.forEachNodeAfterFilterAndSort(row => {
                    if (!row.data.isChildRow) {
                        currentIndex++;
                    }

                    if (row.id === node.id) {
                        rowIndex = currentIndex;
                    }
                });

                return rowIndex;
            },
        };
    }

    static get invoiceDateColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoicesListColumnTranslate[InvoicesListColumn.INVOICE_DATE] }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.INVOICE_DATE],
            }),
            field: InvoicesListColumn.INVOICE_DATE,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceTableItem>) => {
                return data.invoiceDate;
            },
            cellRenderer: ({ data, value }: ICellRendererParams<TInvoiceTableItem>) => {
                if (data.isChildRow) {
                    return null;
                }

                return value;
            },
            width: 140,
            sortable: true,
            comparator: (a, b) => documentSorter(a, b, "date"),
        };
    }

    static get invoiceNumberColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.INVOICE_NUMBER],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.INVOICE_NUMBER],
            }),
            field: InvoicesListColumn.INVOICE_NUMBER,
            width: 220,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceTableItem>) => data.invoiceNumber,
            cellRenderer: ({ data, value }: ICellRendererParams<TInvoiceTableItem>) => {
                if (data.isChildRow) {
                    return null;
                }

                return value;
            },
        };
    }

    static get serviceDescriptionColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.SERVICE_DESCRIPTION],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.SERVICE_DESCRIPTION],
            }),
            field: InvoicesListColumn.SERVICE_DESCRIPTION,
            width: 200,
            sortable: true,
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    static get contactColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.CONTACT],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.CONTACT],
            }),
            field: InvoicesListColumn.CONTACT,
            width: 200,
            sortable: true,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceTableItem>) => {
                if (data.isChildRow) {
                    return null;
                }

                return data.contact;
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    static get projectColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.PROJECT],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.PROJECT],
            }),
            field: InvoicesListColumn.PROJECT,
            width: 80,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceTableItem>) => data.project,
            cellRenderer: ({ data, value }: ICellRendererParams<TInvoiceTableItem>) => {
                if (data.isChildRow) {
                    return null;
                }

                return value;
            },
        };
    }

    static get serviceDateColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.SERVICE_DATE],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.SERVICE_DATE],
            }),
            field: InvoicesListColumn.SERVICE_DATE,
            width: 180,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceTableItem>) => data.serviceDate,
            cellRenderer: ({ data, value }: ICellRendererParams<TInvoiceTableItem>) => {
                if (data.isChildRow) {
                    return null;
                }

                return value;
            },
        };
    }

    static get originalAmountColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.ORIGINAL_AMOUNT],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.ORIGINAL_AMOUNT],
            }),
            field: InvoicesListColumn.ORIGINAL_AMOUNT,
            valueGetter: ({ data, node }: ValueGetterParams<TInvoiceTableItem>) => {
                if (node.rowPinned) {
                    return null;
                }

                return formatDefault(data.originalAmount);
            },
            width: 200,
            cellStyle: { fontWeight: "500", textAlign: "right" },
        };
    }

    static get currencyColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.CURRENCY],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.CURRENCY],
            }),
            field: InvoicesListColumn.CURRENCY,
            width: 100,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceTableItem>) => data.currency,
            cellRenderer: ({ data, value }: ICellRendererParams<TInvoiceTableItem>) => {
                if (data.isChildRow) {
                    return null;
                }

                return value;
            },
        };
    }

    static get rateColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.RATE],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.RATE],
            }),
            field: InvoicesListColumn.RATE,
            cellEditor: "rateEditor",
            editable: true,
            suppressKeyboardEvent: ({ editing, event }: SuppressKeyboardEventParams) =>
                editing && event.key === NavigationKeys.ENTER,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceTableItem>) =>
                data.currency !== GQL.ICurrencyCode.Eur ? data.currencyRate : null,
            cellRenderer: "rateComponent",
            width: 120,
        };
    }

    static get amountNettoColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.AMOUNT_NETTO],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.AMOUNT_NETTO],
            }),
            field: InvoicesListColumn.AMOUNT_NETTO,
            valueGetter: ({ data, node }: ValueGetterParams<TInvoiceTableItem>) => {
                if (node.rowPinned) {
                    return formatDefault(data.amountNetto);
                }
                return formatDefault(data.originalAmount / data.currencyRate);
            },
            width: 170,
            cellStyle: { fontWeight: "500", textAlign: "right" },
        };
    }

    static get vatRateColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.VAT_RATE],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.VAT_RATE],
            }),
            field: InvoicesListColumn.VAT_RATE,
            valueGetter: ({ data, node }: ValueGetterParams<TInvoiceTableItem>) => {
                if (node.rowPinned || (!data.generalTax && typeof data.generalTax !== "number")) {
                    return null;
                }

                return data.generalTaxRate;
            },
            width: 80,
            cellStyle: { textAlign: "left" },
        };
    }

    static get vatColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.VAT],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.VAT],
            }),
            field: InvoicesListColumn.VAT,
            valueGetter: ({ data, node }: ValueGetterParams<TInvoiceTableItem>) => {
                if (data.generalTax === 0) {
                    return decimalFormatter(null);
                }

                if (node.rowPinned) {
                    return formatDefault(data.vat);
                }

                if (!data.generalTax) {
                    return null;
                }

                return formatDefault(data.generalTax / data.currencyRate);
            },
            width: 120,
            cellStyle: { fontWeight: "500", textAlign: "right" },
        };
    }

    static get amountBruttoColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.AMOUNT_BRUTTO],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: InvoicesListColumnTranslate[InvoicesListColumn.AMOUNT_BRUTTO],
            }),
            field: InvoicesListColumn.AMOUNT_BRUTTO,
            valueGetter: ({ data, node }: ValueGetterParams<TInvoiceTableItem>) => {
                if (node.rowPinned) {
                    return formatDefault(data.amountBrutto);
                }

                const amountNetto = data.originalAmount / data.currencyRate;

                if (data.generalTax === 0 || !data.generalTax) {
                    return formatDefault(amountNetto);
                }

                const taxRate = data.generalTax / data.currencyRate;

                return formatDefault(amountNetto + taxRate);
            },
            width: 170,
            cellStyle: { fontWeight: "500", textAlign: "right" },
        };
    }

    private static get actionColumn(): Readonly<ColDef> {
        return {
            headerComponent: "actionControlColumns",
            field: InvoicesListColumn.ACTION,
            width: 170,
            pinned: "right",
            resizable: false,
            filter: false,
            editable: false,
            cellRenderer: "actionList",
            headerClass: styles.headerActionColumns,
            cellStyle: { textAlign: "right" },
        };
    }

    static get invoicesListInitColumnsConfig(): TInvoicesListColumnsConfig {
        return {
            [InvoicesListColumn.INVOICE_ROW_NUMBER]: true,
            [InvoicesListColumn.INVOICE_DATE]: true,
            [InvoicesListColumn.INVOICE_NUMBER]: true,
            [InvoicesListColumn.SERVICE_DESCRIPTION]: true,
            [InvoicesListColumn.CONTACT]: true,
            [InvoicesListColumn.PROJECT]: true,
            [InvoicesListColumn.SERVICE_DATE]: true,
            [InvoicesListColumn.ORIGINAL_AMOUNT]: true,
            [InvoicesListColumn.CURRENCY]: true,
            [InvoicesListColumn.RATE]: true,
            [InvoicesListColumn.AMOUNT_NETTO]: true,
            [InvoicesListColumn.VAT_RATE]: true,
            [InvoicesListColumn.VAT]: true,
            [InvoicesListColumn.AMOUNT_BRUTTO]: true,
            [InvoicesListColumn.ACTION]: true,
        };
    }

    static get requiredColumnsConfig(): string[] {
        return [InvoicesListColumn.INVOICE_ROW_NUMBER, InvoicesListColumn.INVOICE_DATE, InvoicesListColumn.ACTION];
    }

    private static getColumn = (colKey: InvoicesListColumn): Readonly<ColDef> => {
        switch (colKey) {
            case InvoicesListColumn.INVOICE_ROW_NUMBER:
                return { ...this.invoiceRowNumberColumn };
            case InvoicesListColumn.INVOICE_DATE:
                return { ...this.invoiceDateColumn };
            case InvoicesListColumn.INVOICE_NUMBER:
                return { ...this.invoiceNumberColumn };
            case InvoicesListColumn.SERVICE_DESCRIPTION:
                return { ...this.serviceDescriptionColumn };
            case InvoicesListColumn.CONTACT:
                return { ...this.contactColumn };
            case InvoicesListColumn.PROJECT:
                return { ...this.projectColumn };
            case InvoicesListColumn.SERVICE_DATE:
                return { ...this.serviceDateColumn };
            case InvoicesListColumn.ORIGINAL_AMOUNT:
                return { ...this.originalAmountColumn };
            case InvoicesListColumn.CURRENCY:
                return { ...this.currencyColumn };
            case InvoicesListColumn.RATE:
                return { ...this.rateColumn };
            case InvoicesListColumn.AMOUNT_NETTO:
                return { ...this.amountNettoColumn };
            case InvoicesListColumn.VAT_RATE:
                return { ...this.vatRateColumn };
            case InvoicesListColumn.VAT:
                return { ...this.vatColumn };
            case InvoicesListColumn.AMOUNT_BRUTTO:
                return { ...this.amountBruttoColumn };
            case InvoicesListColumn.ACTION:
                return { ...this.actionColumn };
        }
    };

    public static getColumns = (activeKeys?: string[]): ColDef[] =>
        activeKeys.map(item => this.getColumn(item as InvoicesListColumn));
}
