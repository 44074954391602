import React, { FC, useContext, useMemo, useRef } from "react";
import { gridOptions } from "./gridOptions";
import { TransactionsTable } from "@banks/components";
import { CustomTableHeader } from "./CustomTableHeader";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { RequiredTableCols } from "@app/components/shared/AgGridTable/constants/tableCols";
import { Utils } from "@binale-tech/shared";
import { parseNumber } from "@dms/scripts/helpers";

import Container from "@app/components/shared/appearance/page/Container";
import { PreviewParseDataContext } from "@banks/modules/ParseTransactionModule/context/PreviewParseDataProvider";

export const PreviewParseDataTable: FC = () => {
    const { previewParseResult, fieldMatching } = useContext(PreviewParseDataContext);

    const tableRef = useRef<AgGridReact>(null);

    const tableColumns: Readonly<ColDef>[] = useMemo(() => {
        const cols = Object.keys(previewParseResult[0] || {})
            .filter(rk => rk !== "csvLine")
            .map(key => {
                const col = {
                    headerName: key,
                    headerTooltip: key,
                    field: key,
                    width: 200,
                    autoHeaderHeight: true,
                    sortable: false,
                    filter: false,
                    valueGetter: (params: any) => {
                        return params?.data[key];
                    },
                    cellStyle: {},
                };

                if (fieldMatching[key] === "amount") {
                    return {
                        ...col,
                        valueGetter: (params: any) => {
                            return params?.data[key]
                                ? Utils.CurrencyUtils.currencyFormat(Math.round(parseNumber(params?.data[key]) * 100))
                                : null;
                        },
                        cellStyle: (params: any) => {
                            const cellStyles = { textAlign: "right" };
                            const amount = params?.data[key] ? Math.round(parseNumber(params?.data[key]) * 100) : null;
                            if (amount !== null && amount < 0) {
                                return {
                                    ...cellStyles,
                                    color: "#f5222d",
                                };
                            }
                            return {
                                ...cellStyles,
                                color: "#52c41a",
                            };
                        },
                    };
                }
                return col;
            });

        return [
            {
                headerName: "No",
                field: RequiredTableCols.ROW_NUMBER,
                valueGetter: params => params.node.rowIndex + 1,
                width: 75,
                resizable: false,
                filter: false,
                suppressHeaderMenuButton: true,
                suppressMovable: true,
                lockPosition: true,
                suppressNavigable: true,
            },
            ...cols,
        ];
    }, [previewParseResult, fieldMatching]);

    const components = useMemo<{
        [p: string]: any;
    }>(() => {
        return {
            agColumnHeader: CustomTableHeader,
        };
    }, []);

    return (
        <Container absolute flex>
            {(w, h) => (
                <TransactionsTable
                    ref={tableRef}
                    tableKey={"preview-parse-bank-data"}
                    rowData={previewParseResult}
                    gridOptions={gridOptions}
                    components={components}
                    tableColumns={tableColumns}
                    viewHeight={h}
                />
            )}
        </Container>
    );
};
