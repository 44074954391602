import React, { FC, useContext } from "react";
import { BindingButton } from "../components";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useFormProperties } from "../hooks/useFormProperties";
import { useFormRecordComposer } from "../hooks/useFormRecordComposer";
import { useFormHandlerConnectedPayment } from "../hooks/handlers/useFormHandlerConnectedPayment";

export const FormButtonPaymentConnection: FC = () => {
    const isDisabled = useFormIsRefDisabled();
    const { isBindingButtonDisabled } = useFormProperties();
    const { selectedPayment, recordDate } = useContext(RecordFormStateContext);
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { composeFormRecord } = useFormRecordComposer();
    const onChangeConnectedPayment = useFormHandlerConnectedPayment();
    return (
        <BindingButton
            mode={"form"}
            formSelectedPayment={selectedPayment}
            isButtonDisabled={isBindingButtonDisabled}
            isEditDisabled={isDisabled(refsHtml.REF_cBINDING)}
            isDeleteDisabled={isDisabled(refsHtml.REF_cBINDING)}
            paymentRepresentationRecord={recordDate.date ? composeFormRecord() : undefined}
            ref={refsHtml.REF_cBINDING}
            onSave={onChangeConnectedPayment}
        />
    );
};
