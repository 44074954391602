import { ApolloError, DocumentNode, OperationVariables, useApolloClient } from "@apollo/client";
import { GQL } from "@binale-tech/shared";
import { authErrorTranstalions } from "scripts/api/firebase/firebaseErrorMessages";
import { logger } from "scripts/infrastructure/logger";
import { message } from "antd";
import { useIntl } from "react-intl";
import { useMemo } from "react";

interface MessagesOptions {
    loading?: string;
    success?: string;
    error?: string;
}

interface IUseMutatorProps {
    messages?: MessagesOptions;
}

interface IMutateProps<InputType> {
    mutation: DocumentNode;
    input: InputType;
    messages?: MessagesOptions;
    hideMessages?: boolean;
    throwOnError?: boolean;
}

export const useGqlMutator = (opts: IUseMutatorProps = {}) => {
    const intl = useIntl();
    const client = useApolloClient();

    const defaultMessages: MessagesOptions = useMemo(
        () => ({
            loading: intl.formatMessage({ id: "app.message.progress" }),
            success: intl.formatMessage({ id: "app.message.success" }),
            error: intl.formatMessage({ id: "app.global.error" }),
            ...(opts.messages || {}),
        }),
        [intl, opts.messages]
    );

    return useMemo(
        () => ({
            async mutate<MutatorType extends keyof GQL.IMutation, InputType extends OperationVariables>(
                { mutation, input, throwOnError = false, hideMessages }: IMutateProps<InputType>,
                messagesOpts?: MessagesOptions
            ) {
                const messages = { ...defaultMessages, ...messagesOpts };

                let hideLoadingMessage;
                if (!hideMessages && messages?.loading) {
                    hideLoadingMessage = message.loading(messages.loading, 0);
                }

                try {
                    const result = await client.mutate<Pick<GQL.IMutation, MutatorType>, { input: InputType }>({
                        mutation,
                        variables: { input },
                    });
                    if (!hideMessages && messages?.success) {
                        message.success(messages.success);
                    }
                    return result?.data;
                } catch (e) {
                    if (!(e instanceof ApolloError)) {
                        logger.error("mutate function returns not the ApolloError", e);
                        return undefined;
                    }
                    const errorCode = e.graphQLErrors[0]?.extensions.code;
                    if (errorCode && authErrorTranstalions[errorCode as any]) {
                        message.error(intl.formatMessage({ id: authErrorTranstalions[e.message] }));
                    } else {
                        message.error(`${messages.error}: ${e.message}`);
                    }
                    if (throwOnError) {
                        throw e;
                    }
                } finally {
                    if (hideLoadingMessage) {
                        hideLoadingMessage();
                    }
                }
                return undefined;
            },
        }),
        [defaultMessages, client, intl]
    );
};
