import { Input, type InputRef } from "antd";
import { TextAreaProps } from "antd/es/input/TextArea";
import React, { forwardRef } from "react";

interface TextAreaInputProps extends Omit<TextAreaProps, "onChange" | "value"> {
    onChange?: (val: string) => void;
    value?: string;
    pattern?: string;
}

export const TextAreaInput = forwardRef<InputRef, TextAreaInputProps>((props, ref) => {
    const { value, onChange, pattern, disabled, ...rest } = props;
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!pattern || !e.target.value || new RegExp(pattern, "g").test(e.target.value)) {
            if (onChange) {
                onChange(e.target.value);
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.shiftKey && e.key === "Enter") {
            return;
        }

        if (e.key === "Enter") {
            e.stopPropagation();
            e.preventDefault();

            const textArea = e.target as HTMLTextAreaElement;
            const start = textArea.selectionStart;
            const end = textArea.selectionEnd;
            const fieldValue = textArea.value;

            textArea.value = fieldValue.substring(0, start) + "\n" + fieldValue.substring(end);

            const newCursorPos = start + 1;
            textArea.setSelectionRange(newCursorPos, newCursorPos);
        }
    };
    return (
        <Input.TextArea
            {...rest}
            rows={3}
            ref={ref}
            value={value || ""}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            disabled={disabled}
        />
    );
});
