import React from "react";
import pLimit from "p-limit";
import { DocumentNode } from "@apollo/client";
import { GQL } from "@binale-tech/shared";
import { UserContext, UserProviderCompany } from "../../../scripts/context/UserProvider";
import { useGQLRetriever } from "../../../scripts/graphql/gqlRetriever";
import { useSearchParams } from "react-router-dom";
import { DatevContext } from "../../../scripts/context/DatevContext";

export interface CompanyTableRecord {
    company: GQL.ICompany;
    key: string;
    hasAdditionalDataFetched: boolean;
    nr: number;
    users: string[];
    canDrop: boolean;
    canEdit: boolean;
}

export const useCompanyDataFetch = (
    companies: UserProviderCompany[],
    query: DocumentNode,
    page: number,
    pageSize: number
) => {
    const [searchParams] = useSearchParams();
    const searchTerm = searchParams.get("search");
    const datev = Boolean(searchParams.get("datev"));
    const user = React.useContext(UserContext);
    const { datevUserInfo } = React.useContext(DatevContext);
    const retriever = useGQLRetriever<"company">();
    const [loading, setLoading] = React.useState(false);
    const [dataSource, setDataSource] = React.useState<CompanyTableRecord[]>([]);
    const dataSourceRef = React.useRef<CompanyTableRecord[]>(dataSource);

    const fetchCompaniesData = React.useCallback(
        (ids: string[]) => {
            setLoading(true);
            const limit = pLimit(5);
            const input = ids.map(id =>
                limit(() =>
                    retriever.query({ id, query, fetchPolicy: "network-only" }).then(data => {
                        return data?.company;
                    })
                )
            );
            return Promise.all(input).finally(() => setLoading(false));
        },
        [query, retriever]
    );
    const fetchAndSetCompaniesData = React.useCallback(
        (useRefetch?: boolean) => {
            const clientsSet = new Set((datevUserInfo?.clients ?? []).map(c => c.id));
            const draftRes = companies
                .filter(v => !searchTerm || v.name.toLowerCase().includes(searchTerm))
                .filter(v => !datev || clientsSet.has(`${v.datevNrConsultant}-${v.datevNrCompany}`));
            const paginatedViewCompanyIds = companies.slice((page - 1) * pageSize, page * pageSize).map(({ id }) => id);
            const searchListCompanyIds = draftRes.slice(0, 10).map(({ id }) => id);
            const companyIdsToFetch = searchTerm ? searchListCompanyIds : paginatedViewCompanyIds;
            const filteredCompanyIdsToFetch = companyIdsToFetch.filter(
                id => useRefetch || !dataSourceRef.current.find(ds => ds.key === id)?.hasAdditionalDataFetched
            );
            const canEditFn = (i: GQL.ICompany) => {
                return user.isAdminWrite || i.owner?.id === user.fireUser.uid;
            };
            fetchCompaniesData(filteredCompanyIdsToFetch).then(list => {
                const fullDataIds = new Set<string>();
                draftRes.forEach(({ id }, index) => {
                    const fullDataNew = list.find(v => v.id === id);
                    const fullDataExisting = dataSourceRef.current.find(
                        v => v.key === id && v.hasAdditionalDataFetched
                    )?.company;
                    if (fullDataNew) {
                        fullDataIds.add(id);
                        draftRes[index] = fullDataNew;
                    } else if (fullDataExisting) {
                        fullDataIds.add(id);
                        draftRes[index] = fullDataExisting;
                    }
                });

                const enrichedList = draftRes as GQL.ICompany[];
                setDataSource(
                    enrichedList.map((company, idx) => {
                        const canEdit = canEditFn(company);
                        const canDrop = canEdit && user.isAdminWrite && company.id !== user.selectedCompany;
                        return {
                            canEdit,
                            canDrop,
                            company,
                            key: company.id,
                            hasAdditionalDataFetched: fullDataIds.has(company.id),
                            nr: idx + 1,
                            users: company.members?.map(m => m.emailKey) || [],
                        } satisfies CompanyTableRecord;
                    })
                );
            });
        },
        [
            datevUserInfo?.clients,
            companies,
            page,
            pageSize,
            searchTerm,
            fetchCompaniesData,
            datev,
            user.isAdminWrite,
            user.fireUser.uid,
            user.selectedCompany,
        ]
    );
    React.useEffect(() => {
        dataSourceRef.current = dataSource;
    }, [dataSource]);
    React.useEffect(() => {
        if (user.isLoading) {
            setDataSource([]);
        } else {
            fetchAndSetCompaniesData();
        }
    }, [fetchAndSetCompaniesData, fetchCompaniesData, user.isLoading]);
    return { loading, dataSource, fetchAndSetCompaniesData };
};
