import { Base, GQL } from "@binale-tech/shared";

export interface IPaymentType {
    value: GQL.IPaymentType;
    konto?: string;
    isFavourite?: boolean;
    uuid?: string;
}

export class PaymentType {
    static readonly OptionBank = GQL.IPaymentType.Bank;
    static readonly OptionKasse = GQL.IPaymentType.Kasse;
    static readonly OptionPayment = GQL.IPaymentType.Payment;
    // disabled system types
    static readonly OptionVerrechnung = GQL.IPaymentType.Verrechnung;
    static readonly OptionFE = GQL.IPaymentType.Fe;

    static readonly BANK: IPaymentType = {
        value: PaymentType.OptionBank,
    };
    static readonly KASSE: IPaymentType = {
        value: PaymentType.OptionKasse,
    };
    static readonly PAYMENT: IPaymentType = {
        value: PaymentType.OptionPayment,
    };
    static readonly VERRECHNUNG: IPaymentType = {
        value: PaymentType.OptionVerrechnung,
    };
    static readonly FE: IPaymentType = {
        value: PaymentType.OptionFE,
    };

    static isBlocked(type: IPaymentType) {
        return type.value === this.VERRECHNUNG.value || type.value === this.FE.value;
    }
}

export interface PaymentRecordRelations {
    /**
     * sourceRecordKey
     * Source of payment (what are we paying for)
     * Can be a record from ER, ER_A, Deb + FE
     */
    sourceRecordKey?: string;

    /**
     * representationRecordKey
     * Representation of payment (with what record did we pay)
     * Can be a record from KB, Bank + FE
     */
    representationRecordKey?: string;

    /**
     * Auto FE transaction is used it to balance prepayment transaction (sourceRecordKey is from ER_A)
     */
    autoFEBalancingAZRecordKey?: string;

    /**
     * Auto FE transaction is used in case of Skonto in payment is !== 0
     */
    autoFEDiscountRecordKey?: string;
}

export default class Payment implements Base.IKey, PaymentRecordRelations {
    public key: string;
    private _date: Date;
    private _zahlungsBetrag: number;
    private _skontoBetrag: number;
    private _type: IPaymentType;

    // used to link payment to special record in FE (from user input) <recordKey(ER_A), amount>
    // now it's 1-1 connection, 1-N is deprecated
    // todo: migrate to paymentSource?: {recordKey: string, amount: number}
    public paymentSources: Record<string, number> = {};

    public sourceRecordKey?: string;
    public representationRecordKey?: string;
    public autoFEBalancingAZRecordKey?: string;
    public autoFEDiscountRecordKey?: string;

    getRecordRelationKeys() {
        return [
            this.sourceRecordKey,
            this.representationRecordKey,
            this.autoFEBalancingAZRecordKey,
            this.autoFEDiscountRecordKey,
        ].filter(Boolean);
    }

    getAutoFERecordKeys() {
        const keys = [this.autoFEBalancingAZRecordKey, this.autoFEDiscountRecordKey];
        if (Object.keys(Object(this.paymentSources)).length > 0) {
            // Created from ER as Verrechnung Payment with corresponding records in paymentSources
            // This is a payment with ER as sourceRecordKey and auto FE as representationRecordKey
            keys.unshift(this.representationRecordKey);
        }
        return keys.filter(Boolean);
    }

    constructor(date: Date, zahlungsBetrag: number, type: IPaymentType, skontoBetrag: number) {
        this._date = date;
        this._zahlungsBetrag = zahlungsBetrag;
        this._type = type;
        this._skontoBetrag = skontoBetrag;
    }

    static unserialize(v: Partial<Payment> & Record<string, any>): Payment {
        v._date = v._date instanceof Date ? v._date : new Date(v._date as string);
        // v._type = PaymentType.parse(v._type);
        const payment = Object.assign(new Payment(null, null, null, null), v);
        return payment;
    }

    get date(): Date {
        return this._date;
    }

    set date(value: Date) {
        this._date = value;
    }

    get zahlungsBetrag(): number {
        return Number.isFinite(this._zahlungsBetrag) ? this._zahlungsBetrag : 0;
    }

    set zahlungsBetrag(value: number) {
        this._zahlungsBetrag = value;
    }

    get type(): IPaymentType {
        return this._type;
    }

    set type(value: IPaymentType) {
        this._type = value;
    }

    get skontoBetrag(): number {
        return Number.isFinite(this._skontoBetrag) ? this._skontoBetrag : 0;
    }

    set skontoBetrag(value: number) {
        this._skontoBetrag = value;
    }

    get skontoPercent(): number {
        const sum = this.getSum();
        if (sum === 0) {
            return 0;
        }
        return +(((this._skontoBetrag || 0) / this.getSum()) * 100).toFixed(2);
    }

    setSkontoPercent(percent: number, totalAmount: number) {
        this._skontoBetrag = +((totalAmount * (percent || 0)) / 100).toFixed(0);
        this._zahlungsBetrag = totalAmount - this._skontoBetrag;
    }

    getSum() {
        return this.zahlungsBetrag + this.skontoBetrag;
    }
}

export interface PaymentPrototype extends Required<Pick<PaymentRecordRelations, "sourceRecordKey">> {
    skontoBetrag?: number;
}
