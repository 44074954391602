import ItemsTemplate from "./ItemsTemplate";
import React, { useContext } from "react";
import { DmsAppContext } from "@dms/types/ContextTypes";
import { type Identifier } from "dnd-core/src/interfaces";
import { useDragLayer, type XYCoord } from "react-dnd";
import { blue } from "@ant-design/colors";

const layerStyles: React.CSSProperties = {
    position: "fixed",
    pointerEvents: "none",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
};

const getFieldStyle = (): React.CSSProperties => {
    return {
        width: "15%",
        backgroundColor: blue[5],
        padding: "20px",
        color: "white",
        borderRadius: "5px",
        fontSize: "14px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.8,
    };
};

const getItemStyles = (currentOffset: XYCoord | null): React.CSSProperties => {
    if (!currentOffset) {
        return {
            display: "none",
        };
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x + 15}px, ${y + 15}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
};

export function ItemsDragLayer() {
    const { itemType, isDragging, currentOffset } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    const { isDraggingRightNow } = useContext(DmsAppContext);

    const renderItem = (type: Identifier | null) => {
        switch (type) {
            case "ITEM":
                return <ItemsTemplate />;
            default:
                return null;
        }
    };
    if (!isDragging) {
        return null;
    }

    return (
        isDraggingRightNow && (
            <div style={layerStyles}>
                <div style={getItemStyles(currentOffset)}>
                    <div style={getFieldStyle()}>{renderItem(itemType)}</div>
                </div>
            </div>
        )
    );
}
