import React, { useEffect, useState } from "react";
import { Input, InputProps, InputRef } from "antd";

interface ITransformOptions {
    precision?: number;
    onlyInteger?: boolean; // false
    negative?: boolean; // true
}

interface Props extends ITransformOptions, Omit<InputProps, "onChange" | "value"> {
    value?: number;
    onChange?: (value: number) => void;
}

const normalizeNumber = (value: number, options?: ITransformOptions): number => {
    let result = value;
    if (value === undefined) {
        return value;
    }
    if (options?.negative === false) {
        result = Math.abs(result);
    }
    if (options?.onlyInteger) {
        result = Math.round(result);
    }
    if (options?.precision) {
        result = Number(result.toFixed(options.precision));
    }
    return result;
};

const replaceExtrasInStr = (value: string, options?: ITransformOptions): string => {
    let numberStr = value.replace(/[^0-9,-]/g, "");
    const isNegative = numberStr.startsWith("-");
    numberStr = numberStr.replace(/[^0-9,]/g, "");
    numberStr = isNegative ? "-" + numberStr : numberStr;
    if (options?.negative === false && isNegative) {
        numberStr = numberStr.substring(1);
    }
    if (options?.onlyInteger && numberStr.search(",") !== -1) {
        numberStr = numberStr.substring(0, numberStr.search(","));
    }
    if (numberStr.match(/,/g)?.length > 1) {
        const partAfterComma = numberStr.substring(numberStr.search(",") + 1);
        numberStr = numberStr.substring(0, numberStr.search(",") + 1) + partAfterComma.replace(/,/g, "");
    }
    if (options?.precision && numberStr.search(",") !== -1) {
        const floatPart = numberStr.substring(numberStr.search(",") + 1);
        if (floatPart.length > options.precision) {
            numberStr = numberStr.substring(0, numberStr.length - (floatPart.length - options.precision));
        }
    }
    return numberStr;
};

const transformStringToNumber = (value: string, options?: ITransformOptions): number | undefined => {
    let transformedStr = replaceExtrasInStr(value, options);
    transformedStr = transformedStr.replace(/,/g, ".");
    const num = Number(transformedStr);
    if (num === 0 || isNaN(num)) {
        return isNaN(num) || !transformedStr.length ? undefined : 0;
    }
    return normalizeNumber(num, options);
};

const transformNumberToString = (value: number, options?: ITransformOptions): string => {
    if (value === undefined) {
        return "";
    }
    const newInnerNum = options?.precision ? value.toFixed(options.precision) : value.toString();
    return newInnerNum.replace(".", ",");
};

export const InputNumber = React.forwardRef<InputRef, Props>(function InputNumber(props, ref) {
    const { precision, onlyInteger = false, negative = true, max, value, onChange, ...rest } = props;
    const transformOptions: ITransformOptions = { precision, onlyInteger, negative };
    const [innerValue, setInnerValue] = useState("");
    const [innerNumberValue, setInnerNumberValue] = useState(normalizeNumber(value, transformOptions));

    useEffect(() => {
        if (value !== innerNumberValue) {
            const newInnerValue = normalizeNumber(value, transformOptions);
            setInnerNumberValue(newInnerValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        const strNum = transformStringToNumber(innerValue, transformOptions);

        if (strNum !== innerNumberValue) {
            setInnerValue(transformNumberToString(innerNumberValue, transformOptions));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerNumberValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = replaceExtrasInStr(e.target.value, transformOptions);
        if (newVal !== innerValue) {
            setInnerValue(newVal);
            const num = transformStringToNumber(newVal);
            if (num !== innerNumberValue && props.onChange) {
                props.onChange(num);
            }
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setInnerValue(transformNumberToString(innerNumberValue, transformOptions));
        props.onBlur && props.onBlur(e);
    };

    return <Input {...rest} value={innerValue} onChange={handleChange} onBlur={handleBlur} ref={ref} />;
});
