import { DmsModal, UploaderModal } from "@dms/index";
import { PreviewPdf } from "./PreviewPdf";
import React, {
    ContextType,
    DragEventHandler,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { deepEqual } from "fast-equals";
import { AddDmsIdInput } from "./AddDmsIdInput";
import { Button, Card, Col, Flex, Modal, Row, Segmented } from "antd";
import {
    CloseOutlined,
    CloudOutlined,
    CloudUploadOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    FileAddOutlined,
} from "@ant-design/icons";
import { DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { DocumentIcon, DocumentThumbnail } from "./DocumentThumbnail";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { useCanRead, useIsMounted } from "../../../../scripts/infrastructure/hooks";

import "./FileUploader.css";
import { Switch } from "@nextui-org/switch";
import { PreviewDms } from "@app/components/recordform/FileUploader/PreviewDms";
import { PreviewInvoice } from "@app/components/recordform/FileUploader/PreviewInvoice";

interface Props {
    currentDocumentIds: string[];
    onHide: (e: React.MouseEvent) => void;
    onSave?: (vs: GQL.IRecordDocumentInput[]) => void;
    onChangeDocuments?: (vs: GQL.IRecordDocumentInput[]) => void;
    mode: "table" | "form";
    allowEdit?: boolean;
    productKey?: GQL.IProductKey;
}

const supportedProductKeys = [
    GQL.IProductKey.Er,
    GQL.IProductKey.Deb,
    GQL.IProductKey.Pos,
    GQL.IProductKey.La,
    GQL.IProductKey.Fe,
    GQL.IProductKey.Bank,
    GQL.IProductKey.Kb,
];

type CardWrapperProps = Pick<Props, "onHide"> & {
    onDragEnter: DragEventHandler;
    footer: React.ReactNode;
    isCompactMode: boolean;
    previewMode: PreviewMode;
    isModeDisabled?: boolean;
    changeCompactMode: () => void;
    onChangePreviewMode: (v: PreviewMode) => void;
};

const CardWrapper: FC<PropsWithChildren<CardWrapperProps>> = ({
    children,
    onHide,
    onDragEnter,
    footer,
    isCompactMode,
    previewMode,
    isModeDisabled,
    changeCompactMode,
    onChangePreviewMode,
}) => {
    return (
        <Card
            size={"small"}
            title={
                <Flex align={"center"}>
                    <Button
                        type={"text"}
                        size={"small"}
                        shape={"circle"}
                        icon={isCompactMode ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                        style={{ marginRight: 16 }}
                        onClick={changeCompactMode}
                        disabled={isModeDisabled}
                    />
                    <FormattedMessage id="app.dms.add_documents" />
                    <div style={{ width: 100 }}></div>
                    <Segmented<PreviewMode>
                        disabled={isModeDisabled}
                        value={previewMode}
                        options={[
                            { label: "PDF", value: "preview" },
                            { label: "DMS", value: "dms" },
                            { label: "E-Rechnung", value: "invoice" },
                        ]}
                        onChange={onChangePreviewMode}
                    />
                </Flex>
            }
            extra={<Button size={"small"} shape={"circle"} icon={<CloseOutlined />} onClick={onHide} />}
            style={{
                height: "100%",
            }}
            styles={{
                body: { overflow: "hidden", height: "calc(100% - 35px)", position: "relative", padding: "10px 5px" },
            }}
            onDragEnter={onDragEnter}
        >
            <Flex vertical style={{ position: "relative", height: "100%" }}>
                <div style={{ position: "relative", height: "calc(100% - 30px)", overflowY: "hidden" }}>{children}</div>
                <div style={{ marginTop: 5 }}>{footer}</div>
            </Flex>
        </Card>
    );
};

const ModalWrapper: FC<
    PropsWithChildren<
        Pick<Props, "onHide"> & {
            onDragEnter: DragEventHandler;
            footer: React.ReactNode;
        }
    >
> = ({ children, onHide, onDragEnter, footer }) => {
    return (
        <Modal
            className="FileUploader"
            styles={{
                body: {
                    minWidth: "70vw",
                    height: `calc(100vh - 125px)`,
                },
            }}
            style={{ top: 10, display: "flex", alignItems: "center", justifyContent: "center" }}
            width={1000}
            open={true}
            onCancel={onHide}
            destroyOnClose
            wrapProps={{ onDragEnter }}
            footer={[footer]}
        >
            <Flex vertical style={{ position: "relative", height: "100%" }}>
                {children}
            </Flex>
        </Modal>
    );
};

type PreviewMode = "preview" | "dms" | "invoice";

export const FileUploader: FC<Props> = props => {
    const { documentsKV } = useContext(DmsDataContext);
    const { resetAppContext } = useContext(DmsAppControlContext);
    const hasDMSAccess = useCanRead(GQL.IProductKey.Dms);
    const [isCompactMode, setIsCompactMode] = useState(props.mode === "form");
    const [previewMode, setPreviewMode] = useState<PreviewMode>("preview");
    const [documentIds, setDocumentIds] = useState<string[]>(props.currentDocumentIds);
    const [selectedDocumentIdx, setSelectedDocumentIdx] = useState<number>(0);
    const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(props.currentDocumentIds?.[0]);
    const documentsRef = useRef<ContextType<typeof DmsDataContext>["documentsKV"]>({});
    const isMounted = useIsMounted();

    useEffect(() => {
        documentsRef.current = documentsKV;
    }, [documentsKV]);

    useEffect(() => {
        // reset context on mount
        resetAppContext();
    }, [resetAppContext]);

    const changeCompactMode = useCallback(() => setIsCompactMode(v => !v), []);

    const getRecordFiles = useCallback(() => {
        const res = documentIds
            .map(id => {
                const doc = documentsRef.current[id];
                if (!doc) {
                    return null;
                }
                return {
                    id: doc.key,
                    url: doc.fileUrl,
                };
            })
            .filter(Boolean);
        return res;
    }, [documentIds]);

    const { selectedRecordGroup } = useContext(TableViewContext);

    const [showDmsModal, setShowDmsModal] = useState(false);
    const [showUploaderModal, setShowUploaderModal] = useState(false);

    const productKey = supportedProductKeys.includes(props.productKey) ? props.productKey : undefined;

    useEffect(() => {
        // in case of table there is no external state and the internal will be set on mount, so we should avoid external state sync
        if (props.mode === "table") {
            return;
        }
        setDocumentIds(prevState => {
            return deepEqual(prevState, props.currentDocumentIds) ? prevState : props.currentDocumentIds;
        });

        if (props.currentDocumentIds?.length) {
            setPreviewDocumentId(props.currentDocumentIds[0]);
        }
    }, [props.currentDocumentIds, props.mode]);

    useEffect(() => {
        setSelectedDocumentIdx(0);
        setPreviewDocumentId(documentIds[0]);
    }, [documentIds]);

    useEffect(() => {
        if (isMounted() && props.mode === "form") {
            setTimeout(() => props.onChangeDocuments(getRecordFiles()), 100);
        }
    }, [getRecordFiles, props.mode, isMounted]);

    const hasChanges = () => {
        const setA = props.currentDocumentIds ? new Set(props.currentDocumentIds) : new Set<string>();
        const setB = new Set(documentIds);

        if (setA.size !== setB.size) {
            return true;
        }
        const aDiffB = new Set([...setA].filter(x => !setB.has(x)));
        const bDiffA = new Set([...setB].filter(x => !setA.has(x)));
        return aDiffB.size > 0 || bDiffA.size > 0;
    };

    const handleDelete = useCallback(
        (key: string) => {
            if (!props.allowEdit) {
                return;
            }

            setDocumentIds(prev => prev.filter(v => v !== key));
        },
        [props.allowEdit]
    );

    const handleDmsCancel = () => {
        setShowDmsModal(false);
    };

    const handleAddDocuments = (fileKeys: string[]) => {
        setDocumentIds(prevState => {
            const newKeys = fileKeys.filter(id => !prevState.includes(id));
            return newKeys.length ? [...prevState, ...newKeys] : prevState;
        });
    };

    const handleSave = () => {
        props.onSave(getRecordFiles());
    };

    const onDragEnter: DragEventHandler = e => {
        e.preventDefault();
        if (!props.allowEdit || showDmsModal) {
            return;
        }
        if (!showUploaderModal) {
            setShowUploaderModal(true);
        }
    };

    const footer = props.allowEdit ? (
        <Row gutter={10} key="row" justify={"end"}>
            <Col span={10}>
                {hasDMSAccess && <AddDmsIdInput handleAddSearchedId={id => handleAddDocuments([id])} />}
            </Col>
            <Col span={4}>
                <Button
                    onClick={() => setShowUploaderModal(true)}
                    icon={<CloudUploadOutlined />}
                    className="FileUploader__Button"
                >
                    <strong className="button-text button-media">
                        <FormattedMessage id="app.button.upload" />
                    </strong>
                </Button>
                {showUploaderModal && (
                    <UploaderModal
                        isOpen={showUploaderModal}
                        onCancel={() => setShowUploaderModal(false)}
                        onFilesAdd={handleAddDocuments}
                        productData={{ productKey, selectedRecordGroup }}
                    />
                )}
            </Col>
            <Col span={6}>
                {hasDMSAccess && (
                    <>
                        <Button
                            onClick={() => setShowDmsModal(true)}
                            icon={<CloudOutlined />}
                            className="FileUploader__Button"
                        >
                            <span className="button-text button-media">
                                <FormattedMessage id="app.dms.selectInDms" />
                            </span>
                        </Button>

                        <DmsModal
                            productKey={productKey}
                            visible={showDmsModal}
                            selectedFiles={documentIds}
                            onCancel={handleDmsCancel}
                            onSave={ids => {
                                handleAddDocuments(ids);
                                setShowDmsModal(false);
                            }}
                        />
                    </>
                )}
            </Col>
            <Col span={4}>
                {props.mode === "table" && (
                    <Button
                        className="FileUploader__Button"
                        type="primary"
                        onClick={handleSave}
                        disabled={!hasChanges()}
                        key="save"
                    >
                        <span className="button-text">
                            <FormattedMessage id="app.button.save" />
                        </span>
                    </Button>
                )}
            </Col>
        </Row>
    ) : null;

    const body = (
        <>
            <Row style={{ height: "100%" }}>
                <Col
                    style={{
                        overflowY: "auto",
                        scrollbarGutter: "stable",
                        height: "100%",
                        paddingRight: isCompactMode ? 10 : undefined,
                        paddingLeft: isCompactMode ? 5 : undefined,
                    }}
                    sm={isCompactMode ? 2 : 4}
                >
                    {documentIds.length ? (
                        documentIds.map((key, idx) => {
                            if (isCompactMode) {
                                return (
                                    <DocumentIcon
                                        fileId={key}
                                        key={"tn-" + idx}
                                        onClick={() => {
                                            setPreviewDocumentId(key);
                                            setSelectedDocumentIdx(idx);
                                        }}
                                        selected={selectedDocumentIdx === idx}
                                    />
                                );
                            }
                            return (
                                <DocumentThumbnail
                                    fileId={key}
                                    key={"tn-" + idx}
                                    onDelete={props.allowEdit ? () => handleDelete(key) : undefined}
                                    onClick={() => {
                                        setPreviewDocumentId(key);
                                        setSelectedDocumentIdx(idx);
                                    }}
                                    selected={selectedDocumentIdx === idx}
                                />
                            );
                        })
                    ) : (
                        <div className={"FileUploader__empty"} onClick={() => setShowUploaderModal(true)}>
                            <FileAddOutlined />
                        </div>
                    )}
                </Col>
                <Col sm={isCompactMode ? 22 : 20} style={{ height: "100%", paddingLeft: 10 }}>
                    {previewMode === "preview" && <PreviewPdf fileId={previewDocumentId} />}
                    {previewMode === "dms" && <PreviewDms fileId={previewDocumentId} />}
                    {previewMode === "invoice" && <PreviewInvoice fileId={previewDocumentId} productKey={productKey} />}
                </Col>
            </Row>
        </>
    );
    return props.mode === "form" ? (
        <CardWrapper
            onDragEnter={onDragEnter}
            footer={footer}
            onHide={props.onHide}
            isCompactMode={isCompactMode}
            changeCompactMode={changeCompactMode}
            previewMode={previewMode}
            onChangePreviewMode={setPreviewMode}
            isModeDisabled={!previewDocumentId}
        >
            {body}
        </CardWrapper>
    ) : (
        <ModalWrapper onDragEnter={onDragEnter} footer={footer} onHide={props.onHide}>
            {body}
        </ModalWrapper>
    );
};
