import React from "react";
import dayjs from "dayjs";
import { AbstractAuswertung } from "./AbstractAuswertung";
import { FormattedMessage } from "react-intl";
import { GenericRecordProperties } from "../../../../scripts/core/Product";

import { ToolbarAuswertungAvis, ToolbarAuswertungOpos } from "./auswertungToolbars/ToolbarAuswertungOposAvis";
import { ToolbarAuswertungFalligkeit } from "./auswertungToolbars/ToolbarAuswertungFalligkeit";

export class AuswertungOposViewClass extends AbstractAuswertung {
    protected getHeader(): React.ReactNode {
        return (
            <span>
                <FormattedMessage id="app.titles.ER.auswertung" /> OPOS
            </span>
        );
    }
    protected getInlineForm(): React.ReactNode {
        return null;
    }
    protected getToolbarBlock() {
        return <ToolbarAuswertungOpos tableColumns={this.tableColumns} onDownload={this.handleDownload} />;
    }

    protected getExportFilename() {
        return [dayjs().format("DD.MM.YYYY"), "OPOS"].join("_");
    }

    protected useCollapsedData() {
        return true;
    }

    render() {
        return this.getPageWithoutLeftTable();
    }
}

export class AuswertungAvisViewClass extends AbstractAuswertung {
    protected getHeader(): React.ReactNode {
        return (
            <span>
                <FormattedMessage id="app.titles.ER.auswertung" /> <FormattedMessage id="app.titles.ER.za" />
            </span>
        );
    }
    protected getInlineForm(): React.ReactNode {
        return null;
    }
    protected getToolbarBlock() {
        return <ToolbarAuswertungAvis tableColumns={this.tableColumns} onDownload={this.handleDownload} />;
    }

    protected getExportFilename() {
        return [dayjs().format("DD.MM.YYYY"), "Zahlungsavis"].join("_");
    }
    protected useCollapsedData() {
        return true;
    }

    render() {
        return this.getPageWithoutLeftTable();
    }
}

export class AuswertungFalligkeitViewClass extends AbstractAuswertung {
    protected getDefaultSortColumn() {
        return GenericRecordProperties.RecordFalligkeit;
    }

    protected getHeader(): React.ReactNode {
        return (
            <span>
                <FormattedMessage id="app.fields.falligkeit" />
            </span>
        );
    }
    protected getInlineForm(): React.ReactNode {
        return null;
    }

    protected getToolbarBlock() {
        return <ToolbarAuswertungFalligkeit tableColumns={this.tableColumns} onDownload={this.handleDownload} />;
    }

    protected getExportFilename() {
        return [dayjs().format("DD.MM.YYYY"), "Fälligkeiten"].join("_");
    }

    render() {
        return this.getPageWithoutLeftTable();
    }
}
