import { IMessagesType } from "../intl/translations/de";

export class BinaleError extends Error {
    readonly translationKey?: keyof IMessagesType;

    constructor(message: string, translationKey: keyof IMessagesType) {
        super(message);
        this.translationKey = translationKey;
    }
}
