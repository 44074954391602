import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Flex, Input, Popconfirm, Space, Spin, Typography } from "antd";
import { getDateFormat, getTransactionBankTitle } from "@banks/scripts/helpers";
import { GQL, Utils } from "@binale-tech/shared";

import styles from "./BankTransactionsToolbar.module.scss";
import cn from "classnames";
import dayjs from "dayjs";
import { BanksAppControlContext } from "@banks/scripts/context";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../scripts/routing/routeConstants";
import { FormattedMessage } from "react-intl";
import { ArrowLeftOutlined, CloseCircleOutlined, CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { BanksApi } from "@banks/scripts/api";

const { Title } = Typography;

type TProps = {
    transactionBank: GQL.IBank;
    balance: number;
    balanceDate?: string;
    handleSearch: (arg: string) => void;
    selectedRows: GQL.ITransaction[];
};

export const BankTransactionsToolbar: FC<TProps> = ({
    transactionBank,
    balance,
    balanceDate,
    handleSearch,
    selectedRows,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);
    const [deleteBtnDisabled, setDeleteBtnDisabled] = useState<boolean>(false);

    const { openTransactionUploader } = useContext(BanksAppControlContext);

    const navigate = useNavigate();

    useEffect(() => {
        setDeleteBtnDisabled(selectedRows.length < 2);
    }, [selectedRows.length]);

    const goToAllBank = () => {
        navigate(AppRoutes.banks);
    };

    const handleUpdateBank = () => {
        openTransactionUploader(transactionBank);
    };

    const handleDeleteTransactions = async () => {
        setDeleteBtnDisabled(true);
        setIsLoading(true);
        const ids = selectedRows.map(row => row.id);
        const bankId = transactionBank.id;
        const companyId = transactionBank.companyId;
        const res = await BanksApi.transactionDelete({ bankId, ids, companyId });

        setIsLoading(false);

        if (!res) {
            setIsError(true);
        }

        setTimeout(() => {
            setIsError(false);
            setDeleteBtnDisabled(false);
        }, 3000);
    };
    return (
        <>
            <Flex gap={10} align={"center"} justify={"space-between"}>
                <Title style={{ margin: 0 }} level={5}>
                    {`${getTransactionBankTitle(transactionBank ? { exist: transactionBank } : undefined)}`}
                </Title>
                <Space>
                    <Button type={"text"} onClick={goToAllBank} icon={<ArrowLeftOutlined />}>
                        <FormattedMessage id={"app.banks.allBanks"} />
                    </Button>
                    <Button type={"primary"} onClick={handleUpdateBank} icon={<CloudUploadOutlined />}>
                        <FormattedMessage id={"app.banks.uploadTransactions"} />
                    </Button>
                </Space>
            </Flex>
            <Flex gap={20}>
                <Flex gap={5} align={"center"} style={{ fontSize: 16 }}>
                    <span className={styles.balanceHeaderText}>
                        <FormattedMessage id={"app.fields.openingBalance"} />
                    </span>
                    <span
                        className={cn(styles.balanceHeaderText, {
                            [styles.positive]: transactionBank.openingBalance > 0,
                            [styles.negative]: transactionBank.openingBalance < 0,
                        })}
                    >
                        {`${
                            transactionBank.openingBalance
                                ? Utils.CurrencyUtils.currencyFormat(transactionBank.openingBalance)
                                : "0,00"
                        }`}
                    </span>
                    <span className={styles.balanceHeaderText}>EUR</span>
                    <span className={styles.balanceHeaderText}>
                        {transactionBank.openingBalanceDate
                            ? `on ${dayjs(transactionBank.openingBalanceDate, "DD.MM.YYYY").format("DD MMMM YYYY")}`
                            : null}
                    </span>
                </Flex>
                <Flex gap={5} align={"center"} style={{ fontSize: 16 }}>
                    <span className={styles.balanceHeaderText}>
                        <FormattedMessage id={"app.fields.saldo"} />
                    </span>
                    <span
                        className={cn(styles.balanceHeaderText, {
                            [styles.positive]: balance > 0,
                            [styles.negative]: balance < 0,
                        })}
                    >
                        {`${balance ? Utils.CurrencyUtils.currencyFormat(balance) : "0,00"}`}
                    </span>
                    <span className={styles.balanceHeaderText}>EUR</span>
                    <span className={styles.balanceHeaderText}>
                        {balanceDate
                            ? `on ${dayjs(balanceDate, getDateFormat(balanceDate)).format("DD MMMM YYYY")}`
                            : null}
                    </span>
                </Flex>
                <Flex justify={"end"} style={{ flexGrow: 1 }} gap={15}>
                    {selectedRows.length > 1 ? (
                        <Flex align={"center"} gap={15}>
                            {isError && !isLoading && (
                                <CloseCircleOutlined style={{ fontSize: 24, color: "#ff4d4f" }} />
                            )}
                            {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                            <Popconfirm
                                placement="topRight"
                                title={<FormattedMessage id="app.confirmation.header" />}
                                onConfirm={handleDeleteTransactions}
                                okText={<FormattedMessage id="app.button.confirm" />}
                                cancelText={<FormattedMessage id="app.button.cancel" />}
                            >
                                <Button disabled={deleteBtnDisabled}>
                                    <FormattedMessage id={"app.button.delete"} />({selectedRows.length})
                                </Button>
                            </Popconfirm>
                        </Flex>
                    ) : null}
                    <Input.Search
                        style={{ maxWidth: 500, width: "100%" }}
                        onChange={e => handleSearch(e.target.value)}
                        allowClear
                    />
                </Flex>
            </Flex>
        </>
    );
};
