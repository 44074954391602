import dayjs from "dayjs";
import { type TParseTransaction } from "@banks/types";
import { getDateFormat } from "@banks/scripts/helpers/getDateFormat";

export const getParsedDateRange = (parseRows: TParseTransaction[]) => {
    if (!parseRows.length) {
        return { firstData: undefined, lastData: undefined };
    }

    const parsRowsCopy = [...parseRows].filter(el => el?.bookingDate && el.bookingDate.length);

    parsRowsCopy.sort(
        (a, b) =>
            dayjs(a.bookingDate, getDateFormat(a.bookingDate)).unix() -
            dayjs(b.bookingDate, getDateFormat(b.bookingDate)).unix()
    );

    const firstData = dayjs(parsRowsCopy[0].bookingDate, getDateFormat(parsRowsCopy[0].bookingDate));
    const lastData = dayjs(
        parsRowsCopy[parsRowsCopy.length - 1].bookingDate,
        getDateFormat(parsRowsCopy[parsRowsCopy.length - 1].bookingDate)
    );

    return { firstData: firstData, lastData: lastData };
};
