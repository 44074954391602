import React from "react";
import * as ReactDOM from "react-dom";
import { GenericRecordTableItem } from "../../../components/shared/Table/Table";

interface Props {
    visible?: boolean;
    top: number;
    left: number;

    onHide?: () => void;
    children: React.ReactElement;
    targetItems?: GenericRecordTableItem[];
}
export class TableContextMenuPortal extends React.PureComponent<Props, { height: number }> {
    static defaultProps = {
        onHide: () => {},
    };
    state = {
        height: 0,
    };

    protected setHeight = (height: number) => {
        this.setState({ height });
    };

    render(): React.ReactNode {
        const { top: topFromProps, visible, onHide, left, children } = this.props;
        const { height } = this.state;

        if (!visible) {
            return null;
        }

        let top = topFromProps;
        if (top + height > window.innerHeight) {
            top -= height;
        }

        return ReactDOM.createPortal(
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    zIndex: 999999,
                }}
                onClick={onHide}
                onContextMenu={onHide}
            >
                <div
                    style={{
                        position: "absolute",
                        top,
                        left,
                        opacity: 1,
                    }}
                >
                    {React.cloneElement(children, {
                        acquireHeight: this.setHeight,
                        targetItems: this.props.targetItems,
                    })}
                </div>
            </div>,
            document.body
        );
    }
}
