import React, { ReactElement } from "react";

export interface LayoutProps extends React.HTMLProps<HTMLDivElement> {
    className?: string;
    spaceBetween?: number;
    direction?: "column" | "row";
    justify?: React.CSSProperties["justifyContent"];
}

export const Layout = (props: React.PropsWithChildren<LayoutProps>): ReactElement => {
    const style: React.CSSProperties = {
        display: "flex",
        flexDirection: props.direction ?? "row",
        flexWrap: "wrap",
        position: "relative",
        justifyContent: props.justify ?? "flex-start",
        gap: props.spaceBetween ?? 5,
        ...props.style,
    };
    return (
        <div style={style} className={props.className}>
            {props.children}
        </div>
    );
};
