import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TInvoiceFormLineItem, TTotalState, TInvoiceFormColumnsConfig, getLineItemNetto } from "@inv/types";
import { InvoiceFormColumnsConfig } from "@inv/modules/InvoiceTableModule/config/invoiceFormColumnsConfig";
import { InvoiceFormContext } from "@inv/modules/InvoiceFormModule/context/InvoiceFormProvider";
import { decimalFormatter } from "@dms/scripts/helpers";

type TValue = {
    columnsConfig: TInvoiceFormColumnsConfig;
    isOpenSettingsCol: boolean;
    currency: string;
};

type TActionValue = {
    setColumnsConfig: (newValue: TInvoiceFormColumnsConfig) => void;
    setIsOpenSettingsCol: (newValue: boolean) => void;
    setCurrency: (newValue: string) => void;
};

const initialValue: TValue = {
    columnsConfig: InvoiceFormColumnsConfig.InitColumnConfig,
    isOpenSettingsCol: false,
    currency: null,
};

export const InvoiceTableContext = createContext<TValue>(initialValue);
export const InvoiceTableControlContext = createContext<TActionValue>({
    setColumnsConfig: () => {},
    setIsOpenSettingsCol: () => {},
    setCurrency: () => {},
});

type TProps = {
    children?: ReactNode;
};

export const InvoiceTableProvider: FC<TProps> = ({ children }) => {
    const { invoiceTotalView } = useContext(InvoiceFormContext);
    const [columnsConfig, setColumnsConfig] = useState<TInvoiceFormColumnsConfig>(initialValue.columnsConfig);
    const [isOpenSettingsCol, setIsOpenSettingsCol] = useState<boolean>(false);
    const [currency, setCurrency] = useState<string | null>(null);

    useEffect(() => {
        setColumnsConfig(prev => ({
            ...prev,
            tax: invoiceTotalView,
        }));
    }, [invoiceTotalView]);
    const value: TValue = useMemo(
        () => ({
            columnsConfig,
            isOpenSettingsCol,
            currency,
        }),
        [columnsConfig, currency, isOpenSettingsCol]
    );

    const actions: TActionValue = useMemo(
        () => ({
            setColumnsConfig,
            setIsOpenSettingsCol,
            setCurrency,
        }),
        []
    );

    return (
        <InvoiceTableContext.Provider value={value}>
            <InvoiceTableControlContext.Provider value={actions}>{children}</InvoiceTableControlContext.Provider>
        </InvoiceTableContext.Provider>
    );
};
