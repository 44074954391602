import { AppRoutes } from "../../routing/routeConstants";
import { GQL } from "@binale-tech/shared";
import { ApolloClient, gql } from "@apollo/client";

export const datevObtainAuthUrl = gql`
    mutation datevObtainAuthUrl($input: DatevAuthUrlInput!) {
        datevObtainAuthUrl(input: $input) {
            url
        }
    }
`;

export const datevTokenRefresh = gql`
    mutation datevTokenRefresh($input: DatevRefreshInput!) {
        datevTokenRefresh(input: $input) {
            id
            refreshTokenExpiresIn
            accessTokenExpiresIn
            issuedAt
        }
    }
`;

export const datevTokenRevoke = gql`
    mutation datevTokenRevoke($companyId: ID) {
        datevTokenRevoke(companyId: $companyId)
    }
`;
export const DATEV_AUTH_LS_KEY = "datevOAuth";
export type DatevAuthLSData = {
    nonce: string;
    state: string;
    userId: string;
    companyId?: string;
};

export const redirectToDatevOAuth = async (
    client: ApolloClient<any>,
    userId: string,
    company?: GQL.ICompany
): Promise<void> => {
    const state = crypto.randomUUID();
    const nonce = crypto.randomUUID();
    const urlRes = await client.mutate<Pick<GQL.IMutation, "datevObtainAuthUrl">, GQL.IMutationDatevObtainAuthUrlArgs>({
        mutation: datevObtainAuthUrl,
        variables: {
            input: {
                nonce,
                state,
                redirectUri: window.location.href.split("/").slice(0, 3).join("/") + AppRoutes.datevCallback,
                companyId: company?.id,
            },
        },
    });
    localStorage.setItem(
        DATEV_AUTH_LS_KEY,
        JSON.stringify({
            nonce,
            state,
            userId,
            companyId: company?.id,
        } satisfies DatevAuthLSData)
    );
    window.open(urlRes.data.datevObtainAuthUrl.url, "_blank");
};
