// eslint-disable-next-line import/default
import DetectionWorker from "./worker.ts?worker";
import { logger } from "scripts/infrastructure/logger";
//  edge detection
//     https://link.medium.com/DIZKQCBBH1
//     lenajs, marvin
//     https://github.com/davidsonfellipe/lena.js/blob/master/lib/util/convolution.js
//     https://github.com/davidsonfellipe/lena.js/blob/master/lib/filters/highpass.js
//

export class EmptyDetection {
    static readonly isPageBlank = (canvas: HTMLCanvasElement) => {
        // const original = new MarvinImage();
        // original.fromCanvas(canvas);
        // void Marvin;
        // const image = original.clone();
        // image.clear(0xFF000000);
        // Marvin.grayScale(original, image);
        // const width = original.getWidth() > 800 ? 800 : original.getWidth();
        // Marvin.scale(image, image, width);
        // Marvin.prewitt(image, image);
        // Marvin.invertColors(image, image);
        // Marvin.thresholding(image, image, 200);
        // const imageData: ImageData = original.imageData;
        const ctx = canvas.getContext("2d");
        const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
        return new Promise<Blob>(resolve => {
            const renderWorker: Worker = new DetectionWorker();
            renderWorker.onmessage = (ev: MessageEvent) => {
                renderWorker.terminate();
                const { imgData, isBlank } = ev.data;
                logger.log("DetectionWorker.onmessage", { imgData, isBlank });
                ctx.putImageData(imgData, 0, 0);
                resolve(isBlank);
            };
            renderWorker.postMessage({ imgData: data });
        });
    };
}
