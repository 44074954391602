import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { TParseTransaction } from "@banks/types";
import { BanksContext } from "../../../scripts/context/BanksContext";
import { GQL } from "@binale-tech/shared";

type TValue = {
    parseResult: Record<string, string>[];
    transactionBank: GQL.IBank;
};
type TActionValue = {
    setParseResult: (arg: Record<string, string>[]) => void;
};

const initialActionValue = {
    setParseResult: () => Promise.resolve(),
};

export const ParseContext = createContext<TValue>({} as TValue);
export const ParseControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const ParseContextProvider: FC<TProps> = ({ children }) => {
    const [parseResult, setParseResult] = useState<Record<string, string>[]>([]);
    const [transactionBank, setTransactionBank] = useState<GQL.IBank>();

    const { banksData } = useContext(BanksContext);

    useEffect(() => {
        if (!transactionBank) {
            return;
        }

        const bank = banksData[transactionBank.id];

        if (!bank) {
            return;
        }

        setTransactionBank(bank);
    }, [banksData, transactionBank]);

    const value = useMemo(() => {
        return {
            parseResult,
            transactionBank,
        };
    }, [parseResult, transactionBank]);

    const actions = useMemo(() => {
        return {
            setParseResult: (arg: TParseTransaction[]) => {
                setParseResult(arg);
            },
        };
    }, []);

    return (
        <ParseContext.Provider value={value}>
            <ParseControlContext.Provider value={actions}>{children}</ParseControlContext.Provider>
        </ParseContext.Provider>
    );
};
