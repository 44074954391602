import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Tooltip, Typography } from "antd";
import React, { FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";

import { DocumentNumberInput } from "@app/components/shared/form/baseComponents/DocumentNumberInput/DocumentNumberInput";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";

const { Text } = Typography;

const InterneNumberFieldLabel: FC = () => {
    return (
        <Text strong ellipsis>
            {<FormattedMessage id="app.fields.internal_num" />}
            <Tooltip
                placement="top"
                title={
                    <span>
                        <FormattedMessage id="app.dms.tooltipDocumentNumber" />: <br />
                        <code>
                            <FormattedMessage id="app.dms.letters" />: aA - zZ
                        </code>
                        <br />{" "}
                        <code>
                            {" "}
                            <FormattedMessage id="app.dms.number" />: 0 - 9
                        </code>
                        <br />{" "}
                        <code>
                            <FormattedMessage id="app.dms.specialSymbols" />: - / $ & % +
                        </code>
                    </span>
                }
            >
                <InfoCircleOutlined style={{ marginLeft: "10px" }} />
            </Tooltip>
        </Text>
    );
};

const INPUT_MAX_LENGTH = 36;

const SingleInterneNumberField: FC = () => {
    const { isReadOnly } = useContext(SingleFormContext);
    return (
        <FieldLabel className={"DMSForm__Item"} name={"interneNumber"} label={<InterneNumberFieldLabel />}>
            <DocumentNumberInput maxLength={INPUT_MAX_LENGTH} showCount disabled={isReadOnly} />
        </FieldLabel>
    );
};

const GroupInterneNumberField: FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const { notEqualField, isCancel } = useContext(GroupFormContext);
    const placeholder = notEqualField?.includes("interneNumber") ? "Diverse" : undefined;

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    return (
        <>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"interneNumberCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox parentFieldName={"interneNumber"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel className={"DMSForm__Item"} name={"interneNumber"} label={<InterneNumberFieldLabel />}>
                <DocumentNumberInput
                    placeholder={placeholder}
                    maxLength={INPUT_MAX_LENGTH}
                    showCount
                    disabled={isDisabled}
                />
            </FieldLabel>
        </>
    );
};

export const DocumentInterneNumberField: FC = () => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return <>{isGroupEditor ? <GroupInterneNumberField /> : <SingleInterneNumberField />}</>;
};
