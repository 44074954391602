import React, { type ReactNode, useEffect } from "react";
import styles from "./ReloadPrompt.module.scss";
import { useRegisterSW } from "virtual:pwa-register/react";
import { Button, ConfigProvider, Flex, notification } from "antd";
import { FormattedMessage } from "react-intl";

import type { NotificationPlacement } from "antd/es/notification/interface";

export function ReloadPrompt() {
    const [api, contextHolder] = notification.useNotification({ maxCount: 1 });

    const reloadSW = import.meta.env.PROD === true;

    const {
        needRefresh: [needRefresh, setNeedRefresh],
        updateServiceWorker,
    } = useRegisterSW({
        onRegisteredSW(swUrl, reg) {
            console.log(`Service Worker at: ${swUrl}`);
            if (reloadSW) {
                if (!reg) {
                    return;
                }

                setInterval(async () => {
                    console.debug("Checking for sw update");
                    await reg.update();
                }, 60000);
            } else {
                console.log("SW Registered: " + reg);
            }
        },
        onRegisterError(error) {
            console.error("SW registration error", error);
        },
    });

    const close = () => {
        setNeedRefresh(false);
        api.destroy();
    };

    const openNotification = (placement: NotificationPlacement, message: ReactNode | string) => {
        api.info({
            message,
            description: (
                <Flex align={"middle"} justify={"end"} style={{ marginTop: 8 }}>
                    {needRefresh && (
                        <Button
                            type={"primary"}
                            style={{ marginRight: 5 }}
                            onClick={() => {
                                updateServiceWorker(true).finally(() => window.location.reload());
                                api.destroy();
                            }}
                        >
                            <FormattedMessage id="app.button.update" />
                        </Button>
                    )}
                    <Button type={"default"} onClick={() => close()}>
                        <FormattedMessage id="app.button.close" />
                    </Button>
                </Flex>
            ),
            placement,
            duration: null,
            closeIcon: null,
        });
    };

    useEffect(() => {
        if (!needRefresh) {
            return;
        }
        openNotification("top", <FormattedMessage id="app.global.binaleNeedUpdate" tagName="span" />);
    }, [needRefresh]);

    return (
        <div className={styles.reloadContainer}>
            {
                <ConfigProvider
                    theme={{
                        components: {
                            Notification: {
                                width: 600,
                            },
                        },
                    }}
                >
                    {contextHolder}
                </ConfigProvider>
            }
        </div>
    );
}
