import React, { FC, memo, useCallback, useContext, useMemo, useState } from "react";
import { GenericRecord } from "../../../../scripts/models";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { FormExternalRef } from "@app/components/recordform/types/ref";
import { PageFullScreen } from "@app/components/shared/appearance/page";
import { GlobalYearSelect } from "@app/components/shared/Toolbar/YearSelect";
import { GenericRecordForm } from "@app/components/recordform/GenericRecordForm";
import { TemplatesTable } from "./TemplatesTable";
import { FlexFillBlock } from "@app/components/shared/appearance/page/Scaffold";

export const TemplatesViewContent: FC = memo(function TemplatesViewContent() {
    const { moduleActions, moduleTemplates } = useContext(TableViewContext);
    const inlineFormRef = React.useRef<FormExternalRef>();
    const [activeRecord, setActiveRecord] = useState<GenericRecord>();

    const onClear = useCallback(() => setActiveRecord(null), []);
    const onSave = useCallback(
        (r: GenericRecord) => {
            moduleActions.saveTemplate(r);
            onClear();
        },
        [moduleActions, onClear]
    );

    const actions = useMemo(
        () => ({
            onDelete: (r: GenericRecord) => {
                moduleActions.deleteTemplates([r.key]);
                onClear();
            },
            onEdit: (r: GenericRecord) => setActiveRecord(r),
        }),
        [moduleActions, onClear]
    );

    return (
        <PageFullScreen>
            <div className="GenericToolbar">
                <GlobalYearSelect />
            </div>
            <FlexFillBlock>
                <TemplatesTable templates={moduleTemplates} actions={actions} />
            </FlexFillBlock>
            <GenericRecordForm record={activeRecord} ref={inlineFormRef} onSave={onSave} onClear={onClear} isTemplate />
        </PageFullScreen>
    );
});
