import React from "react";
import { Base, Bu, GQL, Utils } from "@binale-tech/shared";
import { BuTaxesSKR } from "../models/BuTaxUtils";
import { BuTimeframe } from "../context/BuContext";
import { Category } from "../models";
import { GenericRecord } from "../models/GenericRecord";
import { ITax, ItemRecordContext } from "../models/Interfaces";
import { MoneyUtils } from "../models/utils/MoneyUtils";
import { PaymentsContext } from "../context/PaymentsProvider";
import { ProductKey } from "../models/Product";
import { logger } from "../infrastructure/logger";
import { IstMNFUtils } from "../models/utils/IstMNFUtils";

// Rule: soll - haben

interface SHRecordExtended {
    vatSH: number;
    nettoSH: number;
}

export interface SHItem {
    konto: Base.IExtNum;
    bu: ITax["bu"];
    USt13b?: ITax["USt13b"];
    amount: number;
    isHaben: boolean;
    isTax?: boolean;
    taxType?: "ust" | "vst";
    isRecordKonto?: boolean;
    extended?: SHRecordExtended;
    taxes?: Set<SHItem>;
}

interface SHRecordElement extends SHItem {
    isRecordKonto: boolean;
    extended: SHRecordExtended;
}

interface SHItemElement extends SHItem {
    isTax: boolean;
    taxType: "ust" | "vst";
    taxes: Set<SHItem>;
}

export interface ConverterResponse {
    product: ProductKey;
    items: SHItem[];
}

export class SHConverter {
    constructor(
        protected yearConfig: GQL.ICompanyAccountingYear,
        protected buTimeframes: BuTimeframe[],
        protected payments: React.ContextType<typeof PaymentsContext>
    ) {}

    convert(record: GenericRecord, vatMode?: boolean): ConverterResponse {
        const { skr, kontoExt } = this.yearConfig;
        const buTimeframe = BuTaxesSKR.getBuTimeframeYearPeriod(skr, this.buTimeframes, record.year, record.period);
        // console.log("convert", {skr: this.skr, date: record.date, buTimeframe, tf: this.buTimeframes});
        const debug = record.key === "-NtCzdN9qgEZHZn5qE_k";
        const recordKonto = record.getRecordCategoryCreditor();
        const productKey = record.getProductKey();
        const recordIsHaben = Utils.SollHaben.isRecordKontoHaben(record.getBrutto(), productKey);
        const brutto = record.getBrutto();
        const bruttoAbs = Math.abs(brutto);
        const netto = Math.abs(record.getNetto(skr));
        const vat = bruttoAbs - netto;
        const recordItem: SHRecordElement = {
            bu: undefined,
            isHaben: recordIsHaben,
            amount: bruttoAbs,
            isTax: false,
            isRecordKonto: true,
            konto: recordKonto,
            extended: {
                vatSH: recordIsHaben ? vat : -vat, // haben stands for minus. if record is haben, then netto and vat are soll.
                nettoSH: recordIsHaben ? netto : -netto,
            },
        };
        const res: ConverterResponse = {
            product: productKey,
            items: [recordItem],
        };
        const itemRecordCtx: ItemRecordContext = {
            recordKonto,
            product: record.getProductKey(),
            year: record.year,
            period: record.period,
        };
        record.items.forEach(v => {
            const buTax = BuTaxesSKR.getBuTaxYearPeriod(
                v.bu,
                skr,
                record.year,
                record.period,
                buTimeframe ? [buTimeframe] : []
            );
            const itemKonto = v.getCategoryCreditor();
            const { useMNFBalancing, useUstMNFKonto, skipInVatExport, switchUStBalancingSign } = IstMNFUtils.getLogic(
                record.getProductKey(),
                this.yearConfig,
                recordKonto,
                itemKonto,
                buTax.bu
            );
            // if (debug) {
            //     console.log({ useMNFKontoFromBu, isItemISTBuMNF });
            // }

            if (skipInVatExport && vatMode) {
                return;
            }

            const itemIsHaben = Utils.SollHaben.isItemKontoHaben(record.getBrutto(), v.brutto, productKey);
            const itemNetto = Math.abs(v.getNetto(itemRecordCtx, skr));
            const item: SHItemElement = {
                bu: v.bu,
                USt13b: v.USt13b,
                isHaben: itemIsHaben,
                // in case of bu-mnf logic this netto is actually brutto, see GenericRecordItem.calculateNetto()
                // and for the vat view we need to calculate normal netto from this brutto
                // isItemISTBuMNF && vatMode
                amount:
                    useMNFBalancing && vatMode ? MoneyUtils.getNettoFromBrutto(itemNetto, buTax.percent) : itemNetto,
                isTax: false,
                taxType: undefined,
                konto: v.getCategoryCreditor(),
                taxes: new Set(),
            };
            res.items.push(item);

            // if present only one tax, it's sign is like item (G.Konto) sign
            let isUstHaben = itemIsHaben;
            const isVstHaben = itemIsHaben;
            const taxOnTop = BuTaxesSKR.isTaxOnTop(buTax);
            if (taxOnTop) {
                // if both present (taxOnTop), then VSt is like item, USt is opposite
                isUstHaben = !itemIsHaben;
            }
            // if (useModernFELogic && isItemISTBuMNF) {
            //     isUstHaben = !isUstHaben;
            //     isVstHaben = !isVstHaben;
            // }
            // if (debug) {
            //     console.log({
            //         useMNFBalancing,
            //         useUstMNFKonto,
            //         skipInVatExport,
            //         switchUStBalancingSign,
            //         isUstHaben,
            //         isVstHaben,
            //         buTax,
            //         taxOnTop,
            //     });
            // }
            const amountTaxItem = Math.abs(v.getTax(buTax.percent, taxOnTop));
            if (taxOnTop && BuTaxesSKR.hasExtraTaxToCategory(buTax)) {
                item.amount = itemNetto + amountTaxItem;
            }
            // if (debug) {
            //     console.log({ num: record.num, amountTaxItem, record });
            // }
            const addTaxItem = (taxKonto: Base.IExtNum, isHaben: boolean, bu: Bu.Bu, taxType: SHItem["taxType"]) => {
                const taxItem: SHItemElement = {
                    isHaben,
                    amount: amountTaxItem,
                    isTax: true,
                    konto: taxKonto,
                    taxes: null,
                    bu,
                    taxType,
                    USt13b: v.USt13b,
                };
                res.items.push(taxItem);
                item.taxes.add(taxItem);
            };
            const logError = (...params: any) => {
                logger.error("integrity error, no category for bu tax num", ...params);
                logger.log(
                    "record date",
                    record.date,
                    "butf_id",
                    buTimeframe.id,
                    "size",
                    buTimeframe.defaultCategories.size
                );
            };

            // bu has M-Konto (ust). ust MNF is always under the ust
            if (buTax.ust) {
                const ustMNFKonto = Category.copyWithFixedNum(
                    buTimeframe.defaultCategories.get(buTax.ustMNF),
                    kontoExt
                );
                if (buTax.ustMNF && !ustMNFKonto) {
                    logError("buTax.ustMNF", buTax.ust);
                }
                const ustKonto = Category.copyWithFixedNum(buTimeframe.defaultCategories.get(buTax.ust), kontoExt);
                if (buTax.ust && !ustKonto) {
                    logError("ust", buTax.ust);
                }

                const ustSign = switchUStBalancingSign ? !isUstHaben : isUstHaben;

                const ustTaxKonto = useUstMNFKonto ? ustMNFKonto : ustKonto;
                addTaxItem(ustTaxKonto, ustSign, buTax.bu, "ust");

                // IST-UstMNF logic for balancing tax for the Payment Representation Records (Bank, KB, FE)
                // https://binale.atlassian.net/wiki/spaces/SPECS/pages/195624961/IST+Versteuerung
                if (buTax.ustMNF && useMNFBalancing) {
                    addTaxItem(ustKonto, !ustSign, buTax.bu, "ust");
                }
            }

            if (buTax.vst) {
                const vstKonto = Category.copyWithFixedNum(buTimeframe.defaultCategories.get(buTax.vst), kontoExt);
                if (buTax.vst && !vstKonto) {
                    logError("vst", buTax.vst);
                }
                addTaxItem(vstKonto, isVstHaben, buTax.bu, "vst");
            }
        });
        return res;
    }

    getAllItems(record: GenericRecord): SHItem[] {
        const conv = this.convert(record);
        return [...conv.items];
    }

    getItemsVatMode(record: GenericRecord): SHItem[] {
        const conv = this.convert(record, true);
        return [...conv.items];
    }
}
