import React, { FC, useContext } from "react";
import { Buchungstext2InputBlock } from "../../shared/form/FormBlocks";
import { useProgramSettings } from "../hooks/useProgramSettings";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useIsItemFieldDisabledOutsideModal } from "../hooks/useIsItemFieldDisabledOutsideModal";
import { useFormHandlerItemText2 } from "../hooks/handlers/useFormHandlerItemText2";

type Props = {
    isModalMode?: boolean;
};
export const FormItemText2: FC<Props> = ({ isModalMode }) => {
    const isModalOnly = useIsItemFieldDisabledOutsideModal(isModalMode);
    const { useBuchText2 } = useProgramSettings();
    const isDisabled = useFormIsRefDisabled();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { editableRecordItem, itemValidationStates } = useContext(RecordFormStateContext);
    const onText2Change = useFormHandlerItemText2();
    if (!useBuchText2) {
        return null;
    }

    const validation = itemValidationStates.get(refsHtml.REF_iTEXT);
    return (
        <Buchungstext2InputBlock
            selectTextOnFocus
            ref={refsHtml.REF_iTEXT2}
            labelProps={{
                style: { flexGrow: 1 },
                fieldError: validation ? { type: "error", message: " " } : undefined,
            }}
            style={{ flexGrow: 1 }}
            value={isModalOnly ? undefined : editableRecordItem.itemText2}
            onChange={onText2Change}
            disabled={isModalOnly || isDisabled(refsHtml.REF_iTEXT2)}
        />
    );
};
