import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { PdfToolsContext, PdfToolsControlContext } from "../../context";
import { PdfViewModule } from "../../../appearance/components/shared/PdfViewer/PdfViewModule";

interface IProps {
    sizeRatio: number[];
}

export const PdfPreviewBlock: FC<IProps> = ({ sizeRatio }) => {
    const { files } = useContext(PdfToolsContext);
    const actions = useContext(PdfToolsControlContext);
    const [activeDocumentsId, setActiveDocumentsId] = useState<string>("");

    const viewFile = useMemo(() => {
        return files.find(el => el.id === activeDocumentsId);
    }, [files, activeDocumentsId]);

    useEffect(() => {
        setActiveDocumentsId(files[0]?.id);
    }, [files]);

    return (
        <PdfViewModule
            documentData={viewFile}
            reorderingFiles={actions.reorderingFiles}
            carouselDocuments={files}
            activeDocumentsId={activeDocumentsId}
            setActiveDocumentsId={setActiveDocumentsId}
            sizeRatio={sizeRatio}
            mode="PDF_Module"
        />
    );
};
