import { Button, message } from "antd";
import React, { FC, useContext, useState } from "react";
import { GQL } from "@binale-tech/shared";
import { useIntl } from "react-intl";
import { ExportOutlined } from "@ant-design/icons";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { ProductAccessUtils } from "../../../../scripts/models/utils/ProductAccessUtils";
import { DmsAppContext, DmsDataContext } from "@dms/types/ContextTypes";
import { GenericRecord } from "../../../../scripts/models";
import { IDocumentEnriched } from "@dms/types";
import { IMessagesType } from "../../../../scripts/intl/translations/de";
import { BinaleError } from "../../../../scripts/errors/errors";
import { useDmsAccountingConverter } from "../../../../scripts/models/converters/useDmsAccountingConverter";

type TProps = {
    documents: IDocumentEnriched[];
};

export const FibuButton: FC<TProps> = ({ documents }) => {
    const intl = useIntl();
    const { activeType } = useContext(DmsAppContext);
    const { documentTypes } = useContext(DmsDataContext);
    const [importing, setImporting] = useState(false);

    const { type, subType } = DmsUtils.getActiveTypeAndSubType(activeType);
    const groupId = DmsUtils.getGroupId(type, subType, documentTypes);
    const { converter, actions } = useDmsAccountingConverter(type as unknown as GQL.IProductKey, groupId);

    const isAccountingType = ProductAccessUtils.getAccountingKeys().has(type as unknown as GQL.IProductKey);
    if (!isAccountingType) {
        return null;
    }

    const isDisabled = DmsUtils.isAccountingExportDisabled(documents, type, groupId);

    const toGenericRecord = async () => {
        if (!documents.length || !actions) {
            return;
        }

        setImporting(true);

        const records: GenericRecord[] = [];
        const errorKeys = new Set<keyof IMessagesType>();
        for (const document of documents) {
            try {
                const record = converter.convertDocumentToRecord(document);
                if (!record) {
                    throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data");
                }
                records.push(record);
            } catch (e) {
                if (e instanceof BinaleError) {
                    errorKeys.add(e.translationKey);
                }
            }
        }

        for (const errorKey of errorKeys) {
            message.warning(intl.formatMessage({ id: errorKey }));
        }

        if (records.length) {
            try {
                await actions.importMany(records, true);
                message.success(intl.formatMessage({ id: "app.button.done" }));
            } catch (e) {
                message.error(e.message);
            }
        }

        setImporting(false);
    };

    return (
        <Button
            onClick={toGenericRecord}
            disabled={isDisabled}
            loading={importing}
            icon={<ExportOutlined />}
            type={"dashed"}
        >
            FIBU
        </Button>
    );
};
