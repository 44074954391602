export const getDateFormat = (parseDate: string) => {
    const arr = parseDate.split(".");

    if (arr.length !== 3) {
        throw new Error("Invalid date format. Expected format: DD.MM.YYYY or DD.MM.YY");
    }

    const year = arr[arr.length - 1];

    if (year.length === 4) {
        return "DD.MM.YYYY";
    } else if (year.length === 2) {
        return "DD.MM.YY";
    } else {
        throw new Error("Invalid year format. Expected format: YYYY or YY");
    }
};
