import { Col, Flex, Row, Typography } from "antd";
import React, { useContext } from "react";
import { BanksContext } from "../../../scripts/context/BanksContext";
import dayjs from "dayjs";
import { Utils } from "@binale-tech/shared";
import { FormattedMessage } from "react-intl";
import { getBalance } from "@banks/scripts/helpers";

const { Title, Text } = Typography;

const bankDate = dayjs().format("DD.MM.YYYY");

export const AllBanksStatistic = () => {
    const { banksData, transactionsData } = useContext(BanksContext);

    const balanceValue = getBalance(banksData, transactionsData);

    return (
        <Flex vertical style={{ height: "100%", padding: 24, backgroundColor: "#f9f9f9", borderRadius: 8 }}>
            <Flex justify={"center"}>
                <Title level={3} ellipsis>
                    <FormattedMessage id={"app.banks.allBanks"} />
                </Title>
            </Flex>

            <Row>
                <Col span={24}>
                    <Flex vertical gap={5}>
                        <Title level={5} ellipsis>
                            <FormattedMessage id={"app.fields.saldo"} />
                            <Text style={{ marginLeft: 10 }} ellipsis>
                                {bankDate}:
                            </Text>
                        </Title>
                        {Object.entries(balanceValue).map(([key, value]) => (
                            <Flex align={"center"} justify={"end"} key={key} gap={10}>
                                <Text strong style={{ color: `${value < 0 ? "#f5222d" : "#52c41a"}` }}>
                                    {Utils.CurrencyUtils.currencyFormat(Math.round(value))}
                                </Text>{" "}
                                <Text>{key}</Text>
                            </Flex>
                        ))}
                    </Flex>
                </Col>
            </Row>
        </Flex>
    );
};
