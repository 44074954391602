import React, { type FC, useCallback, useState } from "react";
import { IHeaderParams } from "ag-grid-community";
import { InvoicesListColumn, InvoicesListColumnTranslate } from "@inv/types";
import { Button, Flex, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const ActionControlColumns: FC<IHeaderParams> = ({ api }) => {
    const [openSearch, setOpenSearch] = useState(false);

    const toggleFloatingFilter = useCallback(() => {
        const allColumns = api.getColumnDefs();

        if (openSearch) {
            api.setFilterModel({});
        }

        const newColModel = allColumns.map(column => {
            return {
                ...column,
                floatingFilter: !openSearch,
            };
        });
        api.updateGridOptions({ columnDefs: newColModel });
        setOpenSearch(prev => !prev);
    }, [api, openSearch]);

    return (
        <Flex align="center" justify="space-between" style={{ width: "100%" }}>
            <Text>
                <FormattedMessage id={InvoicesListColumnTranslate[InvoicesListColumn.ACTION]} />
            </Text>

            <Button icon={<SearchOutlined />} onClick={toggleFloatingFilter} type={"text"}></Button>
        </Flex>
    );
};
