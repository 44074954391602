import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import {
    invoiceSave,
    invoiceDelete,
    invoiceApplyCurrencyRate,
} from "../../../scripts/context/mutations/invoicesMutation.graphql";
import { TInvoicesValues } from "@inv/types";
import { GQL } from "@binale-tech/shared";

export class InvoicesApi {
    private static _companyId: string;
    private static _mutator: ReturnType<typeof useGqlMutator>;

    static set companyId(id: string) {
        this._companyId = id;
    }

    static set mutator(mutator: ReturnType<typeof useGqlMutator>) {
        this._mutator = mutator;
    }

    static invoiceSave = async (input: TInvoicesValues) => {
        if (Object.keys(input.customerVat).length === 0) {
            delete input.customerVat;
        }
        if (Object.keys(input.customerTax).length === 0) {
            delete input.customerTax;
        }
        const res = await this._mutator.mutate({
            mutation: invoiceSave,
            input: { ...input, companyId: this._companyId },
            hideMessages: true,
        });

        return res.invoiceSave?.id;
    };

    static invoiceApplyCurrencyRate = async (input: Omit<GQL.IInvoiceApplyCurrencyRateInput, "companyId">) => {
        await this._mutator.mutate({
            mutation: invoiceApplyCurrencyRate,
            input: { ...input, companyId: this._companyId },
            hideMessages: true,
        });
    };

    static invoiceDelete = async (id: string) => {
        await this._mutator.mutate({
            mutation: invoiceDelete,
            input: { id, companyId: this._companyId },
            hideMessages: true,
        });
    };
}
