import React from "react";
import { Button, Card, Col, Collapse, Drawer, Empty, Form, Popconfirm, Row, Select, Spin } from "antd";
import { DeleteOutlined, EditOutlined, UserAddOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";

import CompanyQueriesGraphql from "./companyQueries.graphql";
import CompanyUserDrawer from "./CompanyUserDrawer";
import { FlexRow } from "../shared/appearance/page/Scaffold";
import { ProductAccessLevels } from "scripts/models/utils/ProductAccessUtils";
import { ProductKeys } from "scripts/models/Product";
import { UserContext } from "scripts/context/UserProvider";
import { decodeKey } from "scripts/api/firebase/firebase";
import { useGQLRetriever } from "scripts/graphql/gqlRetriever";
import { useGqlMutator } from "scripts/graphql/useGqlMutator";

interface ICompanyUserCard {
    companyId: string;
    member: GQL.ICompanyMember;
    ownerEmail?: string;
    membersLength?: number;
    onDelete: () => void;
    onEdit: () => void;
    onRefetch: () => void;
}

const CompanyUserCard: React.FC<ICompanyUserCard> = props => {
    const { member, ownerEmail, membersLength, onEdit, onDelete, companyId, onRefetch } = props;
    const { isAdminWrite } = React.useContext(UserContext);
    const Mutator = useGqlMutator();
    const [collapseKey, setCollapseKey] = React.useState("1");
    const [drawerRoles, setDrawerRoles] = React.useState<GQL.ICompanyMember["roles"] | null>(null);
    const { emailKey, products } = member;
    const email = decodeKey(emailKey);

    const canModify = membersLength > 1 && ownerEmail !== email && isAdminWrite;

    const getAccessLevel = (value: GQL.IProductAccessLevel) => {
        switch (value) {
            case ProductAccessLevels.Read:
                return <FormattedMessage id="app.companies.permission.read" />;
            case ProductAccessLevels.Write:
                return <FormattedMessage id="app.companies.permission.write" />;
            default:
                return <FormattedMessage id="app.companies.permission.no" />;
        }
    };
    const handleSave = React.useCallback(() => {
        Mutator.mutate<"companySaveMemberRoles", GQL.ICompanySaveMemberRolesInput>({
            mutation: CompanyQueriesGraphql.mutationSaveMemberRoles,
            input: {
                companyId,
                email,
                roles: drawerRoles,
            },
        }).then(() => {
            setDrawerRoles(null);
            onRefetch();
        });
    }, [Mutator, companyId, drawerRoles, email, onRefetch]);

    return (
        <Col xs={8} key={emailKey}>
            <Card
                title={
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <div>{email}</div>
                        <Popconfirm
                            onConfirm={onDelete}
                            title={<FormattedMessage id="app.confirmation.header" />}
                            okText={<FormattedMessage id="app.button.confirm" />}
                            cancelText={<FormattedMessage id="app.button.cancel" />}
                        >
                            <Button disabled={!canModify} danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </FlexRow>
                }
            >
                <Collapse activeKey={collapseKey} onChange={v => setCollapseKey(String(v[1]))}>
                    <Collapse.Panel
                        key="1"
                        className="CompanyUserOverview__CollapseHeader"
                        header={
                            <div className="CompanyUserOverview__CollapseHeader__Title">
                                <div>Members</div>
                                <Button
                                    disabled={!canModify}
                                    icon={<EditOutlined />}
                                    onClick={e => {
                                        onEdit();
                                        e.stopPropagation();
                                    }}
                                />
                            </div>
                        }
                    >
                        {ProductKeys.all().map(productKey => (
                            <div key={productKey}>
                                <FormattedMessage id={"app.titles." + productKey} />
                                <span>: </span>
                                <b>{getAccessLevel(products.find(p => p.productKey === productKey)?.access)}</b>
                            </div>
                        ))}
                    </Collapse.Panel>
                    <Collapse.Panel
                        className="CompanyUserOverview__CollapseHeader"
                        header={
                            <div className="CompanyUserOverview__CollapseHeader__Title">
                                <div>Roles</div>
                                <Button
                                    disabled={!canModify}
                                    icon={<EditOutlined />}
                                    onClick={e => {
                                        setDrawerRoles(member.roles);
                                        e.stopPropagation();
                                    }}
                                />
                            </div>
                        }
                        key="2"
                    >
                        {member.roles.map(role => (
                            <div key={role}>{role}</div>
                        ))}
                        {!member.roles.length && <Empty />}
                    </Collapse.Panel>
                </Collapse>
            </Card>
            <Drawer
                key="drawer"
                width={300}
                title="Edit Roles"
                placement="right"
                open={Boolean(drawerRoles)}
                onClose={() => setDrawerRoles(null)}
            >
                <Form.Item>
                    <Select<GQL.IUserRole[]>
                        mode="multiple"
                        value={drawerRoles}
                        onChange={setDrawerRoles}
                        options={[
                            {
                                label: <FormattedMessage id="app.titles.accounting" />,
                                options: [
                                    { label: "Reviewer", value: GQL.IUserRole.RecordReviewer },
                                    { label: "Datev Exporter", value: GQL.IUserRole.DatevExporter },
                                ],
                            },
                            {
                                label: "DMS",
                                options: [
                                    { label: "DMS Admin", value: GQL.IUserRole.DmsAdmin },
                                    { label: "DMS New Documents", value: GQL.IUserRole.DmsNewDocuments },
                                ],
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" icon={<UsergroupAddOutlined />} onClick={handleSave}>
                        <FormattedMessage id="app.button.done" />
                    </Button>
                </Form.Item>
            </Drawer>
        </Col>
    );
};

interface IProps {
    companyId?: string;
    onHide?: () => void;
}

export const CompanyUsersOverviewModal: React.FC<IProps> = ({ companyId, onHide }) => {
    const user = React.useContext(UserContext);
    const Mutator = useGqlMutator();
    const retriever = useGQLRetriever<"company">();
    const [loading, setLoading] = React.useState(false);
    const [company, setCompany] = React.useState<GQL.ICompany | null>();

    const [showDrawer, setShowDrawer] = React.useState(false);
    const [drawerMember, setDrawerMember] = React.useState<GQL.ICompanyMember | null>(null);

    const refetch = React.useCallback(() => {
        setLoading(true);
        retriever
            .query({ id: companyId, query: CompanyQueriesGraphql.queryCompanyMembers })
            .then(data => setCompany(data?.company))
            .finally(() => setLoading(false));
    }, [retriever, companyId]);

    React.useEffect(() => {
        if (companyId) {
            refetch();
        } else {
            setCompany(null);
        }
    }, [refetch, companyId]);

    const members = company?.members || [];

    const handleHide = () => {
        setShowDrawer(false);
        onHide();
    };

    const onDeleteUser = async (email: string) => {
        setLoading(true);
        await Mutator.mutate<"companyDeleteMember", GQL.ICompanyDeleteMemberInput>({
            mutation: CompanyQueriesGraphql.mutationDeleteMember,
            input: {
                companyId,
                email,
            },
        }).finally(() => {
            refetch();
        });
    };

    const onAddUser = () => {
        setDrawerMember(null);
        setShowDrawer(true);
    };

    const onEditUser = (member: GQL.ICompanyMember | null) => {
        setDrawerMember(member);
        setShowDrawer(true);
    };

    const onUserDrawerClose = React.useCallback(() => {
        setShowDrawer(false);
        refetch();
    }, [refetch]);

    return (
        <Drawer
            key="drawer"
            width={1200}
            title={
                <span>
                    <FormattedMessage id="app.titles.company" /> {company?.name}
                </span>
            }
            placement="right"
            open={Boolean(company) || loading}
            onClose={handleHide}
        >
            <Spin spinning={user.isLoading || loading}>
                {user && (
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Button
                                size="large"
                                shape="circle"
                                icon={<UserAddOutlined />}
                                onClick={onAddUser}
                                type="primary"
                                disabled={!user.isAdminWrite}
                            />
                        </Col>
                        {members.map(member => (
                            <CompanyUserCard
                                companyId={companyId}
                                member={member}
                                key={member.id}
                                ownerEmail={company?.owner?.email}
                                membersLength={company?.members?.length}
                                onDelete={() => onDeleteUser(decodeKey(member.emailKey))}
                                onEdit={() => onEditUser(member)}
                                onRefetch={refetch}
                            />
                        ))}
                        {showDrawer && (
                            <CompanyUserDrawer onClose={onUserDrawerClose} company={company} member={drawerMember} />
                        )}
                    </Row>
                )}
            </Spin>
        </Drawer>
    );
};
