import { ApolloClient, ApolloLink, from, HttpLink } from "@apollo/client";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { InMemoryCache } from "@apollo/client/cache";
import { RetryLink } from "@apollo/client/link/retry";
import { getLocalStorageLang } from "../context/AppSettingsProvider";
import { getServerlessApi, getServerlessHost } from "../api/backendApi";
import { logger } from "../infrastructure/logger";
import { setContext } from "@apollo/client/link/context";
import { GraphQLFormattedError } from "graphql";
import { message } from "antd";

const errorLink = onError((errors: ErrorResponse) => {
    const langErrors: GraphQLFormattedError[] = [];
    errors?.graphQLErrors?.forEach(e => {
        if (e.extensions.code === "BINALE_ERROR") {
            langErrors.push(e);
            return;
        }
        if (e.extensions.code === "BINALE_DATEV_TOKEN_CHECK") {
            return;
        }
        logger.error(e.message);
    });
    langErrors.forEach(e => {
        message.warning(e.message);
    });
    // notification.error({
    //     message: "GraphQL error",
    //     description: (
    //         <>
    //             {errors.graphQLErrors?.length && (
    //                 <>
    //                     <p>
    //                         <b>Backend</b>
    //                     </p>
    //                     {errors.graphQLErrors.map((error, i) => {
    //                         return <p key={i}>{error.message}</p>;
    //                     })}
    //                 </>
    //             )}
    //             {errors.networkError && (
    //                 <>
    //                     <p>
    //                         <b>Network</b>
    //                     </p>
    //                     <p>{errors.networkError.message}</p>
    //                 </>
    //             )}
    //         </>
    //     ),
    // });
});
const forwardLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(data => {
        if (data?.errors?.length > 0) {
            const hasClientOffline = data.errors.some(v => v.message.includes("Client is Offline"));
            if (hasClientOffline) {
                throw new Error("Caught GraphQL Operational Error");
            }
        }
        return data;
    });
});
const dynamicHeaders = setContext(async (_, { headers }) => {
    const { headers: appHeaders } = await getServerlessApi();
    return {
        headers: {
            ...headers,
            ...appHeaders,
            "x-binale-client-lang": getLocalStorageLang(),
        },
    };
});

export const getApolloClient = () => {
    const uri = getServerlessHost() + "/graphql";

    // const uri = "http://localhost:5001/steuerflow-testing/us-central1/serverless/graphql";
    const link = from([
        new RetryLink(),
        dynamicHeaders,
        errorLink,
        forwardLink,
        new HttpLink({ uri, headers: { "x-binale-client-version": import.meta.env.VITE_VERSION } }),
    ]);
    return new ApolloClient({
        link,
        cache: new InMemoryCache({
            dataIdFromObject: o => {
                if (o.__typename.includes("Payment")) {
                    return `${o.__typename}_${JSON.stringify(o)}`;
                }
                return `${o.__typename}_${o.id || o.key}_${o.num || o.accountNum || o.name}_${o.year}`;
            },
        }).restore({}),
    });
};
