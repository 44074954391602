import { GQL } from "@binale-tech/shared";

export enum DmsType {
    new_documents = "new_documents",
    uncategorized = "uncategorized",
    Deb = GQL.IProductKey.Deb,
    ER = GQL.IProductKey.Er,
    Bank = GQL.IProductKey.Bank,
    KB = GQL.IProductKey.Kb,
    FE = GQL.IProductKey.Fe,
    POS = GQL.IProductKey.Pos,
    LA = GQL.IProductKey.La,
    taxes = "taxes",
    contracts = "contracts",
    company = "company",
    trash = "trash",
}

export enum DmsTaxeSubType {
    vat = "vat",
    corporation_tax = "corporation_tax",
    business_tax = "business_tax",
    statement = "statement",
    income_tax = "income_tax",
    other_taxes = "other_taxes",
    tax_office = "FE",
}

export enum DmsDefaultSubType {
    all_subTypes = "all_subTypes",
    no_subTypes = "no_subTypes",
}
export enum SubTypeActions {
    createSubType = "createSubType",
    updateSubType = "updateSubType",
    deleteSubType = "deleteSubType",
}

export enum tableModeEnum {
    app = "app",
    modal = "modal",
}

export enum MovementType {
    RECEIPT = "receipt",
    SPENDING = "spending",
    ZERO = "zero",
}

export enum KeysEnum {
    ARROW_UP = "ArrowUp",
    ARROW_LEFT = "ArrowLeft",
    ARROW_RIGHT = "ArrowRight",
    ARROW_DOWN = "ArrowDown",
}

export enum UploadFileStatus {
    NOT_UNIQ = "not uniq",
    READY = "ready",
    PROGRESS = "progress",
    DONE = "done",
    ERROR = "error",
    ALREADY_UPLOADED = "alreadyUploaded",
    DECLINE = "decline",
}
