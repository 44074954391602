import React from "react";
import { BWATableRecord } from "./useBWAColumns";

const emptyMonthValues = new Map(new Array(12).fill(0).map((v, idx) => [idx, 0]));

export const useBWATableDataSource = (bwaData: Map<string, BWATableRecord>, modeAll: boolean) => {
    return React.useMemo(() => {
        const catList: string[] = Array.from(bwaData.keys()).sort((a, b) => Number(a) - Number(b));

        if (modeAll) {
            return catList.map(catNum => bwaData.get(catNum));
        }
        const list: BWATableRecord[] = [];

        const processSG = (proto: BWATableRecord, catsRanges: [number, number][], invertSign?: boolean) => {
            list.push(proto);
            if (!catsRanges.length) {
                return;
            }
            const processRange = (catNumStr: string, catsRange: [number, number]) => {
                const [min, max] = catsRange;
                const catNum = Number(catNumStr.substring(0, 4));
                if (min <= catNum && catNum <= max) {
                    const item = { ...bwaData.get(catNumStr) };
                    if (invertSign) {
                        item.monthAmounts.forEach((value, key) => {
                            item.monthAmounts.set(key, -item.monthAmounts.get(key));
                        });
                    }
                    list.push(item);
                    item.monthAmounts.forEach((value, key) => {
                        proto.monthAmounts.set(key, proto.monthAmounts.get(key) + value);
                    });
                }
            };
            catList.forEach(catNumStr => {
                catsRanges.forEach(range => {
                    processRange(catNumStr, range);
                });
            });
        };

        const sg1020: BWATableRecord = {
            key: 1020,
            subGroup: <span>Umsatzerlöse</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(
            sg1020,
            [
                [4000, 4499],
                [4510, 4589],
                [4690, 4799],
                [4833, 4833],
                [4860, 4864],
                [4992, 4992],
            ],
            true
        );
        const sg1040: BWATableRecord = {
            key: 1040,
            subGroup: <span>Bestandsveränderung FE/UE</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1040, [], true);

        const sg1045: BWATableRecord = {
            key: 1045,
            subGroup: <span>Aktivierte Eigenleistungen</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1045, [], true);

        const groupA: BWATableRecord = {
            key: 1,
            group: <span>A. Gesamtleistung</span>,
            monthAmounts: new Map(sg1020.monthAmounts),
        };
        list.push(groupA);

        const sg1060: BWATableRecord = {
            key: 1060,
            subGroup: <span>Material-/Wareneinkauf</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(
            sg1060,
            [
                [5000, 5885],
                [5900, 5985],
            ],
            true
        );

        const groupB1080: BWATableRecord = {
            key: 1080,
            group: <span>B. Rohertrag</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        groupB1080.monthAmounts.forEach((value, key) => {
            groupB1080.monthAmounts.set(key, groupA.monthAmounts.get(key) + sg1060.monthAmounts.get(key));
        });
        list.push(groupB1080);

        const sg1090: BWATableRecord = {
            key: 301,
            subGroup: <span>Sonstige betriebliche Erlöse</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(
            sg1090,
            [
                [4600, 4689],
                [4830, 4832],
                [4834, 4858],
                [4865, 4971],
                [4975, 4989],
            ],
            true
        );

        const groupC1092: BWATableRecord = {
            key: 1092,
            group: <span>С. Betrieblicher Rohertrag</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        groupC1092.monthAmounts.forEach((value, key) => {
            groupC1092.monthAmounts.set(key, groupB1080.monthAmounts.get(key) + sg1090.monthAmounts.get(key));
        });
        list.push(groupC1092);

        const sg1100: BWATableRecord = {
            key: 1100,
            subGroup: <span>Personalkosten</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(
            sg1100,
            [
                [6000, 6090],
                [6100, 6171],
            ],
            true
        );

        const sg1120: BWATableRecord = {
            key: 1120,
            subGroup: <span>Raumkosten</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1120, [[6305, 6352]], true);

        const sg1140: BWATableRecord = {
            key: 1140,
            subGroup: <span>Betriebliche Steuern</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1140, [[7650, 7694]], true);

        const sg1150: BWATableRecord = {
            key: 1150,
            subGroup: <span>Versicherungen, Beiträge und Abgaben</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1150, [[6400, 6440]], true);

        const sg1160: BWATableRecord = {
            key: 1160,
            subGroup: <span>Besondere Kosten</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1160, [], true);

        const sg1180: BWATableRecord = {
            key: 1180,
            subGroup: <span>Fahrzeugkosten (ohne Steuer)</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1180, [[6500, 6595]], true);

        const sg1200: BWATableRecord = {
            key: 1200,
            subGroup: <span>Werbe- und Reisekosten</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1200, [[6600, 6691]], true);

        const sg1220: BWATableRecord = {
            key: 1220,
            subGroup: <span>Kosten Warenabgabe</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1220, [[6700, 6790]], true);

        const sg1240: BWATableRecord = {
            key: 1240,
            subGroup: <span>Abschreibungen</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1240, [[6200, 6291]], true);

        const sg1250: BWATableRecord = {
            key: 1250,
            subGroup: <span>Reparaturen und Instandhaltungen</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1250, [[6450, 6495]], true);

        const sg1260: BWATableRecord = {
            key: 1260,
            subGroup: <span>Sonstige Kosten</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(
            sg1260,
            [
                [6300, 6304],
                [6390, 6398],
                [6498, 6498],
                [6800, 6850],
                [6855, 6999],
            ],
            true
        );

        const groupD1280: BWATableRecord = {
            key: 1280,
            group: <span>D. Gesamtkosten</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        groupD1280.monthAmounts.forEach((value, key) => {
            groupD1280.monthAmounts.set(
                key,
                value +
                    sg1100.monthAmounts.get(key) +
                    sg1120.monthAmounts.get(key) +
                    sg1140.monthAmounts.get(key) +
                    sg1150.monthAmounts.get(key) +
                    sg1160.monthAmounts.get(key) +
                    sg1180.monthAmounts.get(key) +
                    sg1200.monthAmounts.get(key) +
                    sg1220.monthAmounts.get(key) +
                    sg1220.monthAmounts.get(key) +
                    sg1240.monthAmounts.get(key) +
                    sg1250.monthAmounts.get(key) +
                    sg1260.monthAmounts.get(key)
            );
        });
        list.push(groupD1280);

        const groupE1300: BWATableRecord = {
            key: 1300,
            group: <span>E. Betriebsergebnis</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        groupE1300.monthAmounts.forEach((value, key) => {
            groupE1300.monthAmounts.set(key, groupC1092.monthAmounts.get(key) + groupD1280.monthAmounts.get(key));
        });
        list.push(groupE1300);

        const sg1310: BWATableRecord = {
            key: 1310,
            subGroup: <span>Zinsaufwendungen</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(
            sg1310,
            [
                [7300, 7363],
                [7364, 7365],
                [7366, 7366],
            ],
            true
        );

        const sg1312: BWATableRecord = {
            key: 1312,
            subGroup: <span>Sonstiger neutraler Aufwand</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1312, []);

        const groupF1320: BWATableRecord = {
            key: 1320,
            group: <span>F. Neutraler Aufwand</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        groupF1320.monthAmounts.forEach((value, key) => {
            groupF1320.monthAmounts.set(key, sg1310.monthAmounts.get(key) + sg1312.monthAmounts.get(key));
        });
        list.push(groupF1320);

        const sg1322: BWATableRecord = {
            key: 1322,
            subGroup: <span>Zinserträge</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(
            sg1322,
            [
                [7100, 7143],
                [7144, 7145],
            ],
            true
        );

        const sg1323: BWATableRecord = {
            key: 1323,
            subGroup: <span>Sonstiger neutraler Ertrag</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1323, [[4972, 4974]], true);

        const sg1324: BWATableRecord = {
            key: 1324,
            subGroup: <span>Verrechnete kalk. Kosten</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1324, [], true);

        const groupG1330: BWATableRecord = {
            key: 1330,
            group: <span>G. Neutraler Ertrag</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        groupG1330.monthAmounts.forEach((value, key) => {
            groupG1330.monthAmounts.set(
                key,
                sg1322.monthAmounts.get(key) + sg1323.monthAmounts.get(key) + sg1324.monthAmounts.get(key)
            );
        });
        list.push(groupG1330);

        const groupH1345: BWATableRecord = {
            key: 1345,
            group: <span>H. Ergebnis vor Steuern</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        groupH1345.monthAmounts.forEach((value, key) => {
            groupH1345.monthAmounts.set(
                key,
                groupE1300.monthAmounts.get(key) + groupF1320.monthAmounts.get(key) + groupG1330.monthAmounts.get(key)
            );
        });
        list.push(groupH1345);

        const sg1355: BWATableRecord = {
            key: 1355,
            subGroup: <span>Steuern vom Einkommen und vom Ertrag</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        processSG(sg1355, [[7600, 7649]], true);

        const groupI1380: BWATableRecord = {
            key: 1380,
            group: <span>I. Vorläufiges Ergebnis</span>,
            monthAmounts: new Map(emptyMonthValues),
        };
        groupI1380.monthAmounts.forEach((value, key) => {
            groupI1380.monthAmounts.set(key, groupH1345.monthAmounts.get(key) + sg1355.monthAmounts.get(key));
        });
        list.push(groupI1380);
        return list;
    }, [bwaData, modeAll]);
};
