import { ITableColumns } from "@dms/types";

export enum ConfigColumnGroups {
    DISCOUNT_VALUES = "discountValues",
    CURRENCY_VALUES = "currencyValues",
    DEADLINE_VALUES = "deadlineValues",
    INFO_VALUES = "infoValues",
    OTHER_VALUES = "otherValues",
}

export interface IColumnState {
    [ConfigColumnGroups.DISCOUNT_VALUES]: TColumnValue;
    [ConfigColumnGroups.CURRENCY_VALUES]: TColumnValue;
    [ConfigColumnGroups.DEADLINE_VALUES]: TColumnValue;
    [ConfigColumnGroups.INFO_VALUES]: TColumnValue;
    [ConfigColumnGroups.OTHER_VALUES]: TColumnValue;
}

export type TSwitchedGroup = Record<keyof Omit<IColumnState, "otherValues">, boolean>;

export type TColumnValue = { value: keyof ITableColumns; checked: boolean; translate: string }[];
