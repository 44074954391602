import React, { FC } from "react";
import InvoicesApp from "@inv/InvoicesApp";
import { InvoicesDataProvider } from "@inv/context/InvoicesDataProvider";
import { InvoiceModalProvider } from "@inv/context/InvoiceModalProvider";

export const Invoices: FC = () => {
    return (
        <InvoicesDataProvider>
            <InvoiceModalProvider>
                <InvoicesApp />
            </InvoiceModalProvider>
        </InvoicesDataProvider>
    );
};
