export const styleConst = {
    colSpan: {
        span: 24,
        xs: 24,
        sm: 24,
        md: 24,
        lg: 12,
        xl: 12,
        xxl: 8,
    },
};
