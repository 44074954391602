import React, { forwardRef, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, IRowNode, RowSelectionOptions } from "ag-grid-community";
import { TParseTransaction } from "@banks/types";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import type { AgGridReactProps } from "ag-grid-react/dist/types/src/shared/interfaces";
import { GQL } from "@binale-tech/shared";

interface IProps extends AgGridReactProps {
    tableKey?: string;
    rowData?: Record<string, string>[];
    handleSelectionChange?: (arg: TParseTransaction[] | GQL.ITransaction[]) => void;
    isRowSelectable?: (rowNode: IRowNode) => boolean;
    tableColumns?: Readonly<ColDef>[];
    components?: Record<string, any>;
    viewHeight: string | number;
    rowSelection?: "single" | "multiple" | RowSelectionOptions<any, any>;
}

export const TransactionsTable = memo(
    forwardRef<AgGridReact, IProps>(
        (
            {
                rowData,
                tableKey,
                gridOptions,
                isExternalFilterPresent,
                doesExternalFilterPass,
                rowClassRules,
                handleSelectionChange,
                tableColumns,
                components,
                viewHeight,
                rowSelection,
                ...restProps
            },
            ref
        ) => {
            return (
                <AgGridTable
                    ref={ref}
                    wrapperStyles={{ height: viewHeight ?? "100%" }}
                    tableKey={tableKey}
                    gridOptions={gridOptions}
                    rowClassRules={rowClassRules}
                    rowData={rowData}
                    columnDefs={tableColumns}
                    components={components}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    rowSelection={rowSelection}
                    onSelectionChanged={e => handleSelectionChange && handleSelectionChange(e.api.getSelectedRows())}
                    {...restProps}
                />
            );
        }
    )
);
