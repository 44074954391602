import { Input, type InputProps, type InputRef } from "antd";
import React, { forwardRef, useEffect, useState } from "react";

interface BaseInputIProps extends Omit<InputProps, "onChange" | "value"> {
    onChange?: (val: string) => void;
    value?: string;
}

export const StringInput = forwardRef<InputRef, BaseInputIProps>((props, ref) => {
    const { value, onChange, pattern, disabled, placeholder, ...rest } = props;

    const [placeholderText, setPlaceholderText] = useState(placeholder);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!pattern || !e.target.value || new RegExp(pattern, "g").test(e.target.value)) {
            if (onChange) {
                onChange(e.target.value);
            }
        }
    };

    useEffect(() => {
        if (placeholder === "Diverse" && !disabled && onChange) {
            onChange("");
            setPlaceholderText(undefined);
        }
    }, [disabled, placeholder]);

    return (
        <Input
            {...rest}
            ref={ref}
            value={value || ""}
            onChange={handleChange}
            placeholder={placeholderText}
            disabled={disabled}
        />
    );
});
