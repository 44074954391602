import React, { FC, useContext } from "react";
import { BankModal } from "banks/components/BankModal";
import { BankCreateForm } from "@banks/components";
import { AddBankContextProvider } from "@banks/modules/BankCreateModule/context/BankCreateContext";
import { BanksAppContext } from "@banks/scripts/context";
import { FormattedMessage } from "react-intl";

export const BankCreateModule: FC = () => {
    const { addBankData } = useContext(BanksAppContext);

    return (
        <AddBankContextProvider isNeedSave={addBankData.isNeedSave}>
            <BankModal
                title={<FormattedMessage id={"app.banks.newBank"} />}
                open={addBankData.isModalOpen}
                footer={false}
                closable={false}
                style={{ maxWidth: "95vw" }}
                width={"800px"}
                destroyOnClose
            >
                <BankCreateForm initialValue={addBankData.bankData} />
            </BankModal>
        </AddBankContextProvider>
    );
};
