import { GridOptions } from "ag-grid-community";
import { baseOptions } from "@app/components/shared/AgGridTable/options/baseOptions";

export const gridOptions: GridOptions = {
    ...baseOptions,
    defaultColDef: {
        filter: "agTextColumnFilter",
        enableCellChangeFlash: true,
        suppressMovable: true,
        suppressHeaderFilterButton: true,
        sortable: false,
    },
    cellFlashDuration: 0,
    rowSelection: {
        mode: "multiRow",
        enableClickSelection: false,
        enableSelectionWithoutKeys: false,
        headerCheckbox: false,
        checkboxes: false,
    },
    selectionColumnDef: null,
    suppressCellFocus: true,
    suppressScrollOnNewData: true,
    suppressMultiSort: true,
};
