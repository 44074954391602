import React, { ComponentProps, FC, useContext, useEffect, useMemo, useState } from "react";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { Button, Descriptions, Empty, Flex, Space, Spin } from "antd";
import { decodePdfInvoiceData, XRechnungResponse } from "@inv/scripts/utils/decodePdfInvoiceData";
import { Contacts, GQL } from "@binale-tech/shared";
import { FormattedMessage } from "react-intl";
import { formatDefault } from "@dms/scripts/helpers";
import { CheckCircleTwoTone, DownloadOutlined } from "@ant-design/icons";
import { useFormHandlerRecordTemplate } from "@app/components/recordform/hooks/handlers/useFormHandlerRecordTemplate";
import { ContactsContext } from "../../../../scripts/context/ContactsContext";
import { getContactVatPrint } from "@app/views/productContacts/form/utils";
type Props = {
    fileId: string;
    productKey?: GQL.IProductKey;
};
export const PreviewInvoice: FC<Props> = ({ fileId, productKey }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [data, setData] = useState<XRechnungResponse>(null);
    const { documentsKV } = useContext(DmsDataContext);
    const { contacts } = useContext(ContactsContext);
    const onTemplateChange = useFormHandlerRecordTemplate();
    const file = documentsKV[fileId];
    useEffect(() => {
        if (!file) {
            return;
        }
        fetch(file.fileUrl)
            .then(r => r.arrayBuffer())
            .then(buf => decodePdfInvoiceData(buf, productKey))
            .then(v => setData(v))
            .finally(() => setIsLoading(false));
    }, [file, productKey]);

    const contact = useMemo(() => {
        if (!data?.raw?.vatId) {
            return null;
        }
        return contacts.find(c => data.raw.vatId === getContactVatPrint(c.legalInfo));
    }, [contacts, data?.raw]);

    if (!file) {
        return null;
    }

    const onApplyRecordData = () => {
        setIsSuccess(true);
        if (contact) {
            data.record.partner = {
                id: contact.uuid,
                name: Contacts.getLabelName(contact),
            };
        }
        onTemplateChange(data.record);
        setTimeout(() => setIsSuccess(false), 1000);
    };

    const items: ComponentProps<typeof Descriptions>["items"] = [
        data?.raw?.date
            ? {
                  key: "date",
                  span: 1,
                  label: <FormattedMessage id={"app.fields.date"} />,
                  children: <p>{`${data.raw.date.day}.${data.raw.date.period}.${data.raw.date.year}`}</p>,
              }
            : null,
        data?.record
            ? {
                  key: "brutto",
                  span: 1,
                  label: <FormattedMessage id={"app.fields.brutto"} />,
                  children: (
                      <p>
                          {formatDefault(data.record.getBrutto())} {data.raw.currency}
                      </p>
                  ),
              }
            : null,
        data?.raw?.vatId
            ? {
                  key: "vatId",
                  span: 1,
                  label: <FormattedMessage id={"app.fields.vatNb"} />,
                  children: <p>{data.raw.vatId}</p>,
              }
            : null,
        contact
            ? {
                  span: 3,
                  key: "contact",
                  label: <FormattedMessage id={"app.titles.Contact"} />,
                  children: <p>{Contacts.getLabelName(contact)}</p>,
              }
            : null,
        data?.raw?.lines
            ? {
                  key: "r_pos",
                  span: 3,
                  label: "Rechnung Positionen",
                  children: (
                      <div>
                          {data.raw.lines.map((l, index) => (
                              <Flex
                                  justify={"space-between"}
                                  key={"position" + index}
                                  style={{ paddingBottom: 5, borderBottom: "1px solid #ebebeb" }}
                              >
                                  <span>{l.text}</span>
                                  <code>
                                      {formatDefault(l.amount)} | {l.tax}% | BU{l.bu} |{" "}
                                      {formatDefault((l.amount * (100 + l.tax)) / 100)}
                                  </code>
                              </Flex>
                          ))}
                      </div>
                  ),
              }
            : null,
        data?.record
            ? {
                  key: "rec_pos",
                  span: 3,
                  label: (
                      <Flex justify={"space-between"} align={"center"}>
                          <strong>Buchingspositionen</strong>
                          <Space>
                              {isSuccess && (
                                  <Button size={"small"} type={"text"} icon={<CheckCircleTwoTone />} shape={"circle"} />
                              )}
                              <Button
                                  size={"small"}
                                  type={"primary"}
                                  icon={<DownloadOutlined />}
                                  shape={"round"}
                                  onClick={onApplyRecordData}
                              >
                                  Zum Buchung ubernemen
                              </Button>
                          </Space>
                      </Flex>
                  ),
                  children: (
                      <div>
                          {data.record.items.map((i, index) => (
                              <Flex
                                  justify={"space-between"}
                                  key={"position_rec" + index}
                                  style={{ paddingBottom: 5, borderBottom: "1px solid #ebebeb" }}
                              >
                                  <span>{i.text}</span>
                                  <code>
                                      BU{i.bu} | {formatDefault(i.brutto)}
                                  </code>
                              </Flex>
                          ))}
                      </div>
                  ),
              }
            : null,
    ].filter(Boolean);
    return (
        <div style={{ overflowY: "auto", height: "100%" }}>
            <Spin spinning={isLoading}>
                {data && <Descriptions items={items} layout="vertical" bordered size={"small"} />}
                {!data && <Empty />}
            </Spin>
        </div>
    );
};
