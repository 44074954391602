import Menu, { MenuProps } from "antd/es/menu";
import React from "react";
import { AppRoutes } from "scripts/routing/routeConstants";
import { CompanyContext } from "scripts/context/CompanyContext";
import { UserContext } from "scripts/context/UserProvider";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { ProductAccessUtils } from "../../../../scripts/models/utils/ProductAccessUtils";

type Props = { menuProps: MenuProps };
export const ModuleCommonImportExportLinks: React.FC<Props> = ({ menuProps }) => {
    const { pathname = "" } = useLocation();
    const { companyGQL } = React.useContext(CompanyContext);
    const user = React.useContext(UserContext);
    const canExportDatev = ProductAccessUtils.canExportDatev(companyGQL, user);
    return (
        <Menu
            selectedKeys={[pathname]}
            {...menuProps}
            items={[
                {
                    key: AppRoutes.recordImport,
                    label: (
                        <Link to={AppRoutes.recordImport}>
                            <FormattedMessage id="app.titles.data_import" />
                        </Link>
                    ),
                },
                {
                    key: AppRoutes.recordExport,
                    disabled: !canExportDatev,
                    label: (
                        <Link to={AppRoutes.recordExport}>
                            <FormattedMessage id="app.titles.data_export" />
                        </Link>
                    ),
                },
            ]}
        />
    );
};
