import { gql } from "@apollo/client";

export const genericRecordSave = gql`
    mutation genericRecordSave($input: GenericRecordSaveInput!) {
        genericRecordSave(input: $input) {
            status
            summary {
                id
                action
                productId
            }
        }
    }
`;

export const genericRecordsDelete = gql`
    mutation genericRecordsDelete($input: GenericRecordsIDInput!) {
        genericRecordsDelete(input: $input) {
            status
            summary {
                id
                action
                productId
            }
        }
    }
`;
export const genericRecordDocuments = gql`
    mutation genericRecordDocuments($input: GenericRecordDocumentsInput!) {
        genericRecordDocuments(input: $input) {
            status
            summary {
                id
                action
                productId
            }
        }
    }
`;
export const genericRecordsCancel = gql`
    mutation genericRecordsCancel($input: GenericRecordsIDInput!) {
        genericRecordsCancel(input: $input) {
            status
            summary {
                id
                action
                productId
            }
        }
    }
`;

export const genericRecordsConfirm = gql`
    mutation genericRecordsConfirm($input: GenericRecordsIDInput!) {
        genericRecordsConfirm(input: $input)
    }
`;

export const genericRecordsJournal = gql`
    mutation genericRecordsJournal($input: GenericRecordsIDInput!) {
        genericRecordsJournal(input: $input)
    }
`;

export const genericRecordsAvis = gql`
    mutation genericRecordsAvis($input: GenericRecordsAvisInput!) {
        genericRecordsAvis(input: $input)
    }
`;

export const genericRecordsColor = gql`
    mutation genericRecordsColor($input: GenericRecordsColorInput!) {
        genericRecordsColor(input: $input)
    }
`;

export const genericRecordsReview = gql`
    mutation genericRecordsReview($input: GenericRecordsReviewInput!) {
        genericRecordsReview(input: $input)
    }
`;
export const genericRecordsSetPriorities = gql`
    mutation genericRecordsSetPriorities($input: GenericRecordsSetPrioritiesInput!) {
        genericRecordsSetPriorities(input: $input)
    }
`;
export const genericRecordsImport = gql`
    mutation genericRecordsImport($input: GenericRecordsImportInput!) {
        genericRecordsImport(input: $input)
    }
`;
