import { GenericRecord } from "../models/GenericRecord";

interface SelectOpts {
    records: GenericRecord[];
    periods: Set<number>;
    year: number;
}

export class GenericRecordPeriodExport {
    static getSelectedRecords(opts: SelectOpts): GenericRecord[] {
        return opts.records.filter(v => v.date.getFullYear() === opts.year && opts.periods.has(v.period) && !v.draft);
    }
}
