import { gql } from "@apollo/client";

export const companyCreateBank = gql`
    mutation companyCreateBank($input: CompanyCreateBankInput!) {
        companyCreateBank(input: $input) {
            id
        }
    }
`;
export const companyUpdateBankYearConfig = gql`
    mutation companyUpdateBank($input: CompanyUpdateBankInput!) {
        companyUpdateBankYearConfig(input: $input) {
            id
        }
    }
`;
export const companyDeleteBank = gql`
    mutation companyDeleteBank($input: CompanyNumEntityDeleteInput!) {
        companyDeleteBank(input: $input) {
            id
        }
    }
`;

export const companyCreateKB = gql`
    mutation companyCreateKB($input: CompanyCreateKBInput!) {
        companyCreateKB(input: $input) {
            id
        }
    }
`;
export const companyUpdateKBYearConfig = gql`
    mutation companyUpdateKB($input: CompanyUpdateKBInput!) {
        companyUpdateKBYearConfig(input: $input) {
            id
        }
    }
`;
export const companyDeleteKB = gql`
    mutation companyDeleteKB($input: CompanyNumEntityDeleteInput!) {
        companyDeleteKB(input: $input) {
            id
        }
    }
`;
