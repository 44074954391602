import React, { useCallback, useContext, useMemo } from "react";
import { Contacts, GQL } from "@binale-tech/shared";
import { child, DataSnapshot } from "firebase/database";

import { CompanyContext, YearPeriodContext } from "./CompanyContext";
import { ContactUtils } from "../models/utils/ContactUtils";
import { contactDelete, contactSave } from "./mutations/contactMutations.graphql";
import { logger } from "../infrastructure/logger";
import { refContacts } from "../api/firebase/firebaseRootRefs";
import { useGqlMutator } from "../graphql/useGqlMutator";
import { useCollectionListener } from "./hooks/useCollectionListener";

interface IContactController {
    saveContact: (contact: Contacts.Contact) => Promise<string>;
    deleteContact: (contactUuid: string) => Promise<void>;
}

interface IContactContext {
    contacts: Contacts.Contact[];
    contactsMap: Map<string, Contacts.Contact>;
}

export const ContactsContext = React.createContext<IContactContext>({
    contacts: [],
    contactsMap: new Map(),
});
export const ContactsControlContext = React.createContext<IContactController>({
    saveContact: () => Promise.reject(),
    deleteContact: () => Promise.reject(),
});

export const ContactsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { companyGQL } = useContext(CompanyContext);
    const { year } = useContext(YearPeriodContext);

    const mutator = useGqlMutator();

    const shouldSkipLoad = useMemo(() => !companyGQL?.id, [companyGQL?.id]);
    const ref = useMemo(() => child(refContacts, String(companyGQL?.id)), [companyGQL?.id]);
    const initializer = useCallback((snap: DataSnapshot) => ContactUtils.mapContactFromGQL(snap.val()), []);

    const { list: contactsList, state: contactsMap } = useCollectionListener(ref, initializer, shouldSkipLoad);

    const contacts = useMemo(() => {
        return [...contactsList].sort((a, b) =>
            a.internalName.toLowerCase().localeCompare(b.internalName.toLowerCase())
        );
    }, [contactsList]);

    const contactActions: IContactController = useMemo(
        () => ({
            saveContact: async contact => {
                const data = ContactUtils.mapContactToGQL(contact);
                logger.debug("save contact: ", { data, contact });
                const res = await mutator.mutate<"contactSave", GQL.IContactUpdateInput>({
                    mutation: contactSave,
                    input: {
                        companyId: companyGQL.id,
                        contact: data,
                    },
                });
                return res.contactSave;
            },
            deleteContact: async uuid => {
                await mutator.mutate<"contactDelete", GQL.ICompanyEntityDeleteInput>({
                    mutation: contactDelete,
                    input: {
                        companyId: companyGQL.id,
                        id: uuid,
                        year,
                    },
                });
            },
        }),
        [companyGQL?.id, mutator, year]
    );

    const value: IContactContext = React.useMemo(
        () => ({
            contacts,
            contactsMap,
        }),
        [contacts, contactsMap]
    );

    return (
        <ContactsContext.Provider value={value}>
            <ContactsControlContext.Provider value={contactActions}>{children}</ContactsControlContext.Provider>
        </ContactsContext.Provider>
    );
};
