import { useCallback, useContext } from "react";
import { DmsType, TBindSubType } from "@dms/types";
import { CompanyContext } from "../../scripts/context/CompanyContext";

export const useBindName = () => {
    const { companyGQL } = useContext(CompanyContext);
    return useCallback(
        (id: string, type: TBindSubType) => {
            if (type === DmsType.Bank) {
                if (!companyGQL?.bankList || !companyGQL?.bankList.length) {
                    return;
                }

                const arr = companyGQL.bankList.filter(el => el.id === id);
                return arr.sort((a, b) => a.year - b.year)[arr.length - 1]?.name;
            }

            if (type === DmsType.KB) {
                if (!companyGQL?.kasseList || !companyGQL?.kasseList.length) {
                    return;
                }

                const arr = companyGQL.kasseList.filter(el => el.id === id);
                return arr.sort((a, b) => a.year - b.year)[arr.length - 1]?.name;
            }
        },
        [companyGQL?.bankList, companyGQL?.kasseList]
    );
};
