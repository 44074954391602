import React from "react";
import dayjs from "dayjs";
import { Button, Popconfirm, Table, TableColumnType, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { GQL, Utils } from "@binale-tech/shared";

import Payment, { PaymentType } from "scripts/models/Payment";
import RecordSelector from "./Verrechnung/RecordSelector";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { GenericRecord } from "scripts/models/GenericRecord";
import { PaymentUtils } from "../../../../scripts/models/utils/PaymentUtils";
import { RecordsContext, RecordsCtxData } from "scripts/context/recordsContext/RecordsCtx";

import "./ZahlungenTable.css";

interface TableRecord {
    canDelete: boolean;
    canEdit: boolean;
    key: number;
    nr: number;
    item: Payment;
}

interface Props {
    payments: Payment[];
    onEdit: (idx: number) => void;
    onDelete: (idx: number) => void;
    disabledSave: boolean;
    disabledDelete: boolean;
    activeIdx?: number;
    allRecords?: RecordsCtxData["allRecords"];
    year: number;
    companyGQL: GQL.ICompany;
}

interface State {
    records: GenericRecord[];
    recordsSelectorPayment: Payment;
}

class PaymentsTableClass extends React.Component<Props, State> {
    state: State = {
        records: [],
        recordsSelectorPayment: null,
    };

    protected get dataSource(): TableRecord[] {
        const payments = (this.props.payments || []).sort((a, b) => a.date.getTime() - b.date.getTime());
        return payments.map((item, idx) => {
            // todo check if we still need it
            const paymentRe = item
                .getRecordRelationKeys()
                .map(k => this.props.allRecords.map.get(k))
                .filter(Boolean)
                .find(v => Utils.PaymentUtils.isProductPaymentRepresentation(v.getProductKey()));

            const isEditingAnotherYear = Boolean(item.key) && item.date.getFullYear() !== this.props.year;
            // block edit if editing payment globalYear !== payment year
            const editDisabled =
                this.props.disabledSave ||
                PaymentType.isBlocked(item.type) ||
                (paymentRe && paymentRe.items.length > 1) ||
                isEditingAnotherYear;

            // block delete if globalYear !== payment year
            const dropDisabled = this.props.disabledDelete;
            return {
                key: idx,
                nr: idx + 1,
                item,
                canDelete: !dropDisabled,
                canEdit: !editDisabled,
            };
        });
    }

    protected get columns(): TableColumnType<TableRecord>[] {
        return [
            {
                title: "#",
                dataIndex: "nr",
                key: "nr",
                width: 25,
            },
            {
                title: <FormattedMessage id="app.fields.date" />,
                key: "brutto",
                width: 100,
                render: (cell: string, record: TableRecord) => dayjs(record.item.date).format("DD.MM.YYYY"),
            },
            {
                title: <FormattedMessage id="app.fields.paymentMethod" />,
                key: "Zahlungsart",
                width: 150,
                render: (cell: string, record: TableRecord) => {
                    return PaymentUtils.getPaymentTypeStringValue(
                        record.item.type,
                        this.props.companyGQL,
                        record.item.date.getFullYear()
                    );
                },
            },
            {
                title: "Zahlungsbetrag",
                width: 130,
                key: "Zahlungsbetrag",
                render: (cell: string, record: TableRecord) =>
                    Utils.CurrencyUtils.currencyFormat(record.item.zahlungsBetrag),
            },
            {
                title: (
                    <>
                        <FormattedMessage id="app.fields.skonto" />, %
                    </>
                ),
                key: "skontop",
                width: 100,
                render: (cell: string, record: TableRecord) => record.item.skontoPercent.toLocaleString(),
            },
            {
                title: "Skontobetrag",
                key: "Skontobetrag",
                width: 130,
                render: (cell: string, record: TableRecord) =>
                    Utils.CurrencyUtils.currencyFormat(record.item.skontoBetrag),
            },
            {
                title: "Records",
                key: "Records",
                render: (cell: string, tr: TableRecord) => {
                    const keys = Object.keys(tr.item.paymentSources);
                    if (keys.length === 0) {
                        return "-";
                    }
                    const content = (
                        <section>
                            {keys.map((k, i) => {
                                const rec = this.props.allRecords.map.get(k);
                                if (!rec) {
                                    return null;
                                }
                                return (
                                    <p key={i}>
                                        {i + 1}. <code>{rec.num}</code>&nbsp;
                                        {Utils.CurrencyUtils.currencyFormat(Math.abs(tr.item.paymentSources[k]))}
                                    </p>
                                );
                            })}
                        </section>
                    );
                    return (
                        <Tooltip placement="right" title={content}>
                            <span onClick={this.handleRecordsClick(tr.item)}>
                                <Button type="link">{keys.length}</Button> <InfoCircleOutlined />
                            </span>
                        </Tooltip>
                    );
                },
            },
            {
                title: <FormattedMessage id="app.fields.actions" />,
                key: "actions",
                width: 90,
                render: (cell: any, record: TableRecord) => {
                    const idEditDisabled = !record.canEdit || this.props.activeIdx === record.key;
                    const idDeleteDisabled = !record.canDelete || this.props.activeIdx === record.key;
                    let deleteButton = (
                        <Button
                            disabled={idDeleteDisabled}
                            danger
                            icon={<DeleteOutlined />}
                            onClick={this.getDeleteFn(record.key)}
                        />
                    );
                    if (record.item.key) {
                        deleteButton = (
                            <Popconfirm
                                disabled={idDeleteDisabled}
                                onConfirm={this.getDeleteFn(record.key)}
                                title={<FormattedMessage id="app.confirmation.header" />}
                                okText={<FormattedMessage id="app.button.confirm" />}
                                cancelText={<FormattedMessage id="app.button.cancel" />}
                            >
                                {React.cloneElement(deleteButton, { onClick: () => {} })}
                            </Popconfirm>
                        );
                    }
                    return (
                        <span>
                            <Button
                                disabled={idEditDisabled}
                                icon={<EditOutlined />}
                                onClick={this.getEditFn(record.key)}
                            />{" "}
                            {deleteButton}
                        </span>
                    );
                },
            },
        ];
    }

    protected getDeleteFn(idx: number) {
        return (e: any) => {
            e.stopPropagation();
            this.props.onDelete(idx);
        };
    }

    protected getEditFn(idx: number) {
        return (e: any) => {
            e.stopPropagation();
            this.props.onEdit(idx);
        };
    }

    private handleRecordsClick = (recordsSelectorPayment: Payment) => () => {
        const records = Object.keys(recordsSelectorPayment.paymentSources).map(k => this.props.allRecords.map.get(k));
        // console.log("handleRecordsClick", { recordsSelectorPayment, records });
        this.setState({
            records,
            recordsSelectorPayment,
        });
    };

    private handleRecordSelectorOkCancel = () => this.setState({ recordsSelectorPayment: null });

    render() {
        // const vrPaymentsAllocation = PaymentUtils.getVerrechnungPaymensAllocation(this.props.payments);
        return (
            <>
                <Table
                    dataSource={this.dataSource}
                    columns={this.columns}
                    rowClassName={(record, idx) =>
                        this.props.activeIdx === idx ? "ModalTable__Row--active" : undefined
                    }
                    size="small"
                    pagination={{ pageSize: 10 }}
                    bordered
                    className="FormModalTable"
                />
                <RecordSelector
                    creditor={null}
                    visible={!!this.state.recordsSelectorPayment}
                    onOk={this.handleRecordSelectorOkCancel}
                    onCancel={this.handleRecordSelectorOkCancel}
                    readOnlyRecords={this.state.records}
                />
            </>
        );
    }
}

export const ZahlungenTable: React.FC<Omit<Props, "allRecords" | "year" | "companyGQL">> = props => {
    const { allRecords } = React.useContext(RecordsContext);
    const { year } = React.useContext(YearPeriodContext);
    const { companyGQL } = React.useContext(CompanyContext);
    return <PaymentsTableClass {...props} allRecords={allRecords} year={year} companyGQL={companyGQL} />;
};
