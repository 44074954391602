import { LoadingOutlined } from "@ant-design/icons";
import { Button, ConfigProvider } from "antd";
import React from "react";
import { GQL } from "@binale-tech/shared";
import { useCanRead } from "../../../scripts/infrastructure/hooks";

type Props = {
    isLoading?: boolean;
} & Pick<React.ComponentProps<typeof Button>, "style" | "onClick" | "size" | "disabled">;

export const AiButton: React.FC<Props> = ({ isLoading, disabled, ...props }) => {
    const hasAccess = useCanRead(GQL.IProductKey.DmsAi);
    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        defaultBg: "linear-gradient(45deg, rgba(22,119,255,1), rgba(22,119,255,0.3)) !important",
                        defaultShadow: "none",
                        defaultColor: "#000 !important",
                    },
                },
                token: {
                    colorBgContainerDisabled: "rgba(0, 0, 0, 0.04) !important",
                    colorTextDisabled: "rgba(0, 0, 0, 0.25) !important",
                },
            }}
        >
            <Button
                {...props}
                disabled={!hasAccess || disabled}
                style={{ ...props.style, width: "auto", minWidth: "105px", paddingLeft: 10, paddingRight: 10 }}
                icon={
                    isLoading ? (
                        <span style={{ fontSize: 12 }}>
                            <LoadingOutlined size={10} /> Is thinking...
                        </span>
                    ) : (
                        <span>🪄 Binale AI</span>
                    )
                }
            />
        </ConfigProvider>
    );
};
