import React from "react";
import cn from "classnames";
import { FieldError } from "react-hook-form";
import { Form, FormItemProps } from "antd";

export interface FieldLabelProps extends FormItemProps {
    fieldError?: FieldError;
    labelAddon?: React.ReactNode;
}

export const FieldLabel: React.FC<FormItemProps & FieldLabelProps> = ({
    children,
    fieldError,
    labelAddon,
    ...rest
}) => {
    const labelProps: FormItemProps = {};
    if (labelAddon) {
        labelProps.label = (
            <>
                <div>{rest.label}</div>
                <div style={{ marginLeft: 8 }}>{labelAddon}</div>
            </>
        );
    }

    return (
        <Form.Item
            validateStatus={fieldError ? "error" : ""}
            help={fieldError?.message}
            {...rest}
            {...labelProps}
            className={cn("BinaleForm__Item", rest.className, { BinaleForm__Item__Addon: !!labelAddon })}
        >
            {children}
        </Form.Item>
    );
};
