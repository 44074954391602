import React, { useContext, useState } from "react";
import { Button, Col, Popconfirm, Row, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";

import { BankFormModal } from "appearance/components/kassenbuch/KassenbuchFormModal";
import { CompanyContext, CompanyControlContext } from "scripts/context/CompanyContext";
import { FlexColumn, Page, PageHeader } from "appearance/components/shared/appearance/page";
import { GlobalYearSelect } from "../../components/shared/Toolbar/YearSelect";
import { ProductKeys } from "scripts/models/Product";
import { UserContext } from "scripts/context/UserProvider";
import { useCanWrite } from "scripts/infrastructure/hooks/usePermissions";

interface TableRecord {
    nr: number;
    key: number;
    entity: GQL.ICompanyBank;
}

export const BanksManageView: React.FC = () => {
    const { companyGQL, yearBanks, isLoaded } = useContext(CompanyContext);
    const user = useContext(UserContext);
    const { onBankDelete } = React.useContext(CompanyControlContext);
    const canWrite = useCanWrite(ProductKeys.Bank);
    const accountingYears = companyGQL?.accountingYears || [];

    const [showModal, setShowModal] = useState(false);
    const [bank, setBank] = useState<GQL.ICompanyBank | null>(null);

    if (!isLoaded || !user.isUserDataLoaded) {
        return null;
    }

    const dataSource: TableRecord[] = yearBanks.map((entity, key) => {
        return {
            entity,
            key,
            nr: key + 1,
        };
    });

    const columns: ColumnType<TableRecord>[] = [
        {
            title: <FormattedMessage id="app.fields.lfdnr" />,
            dataIndex: "nr",
            key: "nr",
            width: 75,
        },
        {
            title: <FormattedMessage id="app.fields.konto" />,
            key: "num",
            render: (cell, record) => record.entity.accountNum,
        },
        {
            title: <FormattedMessage id="app.fields.name" />,
            render: (cell, record) => record.entity.name,
            key: "name",
        },
        {
            title: <FormattedMessage id="app.fields.actions" />,
            key: "actions",
            width: 100,
            render: (cell, record) => (
                <div>
                    <div>
                        <Button icon={<EditOutlined />} onClick={() => handleEditBank({ ...record.entity })} />{" "}
                        <Popconfirm
                            placement={"left"}
                            onConfirm={() =>
                                onBankDelete({ id: record.entity.id, num: String(record.entity.accountNum) })
                            }
                            title={<FormattedMessage id="app.confirmation.header" />}
                            okText={<FormattedMessage id="app.button.confirm" />}
                            cancelText={<FormattedMessage id="app.button.cancel" />}
                        >
                            <Button icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </div>
                </div>
            ),
        },
    ];

    const handleClickCreateBank = () => {
        setBank(null);
        setShowModal(true);
    };

    const handleHideModal = () => {
        setBank(null);
        setShowModal(false);
    };

    const handleEditBank = (record: GQL.ICompanyBank) => {
        setBank(record);
        setShowModal(true);
    };

    return (
        <Page style={{ padding: 24, paddingTop: 5 }}>
            <PageHeader>
                <FormattedMessage id="app.titles.Bank.pl" />
            </PageHeader>

            <FlexColumn>
                <Row gutter={24}>
                    <Col>
                        <GlobalYearSelect />
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={handleClickCreateBank}
                            style={{ marginBottom: 15, width: 250 }}
                            disabled={accountingYears.length === 0}
                        >
                            <FormattedMessage id="app.titles.Bank" tagName="span" />
                            &nbsp;
                            <FormattedMessage id="app.button.add" />
                        </Button>
                    </Col>
                </Row>

                <Table
                    dataSource={dataSource}
                    columns={columns}
                    size="middle"
                    pagination={{ pageSize: 10, hideOnSinglePage: true }}
                    bordered
                />
            </FlexColumn>

            <BankFormModal show={showModal} onHide={handleHideModal} disabled={!canWrite} entity={bank} />
        </Page>
    );
};
