import React, { createContext, FC, ReactNode, useContext, useMemo } from "react";
import { BanksApi } from "@banks/scripts/api";
import { BanksAppControlContext } from "@banks/scripts/context";
import { GQL } from "@binale-tech/shared";

type TActionValue = {
    createBank: (arg: GQL.IBankCreateInput) => Promise<void>;
};

export const AddBankControlContext = createContext<TActionValue>({
    createBank: () => Promise.resolve(),
});

type TProps = {
    children?: ReactNode;
    isNeedSave?: true;
};

export const AddBankContextProvider: FC<TProps> = ({ children, isNeedSave }) => {
    const { setLastBankCreateId } = useContext(BanksAppControlContext);

    const actions = useMemo(
        () => ({
            createBank: async (arg: GQL.IBankCreateInput) => {
                const id = await BanksApi.createBank(arg);
                if (isNeedSave) {
                    setLastBankCreateId(id);
                }
            },
        }),
        [isNeedSave, setLastBankCreateId]
    );

    return <AddBankControlContext.Provider value={actions}>{children}</AddBankControlContext.Provider>;
};
