import React, { FC, useEffect, useRef, useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

type TProps = {
    initialRange: dayjs.Dayjs[];
    onSelectRange: (range: [dayjs.Dayjs, dayjs.Dayjs] | undefined[]) => void;
};

export const TransactionRangePicker: FC<TProps> = ({ initialRange, onSelectRange }) => {
    const [dateRange, setDateRange] = useState(null);

    const initialDateRange = useRef(null);

    useEffect(() => {
        if (!initialRange.filter(Boolean).length) {
            return;
        }
        if (initialDateRange.current) {
            return;
        }

        setDateRange(initialRange);
        initialDateRange.current = initialRange;
    }, [initialRange]);

    const handleChange = (range: [dayjs.Dayjs, dayjs.Dayjs]) => {
        const firstDateRange = range[0].startOf("month");
        const lastDateRange = range[1].endOf("month");

        if (!range) {
            setDateRange(initialDateRange.current);
            return onSelectRange(initialDateRange.current || []);
        }

        setDateRange([firstDateRange, lastDateRange]);
        onSelectRange([firstDateRange, lastDateRange]);
    };

    return <RangePicker picker="month" format={"MM.YYYY"} onChange={handleChange} value={dateRange} />;
};
