import { useCallback, useContext } from "react";
import RecordFormUtils from "@app/components/recordform/utils/RecordFormUtils";
import { CurrencyValue } from "@ui-components/AmountInput/BruttoInput";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { useFormValidators } from "../useFormValidators";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";
import { RecordFormStateControlContext } from "../../context/RecordFormState";
import { useFormHandlerItemBrutto } from "../handlers/useFormHandlerItemBrutto";

export const useFormHandlerBruttoTotal = () => {
    const { refsHtml, refsData } = useContext(RecordFormPropsContext);
    const { setRecordItems } = useContext(RecordFormStateControlContext);
    const { setRecordFieldErrorState } = useFormValidators();
    const { updateEditableRecord } = useFormRecordStateUpdater();
    const onBruttoItemChange = useFormHandlerItemBrutto();
    return useCallback(
        (v: CurrencyValue) => {
            setRecordFieldErrorState(refsHtml.REF_rBRUTTO, false);

            const recordUpdate = RecordFormUtils.getFormCurrency(v);
            updateEditableRecord(recordUpdate);

            const formState = refsData.formStateRef.current;
            // todo set in item in some cases?
            //   const { recordCurrency, recordOriginalAmount } = this.state.editableRecord;
            //   if (!this.isModalMode) {
            //     return this.onBruttoItemChange(v.amount, recordOriginalAmount);
            //   } else if (recordCurrency && this.state.editableRecordItem) {
            //     const { itemOriginalAmount } = this.state.editableRecordItem;
            //     const euroAmount = Utils.CurrencyUtils.getEuroFromCurrency(itemOriginalAmount, recordCurrency.rate);
            //     return this.onBruttoItemChange(euroAmount, itemOriginalAmount);
            //   }
            //   return Promise.resolve();
            // })
            // .then(() => {
            if (!formState.recordItems.length) {
                // creating in the inline mode
                onBruttoItemChange(v.amount, recordUpdate.recordOriginalAmount);
            }

            setRecordItems(prevState => {
                return RecordFormUtils.recalculateCurrencyInItems(prevState, recordUpdate.recordCurrency);
            });
        },
        [setRecordFieldErrorState, refsHtml, refsData, updateEditableRecord, setRecordItems, onBruttoItemChange]
    );
};
