import JSZip from "jszip";
import React, { useCallback } from "react";
import dayjs from "dayjs";
import pLimit from "p-limit";
import { Badge, Button, Collapse, Form, Input, message, Modal, Select, Space, Tabs } from "antd";
import { CheckCircleTwoTone, EditOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { Datev, GQL } from "@binale-tech/shared";
import { Link, useSearchParams } from "react-router-dom";
import { saveAs } from "file-saver";

import TableUtils from "scripts/core/TableUtils";
import { AppRoutes } from "scripts/routing/routeConstants";
import { AssetData, Assets } from "../../../../scripts/infrastructure/fileUploader";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { BuContext } from "scripts/context/BuContext";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { CategoryCsvExporter } from "scripts/exporters/csv/CategoryCsvExporter";
import { Multiselect, MultiselectValue } from "../../../components/shared/Multiselect/Multiselect";
import { PageFullScreen, PageHeader } from "../../../components/shared/appearance/page";
import { YearSelect } from "../../../components/shared/Toolbar/YearSelect";
import { downloadCsv, getCsvBlob } from "scripts/infrastructure/downloader";
import { gql, useApolloClient } from "@apollo/client";
import { logger } from "scripts/infrastructure/logger";
import { DatevGenerator } from "./export/datevGenerator";
import ExportCategories from "./export/ExportCategories";
import ExportJournaled from "./export/ExportJournaled";
import { ExportEXTF } from "./export/ExportEXTF";
import ExportDocuments from "./export/ExportDocuments";

type DownloadDocument = {
    fileUrl: string;
    filename: string;
};

const datevExportFile = gql`
    mutation datevExportFile($companyId: ID!, $file: DatevFileInput!) {
        datevExportFile(companyId: $companyId, file: $file) {
            id
            documentDetails {
                uploaded
                failed
                exists
            }
        }
    }
`;
const datevExportJobs = gql`
    query datevExportJobs($companyId: ID!) {
        datevExportJobs(companyId: $companyId) {
            id
            result
            timestamp
            filename
            dateFrom
            dateTo
            label
            validationDetails {
                type
                title
                detail
                affectedElements {
                    name
                    reason
                }
            }
        }
    }
`;

type ExportResult = { file: GQL.IDatevFileInput; id: string; response?: GQL.IDatevExportJob };

const DatevExportView: React.FC = () => {
    const intl = useIntl();
    const [searchParams, setSearchParams] = useSearchParams();
    const { yearConfig, yearBanks, yearKbs, companyGQL } = React.useContext(CompanyContext);
    const { year, onChangeYear } = React.useContext(YearPeriodContext);
    const payments = React.useContext(PaymentsContext);
    const client = useApolloClient();
    const { companyBuTimeframes } = React.useContext(BuContext);
    const recordsCtx = React.useContext(RecordsContext);
    const accountingYears = companyGQL?.accountingYears || [];
    const isDatevConnected = companyGQL?.datevOAuth && Datev.getRefreshTokenValidity(companyGQL.datevOAuth) > 0;

    const [loadingAgenda, setLoadingAgenda] = React.useState(false);
    const [loadingDatev, setLoadingDatev] = React.useState(false);
    const [datevExportProgress, setDatevExportProgress] = React.useState<number>(null);
    const [loadingDocuments, setLoadingDocuments] = React.useState(false);
    const [exportResults, setExportResults] = React.useState<ExportResult[]>();
    const [selectedProductKey, setSelectedProductKey] = React.useState<GQL.IProductKey | "">(
        (searchParams.get("product") as GQL.IProductKey) || ""
    );
    const [selectedGroup, setSelectedGroup] = React.useState<string>("");
    const [selectedPeriods, setSelectedPeriods] = React.useState<Set<number>>(new Set());

    React.useEffect(() => {
        setSelectedGroup("");
    }, [selectedProductKey]);

    const multiselectValues = React.useMemo(() => {
        const MONTH_NAMES = ["01.01.", ...dayjs.months(), "31.12."];
        return Array.from(Array(14).keys()).map(v => {
            return { value: String(v), label: MONTH_NAMES[v], selected: selectedPeriods.has(v) };
        });
    }, [selectedPeriods]);

    const datevGenerator = React.useMemo(() => {
        return new DatevGenerator({
            companyGQL,
            yearConfig,
            recordsCtx,
            periods: selectedPeriods,
            selectedGroup,
            selectedProductKey,
        });
    }, [companyGQL, yearConfig, recordsCtx, selectedPeriods, selectedGroup, selectedProductKey]);

    const selectedRecords = React.useMemo(() => datevGenerator.getSelectedRecords(), [datevGenerator]);

    const onChangePeriods = React.useCallback((v: MultiselectValue[]) => {
        setSelectedPeriods(oldData => {
            const disabled = v.filter(item => !item.selected).map(item => item.value);
            const enabled = v.filter(item => item.selected).map(item => item.value);
            const newData = new Set<number>();
            oldData.forEach(item => {
                if (!disabled.includes(String(item))) {
                    newData.add(item);
                }
                return item;
            });
            enabled.forEach(item => {
                newData.add(Number(item));
            });
            return newData;
        });
    }, []);

    const disabled = selectedPeriods.size === 0 || selectedRecords.length === 0 || Number.isFinite(datevExportProgress);
    const recordsFS = selectedRecords.filter(v => v.journaled && v.date.getFullYear() === year);

    const handleDownloadDocuments = async () => {
        setLoadingDocuments(true);
        await new Promise(r => setTimeout(r, 250));

        const downloadDocuments: DownloadDocument[] = [];
        selectedRecords.forEach(record => {
            if (record.documents && record.documents.length) {
                const belegfeld1 = String(record.num || record.items[0].belegfeld1).replaceAll(/\W/g, "-");
                const filenameSegments = [
                    dayjs(record.date).format("YYYYMMDD"),
                    record.getRecordCategoryCreditor().num,
                    belegfeld1,
                    record.key,
                ];
                record.documents.forEach((doc, index) =>
                    downloadDocuments.push({ fileUrl: doc.url, filename: [...filenameSegments, index].join("_") })
                );
            }
        });
        if (!downloadDocuments.length) {
            setLoadingDocuments(false);
            return;
        }
        const downloadDocumentsChunks: DownloadDocument[][] = [];
        for (let i = 0; i < downloadDocuments.length; i += 5) {
            const chunk = downloadDocuments.slice(i, i + 5);
            downloadDocumentsChunks.push(chunk);
        }
        const limit = pLimit(5);
        const input = downloadDocumentsChunks.map(chunk =>
            limit(() =>
                Assets.getFiles(chunk.map(v => v.fileUrl)).then(files => {
                    return files.map((data, index) => {
                        const filename = chunk[index].filename;
                        const ext = data.name.split(".").pop();
                        const name = `${filename}.${ext}`;
                        return { ...data, name };
                    });
                })
            )
        );
        const chunkedAssets = await Promise.all(input);
        const assets: AssetData[] = [];
        chunkedAssets.forEach(chunk => assets.push(...chunk));

        const zip = new JSZip();
        assets.forEach(({ name, file }) => {
            zip.file(name, file, { binary: true });
        });

        const filename = datevGenerator.generateFilename(
            selectedProductKey as GQL.IProductKey,
            selectedPeriods,
            selectedGroup
        );
        const filenameSegments = filename.split("_");
        filenameSegments[0] = "Dokumente";
        filenameSegments[filenameSegments.length - 1] = filenameSegments[filenameSegments.length - 1].replace(
            ".csv",
            ""
        );

        const blob = await zip.generateAsync({ type: "blob", compression: "DEFLATE" });
        saveAs(blob, filenameSegments.join("_") + ".zip");
        setLoadingDocuments(false);
    };
    const handleDownload = async (useSeparatePeriodFiles?: boolean) => {
        if (useSeparatePeriodFiles) {
            setLoadingDatev(true);
        } else {
            setLoadingAgenda(true);
        }

        await new Promise(r => setTimeout(r, 150));
        await datevGenerator
            .setApolloClient(client)
            .getExportFiles(useSeparatePeriodFiles)
            .then(async files => {
                if (files.length === 0) {
                    return;
                }
                if (files.length === 1) {
                    const { csvString, filename } = files[0];
                    return downloadCsv(csvString, filename);
                }
                const zip = new JSZip();
                let lastFilename = "";
                const isSameProduct = new Set(files.filter(Boolean).map(v => v.pk)).size === 1;
                files.filter(Boolean).forEach(({ csvString, filename }) => {
                    lastFilename = filename;
                    zip.file(filename, getCsvBlob(csvString), { binary: true });
                });

                const filenameSegments = lastFilename.split("_");
                if (!isSameProduct) {
                    filenameSegments.splice(3, 1, "Alle");
                }
                filenameSegments.pop();
                const blob = await zip.generateAsync({ type: "blob", compression: "DEFLATE" });
                saveAs(blob, filenameSegments.join("_") + ".zip");
            })
            .catch(e => {
                logger.error(e);
                message.error(e.message);
            })
            .finally(() => {
                setLoadingAgenda(false);
                setLoadingDatev(false);
            });
    };
    const handleDatevExport = async () => {
        setExportResults(null);
        setDatevExportProgress(0);
        const files = await datevGenerator.setApolloClient(client).getExportFiles(true);
        const results: ExportResult[] = [];
        for (let i = 0; i < files.length; i++) {
            setDatevExportProgress(Math.round((i / files.length) * 100));
            const { filename, csvString } = files[i];
            const file = { filename, csvString };
            await client
                .mutate<Pick<GQL.IMutation, "datevExportFile">, GQL.IMutationDatevExportFileArgs>({
                    mutation: datevExportFile,
                    variables: {
                        companyId: companyGQL.id,
                        file,
                    },
                })
                .then(res => {
                    results.push({ file, id: res.data.datevExportFile.id });
                })
                .catch(e => {
                    message.error(e.message);
                });
        }
        setDatevExportProgress(100);
        await new Promise(r => setTimeout(r, 500));
        await client
            .query<Pick<GQL.IQuery, "datevExportJobs">>({
                query: datevExportJobs,
                variables: { companyId: companyGQL.id },
                fetchPolicy: "network-only",
            })
            .then(res => {
                const jobsMap = new Map<string, GQL.IDatevExportJob>();
                res.data.datevExportJobs.forEach(job => {
                    jobsMap.set(job.id, job);
                });
                results.forEach(file => {
                    file.response = jobsMap.get(file.id);
                });
                setExportResults(results);
            })
            .finally(() => setDatevExportProgress(null));
    };
    const handleCatExportClick = useCallback(() => {
        const d = new CategoryCsvExporter();
        d.download(
            TableUtils.getTableItems(selectedRecords, payments, companyBuTimeframes, yearConfig),
            yearConfig.kontoExt,
            payments.recordRelation
        );
    }, [companyBuTimeframes, payments, selectedRecords, yearConfig]);
    return (
        <PageFullScreen>
            <Form layout="vertical">
                <PageHeader>Datev Config</PageHeader>
                <Space>
                    <Form.Item label="Beraternummer">
                        <Input value={companyGQL.datevNrConsultant} disabled />
                    </Form.Item>
                    <Form.Item label="Mandantennummer">
                        <Input value={companyGQL.datevNrCompany} disabled />
                    </Form.Item>
                    <Form.Item
                        label={
                            isDatevConnected ? (
                                <strong>
                                    DATEV ist verbunden <CheckCircleTwoTone twoToneColor={"#96d551"} />
                                </strong>
                            ) : (
                                <>&nbsp;</>
                            )
                        }
                    >
                        <Link to={AppRoutes.manageCompanies}>
                            <Button icon={<EditOutlined />} shape="circle" />
                        </Link>
                    </Form.Item>
                </Space>
                <PageHeader>Records</PageHeader>
            </Form>
            <Form layout="vertical">
                <Space>
                    <Form.Item label="Year">
                        <YearSelect years={accountingYears} onChange={onChangeYear} value={year} />
                    </Form.Item>
                    <Form.Item label="Periode">
                        <Multiselect includeSelectAllOption onChange={onChangePeriods} data={multiselectValues} />
                    </Form.Item>
                </Space>
            </Form>
            <Form layout="vertical">
                <Space>
                    <Form.Item label="Modul">
                        <Select<GQL.IProductKey | "">
                            value={selectedProductKey}
                            onChange={v => {
                                setSelectedProductKey(v);
                                setSearchParams({ product: v });
                            }}
                            options={[
                                { value: "", label: intl.formatMessage({ id: "app.components.all" }) },
                                {
                                    value: GQL.IProductKey.Er,
                                    label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Er }),
                                },
                                {
                                    value: GQL.IProductKey.ErA,
                                    label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.ErA }),
                                },
                                {
                                    value: GQL.IProductKey.Deb,
                                    label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Deb }),
                                },
                                {
                                    value: GQL.IProductKey.Kb,
                                    label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Kb }),
                                },
                                {
                                    value: GQL.IProductKey.Bank,
                                    label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Bank }),
                                },
                                {
                                    value: GQL.IProductKey.La,
                                    label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.La }),
                                },
                                {
                                    value: GQL.IProductKey.Fe,
                                    label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Fe }),
                                },
                                {
                                    value: GQL.IProductKey.Pos,
                                    label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Pos }),
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item label="Group">
                        <Select
                            value={selectedGroup}
                            onChange={(v: string) => setSelectedGroup(v)}
                            disabled={
                                ![GQL.IProductKey.Kb, GQL.IProductKey.Bank].includes(
                                    selectedProductKey as GQL.IProductKey
                                )
                            }
                        >
                            <Select.Option value="">Not selected</Select.Option>
                            {selectedProductKey === GQL.IProductKey.Kb &&
                                yearKbs.map(v => (
                                    <Select.Option
                                        value={v.id}
                                        key={v.id}
                                        disabled={selectedProductKey !== GQL.IProductKey.Kb}
                                    >
                                        {v.accountNum} {v.name}
                                    </Select.Option>
                                ))}
                            {selectedProductKey === GQL.IProductKey.Bank &&
                                yearBanks.map(v => (
                                    <Select.Option
                                        value={v.id}
                                        key={v.id}
                                        disabled={selectedProductKey !== GQL.IProductKey.Bank}
                                    >
                                        {v.accountNum} {v.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Space>
            </Form>
            <Collapse
                collapsible={"disabled"}
                activeKey={selectedRecords.length ? ["1"] : []}
                items={[
                    {
                        key: "1",
                        showArrow: false,
                        label: (
                            <>
                                <FormattedMessage id="app.global.total" />: {selectedRecords.length}
                            </>
                        ),
                        children: (
                            <div>
                                <Tabs
                                    defaultActiveKey="EXTF"
                                    items={[
                                        {
                                            label: "EXTF",
                                            key: "EXTF",
                                            children: (
                                                <ExportEXTF
                                                    disabled={disabled}
                                                    datevExportProgress={datevExportProgress}
                                                    isLoadingAgenda={loadingAgenda}
                                                    isLoadingDatev={loadingDatev}
                                                    onExportRecords={handleDownload}
                                                    onDatevExport={handleDatevExport}
                                                />
                                            ),
                                        },
                                        {
                                            label: "DMS",
                                            key: "DMS",
                                            children: (
                                                <ExportDocuments
                                                    disabled={disabled || selectedProductKey === ""}
                                                    isLoading={loadingDocuments}
                                                    onExport={handleDownloadDocuments}
                                                />
                                            ),
                                        },
                                        {
                                            label: <FormattedMessage id="app.titles.ER.festschreibung" />,
                                            key: "FS",
                                            disabled: true,
                                            children: (
                                                <ExportJournaled
                                                    isLoading={loadingAgenda}
                                                    onExport={() => handleDownload()}
                                                    recordsFS={recordsFS}
                                                />
                                            ),
                                        },
                                        {
                                            label: <FormattedMessage id="app.titles.category.pl" />,
                                            key: "Categories",
                                            children: <ExportCategories onExport={handleCatExportClick} />,
                                        },
                                    ]}
                                />
                            </div>
                        ),
                    },
                ]}
            />
            <Modal
                title={"Datev Export results"}
                open={Boolean(exportResults)}
                onCancel={() => setExportResults(undefined)}
                styles={{ body: { padding: 20 } }}
                width={800}
                footer={null}
            >
                {exportResults && (
                    <Collapse
                        size={"small"}
                        items={exportResults.map(item => {
                            const status = item.response.result;
                            return {
                                key: item.id,
                                showArrow: status === "failed",
                                collapsible: status === "failed" ? "header" : "disabled",
                                label: (
                                    <Space style={{ color: "black" }}>
                                        <span>
                                            {status === "succeeded" && <Badge status="success" />}
                                            {status === "failed" && <Badge status="error" />}
                                        </span>
                                        <span>{item.file.filename}</span>
                                    </Space>
                                ),
                                children:
                                    status === "failed" ? (
                                        <div>
                                            <pre>{JSON.stringify(item.response, null, 2)}</pre>
                                        </div>
                                    ) : undefined,
                            };
                        })}
                    />
                )}
            </Modal>
        </PageFullScreen>
    );
};
export default DatevExportView;
