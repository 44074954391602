import React, { FC } from "react";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./closeButton.module.css";

type TProps = {
    onClose: () => void;
    position?: React.CSSProperties["position"];
};

export const CloseButton: FC<TProps> = ({ onClose, position }) => {
    return (
        <CloseOutlined className={styles.closeButton} style={position ? { position } : undefined} onClick={onClose} />
    );
};
