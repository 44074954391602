import { GQL } from "@binale-tech/shared";
import { GenericRecord } from "scripts/models";
import { IntlShape } from "react-intl";
import { message } from "antd";

export class Msg {
    protected constructor(protected readonly intl: IntlShape) {}
    static init(intl: IntlShape) {
        return new Msg(intl);
    }
    public getMsg = (pk: GQL.IProductKey, action: GQL.ISaveSummaryAction) => {
        const module = this.intl.formatMessage({ id: `app.titles.${pk}` });
        return this.intl.formatMessage({ id: "app.global.components.payment_connection." + action }, { module });
    };
    public getMsgFn = (v: GenericRecord) => {
        if (v.journaled) {
            return message.warning;
        } else if (!v.draft) {
            return message.info;
        } else {
            return message.success;
        }
    };
}
