import React, { FC, useContext, useMemo, useState } from "react";

import { PdfToolsContext, PdfToolsControlContext } from "../../context";
import { EmptyDetection } from "../../tools/emptyDetection";
import { useImageRotation } from "../../hooks/useImageRotation";
import { ActionButtonsGrid } from "@pdf-tools/components/ActionButtons/components/ActionButtonsGrid";
import { ActionButtonsList } from "@pdf-tools/components/ActionButtons/components/ActionButtonsList";

interface IProps {
    showDeletedPages: boolean;
    setDebugInfo: (HTML: string) => void;
    toggleDeletedPages: (showDeletedPages: boolean) => void;
}

export const ActionButtons: FC<IProps> = ({ setDebugInfo, showDeletedPages, toggleDeletedPages }) => {
    const { pages, windowSize } = useContext(PdfToolsContext);
    const actions = useContext(PdfToolsControlContext);
    const [hidingProgress, setHidingProgress] = useState<number | null>(null);

    const minHeight = useMemo(() => {
        return windowSize.height < 800;
    }, [windowSize]);

    const middleHeight = useMemo(() => {
        return windowSize.height < 1000;
    }, [windowSize]);

    const checkedPages = pages.filter(page => page.checked);
    const checkedIds = checkedPages.map(page => page.id);

    const { rotateImage } = useImageRotation(checkedPages);

    const action = useMemo(() => {
        return {
            rotateCheckedPages: async () => {
                await rotateImage();
            },
            deleteCheckedPages: () => {
                actions.deletePages(checkedIds);
            },
            restoreCheckedPages: () => {
                actions.restorePages(checkedIds);
            },
            unifyCheckedDocs: () => {
                actions.unifyDocuments(checkedIds);
            },
            splitCheckedDocs: () => {
                actions.splitDocuments(checkedIds);
            },
            deleteEmptyPages: async () => {
                setHidingProgress(1);
                const hidePages: string[] = [];
                const debugHTML: string[] = new Array(pages.length).fill(" ");

                const maxWorkers = 4;
                for (let i = 0; i < pages.length; i += maxWorkers) {
                    const slicedPages = pages.slice(i, i + maxWorkers);
                    let outerIndex = i;
                    await Promise.all(
                        slicedPages.map(async (page, pageIdx) => {
                            const { canvas } = page;
                            const isBlank = await EmptyDetection.isPageBlank(canvas);
                            if (isBlank) {
                                hidePages.push(page.id);
                            }
                            setHidingProgress(1 + Number(((95 * outerIndex) / pages.length).toFixed(0)));
                            outerIndex++;
                            const dataUrl = canvas.toDataURL("image/jpeg");
                            debugHTML[i + pageIdx] = `<br/> edge ${pageIdx + 1}
                        <br/><img src="${dataUrl}" alt="" class="pdf-tools-img-border"/><br/>`;
                        })
                    );
                }

                setHidingProgress(null);
                actions.deletePages(hidePages);
                setDebugInfo(debugHTML.join(""));
            },
            checkAll: () => {
                const allPages = pages.map(page => page.id);
                actions.checkPages(allPages);
            },
            uncheckAll: () => {
                actions.checkPages([]);
            },
        };
    }, [checkedIds, actions]);

    return !minHeight ? (
        <ActionButtonsList
            checkedIds={checkedIds}
            middleHeight={middleHeight}
            toggleDeletedPages={toggleDeletedPages}
            showDeletedPages={showDeletedPages}
            hidingProgress={hidingProgress}
            {...action}
        />
    ) : (
        <ActionButtonsGrid
            checkedIds={checkedIds}
            toggleDeletedPages={toggleDeletedPages}
            showDeletedPages={showDeletedPages}
            hidingProgress={hidingProgress}
            {...action}
        />
    );
};
