import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Select } from "antd";

import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";

interface YearSelectProps {
    years: number[];
    value?: number;
    onChange?: (year: number) => void;
    disabled?: boolean;
}

export const YearSelect = React.memo<YearSelectProps>(function YearSelect({
    years = [],
    disabled,
    onChange = () => {},
    value,
}) {
    return (
        <Select
            onChange={v => onChange(+v)}
            value={"" + value}
            disabled={disabled}
            style={{ maxWidth: 80 }}
            notFoundContent={<FormattedMessage id="app.components.table.no_items" />}
            options={years.map(value => ({ value }))}
        />
    );
});

interface GlobalYearSelectProps {
    disabled?: boolean;
}

export const GlobalYearSelect = React.memo<GlobalYearSelectProps>(function GlobalYearSelect({ disabled }) {
    const { year, onChangeYear } = useContext(YearPeriodContext);
    const { companyGQL } = React.useContext(CompanyContext);

    const years = companyGQL?.accountingYears || [];

    return <YearSelect years={years} disabled={disabled} value={year} onChange={onChangeYear} />;
});
