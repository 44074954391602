import Big from "big.js";

export class MoneyUtils {
    static getNettoFromBrutto(brutto: number, percent: number) {
        const bigBrutto = new Big(brutto);
        return bigBrutto
            .mul(new Big(100))
            .div(new Big(100 + percent))
            .round(0, 1)
            .toNumber();
    }
}
