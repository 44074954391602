import React from "react";
import { Multiselect, MultiselectValue } from "../Multiselect/Multiselect";
import { useIntl } from "react-intl";

interface Props {
    columns: Map<string, React.ReactNode>;
    unselectedColumns: string[];
    disabledOptions?: string[];
    onChange?: (disabled: string[]) => void;
}

export const TableColumnsSelector: React.FC<Props> = props => {
    const { unselectedColumns = [], disabledOptions = [], columns = new Map() } = props;
    const onChange = React.useRef<Props["onChange"]>(props.onChange || (() => {}));
    const intl = useIntl();

    const multiselectValues = React.useMemo(() => {
        const res: MultiselectValue[] = [];
        columns.forEach((v, k) => {
            res.push({
                value: k,
                label: v,
                selected: !unselectedColumns.includes(k),
                disabled: disabledOptions.includes(k),
            });
        });
        return res;
    }, [unselectedColumns, columns, disabledOptions]);

    const onChangeHandler = React.useCallback(
        (v: MultiselectValue[]) => {
            const disabled = v.filter(item => !item.selected).map(item => item.value);
            const enabled = v.filter(item => item.selected).map(item => item.value);
            const newData = multiselectValues.map(item => {
                if (disabled.includes(item.value)) {
                    item.selected = false;
                } else if (enabled.includes(item.value)) {
                    item.selected = true;
                }
                return item;
            });
            onChange.current(newData.filter(item => !item.selected).map(item => item.value));
        },
        [multiselectValues, onChange]
    );
    return (
        <Multiselect
            includeSelectAllOption
            rightSide
            onChange={onChangeHandler}
            data={multiselectValues}
            labelStatic={intl.formatMessage({ id: "app.settings.button.columns.text" })}
        />
    );
};
