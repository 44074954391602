import React, { FC } from "react";
import dayjs from "dayjs";
import { Divider, Select } from "antd";
import { useIntl } from "react-intl";

type Props = {
    period: number;
    onChange: (v: number) => void;
};
export const PeriodQuarterSelect: FC<Props> = ({ period, onChange }) => {
    const intl = useIntl();
    return (
        <Select<number>
            value={period}
            onChange={onChange}
            popupClassName={"Popup--MaxHeight"}
            options={[
                {
                    label: intl.formatMessage({ id: "app.labels.allYear" }),
                    value: null,
                },
                ...dayjs.months().map((item, index) => ({
                    label: item,
                    value: index + 1,
                })),
                {
                    label: <Divider style={{ margin: "8px 0" }} />,
                    disabled: true,
                },
                {
                    label: "Quartal 1",
                    value: 41,
                },
                {
                    label: "Quartal 2",
                    value: 42,
                },
                {
                    label: "Quartal 3",
                    value: 43,
                },
                {
                    label: "Quartal 4",
                    value: 44,
                },
            ]}
        />
    );
};
