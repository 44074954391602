import { FC, useContext } from "react";
import { DmsDataContext } from "@dms/types/ContextTypes";

type TProps = {
    type: string;
    subType: string;
};

export const DocumentSubTypeFormatted: FC<TProps> = ({ type, subType }) => {
    const { documentTypes } = useContext(DmsDataContext);

    if (!documentTypes.length) {
        return null;
    }

    const subTypes = documentTypes.find(el => el.id === type)?.subTypes ?? [];

    if (!subTypes.length) {
        return null;
    }

    return subTypes.find(el => el.id === subType)?.name;
};
