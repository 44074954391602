import React from "react";
import { Button, Form } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

type Props = {
    onExport: () => void;
};
const ExportCategories: React.FC<Props> = ({ onExport }) => {
    return (
        <Form layout="vertical">
            <Form.Item label={<span>&nbsp;</span>}>
                <Button style={{ width: 180 }} icon={<DownloadOutlined />} htmlType="button" onClick={onExport}>
                    &nbsp;
                    <FormattedMessage id="app.titles.category.pl" />
                    &nbsp;CSV
                </Button>
            </Form.Item>
        </Form>
    );
};
export default React.memo(ExportCategories);
