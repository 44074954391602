import React, { FC } from "react";
import { Button, Form, Progress, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { DatevButton } from "../../../../components/oauth/DatevButton";
import { CompanyContext } from "../../../../../scripts/context/CompanyContext";
import { Datev } from "@binale-tech/shared";

type Props = {
    disabled: boolean;
    onExportRecords: (useDatevSeparation: boolean) => void;
    onDatevExport: () => void;
    isLoadingAgenda: boolean;
    isLoadingDatev: boolean;
    datevExportProgress?: number;
};
export const ExportEXTF: FC<Props> = ({
    disabled,
    onExportRecords,
    isLoadingAgenda,
    isLoadingDatev,
    datevExportProgress,
    onDatevExport,
}) => {
    const { companyGQL } = React.useContext(CompanyContext);
    const isDatevConnected = companyGQL?.datevOAuth && Datev.getRefreshTokenValidity(companyGQL.datevOAuth) > 0;
    return (
        <div>
            <Form layout="vertical">
                <Space>
                    <Form.Item label={<span>&nbsp;</span>}>
                        <Button disabled={disabled} onClick={() => onExportRecords(true)} loading={isLoadingDatev}>
                            <FormattedMessage id="app.button.download" />
                            Datev
                        </Button>
                    </Form.Item>
                    <Form.Item label={<span>&nbsp;</span>}>
                        <DatevButton
                            disabled={!isDatevConnected}
                            onClick={onDatevExport}
                            loading={Number.isFinite(datevExportProgress)}
                        >
                            Export to Datev
                        </DatevButton>
                    </Form.Item>

                    {Number.isFinite(datevExportProgress) && (
                        <div style={{ width: 200 }}>
                            <Progress
                                percent={datevExportProgress}
                                status={datevExportProgress === 100 ? "success" : "active"}
                            />
                        </div>
                    )}
                </Space>
            </Form>

            <Form layout="vertical">
                <Space>
                    <Form.Item label={<span>&nbsp;</span>}>
                        <Button disabled={disabled} onClick={() => onExportRecords(false)} loading={isLoadingAgenda}>
                            <FormattedMessage id="app.button.download" />
                            Agenda
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </div>
    );
};
