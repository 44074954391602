import React, { FC, FocusEventHandler, useCallback, useContext } from "react";
import { Popconfirm } from "antd";
import { RechnungInputBlock } from "@ui-components/RechnungInputBlock";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";

import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useCheckAutoPaymentConnection } from "../hooks/useCheckAutoPaymentConnection";
import { useFormHandlerRecordBF1 } from "../hooks/handlers/useFormHandlerRecordBF1";

export const FormRecordBelegfeld1: FC = () => {
    const isDisabled = useFormIsRefDisabled();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { editableRecord, recordValidationStates } = useContext(RecordFormStateContext);
    const { showDoppelErfassungAlert, onDoppelErfassungConfirm, onRechnungsnummerBlur } =
        useContext(RecordFormPropsContext);
    const onRecordBelegfeld1Change = useFormHandlerRecordBF1();

    const checkAutoBinding = useCheckAutoPaymentConnection();

    const validation = recordValidationStates.get(refsHtml.REF_rBELEGFELD1);

    const onRecordBelegfeld1Blur: FocusEventHandler<HTMLInputElement> = useCallback(
        e => {
            const num = e.target.value;
            if (onRechnungsnummerBlur && num) {
                onRechnungsnummerBlur(num);
            }
            checkAutoBinding();
        },
        [checkAutoBinding, onRechnungsnummerBlur]
    );

    return (
        <Popconfirm
            destroyTooltipOnHide
            placement="top"
            okButtonProps={{
                autoFocus: true,
                onKeyDown: e => {
                    if (e.key === "Enter") {
                        onDoppelErfassungConfirm();
                    }
                },
            }}
            title={
                <div>
                    <strong>Achtung! </strong>
                    Diese Rechnungsnummer wurde bereits verwendet. Bitte überprüfen Sie die Doppelerfassung!
                </div>
            }
            onConfirm={onDoppelErfassungConfirm}
            showCancel={false}
            okText="OK"
            open={showDoppelErfassungAlert}
            trigger={"onBlur" as any}
        >
            <RechnungInputBlock
                ref={refsHtml.REF_rBELEGFELD1}
                labelProps={{
                    fieldError: validation ? { type: "error", message: " " } : undefined,
                    style: { minWidth: 150 },
                }}
                value={editableRecord.recordNum}
                onChange={onRecordBelegfeld1Change}
                onBlur={onRecordBelegfeld1Blur}
                className="InputBlock__ShortBlock"
                disabled={isDisabled(refsHtml.REF_rBELEGFELD1)}
            />
        </Popconfirm>
    );
};
