import React, {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import pLimit from "p-limit";

import { AISearch } from "../tools/ai/AISearch";
import { CompanyContext } from "scripts/context/CompanyContext";
import {
    DroppableIds,
    IController,
    IDecodeOptions,
    IDecoderResponse,
    IPdfToolsContext,
    OcrDocument,
    OcrDocumentViewModel,
    OcrLoadedFile,
    OcrPage,
    PdfParkingState,
    SplitMode,
} from "../types";

import { addDoc, collection } from "firebase/firestore";
import {
    moveToList,
    parkNewPages,
    parkPages,
    removeDocumentFromParking,
    reorderPages,
    splitDocuments,
    unifyDocuments,
} from "./helpers/helpers";
import { firestore } from "../../scripts/api/firebase/firebase";
import { logger } from "../../scripts/infrastructure/logger";
import { message } from "antd";
import { useIntl } from "react-intl";
import { DropResult } from "react-beautiful-dnd";
import { createOcrFile, PdfDecoder } from "../tools/pdfDecoder";
import { downloadDocuments, exportDocuments } from "./helpers/helpersPdf";
import { useGqlMutator } from "../../scripts/graphql/useGqlMutator";
import { calculateHashArrayBuffer } from "../../scripts/infrastructure/helpers/hashCalculate";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { DmsType } from "@dms/types";

const modelVersion = "2.11";

const defaultPdfToolsState: IPdfToolsContext = {
    files: [],
    documents: [],
    viewDocuments: [],
    viewUploadDocuments: [],
    viewParkedDocuments: [],
    pages: [],
    modelVersion,
    activePageId: null,
    metadata: null,
    decodeProgress: null,
    ignoreEncryption: false,
    parkedDocuments: null,
    uploadedFiles: null,
    draggableId: null,
    windowSize: { width: 0, height: 0 },
    isExportProgress: false,
};

const defaultControllerState: IController = {
    setActivePageId: () => {},
    addFiles: () => Promise.resolve(),
    loadNewFile: () => Promise.resolve(),
    clearAllFiles: () => {},
    decodePdf: () => Promise.resolve(),
    deletePages: () => {},
    restorePages: () => {},
    pageCalculation: () => {},
    rotatePages: () => {},
    checkPages: () => {},
    splitDocuments: () => {},
    unifyDocuments: () => {},
    renameDocument: () => {},
    reorderingFiles: () => {},
    movePage: () => {},
    movePageToList: () => {},
    movePageToParking: () => {},
    setIgnoreEncryption: () => {},
    handleDownload: () => Promise.reject(),
    saveDraggableId: () => {},
    removeParkingDocument: () => {},
    removeUploadedDocument: () => {},
    exportFiles: () => Promise.reject(),
};

export const PdfToolsContext = createContext<IPdfToolsContext>(defaultPdfToolsState);

export const PdfToolsControlContext = createContext<IController>(defaultControllerState);

export const PdfToolsProvider: FC<PropsWithChildren> = props => {
    const intl = useIntl();
    const { companyGQL } = useContext(CompanyContext);
    const { documentsKV: dmsDocuments } = useContext(DmsDataContext);
    const mutator = useGqlMutator();

    const [activePageId, setActivePageId] = useState<string | null>(null);
    const [ignoreEncryption, setIgnoreEncryption] = useState(false);
    const [files, setFiles] = useState<OcrLoadedFile[]>([]);
    const [pages, setPages] = useState<OcrPage[]>([]);
    const [documents, setDocuments] = useState<OcrDocument[]>([]);
    const [decodeProgress, setDecodeProgress] = useState<number | null>(null);
    const [parkedDocuments, setParkedDocuments] = useState<PdfParkingState | null>();
    const [uploadedFiles, setUploadedFiles] = useState<IDecoderResponse | null>(null);
    const [draggableId, setDraggableId] = useState<string | null>(null);
    const [decoder] = useState<PdfDecoder>(new PdfDecoder(AISearch.getInstance(modelVersion), setDecodeProgress));
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
    const [isExportProgress, setIsExportProgress] = useState(false);

    useEffect(() => {
        function onResize() {
            const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
            setWindowSize({ width: windowWidth, height: windowHeight });
        }

        onResize();
        addEventListener("resize", onResize);

        return () => {
            removeEventListener("resize", onResize);
        };
    }, []);

    const onSaveMetadata = useCallback(
        (data: unknown) => {
            if (companyGQL?.id) {
                addDoc(collection(firestore, "ocr/metadata/" + companyGQL.id), data).then(r =>
                    logger.log("metadata saved", r)
                );
            }
        },
        [companyGQL?.id]
    );

    const viewDocuments: OcrDocumentViewModel[] = useMemo(() => {
        return documents.map(doc => {
            const docPages = doc.pageIds.map(pageId => pages.find(page => page.id === pageId));
            return {
                ...doc,
                pages: docPages,
                visible: docPages.some(page => page && !page.deleted),
            };
        });
    }, [documents, pages]);

    const viewParkedDocuments: OcrDocumentViewModel[] = useMemo(() => {
        if (!parkedDocuments) {
            return [];
        }
        return parkedDocuments.parsedDocuments
            .map(doc => {
                const docPages = doc.pageIds
                    .map(pageId => parkedDocuments.parsedPages.find(page => page.id === pageId))
                    .filter(Boolean);
                if (!docPages.length) {
                    return null;
                }
                return {
                    ...doc,
                    pages: docPages,
                    visible: true,
                };
            })
            .filter(Boolean);
    }, [parkedDocuments]);

    const viewUploadDocuments: OcrDocumentViewModel[] = useMemo(() => {
        if (!uploadedFiles) {
            return [];
        }

        return uploadedFiles.parsedDocuments
            .map(doc => {
                const docPages = doc.pageIds
                    .map(pageId => uploadedFiles.parsedPages.find(page => page.id === pageId))
                    .filter(Boolean);
                if (!docPages.length) {
                    return null;
                }
                return {
                    ...doc,
                    pages: docPages,
                    visible: true,
                };
            })
            .filter(Boolean);
    }, [uploadedFiles]);

    useEffect(() => {
        return () => decoder?.dispose();
    }, [decoder]);

    const decodePdf = useCallback(
        async (options: IDecodeOptions, ocrFiles: OcrLoadedFile[]): Promise<void> => {
            const { mode, skipQRCheck, userEmail } = options;
            const results = await decoder.decodeMultiplePdfs({ mode, skipQRCheck, userEmail }, ocrFiles);
            if (results.length === 0) {
                return;
            }

            const newFiles = results.map(({ newFile }) => newFile);
            const newPages = results.flatMap(({ parsedPages }) => parsedPages);
            const newDocuments = results.flatMap(({ parsedDocuments }) => parsedDocuments);

            if (newFiles.length > 0) {
                setFiles(prevFiles => {
                    return prevFiles.map(stateFile => {
                        const updatedFile = newFiles.find(newFile => newFile.id === stateFile.id);
                        return updatedFile ? { ...stateFile, ...updatedFile } : stateFile;
                    });
                });
            }

            setPages(prevPages => [...prevPages, ...newPages]);
            setActivePageId(newPages.length > 0 ? newPages[0].id : "");

            if (mode === SplitMode.put) {
                const pagesIds = newPages.map(p => p.id);
                const newDocs = unifyDocuments(pagesIds, newDocuments);
                return setDocuments(newDocs);
            }

            setDocuments(prevDocuments => [...prevDocuments, ...newDocuments]);
        },
        [decoder]
    );

    const loadNewFile = useCallback(
        async (opts: IDecodeOptions, file: File) => {
            const ocrFile = await createOcrFile(file);
            const result = await decoder.decodePdf(opts, ocrFile);
            if (result) {
                setUploadedFiles(result);
            }
        },
        [decoder]
    );

    const togglePages = useCallback(
        (pageIds: string[], deletePage: boolean) => {
            const newPages = pages.map(page => {
                if (pageIds.includes(page.id)) {
                    page.deleted = deletePage;
                }
                return page;
            });
            setPages([...newPages]);
        },
        [pages]
    );

    const controller: IController = useMemo(
        () => ({
            setIgnoreEncryption,
            setActivePageId: (pageId: string | null) => setActivePageId(pageId),
            addFiles: async (arg: File[]): Promise<void> => {
                const limit = pLimit(2);
                const input = arg.map(el => limit(() => createOcrFile(el)));
                const ocrFiles = await Promise.all(input);
                setFiles(prevFiles => [...prevFiles, ...ocrFiles]);
            },
            reorderingFiles: (arg: OcrLoadedFile[]) => setFiles(arg),
            clearAllFiles: () => {
                decoder.cancel();
                setActivePageId(null);
                setDecodeProgress(null);
                setDocuments([]);
                setPages([]);
                setFiles([]);
                setParkedDocuments(undefined);
                setUploadedFiles(null);
            },
            decodePdf,
            loadNewFile,
            deletePages: pageIds => {
                togglePages(pageIds, true);
            },
            restorePages: pageIds => {
                togglePages(pageIds, false);
            },
            pageCalculation: (id: string, state: boolean) => {
                const newPages = pages.map(page => {
                    if (id === page.id) {
                        page.isCalculated = state;
                    }
                    return page;
                });
                setPages([...newPages]);
            },
            rotatePages: (pageIds, rotatedImageUrl, rotateValue) => {
                const newPages = pages.map(page => {
                    if (pageIds.includes(page.id)) {
                        page.imageUrl = rotatedImageUrl;
                        if (rotateValue !== undefined) {
                            page.rotate = rotateValue;
                        } else {
                            page.rotate = page.rotate !== 180 ? 180 : 0;
                        }
                    }
                    return page;
                });
                setPages([...newPages]);
            },
            checkPages: pageIds => {
                const newPages = pages.map(page => {
                    page.checked = pageIds.includes(page.id);
                    return page;
                });
                setPages([...newPages]);
            },
            splitDocuments: (pageIds: string[]) => {
                const newDocs = splitDocuments(pageIds, documents);
                setDocuments(newDocs);
            },
            unifyDocuments: (pageIds: string[]) => {
                const newDocs = unifyDocuments(pageIds, documents);
                setDocuments(newDocs);
            },
            renameDocument: (document: OcrDocument, newName: string) => {
                const newDocs = documents.map(doc => (doc.id === document.id ? { ...document, name: newName } : doc));
                setDocuments(newDocs);
            },
            movePage: (oldIndex: number, newIndex: number) => {
                const newDocs = reorderPages(oldIndex, newIndex, documents);
                setDocuments(newDocs);
                setDraggableId(null);
            },
            movePageToList: (e: DropResult) => {
                if (e.source.droppableId !== DroppableIds.UploadedPages) {
                    const parkedDocs = moveToList({ e, documents, currentParkingState: parkedDocuments });

                    setDocuments(parkedDocs.documentList);
                    setPages(prev => {
                        return Array.from(new Set([...prev, ...parkedDocs.parsedPages]));
                    });

                    if (parkedDocuments?.newFile && parkedDocuments.newFile.length) {
                        setFiles(prevFiles => [...prevFiles, ...parkedDocuments.newFile]);
                    }

                    setActivePageId(parkedDocs?.parsedPages[0].id);
                    setParkedDocuments(
                        parkedDocs.parkedDocuments.parsedPages.length ? parkedDocs.parkedDocuments : undefined
                    );
                    setDraggableId(null);
                } else {
                    const parkedDocs = moveToList({ e, documents, currentParkingState: uploadedFiles });
                    setDocuments(parkedDocs.documentList);
                    setPages(prev => {
                        return Array.from(new Set([...prev, ...parkedDocs.parsedPages]));
                    });

                    if (uploadedFiles.newFile) {
                        setFiles(prevFiles => [...prevFiles, uploadedFiles.newFile]);
                    }

                    setActivePageId(parkedDocs?.parsedPages[0].id);
                    setUploadedFiles(null);
                    setDraggableId(null);
                }
            },
            movePageToParking: (e: DropResult) => {
                if (e.source.droppableId !== DroppableIds.UploadedPages) {
                    const { docs, parkingDocs } = parkPages({
                        e,
                        documents,
                        pages,
                        currentParkingState: parkedDocuments,
                    });

                    setDocuments(docs.documentList);
                    setPages(docs.parsedPages);
                    setParkedDocuments(parkingDocs);
                    if (parkingDocs.parsedDocuments[parkingDocs.parsedDocuments.length - 1]) {
                        setActivePageId(parkingDocs.parsedDocuments[parkingDocs.parsedDocuments.length - 1].pageIds[0]);
                    }
                    setDraggableId(null);
                } else {
                    const { parkingDocs } = parkNewPages({
                        e,
                        uploadedState: uploadedFiles,
                        currentParkingState: parkedDocuments,
                    });
                    setParkedDocuments(parkingDocs);
                    if (parkingDocs.parsedDocuments[parkingDocs.parsedDocuments.length - 1]) {
                        setActivePageId(parkingDocs.parsedDocuments[parkingDocs.parsedDocuments.length - 1].pageIds[0]);
                    }
                    setUploadedFiles(null);
                    setDraggableId(null);
                }
            },
            removeParkingDocument: (id: string) => {
                const parkingDocs = removeDocumentFromParking({ id, currentParkingState: parkedDocuments });

                if (!parkingDocs.parsedPages.length) {
                    setActivePageId(pages[0].id);
                }

                setParkedDocuments(parkingDocs.parsedPages.length ? parkingDocs : undefined);
            },
            removeUploadedDocument: (id: string) => {
                if (id) {
                    setUploadedFiles(null);
                }
                setActivePageId(documents[0].pageIds[0]);
            },
            handleDownload: async (documentId?: string) => {
                const visibleDocs = viewDocuments.filter(doc => doc.visible);
                if (!visibleDocs.length || !files.length) {
                    message.warning("No documents to export");
                    return;
                }
                if (!documentId) {
                    files.forEach(file => onSaveMetadata(file.metadata));
                }

                try {
                    await downloadDocuments({ viewDocuments, files, documents, pages, ignoreEncryption, documentId });
                } catch (e) {
                    logger.error("Download error", e);
                    message.error(intl.formatMessage({ id: "app.global.error" }));
                }
            },
            saveDraggableId: (id: string) => {
                setDraggableId(id);
            },
            exportFiles: async (documentId?: string) => {
                setIsExportProgress(true);
                const visibleDocs = viewDocuments.filter(doc => doc.visible);
                if (!visibleDocs.length || !files.length) {
                    message.warning("No documents to export");
                    return;
                }
                const exportFiles = await exportDocuments({
                    viewDocuments,
                    files,
                    pages,
                    documents,
                    ignoreEncryption,
                    documentId,
                });
                const errorFileName: string[] = [];
                const exportedFileName: string[] = [];
                const alreadyInDms: string[] = [];
                const limit = pLimit(2);

                const promisesArr = exportFiles
                    .map(el => {
                        const file = new File([el.blob], el.fileName, { lastModified: 0 });

                        return { file, uint8Array: el.uint8Array, fileName: el.fileName };
                    })
                    .map(file =>
                        limit(async () => {
                            const hash = await calculateHashArrayBuffer(file.uint8Array);
                            const hasFileInDb = Object.keys(dmsDocuments).includes(hash);

                            if (hasFileInDb) {
                                alreadyInDms.push(file.fileName);
                                return;
                            }

                            const res = await DocumentsApi.createDocument({
                                fileData: { file: file.file, isUploaded: false, hash },
                                mutator,
                                companyData: companyGQL,
                                documentType: { type: DmsType.new_documents },
                                setFileStatus: () => {},
                            }).catch(err => {
                                logger.error(err.message);
                                errorFileName.push(file.fileName);
                            });

                            if (!res) {
                                return;
                            }

                            exportedFileName.push(file.fileName);
                            return;
                        })
                    );

                await Promise.all(promisesArr);

                if (exportedFileName.length) {
                    await message.success(
                        `The file(-s) ${exportedFileName.join(", ")}  was successfully exported to DMS`
                    );
                }

                if (alreadyInDms.length) {
                    message.info(`The file(-s) ${alreadyInDms.join(", ")} is already in DMS`);
                }

                if (errorFileName.length) {
                    await message.error(`The file(-s) ${errorFileName.join(", ")}  is not exported to DMS`);
                }

                setIsExportProgress(false);
            },
        }),
        [
            decodePdf,
            loadNewFile,
            decoder,
            togglePages,
            pages,
            documents,
            parkedDocuments,
            uploadedFiles,
            viewDocuments,
            files,
            onSaveMetadata,
            ignoreEncryption,
            intl,
            dmsDocuments,
            mutator,
            companyGQL,
        ]
    );
    const value = useMemo(
        () => ({
            ...defaultPdfToolsState,
            files,
            pages,
            documents,
            viewDocuments,
            viewParkedDocuments,
            viewUploadDocuments,
            activePageId,
            decodeProgress,
            ignoreEncryption,
            parkedDocuments,
            uploadedFiles,
            draggableId,
            windowSize,
            isExportProgress,
        }),
        [
            activePageId,
            parkedDocuments,
            uploadedFiles,
            decodeProgress,
            documents,
            files,
            ignoreEncryption,
            pages,
            viewDocuments,
            viewParkedDocuments,
            viewUploadDocuments,
            draggableId,
            windowSize,
            isExportProgress,
        ]
    );

    return (
        <PdfToolsContext.Provider value={value}>
            <PdfToolsControlContext.Provider value={controller}>{props.children}</PdfToolsControlContext.Provider>
        </PdfToolsContext.Provider>
    );
};
