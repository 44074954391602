import React from "react";
import { Contacts } from "@binale-tech/shared";
import { Select, SelectProps } from "antd";

const { Option } = Select;

export const LegalFormSelect: React.FC<SelectProps> = props => {
    return (
        <Select getPopupContainer={trigger => trigger.parentNode} {...props}>
            {Contacts.ContactConstants.LegalForm.map(value => (
                <Option key={value} value={value}>
                    {value}
                </Option>
            ))}
        </Select>
    );
};
