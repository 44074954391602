import React, { FC } from "react";
import dayjs from "dayjs";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, DatePicker, Space } from "antd";

import { FlexRow } from "../../shared/appearance/page/Scaffold";
import { PeriodSelect } from "../../shared/Toolbar/PeriodSelect";
import { YearSelect } from "../../shared/Toolbar/YearSelect";

export interface DateSectionProps {
    year?: number;
    period?: number;
    years?: number[];
    withAll?: boolean;
}

export interface DateSectionInternalProps {
    onYearChange?: (v: number) => void;
    onPeriodChange?: (v: number) => void;
}

export const DateSection: FC<
    DateSectionProps & DateSectionInternalProps & { withArrows?: boolean; disableYear?: boolean }
> = React.memo(function DateSection(props) {
    return (
        <FlexRow style={{ width: 270 }}>
            <YearSelect
                years={props.years}
                onChange={props.onYearChange}
                value={props.year}
                disabled={props.disableYear}
            />
            <div style={{ width: 5 }} />
            <Space.Compact>
                <PeriodSelect onChange={props.onPeriodChange} value={props.period} withAll={props.withAll} />
                {props.withArrows && (
                    <>
                        <Button
                            icon={<ArrowLeftOutlined />}
                            onClick={() => props.onPeriodChange(props.period > 0 ? props.period - 1 : 0)}
                        />
                        <Button
                            icon={<ArrowRightOutlined />}
                            onClick={() => props.onPeriodChange(props.period < 13 ? props.period + 1 : 13)}
                        />
                    </>
                )}
            </Space.Compact>
        </FlexRow>
    );
});

export interface IDateFieldProps {
    value: Date | null;
    setValue: (value: Date | null) => void;
    setStartOfDay?: boolean;
    setEndOfDay?: boolean;
}

export const DateField: FC<IDateFieldProps> = React.memo(function DateField({
    setValue,
    value,
    setEndOfDay,
    setStartOfDay,
}) {
    const handleChange = (date: dayjs.Dayjs) => {
        let newDate: Date = null;
        if (date !== null) {
            if (setEndOfDay) {
                newDate = date.endOf("day").toDate();
            }
            if (setStartOfDay) {
                newDate = date.startOf("day").toDate();
            }
        }
        setValue(newDate);
    };
    return (
        <DatePicker
            style={{ width: 110 }}
            value={value ? dayjs(value) : null}
            onChange={handleChange}
            allowClear
            format={"DD.MM.YYYY"}
        />
    );
});
