import React, { type FC, useEffect, useState } from "react";
import { PdfViewer } from "@ui-components/PdfViewer/PdfViewer";
import { getAppCheckToken } from "../../../../scripts/api/firebase/firebase";

interface IProps {
    style?: { height: string; width: string };
    isModal?: true;
    url?: string;
    filename?: string;
    type?: string;
}

const DEFAULT_STYLE = { height: "calc(100vh - 122px)", width: "100%" };

export const DocumentPdfViewer: FC<IProps> = ({ style, isModal, url, filename, type }) => {
    const [appCheckToken, setAppCheckToken] = useState<string>();
    const [viewerUrl, setViewerUrl] = useState<string>();

    useEffect(() => {
        if (!url) {
            return;
        }

        getAppCheckToken().then(token => {
            setAppCheckToken(token);
            setViewerUrl(url);
        });
    }, [url]);

    return (
        <div style={style ?? DEFAULT_STYLE}>
            <PdfViewer
                isModal={isModal}
                url={viewerUrl}
                fileName={filename}
                type={type}
                appCheckToken={appCheckToken}
            />
        </div>
    );
};
