import React, { FC, useState } from "react";
import { useLocation } from "react-router";
import { PreviewParseDataContextProvider } from "@banks/modules/ParseTransactionModule/context/PreviewParseDataProvider";
import { ParseDataContextProvider } from "@banks/modules/ParseTransactionModule/context/ParseDataProvider";
import { PreviewParseDataModule } from "@banks/modules/ParseTransactionModule/components/PreviewParseData/PreviewParseData";
import { ParseDataModule } from "@banks/modules/ParseTransactionModule/components/ParseData";

export const ParseTransactionModule: FC = () => {
    const [previewMode, setPreviewMode] = useState<boolean>(true);
    const location = useLocation();

    return (
        <PreviewParseDataContextProvider
            initialBankData={location.state.bankToUpload}
            lastBankCreateId={location.state.lastBankCreateId}
            parseResult={location.state.parseResult}
            setPreviewMode={arg => setPreviewMode(arg)}
        >
            <ParseDataContextProvider>
                {previewMode ? <PreviewParseDataModule /> : <ParseDataModule />}
            </ParseDataContextProvider>
        </PreviewParseDataContextProvider>
    );
};
