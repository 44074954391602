import FileRender from "./FileRender";
import React, { FC } from "react";
import { Empty } from "antd";

import styles from "./Preview.module.scss";

type TProps = {
    fileId?: string;
};

export const PreviewPdf: FC<TProps> = ({ fileId }) => {
    if (!fileId) {
        return (
            <div className={styles.previewEmpty}>
                <Empty />
            </div>
        );
    }

    return (
        <div className={styles.previewWrapper}>
            <FileRender fileId={fileId} />
        </div>
    );
};
