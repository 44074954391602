import React, { useMemo } from "react";
import dayjs from "dayjs";
import { DefaultOptionType } from "antd/es/select";
import { Periods } from "@binale-tech/shared";

import { Select, SelectProps } from "antd";
import { useIntl } from "react-intl";

export type SelectorPeriods = Periods.Period | Periods.ExtraPeriod;

interface Props extends SelectProps {
    withAll?: boolean;
    value?: SelectorPeriods;
    onChange?: (month: Periods.Period) => void;
    disabled?: boolean;
}

export const PeriodSelect: React.FC<Props> = props => {
    const intl = useIntl();
    const { withAll, value, disabled, onChange, ...rest } = props;

    const selectOptions: DefaultOptionType[] = useMemo(() => {
        const options: DefaultOptionType[] = [
            {
                label: "01.01. (Saldenvortrag)",
                value: 0,
            },
            ...dayjs.months().map((item, index) => ({
                label: item,
                value: index + 1,
            })),
            {
                label: "31.12. (Abschluss)",
                value: 13,
            },
        ];
        if (withAll) {
            options.unshift({
                label: intl.formatMessage({ id: "app.labels.allYear" }),
                value: -1,
            });
        }
        return options;
    }, [intl, withAll]);

    return (
        <Select
            {...rest}
            onChange={v => onChange(v === -1 ? null : v)}
            style={{ width: 120 }}
            value={value === null ? -1 : value}
            disabled={disabled}
            listHeight={500}
            notFoundContent={intl.formatMessage({ id: "app.components.table.no_items" })}
            options={selectOptions}
        />
    );
};
