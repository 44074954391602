import { useCallback, useContext } from "react";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { useFormValidators } from "../../hooks/useFormValidators";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";

export const useFormHandlerItemBrutto = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setItemFieldErrorState } = useFormValidators();
    const { updateEditableItem } = useFormRecordStateUpdater();
    return useCallback(
        (itemBrutto: number, itemOriginalAmount: number) => {
            setItemFieldErrorState(refsHtml.REF_iBELEGFELD1, false);
            updateEditableItem({ itemBrutto, itemOriginalAmount });
        },
        [refsHtml, updateEditableItem, setItemFieldErrorState]
    );
};
