import React, { CSSProperties, FC, useCallback, useContext, useState } from "react";
import { Button, message, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { UploadOutlined } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";

import { PdfToolsControlContext } from "../../context";
import { isFileEncrypted } from "../../context/helpers/helpersPdf";

const overlayStyle: CSSProperties = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: "2.5em 0",
    background: "rgba(0,0,0,0.5)",
    textAlign: "center",
    color: "#fff",
};

const infoStyle: CSSProperties = {
    position: "absolute",
    top: 50,
    fontSize: "large",
};

export const UploadPdfFile: FC = () => {
    const actions = useContext(PdfToolsControlContext);
    const [loading, setLoading] = useState(false);
    const { formatMessage } = useIntl();

    const onDrop = useCallback(
        async (files: File[]) => {
            setLoading(true);
            const isEncrypted = await isFileEncrypted(files[0]);
            if (isEncrypted) {
                message.error(formatMessage({ id: "app.ocr.error.encrypted" }));
            } else {
                actions.addFiles(files);
            }
            setLoading(false);
        },
        [formatMessage, actions]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { "application/pdf": [".pdf"] },
        multiple: true,
    });

    return (
        <Row>
            <div {...getRootProps()} className="pdf-tools-RowDropzone--fullscreen">
                <input {...getInputProps()} />
                {isDragActive ? (
                    <div style={overlayStyle}>
                        <FormattedMessage id="app.ocr.placeDocumentHere" />
                    </div>
                ) : (
                    <div style={infoStyle}>
                        <FormattedMessage id="app.ocr.dragDocument" />
                    </div>
                )}
                <Button size="large" type="primary" icon={<UploadOutlined />} shape="round" loading={loading}>
                    <FormattedMessage id="app.ocr.button.selectDocument" tagName="span" />
                </Button>
            </div>
        </Row>
    );
};
