import Category from "../Category";
import Payment, { IPaymentType, PaymentPrototype, PaymentType } from "../Payment";
import { Base, GQL, Utils } from "@binale-tech/shared";
import { CategoryUtils } from "./CategoryUtils";
import { CompanyContext } from "../../context/CompanyContext";
import { ContextType } from "react";
import { GenericRecord } from "../GenericRecord";
import { PaymentsCtxData } from "../../context/PaymentsProvider";
import { ProductKeys } from "../Product";

export class PaymentUtils {
    protected static emptyPM: IPaymentType[] = [];

    static getPaymentType(category: Category, type: GQL.IPaymentType): IPaymentType {
        return {
            value: type,
            konto: category.getExtNum(Base.CompanyKontoExtMax),
        };
    }

    static getPaymentTypeStringValue(type: IPaymentType, c: GQL.ICompany, year: number): string {
        if (!type) {
            return "";
        }
        switch (type.value) {
            case PaymentType.OptionPayment:
                return "Zahlung";
            case PaymentType.OptionVerrechnung:
                return "Verrechnung mit geleisteten Anzahlungen";
            case PaymentType.OptionFE:
                return new Category(type.konto, "").getExtNumPrint(
                    c.accountingConfigs.find(v => v.year === year)?.kontoExt || 0
                );
        }

        const accountingYearConfig = c.accountingConfigs.find(v => v.year === year);
        if (type.value === PaymentType.OptionKasse) {
            const kb = c.kasseList
                .filter(v => v.year === year)
                .find(v => new Category(v.accountNum.toString(), "").equalsTo(new Category(type.konto, "")));
            if (!kb || !accountingYearConfig) {
                return "Kasse " + type.konto;
            }
            const cat = new Category(String(kb.accountNum), kb.name);
            return cat.getExtNumPrint(accountingYearConfig.kontoExt) + " - " + cat.name;
        }

        if (type.value === PaymentType.OptionBank) {
            const bank = c.bankList
                .filter(v => v.year === year)
                .find(v => new Category(v.accountNum.toString(), "").equalsTo(new Category(type.konto, "")));
            if (!bank || !accountingYearConfig) {
                return "Bank " + type.konto;
            }
            const cat = new Category(String(bank.accountNum), bank.name);
            return cat.getExtNumPrint(accountingYearConfig.kontoExt) + " - " + cat.name;
        }
        throw new Error("unknown payment type: " + type.value);
    }

    static getCompanyPaymentMethods(c: GQL.ICompany, year: number) {
        if (!c) {
            return this.emptyPM;
        }
        if (!c.accountingYears.includes(year)) {
            return this.emptyPM;
        }
        const vs: IPaymentType[] = [];
        if (c.kasseList.length) {
            c.kasseList
                .filter(v => v.year === year)
                .forEach(kb => {
                    const numCat = new Category(String(kb.accountNum), kb.name);
                    vs.push({
                        ...this.getPaymentType(numCat, PaymentType.OptionKasse),
                        isFavourite: kb.id === c.favouritePaymentType,
                        uuid: kb.id,
                    });
                });
        }
        if (c.bankList.length) {
            c.bankList
                .filter(v => v.year === year)
                .forEach(bank => {
                    const numCat = new Category(String(bank.accountNum), bank.name);
                    vs.push({
                        ...this.getPaymentType(numCat, PaymentType.OptionBank),
                        isFavourite: bank.id === c.favouritePaymentType,
                        uuid: bank.id,
                    });
                });
        }
        [PaymentType.PAYMENT].forEach(type =>
            vs.push({
                ...type,
                isFavourite: type.value === c.favouritePaymentType,
            })
        );
        return vs;
    }

    static getVerrechnungPaymensAllocation(payments: Payment[], excludePaymentKeys: string[] = []) {
        const vrPaymentsLinkedKeys = new Map();
        payments
            .filter(({ key }) => !excludePaymentKeys.includes(key))
            .forEach(p => {
                if (p.type.value === PaymentType.VERRECHNUNG.value) {
                    Object.keys(p.paymentSources).forEach(k => {
                        if (!vrPaymentsLinkedKeys.has(k)) {
                            vrPaymentsLinkedKeys.set(k, 0);
                        }
                        vrPaymentsLinkedKeys.set(k, vrPaymentsLinkedKeys.get(k) + p.paymentSources[k]);
                    });
                }
            });
        return vrPaymentsLinkedKeys;
    }

    static getDefaultPaymentMethod({
        companyGQL,
        yearBanks,
        yearKbs,
    }: Pick<ContextType<typeof CompanyContext>, "companyGQL" | "yearBanks" | "yearKbs">) {
        const favouritePayment = companyGQL.favouritePaymentType;
        if (favouritePayment) {
            const foundBank = yearBanks.find(value => value.id === favouritePayment);
            const foundKasse = yearKbs.find(value => value.id === favouritePayment);
            if (foundKasse) {
                return this.getPaymentType(
                    new Category(String(foundKasse.accountNum), foundKasse.name),
                    PaymentType.OptionKasse
                );
            }
            if (foundBank) {
                const numCat = new Category(String(foundBank.accountNum), foundBank.name);
                return this.getPaymentType(numCat, PaymentType.OptionBank);
            }
        }
        switch (favouritePayment) {
            case PaymentType.BANK.value:
                return PaymentType.BANK;
            case PaymentType.KASSE.value:
                return PaymentType.KASSE;
            default:
                return PaymentType.PAYMENT;
        }
    }

    static getEuroFromCurrency(originalAmount: number, rate: number) {
        if (rate) {
            return Math.round(originalAmount / rate);
        } else {
            return originalAmount || 0;
        }
    }

    static getOpenBruttoFromSourceForPaymentRepresentationRecord(
        paymentRepresentationRecord: GenericRecord,
        paymentRepresentationRecordProductKey: GQL.IProductKey,
        paymentSourceRecord: GenericRecord,
        recordRelation: PaymentsCtxData["recordRelation"]
    ) {
        const relatedPayments = recordRelation.get(paymentSourceRecord.key);
        const openAmount = paymentSourceRecord.getOpenBrutto(relatedPayments);
        const { amount } = Utils.PaymentUtils.getPaymentDataFromRepresentationRecord(
            openAmount,
            paymentRepresentationRecordProductKey,
            paymentSourceRecord.getProductKey(),
            { category: paymentRepresentationRecord.category?.num },
            paymentRepresentationRecord.items.map(item => ({ category: item.category?.num }))
        );
        return amount;
    }

    static toPrototype(
        payment: Payment,
        paymentRecordRepresentationProductKey: GQL.IProductKey,
        paymentRecordSourceProductKey: GQL.IProductKey
    ): PaymentPrototype {
        if (!payment) {
            return null;
        }
        const skontoBetrag = Utils.SollHaben.areSameSign(
            paymentRecordRepresentationProductKey,
            paymentRecordSourceProductKey
        )
            ? payment.skontoBetrag
            : -payment.skontoBetrag;
        return {
            skontoBetrag: Object.is(-0, skontoBetrag) ? 0 : skontoBetrag,
            sourceRecordKey: payment.sourceRecordKey,
        };
    }

    public static hasPaymentTypeChanged(oldPaymentType: Payment["type"], newPaymentType: Payment["type"]) {
        if (oldPaymentType.value !== newPaymentType.value) {
            return true;
        }
        const isKontoSame = CategoryUtils.areEqual(oldPaymentType.konto, newPaymentType.konto);
        return !isKontoSame;
    }

    /**
     * Determines if this is FE record from Anzahlungen payment
     * @param {GenericRecord} record
     * @param {Map<string, Payment[]>} paymentsData
     * @returns {boolean}
     */
    static readonly isFERecordEditBlocked = (record: GenericRecord, paymentsData: Map<string, Payment[]>) => {
        if (record.getProductKey() !== ProductKeys.FE) {
            return false;
        }
        const p = paymentsData.get(record.key) || [];
        if (p.length === 1) {
            const [payment] = p;
            if (payment.getAutoFERecordKeys().length > 0) {
                return true;
            }
        }
        return false;
    };

    static readonly isRepresentationRecordEditBlocked = (
        record: GenericRecord,
        paymentsData: Map<string, Payment[]>,
        yearConfig: GQL.ICompanyAccountingYear
    ) => {
        if (yearConfig.taxation !== "IST") {
            return false;
        }
        if (!Utils.PaymentUtils.isProductPaymentRepresentation(record.getProductKey())) {
            return false;
        }
        const p = paymentsData.get(record.key) || [];
        if (p.length && record.items.length > 1) {
            return true;
        }
        return false;
    };
}
