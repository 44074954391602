import { TTableCols } from "@dms/types";

export const reorderingCols = (
    arr1: Record<keyof TTableCols, boolean>[],
    arr2: Record<keyof TTableCols, boolean>[]
): Record<keyof TTableCols, boolean>[] => {
    const targetArr = [...arr1];
    const sampleArr = [...arr2];
    const sampleDictionary: Record<string, boolean> = {};

    sampleArr.forEach(el => {
        const [key] = Object.keys(el);
        const [value] = Object.values(el);
        sampleDictionary[key] = value;
    });

    const notIncluded = [];

    while (sampleArr.length) {
        const firstSampleEl = sampleArr[0];
        const [key] = Object.keys(firstSampleEl);

        const prevIndex = targetArr.findIndex(el => {
            return el && key === Object.keys(el)[0];
        });

        if (prevIndex === -1) {
            notIncluded.push(firstSampleEl);
            sampleArr.splice(0, 1);
            delete sampleDictionary[key];
            continue;
        }

        const newIndex = targetArr.findIndex(el => {
            const [targetKey] = Object.keys(el);
            return sampleDictionary[targetKey];
        });

        const [movedElem] = targetArr.splice(newIndex, 1, firstSampleEl);
        targetArr.splice(prevIndex, 1, movedElem);

        sampleArr.splice(0, 1);
        delete sampleDictionary[key];
    }
    return [...notIncluded, ...targetArr];
};
