import React, { FC } from "react";

import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import styles from "@banks/components/FormFields/FormFields.module.scss";
import { Typography } from "antd";
import { StringInput } from "@app/components/shared/form/baseComponents/StringInput/StringInput";
import { FormattedMessage } from "react-intl";

const { Text } = Typography;

const TEXT_MAX_LENGTH = 60;

export const InfoField: FC = () => {
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id={"app.fields.buchtext"} />
                </Text>
            }
            name={"text"}
        >
            <StringInput maxLength={TEXT_MAX_LENGTH} showCount />
        </FieldLabel>
    );
};

const TEXT2_MAX_LENGTH = 80;

export const Info2Field: FC = () => {
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id={"app.fields.buchtext2"} />
                </Text>
            }
            name={"text2"}
        >
            <StringInput maxLength={TEXT2_MAX_LENGTH} showCount />
        </FieldLabel>
    );
};
