import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { BanksContext } from "../../../../scripts/context/BanksContext";
import { GQL } from "@binale-tech/shared";

type TValue = {
    banks: GQL.IBank[];
};

export const BankListContext = createContext<TValue>({
    banks: [],
});

type TProps = {
    children?: ReactNode;
};

export const BanksLitContextProvider: FC<TProps> = ({ children }) => {
    const [bankList, setBankList] = useState<GQL.IBank[]>();

    const { banksData } = useContext(BanksContext);

    useEffect(() => {
        const isFavoriteArr: GQL.IBank[] = [];
        const isNotFavoriteArr: GQL.IBank[] = [];

        Object.keys(banksData).forEach(bank => {
            if (banksData[bank].isFavorite) {
                isFavoriteArr.push(banksData[bank]);
            } else {
                isNotFavoriteArr.push(banksData[bank]);
            }
        });

        isFavoriteArr.sort((a, b) => a?.bankName?.localeCompare(b?.bankName));
        isNotFavoriteArr.sort((a, b) => a?.bankName?.localeCompare(b?.bankName));

        setBankList([...isFavoriteArr, ...isNotFavoriteArr]);
    }, [banksData]);

    const value = useMemo(() => {
        return {
            banks: bankList,
        };
    }, [bankList]);

    return <BankListContext.Provider value={value}>{children}</BankListContext.Provider>;
};
