import React from "react";
import { Collapse, Flex, Form } from "antd";
import { GQL } from "@binale-tech/shared";

type Props = {
    datevUserInfo: GQL.IDatevUserInfo;
};
export const DatevClients: React.FC<Props> = ({ datevUserInfo }) => {
    return (
        <Collapse
            items={datevUserInfo.clients.map(c => ({
                key: c.id,
                label: (
                    <Flex justify="space-between">
                        <span>{c.name}</span>
                        <code>{c.id}</code>
                    </Flex>
                ),
                children: (
                    <Form layout="vertical">
                        {c.services.map((s, idx) => {
                            return (
                                <Form.Item label={s.name} key={idx.toString() + s.name}>
                                    <code>{s.scopes.join("\n")}</code>
                                </Form.Item>
                            );
                        })}
                    </Form>
                ),
            }))}
        />
    );
};
