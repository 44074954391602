import React, { FC } from "react";
import { KBProvider } from "../KBProvider";
import { TemplatesViewContent } from "@app/views/productSharedComponents/templates/TemplatesViewContent";

export const KBTemplatesView: FC = () => {
    return (
        <KBProvider>
            <TemplatesViewContent />
        </KBProvider>
    );
};
