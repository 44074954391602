import React, { useEffect, useState } from "react";
import { Button, Form, Popover } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Utils } from "@binale-tech/shared";

import { BruttoInputBlock } from "appearance/components/shared/form/FormBlocks";

interface SumOverrideProps {
    brutto: number;

    onSubmit: (v: number) => void;
    disabled: boolean;
}

const SumOverride: React.FC<SumOverrideProps> = ({ brutto, onSubmit, disabled }) => {
    const [inputValue, setInputValue] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [visible, setVisible] = useState<boolean>(false);

    const validateField = React.useCallback(
        (value: number) => {
            if (!value) {
                return "required";
            }
            if (value <= 0) {
                return "too low";
            }
            if (value > brutto) {
                return "too high";
            }
            return null;
        },
        [brutto]
    );

    const handleSubmit = React.useCallback(() => {
        const validationError = validateField(inputValue);
        if (validationError) {
            setError(validationError);
        } else {
            onSubmit(inputValue);
            setVisible(false);
        }
    }, [validateField, onSubmit, inputValue]);

    useEffect(() => {
        setInputValue(brutto);
    }, [brutto]);

    return (
        <Popover
            open={visible}
            onOpenChange={setVisible}
            destroyTooltipOnHide
            overlayClassName="SumOverride"
            trigger="click"
            placement="right"
            content={
                <Form layout="inline">
                    <BruttoInputBlock
                        labelProps={{
                            fieldError: error ? { type: "error", message: error } : undefined,
                            style: { width: "auto" },
                        }}
                        autoFocus
                        value={inputValue}
                        onChange={value => {
                            setInputValue(value.amount);
                            setError(null);
                        }}
                    />
                    <Form.Item>
                        <Button type="primary" disabled={inputValue === brutto} onClick={handleSubmit}>
                            <FormattedMessage id="app.button.save" />
                        </Button>
                    </Form.Item>
                </Form>
            }
        >
            <Button type="primary" icon={<EditOutlined />} size="small" disabled={disabled}>
                {Utils.CurrencyUtils.currencyFormat(brutto)}
            </Button>
        </Popover>
    );
};
export default React.memo(SumOverride);
