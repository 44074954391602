import { IViewsKeys } from "./User";
import { ProductKey } from "./Product";
import { child, get, set, update as fbUpdate } from "firebase/database";
import { refSettings } from "../api/firebase/firebaseRootRefs";

class ProgramSettings {
    hideBuchText?: boolean;
    useBuchText2?: boolean;
    useBelegfeld2?: boolean;
    useKs?: boolean;
    useExpertBu?: boolean;
    useCurrency?: boolean;
    useDueDate?: boolean;
    hideContacts?: boolean;

    protected static async retrieveFields(companyId: string): Promise<Record<ProductKey, ProgramSettings>> {
        const fbRef = child(refSettings, `${companyId}/global/forms`);
        const snap = await get(fbRef);
        return snap.val() || {};
    }

    protected static async retrieveColumns(data: { userId: string; companyId: string }) {
        if (!data?.userId || !data?.companyId) {
            return new Map<keyof IViewsKeys, string[]>();
        }

        const fbRef = child(refSettings, `${data.companyId}/user/${data.userId}/tables`);
        const snap = await get(fbRef);

        return new Map(Object.entries(snap.val() || {})) as Map<keyof IViewsKeys, string[]>;
    }

    static async getFields(companyId: string) {
        return this.retrieveFields(companyId);
    }

    static async getColumns(data: { userId: string; companyId: string }) {
        return this.retrieveColumns(data);
    }

    static async updateFields(companyId: string, productKey: ProductKey, update: Partial<ProgramSettings>) {
        const fbRef = child(refSettings, `${companyId}/global/forms/${productKey}`);
        await fbUpdate(fbRef, update);
    }

    static async updateColumns(
        data: { userId?: string; companyId?: string },
        view: keyof IViewsKeys,
        update: string[]
    ) {
        const fbRef = child(refSettings, `${data.companyId}/user/${data.userId}/tables/${view}`);
        await set(fbRef, update);
    }

    protected static async retrieveDmsConfig(data: { userId: string; companyId: string }) {
        if (!data?.userId || !data?.companyId) {
            return {};
        }

        const fbRefColumnTableDms = child(refSettings, `${data.companyId}/user/${data.userId}/dms_table`);
        const fbRefFilterTableDms = child(refSettings, `${data.companyId}/user/${data.userId}/dms_table_filters`);

        const snapConfTableDmsColumns = await get(fbRefColumnTableDms);
        const snapConfTableDmsFilters = await get(fbRefFilterTableDms);

        return {
            columnConfig: snapConfTableDmsColumns.val() || {},
            filterConfig: snapConfTableDmsFilters.val() || {},
        };
    }

    protected static replaceUndefined(obj: Record<string, any>): Record<string, any> {
        const t = obj;
        for (const v in t) {
            if (typeof t[v] === "object") {
                this.replaceUndefined(t[v]);
            } else if (t[v] === undefined) {
                t[v] = null;
            }
        }
        return t;
    }

    static async getDmsConfig(data: { userId: string; companyId: string }) {
        return this.retrieveDmsConfig(data);
    }

    static async updateDmsColumnConfig(data: { userId?: string; companyId?: string }, update: Record<string, any>) {
        this.replaceUndefined(update);

        const fbRef = child(refSettings, `${data.companyId}/user/${data.userId}/dms_table`);

        await set(fbRef, update);
    }

    static async updateDmsFilterConfig(data: { userId?: string; companyId?: string }, update: Record<string, any>) {
        this.replaceUndefined(update);
        const fbRef = child(refSettings, `${data.companyId}/user/${data.userId}/dms_table_filters`);

        await set(fbRef, update);
    }
}

export default ProgramSettings;
