import React from "react";
import { Button, Dropdown, Menu, Modal, Space, Transfer } from "antd";
import { DownOutlined, FileExcelOutlined, FilePdfOutlined, UpOutlined } from "@ant-design/icons";

import { Exporters, TableExportType } from "../../../../scripts/exporters/Exporters";
import "./TableExporter.css";

interface Props {
    columns: string[];
    defaultColumnsKeys?: string[];
    onOk: (type: TableExportType, columns?: string[]) => Promise<void>;
    children: React.ReactNode;
    className?: string;
}

interface State {
    showColumnsModal: boolean;
    targetColumns: string[];
    selectedTargetKey?: string;
    type?: TableExportType;
    loading: boolean;
}

class TableExporter extends React.PureComponent<Props, State> {
    static defaultProps = {
        disabled: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            showColumnsModal: false,
            loading: false,
            targetColumns: [...(props.defaultColumnsKeys || [])],
        };
    }

    private onClick: React.ComponentProps<typeof Menu>["onClick"] = opts => {
        if (opts.key === TableExportType.csv) {
            this.props.onOk(opts.key);
        } else {
            this.setState({
                showColumnsModal: true,
                type: TableExportType.pdf,
            });
        }
    };

    private onOk = () => {
        this.setState({ loading: true });
        this.props.onOk(this.state.type, this.state.targetColumns).then(() => {
            this.onCancel();
        });
    };

    private onCancel = () => {
        this.setState({
            showColumnsModal: false,
            targetColumns: [...this.props.defaultColumnsKeys],
            selectedTargetKey: null,
            type: null,
            loading: false,
        });
    };

    private onChange = (targetKeys: string[], direction: string, moveKeys: any) => {
        const targetColumns = direction === "right" ? [...this.state.targetColumns, ...moveKeys] : targetKeys;
        this.setState({
            targetColumns: [...targetColumns],
        });
    };

    private onTargetKeySelect = (s: string[], t: string[]) => {
        this.setState({
            selectedTargetKey: t.length === 1 ? t[0] : null,
        });
    };

    private isUpButtonDisabled() {
        const targetKey = this.state.selectedTargetKey;
        const isTopItem = this.state.targetColumns.indexOf(targetKey) === 0;
        return targetKey == null || isTopItem;
    }

    private isDownButtonDisabled() {
        const targetKey = this.state.selectedTargetKey;
        const isBottomItem = this.state.targetColumns.indexOf(targetKey) === this.state.targetColumns.length - 1;
        return targetKey == null || isBottomItem;
    }

    private onUpClick = () => {
        const targetKey = this.state.selectedTargetKey;
        const indexOf = this.state.targetColumns.indexOf(targetKey);
        const targetColumns = [...this.state.targetColumns];
        if (indexOf > 0) {
            targetColumns[indexOf] = targetColumns[indexOf - 1];
            targetColumns[indexOf - 1] = targetKey;
            this.setState({
                targetColumns,
            });
        }
    };

    private onDownClick = () => {
        const targetKey = this.state.selectedTargetKey;
        const indexOf = this.state.targetColumns.indexOf(targetKey);
        const targetColumns = [...this.state.targetColumns];
        if (indexOf < targetColumns.length - 1) {
            targetColumns[indexOf] = targetColumns[indexOf + 1];
            targetColumns[indexOf + 1] = targetKey;
            this.setState({
                targetColumns,
            });
        }
    };

    private getColumnsSelector() {
        return (
            <div>
                <Transfer
                    dataSource={Array.from(new Set(this.props.columns)).map(c => ({
                        key: c,
                        title: c,
                    }))}
                    targetKeys={this.state.targetColumns}
                    render={item => item.title}
                    onChange={this.onChange}
                    listStyle={{
                        width: 300,
                        height: 300,
                    }}
                    className="TableExporter"
                    onSelectChange={this.onTargetKeySelect}
                />
                <Button
                    type="primary"
                    size="small"
                    icon={<UpOutlined />}
                    disabled={this.isUpButtonDisabled()}
                    onClick={this.onUpClick}
                    style={{
                        position: "absolute",
                        top: 120,
                        left: 332,
                    }}
                />
                <Button
                    type="primary"
                    size="small"
                    icon={<DownOutlined />}
                    disabled={this.isDownButtonDisabled()}
                    onClick={this.onDownClick}
                    style={{
                        position: "absolute",
                        top: 205,
                        left: 332,
                    }}
                />
            </div>
        );
    }

    public render() {
        const menuItems = [
            {
                key: Exporters.TYPE_CSV,
                label: (
                    <Space>
                        <FileExcelOutlined /> CSV
                    </Space>
                ),
            },
            {
                key: Exporters.TYPE_PDF,
                label: (
                    <Space>
                        <FilePdfOutlined /> PDF
                    </Space>
                ),
            },
        ];
        return (
            <div className={this.props.className}>
                <Dropdown menu={{ onClick: this.onClick, items: menuItems }} placement="bottomRight">
                    {this.props.children}
                </Dropdown>
                <Modal
                    style={{ top: 20 }}
                    open={this.state.showColumnsModal}
                    onOk={this.onOk}
                    okButtonProps={{
                        loading: this.state.loading,
                    }}
                    onCancel={this.onCancel}
                    width={730}
                >
                    {this.getColumnsSelector()}
                </Modal>
            </div>
        );
    }
}

export default TableExporter;
