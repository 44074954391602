import React, { FC, useContext } from "react";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { SingleFormModule } from "@dms/modules/SingleFormModule/SingleFormModule";
type Props = {
    fileId: string;
};
export const PreviewDms: FC<Props> = ({ fileId }) => {
    const { documentsKV } = useContext(DmsDataContext);
    const file = documentsKV[fileId];
    if (!file) {
        return null;
    }
    return (
        <div style={{ overflowY: "auto", height: "100%", paddingBottom: 10 }}>
            <SingleFormModule documentInitialValue={file} isReadOnly />
        </div>
    );
};
