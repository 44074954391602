import React, { FC, useContext, useMemo } from "react";
import { ContactsContext } from "../../../../../scripts/context/ContactsContext";
import { ContactSelect } from "@app/components/shared/form/baseComponents/ContactSelect/ContactSelect";
import { Contacts, GQL } from "@binale-tech/shared";
import { useCanWrite } from "../../../../../scripts/infrastructure/hooks";
import { InvoiceFormInputTranslate } from "@inv/types";
import { useIntl } from "react-intl";

interface InvoiceContactProps {
    value?: string;
    onChange?: (value: string) => void;
}

export const InvoiceContact: FC<InvoiceContactProps> = ({ value, onChange }) => {
    const { contactsMap } = useContext(ContactsContext);
    const intl = useIntl();
    const hasContactsWriteAccess = useCanWrite(GQL.IProductKey.Contacts);

    const selectedContact = useMemo(() => {
        const contact = contactsMap.get(value);

        return contact
            ? {
                  id: contact.uuid,
                  name: Contacts.getLabelName(contact),
              }
            : null;
    }, [contactsMap, value]);

    return (
        <ContactSelect
            value={selectedContact}
            onChange={v => onChange(v ? v.id : undefined)}
            placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.CONTACT })}
            disabled={!hasContactsWriteAccess}
        />
    );
};
