import { DmsAccountingConverter } from "./DmsAccountingConverter";
import { useContext, useMemo } from "react";
import { BuContext } from "../../context/BuContext";
import { CompanyContext } from "../../context/CompanyContext";
import { ContactsContext } from "../../context/ContactsContext";
import { RecordsControlContext } from "../../context/recordsContext/RecordsControlCtx";
import { ProductFactory } from "../../core/ProductFactory";
import { GQL } from "@binale-tech/shared";
import { DmsAppContext } from "@dms/types/ContextTypes";

export const useDmsAccountingConverter = (productKey: GQL.IProductKey, groupId?: string) => {
    const { companyGQL, yearConfig } = useContext(CompanyContext);
    const { contacts } = useContext(ContactsContext);
    const { companyBuTimeframes } = useContext(BuContext);
    const recordActions = useContext(RecordsControlContext);
    const { templates } = useContext(DmsAppContext);

    return useMemo(() => {
        const config = ProductFactory.getAccountingProductConfig(
            productKey,
            yearConfig,
            companyGQL,
            recordActions,
            groupId
        );
        if (!config) {
            return { converter: undefined, actions: undefined };
        }
        const { product, actions } = config;
        const converter = new DmsAccountingConverter(
            companyGQL,
            product,
            contacts,
            templates,
            yearConfig.skr,
            companyBuTimeframes,
            groupId
        );
        return { converter, actions };
    }, [companyBuTimeframes, companyGQL, contacts, groupId, productKey, recordActions, templates, yearConfig]);
};
