import { useContext, useMemo } from "react";
import { GQL } from "@binale-tech/shared";
import { CompanyContext } from "../../scripts/context/CompanyContext";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { DmsType, IDocumentType } from "@dms/types";

const getList = <T extends Pick<GQL.ICompanyKasse, "id" | "year">>(
    documentTypes: IDocumentType[],
    dmsType: DmsType.KB | DmsType.Bank,
    groupList: T[]
) => {
    let bindingList: T[] = [];
    const groupIds = documentTypes
        .find(el => el.id === dmsType)
        ?.subTypes.map(el => (dmsType === DmsType.KB ? el.kbId : el.bankId));

    groupList?.forEach(el => {
        if (groupIds && groupIds.includes(el.id)) {
            return;
        }

        const foundGroupEl = bindingList.find(i => i.id === el.id);
        if (!foundGroupEl) {
            bindingList.push(el);
        } else if (foundGroupEl.year <= el.year) {
            bindingList = bindingList.filter(i => i.id !== el.id);
            bindingList.push(el);
        }
    });
    return bindingList;
};

export const useBindData = () => {
    const { documentTypes } = useContext(DmsDataContext);
    const { companyGQL } = useContext(CompanyContext);
    return useMemo(() => {
        const data: { kbList: GQL.ICompanyKasse[]; bankList: GQL.ICompanyBank[] } = {
            kbList: getList(documentTypes, DmsType.KB, companyGQL?.kasseList ?? []),
            bankList: getList(documentTypes, DmsType.Bank, companyGQL?.bankList ?? []),
        };
        return data;
    }, [companyGQL?.bankList, companyGQL?.kasseList, documentTypes]);
};
