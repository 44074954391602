import { Form, Typography } from "antd";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";

import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";
import { IntInput } from "../DocumentInputs/IntInput";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";

const { Text } = Typography;

export const SingleDiscountDaysField = () => {
    const { formatMessage } = useIntl();
    const { isReadOnly } = useContext(SingleFormContext);
    return (
        <FieldLabel
            className={"DMSForm__Item"}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id="app.fields.discountDays" />
                </Text>
            }
            name={"discountDays"}
        >
            <IntInput
                type="integer"
                placeholder={formatMessage({ id: "app.fields.discountDays" })}
                disabled={isReadOnly}
            />
        </FieldLabel>
    );
};

const GroupDiscountDaysField: FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { notEqualField, isCancel, requiredFields } = useContext(GroupFormContext);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    const placeholder = notEqualField?.includes("discountDays") ? "Diverse" : undefined;

    return (
        <>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"discountDaysCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox parentFieldName={"discountDays"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                className={"DMSForm__Item"}
                label={
                    <Text strong ellipsis>
                        <FormattedMessage id="app.fields.discountDays" />
                    </Text>
                }
                rules={[{ required: requiredFields?.includes("discountDays"), message: "" }]}
                name={"discountDays"}
            >
                <IntInput type="integer" placeholder={placeholder} disabled={isDisabled} />
            </FieldLabel>
        </>
    );
};

export const DocumentDiscountDaysField: FC = () => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return <>{isGroupEditor ? <GroupDiscountDaysField /> : <SingleDiscountDaysField />}</>;
};
