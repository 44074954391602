import React, { FC, useCallback, useContext, useMemo } from "react";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import { gridOptions } from "@inv/modules/InvocesListModule/config/gridOptions";
import { GridApi, GridReadyEvent, RowClassRules, RowDataUpdatedEvent, SortChangedEvent } from "ag-grid-community";
import { invoicesListTotal } from "@inv/scripts/utils/utils";
import { useColumnConfig } from "@inv/modules/InvocesListModule/hooks/useColumnConfig";
import { ActionList } from "@inv/components/ActionList";
import { ActionControlColumns } from "./components/ActionControlColumns";
import { RateComponent } from "@inv/components/InvoicesList/components/RateComponent";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import Container from "@app/components/shared/appearance/page/Container";
import { RateEditor } from "@inv/modules/InvocesListModule/editors";
import { InvoicesListColumn, TInvoiceTableItem } from "@inv/types";

import styles from "./InvoicesList.module.scss";

export const InvoicesList: FC = () => {
    const { invoicesTransformedTableList, expandedRows } = useContext(InvoicesDataContext);
    const columns = useColumnConfig();

    const removedChildRow = (api: GridApi) => {
        api.forEachNode(node => {
            if (node.data.lineItemsList.length > 1 && node.data.isExpanded) {
                node.setData({ ...node.data, isExpanded: false });
            }
            if (node.data.isChildRow) {
                api.applyTransaction({ remove: [node.data] });
            }
        });
    };

    const updatePinnedBottomRowData = (api: GridApi, rowData: TInvoiceTableItem[]) => {
        const { amountNetto, vat, amountBrutto } = invoicesListTotal(rowData);
        api.updateGridOptions({
            pinnedBottomRowData: [{ amountNetto, vat, amountBrutto }],
        });
    };

    const onGridReady = useCallback(({ api }: GridReadyEvent) => {
        const rowData: TInvoiceTableItem[] = api.getRenderedNodes().map(node => node.data);
        updatePinnedBottomRowData(api, rowData);
        api.applyColumnState({
            state: [{ colId: InvoicesListColumn.INVOICE_DATE, sort: "desc" }],
            defaultState: { sort: null },
        });
    }, []);

    const onRowDataUpdated = useCallback(
        ({ api }: RowDataUpdatedEvent) => {
            const rowData: TInvoiceTableItem[] = api.getRenderedNodes().map(node => node.data);
            updatePinnedBottomRowData(api, rowData);
        },
        [expandedRows]
    );

    const onSortChanged = useCallback(({ api }: SortChangedEvent) => {
        api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: [InvoicesListColumn.INVOICE_ROW_NUMBER],
        });
        removedChildRow(api);
    }, []);

    const rowClassRules = useMemo<RowClassRules<TInvoiceTableItem>>(
        () => ({
            [styles.agTotalRow]: params => !!params.node.rowPinned,
            [styles.agChildRow]: params => params.data.isChildRow,
        }),
        []
    );

    const components = {
        rateEditor: RateEditor,
        rateComponent: RateComponent,
        actionControlColumns: ActionControlColumns,
        actionList: ActionList,
    };

    return (
        <Container absolute flex>
            {(w, h) => (
                <AgGridTable
                    columnDefs={columns}
                    gridOptions={gridOptions}
                    rowData={invoicesTransformedTableList}
                    onRowDataUpdated={onRowDataUpdated}
                    onGridReady={onGridReady}
                    rowClassRules={rowClassRules}
                    components={components}
                    wrapperStyles={{ height: h }}
                    getRowId={params => {
                        // console.log(params.data);
                        return params.data.id;
                    }}
                    onSortChanged={onSortChanged}
                />
            )}
        </Container>
    );
};
