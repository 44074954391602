import React from "react";
import { Utils } from "@binale-tech/shared";

export class SollHabenPrintHelper {
    // Rule: soll - haben
    protected static getSH = (n: number) => {
        if (n === 0) {
            return null;
        }
        return n > 0 ? "S" : "H";
    };
    /**
     * printSH prints negative as Haben, positive as Soll
     * @param {number} n
     * @returns {any}
     */
    static printSH(n: number): React.ReactNode {
        const sh = this.getSH(n);
        return (
            <span>
                {Utils.CurrencyUtils.currencyFormat(Math.abs(n))}{" "}
                <code style={{ opacity: sh === null ? 0 : undefined }}>{sh}</code>
            </span>
        );
    }
    static printSHText(n: number): React.ReactNode {
        const sh = this.getSH(n);
        return Utils.CurrencyUtils.currencyFormat(Math.abs(n)) + (sh ? " " + sh : "");
    }
}
