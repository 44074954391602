import React, { createContext, FC, ReactNode, useMemo, useState } from "react";
import { TInvoiceFormLineItem, TInvoicesValues } from "@inv/types";
import { GQL } from "@binale-tech/shared";

type TValue = {
    initRow: TInvoiceFormLineItem;
    invoiceTotalView: boolean;
    initialValues: TInvoicesValues;
};

type TActionValue = {
    setInvoiceTotalView: (value: boolean) => void;
    setInitialValues: (value: TInvoicesValues) => void;
};

const initialValue: TValue = {
    invoiceTotalView: true,
    initRow: {
        productsServices: "",
        quantity: 1,
        price: 0,
        tax: 0,
        discount: 0,
    },
    initialValues: {
        id: null,
        contactId: null,
        countryCode: null,
        city: null,
        address: null,
        zipCode: null,
        street: null,
        invoiceNumber: null,
        customerNumber: null,
        date: null,
        documentTitle: null,
        introductionText: null,
        deliveryDate: null,
        serviceDate: null,
        servicePeriodDays: null,
        servicePeriodMonths: null,
        currencyCode: GQL.ICurrencyCode.Eur,
        isTaxIncluded: true,
        lineItems: [],
        paymentTerm: null,
        description: null,
    },
};

export const InvoiceFormContext = createContext<TValue>(initialValue);
export const InvoiceFormControlContext = createContext<TActionValue>({
    setInvoiceTotalView: () => {},
    setInitialValues: () => {},
});

type TProps = {
    children?: ReactNode;
};

export const InvoiceFormProvider: FC<TProps> = ({ children }) => {
    const [invoiceTotalView, setInvoiceTotalView] = useState<boolean>(initialValue.invoiceTotalView);
    const [initialValues, setInitialValues] = useState<TInvoicesValues>({
        ...initialValue.initialValues,
        lineItems: [{ ...initialValue.initRow }],
    });

    const value: React.ContextType<typeof InvoiceFormContext> = useMemo(
        () => ({ invoiceTotalView, initialValues, initRow: { ...initialValue.initRow } }),
        [initialValues, invoiceTotalView]
    );
    const actions: React.ContextType<typeof InvoiceFormControlContext> = useMemo(
        () => ({
            setInvoiceTotalView,
            setInitialValues,
        }),
        []
    );

    return (
        <InvoiceFormContext.Provider value={value}>
            <InvoiceFormControlContext.Provider value={actions}>{children}</InvoiceFormControlContext.Provider>
        </InvoiceFormContext.Provider>
    );
};
