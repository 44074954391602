import { blue, gray, orange } from "@ant-design/colors";
import { GQL } from "@binale-tech/shared";
import { Card, Tooltip } from "antd";
import cn from "classnames";
import React, { type FC, useContext, useEffect, useState } from "react";
import { IDocumentEnriched } from "@dms/types";

import { DmsAppContext } from "@dms/types/ContextTypes";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

import { Drag } from "../DragAndDrop/Drag";
import { DocumentCardTitle } from "./DocumentCardTitle";
import styles from "./documetCard.module.scss";
import { ModalPdfViewer } from "@dms/components/ModalPdfViewer/ModalPdfViewer";

const documentStatusColor: Record<GQL.IDocumentStatus, string> = {
    ready: "rgba(0, 0, 0, 0.4)",
    draft: orange[5],
    new: blue[5],
    trash: gray[5],
};

const selectedDocumentStatusColor: Record<GQL.IDocumentStatus, string> = {
    ready: "#000",
    draft: orange[6],
    new: blue[6],
    trash: gray[6],
};
interface IProps {
    document: IDocumentEnriched;
    index: number;
}

export const DocumentCard: FC<IProps> = ({ document, index }) => {
    const [previewUrl, setPreviewUrl] = useState<string>();
    const [viewerDocument, setViewerDocument] = useState<GQL.IDocument>();
    const { selectedRowKeys } = useContext(DmsAppContext);
    const processedStatus = DmsUtils.getDocumentStatus(document);

    const { documentNumber, documentDate } = document;
    const isSelected = selectedRowKeys.includes(document.key);
    const switchColor = (() => {
        switch (processedStatus) {
            case GQL.IDocumentStatus.Draft:
                return isSelected ? selectedDocumentStatusColor.draft : documentStatusColor.draft;
            case GQL.IDocumentStatus.New:
                return isSelected ? selectedDocumentStatusColor.new : documentStatusColor.new;
            case GQL.IDocumentStatus.Ready:
                return isSelected ? selectedDocumentStatusColor.ready : documentStatusColor.ready;
            default:
                return isSelected ? selectedDocumentStatusColor.trash : documentStatusColor.trash;
        }
    })();

    const border = isSelected ? `2px solid ${switchColor}` : `1px solid ${switchColor}`;

    useEffect(() => {
        DmsUtils.getPreviewUrl(document).then(r => setPreviewUrl(r));
    }, [document]);

    return (
        <Drag id={document.key} typeDrag={"grid"}>
            <Card
                title={<DocumentCardTitle document={document} index={index} />}
                hoverable
                className={styles.card}
                style={{ border }}
                styles={{
                    header: {
                        padding: "0",
                        borderBottom: border,
                        minHeight: 35,
                        width: "100%",
                    },
                    body: {
                        width: "100%",
                        padding: "10px",
                        backgroundColor: processedStatus !== GQL.IDocumentStatus.Ready ? switchColor : "#fff",
                        borderTop: border,
                        borderRadius: "0 0 6px 6px",
                    },
                }}
                cover={
                    <div className={styles["card-body"]}>
                        <div
                            className={styles.card__content}
                            style={{ backgroundImage: `url(${previewUrl})` }}
                            onClick={() => setViewerDocument(document)}
                        ></div>
                    </div>
                }
                bordered={false}
            >
                <Card.Meta
                    description={
                        <div className={styles["card-footer"]}>
                            <div className={styles["card-footer-info"]}>
                                {documentNumber ? (
                                    <Tooltip title={documentNumber} placement="topLeft">
                                        <span
                                            className={cn({
                                                [styles["card-footer__text"]]: true,
                                                [styles["card-footer__number"]]: true,
                                                [styles["card-footer__text--ready"]]:
                                                    processedStatus === GQL.IDocumentStatus.Ready,
                                            })}
                                        >
                                            {documentNumber}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <span
                                        className={cn({
                                            [styles["card-footer__text"]]: true,
                                            [styles["card-footer__text--ready"]]:
                                                processedStatus === GQL.IDocumentStatus.Ready,
                                        })}
                                    >
                                        -----
                                    </span>
                                )}

                                <span
                                    className={cn({
                                        [styles["card-footer__text"]]: true,
                                        [styles["card-footer__text--ready"]]:
                                            processedStatus === GQL.IDocumentStatus.Ready,
                                    })}
                                >
                                    {documentDate ? documentDate : "--"}
                                </span>
                            </div>
                        </div>
                    }
                />
                <ModalPdfViewer document={viewerDocument} onClose={() => setViewerDocument(undefined)} />
            </Card>
        </Drag>
    );
};
