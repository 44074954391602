import { InputProps, InputRef } from "antd";
import React, { FC, forwardRef } from "react";
import { BaseInput } from "@app/components/shared/form/baseComponents";

interface IProps extends Omit<InputProps, "onChange" | "value"> {
    onChange?: (v: string) => void;
    value?: string;
}
export const UppercaseAndNumberInput: FC<IProps> = forwardRef<InputRef, IProps>(({ onChange, ...restProps }, ref) => {
    const handleOnChange = (arg: string) => {
        let val = arg;
        val = val.replace(/[^A-Za-z0-9]/g, "");
        onChange?.(val);
    };

    const handleOnBlur = (arg: string) => {
        let val = arg;
        val = val.replace(/\w/g, c => c.toUpperCase());
        if (val.length === 8) {
            val = val + "XXX";
        }
        onChange?.(val);
    };

    return <BaseInput {...restProps} onChange={handleOnChange} onBlur={el => handleOnBlur(el.target.value)} />;
});
