import React, { FC, useState } from "react";
import {
    AlignCenterOutlined,
    MergeCellsOutlined,
    PicCenterOutlined,
    QrcodeOutlined,
    SlidersOutlined
} from "@ant-design/icons";
import { Button, message } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { FlexColumn } from "../../../appearance/components/shared/appearance/page";
import { SplitMode } from "../../types";
import { logger } from "../../../scripts/infrastructure/logger";
import { useIsMounted } from "../../../scripts/infrastructure/hooks";

interface IProps {
    onDecode: (mode: SplitMode) => Promise<void>;
}

export const SplitModeButtons: FC<IProps> = ({ onDecode }) => {
    const [loading, setLoading] = useState(false);
    const intl = useIntl();
    const isMounted = useIsMounted();

    const decodePdf = async (mode: SplitMode) => {
        setLoading(true);
        try {
            await onDecode(mode);
        } catch (e) {
            logger.error("error: ", e);
            if (e.name === "PasswordException") {
                intl.formatMessage({ id: "app.ocr.error.encrypted" });
                message.error(intl.formatMessage({ id: "app.ocr.error.encrypted" }));
            } else {
                message.error(intl.formatMessage({ id: "app.global.error" }) + e.message || e || "Unknown error");
            }
        }
        if (isMounted()) {
            setLoading(false);
        }
    };

    return (
        <FlexColumn style={{ marginTop: 16 }}>
            <Button
                size="large"
                type="primary"
                shape="round"
                disabled={loading}
                icon={<MergeCellsOutlined />}
                style={{ marginBottom: 8 }}
                onClick={() => decodePdf(SplitMode.put)}
                data-testid={SplitMode.put}
            >
                <FormattedMessage id="app.ocr.mode.put" tagName="span" />
            </Button>
            <Button
                size="large"
                type="primary"
                shape="round"
                disabled={loading}
                icon={<QrcodeOutlined />}
                style={{ marginBottom: 8 }}
                onClick={() => decodePdf(SplitMode.qr)}
                data-testid={SplitMode.qr}
            >
                <FormattedMessage id="app.ocr.mode.qr" tagName="span" />
            </Button>
            <Button
                size="large"
                type="primary"
                shape="round"
                disabled={loading}
                icon={<AlignCenterOutlined />}
                style={{ marginBottom: 8 }}
                onClick={() => decodePdf(SplitMode.every)}
                data-testid={SplitMode.every}
            >
                <FormattedMessage id="app.ocr.mode.every" tagName="span" />
            </Button>
            <Button
                size="large"
                type="primary"
                shape="round"
                disabled={loading}
                icon={<PicCenterOutlined />}
                style={{ marginBottom: 8 }}
                onClick={() => decodePdf(SplitMode.second)}
                data-testid={SplitMode.second}
            >
                <FormattedMessage id="app.ocr.mode.second" tagName="span" />
            </Button>
            <Button
                size="large"
                type="primary"
                shape="round"
                disabled={loading}
                icon={<SlidersOutlined />}
                onClick={() => decodePdf(SplitMode.manual)}
                data-testid={SplitMode.manual}
            >
                <FormattedMessage id="app.ocr.mode.manual" tagName="span" />
            </Button>
        </FlexColumn>
    );
};
