import React, { createContext, MutableRefObject, useContext, useRef, useState } from "react";
import { FormWrapperContext } from "./FormWrapperContext";
import { IDocumentEnriched } from "@dms/types";

interface TValue {
    checkedAll: boolean;
    indeterminate: boolean;
    containerRef: MutableRefObject<HTMLDivElement>;
    handleCheckedItems: (doc: IDocumentEnriched[]) => void;
    handleCheckedAll: (checked: boolean, doc: IDocumentEnriched[]) => void;
}

export const CarouselContext = createContext({} as TValue);

export const CarouselProvider = (props: { children: React.ReactNode | null | undefined }) => {
    const [checkedAll, setCheckedAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const { checkboxCarousel, setCheckboxCarousel } = useContext(FormWrapperContext);

    const handleCheckedItems = (doc: IDocumentEnriched[]) => {
        setIndeterminate(!(checkboxCarousel.length === 0) && checkboxCarousel.length < doc.length);
        setCheckedAll(checkboxCarousel.length === doc.length);
    };

    const handleCheckedAll = (checked: boolean, doc: IDocumentEnriched[]) => {
        setCheckboxCarousel(checked ? doc.map(el => el.key) : []);
        setCheckedAll(checked);
        setIndeterminate(false);
    };

    const value: TValue = {
        checkedAll,
        indeterminate,
        containerRef,
        handleCheckedItems,
        handleCheckedAll,
    };

    return <CarouselContext.Provider value={value}>{props.children}</CarouselContext.Provider>;
};
