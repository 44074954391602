import dayjs from "dayjs";

const compareStrings = (a: string, b: string) => {
    const stringA = a ? String(a) : "";
    const stringB = b ? String(b) : "";

    if (stringA && stringB) {
        return stringA.localeCompare(stringB);
    }
    return (stringB ? stringB : " ").localeCompare(stringA ? stringA : " ");
};

const compareDates = (a: string, b: string, format: string) => {
    if (a && b) {
        return dayjs(a, format).unix() - dayjs(b, format).unix();
    }
    return (b ? b : " ").localeCompare(a ? a : " ");
};

const compareNumbers = (a: number, b: number) => {
    const aNum = a ? parseFloat(a.toString().replace(/[.,]/g, "")) : null;
    const bNum = b ? parseFloat(b.toString().replace(/[.,]/g, "")) : null;

    return bNum - aNum;
};

const compareBooleans = (a: boolean, b: boolean) => {
    const aAttached = a ? 1 : 0;
    const bAttached = b ? 1 : 0;
    return bAttached - aAttached;
};

type TSorterArg = string | number | boolean;
type TSorterType = "string" | "date" | "dateTime" | "number" | "boolean";

export const documentSorter = (a: TSorterArg, b: TSorterArg, type: TSorterType) => {
    switch (type) {
        case "string":
            return compareStrings(a as string, b as string);

        case "date":
            return compareDates(a as string, b as string, "DD.MM.YYYY");

        case "dateTime":
            return compareDates(a as string, b as string, "DD.MM.YYYY HH:mm");

        case "number":
            return compareNumbers(a as number, b as number);

        case "boolean":
            return compareBooleans(a as boolean, b as boolean);

        default:
            return 0;
    }
};
