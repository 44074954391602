export const styleConst = {
    layoutPadding: "20px",
    bankListColSpan: {
        xxl: 16,
        xl: 14,
        lg: 14,
        md: 12,
        xs: 24,
        sm: 24,
    },
    banksStatisticSpan: {
        xxl: 8,
        xl: 10,
        lg: 10,
        md: 12,
        xs: 24,
        sm: 24,
    },
};
