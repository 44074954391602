export const calculateHashArrayBuffer = async (data: Uint8Array | ArrayBuffer): Promise<string | ""> => {
    return crypto.subtle
        .digest("SHA-256", data)
        .then(hash => {
            const hashArray = Array.from(new Uint8Array(hash));
            return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
        })
        .catch(error => {
            console.error(error);
            return "";
        });
};
export const hashCalculate = async (file: File): Promise<string | ""> => {
    return calculateHashArrayBuffer(await file.arrayBuffer());
};
