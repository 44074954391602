import AnsichtView from "../../../views/productSharedComponents/AnsichtView";
import Menu, { MenuProps } from "antd/es/menu";
import React from "react";
import { AppRoutes } from "scripts/routing/routeConstants";
import { DownOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { Link, useLocation } from "react-router-dom";
import { Modal } from "antd";

type Props = { menuProps: MenuProps };
export const ModuleDebLinks: React.FC<Props> = ({ menuProps }) => {
    const { pathname = "" } = useLocation();
    const [showSettings, setSettings] = React.useState(false);
    return (
        <>
            <Menu
                selectedKeys={[pathname]}
                {...menuProps}
                items={[
                    {
                        key: AppRoutes.debitor,
                        label: (
                            <Link to={AppRoutes.debitor}>
                                <FormattedMessage id="app.titles.Deb" />
                            </Link>
                        ),
                    },
                    {
                        key: "Tools",
                        label: (
                            <span>
                                <FormattedMessage id="app.titles.ER.auswertung" /> <DownOutlined />
                            </span>
                        ),
                        children: [
                            {
                                key: AppRoutes.debitorAnalysisOpos,
                                label: <Link to={AppRoutes.debitorAnalysisOpos}>OPOS</Link>,
                            },
                            {
                                key: AppRoutes.debitorAnalysisAvis,
                                label: (
                                    <Link to={AppRoutes.debitorAnalysisAvis}>
                                        <FormattedMessage id="app.titles.ER.za" />
                                    </Link>
                                ),
                            },
                        ],
                    },
                    {
                        key: AppRoutes.debitorTemplates,
                        label: (
                            <Link to={AppRoutes.debitorTemplates}>
                                <FormattedMessage id="app.titles.templates" />
                            </Link>
                        ),
                    },
                    {
                        key: "settings",
                        onClick: () => setSettings(true),
                        label: <FormattedMessage id="app.titles.settings" />,
                    },
                ]}
            />
            <Modal
                open={showSettings}
                onCancel={() => setSettings(false)}
                footer={false}
                width={900}
                focusTriggerAfterClose={false}
            >
                <AnsichtView pk={GQL.IProductKey.Deb} />
            </Modal>
        </>
    );
};
