import { DmsType, MovementType } from "@dms/types";

export const getMovementType = (amount: number | undefined, type: DmsType | undefined) => {
    if (!amount || !type) {
        return MovementType.ZERO;
    }
    if (type === DmsType.ER) {
        return amount > 0 ? MovementType.SPENDING : MovementType.RECEIPT;
    }
    return amount < 0 ? MovementType.SPENDING : MovementType.RECEIPT;
};
