import React, { CSSProperties, FC } from "react";
import {
    BorderOutlined,
    CheckCircleOutlined,
    CheckSquareOutlined,
    CloseCircleOutlined,
    CopyOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    PlusOutlined,
    RedoOutlined,
    ScissorOutlined
} from "@ant-design/icons";
import { Button, Progress, Typography } from "antd";
import { FormattedMessage } from "react-intl";

interface IProps {
    checkAll: () => void;
    deleteEmptyPages: () => void;
    hidingProgress: number;
    showDeletedPages: boolean;
    toggleDeletedPages: (arg: boolean) => void;
    deleteCheckedPages: () => void;
    restoreCheckedPages: () => void;
    checkedIds: string[];
    splitCheckedDocs: () => void;
    rotateCheckedPages: () => void;
    unifyCheckedDocs: () => void;
    uncheckAll: () => void;
    middleHeight: boolean;
}

const { Text } = Typography;

export const ActionButtonsList: FC<IProps> = ({
    checkAll,
    deleteEmptyPages,
    hidingProgress,
    showDeletedPages,
    toggleDeletedPages,
    deleteCheckedPages,
    restoreCheckedPages,
    checkedIds,
    splitCheckedDocs,
    rotateCheckedPages,
    unifyCheckedDocs,
    uncheckAll,
    middleHeight,
}) => {
    const btnStyle = {
        display: "inline-flex",
        height: "auto",
        alignItems: "center",
        minHeight: 27,
        justifyContent: "flex-start",
        marginBottom: "10px",
        textAlign: "left",
        whiteSpace: "normal",
    } as CSSProperties;

    return (
        <div className="FlexColumn pdf-tools-actions">
            <FormattedMessage id="app.ocr.allPages" tagName="h3" />

            <Button
                size={middleHeight ? "small" : "middle"}
                style={{ ...btnStyle }}
                onClick={checkAll}
                icon={<CheckSquareOutlined />}
            >
                <Text ellipsis>
                    <FormattedMessage id="app.ocr.button.checkAll" />
                </Text>
            </Button>
            <Button
                size={middleHeight ? "small" : "middle"}
                style={{ ...btnStyle }}
                onClick={deleteEmptyPages}
                icon={<CopyOutlined />}
            >
                {hidingProgress > 0 && <Progress percent={hidingProgress || 0} type="circle" width={20} />}
                <Text ellipsis>
                    <FormattedMessage id="app.ocr.button.deleteEmptyPages" />
                </Text>
            </Button>
            {showDeletedPages && (
                <Button
                    size={middleHeight ? "small" : "middle"}
                    style={{ ...btnStyle }}
                    onClick={() => toggleDeletedPages(false)}
                    icon={<EyeInvisibleOutlined />}
                >
                    <Text ellipsis>
                        <FormattedMessage id="app.ocr.button.hideDeletedPages" />
                    </Text>
                </Button>
            )}
            {!showDeletedPages && (
                <Button
                    size={middleHeight ? "small" : "middle"}
                    style={{ ...btnStyle }}
                    onClick={() => toggleDeletedPages(true)}
                    icon={<EyeOutlined />}
                >
                    <Text ellipsis>
                        <FormattedMessage id="app.ocr.button.showDeletedPages" />
                    </Text>
                </Button>
            )}

            <FormattedMessage id="app.ocr.selectedPages" tagName="h3" />
            <Button
                onClick={deleteCheckedPages}
                size={middleHeight ? "small" : "middle"}
                style={{ ...btnStyle }}
                disabled={!checkedIds.length}
                icon={<CloseCircleOutlined />}
            >
                <Text ellipsis>
                    <FormattedMessage id="app.ocr.button.deletePage" />
                </Text>
            </Button>
            <Button
                onClick={restoreCheckedPages}
                size={middleHeight ? "small" : "middle"}
                style={{ ...btnStyle }}
                disabled={!checkedIds.length}
                icon={<CheckCircleOutlined />}
            >
                <Text ellipsis>
                    <FormattedMessage id="app.ocr.button.restorePage" />
                </Text>
            </Button>
            <Button
                onClick={rotateCheckedPages}
                size={middleHeight ? "small" : "middle"}
                style={{ ...btnStyle }}
                disabled={!checkedIds.length}
                icon={<RedoOutlined />}
            >
                <Text ellipsis>
                    <FormattedMessage id="app.ocr.button.rotatePage" />
                    <span style={{ marginLeft: "5px" }}>90°</span>
                </Text>
            </Button>
            <Button
                onClick={splitCheckedDocs}
                size={middleHeight ? "small" : "middle"}
                style={{ ...btnStyle }}
                disabled={!checkedIds.length}
                icon={<ScissorOutlined />}
            >
                <Text ellipsis>
                    <FormattedMessage id="app.ocr.button.splitDocs" />
                </Text>
            </Button>

            <Button
                onClick={unifyCheckedDocs}
                size={middleHeight ? "small" : "middle"}
                style={{ ...btnStyle }}
                disabled={!checkedIds.length}
                icon={<PlusOutlined />}
            >
                <Text ellipsis>
                    <FormattedMessage id="app.ocr.button.unifyDocs" />
                </Text>
            </Button>
            <Button
                onClick={uncheckAll}
                size={middleHeight ? "small" : "middle"}
                style={{ ...btnStyle }}
                disabled={!checkedIds.length}
                icon={<BorderOutlined />}
            >
                <Text ellipsis>
                    <FormattedMessage id="app.ocr.button.uncheckAll" />
                </Text>
            </Button>
            <br />
        </div>
    );
};
