import { Col, Row } from "antd";
import React, { useContext, useEffect, useRef } from "react";
import { styleConst } from "./style";
import { BankCard } from "../BankCard";
import { BankListContext } from "banks/modules/BankListModule/context";
import { BanksAppContext, BanksAppControlContext } from "@banks/scripts/context";

const { colSpan } = styleConst;

export const BankListContent = () => {
    const { activeBankId } = useContext(BanksAppContext);
    const { setActiveBankId } = useContext(BanksAppControlContext);

    const { banks } = useContext(BankListContext);

    const ref = useRef(null);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (!ref.current || !ref.current.contains(event.target)) {
                return setActiveBankId(null);
            }

            const el = (event.target as Element).closest("[data-parent]");
            if (!(el instanceof HTMLElement)) {
                return setActiveBankId(null);
            }

            if (el.dataset.parent !== "banks") {
                return setActiveBankId(null);
            }
        };

        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <Row gutter={[30, 16]} justify="start" wrap={true} ref={ref}>
            {banks &&
                banks.map(bank => {
                    const { id } = bank;
                    return (
                        <Col key={id} {...colSpan}>
                            <BankCard
                                isActive={id === activeBankId}
                                bank={bank}
                                onClick={() => {
                                    setActiveBankId(id);
                                }}
                            />
                        </Col>
                    );
                })}
        </Row>
    );
};
