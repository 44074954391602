import React, {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { child, DataSnapshot } from "firebase/database";
import { TInvoicesListColumnsConfig, TInvoiceTableItem } from "@inv/types";
import { searchInAllFields, transformServicePeriod } from "@inv/scripts/utils/utils";
import { InvoicesListTableColumns } from "@inv/modules/InvocesListModule/config/tableColumns";
import { InvoicesApi } from "@inv/scripts/api";
import { CompanyContext } from "../../scripts/context/CompanyContext";
import { useGqlMutator } from "../../scripts/graphql/useGqlMutator";
import { useCollectionListener } from "../../scripts/context/hooks/useCollectionListener";
import { refInvoices } from "../../scripts/api/firebase/firebaseRootRefs";
import { UserContext } from "../../scripts/context/UserProvider";
import { ContactsContext } from "../../scripts/context/ContactsContext";
import { Contacts, GQL } from "@binale-tech/shared";
import { Contact } from "@binale-tech/shared/lib/contacts";

type TValue = {
    invoicesTransformedTableList?: Partial<TInvoiceTableItem>[];
    invoicesMap: Map<string, GQL.IInvoice>;
    invoiceListColumnsConfig: TInvoicesListColumnsConfig;
    isOpenSettingsColumns: boolean;
    searchValue: string;
    expandedRows: Set<string>;
};

type TActionValue = {
    setColumnConfig: (newValue: TInvoicesListColumnsConfig) => void;
    setViewConfig: (newValue: boolean) => void;
    setSearchValue: (newValue: string) => void;
    setExpandedRows: Dispatch<SetStateAction<Set<string>>>;
};

const initialValue: TValue = {
    invoiceListColumnsConfig: InvoicesListTableColumns.invoicesListInitColumnsConfig,
    isOpenSettingsColumns: false,
    expandedRows: new Set<string>(),
    searchValue: "",
    invoicesMap: new Map(),
};

const initialActionValue = {
    setColumnConfig: () => {},
    setViewConfig: () => {},
    setSearchValue: () => {},
    setExpandedRows: () => {},
};

export const InvoicesDataContext = createContext<TValue>(initialValue);
export const InvoicesListControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const InvoicesDataProvider: FC<TProps> = ({ children }) => {
    const { companyGQL } = useContext(CompanyContext);
    const { fireUser } = useContext(UserContext);
    const { contacts } = useContext(ContactsContext);

    const companyId = companyGQL?.id;
    const mutator = useGqlMutator();

    useEffect(() => {
        InvoicesApi.mutator = mutator;
        InvoicesApi.companyId = companyId;
    }, [companyId, mutator]);

    const [invoiceListColumnsConfig, setInvoiceListColumnsConfig] = useState<TInvoicesListColumnsConfig>(
        initialValue.invoiceListColumnsConfig
    );
    const [isOpenSettingsColumns, setIsOpenSettingsColumns] = useState<boolean>(initialValue.isOpenSettingsColumns);
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
    const [searchValue, setSearchValue] = useState<string>("");

    const shouldSkipLoad = useMemo(() => !companyId, [companyId]);
    const ref = useMemo(() => child(refInvoices, companyId), [companyId]);
    const initializer = useCallback((snap: DataSnapshot) => snap.val(), []);

    const { list: invoicesList, state: invoicesMap } = useCollectionListener(ref, initializer, shouldSkipLoad);

    const transformInvoicesData = useCallback(
        (invoices: GQL.IInvoice[], contacts: Contact[]): TInvoiceTableItem[] =>
            invoices.map(invoice => {
                const { lineItems, isTaxIncluded } = invoice;

                const contact = contacts.find(c => invoice?.contactId === c.uuid);
                const contactName = contact ? Contacts.getLabelName(contact) : null;

                const lineItemsTotal = (lineItems || []).reduce(
                    (totals, item, index) => {
                        const originalAmount = item.quantity * item.discount;

                        if (isTaxIncluded) {
                            const tax = item.tax / 100;
                            totals.generalTax += originalAmount * tax;
                            totals.generalTaxRate =
                                index === 0
                                    ? item.tax.toString()
                                    : totals.generalTaxRate !== item.tax.toString()
                                      ? "Diverse"
                                      : totals.generalTaxRate;
                        } else {
                            totals.generalTax = null;
                        }

                        totals.originalAmount += originalAmount;
                        return totals;
                    },
                    {
                        originalAmount: 0,
                        generalTax: 0,
                        generalTaxRate: "",
                    }
                );

                const servicePeriodDaysValue = invoice?.servicePeriodDays
                    ? transformServicePeriod(invoice?.servicePeriodDays)
                    : null;
                const servicePeriodMonthsValue = invoice?.servicePeriodMonths
                    ? transformServicePeriod(invoice?.servicePeriodMonths)
                    : null;

                return {
                    id: invoice.id,
                    invoiceDate: invoice?.date,
                    invoiceNumber: "DRAFT",
                    serviceDescription: lineItems.length > 1 ? "Diverse" : lineItems[0].productsServices,
                    contact: contactName,
                    currency: invoice?.currencyCode,
                    project: "N/A",
                    isChildRow: false,
                    serviceDate:
                        invoice?.serviceDate ??
                        invoice?.deliveryDate ??
                        servicePeriodDaysValue ??
                        servicePeriodMonthsValue,
                    currencyRate: invoice.currencyRate,
                    isTaxIncluded,
                    isExpanded: false,
                    lineItemsList: lineItems,
                    ...lineItemsTotal,
                };
            }),
        []
    );

    const invoicesTransformedTableList = useMemo(
        () =>
            transformInvoicesData(invoicesList, contacts).filter(el => {
                if (!searchValue) {
                    return true;
                }
                return searchInAllFields(el, searchValue);
            }),
        [transformInvoicesData, invoicesList, contacts, searchValue]
    );
    const value: React.ContextType<typeof InvoicesDataContext> = useMemo(
        () => ({
            invoicesMap,
            invoicesTransformedTableList,
            invoiceListColumnsConfig,
            isOpenSettingsColumns,
            searchValue,
            expandedRows,
        }),
        [
            expandedRows,
            invoiceListColumnsConfig,
            invoicesMap,
            invoicesTransformedTableList,
            isOpenSettingsColumns,
            searchValue,
        ]
    );
    const actions = useMemo(
        () => ({
            setColumnConfig: (newValue: TInvoicesListColumnsConfig) => setInvoiceListColumnsConfig(newValue),
            setViewConfig: (newValue: boolean) => setIsOpenSettingsColumns(newValue),
            setSearchValue: (newValue: string) => setSearchValue(newValue),
            setExpandedRows,
        }),
        []
    );

    return (
        <InvoicesDataContext.Provider value={value}>
            <InvoicesListControlContext.Provider value={actions}>{children}</InvoicesListControlContext.Provider>
        </InvoicesDataContext.Provider>
    );
};
