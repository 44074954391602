import React, { FC, useContext } from "react";
import { TagComboboxBlock } from "../../shared/form/FormBlocks";
import { useProgramSettings } from "../hooks/useProgramSettings";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useIsItemFieldDisabledOutsideModal } from "../hooks/useIsItemFieldDisabledOutsideModal";
import { useFormHandlerItemTag } from "../hooks/handlers/useFormHandlerItemTag";

type Props = {
    isModalMode?: boolean;
};
export const FormItemTag: FC<Props> = ({ isModalMode }) => {
    const isModalOnly = useIsItemFieldDisabledOutsideModal(isModalMode);
    const { useKs } = useProgramSettings();
    const isDisabled = useFormIsRefDisabled();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { editableRecordItem, itemValidationStates } = useContext(RecordFormStateContext);
    const onTagChange = useFormHandlerItemTag();

    if (!useKs) {
        return null;
    }

    return (
        <TagComboboxBlock
            inputRef={refsHtml.REF_iTAG}
            labelProps={{
                style: { width: 100 },
                fieldError: itemValidationStates.get(refsHtml.REF_iTAG) ? { type: "error", message: " " } : undefined,
            }}
            value={isModalOnly ? undefined : editableRecordItem.itemTag}
            onChange={onTagChange}
            disabled={isModalOnly || isDisabled(refsHtml.REF_iTAG)}
        />
    );
};
