import React from "react";
import { Button, Typography } from "antd";
import { FormattedMessage } from "react-intl";

interface IProps {
    onEnable2fa: () => void;
}

export const Layout2faDisabled: React.FC<IProps> = ({ onEnable2fa }) => (
    <div data-testid="Layout2faDisabled">
        <Typography.Paragraph>
            <FormattedMessage
                id="app.2fa.description"
                values={
                    {
                        b: (chunks: string) => <b>{chunks}</b>,
                    } as any
                }
            />
        </Typography.Paragraph>
        <div style={{ display: "flex", justifyContent: "center", margin: "24px 0" }}>
            <Button type="primary" size="large" className="wide-button" onClick={onEnable2fa}>
                <FormattedMessage id="app.2fa.button.enable2fa" />
            </Button>
        </div>
    </div>
);
