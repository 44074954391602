import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { child, get, onValue } from "firebase/database";
import { refBanks } from "../api/firebase/firebaseRootRefs";
import { logger } from "../infrastructure/logger";

import { TBanksData, TTransactionData } from "@banks/types";
import { useGqlMutator } from "../graphql/useGqlMutator";
import { BanksApi } from "@banks/scripts/api";
import { CompanyContext } from "./CompanyContext";

type TValue = {
    banksData: TBanksData;
    companyId: string;
    transactionsData: Record<string, TTransactionData>;
};

type TActionValue = Record<string, unknown>;

export const BanksContext = createContext<TValue>({} as TValue);
export const BanksControlContext = createContext<TActionValue>({} as TActionValue);

type TProps = {
    children: ReactNode;
};

export const BanksContextProvider: FC<TProps> = ({ children }) => {
    const [banksData, setBanksData] = useState<TBanksData>({});
    const [transactionsData, setTransactionsData] = useState<Record<string, TTransactionData>>({});

    const mutator = useGqlMutator();
    const { companyGQL } = useContext(CompanyContext);
    const companyId = companyGQL?.id;

    useEffect(() => {
        BanksApi.mutator = mutator;
        BanksApi.companyId = companyId;
    }, [companyId, mutator]);

    useEffect(() => {
        const destructors: (() => void)[] = [];

        if (!companyId) {
            setTransactionsData({});
            return setBanksData({});
        }

        let unsubscribe: ReturnType<typeof onValue>;

        const fbBankRef = child(refBanks, `${companyId}/banks`);
        get(fbBankRef)
            .then(snap => {
                setBanksData(snap.val() || {});
                unsubscribe = onValue(fbBankRef, subscriptionSnap => {
                    setBanksData(subscriptionSnap.val() || {});
                });
                destructors.push(() => {
                    unsubscribe && unsubscribe();
                });
            })
            .catch((e: Error) => {
                if (e.message === "Permission denied") {
                    setBanksData({});
                } else {
                    logger.crit(e, "BankProvider: ");
                }
            });

        const fbTransactionRef = child(refBanks, `${companyId}/transactions`);
        get(fbTransactionRef)
            .then(snap => {
                setTransactionsData(snap.val() || {});
                unsubscribe = onValue(fbTransactionRef, subscriptionSnap => {
                    setTransactionsData(subscriptionSnap.val() || {});
                });
                destructors.push(() => {
                    unsubscribe && unsubscribe();
                });
            })
            .catch((e: Error) => {
                if (e.message === "Permission denied") {
                    setTransactionsData({});
                } else {
                    logger.crit(e, "BankProvider: ");
                }
            });

        return () => {
            destructors.forEach(fn => fn());
        };
    }, [companyId]);

    const value = useMemo(() => {
        return {
            banksData,
            companyId,
            transactionsData,
        };
    }, [banksData, companyId, transactionsData]);

    const actions = {};

    return (
        <BanksContext.Provider value={value}>
            <BanksControlContext.Provider value={actions}>{children}</BanksControlContext.Provider>
        </BanksContext.Provider>
    );
};
