import React, { FC, useContext, useEffect, useState } from "react";
import style from "./AddDmsIdInput.module.scss";
import { CloseOutlined } from "@ant-design/icons";

import { FormattedMessage, useIntl } from "react-intl";
import { Input } from "antd";
import { DmsDataContext } from "@dms/types/ContextTypes";

type TProps = {
    handleAddSearchedId: (arg: string) => void;
};
export const AddDmsIdInput: FC<TProps> = ({ handleAddSearchedId }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>();
    const [warning, setWarning] = useState<boolean>(false);

    const { documentsKV } = useContext(DmsDataContext);

    const { formatMessage } = useIntl();

    useEffect(() => {
        setWarning(false);
    }, [inputValue]);

    const handleAddId = (searchId: string) => {
        if (searchId === "") {
            setIsLoading(false);
        }
        setIsLoading(true);
        if (searchId in documentsKV) {
            handleAddSearchedId(searchId);
            setInputValue("");
        } else {
            setWarning(true);
        }

        setIsLoading(false);
    };

    return (
        <div className={style.inputWrapper}>
            {warning && (
                <div className={style.warningMsg}>
                    <FormattedMessage id="app.message.no_document_found" />
                </div>
            )}
            <Input.Search
                placeholder={formatMessage({ id: "app.dms.addDocumentId" })}
                loading={isLoading}
                autoFocus
                value={inputValue}
                status={warning ? "warning" : undefined}
                onChange={e => setInputValue(e.target.value)}
                onSearch={handleAddId}
                suffix={
                    isLoading ? undefined : (
                        <CloseOutlined
                            onClick={() => {
                                setWarning(undefined);
                                setInputValue("");
                            }}
                        />
                    )
                }
            />
        </div>
    );
};
