import "./FileUploader.css";
import { Button } from "antd";
import React, { type FC, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";

import { HadUploadedFiles } from "./components/HadBeenUploaded/HadUploadedFiles";
import { UploadFileList } from "./components/IsUploadingFiles/UploadFileList";
import { NotUniqFileList } from "./components/NotUniqFiles/NotUniqFileList";
import { NotValidFiles } from "./components/NotValidFiles/NotValidFiles";
import { FileUploaderContext, FileUploaderControlContext } from "./context/FileUploaderContext";
import { hashCalculate } from "../../../scripts/infrastructure/helpers/hashCalculate";
import { debounce } from "@dms/scripts/helpers";
import { CustomDragger } from "@dms/modules/DocumentUploader/components/CustomDragger/CustomDragger";

interface IProps {
    onReady: () => void;
}

export const FileUploader: FC<IProps> = ({ onReady }) => {
    const [notValidFiles, setNotValidFiles] = useState<File[] | []>([]);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);

    const { isDisabled, isDndFile, queueIsEmpty } = useContext(FileUploaderContext);
    const { setIsDisabled, setIsDndFile, setAddedFiles } = useContext(FileUploaderControlContext);

    useEffect(() => {
        const handleResize = debounce(() => setInnerHeight(window.innerHeight), 200);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const height = useMemo((): number | undefined => {
        if (isDndFile) {
            return innerHeight * 0.65;
        }
    }, [innerHeight, isDndFile]);

    const handleResetNotValidFiles = () => {
        if (!isDisabled) {
            setNotValidFiles([]);
        }
    };

    const handleChange = async (files: File[]) => {
        setIsDndFile(false);

        setIsDisabled(true);

        const readyForUploadFiles = [];

        for (const file of files) {
            if (file.type !== "application/pdf") {
                setNotValidFiles(prev => [...prev, file]);
                continue;
            }

            const isValidPDF = await DocumentsApi.checkPDF(file);
            if (!isValidPDF) {
                setNotValidFiles(prev => [...prev, file]);
                continue;
            }

            const hash = await hashCalculate(file);
            readyForUploadFiles.push({ file, hash });
        }

        setAddedFiles(readyForUploadFiles);
    };

    return (
        <>
            <div onClick={handleResetNotValidFiles}>
                <CustomDragger onChange={handleChange} height={height} />
            </div>
            {notValidFiles.length ? (
                <div style={{ marginTop: "15px" }}>{<NotValidFiles notValidFiles={notValidFiles} />}</div>
            ) : null}
            {
                <div style={{ marginTop: "15px" }}>
                    <NotUniqFileList />
                </div>
            }
            {
                <div style={{ marginTop: "15px" }}>
                    <HadUploadedFiles />
                </div>
            }
            {
                <div style={{ marginTop: "15px" }}>
                    <UploadFileList />
                </div>
            }

            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "40px" }}>
                <Button type={"primary"} disabled={!queueIsEmpty} onClick={onReady}>
                    <FormattedMessage id="app.button.done" />
                </Button>
            </div>
        </>
    );
};
