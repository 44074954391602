import { Utils } from "@binale-tech/shared";
import * as Accounting from "accounting-js";

export const isNullOrUndefined = (value: unknown) => value === undefined || value === null;

export const currencyFormatter = (value: number, precision?: number, stripZeros?: boolean, symbol?: string): string => {
    if (isNullOrUndefined(value) || isNaN(value)) {
        return "";
    }
    const settings = {
        symbol: symbol ?? "",
        decimal: ",",
        thousand: ".",
        precision: precision ?? 2,
        stripZeros: stripZeros ?? false,
    };
    value /= 100;
    return Accounting.formatMoney(value, settings);
};

export const formatDefault = (v: number, precision = Utils.CurrencyUtils.CurrencyFormatter.PRECISION) =>
    Utils.CurrencyUtils.currencyFormat(v || 0, precision);
export const parseNumber = (v: string) => Number(v.replace(/\./, "").replace(/,/, "."));
export const parseSearchAmount = (v: string) => Number(v.replace(/\./g, "").replace(/,/, "."));

export const decimalFormatter = (value: number, decimal?: number) => {
    return value ? value.toFixed(decimal || 2).replace(".", ",") : "0,00";
};
