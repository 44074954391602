import Dropzone from "react-dropzone";
import React from "react";
import dayjs from "dayjs";
import { Base, Bu, GQL, KontoNumUtils, Utils } from "@binale-tech/shared";
import { Badge, Button, Flex, message, Select, Space } from "antd";
import { CheckCircleOutlined, CloseOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { PageSizes, PDFDocument } from "pdf-lib";

import Category from "scripts/models/Category";
import Container from "../../../components/shared/appearance/page/Container";
import TableUtils from "scripts/core/TableUtils";
import { BuContext, BuTimeframe } from "scripts/context/BuContext";
import { BuTaxesSKR } from "scripts/models/BuTaxUtils";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { KontoContext, KontoControlContext } from "scripts/context/KontoEntitiesProvider";
import { RecordsControlContext } from "scripts/context/recordsContext/RecordsControlCtx";
import { Creditor, Tag } from "scripts/models";
import { Debitor } from "scripts/models/Creditor";

import TableContextMenuImport from "../../productSharedComponents/ContextMenu/TableContextMenuImport";
import { DATEV_HEADER } from "./datevConversion/constants";
import { FlexFillBlock, PageFullScreen } from "../../../components/shared/appearance/page/Scaffold";
import {
    GenericItem,
    GenericRecord,
    RecordBank,
    RecordDeb,
    RecordER,
    RecordFE,
    RecordKB,
    RecordLA,
    RecordPOS,
} from "scripts/models/GenericRecord";
import { GenericRecordColumns } from "../../../columns/ColumnConfig";
import { GenericRecordProperties } from "scripts/core/Product";
import { GenericRecordTableItem, TableItem } from "../../../components/shared/Table/Table";
import { GlobalYearSelect } from "../../../components/shared/Toolbar/YearSelect";
import { ICCD, IGenericRecord } from "scripts/models/Interfaces";
import { PageHeader } from "../../../components/shared/appearance/page";
import { RecordsTableBlock } from "../../productSharedComponents/RecordsTableBlock";
import { logger } from "scripts/infrastructure/logger";
import { ProductFactory } from "../../../../scripts/core/ProductFactory";

import { DocumentsApi } from "@dms/scripts/DocumentsApi";
import { hashCalculate } from "../../../../scripts/infrastructure/helpers/hashCalculate";
import { DmsType } from "@dms/types";
import { useGqlMutator } from "../../../../scripts/graphql/useGqlMutator";
import { DmsDataContext } from "@dms/types/ContextTypes";

const fileToString = (f: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(f, "CP1252");
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
};

const generateKey = (date: Date, period: number) => {
    return Utils.ModuleUtils.generateKey(date, period, "DatI");
};

const generateSingleItems = (record: GenericRecord) => {
    return record.items.map(item => {
        const newRecord = GenericRecord.genegateSingleItemRecord(record, item);
        newRecord.key = generateKey(record.date, record.period);
        return newRecord;
    });
};
type DatevEntry = {
    config: string[];
    dataEntries: { map: Record<string, string>; list: { key: string; value: string }[]; textLine: string }[];
};
const getAmount = (value: string) => {
    const bruttoStr = value.split(",");
    const cents = bruttoStr?.[1] || "0";
    return Number(bruttoStr[0] + cents.padEnd(2, "0"));
};

const getKontoType = (num: string, kontoExt: Base.CompanyKontoExt) => {
    const isCreditor = KontoNumUtils.isCreditorStrict(num, kontoExt);
    const isDebitor = KontoNumUtils.isDebitorStrict(num, kontoExt);
    return {
        isCreditor,
        isDebitor,
        isCategory: !isCreditor && !isDebitor,
    };
};
class KontoProductResolver {
    missingCreditors: string[] = [];
    missingDebitors: string[] = [];
    missingCategories: string[] = [];
    constructor(
        protected readonly creditors: Creditor[],
        protected readonly debitors: Debitor[],
        protected readonly yearConfig: GQL.ICompanyAccountingYear,
        protected readonly buTimeframes: BuTimeframe[]
    ) {}
    resolveProduct = (
        kontoNum: string,
        gKontoNum: string,
        allCategoriesStrMap: Map<string, Category>
    ): { product: GQL.IProductKey; performKontoSwitch: boolean } => {
        const { skr, kontoExt } = this.yearConfig;
        const kontoType = getKontoType(kontoNum, kontoExt as Base.CompanyKontoExt);
        const gKontoType = getKontoType(gKontoNum, kontoExt as Base.CompanyKontoExt);
        // console.log({ kontoType, gKontoType });
        const isKontoBank = KontoNumUtils.isBankStrict(kontoNum, skr, kontoExt);
        const isGKontoBank = KontoNumUtils.isBankStrict(gKontoNum, skr, kontoExt);
        const isKontoKasse = KontoNumUtils.isKasseStrict(kontoNum, skr, kontoExt);
        const isGKontoKasse = KontoNumUtils.isKasseStrict(gKontoNum, skr, kontoExt);
        const isKontoLA = KontoNumUtils.isLACat(kontoNum, skr, kontoExt);
        const isGKontoLA = KontoNumUtils.isLACat(gKontoNum, skr, kontoExt);
        if (kontoType.isCreditor && gKontoType.isCreditor) {
            logger.error("wtf 2 creditors", { kontoNum, gKontoNum });
            throw new Error();
        }
        if (kontoType.isDebitor && gKontoType.isDebitor) {
            logger.error("wtf 2 debitors", { kontoNum, gKontoNum });
            throw new Error();
        }
        const hasCreditor = kontoType.isCreditor || gKontoType.isCreditor;
        const hasDebitor = kontoType.isDebitor || gKontoType.isDebitor;
        if (hasCreditor && hasDebitor) {
            logger.error("wtf hasDebitor and hasCreditor are true", { kontoNum, gKontoNum });
            throw new Error();
        }
        const isCategoryAuto = (num: string) => {
            const intendedLen = Category.DefaultLen + kontoExt;
            const fixedKontoNum = num.padStart(intendedLen, "0");
            const category = allCategoriesStrMap.get(fixedKontoNum);
            return category.isAutoBu() && category.buScope !== "KU";
        };

        const kontoNumShort = Number(kontoNum.substring(0, 4));
        const isKontoSaldo = kontoNumShort >= KontoNumUtils.SALDO_MIN && kontoNumShort <= KontoNumUtils.SALDO_MAX;
        let product: GQL.IProductKey;
        let performKontoSwitch = false;
        switch (true) {
            case isKontoBank || isGKontoBank:
                product = GQL.IProductKey.Bank;
                performKontoSwitch = isGKontoBank;
                break;
            case isKontoKasse || isGKontoKasse:
                product = GQL.IProductKey.Kb;
                performKontoSwitch = isGKontoKasse;
                break;
            case isKontoLA || isGKontoLA:
                product = GQL.IProductKey.La;
                performKontoSwitch = isGKontoLA;
                break;
            case hasDebitor:
                product = GQL.IProductKey.Deb;
                performKontoSwitch = gKontoType.isDebitor;
                break;
            case hasCreditor:
                product = GQL.IProductKey.Er;
                performKontoSwitch = gKontoType.isCreditor;
                break;
            default:
                product = GQL.IProductKey.Fe;
                performKontoSwitch =
                    gKontoType.isCreditor ||
                    gKontoType.isDebitor ||
                    (kontoType.isCategory && (isKontoSaldo || isCategoryAuto(kontoNum)));
        }

        return { product, performKontoSwitch };
    };
    getBruttoData = (
        entry: DatevEntry["dataEntries"][number],
        performKontoSwitch: boolean,
        product: GQL.IProductKey
    ): Pick<GenericRecord, "brutto" | "currency" | "originalAmount"> => {
        // const isSoll = entry.map["Soll/Haben-Kennzeichen"] === "S";
        const isSoll = entry.list[1].value === "S";
        // When the record is in Euro, then there is only `Umsatz (ohne Soll/Haben-Kz)`
        const umsatzOhneStr = entry.list[0].value; // entry.map["Umsatz (ohne Soll/Haben-Kz)"]
        // When the record is in different currency, then
        // -- Umsatz (ohne Soll/Haben-Kz) / list[0] - is amount in the original currency
        // -- WKZ Umsatz / list[2]
        // -- Kurs / list[3]- exchange rate
        // -- Basis-Umsatz / list[4]- - amount in Euro
        const umsatzBasisStr = entry.list[4].value; // entry.map["Basis-Umsatz"]
        const hasCurrency = umsatzBasisStr.length > 0;
        // So first we check if `Basis-Umsatz` exists and use it, otherwise use `Umsatz (ohne Soll/Haben-Kz)`
        const bruttoEuro = getAmount(hasCurrency ? umsatzBasisStr : umsatzOhneStr);
        let bruttoSignSwitches = 0;
        if (isSoll) {
            bruttoSignSwitches++;
        }
        if (performKontoSwitch) {
            bruttoSignSwitches++;
        }
        const isRecordKontoHaben = Utils.SollHaben.isRecordPositiveBruttoHaben(product);
        if (!isRecordKontoHaben) {
            bruttoSignSwitches++;
        }
        const isBruttoStillPositive = bruttoSignSwitches % 2 === 0;
        const brutto = isBruttoStillPositive ? bruttoEuro : -bruttoEuro;

        const currency: Base.CurrencyConfig = hasCurrency
            ? {
                  code: entry.list[2].value as GQL.ICurrencyCode, // WKZ Umsatz
                  rate: Number(entry.list[3].value.replace(",", ".")), // Kurs
              }
            : undefined;
        const originalAmount: number = hasCurrency ? getAmount(umsatzOhneStr) * (brutto < 0 ? -1 : 1) : undefined;
        return { brutto, currency, originalAmount };
    };
    getCCD = (kontoNum: string, allCategoriesStrMap: Map<string, Category>): ICCD => {
        const { kontoExt } = this.yearConfig;
        const { creditors, debitors } = this;
        const { isCreditor, isDebitor } = getKontoType(kontoNum, kontoExt as Base.CompanyKontoExt);
        if (isCreditor) {
            const creditor = creditors.find(v => v.equalsTo(new Creditor(kontoNum, "")));
            if (!creditor) {
                this.missingCreditors.push(kontoNum);
                return { creditor: new Creditor(kontoNum, "") };
            }
            return { creditor };
        }
        if (isDebitor) {
            const debitor = debitors.find(v => v.equalsTo(new Debitor(kontoNum, "")));
            if (!debitor) {
                this.missingDebitors.push(kontoNum);
                return { debetor: new Debitor(kontoNum, "") };
            }
            return { debetor: debitor };
        }

        const intendedLen = Category.DefaultLen + kontoExt;
        const fixedKontoNum = kontoNum.padStart(intendedLen, "0");
        const category = allCategoriesStrMap.get(fixedKontoNum);
        console.log({ kontoNum, intendedLen, fixedKontoNum, category, allCategoriesStrMap });
        if (category) {
            return { category };
        }

        this.missingCategories.push(fixedKontoNum);
        return { category: new Category(fixedKontoNum, "") };
    };
}
const DatevImportView: React.FC = () => {
    const intl = useIntl();
    const mutator = useGqlMutator();
    const { yearConfig, yearBanks, yearKbs, companyGQL } = React.useContext(CompanyContext);
    const { year: globalYear } = React.useContext(YearPeriodContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const skr = yearConfig?.skr;
    const { creditors, debitors, tags, categoryOverrides } = React.useContext(KontoContext);
    const { categoryOverrideSave, creditorSave, debSave } = React.useContext(KontoControlContext);
    const { companyBuTimeframes } = React.useContext(BuContext);
    const recordActionsCtx = React.useContext(RecordsControlContext);
    const { documentTypes } = React.useContext(DmsDataContext);
    const [documents, setDocuments] = React.useState<Map<string, File>>(new Map());
    const [tableItems, setTableItems] = React.useState<GenericRecordTableItem[]>([]);
    const [focusIndex, setFocusIndex] = React.useState<number>();
    const [isImporting, setIsImporting] = React.useState(false);
    const [isConverting, setIsConverting] = React.useState(false);
    const [importedKeys, setImportedKeys] = React.useState<Set<string>>(new Set());
    const [selectedProductKey, setSelectedProductKey] = React.useState<GQL.IProductKey>(GQL.IProductKey.Er);
    const [selectedGroup, setSelectedGroup] = React.useState<string>("");
    const [recordDocumentIds, setRecordDocumentIds] = React.useState<Map<string, string>>(new Map());
    const [records, setRecords] = React.useState<Map<GQL.IProductKey, GenericRecord[]>>(new Map());
    const [groupRecords, setGroupRecords] = React.useState<Map<string, GenericRecord[]>>(new Map());
    const [missingAcconts, setMissingAccounts] = React.useState<{
        creditors?: string[];
        debitors?: string[];
        categories?: string[];
    }>({});
    const isPreviewActive = records.size > 0;
    React.useEffect(() => {
        let tableRecords = records.get(selectedProductKey) || [];
        if ([GQL.IProductKey.Kb, GQL.IProductKey.Bank].includes(selectedProductKey)) {
            tableRecords = groupRecords.get(selectedGroup) || [];
        }
        const items = TableUtils.getTableItems(
            tableRecords,
            { list: [], map: new Map(), recordRelation: new Map(), isLoaded: true },
            companyBuTimeframes,
            yearConfig,
            true
        );
        setTableItems(items);
    }, [yearConfig, records, selectedProductKey, selectedGroup, companyBuTimeframes, groupRecords]);
    React.useEffect(() => {
        setSelectedGroup("");
    }, [selectedProductKey]);

    const handleDropAcceptedDocuments = async (files: File[]) => {
        setIsConverting(true);
        const invalidNames: string[] = [];
        const validFiles: File[] = [];
        const recordDocumentIdsSet = new Set(recordDocumentIds.values());
        for (const file of files) {
            const segments = file.name.split(".");
            const ext = segments.pop().toLowerCase();
            const name = segments.join(".").toLowerCase();
            const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}(-\d)?$/g;
            if (!regex.test(name)) {
                invalidNames.push(file.name);
                return;
            }
            const uuidName = /(-\d)$/g.test(name) ? name.slice(0, -2) : name;
            if (!recordDocumentIdsSet.has(uuidName)) {
                return;
            }
            const renamedFile = new File([file], uuidName + "." + ext, {
                type: file.type,
                lastModified: file.lastModified,
            });
            if (file.type === "application/pdf") {
                validFiles.push(renamedFile);
            }
            if (file.type === "image/jpeg") {
                const pdfDoc = await PDFDocument.create();
                const jpgImage = await pdfDoc.embedJpg(await file.arrayBuffer());

                const jpgDims = jpgImage.scale(1);

                const page = pdfDoc.addPage([jpgImage.width, jpgImage.height]);

                page.drawImage(jpgImage, {
                    x: page.getWidth() / 2 - jpgDims.width / 2,
                    y: page.getHeight() / 2 - jpgDims.height / 2,
                    width: jpgDims.width,
                    height: jpgDims.height,
                });
                pdfDoc.setModificationDate(new Date(file.lastModified));
                pdfDoc.setCreationDate(new Date(file.lastModified));
                const pdfBytes = await pdfDoc.save();
                const convertedFile = new File([pdfBytes], uuidName + ".pdf", {
                    type: "application/pdf",
                    lastModified: file.lastModified,
                });
                validFiles.push(convertedFile);
            }
        }
        message.success(
            <div>
                Imported {validFiles.length} documents out of {files.length}
            </div>
        );
        if (invalidNames.length) {
            message.warning(
                <div>
                    <div>
                        Only files with <b>uuid names from DATEV</b> are accepted.
                        <br />
                        Following filenames are invalid:
                    </div>
                    {invalidNames.map(n => (
                        <div key={n}>{n}</div>
                    ))}
                </div>
            );
        }
        validFiles.forEach(file => {
            const key = file.name.slice(0, -4);
            if (!documents.has(key)) {
                documents.set(key, file);
            }
        });
        const newDocs = new Map(documents);
        setDocuments(newDocs);
        const processRecords = (records: GenericRecord[]) => {
            records.forEach((record, idx) => {
                if (recordDocumentIds.has(record.key)) {
                    const uuid = recordDocumentIds.get(record.key);
                    if (newDocs.has(uuid)) {
                        record.documents = [{ id: uuid, url: undefined }];
                        records[idx] = record.clone();
                    }
                }
            });
        };
        setRecords(prevState => {
            for (const [, records] of prevState.entries()) {
                processRecords(records);
            }
            return new Map(prevState);
        });
        setGroupRecords(prevState => {
            for (const [, records] of prevState.entries()) {
                processRecords(records);
            }
            return new Map(prevState);
        });
        setIsConverting(false);
    };

    const selectedImportKey = selectedProductKey + (selectedGroup || "");

    const handleDropAccepted = (file: File) => {
        fileToString(file)
            .then(str => {
                return str
                    .split("\n")
                    .map(line => line.split(";").map(cell => cell.replace(/^"|"$/g, "")))
                    .filter(line => line.length > 1);
            })
            .then((array): DatevEntry => {
                const [first, ...lengths] = array.map(line => line.length);
                logger.log({ first, lengths });
                const allValid = lengths.every(l => l > 40 && l <= DATEV_HEADER.length);
                if (!allValid) {
                    throw new Error(`All rows should have 40-${DATEV_HEADER.length} columns`);
                }
                const [config, ...data] = array;
                // if (Number(config[26]) !== skr) {
                //     throw new Error(`SKR mismatch, file: ${config[26]}, your company: ${skr}`);
                // }
                if (Number(config[13]) - 4 !== kontoExt) {
                    throw new Error(`Konten length mismatch, file: ${config[13]}, your company: ${kontoExt + 4}`);
                }
                const [header, ...entries] = data;
                const dataEntries = entries.map((line, index) => {
                    const list = line.map((value, idx) => ({ key: header[idx], value }));
                    const map: Record<string, string> = {};
                    list.forEach(({ key, value }) => {
                        map[key] = value;
                    });
                    return { map, list, textLine: line.join(";") + `|${index}|` + config.join(";") };
                });
                return { config, dataEntries };
            })
            .then(list => {
                logger.log(list);

                const resolver = new KontoProductResolver(creditors, debitors, yearConfig, companyBuTimeframes);
                const missingTags: string[] = [];
                const missingKB: string[] = [];
                const missingBank: string[] = [];
                const documentIds = new Map<string, string>();
                let recordsER: GenericRecord[] = [];
                let recordsDeb: GenericRecord[] = [];
                let recordsLA: GenericRecord[] = [];
                let recordsFE: GenericRecord[] = [];
                const recordsKB: Map<string, GenericRecord[]> = new Map();
                const recordsBank: Map<string, GenericRecord[]> = new Map();
                yearKbs.forEach(v => recordsKB.set(v.id, []));
                yearBanks.forEach(v => recordsBank.set(v.id, []));
                list.dataEntries.forEach(entry => {
                    const year = Number(list.config[12].substring(0, 4));
                    if (globalYear !== year) {
                        throw new Error(`Import file is configured for year ${year}. Please select this year`);
                    }
                    let { Belegdatum } = entry.map;
                    if (Belegdatum.length > 4) {
                        Belegdatum = Belegdatum.substring(0, Belegdatum.length - 4);
                    }
                    const dm = Belegdatum.padStart(4, "0");
                    const day = dm.substring(0, dm.length - 2);
                    const period = Number(dm.substring(2, 4));
                    if (period < 1 || period > 12) {
                        throw new Error(`Incorrect period decoded: ${period}, allowed values are [1, 12]`);
                    }
                    const date = new Date(year, period - 1, Number(day), 12);

                    const buTimeframe = BuTaxesSKR.getBuTimeframeYearPeriod(skr, companyBuTimeframes, year, period);
                    const defaultCategories = buTimeframe?.defaultCategories || new Map<number, Category>();
                    const allCategoriesMap = Category.mergeCategories(
                        defaultCategories,
                        categoryOverrides,
                        kontoExt
                    ).allMap;
                    const kontoNum = entry.map.Konto;
                    const gKontoNum = entry.list[7].value;

                    const { product, performKontoSwitch } = resolver.resolveProduct(
                        kontoNum,
                        gKontoNum,
                        allCategoriesMap
                    );

                    const { brutto, currency, originalAmount } = resolver.getBruttoData(
                        entry,
                        performKontoSwitch,
                        product
                    );

                    const recordKontoNum = performKontoSwitch ? gKontoNum : kontoNum;
                    const itemKontoNum = performKontoSwitch ? kontoNum : gKontoNum;
                    logger.log({
                        product,
                        brutto,
                        currency,
                        originalAmount,
                        performKontoSwitch,
                        recordKontoNum,
                        itemKontoNum,
                    });

                    const recordKonto: ICCD = {};
                    const itemKonto: ICCD = {};
                    try {
                        const res = resolver.getCCD(recordKontoNum, allCategoriesMap);
                        Object.assign(recordKonto, res);
                    } catch (e) {
                        logger.log(e.message);
                        return;
                    }
                    try {
                        const res = resolver.getCCD(itemKontoNum, allCategoriesMap);
                        Object.assign(itemKonto, res);
                    } catch (e) {
                        logger.log(e.message);
                        return;
                    }
                    logger.log({
                        recordKonto,
                        itemKonto,
                    });
                    const key = generateKey(date, period);

                    const documentId = entry.map.Beleglink.slice(7, -2);
                    if (documentId) {
                        documentIds.set(key, documentId);
                    }

                    const tagStr = entry.list[36].value; // "KOST1 - Kostenstelle"
                    const tag = tags.find(t => tagStr.length > 0 && t.equalsTo(new Tag(tagStr, "")));
                    if (tagStr.length > 0 && !tag) {
                        missingTags.push(tagStr);
                        return;
                    }
                    const USt13bValue = entry.map["Sachverhalt L+L"]
                        ? (Number(entry.map["Sachverhalt L+L"]) as unknown as Bu.USt13bOption)
                        : undefined;

                    // TODO: Open question: how to detect "cancelled" record
                    const cancellation: IGenericRecord["cancellation"] =
                        entry.map["Generalumkehr (GU)"] === "1" ? "counterweight" : undefined;

                    const getBu = () => {
                        const isAutoBu = itemKonto.category?.isAutoBu();
                        if (isAutoBu) {
                            return itemKonto.category.getAutoBu();
                        }
                        const buStr = entry.map["BU-Schlüssel"];
                        const guMap = new Map(buTimeframe.items.map(v => [v.GU, v.bu]));
                        // if bu is empty, then it means it's should be 0
                        const buInt = Number(buStr ?? "0");
                        if (cancellation === "counterweight" && guMap.has(buInt)) {
                            return guMap.get(buInt);
                            // there are cases when Datev file has original Bu number instead of GU
                        }
                        return buInt;
                    };
                    const bu = getBu();
                    const journaled = Boolean(entry.map.Festschreibung);

                    const useItemBelegfeld1 = Utils.ModuleUtils.useBelegfeld1Split(product);

                    const prototype: IGenericRecord = {
                        key,
                        date,
                        year,
                        period,
                        num: useItemBelegfeld1 ? "" : (entry.map["Belegfeld 1"] ?? ""),
                        brutto,
                        originalAmount,
                        currency,
                        ...recordKonto,
                        journaled,
                        cancellation,
                        items: [
                            new GenericItem({
                                brutto,
                                originalAmount,
                                text: String(entry.map.Buchungstext || "").substring(0, 60),
                                belegfeld2: entry.map["Belegfeld 2"],
                                belegfeld1: useItemBelegfeld1 ? entry.map["Belegfeld 1"] : undefined, // use for KB / Bank / FE / Pos
                                ...itemKonto,
                                bu,
                                USt13b: USt13bValue,
                                tag,
                            }),
                        ],
                    };
                    const kb = yearKbs.find(v =>
                        KontoNumUtils.equalsTo(new Category(String(v.accountNum), ""), recordKonto.category)
                    );
                    const bank = yearBanks.find(v =>
                        KontoNumUtils.equalsTo(new Category(String(v.accountNum), ""), recordKonto.category)
                    );
                    switch (product) {
                        case GQL.IProductKey.Er:
                            recordsER.push(new RecordER(prototype));
                            break;
                        case GQL.IProductKey.Deb:
                            recordsDeb.push(new RecordDeb(prototype));
                            break;
                        case GQL.IProductKey.La:
                            recordsLA.push(new RecordLA(prototype));
                            break;
                        case GQL.IProductKey.Fe:
                            recordsFE.push(new RecordFE(prototype));
                            break;
                        case GQL.IProductKey.Kb:
                            if (!kb) {
                                missingKB.push(recordKonto.category.num);
                                return;
                            }
                            recordsKB.get(kb.id).push(new RecordKB(prototype));
                            break;
                        case GQL.IProductKey.Bank:
                            if (!bank) {
                                missingBank.push(recordKonto.category.num);
                                return;
                            }
                            recordsBank.get(bank.id).push(new RecordBank(prototype));
                            break;
                    }
                });
                const unifyRecordsWithSplitFn = (map: Map<string, GenericRecord>) => (record: GenericRecord) => {
                    // if record.num is empty, we shouldn't group the entity, so we generate random key
                    if (!record.num) {
                        map.set(crypto.randomUUID(), record);
                        return;
                    }
                    const key =
                        record.num +
                        dayjs(record.date).format("YYYY-MM-DD") +
                        record.getRecordCategoryCreditor().getExtNum(2);
                    if (map.has(key)) {
                        const r = map.get(key);
                        r.items.push(...record.items);
                        r.calculateBrutto();
                    } else {
                        map.set(key, record);
                    }
                };

                const doGroupRecords = (recordsList: GenericRecord[]) => {
                    const recordsMap = new Map<string, GenericRecord>();
                    recordsList.forEach(record => unifyRecordsWithSplitFn(recordsMap)(record));
                    return Array.from(recordsMap.values());
                };

                recordsER = doGroupRecords(recordsER);
                recordsDeb = doGroupRecords(recordsDeb);
                recordsLA = doGroupRecords(recordsLA);
                recordsFE = doGroupRecords(recordsFE);

                recordsKB.forEach((recordsList, key) => {
                    recordsKB.set(key, doGroupRecords(recordsList));
                });

                recordsBank.forEach((recordsList, key) => {
                    recordsBank.set(key, doGroupRecords(recordsList));
                });

                if (resolver.missingCreditors.length) {
                    message.info(`Creditors to create on save ${[...new Set(resolver.missingCreditors)].toString()}`);
                }
                if (resolver.missingDebitors.length) {
                    message.info(`Debitors to create on save ${[...new Set(resolver.missingDebitors)].toString()}`);
                }
                if (resolver.missingCategories.length) {
                    message.info(`Categories to create on save ${[...new Set(resolver.missingCategories)].toString()}`);
                }
                if (missingTags.length) {
                    message.error(`Missing kostenstelle ${[...new Set(missingTags)].toString()}`);
                    return;
                }
                if (missingBank.length) {
                    message.error(`Missing banks ${[...new Set(missingBank)].toString()}`);
                    return;
                }
                if (missingKB.length) {
                    message.error(`Missing kassen ${[...new Set(missingKB)].toString()}`);
                    return;
                }
                const groupRecordsList = new Map([...recordsKB, ...recordsBank]);
                const groupRecordsLen = Array.from(groupRecordsList.values()).reduce((acc, arr) => acc + arr.length, 0);
                message.info(
                    `Selected for import ${
                        [...recordsER, ...recordsDeb, ...recordsLA, ...recordsFE].length + groupRecordsLen
                    } records out of ${list.dataEntries.length}`
                );
                setRecordDocumentIds(documentIds);
                setRecords(
                    new Map([
                        [GQL.IProductKey.Er, recordsER],
                        [GQL.IProductKey.Deb, recordsDeb],
                        [GQL.IProductKey.La, recordsLA],
                        [GQL.IProductKey.Fe, recordsFE],
                        [GQL.IProductKey.Pos, []],
                    ])
                );
                setGroupRecords(groupRecordsList);
                setMissingAccounts({
                    creditors: resolver.missingCreditors,
                    debitors: resolver.missingDebitors,
                    categories: resolver.missingCategories,
                });
            })
            .catch(e => {
                message.error(e.message);
            });
    };
    console.log(records);
    console.log(tableItems);
    const importAccounts = async () => {
        const categorySet = new Set(missingAcconts.categories || []);
        for (const num of categorySet) {
            await categoryOverrideSave({ num, name: "" });
        }
        const debitorSet = new Set(missingAcconts.debitors || []);
        for (const num of debitorSet) {
            await debSave(new Debitor(num, ""));
        }
        const creditorSet = new Set(missingAcconts.creditors || []);
        for (const num of creditorSet) {
            await creditorSave(new Creditor(num, ""));
        }
    };
    const importDocuments = async (vs: GenericRecord[]) => {
        const hideLoadingMessage = message.loading("Loading documents", 0);
        const getSubType = () => {
            if (!selectedGroup) {
                return;
            }
            for (const documentType of documentTypes) {
                for (const subType of documentType.subTypes) {
                    if (subType.bankId === selectedGroup) {
                        return subType.id;
                    }
                    if (subType.kbId === selectedGroup) {
                        return subType.id;
                    }
                }
            }
        };
        const subType = getSubType();
        for (const [idx, record] of vs.entries()) {
            if (!record.documents?.length) {
                continue;
            }
            const [document] = record.documents;
            const uuid = document.id;
            const file = documents.get(uuid);
            const hash = await hashCalculate(file);
            const res = await DocumentsApi.createDocument({
                fileData: { file, hash, isUploaded: false },
                mutator,
                companyData: companyGQL,
                setFileStatus: status => {},
                documentType: { type: product.productKey() as DmsType, subType },
            });
            record.documents = [{ id: hash, url: res.url }];
            vs[idx] = record.clone();
        }
        hideLoadingMessage();
        return [...vs];
    };
    const {
        view,
        product,
        actions: recordActions,
    } = ProductFactory.getAccountingProductConfig(
        selectedProductKey,
        yearConfig,
        companyGQL,
        recordActionsCtx,
        selectedGroup
    );

    const handleImport = async () => {
        setIsImporting(true);
        const importRecords = tableItems.map(v => v.item);
        await importAccounts();
        const recordsWithDocuments = await importDocuments(importRecords);
        await recordActions
            .importMany(recordsWithDocuments)
            .then(() => {
                message.success(intl.formatMessage({ id: "app.button.done" }));
                setImportedKeys(prevSet => new Set([...prevSet, selectedImportKey]));
            })
            .catch(e => message.warning(e.message))
            .finally(() => setIsImporting(false));
    };
    const handleReset = () => {
        setRecords(new Map());
        setGroupRecords(new Map());
        setMissingAccounts({});
        setSelectedProductKey(GQL.IProductKey.Er);
        setImportedKeys(new Set());
        setIsImporting(false);
        setIsConverting(false);
        setFocusIndex(undefined);
        setDocuments(new Map());
        setRecordDocumentIds(new Map());
    };
    const isImported = importedKeys.has(selectedImportKey);
    return (
        <PageFullScreen>
            <PageHeader>{intl.formatMessage({ id: "app.titles.data_import" })}</PageHeader>
            <Flex justify={"space-between"} style={{ margin: "5px 0" }}>
                <Space>
                    <GlobalYearSelect />

                    {isPreviewActive ? (
                        <Badge count={documents.size}>
                            <Space.Compact block>
                                <Dropzone
                                    accept={{ "image/jpeg": [".jpg", ".jpeg"], "application/pdf": [".pdf"] }}
                                    multiple
                                    onDropAccepted={files => {
                                        handleDropAcceptedDocuments(files);
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />

                                            <Button
                                                style={{ width: 250 }}
                                                type="dashed"
                                                icon={<UploadOutlined />}
                                                htmlType="button"
                                                loading={isConverting}
                                            >
                                                {intl.formatMessage({ id: "app.button.upload" })}
                                                &nbsp;.PDF&nbsp;.JPF
                                            </Button>
                                        </div>
                                    )}
                                </Dropzone>
                                <Button icon={<CloseOutlined />} onClick={() => setDocuments(new Map())} />
                            </Space.Compact>
                        </Badge>
                    ) : (
                        <Dropzone
                            accept={{ "text/csv": [".csv"] }}
                            multiple={false}
                            onDropAccepted={files => {
                                handleDropAccepted(files[0]);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <Button
                                        style={{ width: 250 }}
                                        type="primary"
                                        icon={<UploadOutlined />}
                                        htmlType="button"
                                    >
                                        {intl.formatMessage({ id: "app.button.upload" })}
                                        &nbsp;DATEV&nbsp;CSV
                                    </Button>
                                </div>
                            )}
                        </Dropzone>
                    )}
                </Space>
                <Space>
                    <Select<GQL.IProductKey>
                        value={selectedProductKey}
                        onChange={setSelectedProductKey}
                        options={[
                            {
                                value: GQL.IProductKey.Er,
                                label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Er }),
                            },
                            {
                                value: GQL.IProductKey.Deb,
                                label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Deb }),
                            },
                            {
                                value: GQL.IProductKey.Kb,
                                label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Kb }),
                            },
                            {
                                value: GQL.IProductKey.Bank,
                                label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Bank }),
                            },
                            {
                                value: GQL.IProductKey.La,
                                label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.La }),
                            },
                            {
                                value: GQL.IProductKey.Fe,
                                label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Fe }),
                            },
                            {
                                value: GQL.IProductKey.Pos,
                                label: intl.formatMessage({ id: "app.titles." + GQL.IProductKey.Pos }),
                            },
                        ]}
                    />
                    <Select<string>
                        value={selectedGroup}
                        onChange={v => setSelectedGroup(v)}
                        disabled={![GQL.IProductKey.Kb, GQL.IProductKey.Bank].includes(selectedProductKey)}
                        options={[
                            { value: "", label: "Not selected" },
                            ...yearKbs.map(v => ({
                                value: v.id,
                                label: `${v.accountNum} ${v.name}`,
                                disabled: selectedProductKey !== GQL.IProductKey.Kb,
                            })),
                            ...yearBanks.map(v => ({
                                value: v.id,
                                label: `${v.accountNum} ${v.name}`,
                                disabled: selectedProductKey !== GQL.IProductKey.Bank,
                            })),
                        ]}
                    />
                </Space>
                <Space>
                    <Button
                        style={{ width: 250 }}
                        type="primary"
                        icon={<SaveOutlined />}
                        disabled={!tableItems.length || isImporting || isImported}
                        loading={isImporting}
                        onClick={() => handleImport()}
                    >
                        {intl.formatMessage({ id: "app.button.save" })}{" "}
                        {isImported ? <CheckCircleOutlined /> : tableItems.length > 0 ? `(${tableItems.length})` : null}
                    </Button>
                    {isPreviewActive && (
                        <Button icon={<CloseOutlined />} onClick={handleReset}>
                            {intl.formatMessage({ id: "app.button.cancel" })}
                        </Button>
                    )}
                </Space>
            </Flex>
            {isPreviewActive && (
                <FlexFillBlock style={{ marginBottom: 5 }}>
                    <Container absolute>
                        {(w, h) => (
                            <RecordsTableBlock
                                view={view}
                                tableRef={undefined}
                                selectedPeriodEditBound={null}
                                tableItems={tableItems}
                                tableHeight={h}
                                tableSaldoHeader={undefined}
                                tableRowClassFunc={tableItem => {
                                    return TableUtils.getTableRowClassName(tableItem, focusIndex);
                                }}
                                onSort={() => {}}
                                sortColumn={undefined}
                                product={product}
                                canWrite={false}
                                onPayments={() => {}}
                                focusIndex={focusIndex}
                                onSetFocus={idx => setFocusIndex(idx)}
                                tableConfigFooters={undefined}
                                contextMenu={
                                    <TableContextMenuImport
                                        selectedProductKey={selectedProductKey}
                                        onMoveToFE={(ti, isPos) => {
                                            const keys = new Set(ti.map(v => v.item.key));
                                            if (
                                                [GQL.IProductKey.Kb, GQL.IProductKey.Bank].includes(selectedProductKey)
                                            ) {
                                                let tableRecords = groupRecords.get(selectedGroup) || [];
                                                tableRecords = tableRecords.filter(v => !keys.has(v.key));
                                                groupRecords.set(selectedGroup, tableRecords);
                                            } else {
                                                let tableRecords = records.get(selectedProductKey);
                                                tableRecords = tableRecords.filter(v => !keys.has(v.key));
                                                records.set(selectedProductKey, tableRecords);
                                            }
                                            const targetProductKey = isPos ? GQL.IProductKey.Pos : GQL.IProductKey.Fe;
                                            records.get(targetProductKey).push(
                                                ...ti.map(v => {
                                                    const isBf1Splitted = Utils.ModuleUtils.useBelegfeld1Split(
                                                        v.item.getProductKey()
                                                    );
                                                    const clone = v.item.clone();
                                                    if (!isBf1Splitted) {
                                                        clone.items.forEach(item => {
                                                            item.belegfeld1 = clone.num;
                                                        });
                                                        clone.num = undefined;
                                                    }
                                                    const r = isPos ? new RecordPOS(clone) : new RecordFE(clone);
                                                    if (
                                                        !Utils.SollHaben.areSameSign(
                                                            selectedProductKey,
                                                            targetProductKey
                                                        )
                                                    ) {
                                                        r.items.forEach(item => {
                                                            item.brutto = -item.brutto;
                                                        });
                                                        r.calculateBrutto();
                                                    }
                                                    return r;
                                                })
                                            );
                                            setRecords(new Map(records));
                                            setGroupRecords(new Map(groupRecords));
                                        }}
                                        onUnsplit={ti => {
                                            if (
                                                [GQL.IProductKey.Kb, GQL.IProductKey.Bank].includes(selectedProductKey)
                                            ) {
                                                let tableRecords = groupRecords.get(selectedGroup) || [];
                                                tableRecords = tableRecords.filter(v => v.key !== ti.item.key);
                                                const singleItems = generateSingleItems(ti.item);
                                                tableRecords.push(...singleItems);
                                                groupRecords.set(selectedGroup, tableRecords);
                                            } else {
                                                let tableRecords = records.get(selectedProductKey);
                                                tableRecords = tableRecords.filter(v => v.key !== ti.item.key);
                                                const singleItems = generateSingleItems(ti.item);
                                                tableRecords.push(...singleItems);
                                                records.set(selectedProductKey, tableRecords);
                                            }
                                            setRecords(new Map(records));
                                            setGroupRecords(new Map(groupRecords));
                                        }}
                                        onMoveToPeriod={(ti, period) => {
                                            const keys = new Set(ti.map(v => v.item.key));
                                            if (
                                                [GQL.IProductKey.Kb, GQL.IProductKey.Bank].includes(selectedProductKey)
                                            ) {
                                                const tableRecords = groupRecords.get(selectedGroup) || [];
                                                tableRecords
                                                    .filter(v => keys.has(v.key))
                                                    .forEach(record => {
                                                        record.period = period;
                                                    });
                                            } else {
                                                const tableRecords = records.get(selectedProductKey);
                                                tableRecords
                                                    .filter(v => keys.has(v.key))
                                                    .forEach(record => {
                                                        record.period = period;
                                                    });
                                            }
                                            setRecords(new Map(records));
                                            setGroupRecords(new Map(groupRecords));
                                        }}
                                    />
                                }
                                columnConfig={
                                    new GenericRecordColumns(product, yearConfig, () => {}, {
                                        getters: {
                                            [GenericRecordProperties.RecordCategoryCreditorNum]: (
                                                tableItem: TableItem<GenericRecord>,
                                                ext: number
                                            ) => tableItem.item.getRecordCategoryCreditor().getExtNumPrint(ext),
                                            [GenericRecordProperties.ItemCategoryCreditorNum]: (
                                                tableItem: TableItem<GenericRecord>,
                                                ext: number
                                            ) => tableItem.item.getItemCategoryCreditor().getExtNumPrint(ext),
                                        },
                                    })
                                }
                                itemActions={{
                                    handleEditItem: () => {},
                                    handleCopyItem: () => {},
                                    handleDeleteItems: () => {},
                                    handleCancelItems: () => () => {},
                                    handleUpdateItems: items => setTableItems(items),
                                    handleColorTableItems: () => {},
                                    handleAvisTableItems: () => {},
                                    handleBulkEditItem: () => {},
                                }}
                            />
                        )}
                    </Container>
                </FlexFillBlock>
            )}
        </PageFullScreen>
    );
};
export default DatevImportView;
