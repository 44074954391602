import React from "react";
import { Alert, Button, Col, Drawer, Row, Spin } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { gql } from "@apollo/client";

import { Multiselect, MultiselectValue } from "../shared/Multiselect/Multiselect";
import { ProductKeys } from "scripts/models/Product";
import { UserContext } from "scripts/context/UserProvider";
import { useGQLRetriever } from "scripts/graphql/gqlRetriever";
import { useGqlMutator } from "scripts/graphql/useGqlMutator";

type Props = {
    company?: GQL.ICompany;
    onHide: () => void;
};

const fragment = gql`
    fragment CompanyProductsData on Company {
        id
        name
        products {
            id
            productKey
        }
    }
`;
const query = gql`
    ${fragment}
    query companyWithProducts($id: ID!) {
        company(id: $id) {
            ...CompanyProductsData
        }
    }
`;
const mutation = gql`
    ${fragment}
    mutation companySetProducts($input: CompanySetProductsInput!) {
        companySetProducts(input: $input) {
            ...CompanyProductsData
        }
    }
`;

export const CompanyModules: React.FC<Props> = props => {
    const [loading, setLoading] = React.useState(false);
    const [products, setProducts] = React.useState<GQL.ICompanyProductInput[]>([]);
    const { isAdminWrite } = React.useContext(UserContext);
    const { company } = props;
    const name = company?.name;
    const companyId = company?.id;
    const intl = useIntl();
    const mutator = useGqlMutator();
    const retriever = useGQLRetriever<"company">();

    React.useEffect(() => {
        if (!companyId) {
            setProducts([]);
            return;
        }
        const getCompanyData = async () => {
            setLoading(true);
            const response = await retriever.query({ query, id: companyId });
            if (response.company) {
                setProducts(response.company.products.map(({ productKey }) => ({ productKey })));
            }
            setLoading(false);
        };
        getCompanyData();
    }, [retriever, companyId]);

    const onChangeSubscription = (v: MultiselectValue[]) => {
        const newProducts: GQL.ICompanyProductInput[] = [];
        v.forEach(mv => {
            if (mv.selected) {
                newProducts.push({ productKey: mv.value as GQL.IProductKey });
            }
        });
        setProducts(newProducts);
    };

    const handleClick = () => {
        setLoading(true);
        mutator
            .mutate<"companySetProducts", GQL.ICompanySetProductsInput>({
                mutation,
                input: { companyId, products },
            })
            .then(() => {
                setLoading(false);
                setTimeout(() => props.onHide(), 500);
            });
    };

    const values: MultiselectValue[] = ProductKeys.all().map(value => {
        return {
            value,
            label: intl.formatMessage({ id: "app.titles." + value }),
            selected: Boolean(products.find(p => p.productKey === value)),
            disabled: !isAdminWrite,
        };
    });

    const disabled = loading || !isAdminWrite;

    return (
        <Drawer
            title={
                <span>
                    <FormattedMessage id="app.titles.company" /> {name}
                </span>
            }
            placement="right"
            open={Boolean(companyId)}
            onClose={props.onHide}
        >
            <Spin spinning={loading}>
                <h3>
                    <FormattedMessage id="app.fields.products" />
                </h3>
                {!isAdminWrite && (
                    <Alert showIcon message="Only admin can set products" type="warning" style={{ marginBottom: 16 }} />
                )}
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Multiselect onChange={onChangeSubscription} disabled={disabled} data={values} />
                    </Col>
                    <Col xs={24}>
                        <Button type="primary" disabled={disabled} onClick={handleClick}>
                            <FormattedMessage id="app.button.save" />
                        </Button>
                    </Col>
                </Row>
            </Spin>
        </Drawer>
    );
};
