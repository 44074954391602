import React, { FC, useContext, useEffect, useMemo } from "react";
import { Row } from "antd";
import { PdfToolsContext } from "../../context";
import { ScrollAllPages, ScrollInvisiblePages } from "../ScrollPages";

import "./ScrollPageTool.css";

export interface Props {
    showDeletedPages: boolean;
}

export const ScrollPagesTool: FC<Props> = ({ showDeletedPages }) => {
    const { pages, documents, viewDocuments, activePageId } = useContext(PdfToolsContext);

    const activePageIndex = useMemo(() => {
        let requiredIdx = 0;
        let foundPage = false;
        documents.forEach(document => {
            if (foundPage) {
                return;
            }
            const pageIdx = document.pageIds.indexOf(activePageId);
            if (pageIdx === -1) {
                requiredIdx += document.pageIds.length;
            } else {
                requiredIdx += pageIdx;
                foundPage = true;
            }
        });
        return foundPage ? requiredIdx : -1;
    }, [documents, activePageId]);

    useEffect(() => {
        const isIncludes = documents.flatMap(el => el.pageIds).includes(activePageId);
        if (activePageId !== null && isIncludes) {
            setTimeout(() => {
                const el = window.document.getElementById(activePageId);
                if (el) {
                    el.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            }, 10);
        }
    }, [activePageId, activePageIndex, pages.length]);

    if (!viewDocuments?.length || !viewDocuments[0].pages || !viewDocuments[0].pages[0]) {
        return null;
    }

    return (
        <Row className="pdf-tools-ScrollBlock" id="scrollBlock">
            {showDeletedPages && <ScrollAllPages />}
            {!showDeletedPages && <ScrollInvisiblePages />}
        </Row>
    );
};
