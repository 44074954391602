import React, { useContext, useState } from "react";
import { Button, Popconfirm, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { UserControlContext } from "scripts/context/UserProvider";
import { useSecretQrCode } from "./useSecretQrCode";

export const Layout2faEnabled: React.FC = () => {
    const [showConfirmDisable, setShowConfirmDisable] = useState(false);
    const [disabling, setDisabling] = useState(false);
    const [loadingToken, setLoadingToken] = useState(false);
    const [privateToken, setPrivateToken] = useState<string>(null);
    const qrCode = useSecretQrCode(privateToken);
    const { removePrivateToken, getPrivateToken } = useContext(UserControlContext);

    const handleShowPrivateKey = async () => {
        setLoadingToken(true);
        const token = await getPrivateToken();
        setPrivateToken(token);
        setLoadingToken(false);
    };

    const handleDisable2fa = () => {
        setShowConfirmDisable(true);
    };

    const handleDeletePrivateKey = async () => {
        setDisabling(true);
        await removePrivateToken();
    };

    return (
        <div data-testid="Layout2faEnabled">
            <FormattedMessage
                id="app.2fa.enabled2fa_description"
                values={
                    {
                        b: (chunks: string) => <b>{chunks}</b>,
                    } as any
                }
                tagName="p"
            />
            <Space>
                <Button
                    type="primary"
                    onClick={handleShowPrivateKey}
                    disabled={!!privateToken || loadingToken}
                    loading={loadingToken}
                    data-testid="showToken"
                >
                    <FormattedMessage id="app.2fa.button.showPrivateKey" />
                </Button>

                <Popconfirm
                    title={<FormattedMessage id="app.2fa.confirm_delete" />}
                    open={showConfirmDisable}
                    onConfirm={handleDeletePrivateKey}
                    okButtonProps={{ loading: disabling, title: "Disable" }}
                    placement="bottom"
                    icon={<QuestionCircleOutlined />}
                    okText={<FormattedMessage id="app.global.yes" />}
                    cancelText={<FormattedMessage id="app.button.cancel" />}
                    okType="danger"
                    onCancel={() => setShowConfirmDisable(false)}
                >
                    <Button type="default" danger onClick={handleDisable2fa} data-testid="disable2fa">
                        <FormattedMessage id="app.2fa.button.disable2fa" />
                    </Button>
                </Popconfirm>
            </Space>
            {privateToken && (
                <>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
                        {qrCode && <img src={qrCode} alt="qr code" />}
                        <div>
                            <FormattedMessage id="app.2fa.step.backup.description" tagName="p" />
                            <p className="StepsLayout__code">{privateToken}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
