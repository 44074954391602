import { Checkbox, CheckboxRef, Form, type InputProps, Typography } from "antd";
import React, { forwardRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import styles from "../../../components/DocumentForm/DocumentFormFields/DocumentFormField.module.scss";

const { Text } = Typography;
interface BaseInputIProps extends Omit<InputProps, "onChange" | "value"> {
    onChange?: (val: boolean) => void;
    value?: boolean;
    setIsAttached: (val: boolean) => void;
}

export const AttachmentCheckbox = forwardRef<CheckboxRef, BaseInputIProps>((props, ref) => {
    const { onChange, setIsAttached, ...rest } = props;
    const form = Form.useFormInstance();
    const isAttached = form.getFieldValue("isAttachment");

    useEffect(() => {
        if (isAttached) {
            setIsAttached(isAttached);
        }
    }, []);

    const handleCheck = (value: boolean): void => {
        setIsAttached(value);
        onChange?.(value);
    };

    return (
        <div className={styles.attachment__children}>
            <Text strong ellipsis>
                <FormattedMessage id="app.fields.attachment" />
            </Text>
            <Checkbox
                {...rest}
                ref={ref}
                style={{ height: 16 }}
                value={Boolean(isAttached)}
                onChange={e => {
                    handleCheck(e.target.checked);
                }}
            />
        </div>
    );
});
