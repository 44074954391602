import React from "react";
import { AuswertungAvisViewClass } from "../../productSharedComponents/auswertung/AuswertungViews";
import { AuswertungWrapper } from "../../productSharedComponents/auswertung/AuswertungWrapper";
import { CompanyContext } from "scripts/context/CompanyContext";
import { ProductAuswertungOposAvisER, ProductAuswertungOposAvisERAnz } from "scripts/core/Product";
import { useAvisDefaultFilters } from "../../productSharedComponents/auswertung/auswertungDefaultFilters";

export const AuswertungAvisViewER = React.memo(function AuswertungAvisViewER() {
    const df = useAvisDefaultFilters();
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const product = React.useMemo(
        () => new ProductAuswertungOposAvisER(yearConfig.skr, companyGQL),
        [yearConfig, companyGQL]
    );
    return (
        <AuswertungWrapper Component={AuswertungAvisViewClass} defaultFilters={df} product={product} view="erAVIS" />
    );
});
export const AuswertungAvisViewErA = React.memo(function AuswertungAvisViewErA() {
    const df = useAvisDefaultFilters();
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const product = React.useMemo(
        () => new ProductAuswertungOposAvisERAnz(yearConfig.skr, companyGQL),
        [yearConfig, companyGQL]
    );
    return (
        <AuswertungWrapper
            Component={AuswertungAvisViewClass}
            defaultFilters={df}
            product={product}
            view="erAnzahalunenAVIS"
        />
    );
});
