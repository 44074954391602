import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { Flex, Row, Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { PreviewBoxHeader } from "./components/PreviewBoxHeader";
import { useMouseEvents } from "../../hooks/useMouseEvents";
import { PdfToolsContext } from "../../context";

import styles from "./previewBox.module.scss";
import { OcrPage, PageSource } from "../../types";

export const PreviewBox: FC = () => {
    const { activePageId, documents, pages, parkedDocuments, uploadedFiles } = useContext(PdfToolsContext);
    const [zoom, setZoom] = useState(1);

    const containerRef = useRef<HTMLDivElement>(null);

    const { activePage, source }: { activePage: OcrPage | undefined; source: PageSource } = useMemo(() => {
        let activePage = pages.find(page => page.id === activePageId);
        let source = PageSource.LISTED;

        if (!activePage && parkedDocuments) {
            activePage = parkedDocuments.parsedPages.find(page => page.id === activePageId);
            source = PageSource.PARKED;
        }

        if (!activePage && uploadedFiles) {
            activePage = uploadedFiles.parsedPages.find(page => page.id === activePageId);
            source = PageSource.UPLOADED;
        }
        return { activePage, source };
    }, [activePageId, pages, parkedDocuments, uploadedFiles]);

    const imageUrl = useMemo(() => {
        if (activePage) {
            return activePage.imageUrl;
        }

        return "";
    }, [activePage]);

    const orderedPages: string[] = useMemo(() => {
        let pagesList: string[] = [];

        switch (source) {
            case PageSource.LISTED:
                documents.forEach(doc => {
                    pagesList = [...pagesList, ...doc.pageIds];
                });
                break;
            case PageSource.UPLOADED:
                const foundUploadedPagesIds =
                    uploadedFiles?.parsedDocuments.find(el => el.pageIds.includes(activePageId))?.pageIds ?? [];
                pagesList = [...pagesList, ...foundUploadedPagesIds];
                break;

            case PageSource.PARKED:
                const foundParkedPagesIds =
                    parkedDocuments?.parsedDocuments.find(el => el.pageIds.includes(activePageId))?.pageIds ?? [];
                pagesList = [...pagesList, ...foundParkedPagesIds];
                break;
        }
        return pagesList;
    }, [documents, activePage]);

    const activePageIndex = orderedPages.findIndex(pageId => pageId === activePageId);

    useEffect(() => {
        setZoom(prevZoom => {
            return prevZoom > 100 ? 100 : prevZoom;
        });
    }, [activePageId]);

    const zoomIn = useCallback(() => {
        setZoom(prevZoom => prevZoom + 0.25);
    }, []);

    const zoomOut = useCallback(() => {
        setZoom(prevZoom => Math.max(1, prevZoom - 0.25));
    }, []);

    const handleMouseDown = useMouseEvents(containerRef, zoom);

    if (!activePage) {
        return null;
    }

    return (
        <div className={styles.previewBox}>
            <PreviewBoxHeader
                activePage={activePage}
                isDisabled={source !== PageSource.LISTED}
                activePageIndex={activePageIndex}
                orderedPages={orderedPages}
                zoom={zoom}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
            />
            <Row
                ref={containerRef}
                justify="center"
                align="middle"
                className={cn(styles["previewBox-image_container"], {
                    [styles["previewBox-image_container--scroll"]]: zoom > 1,
                })}
                style={{ cursor: zoom > 1 ? "grab" : "auto" }}
                onMouseDown={handleMouseDown}
            >
                <Flex
                    justify="center"
                    align="center"
                    className={styles["previewBox-image_container-zoom"]}
                    style={{ transform: `scale(${zoom})` }}
                >
                    {activePage.isCalculated ? (
                        <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: 50 }} color="primary" spin />} />
                    ) : (
                        <img src={activePage.imageUrl} className={styles.previewBoxImage} alt="Preview" />
                    )}
                </Flex>
            </Row>
        </div>
    );
};
