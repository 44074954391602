import React, { FC, useState } from "react";

import { AuthLayout } from "../AuthLayout";
import { Login2faForm } from "./Login2faForm";
import { LoginForm, LoginFormValues } from "./LoginForm";
import { LogoHeader } from "../LogoHeader";

import "../styles.css";

export const LoginView: FC = () => {
    const [use2fa, setUse2fa] = useState(false);
    const [credentials, setCredentials] = useState<LoginFormValues | null>(null);

    return (
        <AuthLayout>
            <LogoHeader />

            {use2fa && <Login2faForm hide2fa={() => setUse2fa(false)} credentials={credentials} />}
            {!use2fa && (
                <LoginForm
                    show2fa={values => {
                        setUse2fa(true);
                        setCredentials(values);
                    }}
                />
            )}
        </AuthLayout>
    );
};
