import React, { FC, useContext } from "react";
import { blue } from "@ant-design/colors";
import Checkbox from "antd/es/checkbox/Checkbox";
import { Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { CarouselContext } from "@dms/modules/DocumentFormModule/context/CarouselContext";
import { IDocumentEnriched, TCarouselMode } from "@dms/types";

import styles from "./CarouselHeader.module.scss";

interface IProps {
    documents: IDocumentEnriched[];
    mode?: TCarouselMode;
}

export const CarouselHeader: FC<IProps> = ({ documents, mode = "DMS_Module" }) => {
    const { checkedAll, indeterminate, handleCheckedAll } = useContext(CarouselContext);

    return (
        <div
            className={styles.header}
            style={{
                backgroundColor: blue[0],
                border: `1px  solid ${blue[1]}`,
            }}
        >
            <div className={styles.headerItems}>
                {mode === "DMS_Module" ? (
                    <div className={styles.headerCheckbox}>
                        <Checkbox
                            onChange={event => {
                                handleCheckedAll(event.target.checked, documents);
                            }}
                            checked={checkedAll}
                            indeterminate={indeterminate}
                        />
                        <Typography.Text>
                            <FormattedMessage id="app.dms.selectAll" />
                        </Typography.Text>
                    </div>
                ) : null}

                <div>
                    <Typography.Text type="secondary">
                        <FormattedMessage id="app.dms.totalDocuments" values={{ count: documents?.length }} />
                    </Typography.Text>
                </div>
            </div>
        </div>
    );
};
