import React, { useContext, useEffect, useMemo, useState } from "react";
import { Bu } from "@binale-tech/shared";

import { CompanyContext } from "scripts/context/CompanyContext";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { BuContext } from "scripts/context/BuContext";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { BuTaxDB } from "../../../../../scripts/models/Bu";
import { BuTaxesSKR } from "../../../../../scripts/models/BuTaxUtils";
import { SHConverter, SHItem } from "../../../../../scripts/core/SollHaben";
import { defaultVatRowConfig } from "./defaultDataSource";
import { IVatRowData } from "./types";
import { useSearchParams } from "react-router-dom";

interface IVATContext {
    period: number;
    canReload: boolean;
    dataSource: IVatRowData[];
}
type VatRouting = { bmg?: number; ust?: number; vst?: number };
export const VATContext = React.createContext<IVATContext>({ dataSource: [], period: null, canReload: false });
interface IVATControlContext {
    editItem: (period: number, stIndex: string, value: number) => void;
    setPeriod: (v: number | null) => void;
    reload: () => void;
}

export const VATControlContext = React.createContext<IVATControlContext>({
    editItem: () => {},
    setPeriod: () => {},
    reload: () => {},
});

const vstVatRoutes = ["66", "61", "62", "67", "63", "59", "64"];
const buModeV: number[] = [
    6,
    7,
    Bu.Bu.BU8,
    Bu.Bu.BU9,
    16,
    17,
    18,
    19,
    Bu.Bu.BU91,
    Bu.Bu.BU92,
    Bu.Bu.BU94,
    Bu.Bu.BU95,
    96,
    97,
    98,
    99,
];

export const VATProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamsPeriod = searchParams.get("period");
    const { companyBuTimeframes } = useContext(BuContext);
    const { yearConfig } = useContext(CompanyContext);
    const { allRecords } = useContext(RecordsContext);
    const payments = useContext(PaymentsContext);
    const [period, setPeriod] = useState<number>(searchParamsPeriod ? Number(searchParamsPeriod) : null);
    const [dirty, setDirty] = useState<boolean>(false);

    useEffect(() => {
        setSearchParams(prev => {
            if (Number.isFinite(period)) {
                prev.set("period", String(period));
            } else {
                prev.delete("period");
            }
            return prev;
        });
    }, [period, setSearchParams]);

    const periodsRecordsDraftDataRef = React.useRef<Pick<IVatRowData, "periods" | "titleSt" | "titleBmg">[]>([]);
    const [periodsRecordsDraftData, setPeriodsRecordsDraftData] = useState<
        Pick<IVatRowData, "periods" | "titleSt" | "titleBmg">[]
    >([]);
    const yearRecords = React.useMemo(
        () => (allRecords.list || []).filter(record => record.date.getFullYear() === yearConfig.year && !record.draft),
        [allRecords, yearConfig.year]
    );
    const periodsDraftData = React.useMemo(() => {
        if (!yearConfig || yearRecords.length === 0) {
            return [];
        }
        if (!companyBuTimeframes) {
            return [];
        }
        if (yearConfig.year < 2022) {
            return [];
        }
        const converter = new SHConverter(yearConfig, companyBuTimeframes, payments);
        const buTimeframe = BuTaxesSKR.getBuTimeframeYearPeriod(
            yearConfig.skr,
            companyBuTimeframes,
            yearConfig.year,
            1
        );
        const getVatRoutes = (v: BuTaxDB, ust13b: Bu.USt13bOption): VatRouting => {
            if (ust13b) {
                const ust13bVatRouting = Bu.getUst13bData(v.bu).options?.find(
                    o => String(o.value) === String(ust13b)
                )?.vatRouting;
                // console.log({ ust13b, ust13bVatRouting, o: Bu.getUst13bData(v.bu).options });
                if (ust13bVatRouting) {
                    return ust13bVatRouting;
                }
            }

            const vatRouting: VatRouting = {};
            if (Number.isFinite(v.MBMG)) {
                vatRouting.bmg = v.MBMG as number;
            }
            if (Number.isFinite(v.MSteuer)) {
                vatRouting.ust = v.MSteuer as number;
            }
            if (Number.isFinite(v.VSteuer)) {
                vatRouting.vst = v.VSteuer;
            }
            return vatRouting;
        };
        const addToSegment = (
            vatRoute: number,
            periodSegment: Map<string, number>,
            shItem: SHItem,
            routeType: "ust" | "vst" | "bmg"
        ) => {
            if (!vatRoute) {
                return;
            }
            const vatRouteStr = String(vatRoute);
            if (!periodSegment.has(vatRouteStr)) {
                periodSegment.set(vatRouteStr, 0);
            }
            const getAmount = () => {
                if (buModeV.includes(shItem.bu)) {
                    // bmg S+ / H-
                    // vst S+ / H-
                    // ust S- / H+
                    switch (routeType) {
                        case "bmg":
                        case "vst":
                            return shItem.isHaben ? -shItem.amount : shItem.amount;
                        case "ust":
                            return shItem.isHaben ? shItem.amount : -shItem.amount;
                    }
                }
                // bmg S- / H+
                // ust S- / H+
                return shItem.isHaben ? shItem.amount : -shItem.amount;
            };
            // console.log("addToSegment", {
            //     vatRoute,
            //     periodSegment,
            //     cv: periodSegment.get(vatRouteStr),
            //     amount: getAmount(),
            //     shItem,
            // });
            periodSegment.set(vatRouteStr, periodSegment.get(vatRouteStr) + getAmount());
        };
        const periodsDistributionBmg: Map<string, number>[] = new Array(14).fill(undefined).map(() => new Map());
        const periodsDistributionSt: Map<string, number>[] = new Array(14).fill(undefined).map(() => new Map());
        yearRecords.forEach(v => {
            converter.getItemsVatMode(v).forEach(item => {
                if (Number.isFinite(item.bu) && item.bu > 0) {
                    // console.group();
                    const buData = buTimeframe.items.find(buTax => buTax.bu === item.bu);
                    if (!buData) {
                        console.log(item.bu);
                        return;
                    }
                    const { bmg, ust, vst } = getVatRoutes(buData, item.USt13b);
                    // console.log({ ust13b: item.USt13b, vatRouting: { bmg, ust, vst }, item, record: v });
                    if (item.isTax) {
                        if (item.taxType === "ust") {
                            addToSegment(ust, periodsDistributionSt[v.period], item, "ust");
                        }
                        if (item.taxType === "vst") {
                            addToSegment(vst, periodsDistributionSt[v.period], item, "vst");
                        }
                    } else {
                        addToSegment(bmg, periodsDistributionBmg[v.period], item, "bmg");
                    }
                    // console.log(periodsDistributionSt[v.period], periodsDistributionBmg[v.period]);
                    // console.groupEnd();
                }
            });
        });
        const periodRows = defaultVatRowConfig.map((data, index) => {
            const periods = new Array(14).fill(undefined).map((_, pidx) => {
                const bmgDraft = periodsDistributionBmg[pidx].get(data.titleBmg) ?? 0;
                // bmg should be rounded to the euro (no cents) // or not
                const bmg = bmgDraft; //Math.floor(bmgDraft / 100) * 100;
                const getSt = () => {
                    if (!data.titleSt) {
                        return 0;
                    }
                    if (data.titleSt.startsWith("X")) {
                        if (["X1", "X3"].includes(data.titleSt)) {
                            return Math.round(bmg * 0.19);
                        }
                        if (["X2", "X4"].includes(data.titleSt)) {
                            return Math.round(bmg * 0.07);
                        }
                    }
                    return periodsDistributionSt[pidx].get(data.titleSt) ?? 0;
                };
                return {
                    bmg,
                    st: getSt(),
                };
            });
            // add period 01.01 to January
            periods[1].st += periods[0].st;
            periods[1].bmg += periods[0].bmg;
            // add period 31.12 to December
            periods[12].st += periods[13].st;
            periods[12].bmg += periods[13].bmg;
            const periodRow: Pick<IVatRowData, "periods" | "titleSt" | "titleBmg"> = {
                periods,
                titleSt: data.titleSt,
                titleBmg: data.titleBmg,
            };
            return periodRow;
        });

        return periodRows;
    }, [companyBuTimeframes, yearConfig, yearRecords, payments]);

    React.useEffect(() => {
        periodsRecordsDraftDataRef.current = periodsDraftData;
        if (!dirty) {
            setPeriodsRecordsDraftData(periodsDraftData);
        }
    }, [periodsDraftData, dirty]);

    const ctxDataSource: IVATContext["dataSource"] = useMemo(() => {
        if (!periodsRecordsDraftData.length) {
            return [];
        }
        const dataSource = defaultVatRowConfig.map((data, rowIdx) => {
            const { periods } = periodsRecordsDraftData[rowIdx];
            // postprocess aggregating X queries
            if (data.titleSt && data.titleSt.startsWith("X")) {
                if (data.titleSt === "X5") {
                    periods.forEach((segment, pidx) => {
                        segment.st = periodsRecordsDraftData
                            .filter(row =>
                                ["X1", "X2", "36", "80", "X3", "X4", "98", "96", "47", "74", "85"].includes(row.titleSt)
                            )
                            .reduce((acc, v) => acc + v.periods[pidx].st, 0);
                    });
                } else if (data.titleSt === "X6") {
                    periods.forEach((segment, pidx) => {
                        segment.st = periodsRecordsDraftData
                            .filter(row => vstVatRoutes.includes(row.titleSt))
                            .reduce((acc, v) => acc + v.periods[pidx].st, 0);
                    });
                } else if (data.titleSt === "X7") {
                    periods.forEach((segment, pidx) => {
                        const rowX5 = periodsRecordsDraftData.find(row => row.titleSt === "X5");
                        const rowX6 = periodsRecordsDraftData.find(row => row.titleSt === "X6");
                        segment.st = rowX5.periods[pidx].st - rowX6.periods[pidx].st;
                    });
                } else if (data.titleSt === "X8") {
                    periods.forEach((segment, pidx) => {
                        segment.st = periodsRecordsDraftData
                            .filter(row => ["X7", "65", "69"].includes(row.titleSt))
                            .reduce((acc, v) => acc + v.periods[pidx].st, 0);
                    });
                }
            } else if (data.titleSt === "83") {
                periods.forEach((segment, pidx) => {
                    const rowX8 = periodsRecordsDraftData.find(row => row.titleSt === "X8");
                    const row39 = periodsRecordsDraftData.find(row => row.titleSt === "39");
                    segment.st = rowX8.periods[pidx].st - row39.periods[pidx].st;
                });
            }
            const fullRow: IVatRowData = {
                ...data,
                key: `${rowIdx}:${data.titleSt}:${data.titleBmg}`,
                periods,
                year: {
                    // since we add periods 01.01 and 31.12 to months 1 and 12, we need to filter it out of the aggregation
                    bmg: periods.filter((p, idx) => idx > 0 && idx < 13).reduce((acc, v) => acc + v.bmg, 0),
                    st: periods.filter((p, idx) => idx > 0 && idx < 13).reduce((acc, v) => acc + v.st, 0),
                },
                quartals: Array(4)
                    .fill(undefined)
                    .map((_, qidx) => ({
                        bmg: periods
                            .filter((v, pidx) => pidx > qidx * 3 && pidx <= (qidx + 1) * 3)
                            .reduce((acc, v) => acc + v.bmg, 0),
                        st: periods
                            .filter((v, pidx) => pidx > qidx * 3 && pidx <= (qidx + 1) * 3)
                            .reduce((acc, v) => acc + v.st, 0),
                    })),
            };
            return fullRow;
        });
        return dataSource;
    }, [periodsRecordsDraftData]);

    const controlValue = React.useMemo(
        () => ({
            setPeriod,
            reload: () => {
                setPeriodsRecordsDraftData(periodsRecordsDraftDataRef.current);
            },
            editItem: (period: number, stIndex: string, value: number) => {
                setDirty(true);
                setPeriodsRecordsDraftData(data => {
                    const row = data.find(v => v.titleSt === stIndex);
                    row.periods[period] = { ...row.periods[period], st: value };
                    return [...data];
                });
            },
        }),
        []
    );

    const ctxValue = React.useMemo(
        (): IVATContext => ({
            dataSource: ctxDataSource,
            period,
            canReload: dirty && periodsRecordsDraftDataRef.current !== periodsRecordsDraftData,
        }),
        [ctxDataSource, dirty, period, periodsRecordsDraftData]
    );

    return (
        <VATContext.Provider value={ctxValue}>
            <VATControlContext.Provider value={controlValue}> {children}</VATControlContext.Provider>
        </VATContext.Provider>
    );
};
