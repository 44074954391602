import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";

import { Layout2faDisabled } from "./Layout2faDisabled";
import { Layout2faEnabled } from "./Layout2faEnabled";
import { StepsLayout } from "./StepsLayout";
import { UserContext } from "scripts/context/UserProvider";

enum Layouts {
    Enabled,
    Steps,
    Disabled,
}

export const ProfileSecurity: React.FC = () => {
    const [layout, setLayout] = useState(Layouts.Disabled);
    const { is2faEnabled } = useContext(UserContext);

    useEffect(() => {
        setLayout(is2faEnabled ? Layouts.Enabled : Layouts.Disabled);
    }, [is2faEnabled]);

    return (
        <Row>
            <Col sm={24} lg={16} xxl={10}>
                {layout === Layouts.Disabled && <Layout2faDisabled onEnable2fa={() => setLayout(Layouts.Steps)} />}
                {layout === Layouts.Steps && <StepsLayout onCancel={() => setLayout(Layouts.Disabled)} />}
                {layout === Layouts.Enabled && <Layout2faEnabled />}
            </Col>
        </Row>
    );
};
