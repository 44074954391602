import React from "react";
import ReadinessProvider from "./DataReadyness";
import { AppSettingsProvider } from "./AppSettingsProvider";
import { CompanyProvider } from "./CompanyContext";
import { ContactsProvider } from "./ContactsContext";
import { DmsDataProvider } from "./DmsDataProvider";
import { EntitiesProvider } from "./KontoEntitiesProvider";
import { PaymentsProvider } from "./PaymentsProvider";
import { RecordsControlProvider } from "./recordsContext/RecordsControlCtx";
import { RecordsProvider } from "./recordsContext/RecordsCtx";
import { UserProvider } from "./UserProvider";
import { UserSettingsProvider } from "./UserSettingsProvider";
import { DatevContextProvider } from "./DatevContext";
import { BanksContextProvider } from "./BanksContext";

export const ContextProvider: React.FC<React.PropsWithChildren> = props => {
    return (
        <AppSettingsProvider>
            <UserProvider>
                <DatevContextProvider>
                    <CompanyProvider>
                        <UserSettingsProvider>
                            <EntitiesProvider>
                                <RecordsProvider>
                                    <RecordsControlProvider>
                                        <PaymentsProvider>
                                            <ContactsProvider>
                                                <ReadinessProvider>
                                                    <BanksContextProvider>
                                                        <DmsDataProvider>{props.children}</DmsDataProvider>
                                                    </BanksContextProvider>
                                                </ReadinessProvider>
                                            </ContactsProvider>
                                        </PaymentsProvider>
                                    </RecordsControlProvider>
                                </RecordsProvider>
                            </EntitiesProvider>
                        </UserSettingsProvider>
                    </CompanyProvider>
                </DatevContextProvider>
            </UserProvider>
        </AppSettingsProvider>
    );
};
