import React, { useContext, useState } from "react";
import cn from "classnames";
import { Table } from "antd";

import { ReadinessContext } from "scripts/context/DataReadyness";
import { Container } from "../../../../components/shared/appearance/page";

import "../../../../components/shared/Page.css";
import { BWAToolbar } from "./BWAToolbar";
import "./BWAView.css";

import { useBWAColumns } from "./hooks/useBWAColumns";
import { useBWAAccountMap } from "./hooks/useBWAAccountMap";
import { useBWATableDataSource } from "./hooks/useBWATableDataSource";

export const BWAView: React.FC = () => {
    const { isReady } = useContext(ReadinessContext);

    const [period, setPeriod] = useState<number>(null);
    const [selected, setSelected] = useState<number>();
    const [modeAll, setModeAll] = useState<boolean>();

    const columns = useBWAColumns(period);
    const bwaData = useBWAAccountMap();
    const dataSource = useBWATableDataSource(bwaData, modeAll);

    return (
        <div style={{ margin: 8 }} onClick={() => setSelected(null)}>
            <BWAToolbar
                showAllMonths={modeAll}
                onShowAllMonths={setModeAll}
                period={period}
                onChangePeriod={setPeriod}
            />
            <Container
                style={{
                    width: "calc(100% - 16px)",
                    height: "calc(100% - 50px)",
                    position: "absolute",
                    maxWidth: Number.isFinite(period) ? 1000 : undefined,
                }}
            >
                {(w, h) => {
                    return (
                        <div>
                            <Table
                                loading={!isReady}
                                className="BWATable antd-table-with-selection"
                                pagination={{ hideOnSinglePage: true, defaultPageSize: 10000 }}
                                columns={columns}
                                dataSource={dataSource}
                                size="small"
                                rowClassName={tableItem =>
                                    cn({
                                        "table__row--disabled": !tableItem.konto,
                                        "row--selected": selected === tableItem.key,
                                    })
                                }
                                bordered
                                onRow={data => ({
                                    onClick: e => {
                                        setSelected(data.key);
                                        e.stopPropagation();
                                    },
                                })}
                                scroll={{ y: h - 50, x: Number.isFinite(period) ? 990 : w - 10 }}
                            />
                        </div>
                    );
                }}
            </Container>
        </div>
    );
};
