import { useCallback } from "react";
import { GenericRecord } from "../../../../../scripts/models";
import { useFormRecordLifecycle } from "@app/components/recordform/hooks/useFormRecordLifecycle";

export const useFormHandlerRecordTemplate = () => {
    const { receiveRecord } = useFormRecordLifecycle();

    return useCallback(
        (template: GenericRecord) => {
            if (template) {
                receiveRecord(template, true);
            }
        },
        [receiveRecord]
    );
};
