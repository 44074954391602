import { Input } from "antd";
import React, { type FC } from "react";
import { useIntl } from "react-intl";

import styles from "./EditSubTypeInput.module.scss";

interface IProps {
    value?: string;
    onChange: (arg: string) => void;
    isError?: boolean;
}

export const EditSubTypeInput: FC<IProps> = ({ value, onChange, isError }) => {
    const intl = useIntl();

    const handleInputValue = (inputValue: string) => {
        onChange(inputValue);
    };

    return (
        <Input
            className={styles.input}
            maxLength={80}
            showCount
            placeholder={intl.formatMessage({ id: "app.dms.add_subtype" })}
            onChange={e => {
                handleInputValue(e.target.value);
            }}
            value={value}
            status={isError ? "error" : ""}
        />
    );
};
