import React, { ComponentProps, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getName } from "i18n-iso-countries";
import { GQL } from "@binale-tech/shared";
import { Input, Select, Tag } from "antd";

import { ContactsContext } from "scripts/context/ContactsContext";
import { CountriesCombobox, ICountriesOption } from "../components/CountriesCombobox";

interface StringFieldProps {
    value: string;
    onChange: (value: string) => void;
    minWidth?: number;
    label: React.ReactNode;
}

const TypeColors: Record<GQL.IContactRelation, React.ComponentProps<typeof Tag>["color"]> = {
    [GQL.IContactRelation.Client]: "red",
    [GQL.IContactRelation.Employee]: "purple",
    [GQL.IContactRelation.Other]: "green",
    [GQL.IContactRelation.Partner]: "cyan",
    [GQL.IContactRelation.Shareholder]: "geekblue",
};

export const StringField: React.FC<StringFieldProps> = ({ value, onChange, label, minWidth }) => {
    const [innerValue, setInnerValue] = useState(value);

    const style: React.CSSProperties = {};
    if (minWidth) {
        style.minWidth = minWidth;
    }

    useEffect(() => {
        setInnerValue(value || "");
    }, [value]);

    return (
        <div style={style}>
            {label}
            <Input.Search
                size="small"
                allowClear
                value={innerValue || ""}
                onChange={event => setInnerValue(event.target.value)}
                onSearch={onChange}
                onBlur={event => {
                    const blurValue = event.target.value;
                    if (blurValue !== (value || "")) {
                        onChange(blurValue || undefined);
                    }
                }}
            />
        </div>
    );
};

export const TypeField: React.FC<{
    value: GQL.IContactType;
    onChange: (value: GQL.IContactType) => void;
}> = ({ value, onChange }) => (
    <div style={{ minWidth: 150 }}>
        <FormattedMessage id="app.fields.type" />
        <div>
            <Select
                size="small"
                style={{ width: "100%" }}
                allowClear
                value={value}
                onChange={onChange}
                options={Object.values(GQL.IContactType).map(v => ({
                    value: v,
                    label: <FormattedMessage id={"app.contacts.type." + v} />,
                }))}
            />
        </div>
    </div>
);

const tagRender = ({ value, ...props }: ComponentProps<typeof Tag> & { value?: ReactNode }) => (
    <Tag {...props} color={TypeColors[value as GQL.IContactRelation]} style={{ marginRight: 3 }}>
        <FormattedMessage id={`app.titles.contacts.${value}`} />
    </Tag>
);

export const ClassificationField: React.FC<{
    value: GQL.IContactRelation[];
    onChange: (value: GQL.IContactRelation[]) => void;
}> = ({ onChange, value }) => {
    return (
        <div style={{ minWidth: 100 }}>
            <FormattedMessage id="app.titles.contacts.classification" />
            <div>
                <Select
                    size="small"
                    style={{ width: "100%" }}
                    allowClear
                    mode="multiple"
                    tagRender={tagRender}
                    value={value}
                    onChange={onChange}
                    options={Object.values(GQL.IContactRelation).map(v => ({
                        value: v,
                        label: <FormattedMessage id={`app.titles.contacts.${v}`} tagName="b" />,
                    }))}
                />
            </div>
        </div>
    );
};

export const CountriesField: React.FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => {
    const { contacts } = useContext(ContactsContext);
    const intl = useIntl();

    const usedCountries: ICountriesOption[] = useMemo(() => {
        const usedCodes = contacts.reduce((acc, item) => {
            item.addresses?.forEach(address => {
                if (address.countryCode && !acc.includes(address.countryCode)) {
                    acc.push(address.countryCode);
                }
            });
            return acc;
        }, []);

        return usedCodes.map(countryCode => ({
            name: getName(countryCode, intl.locale),
            value: countryCode,
        }));
    }, [contacts, intl.locale]);

    return (
        <div style={{ minWidth: 120 }}>
            <FormattedMessage id="app.fields.countryCode" />
            <div>
                <CountriesCombobox
                    optionsList={usedCountries}
                    allowClear={false}
                    inputProps={{ size: "small", allowClear: true }}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};
