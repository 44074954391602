import React, { FC, useContext } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { OptionsValues } from "@banks/types/enums";
import { Select, Typography } from "antd";

import { FormattedMessage } from "react-intl";
import { BanksContext } from "../../../../../../scripts/context/BanksContext";

type TProps = {
    selectBank: (arg: string) => void;
    value: string;
};

const { Text } = Typography;

export const BankSelect: FC<TProps> = ({ selectBank, value }) => {
    const { banksData } = useContext(BanksContext);

    const options = Object.values(banksData).map(el => {
        const key = el.id;
        return {
            label: el.bankName,
            value: key,
        };
    });

    return (
        <Select
            options={[
                ...options,
                {
                    label: (
                        <span>
                            <PlusOutlined />
                            <Text style={{ marginLeft: 5 }}>
                                <FormattedMessage id={"app.banks.newBank"} />
                            </Text>
                        </span>
                    ),
                    value: OptionsValues.ADD_NEW_BANK,
                },
            ]}
            style={{ minWidth: 300, maxWidth: 400 }}
            popupMatchSelectWidth={true}
            value={value}
            onSelect={val => selectBank(val)}
            placeholder="Select Bank"
        />
    );
};
