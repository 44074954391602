import React, { SyntheticEvent } from "react";
import cn from "classnames";
import { Input, InputProps, InputRef } from "antd";
import { Base, Utils } from "@binale-tech/shared";

export type CurrencyValue = {
    amount: number | null;
    originalAmount?: number;
    currency?: Base.CurrencyConfig;
};

interface BruttoInputProps extends Omit<InputProps, "onChange" | "onFocus" | "onBlur" | "onKeyDown"> {
    value?: number;
    onChange?: (value: CurrencyValue, e?: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (value: CurrencyValue) => void;
    onFocus?: (value: CurrencyValue) => void;
    onKeyDown?: (value: CurrencyValue, e?: React.KeyboardEvent<HTMLInputElement>) => void;
}

type State = {
    text: string;
    brutto?: number | null;
    digits: number;
};

export class BruttoInput extends React.Component<BruttoInputProps, State> {
    private inputGroupRef = React.createRef<InputRef>();
    static MAX_VALUE = Number.MAX_SAFE_INTEGER;
    static defaultProps = {
        placeholder: Utils.CurrencyUtils.currencyFormat(0, Utils.CurrencyUtils.CurrencyFormatter.PRECISION),
        maxLength: 14,
    };
    state: State = {
        text: "",
        brutto: undefined,
        digits: Utils.CurrencyUtils.CurrencyFormatter.PRECISION,
    };

    private isValueUpdated = true;

    static getNewStateFromProps(props: BruttoInputProps, state: State): State {
        const { digits } = state;
        const { value } = props;
        const text = !value ? "" : Utils.CurrencyUtils.currencyFormat(value, digits, digits === 0);
        return {
            brutto: value,
            text,
            digits,
        };
    }

    componentDidMount() {
        this.setState(BruttoInput.getNewStateFromProps(this.props, this.state));
    }

    UNSAFE_componentWillReceiveProps(nextProps: BruttoInputProps) {
        this.setState(state => {
            return BruttoInput.getNewStateFromProps(nextProps, state);
        });
    }

    public focus = () => {
        this.inputGroupRef.current?.focus({ cursor: "all" });
    };

    protected processChange(e: SyntheticEvent<HTMLInputElement>, callback: (newBrutto: number | null) => void) {
        e = this.getEvent(e);
        const target = e.currentTarget;
        const newText = target.value;
        const oldText = this.state.text;
        let changedText = oldText;
        let digits = 0;
        const selectionStart = target.selectionStart || 0;
        const selectionEnd = target.selectionEnd || 0;
        this.isValueUpdated = true;

        if (oldText !== newText) {
            // Add
            if (newText === "0" || newText === "0," || newText === "0,0") {
                changedText = newText;
                this.isValueUpdated = false;
            } else if (newText === "-" || newText === "-0" || newText === "-0," || newText === "-0,0") {
                changedText = newText;
                this.isValueUpdated = false;
            } else if (oldText.length < newText.length) {
                const changeText = newText.substring(selectionStart - 1, selectionEnd);
                if (/[0-9,.-]/.test(changeText)) {
                    const hasMinus = oldText.includes("-");
                    const num = Utils.CurrencyUtils.CurrencyFormatter.textToCents(newText);
                    switch (changeText) {
                        case Utils.CurrencyUtils.CurrencyFormatter.THOUSANDS_SEPARATOR:
                            break;
                        case "-":
                            changedText = hasMinus ? oldText.replace("-", "") : "-" + oldText;
                            this.isValueUpdated = oldText !== "";
                            digits =
                                oldText.length -
                                1 -
                                oldText.indexOf(Utils.CurrencyUtils.CurrencyFormatter.DECIMAL_SEPARATOR);
                            digits = digits === oldText.length ? 0 : digits;
                            break;
                        case Utils.CurrencyUtils.CurrencyFormatter.DECIMAL_SEPARATOR:
                            if (
                                oldText !== "" &&
                                !oldText.includes(Utils.CurrencyUtils.CurrencyFormatter.DECIMAL_SEPARATOR) &&
                                newText.indexOf(Utils.CurrencyUtils.CurrencyFormatter.DECIMAL_SEPARATOR) ===
                                    oldText.length
                            ) {
                                changedText = oldText + Utils.CurrencyUtils.CurrencyFormatter.DECIMAL_SEPARATOR;
                                this.isValueUpdated = false;
                            }
                            break;
                        default:
                            digits = 0;
                            if (
                                oldText !== "" &&
                                newText.includes(Utils.CurrencyUtils.CurrencyFormatter.DECIMAL_SEPARATOR)
                            ) {
                                const yetFractionDigits =
                                    oldText.length -
                                    1 -
                                    oldText.indexOf(Utils.CurrencyUtils.CurrencyFormatter.DECIMAL_SEPARATOR);
                                if (yetFractionDigits === Utils.CurrencyUtils.CurrencyFormatter.PRECISION) {
                                    digits = Utils.CurrencyUtils.CurrencyFormatter.PRECISION;
                                } else {
                                    digits =
                                        yetFractionDigits < Utils.CurrencyUtils.CurrencyFormatter.PRECISION
                                            ? yetFractionDigits + 1
                                            : 0;
                                }
                            }
                            if (num < BruttoInput.MAX_VALUE) {
                                changedText = Utils.CurrencyUtils.currencyFormat(num, digits, digits === 0);
                            }
                            break;
                    }
                }
            } else {
                // Delete
                digits = newText.includes(Utils.CurrencyUtils.CurrencyFormatter.DECIMAL_SEPARATOR)
                    ? newText.length - 1 - newText.indexOf(Utils.CurrencyUtils.CurrencyFormatter.DECIMAL_SEPARATOR)
                    : 0;
                const num = Utils.CurrencyUtils.CurrencyFormatter.textToCents(newText);
                const lastChar = newText.charAt(newText.length - 1);
                if (
                    oldText.length > newText.length &&
                    lastChar === Utils.CurrencyUtils.CurrencyFormatter.DECIMAL_SEPARATOR
                ) {
                    changedText = newText === "" ? "" : newText;
                    this.isValueUpdated = false;
                } else {
                    changedText = newText === "" ? "" : Utils.CurrencyUtils.currencyFormat(num, digits, digits === 0);
                }
            }
        }
        const text = changedText;
        const brutto = text === "" ? null : Utils.CurrencyUtils.CurrencyFormatter.textToCents(text);
        this.setState(
            {
                text,
                digits,
                brutto: brutto ? brutto : null,
            },
            () => {
                callback(brutto);
            }
        );
    }
    protected getEvent = (event: SyntheticEvent<HTMLInputElement>): SyntheticEvent<HTMLInputElement> => {
        return event;
    };

    protected onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.processChange(e, newBrutto => {
            if (this.props.onChange && this.isValueUpdated) {
                this.props.onChange({ amount: newBrutto }, e);
            }
        });
    };

    protected onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        this.processChange(e, newBrutto => {
            if (this.props.onKeyDown && this.isValueUpdated) {
                this.props.onKeyDown({ amount: newBrutto }, e);
            }
        });
    };

    protected onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        this.processChange(e, newBrutto => {
            const digits = Utils.CurrencyUtils.CurrencyFormatter.PRECISION;
            const text = !newBrutto ? "" : Utils.CurrencyUtils.currencyFormat(newBrutto, digits, false);
            this.setState({ digits, text }, () => {
                if (this.isValueUpdated) {
                    this.props.onChange && this.props.onChange({ amount: newBrutto });
                    this.props.onBlur && this.props.onBlur({ amount: newBrutto });
                }
            });
        });
    };

    protected onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
        if (this.props.onFocus && this.isValueUpdated) {
            this.props.onFocus({ amount: this.state.brutto || 0 });
        }
    };

    render() {
        return (
            <Input
                ref={this.inputGroupRef}
                type="text"
                {...this.props}
                className={cn(this.props.className, "BruttoInputBlock")}
                value={this.state.text || ""}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
            />
        );
    }
}
