import { GQL } from "@binale-tech/shared";
import { Col, Flex, Row } from "antd";
import dayjs from "dayjs";
import React, { type FC, useContext, useMemo } from "react";

import { DocumentGrid } from "@dms/components/DocumentGrid/DocumentGrid";
import { Toolbar } from "@dms/components/Toolbar/Toolbar";
import { DmsAppContext, DmsAppControlContext, DmsDataContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { documentSearchPredicate } from "@dms/scripts/helpers/documentSearchPredicate";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { ITableDocument } from "@dms/types";
import { DocumentTableModule } from "@dms/modules/DocumentTableModule";
import { RecordDataPopover } from "@dms/components/RecordDataTable/RecordDataPopover";
import { DocumentsApi } from "@dms/scripts/DocumentsApi";

enum TypeViewEnum {
    list = "list",
    grid = "grid",
}

export const DocumentListModule: FC = () => {
    const { userConfig } = useContext(DmsUserSettingsContext);
    const { folderDocuments, view, activeType, selectedRowKeys, searchValue } = useContext(DmsAppContext);
    const { setFocusedRow } = useContext(DmsAppControlContext);
    const { dmsRecordsData } = useContext(DmsDataContext);
    const filterConfig = DmsUtils.getFilterConfig(userConfig.filterConfig, activeType);

    const dataSource: ITableDocument[] = useMemo(() => {
        setFocusedRow(null);
        return folderDocuments
            .filter(el => {
                if (!filterConfig?.isDraftSelected) {
                    return true;
                }
                return DmsUtils.getDocumentStatus(el) === GQL.IDocumentStatus.Draft;
            })
            .filter(el => {
                if (!filterConfig?.selectedFilter) {
                    return true;
                }
                return selectedRowKeys.includes(el.key);
            })
            .filter(el => {
                const year = filterConfig?.year ?? "all";
                const month = filterConfig?.month ?? -1;

                if (year === "empty") {
                    return !el.documentDate;
                }

                return (
                    (year === "all" || dayjs(el.documentDate, "DD.MM.YYYY").year() === +year) &&
                    (month === -1 || dayjs(el.documentDate, "DD.MM.YYYY").month() === month)
                );
            })
            .filter(el => {
                if (!searchValue) {
                    return true;
                }
                return documentSearchPredicate(el, searchValue);
            })
            .map(document => {
                return {
                    ...document,
                    isAttached: dmsRecordsData.recordsAssetsSet.has(DocumentsApi.urlFormatter(document.fileUrl)) && (
                        <RecordDataPopover url={document.fileUrl} position={"table"} />
                    ),
                };
            });
    }, [
        folderDocuments,
        filterConfig?.isDraftSelected,
        filterConfig?.selectedFilter,
        filterConfig?.month,
        filterConfig?.year,
        searchValue,
        dmsRecordsData.recordsAssetsSet,
    ]);

    return (
        <Flex vertical style={{ height: "100%", position: "relative" }}>
            <Toolbar />
            {view === TypeViewEnum.list && <DocumentTableModule dataSource={dataSource} />}
            {view === TypeViewEnum.grid && <DocumentGrid dataSource={dataSource} />}
        </Flex>
    );
};
