import React, { createContext, FC, ReactNode, useState } from "react";

interface IModalData {
    title: string;
    component: ReactNode;
}

type TValue = {
    open: boolean;
    modalData: IModalData;
};

type TActionValue = {
    openModal: (newValue: IModalData) => void;
    closeModal: () => void;
};

const initialValue: TValue = {
    open: false,
    modalData: null,
};

const initialActionValue: TActionValue = {
    openModal: () => {},
    closeModal: () => {},
};

export const InvoiceModalContext = createContext<TValue>(initialValue);
export const InvoiceModalControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const InvoiceModalProvider: FC<TProps> = ({ children }) => {
    const [modalData, setModalData] = useState<IModalData>(initialValue.modalData);
    const value: TValue = { open: modalData !== null, modalData };

    const actions: TActionValue = {
        openModal: (newValue: IModalData) => setModalData(newValue),
        closeModal: () => setModalData(null),
    };

    return (
        <InvoiceModalContext.Provider value={value}>
            <InvoiceModalControlContext.Provider value={actions}>{children}</InvoiceModalControlContext.Provider>
        </InvoiceModalContext.Provider>
    );
};
