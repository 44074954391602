import React, { FC } from "react";
import { BankProvider } from "../BankProvider";
import { TemplatesViewContent } from "@app/views/productSharedComponents/templates/TemplatesViewContent";

export const BankTemplatesView: FC = () => {
    return (
        <BankProvider>
            <TemplatesViewContent />
        </BankProvider>
    );
};
