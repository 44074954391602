import React, { FC, useContext, useEffect, useState } from "react";
import { PdfToolsContext, PdfToolsControlContext } from "../../context";
import { UploadedDocumentItem } from "./components/UploadedDocumentItem";
import { DroppableIds } from "../../types";
import { Droppable } from "react-beautiful-dnd";
import styles from "./UploadDocumentContainer.module.scss";
import { getElemSize } from "../../tools/helpers";
import { DropFileZone } from "../DropFileZone/DropFileZone";

type TProps = {
    uploadFile: (files: File[]) => Promise<void>;
};

export const UploadDocumentContainer: FC<TProps> = ({ uploadFile }) => {
    const { viewUploadDocuments } = useContext(PdfToolsContext);
    const { setActivePageId } = useContext(PdfToolsControlContext);

    const [wrapperSize, setWrapperSize] = useState({ width: "100%", height: 0 });

    useEffect(() => {
        function onResize() {
            const { height } = getElemSize("carouselWrapper");
            setWrapperSize(prev => {
                return { ...prev, height };
            });
        }

        onResize();
        addEventListener("resize", onResize);

        return () => {
            removeEventListener("resize", onResize);
        };
    }, []);

    return (
        <div style={wrapperSize} className="pdf-tools-Upload_file">
            {viewUploadDocuments.length ? (
                <Droppable droppableId={DroppableIds.UploadedPages} isDropDisabled={true}>
                    {provided => {
                        return (
                            <div
                                className={styles.uploadedPagesWrapper}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <UploadedDocumentItem
                                    draggableId={viewUploadDocuments[0].id}
                                    pages={viewUploadDocuments[0].pages}
                                    key={viewUploadDocuments[0].id}
                                    idx={0}
                                    onClick={setActivePageId}
                                />
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            ) : (
                <DropFileZone uploadFile={uploadFile} multiple={false} />
            )}
        </div>
    );
};
