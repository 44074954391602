import React from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";

interface HeaderWithTooltipProps {
    id: string;
}

export const HeaderWithTooltip: React.FC<HeaderWithTooltipProps> = ({ id }) => {
    const onTooltipTitleRender = (params: { id: string }) => {
        return <FormattedMessage id={params.id} />;
    };

    return (
        <Tooltip title={onTooltipTitleRender({ id })}>
            <span>
                <FormattedMessage id={id} />
            </span>
        </Tooltip>
    );
};
