import React, { useContext, useMemo } from "react";
import Select, { RefSelectProps, SelectProps } from "antd/es/select";
import { Bu } from "@binale-tech/shared";
import { FormattedMessage } from "react-intl";

import Category from "scripts/models/Category";
import { BaseSelect } from "../BaseSelect";
import { BuContext } from "../../../../../../scripts/context/BuContext";
import { CompanyContext } from "../../../../../../scripts/context/CompanyContext";
import { BuTaxesSKR } from "scripts/models/BuTaxUtils";

interface UstProps extends Omit<SelectProps<Bu.Bu>, "value" | "onChange"> {
    category?: Category;
    recordDate?: Date;
    value?: Bu.Bu;
    onChange?: (value: Bu.Bu) => void;
}

export const UstCombobox = React.forwardRef<RefSelectProps, UstProps>(function UstCombobox(
    { category, recordDate, ...props },
    ref
) {
    const { yearConfig } = useContext(CompanyContext);
    const skr = yearConfig?.skr;
    const { companyBuTimeframes } = useContext(BuContext);
    const vatOptions = useMemo(
        () => BuTaxesSKR.getCategoryBuTaxListYear(category, skr, yearConfig.year, companyBuTimeframes) || [],
        [category, skr, recordDate, companyBuTimeframes]
    );

    return (
        <BaseSelect
            ref={ref}
            getPopupContainer={trigger => trigger.parentNode}
            notFoundContent={<FormattedMessage id="app.components.table.no_items" />}
            style={{ width: "100%" }}
            {...props}
            disabled={category?.isAutoBu() || props.disabled}
        >
            {vatOptions.map((option, index) => (
                <Select.Option key={`option-${index}`} value={option.bu}>
                    {option.bu === Bu.Bu.KU ? <span>&nbsp;</span> : option.text}
                </Select.Option>
            ))}
        </BaseSelect>
    );
});
