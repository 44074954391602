import { useCallback, useContext } from "react";
import { Category, Creditor } from "../../../../../scripts/models";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { useFormValidators } from "../useFormValidators";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";

export const useFormHandlerRecordAccount = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setRecordFieldErrorState } = useFormValidators();
    const { updateEditableRecord } = useFormRecordStateUpdater();
    return useCallback(
        (recordCategoryCreditor: Creditor | Category) => {
            updateEditableRecord({ recordCategoryCreditor });
            setRecordFieldErrorState(refsHtml.REF_rCATEGORYCREDITOR, false);
        },
        [refsHtml.REF_rCATEGORYCREDITOR, updateEditableRecord, setRecordFieldErrorState]
    );
};
