import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Button, Dropdown, Input, InputRef, Row } from "antd";
import { CheckOutlined, DownloadOutlined, EditOutlined, ExportOutlined, MoreOutlined } from "@ant-design/icons";

import { sanitize } from "../../tools/sanitizeFilename";

import styles from "./DocInput.module.scss";
import { FormattedMessage } from "react-intl";
import { MenuProps } from "antd/es/menu";
import { useCanWrite } from "../../../scripts/infrastructure/hooks";
import { GQL } from "@binale-tech/shared";

export interface IDocInputProps {
    visualIndex: number;
    name: string;
    selected: boolean;
    editing: boolean;
    onSelectDocument: () => void;
    onStartEdit: () => void;
    onFinishEdit: (name: string) => void;
    onFinishAndSelectNext?: (name: string) => void;
    onDownload?: () => void;
    onExportToDms?: () => void;
}

export const DocInput: FC<IDocInputProps> = props => {
    const { name, selected, editing, onFinishEdit } = props;
    const cleanName = name.replace(/\.pdf$/, "");
    const inputRef = useRef<InputRef>();
    const [value, setValue] = useState(cleanName);

    useEffect(() => setValue(cleanName), [cleanName]);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    useEffect(() => {
        if (!selected && editing) {
            onFinishEdit(value + ".pdf");
        }
    }, [selected, value, editing, onFinishEdit]);

    const handleClick = () => {
        if (!selected) {
            props.onSelectDocument();
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" || e.key === "Tab") {
            props.onFinishAndSelectNext(value + ".pdf");
        }
        if (e.key === "Escape") {
            setValue(cleanName);
            onFinishEdit(name);
        }
    };

    const handleEditClick = () => {
        if (editing) {
            onFinishEdit(value + ".pdf");
        } else {
            props.onSelectDocument();
            props.onStartEdit();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(sanitize(e.target.value));
    };

    const handleInputBlur = () => {
        onFinishEdit(value + ".pdf");
    };

    const dropdownItems: MenuProps["items"] = [
        {
            label: <FormattedMessage id="app.button.download" />,
            key: 1,
            icon: <DownloadOutlined />,
            onClick: () => props.onDownload(),
        },
        {
            label: <FormattedMessage id="app.button.send_to_dms" />,
            key: 2,
            icon: <ExportOutlined />,
            onClick: () => props.onExportToDms(),
            disabled: !useCanWrite(GQL.IProductKey.Dms),
        },
    ];

    return (
        <Row style={{ marginBottom: 5, flexWrap: "nowrap" }}>
            <div
                style={{ cursor: selected ? "default" : "pointer" }}
                className={styles.docInputWrapper}
                onClick={handleClick}
            >
                <Input
                    addonBefore={props.visualIndex}
                    ref={inputRef}
                    value={value}
                    disabled={!editing}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleInputBlur}
                    className={classNames({
                        [styles.docInputActive]: selected,
                    })}
                    addonAfter=".pdf"
                />
                {!editing && <div className={styles.docInputWrapperFiller}></div>}
            </div>
            <Button
                tabIndex={-1}
                onClick={handleEditClick}
                type={editing ? "primary" : "default"}
                shape="circle"
                icon={editing ? <CheckOutlined /> : <EditOutlined />}
                style={{ marginLeft: 5 }}
                data-testid="edit"
            />
            <Dropdown menu={{ items: dropdownItems }} trigger={["click"]}>
                <Button tabIndex={-1} shape="circle" icon={<MoreOutlined />} style={{ marginLeft: 5 }} />
            </Dropdown>
        </Row>
    );
};
