import { getDownloadURL, getMetadata, ref } from "firebase/storage";
import { storage } from "../api/firebase/firebase";

export interface AssetData {
    name: string;
    downloadUrl: string;
    file: File;
}

class DocumentsCache {
    protected static readonly cache = new Map<string, AssetData>();
    protected static cacheList: string[] = [];
    static cacheAdd = (k: string, v: AssetData) => {
        this.cache.set(k, v);
        this.cacheList.push(k);
        if (this.cacheList.length > 100) {
            const drop = this.cacheList[0];
            this.cacheList = this.cacheList.slice(1);
            this.cache.delete(drop);
        }
    };
    static has = (k: string) => this.cache.has(k);
    static get = (k: string) => this.cache.get(k);
}

export class Assets {
    static getRefs = (refs: string[]) => Promise.all(refs.map(v => ref(storage, v)));
    static getNameFromDownloadUrl = (v: string) => {
        let ext = v.split(".").pop();
        ext = ext.slice(0, ext.indexOf("?"));
        return "file." + ext;
    };
    static getFilesCached = (refs: string[]): Promise<AssetData[]> => {
        if (!refs || refs.length === 0) {
            return Promise.resolve([]);
        }
        const cached = refs.filter(v => DocumentsCache.has(v)).map(v => DocumentsCache.get(v));
        const unCached = refs.filter(v => !DocumentsCache.has(v));
        return Assets.getFiles(unCached).then(data => {
            data.forEach((v, i) => DocumentsCache.cacheAdd(unCached[i], v));
            return [...cached, ...data];
        });
    };
    static getFiles = (refsStr: string[]): Promise<AssetData[]> =>
        Assets.getRefs(refsStr).then(async refs => {
            if (refs.length === 0) {
                return [];
            }
            const meta = await Promise.all(refs.map(v => getMetadata(v)));
            const download = await Promise.all(refs.map(v => getDownloadURL(v)));
            const blobs: Blob[] = await Promise.all(
                download.map(url => {
                    return new Promise<Blob>((resolve, reject) => {
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = "blob";
                        xhr.onload = function () {
                            const blob: Blob = xhr.response;
                            resolve(blob);
                        };
                        xhr.onerror = function () {
                            reject(new Error("error"));
                        };
                        xhr.open("GET", url);
                        xhr.send();
                    });
                })
            );
            const assetsDetails: AssetData[] = meta.map((v, i) => {
                const name =
                    v.customMetadata && v.customMetadata.originalName
                        ? v.customMetadata.originalName
                        : i + "." + Assets.getNameFromDownloadUrl(download[i]);
                return {
                    name,
                    downloadUrl: download[i],
                    file: new File([blobs[i]], name, { type: blobs[i].type }),
                };
            });
            return assetsDetails;
        });
}
