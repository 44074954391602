import { TBanksData, TTransactionData } from "@banks/types";

export const getBalance = (banksData: TBanksData, transactionsData: Record<string, TTransactionData>) => {
    return Object.values(banksData || []).reduce(
        (total, bank) => {
            const key = bank.bankCurrency || "EUR";
            const openingBalance = bank?.openingBalance ?? 0;
            const acc = {
                ...total,
                [key]: total[key] ? total[key] + openingBalance : openingBalance,
            };

            Object.values(transactionsData[bank.id] || []).forEach(el => {
                const currencyCode = el.currencyCode ? el.currencyCode : "EUR";
                const amount = el?.amount ?? 0;
                acc[currencyCode] = acc[currencyCode] ? acc[currencyCode] + amount : amount;
            });

            return acc;
        },
        {} as Record<string, number>
    );
};
