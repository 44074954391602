import { GQL } from "@binale-tech/shared";
import { TranslateRequiredTableColumns } from "@app/components/shared/AgGridTable/constants";
import { RequiredTableCols } from "@app/components/shared/AgGridTable/constants/tableCols";

const DmsSpecificCols = {
    IS_ATTACHED: "isAttached",
    DOCUMENT_DATE: "documentDate",
    PARTNER: "partner",
    DOCUMENT_NUMBER: "documentNumber",
    INTERNE_NUMBER: "interneNumber",
    DOCUMENT_AMOUNT: "documentAmount",
    DOCUMENT_AMOUNT_TYPE: "documentAmountType",
    DOCUMENT_ORIGINAL_AMOUNT: "originalAmount",
    CURRENCY: "currency",
    DESCRIPTION: "description",
    FILE_NAME: "fileName",
    NUM_PAGES: "numPages",
    CREATED_AT: "createdAt",
    CREATED_BY: "createdBy",
    VAT_NB: "vatNumber",
    CURRENCY_RATE: "currencyRate",
    DEADLINE_DAYS: "deadlineDays",
    DISCOUNT_AMOUNT: "discountAmount",
    DISCOUNT_DATE: "discountDate",
    DISCOUNT_DAYS: "discountDays",
    DISCOUNT_PERCENT: "discountPercent",
    DUE_DATE: "dueDate",
    NOTES: "notes",
    PAYMENT_TYPE: "paymentType",
    TYPE: "type",
    SUB_TYPE: "subType",
    UPDATED_AT: "updatedAt",
} as const;

export const DmsTableCols = { ...RequiredTableCols, ...DmsSpecificCols } as const;

export const TranslateDmsTableColumns = {
    ...TranslateRequiredTableColumns,
    [DmsTableCols.NUM_PAGES]: "app.fields.numPages",
    [DmsTableCols.IS_ATTACHED]: "app.dms.isAttached",
    [DmsTableCols.CREATED_AT]: "app.dms.createdAt",
    [DmsTableCols.CREATED_BY]: "app.dms.userName",
    [DmsTableCols.VAT_NB]: "app.fields.vatNb",
    [DmsTableCols.CURRENCY]: "app.fields.currency",
    [DmsTableCols.CURRENCY_RATE]: "app.fields.currency.rate",
    [DmsTableCols.DEADLINE_DAYS]: "app.fields.deadlineDays",
    [DmsTableCols.DESCRIPTION]: "app.fields.description",
    [DmsTableCols.DISCOUNT_AMOUNT]: "app.fields.discountAmount",
    [DmsTableCols.DISCOUNT_DATE]: "app.fields.discountDate",
    [DmsTableCols.DISCOUNT_DAYS]: "app.fields.discountDays",
    [DmsTableCols.DISCOUNT_PERCENT]: "app.fields.discountPercent",
    [DmsTableCols.DOCUMENT_AMOUNT]: "app.fields.betrag",
    [DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT]: "app.fields.originalAmount",
    [DmsTableCols.DOCUMENT_AMOUNT_TYPE]: "app.fields.betragType",
    [DmsTableCols.DOCUMENT_DATE]: "app.dms.documentDate",
    [DmsTableCols.DOCUMENT_NUMBER]: "app.dms.documentNumber",
    [DmsTableCols.DUE_DATE]: "app.fields.dueDate",
    [DmsTableCols.FILE_NAME]: "app.fields.bezeichnung",
    [DmsTableCols.INTERNE_NUMBER]: "app.fields.internal_num",
    [DmsTableCols.NOTES]: "app.fields.notes",
    [DmsTableCols.PARTNER]: "app.fields.contact",
    [DmsTableCols.PAYMENT_TYPE]: "app.fields.paymentMethod",
    [DmsTableCols.TYPE]: "app.dms.typeOfDocuments",
    [DmsTableCols.SUB_TYPE]: "app.fields.subType",
    [DmsTableCols.UPDATED_AT]: "app.dms.updatedAt",
};

export const AmountTypeKeys = {
    ATTACHMENT: "attachment",
    INVOICE: "invoice",
    CREDIT_NOTE: "creditNote",
} as const;

export const AmountTypeTranslateKeys = {
    [AmountTypeKeys.ATTACHMENT]: "app.fields.attachment",
    [AmountTypeKeys.INVOICE]: "app.fields.invoice",
    [AmountTypeKeys.CREDIT_NOTE]: "app.fields.creditNote",
};

export const ExcludedColsFromSetting = [
    DmsTableCols.DRAG,
    DmsTableCols.ROW_NUMBER,
    DmsTableCols.ACTIONS,
    DmsTableCols.IS_ATTACHED,
    DmsTableCols.PARTNER,
] as const;

export const TableRowColors = {
    [GQL.IDocumentColors.Red]: "rgba(237, 27, 53, 0.45)",
    [GQL.IDocumentColors.Green]: "rgba(68, 184, 92, 0.45)",
    [GQL.IDocumentColors.Purple]: "rgba(142, 71, 156, 0.45)",
    [GQL.IDocumentColors.Yellow]: "rgba(255, 203, 49, 0.45)",
    [GQL.IDocumentColors.Blue]: "rgba(30, 153, 229, 0.45)",
};
