import React, { PropsWithChildren } from "react";
import { motion } from "framer-motion";

import { AppRoutes, RouterParams } from "./routeConstants";
import { CompanyContext } from "scripts/context/CompanyContext";
import { ProductKey } from "../models/Product";
import { useIntl } from "react-intl";
import { useLocation, useParams } from "react-router";

interface Props {
    productKey?: ProductKey;
    verticalTransition?: boolean;
    disableTransition?: boolean;
}

export const TitledPage: React.FC<PropsWithChildren<Props>> = props => {
    const { pathname } = useLocation();
    const params = useParams<RouterParams>();

    const { yearKbs, yearBanks } = React.useContext(CompanyContext);
    const { productKey } = props;

    const intl = useIntl();
    let title = "Binale";

    const productTranslationKey = "app.titles." + productKey;
    if (intl.messages[productTranslationKey]) {
        title = intl.formatMessage({ id: productTranslationKey });
    }

    if (pathname === AppRoutes.SuSa) {
        title = intl.formatMessage({ id: "app.titles.SuSa" });
    }
    if (pathname === AppRoutes.accountsView) {
        title = intl.formatMessage({ id: "app.titles.KA" });
    }
    if (pathname === AppRoutes.filtersAndSearchView) {
        title = intl.formatMessage({ id: "app.titles.filtern" });
    }

    if (pathname.startsWith(AppRoutes.bank)) {
        const bank = yearBanks.find(b => b.id === params.uuid);
        if (bank) {
            title += " " + bank.accountNum;
        }
    }
    if (pathname.startsWith(AppRoutes.kassenbuch)) {
        const kasse = yearKbs.find(k => k.id === params.uuid);
        if (kasse) {
            title += " " + kasse.accountNum;
        }
    }
    if (pathname.startsWith(AppRoutes.manageAccount)) {
        title = intl.formatMessage({ id: "app.titles.kontenverwaltung" });
    }
    if (pathname.startsWith("/documents")) {
        title += " DMS";
    }

    React.useEffect(() => {
        document.title = title;
    }, [title]);

    if (props.disableTransition) {
        return <>{props.children}</>;
    }
    const initial = props.verticalTransition ? { y: -10, x: 0, opacity: 0 } : { x: -10, y: 0, opacity: 0 };
    return (
        <motion.div
            className="PageFullScreen"
            style={{ padding: 0 }}
            initial={initial}
            animate={{ x: 0, y: 0, opacity: 1 }}
            transition={{ duration: 0.2, type: "spring", stiffness: 70 }}
        >
            {props.children}
        </motion.div>
    );
};
export default React.memo(TitledPage);
