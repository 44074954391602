import React from "react";
import { Button, Space, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import { Link, Navigate } from "react-router-dom";

import { AppRoutes, getRoute } from "scripts/routing/routeConstants";
import { CompanyContext } from "scripts/context/CompanyContext";
import { GQL } from "@binale-tech/shared";
import { GlobalYearSelect } from "../shared/Toolbar/YearSelect";
import { Page } from "../shared/appearance/page";

type ReceiptsInitProps = {
    productKey: GQL.IProductKey;
};
export const KbBankHomePage: React.FC<ReceiptsInitProps> = props => {
    const { productKey } = props;
    const { yearKbs, yearBanks, isLoaded } = React.useContext(CompanyContext);
    const firstBank = yearBanks[0]?.id;
    const firstKB = yearKbs[0]?.id;
    const linkBank = getRoute(AppRoutes.bankOverview, { uuid: firstBank });
    const linkKB = getRoute(AppRoutes.kassenbuchOverview, { uuid: firstKB });
    if (isLoaded) {
        if (productKey === GQL.IProductKey.Kb && firstKB) {
            return <Navigate to={linkKB} />;
        }
        if (productKey === GQL.IProductKey.Bank && firstBank) {
            return <Navigate to={linkBank} />;
        }
    }

    return (
        <Spin spinning={!isLoaded}>
            <Page>
                <Space>
                    <GlobalYearSelect />
                    <div>
                        {props.productKey === GQL.IProductKey.Kb && (
                            <Link to={AppRoutes.kassenbuchManage}>
                                <Button type="primary">
                                    <FormattedMessage id="app.companies.detail_request.select_kb" />
                                </Button>
                            </Link>
                        )}
                        {props.productKey === GQL.IProductKey.Bank && (
                            <Link to={AppRoutes.manageBanks}>
                                <Button type="primary">
                                    <FormattedMessage id="app.companies.detail_request.select_bank" />
                                </Button>
                            </Link>
                        )}
                    </div>
                    <div>
                        <Link to={AppRoutes.home} className="cancelButton">
                            <Button>
                                <FormattedMessage id="app.button.cancel" />
                            </Button>
                        </Link>
                    </div>
                </Space>
            </Page>
        </Spin>
    );
};
