// eslint-disable-next-line import/no-duplicates
import { PdfLibDataload } from "./pdflib.worker";
// eslint-disable-next-line import/no-duplicates
import { PdfWorkerDataload } from "./pdfmake.worker";
import { logger } from "../../infrastructure/logger";

// eslint-disable-next-line import/no-duplicates
import ExportWorker from "./pdfmake.worker.ts?worker";
// eslint-disable-next-line import/no-duplicates
import PDFLibWorker from "./pdflib.worker.ts?worker";

export class PDFMakeWrk {
    static readonly createBlob = (config: PdfWorkerDataload): Promise<PdfWorkerOutput> => {
        return new Promise<PdfWorkerOutput>((resolve, reject) => {
            const renderWorker: Worker = new ExportWorker();
            renderWorker.onmessage = (ev: MessageEvent) => {
                logger.log("PDFMakeWrk createBlob.onmessage", ev);
                renderWorker.terminate();
                resolve(ev.data);
            };
            renderWorker.postMessage(config);
        });
    };
}

export interface PdfWorkerOutput {
    blob: Blob;
    fileExt: "pdf" | "zip";
    error?: string;
    fileName?: string;
    uint8Array?: Uint8Array;
}

export class PDFLibWrk {
    static readonly createBlob = (config: PdfLibDataload): Promise<PdfWorkerOutput | PdfWorkerOutput[]> => {
        return new Promise<PdfWorkerOutput>((resolve, reject) => {
            logger.log({ config });
            const renderWorker: Worker = new PDFLibWorker();
            renderWorker.onmessage = (ev: MessageEvent) => {
                logger.log("PDFLibWrk createBlob.onmessage", ev);
                renderWorker.terminate();
                if (ev.data.error) {
                    return reject(ev.data.error);
                }
                resolve(ev.data);
            };
            renderWorker.onerror = () => {};
            renderWorker.postMessage(config);
        });
    };
}
