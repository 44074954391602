import { GQL } from "@binale-tech/shared";
import React, { type FC } from "react";

import { FileUploaderProvider } from "./context/FileUploaderContext";
import { FileUploaderModule } from "./FileUploaderModule";

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
    onFilesAdd?: (arg: string[]) => void;
    productData?: { productKey: GQL.IProductKey; selectedRecordGroup: string };
    documentType?: string[];
}

export const UploadFileModal: FC<IProps> = ({ isOpen, onCancel, onFilesAdd, productData, documentType }) => {
    return (
        <FileUploaderProvider onFilesAdd={onFilesAdd} productData={productData} documentType={documentType}>
            <FileUploaderModule onFilesAdd={onFilesAdd} isOpen={isOpen} onCancel={onCancel} />
        </FileUploaderProvider>
    );
};
