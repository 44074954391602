import { Input } from "antd";
import React, { forwardRef, useEffect, useState } from "react";

import { floatInputBlocker, formatDefault, parseNumber } from "@dms/scripts/helpers";
import { GQL } from "@binale-tech/shared";

type TProps = {
    value?: number;
    onChange?: (value: number) => void;
    code?: GQL.ICurrencyCode;
};

export const AmountInput = forwardRef<any, TProps>(function CurrencyInputBlock(props, ref) {
    const { value, onChange } = props;

    const [euroValueRes, setEuroValueRes] = useState(formatDefault(value ?? 0));

    useEffect(() => {
        const euroAmount = value ?? 0;
        setEuroValueRes(formatDefault(euroAmount ?? 0));
    }, [value]);

    const handleBlur = () => {
        const euroAmount = Math.round(parseNumber(euroValueRes) * 100);
        if (onChange) {
            onChange(euroAmount);
        }
    };

    return (
        <Input
            ref={ref}
            {...props}
            value={euroValueRes}
            onChange={e => {
                setEuroValueRes(e.target.value);
            }}
            onBlur={() => {
                handleBlur();
            }}
            onFocus={e => e.target.select()}
            onKeyDown={floatInputBlocker}
            suffix={props.code || "EUR"}
        />
    );
});
