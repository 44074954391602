import React from "react";
import { FormattedMessage } from "react-intl";
import { DmsType, IDocumentType } from "@dms/types";

export const getSubTypeOptions = (
    documentTypes: IDocumentType[],
    type: DmsType
): { value: string; label: string | React.ReactNode }[] => {
    const options: { value: string; label: React.ReactNode }[] = [];
    const dbType = documentTypes.find(el => el.id === type);

    if (dbType && dbType.subTypes) {
        dbType.subTypes.forEach(subType => {
            const subTypeValue = {
                value: subType.id,
                label: dbType.id === "taxes" ? <FormattedMessage id={`app.dms.${subType.id}`} /> : subType.name,
            };
            options.push(subTypeValue);
        });
    }

    return options;
};
