import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import Dropzone from "react-dropzone";
import React, { FC, useState } from "react";

type TProps = {
    uploadFile: (arg: File[]) => Promise<void>;
    multiple: boolean;
};

export const DropFileZone: FC<TProps> = ({ uploadFile, multiple }) => {
    const [loadingFile, setLoadingFile] = useState(false);
    const [styleHoverDndButton, setStyleHoverDndButton] = useState("");

    const onDrop = async (files: File[]) => {
        setLoadingFile(true);

        await uploadFile(files);

        setLoadingFile(false);
        setStyleHoverDndButton("");
    };

    const onDragOver = () => {
        if (!styleHoverDndButton) {
            setStyleHoverDndButton("pdf-tools-action-btn__drag-enter");
        }
    };

    const onDragLeave = () => {
        setStyleHoverDndButton("");
    };
    return (
        <Dropzone accept={{ "application/pdf": [".pdf"] }} multiple={multiple} onDropAccepted={onDrop}>
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} style={{ height: "100%" }}>
                    <input {...getInputProps()} />
                    <Button
                        className={`pdf-tools-action-btn pdf-tools-dropzone ${styleHoverDndButton}`}
                        icon={<UploadOutlined />}
                        loading={loadingFile}
                        disabled={loadingFile}
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                    >
                        <FormattedMessage id="app.ocr.button.addDocument" tagName="span" />
                    </Button>
                </div>
            )}
        </Dropzone>
    );
};
