import React, { useContext, useMemo, useState } from "react";
import { Card, Col, Row } from "antd";
import { FormattedMessage } from "react-intl";

import Creditor from "scripts/models/Creditor";
import { CompanyContext } from "scripts/context/CompanyContext";
import { KontoContext, KontoControlContext } from "scripts/context/KontoEntitiesProvider";
import { CreditorForm } from "./CreditorForm";
import { FormHeader, getFiltered, SearchField } from "../helpers";
import { GlobalYearSelect } from "../../../../components/shared/Toolbar/YearSelect";
import { NumNameTable } from "../NumNameTable";
import { ProductKeys } from "scripts/models/Product";
import { useCanWrite } from "scripts/infrastructure/hooks/usePermissions";

export const EditCreditorView: React.FC = () => {
    const canWrite = useCanWrite(ProductKeys.AccountingCommon);
    const { creditors } = useContext(KontoContext);
    const { creditorSave, creditorDelete } = useContext(KontoControlContext);
    const { yearConfig } = useContext(CompanyContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;

    const [activeItem, setActiveItem] = useState<Creditor | null>();
    const [querySearch, setQuerySearch] = useState("");

    const uniqueNums = creditors.map((creditor: Creditor) => creditor.getExtNum(kontoExt));

    const handleClear = React.useCallback(() => setActiveItem(null), []);

    const tableItems = useMemo(() => {
        return getFiltered(creditors, querySearch, kontoExt).map(entity => ({ entity }));
    }, [creditors, querySearch, kontoExt]);

    return (
        <div className="ManageAccount__container">
            <Row align="middle" gutter={24} style={{ marginTop: 16, marginBottom: 16 }}>
                <Col>
                    <GlobalYearSelect />
                </Col>
                <Col>
                    <SearchField onSearch={setQuerySearch} />
                </Col>
            </Row>

            <Row gutter={32} style={{ flexGrow: 1 }}>
                <Col>
                    <NumNameTable
                        items={tableItems}
                        onClick={item => setActiveItem(item as Creditor)}
                        onDeleteClick={item => creditorDelete(item.key, item.num)}
                        isRemovable={() => true}
                    />
                </Col>
                <Col span={7} xxl={5}>
                    <Card>
                        <FormHeader
                            isEditing={!!activeItem?.key}
                            entityLabel={<FormattedMessage id="app.titles.creditor" />}
                        />
                        <CreditorForm
                            item={activeItem}
                            disabled={!canWrite}
                            uniqueNums={uniqueNums}
                            onSave={creditorSave}
                            onClear={handleClear}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
export default React.memo(EditCreditorView);
