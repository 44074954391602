import Payment from "../Payment";
import { Base, GQL, Periods, Utils } from "@binale-tech/shared";
import { GenericRecord } from "../GenericRecord";
import { GenericRecordTableItem } from "../../../appearance/components/shared/Table/Table";
import { PaymentUtils } from "./PaymentUtils";
import { Product } from "../../core/Product";
import { logger } from "../../infrastructure/logger";
import RecordFormState from "@app/components/recordform/types/RecordFormState";
import { IGenericItem, IGenericRecord } from "../Interfaces";
import { ProductAccessUtils } from "./ProductAccessUtils";
import { ProductFactory } from "../../core/ProductFactory";

interface ManipulationPermissionsSection {
    disabled: boolean;
    reason?: string;
}

export interface ManipulationPermissions {
    remove: ManipulationPermissionsSection;
    cancel: ManipulationPermissionsSection;
    edit: ManipulationPermissionsSection;
    confirm: ManipulationPermissionsSection;
    copy: ManipulationPermissionsSection;
    paymentSave: ManipulationPermissionsSection;
    paymentDelete: ManipulationPermissionsSection;
}

export class GenericRecordUtils {
    protected static readonly ResEnabled: ManipulationPermissions = {
        remove: { disabled: false },
        cancel: { disabled: false },
        edit: { disabled: false },
        copy: { disabled: false },
        confirm: { disabled: false },
        paymentSave: { disabled: false },
        paymentDelete: { disabled: false },
    };

    static getManipulationPermissions(
        record: GenericRecord,
        hasProgramWritePermission: boolean,
        programProduct: Product,
        paymentsData: Map<string, Payment[]>,
        editBounds: { selectedPeriod: number; globalYear: number },
        yearConfig: GQL.ICompanyAccountingYear,
        companyGQL: GQL.ICompany,
        user: Base.UserInterface
    ): ManipulationPermissions {
        const { selectedPeriod: selectedPeriodEditBound, globalYear } = editBounds;
        const res: ManipulationPermissions = {
            remove: { disabled: true },
            cancel: { disabled: true },
            edit: { disabled: true },
            copy: { disabled: true },
            confirm: { disabled: true },
            paymentDelete: { disabled: true },
            paymentSave: { disabled: true },
        };
        const setRecordAllDisabledWithReason = (v: string) => {
            res.remove.disabled = true;
            res.remove.reason = v;
            res.edit.disabled = true;
            res.edit.reason = v;
            res.copy.disabled = true;
            res.copy.reason = v;
            res.confirm.disabled = true;
            res.confirm.reason = v;
            res.cancel.disabled = true;
            res.cancel.reason = v;
        };
        const setPaymentWithReason = (opts: { disableDelete?: boolean; disableSave?: boolean }, reason?: string) => {
            res.paymentDelete.disabled = opts.disableDelete;
            if (opts.disableDelete) {
                res.paymentDelete.reason = reason;
            }

            res.paymentSave.disabled = opts.disableSave;
            if (opts.disableSave) {
                res.paymentSave.reason = reason;
            }
        };

        const enableRecordEdit = (v: string) => {
            res.edit.disabled = res.remove.disabled = res.cancel.disabled = res.copy.disabled = false;
            res.confirm.reason = v;
            res.confirm.reason = v;
        };
        if (!hasProgramWritePermission) {
            // disable payment
            const reason = "You do not have write permission";
            setRecordAllDisabledWithReason(reason);
            setPaymentWithReason({ disableDelete: true, disableSave: true }, reason);
            return res;
        }
        if (!record.key) {
            return this.ResEnabled;
        }
        if (record.journaled) {
            // enable payment save
            // enable payment delete
            setRecordAllDisabledWithReason("Record is in journal");
            if (!record.cancellation) {
                res.cancel = { disabled: false };
            }
            res.copy = { disabled: false };
            setPaymentWithReason({ disableDelete: false, disableSave: false });
            return res;
        }
        if (record.cancellation) {
            // disable payment save
            // enable  payment delete
            const reason =
                record.cancellation === "cancelled"
                    ? "Record is cancelled (Storno)"
                    : "Record is cancellation counterweight";
            setRecordAllDisabledWithReason(reason);
            if (record.cancellation === "counterweight") {
                res.remove = { disabled: false };
            } else {
                res.copy = { disabled: false };
            }
            setPaymentWithReason({ disableDelete: false, disableSave: true }, reason);
            return res;
        }
        if (record.review === "ok" && !ProductAccessUtils.canReviewRecord(record.getProductKey(), companyGQL, user)) {
            const reason = "This record was reviewed, no changes are allowed";
            setRecordAllDisabledWithReason(reason);
            res.copy = { disabled: false };
            setPaymentWithReason({ disableDelete: false, disableSave: false }, reason);
            return res;
        }
        if (record.date.getFullYear() !== globalYear) {
            // enable payment save
            // enable payment delete
            setRecordAllDisabledWithReason("Selected global year doesn't match record's year");
            res.copy = { disabled: false };
            setPaymentWithReason({ disableDelete: false, disableSave: false });
            return res;
        }
        if (Number.isFinite(selectedPeriodEditBound)) {
            if (record.period !== selectedPeriodEditBound) {
                setRecordAllDisabledWithReason("Selected period doesn't match record's period");
                res.copy = { disabled: false };
                setPaymentWithReason({ disableDelete: false, disableSave: false });
                // enable payment save
                // enable payment delete
                return res;
            }
        }
        const pk = record.getProductKey();
        if (!pk) {
            logger.log("record without product key", JSON.stringify(this));
            throw new Error("record without product key");
        }

        if (!programProduct.getConfig().enableRecordForm) {
            setPaymentWithReason({ disableDelete: false, disableSave: false });
            res.copy = { disabled: false };
            setRecordAllDisabledWithReason(
                `Record is created in product ${record.getProductKey()}. You can edit it only in the main module`
            );
            return res;
        }
        if (PaymentUtils.isFERecordEditBlocked(record, paymentsData)) {
            setRecordAllDisabledWithReason(`This is auto record, it's not editable. Please edit original payment`);
            return res;
        }
        if (PaymentUtils.isRepresentationRecordEditBlocked(record, paymentsData, yearConfig)) {
            res.confirm.disabled = res.remove.disabled = false;
            res.edit.reason = `This is connected split representation IST record, it's not editable`;
            return res;
        }
        if (record.draft) {
            res.edit.disabled = res.remove.disabled = res.copy.disabled = false;
            res.confirm.disabled = !this.isConfirmationAllowed(record, programProduct);
            return res;
        }
        // local updates
        if (!record.draft) {
            enableRecordEdit(`Already confirmed`);
            setPaymentWithReason({ disableDelete: false, disableSave: false });
            return res;
        }

        return res;
    }

    static isRecordPayed(record: GenericRecord, payments: Map<GenericRecord, Payment[]>) {
        return payments && record.getOpenBrutto(payments.get(record)) === 0;
    }

    // todo:shared: check here too
    static copyRecord(proto: GenericRecord, year: number, period?: number): GenericRecord {
        const record = proto.clone();
        let recordMonth = record.date.getMonth();
        let recordPeriod = record.period;
        let recordDay = record.date.getDate();
        if (Number.isFinite(period)) {
            recordPeriod = period;
            const { month, day } = Periods.getMonthAndDay(period);
            recordMonth = month;
            if (day) {
                recordDay = day;
                // in case date was 31 and new month has less days
            } else if (new Date(year, recordMonth, recordDay).getMonth() !== recordMonth) {
                recordDay = new Date(year, recordMonth + 1, 0).getDate();
            }
        }
        record.date.setFullYear(year, recordMonth, recordDay);
        record.period = recordPeriod;
        record.day = recordDay;
        record.year = year;

        record.key = null;
        record.documents = null;
        record.falligkeit = null;
        record.review = undefined;
        Object.assign(record, { journaled: false });
        if (record.priority) {
            record.priority++;
        }
        return record;
    }

    static isAssetsAndColorDisabled(tableItem: GenericRecordTableItem) {
        if (tableItem.extra.isVirtualRecord) {
            return true;
        }
        return Boolean(tableItem.parent);
    }

    static mapToGQL(record: GenericRecord): GQL.IGenericRecordInput {
        delete (record as any).removedAt;
        delete (record as any).removed;
        const {
            debetor,
            creditor,
            category,
            createdAt,
            updatedAt,
            updatedBy,
            color,
            review,
            brutto,
            date,
            priority,
            journaled,
            cancellation,
            avis,
            currency,
            ...restRecord
        } = record;
        const currencyData = Object.keys(currency ?? {}).length ? currency : undefined;

        return {
            ...restRecord,
            documents: restRecord.documents || [],
            currency: currencyData,
            originalAmount: currencyData ? Number(restRecord.originalAmount) : undefined,
            year: record.date.getFullYear(),
            day: record.date.getDate(),
            falligkeit: record.falligkeit ? record.falligkeit.toISOString() : undefined,
            num: record.num,
            period: record.period,
            category: category?.num,
            creditor: creditor?.num,
            debitor: debetor?.num,
            items: record.items.map(item => {
                const { debetor: debItem, ...rest } = item;
                return {
                    ...rest,
                    originalAmount: currency ? Number(item.originalAmount) : undefined,
                    category: item.category?.num,
                    creditor: item.creditor?.num,
                    debitor: debItem?.num,
                    tag: item.tag?.num,
                    bu: item.bu || 0,
                    USt13b: item.USt13b ? Number(item.USt13b) : undefined,
                };
            }),
        };
    }

    static getInvoiceNumber = (r: GenericRecord, pk: GQL.IProductKey, useDivRepresentation?: boolean) => {
        if (Utils.ModuleUtils.useBelegfeld1Split(pk)) {
            if (useDivRepresentation) {
                return r.getBelegfeld1();
            }
            return r.items.find(el => el.belegfeld1)?.belegfeld1;
        }

        return r.num;
    };

    protected static isConfirmationAllowed(item: GenericRecord, product: Product): boolean {
        if (!item.draft) {
            return false;
        }

        if (product.getConfig().isRecordRechnungRequired && !item.num) {
            return false;
        }
        if (!item.getRecordCategoryCreditor().num) {
            return false;
        }
        if (!item.getItemCategoryCreditor().num) {
            return false;
        }
        return true;
    }

    static convertRecordToForm = (r: IGenericRecord): RecordFormState["editableRecord"] => {
        return {
            recordKey: r.key,
            recordNum: r.num,
            recordBrutto: r.brutto,
            recordCurrency: r.currency,
            recordOriginalAmount: r.originalAmount,
            recordCategoryCreditor: r.creditor || r.category || r.debetor,
            recordLastschrift: r.lastschrift,
            recordFalligkeit: r.falligkeit,
            recordJournaled: r.journaled,
            recordDraft: r.draft,
            recordPriority: r.priority,
            recordDocuments: r.documents,
            recordColor: r.color,
            recordCreatedAt: r.createdAt,
            recordReview: r.review,
            recordContact: r.partner,
        };
    };

    static convertRecordItemToForm = (i: IGenericItem, product: Product): RecordFormState["editableRecordItem"] => {
        return {
            itemBu: product.productKey() === GQL.IProductKey.ErA ? i.extra.bu : i.bu,
            itemBelegfeld1: i.belegfeld1,
            itemBelegfeld2: i.belegfeld2,
            itemCategoryCreditor: i.category || i.creditor || i.debetor,
            itemBrutto: i.brutto,
            itemOriginalAmount: i.originalAmount,
            itemTag: i.tag,
            itemText: i.text,
            itemText2: i.text2,
            itemUSt13b: i.USt13b,
        };
    };
}
