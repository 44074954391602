import React, { ChangeEvent, CSSProperties, FC, KeyboardEvent } from "react";
import { Input } from "antd";
import { RechnungInputBlock } from "@ui-components/RechnungInputBlock";
import { SearchOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

export interface SearchInputProps {
    style?: CSSProperties | undefined;
    onSearch: (value: string) => void;
    value: string;
    onChange: (value: string) => void;
}

export const SearchInput: FC<SearchInputProps> = React.memo(function SearchInput(props) {
    const { formatMessage } = useIntl();
    const searchInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        props.onChange(value);
        if (!value) {
            setTimeout(() => props.onSearch(value), 10);
        }
    };

    const onKeyUpSearchInputHandle = (event: KeyboardEvent<HTMLInputElement>) => {
        const { onSearch } = props;

        if (onSearch && event.key === "Enter") {
            onSearch(props.value);
        }
    };

    return (
        <div className="ToolbarSearch__container" style={props.style}>
            <Input
                status={props.value ? "warning" : undefined}
                placeholder={formatMessage({ id: "app.fields.search" })}
                value={props.value}
                onChange={searchInputHandler}
                onKeyUp={onKeyUpSearchInputHandle}
                prefix={<SearchOutlined />}
            />
        </div>
    );
});

export interface INumField {
    value: string;
    setValue: (value: string | null) => void;
}

export const NumField: FC<INumField> = React.memo(function NumField({ value, setValue }) {
    return <RechnungInputBlock value={value} onChange={setValue} width={150} />;
});
