import React, { FC } from "react";
import { DmsType, ITableDocument, MovementType } from "@dms/types";
import { Tag } from "antd";
import { getMovementType } from "@dms/scripts/helpers";
import { ICellRendererParams } from "ag-grid-community";

import styles from "../../DocumentTable.module.scss";

export const AmountType: FC<ICellRendererParams<ITableDocument>> = ({ data, value }) => {
    const { type, isAttachment, documentAmount } = data;

    if (isAttachment) {
        return <Tag className={styles.cellAmountType}>{value}</Tag>;
    }

    if (documentAmount && documentAmount !== 0) {
        const movementType = getMovementType(documentAmount, type as DmsType);
        if (movementType !== "zero") {
            return (
                <Tag
                    className={styles.cellAmountType}
                    color={movementType === MovementType.RECEIPT ? "success" : "error"}
                >
                    {value}
                </Tag>
            );
        }
    }

    return null;
};
