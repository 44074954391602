import React, { FC, useCallback, useContext, useState } from "react";
import { Form, Modal } from "antd";
import { TContactFormFields, TInvoicesValues } from "@inv/types";
import { Contacts, GQL } from "@binale-tech/shared";
import { FormattedMessage, useIntl } from "react-intl";
import { StringInput } from "@app/components/shared/form/baseComponents/StringInput/StringInput";
import { ContactsControlContext } from "../../../../../scripts/context/ContactsContext";

type Props = {
    open: boolean;
    setOpen: (v: boolean) => void;
};

export const ModalSaveContact: FC<Props> = ({ open, setOpen }) => {
    const intl = useIntl();
    const form = Form.useFormInstance<TInvoicesValues>();
    const customerName = Form.useWatch(["customerName"], form);
    const { saveContact } = useContext(ContactsControlContext);
    const [isLoading, setIsLoading] = useState(false);

    const onChange = useCallback(
        (v: string) => {
            form.setFieldValue("customerName", v);
        },
        [form]
    );
    const onOk = useCallback(() => {
        setIsLoading(true);
        const values: TContactFormFields = form.getFieldsValue();
        const data: Contacts.Contact = {
            type: GQL.IContactType.Organization,
            internalName: values.customerName,
            organizationName: values.customerName,
            defaultCurrencyCode: values.currencyCode,
            addresses: [
                {
                    city: values.city,
                    countryCode: values.countryCode,
                    street: values.street,
                    house: values.houseNumber,
                    postCode: values.zipCode,
                    addressLine2: values.address,
                },
            ],
            relations: [{ type: GQL.IContactRelation.Client }],
        };
        if (values.customerEmail) {
            data.communications = [
                {
                    type: GQL.ICommunicationType.Email,
                    value: values.customerEmail,
                },
            ];
        }
        if (values.customerVat) {
            data.legalInfo = {
                vat: values.customerVat,
            };
        }
        saveContact(data)
            .then(id => {
                setTimeout(() => form.setFieldValue("contactId", id), 100);
            })
            .then(() => {
                setIsLoading(false);
                setTimeout(() => setOpen(false), 110);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [form, saveContact]);
    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            okButtonProps={{
                disabled: !customerName,
                loading: isLoading,
            }}
            onOk={onOk}
            destroyOnClose
            styles={{ body: { margin: 20 } }}
        >
            <Form form={form} layout="vertical">
                <Form.Item label={<FormattedMessage id={"app.fields.companyName"} />}>
                    <StringInput
                        placeholder={intl.formatMessage({ id: "app.fields.companyName" })}
                        value={customerName ?? ""}
                        onChange={onChange}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
