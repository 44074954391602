import React, { FC, memo } from "react";

import { PdfToolsProvider } from "./context";
import { PdfToolView } from "./views/pdfToolView";

const PdfToolContainer: FC = () => {
    return (
        <PdfToolsProvider>
            <PdfToolView />
        </PdfToolsProvider>
    );
};
export default memo(PdfToolContainer);
