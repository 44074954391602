import { Base } from "@binale-tech/shared";

export default class Tag implements Base.IExtNum {
    key?: string | null;

    constructor(
        public readonly num: string,
        public readonly name: string
    ) {}

    static unserialize(v: Record<string, unknown> | Tag): Tag {
        return Object.assign(new Tag("", ""), v);
    }

    fixNum(ext: number) {
        return this;
    }

    getExtNum(ext: number): string {
        return this.num;
    }

    getExtNumPrint(ext: number): string {
        return this.num;
    }

    equalsTo(c: Tag): boolean {
        if (!c) {
            return false;
        }
        return this.num === c.num;
    }

    getDefaultLen(): number {
        return 1;
    }
}
export const TAG_EMPTY = new Tag("", "");
export const TAG_DIV = new Tag("Div.", "Div.");
