import React, {
    ContextType,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { CompanyContext } from "./CompanyContext";
import { GQL } from "@binale-tech/shared";

import { child, DataSnapshot } from "firebase/database";

import { getDmsDocumentTypes } from "./queries/ctxQueries.graphql";

import { refDocuments } from "../api/firebase/firebaseRootRefs";
import { useApolloClient } from "@apollo/client";

import { DmsTypeOptions } from "@dms/configs/constants";
import { IDocumentType } from "@dms/types";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { DmsAppProvider } from "@dms/scripts/context/DmsAppProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DmsUserProvider } from "@dms/scripts/context/DmsUserProvider";
import { useCollectionListener } from "./hooks/useCollectionListener";
import { useDmsRecordsData } from "@dms/hooks/useDmsRecordsData";

/**
 Context Provider for Documents data state
 */
export const DmsDataProvider: FC<PropsWithChildren> = ({ children }) => {
    const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>([]);
    const { companyGQL } = useContext(CompanyContext);
    const client = useApolloClient();

    const companyId = companyGQL?.id;

    const shouldSkipLoad = useMemo(() => !companyId, [companyId]);
    const ref = useMemo(() => child(refDocuments, String(companyId)), [companyId]);
    const initializer = useCallback((snap: DataSnapshot) => snap.val(), []);

    const { state: documentsMap, list: documents } = useCollectionListener(ref, initializer, shouldSkipLoad);
    const documentsKV = useMemo(() => Object.fromEntries(documentsMap), [documentsMap]);

    const dmsRecordsData = useDmsRecordsData();

    const fetchTypes = useCallback(async () => {
        const res = await client.query<Pick<GQL.IQuery, "dmsDocumentTypes">, GQL.IQueryDmsDocumentTypesArgs>({
            query: getDmsDocumentTypes,
            fetchPolicy: "network-only",
            variables: { companyId },
        });

        const types = DmsTypeOptions.filter(v => Boolean(v.value)).map(({ value }) => {
            const dbDmsType = res.data.dmsDocumentTypes.find(v => value === v.id);
            if (!dbDmsType) {
                throw new Error(`Unable to find type ${value} in the backend data`);
            }
            return dbDmsType as IDocumentType;
        });

        setDocumentTypes(prevTypes => {
            if (JSON.stringify(prevTypes) === JSON.stringify(types)) {
                return prevTypes;
            }
            return types;
        });
    }, [client, companyId]);

    useEffect(() => {
        if (!companyId) {
            return;
        }
        const getTypes = async () => {
            if (!companyId || document.visibilityState !== "visible") {
                return;
            }
            await fetchTypes();
        };

        getTypes().then(() => document.addEventListener("visibilitychange", getTypes));

        return () => {
            document.removeEventListener("visibilitychange", getTypes);
        };
    }, [companyId, fetchTypes]);

    const value = useMemo<ContextType<typeof DmsDataContext>>(
        () => ({
            documentsKV,
            documents,
            dmsRecordsData,
            documentTypes,
            fetchTypes,
        }),
        [documentsKV, documents, dmsRecordsData, documentTypes, fetchTypes]
    );

    return (
        <DmsDataContext.Provider value={value}>
            <DmsUserProvider>
                <DmsAppProvider>
                    <DndProvider backend={HTML5Backend}>{children}</DndProvider>
                </DmsAppProvider>
            </DmsUserProvider>
        </DmsDataContext.Provider>
    );
};
