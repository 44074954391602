import { Flex, Tag, Typography } from "antd";
import React, { useContext } from "react";
import { transactionFieldsGroups, transactionFieldsGroupTitle } from "@banks/configs/constants/transactionFieldsDict";
import styles from "./MatchKeyList.module.scss";
import { FormattedMessage } from "react-intl";
import { PreviewParseDataContext } from "@banks/modules/ParseTransactionModule/context/PreviewParseDataProvider";

const { Text } = Typography;

export const MatchKeyList = () => {
    const { unmappedDataKeys } = useContext(PreviewParseDataContext);

    const getColor = (key: string) => {
        return unmappedDataKeys.includes(key) ? "magenta" : "green";
    };

    return (
        <Flex gap={15}>
            <div
                className={styles.groupWrapper}
                style={{
                    width: "100%",
                    backgroundColor: "rgb(250, 250, 250)",
                    borderRadius: 8,
                    border: "1px solid",
                    borderColor: "rgba(210, 210, 210)",
                    padding: "5px 10px",
                }}
            >
                <Text type={"secondary"}>
                    <FormattedMessage id={transactionFieldsGroupTitle.transactionInfo} />
                </Text>
                <Flex>
                    <div className={styles.tagWrapper}>
                        <Tag
                            style={{
                                width: 130,
                                minWidth: 50,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            color={getColor("amount")}
                        >
                            <FormattedMessage id={transactionFieldsGroups.transactionInfo.amount} />
                        </Tag>
                    </div>
                    <div className={styles.tagWrapper}>
                        <Tag
                            style={{
                                width: 130,
                                minWidth: 50,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            color={getColor("currencyCode")}
                        >
                            <FormattedMessage id={transactionFieldsGroups.transactionInfo.currencyCode} />
                        </Tag>
                    </div>
                    <div className={styles.tagWrapper}>
                        <Tag
                            style={{
                                width: 130,
                                minWidth: 50,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            color={getColor("transactionType")}
                        >
                            <FormattedMessage id={transactionFieldsGroups.transactionInfo.transactionType} />
                        </Tag>
                    </div>
                </Flex>

                <Flex>
                    <div className={styles.tagWrapper}>
                        <Tag
                            style={{
                                width: 130,
                                minWidth: 50,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            color={getColor("purpose")}
                        >
                            <FormattedMessage id={transactionFieldsGroups.transactionInfo.purpose} />
                        </Tag>
                    </div>
                    <div className={styles.tagWrapper}>
                        <Tag
                            style={{
                                width: 130,
                                minWidth: 50,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            color={getColor("bookingDate")}
                        >
                            <FormattedMessage id={transactionFieldsGroups.transactionInfo.bookingDate} />
                        </Tag>
                    </div>
                </Flex>
            </div>
            <div
                className={styles.groupWrapper}
                style={{
                    width: "100%",
                    backgroundColor: "rgb(250, 250, 250)",
                    borderRadius: 8,
                    border: "1px solid",
                    borderColor: "rgba(210, 210, 210)",
                    padding: "5px 10px",
                }}
            >
                <Text type={"secondary"}>
                    <FormattedMessage id={transactionFieldsGroupTitle.counterparty} />
                </Text>
                <Flex>
                    <div className={styles.tagWrapper}>
                        <Tag
                            style={{
                                width: 150,
                                minWidth: 50,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            color={getColor("counterpartyIbanOrAccountNum")}
                        >
                            <FormattedMessage id={transactionFieldsGroups.counterparty.counterpartyIbanOrAccountNum} />
                        </Tag>
                    </div>
                    <div className={styles.tagWrapper}>
                        <Tag
                            style={{
                                width: 150,
                                minWidth: 50,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            color={getColor("counterpartyBicOrBlz")}
                        >
                            <FormattedMessage id={transactionFieldsGroups.counterparty.counterpartyBicOrBlz} />
                        </Tag>
                    </div>
                </Flex>
                <Flex>
                    <div className={styles.tagWrapper}>
                        <Tag
                            style={{
                                width: 150,
                                minWidth: 50,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            color={getColor("counterpartyName")}
                        >
                            <FormattedMessage id={transactionFieldsGroups.counterparty.counterpartyName} />
                        </Tag>
                    </div>
                </Flex>
            </div>
        </Flex>
    );
};
