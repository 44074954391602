import React from "react";
import { GQL } from "@binale-tech/shared";
import { TableColumn, TableItem } from "../components/shared/Table/Table";

type SortingFnProto<E> = (
    getter: TableColumn<E>["getter"],
    a: TableItem<E>,
    b: TableItem<E>,
    opts: GQL.ICompanyAccountingYear
) => number;

type GetterOverride<E> = (tableItem: TableItem<E>, kontoExt: number) => React.ReactNode;

export class ConfigurableColumn<E> {
    protected getterOverride: GetterOverride<E>;
    protected sortingFn: SortingFnProto<E>;
    protected opts: GQL.ICompanyAccountingYear;
    protected widthConstraints: {
        min: number;
        max: number;
    };

    constructor(
        protected readonly configPrototype: Omit<
            TableColumn<E>,
            "sortable" | "sortFunc" | "getter" | "widthConstraints" | "resizable"
        > &
            Partial<Pick<TableColumn<E>, "getter">>,
        protected readonly getterPrototype?: (tableItem: TableItem<E>, opts: GQL.ICompanyAccountingYear) => any
    ) {}

    getColumnKey() {
        return this.configPrototype.key;
    }

    setGetterOverride(getter: GetterOverride<E>): ConfigurableColumn<E> {
        this.getterOverride = getter;
        return this;
    }

    withSorting(sortingFnProto?: SortingFnProto<E>) {
        const defaultSortingFn: SortingFnProto<E> = (getter, a, b, opts) => {
            return (getter(a) || "").toString().localeCompare((getter(b) || "").toString());
        };

        this.sortingFn = sortingFnProto ? sortingFnProto : defaultSortingFn;
        return this;
    }

    withResizing(widthConstraints: { min: number; max: number }) {
        this.widthConstraints = widthConstraints;
        return this;
    }

    setOpts(opts: GQL.ICompanyAccountingYear) {
        this.opts = opts;
        return this;
    }

    composeConfig(): TableColumn<E> {
        // if (!this.getterPrototype && !this.getterOverride) {
        //     throw new Error(
        //         `column ${this.configPrototype.key} does not have a default getter or getterOverride. You should provide one of the options`
        //     );
        // }
        const getter = this.getterOverride
            ? (ti: TableItem<E>) => this.getterOverride(ti, this.opts.kontoExt)
            : this.getterPrototype
              ? (ti: TableItem<E>) => this.getterPrototype(ti, this.opts)
              : () => "";
        const sortable = Boolean(this.sortingFn);
        return {
            ...this.configPrototype,
            getter,
            sortable,
            sortFunc: sortable
                ? (a, b) => {
                      return this.sortingFn(getter, a, b, this.opts);
                  }
                : undefined,
            resizable: Boolean(this.widthConstraints),
            widthConstraints: this.widthConstraints,
        };
    }
}
