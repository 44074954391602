import { gql } from "@apollo/client";

export const invoiceSave = gql`
    mutation invoiceSave($input: InvoiceSaveInput!) {
        invoiceSave(input: $input) {
            id
        }
    }
`;

export const invoiceDelete = gql`
    mutation invoiceDelete($input: InvoiceDeleteInput!) {
        invoiceDelete(input: $input)
    }
`;

export const invoiceApplyCurrencyRate = gql`
    mutation invoiceApplyCurrencyRate($input: invoiceApplyCurrencyRateInput!) {
        invoiceApplyCurrencyRate(input: $input)
    }
`;
