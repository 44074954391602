import React, { type FC } from "react";
import { IDocumentEnriched, ITableDocument } from "@dms/types";
import { DropdownActions } from "@dms/components/ActionList/DropdownActions";
import { IRowNode } from "ag-grid-community";

interface IProps {
    document: IDocumentEnriched;
    node?: IRowNode<ITableDocument>;
}

export const CardActionsList: FC<IProps> = ({ document, node }) => {
    return <DropdownActions document={document} mode={"card"} node={node} />;
};
