import { Form } from "antd";
import { Switch } from "@nextui-org/switch";
import { FormattedMessage } from "react-intl";
import React, { FC, useContext } from "react";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useFormHandlerRecordDraft } from "../hooks/handlers/useFormHandlerRecordDraft";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";

export const FormRecordDraft: FC = () => {
    const { editableRecord } = useContext(RecordFormStateContext);
    const isDisabled = useFormIsRefDisabled();
    const onChangeDraft = useFormHandlerRecordDraft();
    const { isUpdating, record, refsHtml } = useContext(RecordFormPropsContext);
    const getIsDisabled = () => {
        if (isDisabled(refsHtml.REF_rDRAFT)) {
            return true;
        }
        if (isUpdating) {
            return !record.draft;
        }
        return false;
    };
    return (
        <Form.Item>
            <Switch
                ref={refsHtml.REF_rDRAFT}
                size="sm"
                color="warning"
                isDisabled={getIsDisabled()}
                isSelected={editableRecord.recordDraft}
                tabIndex={-1}
                onValueChange={onChangeDraft}
            >
                <FormattedMessage id="app.fields.draft" />
            </Switch>
        </Form.Item>
    );
};
