import React from "react";
import { Button, Dropdown, Row, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";

import { CommunicationIcons, getTypeName } from "./helpers";
import { GQL } from "@binale-tech/shared";
import { ItemType } from "antd/es/menu/interface";

interface CommunicationButtonProps {
    onSelect: (e: GQL.ICommunicationType) => void;
}

export const CommunicationButton = React.forwardRef<HTMLButtonElement, CommunicationButtonProps>(
    function CommunicationButton({ onSelect }, ref) {
        const menuItems = Object.values(GQL.ICommunicationType).map<ItemType>(type => ({
            key: type,
            "data-testid": `communication-menuitem-${type}`,
            tabIndex: 1,
            label: (
                <Row justify="space-between">
                    <span>{getTypeName(type)}</span>
                    <Typography.Text disabled>{CommunicationIcons[type]}</Typography.Text>
                </Row>
            ),
        }));

        return (
            <Dropdown
                menu={{
                    items: menuItems,
                    onClick: e => onSelect(e.key as GQL.ICommunicationType),
                }}
                autoFocus
                placement="bottomLeft"
                trigger={["click"]}
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            >
                <Button type="primary" icon={<PlusOutlined />} data-testid="communication-add" ref={ref}>
                    <FormattedMessage id="app.titles.communication" tagName="span" />
                </Button>
            </Dropdown>
        );
    }
);
