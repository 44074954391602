import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { logger } from "../logger";

declare let safari: any;
declare let InstallTrigger: any;

export class BrowserDetection {
    // https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari
    static readonly isMobileSafari = () => {
        return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);
    };
    // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
    static readonly isSafari =
        BrowserDetection.isMobileSafari() ||
        /constructor/i.test((window as any).HTMLElement) ||
        (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
            // eslint-disable-next-line @typescript-eslint/dot-notation
        })(!(window as any).safari || (typeof safari !== "undefined" && safari.pushNotification));
    static readonly isFirefox = typeof InstallTrigger !== "undefined";
}

export class ScrollbarDetection {
    protected static width = 0;

    static runDetector() {
        const scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);

        const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        this.width = scrollbarWidth; // Mac:  15

        document.body.removeChild(scrollDiv);
    }

    static getWidth() {
        return this.width;
    }
}

// https://stackoverflow.com/a/32841164
function findIP(resolve: any) {
    //  onNewIp - your listener function for new IPs
    if (BrowserDetection.isSafari) {
        resolve("safari:0.0.0.0");
        return;
    }
    const w = window as any;
    const MyPeerConnection = w.RTCPeerConnection || w.mozRTCPeerConnection || w.webkitRTCPeerConnection; // compatibility for firefox and chrome
    const pc = new MyPeerConnection({ iceServers: [] });
    const noop = function () {};
    const localIPs: Record<string, any> = {};
    const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

    function ipIterate(ip: string) {
        if (!localIPs[ip]) {
            resolve(ip);
        }
        localIPs[ip] = true;
    }

    pc.createDataChannel(""); // create a bogus data channel
    pc.createOffer({}).then((sdp: any) => {
        sdp.sdp.split("\n").forEach(function (line: any) {
            if (line.indexOf("candidate") < 0) {
                return;
            }
            line.match(ipRegex).forEach(ipIterate);
        });
        pc.setLocalDescription(sdp, noop, noop);
    }, noop); // create offer and set local description
    pc.onicecandidate = function (ice: any) {
        // listen for candidate events
        if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) {
            return;
        }
        ice.candidate.candidate.match(ipRegex).forEach(ipIterate);
    };
}

export const AcuireIP = new Promise(resolve => {
    try {
        findIP(resolve);
    } catch (e) {
        logger.error("AcuireIP error", e);
        resolve("0.0.0.0");
    }
});

export const getIPFingerprint = async () => {
    const ip = await AcuireIP;
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const visitorId = result.visitorId;
    return `${visitorId}${ip}`;
};

export function checkScrollbars() {
    const scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    logger.warning("checkScrollbars", scrollbarWidth); // Mac:  15

    // Delete the DIV
    document.body.removeChild(scrollDiv);
}
