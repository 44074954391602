import React, { useContext, useMemo } from "react";
import { Button, Dropdown, Menu, Space } from "antd";
import { CheckCircleOutlined, DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { KontoNumUtils } from "@binale-tech/shared";
import { useLocation, useNavigate } from "react-router-dom";

import { AppRoutes, getRoute } from "scripts/routing/routeConstants";
import { Category } from "../../../scripts/models";
import { CompanyContext } from "scripts/context/CompanyContext";
import { UserContext } from "scripts/context/UserProvider";

interface Props {
    selectedRecordGroup: string;
}

export const KassenbuchDropdown: React.FC<Props> = ({ selectedRecordGroup }) => {
    const user = useContext(UserContext);
    const { pathname } = useLocation();
    const { yearKbs, isLoaded, yearConfig } = useContext(CompanyContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const navigate = useNavigate();

    const buttonText = useMemo(() => {
        let title: React.ReactNode = <FormattedMessage id="app.titles.KB.pl" />;
        yearKbs.forEach(v => {
            if (v.id === selectedRecordGroup) {
                title =
                    KontoNumUtils.getExtNumPrint(String(v.accountNum), kontoExt, Category.DefaultLen) + " - " + v.name;
            }
        });
        if (pathname === AppRoutes.kassenbuchManage) {
            title = <FormattedMessage id="app.titles.settings.manage" />;
        }
        return title;
    }, [yearKbs, selectedRecordGroup, pathname, kontoExt]);

    const handleSelect: React.ComponentProps<typeof Menu>["onClick"] = v => {
        if (v.key === AppRoutes.kassenbuchManage) {
            return navigate(AppRoutes.kassenbuchManage);
        }
        navigate(getRoute(AppRoutes.kassenbuchOverview, { uuid: v.key.toString() }));
    };

    const menuItems: React.ComponentProps<typeof Menu>["items"] = React.useMemo(() => {
        return [
            ...yearKbs.map((b, i) => {
                const isActive = b.id === selectedRecordGroup;
                return {
                    key: b.id,
                    label: (
                        <Space>
                            {b.accountNum + " - " + b.name}
                            <CheckCircleOutlined style={{ opacity: isActive ? 1 : 0 }} />
                        </Space>
                    ),
                };
            }),
            { type: "divider" },
            { key: AppRoutes.kassenbuchManage, label: <FormattedMessage id="app.titles.settings.manage" /> },
        ];
    }, [yearKbs, selectedRecordGroup]);

    const isDisabled = !isLoaded || !user.isUserDataLoaded;

    return (
        <Dropdown
            menu={{ onClick: handleSelect, selectedKeys: [pathname], items: isDisabled ? [] : menuItems }}
            trigger={["click"]}
            disabled={isDisabled}
        >
            <Button className="Header--Button Header--Button__margin">
                {isDisabled ? <LoadingOutlined /> : buttonText} <DownOutlined />
            </Button>
        </Dropdown>
    );
};
