import React, { FC } from "react";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface IProps {
    tooltip: string;
}

export const InvisiblePage: FC<IProps> = ({ tooltip }) => {
    return (
        <Tooltip placement="top" title={`Hidden pages: ${tooltip}`}>
            <div className="pdf-tools-Page_hidden">
                <EyeInvisibleOutlined />
            </div>
        </Tooltip>
    );
};
