import React from "react";
import { Button, Form } from "antd";
import { PictureOutlined } from "@ant-design/icons";

type Props = {
    disabled: boolean;
    onExport: () => void;
    isLoading: boolean;
};
const ExportDocuments: React.FC<Props> = ({ onExport, disabled, isLoading }) => {
    return (
        <Form layout="vertical">
            <Form.Item label={<span>&nbsp;</span>}>
                <Button disabled={disabled} onClick={onExport} loading={isLoading} icon={<PictureOutlined />}>
                    Export Documents
                </Button>
            </Form.Item>
        </Form>
    );
};
export default React.memo(ExportDocuments);
