import Payment from "../../models/Payment";
import dayjs from "dayjs";
import { AbstractCsvTableExporter } from "./AbstractCsvExporter";
import { CsvConverter } from "../CsvConverter";
import { GenericRecord } from "../../models/GenericRecord";
import { TableItem } from "../../../appearance/components/shared/Table/Table";
import { Utils } from "@binale-tech/shared";
import { downloadCsv as csvDownload } from "../../infrastructure/downloader";

export class CategoryCsvExporter extends AbstractCsvTableExporter {
    getColumns(): string[] {
        return ["Konto", "Kategorie", "Summe"];
    }

    convert(tableItems: TableItem<GenericRecord>[], kontoExt: number): string[][] {
        const rowsMap = new Map();
        tableItems.forEach(tableItem => {
            tableItem.item.items.forEach(b => {
                const cc = b.getCategoryCreditor();
                const catExtNum = cc.getExtNum(kontoExt);
                if (!rowsMap.has(catExtNum)) {
                    rowsMap.set(catExtNum, [catExtNum, cc.name, 0]);
                }
                rowsMap.get(catExtNum)[2] += b.brutto;
            });
        });
        const rows = Array.from(rowsMap.values());
        rows.sort((a: any, b: any) => {
            const creComp = Number(a[0].replace(" ", "")) - Number(b[0].replace(" ", ""));
            if (creComp !== 0) {
                return creComp;
            }
            return a[2] - b[2];
        });
        rows.forEach(r => {
            r[2] = Utils.CurrencyUtils.currencyFormat(r[2]);
        });
        return rows;
    }

    getFilename() {
        return "Export_Category";
    }

    download(
        tableItems: TableItem<GenericRecord>[],
        kontoExt: number,
        payments: Map<string, Payment[]>,
        filenameSuffix?: string
    ) {
        const data = this.convert(tableItems, kontoExt);
        const name =
            [dayjs().format("YYYY.MM.DD"), this.getFilename(), filenameSuffix].filter(v => Boolean(v)).join("_") +
            ".csv";
        csvDownload(CsvConverter.getCsv([this.getColumns(), ...data]), name);
    }
}
