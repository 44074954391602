import { useContext } from "react";

import { CompanyContext } from "../../context/CompanyContext";
import { GQL } from "@binale-tech/shared";
import { ProductAccessUtils } from "../../models/utils/ProductAccessUtils";
import { ProductKey } from "../../models/Product";
import { UserContext } from "../../context/UserProvider";

export const useCanWrite = (productKey: ProductKey) => {
    const user = useContext(UserContext);
    const { companyGQL } = useContext(CompanyContext);
    const canWrite = ProductAccessUtils.canWrite(productKey, companyGQL, user);
    return canWrite;
};
export const useCanRead = (productKey: GQL.IProductKey) => {
    const user = useContext(UserContext);
    const { companyGQL } = useContext(CompanyContext);
    return ProductAccessUtils.canRead(productKey, companyGQL, user);
};
