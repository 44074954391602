import React, { useCallback, useMemo, useState } from "react";
import { AutoCompleteProps, Button, Input, InputProps, RefSelectProps } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { DefaultOptionType } from "antd/es/select";

import { BaseAutocomplete } from "../BaseSelect";
import { FlexRow } from "appearance/components/shared/appearance/page/Scaffold";

export interface InputWithAutocompleteProps extends AutoCompleteProps {
    selectTextOnFocus?: boolean;
    autocompleteList: string[];
    storageId: string;
    inputProps?: InputProps;
}

const getBlacklist = (storageId?: string): Set<string> => {
    if (storageId) {
        const lsData = localStorage.getItem(`blacklist:${storageId}`) || "[]";
        return new Set<string>(JSON.parse(lsData));
    }
    return new Set([]);
};

export const InputWithAutocomplete = React.forwardRef<RefSelectProps, InputWithAutocompleteProps>(
    function InputWithAutocomplete(
        { value, selectTextOnFocus, autocompleteList, storageId, inputProps, ...rest },
        autocompleteRef
    ) {
        const [blacklist, setBlacklist] = useState(getBlacklist(storageId));

        const handleAddToBlacklist = (val: string) => {
            if (storageId) {
                blacklist.add(val);
                setBlacklist(new Set(blacklist));
                localStorage.setItem(`blacklist:${storageId}`, JSON.stringify([...blacklist]));
            }
        };

        const options = useMemo(() => {
            return !value || value?.length < 3
                ? []
                : autocompleteList
                      .filter(v => !blacklist.has(v))
                      .filter(v => v !== value) // value should be excluded, otherwise you will get [object Object] in the input
                      .map(option => ({
                          key: option,
                          label: (
                              <FlexRow
                                  style={{ justifyContent: "space-between", alignItems: "center" }}
                                  role="listitem"
                              >
                                  <span>{option}</span>
                                  <Button
                                      icon={<CloseOutlined />}
                                      size="small"
                                      shape="circle"
                                      onClick={e => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          handleAddToBlacklist(option);
                                      }}
                                  />
                              </FlexRow>
                          ),
                          value: option,
                      }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [blacklist, value]);

        const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            inputProps?.onFocus && inputProps.onFocus(e);
            if (selectTextOnFocus) {
                e.target.select();
            }
        };

        const filterListFn = useCallback(
            (inputValue: string, option: DefaultOptionType) => {
                if (value?.length < 3) {
                    return false;
                }
                return option.value.toString().toLowerCase().startsWith(value.toLowerCase());
            },
            [value]
        );

        return (
            <BaseAutocomplete
                ref={autocompleteRef}
                dropdownStyle={{ maxWidth: 325 }}
                options={options}
                filterOption={filterListFn}
                value={value ? value.toString() : ""}
                {...rest}
            >
                <Input {...inputProps} onFocus={handleOnFocus} />
            </BaseAutocomplete>
        );
    }
);
