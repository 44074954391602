import React, { useMemo } from "react";
import cn from "classnames";
import { Dropdown } from "antd";
import { FormattedMessage } from "react-intl";
import { GenericRecord } from "scripts/models/GenericRecord";
import { GenericRecordTableItem } from "../../../../components/shared/Table/Table";
import { ItemType } from "antd/es/menu/interface";

type Props = {
    tableItem: GenericRecordTableItem;
    onClick: (review: GenericRecord["review"]) => void;
    canReviewRecord: boolean;
};

const computeStatusClass = (review: GenericRecord["review"], clickabe = true) =>
    cn("status", "status-review", {
        "status-clickable": clickabe,
        "status-noneColor": !review,
        "status-offenColor": review === "error",
        "status-bezahltColor": review === "ok",
        "status-avisColor": review === "question",
        "status-changedColor": review === "changed",
    });

const reviewItems: GenericRecord["review"][] = [undefined, "ok", "question", "error"];

export const ReviewDropdown: React.FC<Props> = ({ tableItem, onClick, canReviewRecord }) => {
    const items = useMemo(
        () =>
            reviewItems.map<ItemType>(review => ({
                key: review ?? "open",
                onClick: () => onClick(review),
                label: (
                    <p className={cn("status-review", computeStatusClass(review))}>
                        <FormattedMessage id={`app.fields.recordReview.${review ?? "new"}`} />
                    </p>
                ),
            })),
        [onClick]
    );

    // Don't render if the item is a subItem
    if (!tableItem.item.key) {
        return null;
    }

    const review = tableItem.item.review;

    return (
        <Dropdown menu={{ items }} trigger={["click"]} disabled={!canReviewRecord} placement="bottom">
            <p
                className={computeStatusClass(review, canReviewRecord)}
                style={{ textAlign: "center" }}
                onClick={e => e.stopPropagation()}
            >
                <FormattedMessage id={review ? `app.fields.recordReview.${review}` : "app.fields.recordReview.new"} />
            </p>
        </Dropdown>
    );
};
