export const getEnvCode = () => {
    if (document.location.host.includes("localhost")) {
        return "local";
    }
    if (document.location.host.includes("binale.tech") || document.location.host.includes("steuerflow-testing")) {
        return "testing-master";
    }
    if (import.meta.env.VITE_PRODUCTION_DATABASE) {
        return "production";
    }
    return "testing";
};
