import React, { CSSProperties, FC, useContext } from "react";
import { Droppable } from "react-beautiful-dnd";

import { PdfToolsContext, PdfToolsControlContext } from "../../context";
import { InvisibleDoc } from "./components/InvisibleDoc";
import { InvisiblePage } from "./components/InvisiblePage";
import { PageItem } from "./components/PageItem";
import { DroppableIds } from "../../types";

const InnerInvisiblePagesList: FC = () => {
    const { documents, viewDocuments, activePageId } = useContext(PdfToolsContext);
    const actions = useContext(PdfToolsControlContext);

    let globalIndex = 0;
    let invisibleDocs: number[] = [];
    let invisiblePages: number[] = [];

    return viewDocuments.map((document, docIdx) => {
        const firstVisiblePage = document?.pages?.find(page => !page.deleted);
        const visiblePages = document?.pages.filter(page => !page.deleted);
        const nextDoc = viewDocuments[docIdx + 1];
        const prevDoc = viewDocuments[docIdx - 1];
        if (!document.visible) {
            globalIndex += document.pages.length;
            invisibleDocs.push(docIdx + 1);
            if (!nextDoc || nextDoc.visible) {
                const savedInvisibleDocs = [...invisibleDocs];
                return (
                    <InvisibleDoc
                        key={document.id}
                        tooltip={invisibleDocs.join(", ")}
                        unifyDown={!invisibleDocs.includes(documents.length)}
                        unifyUp={!invisibleDocs.includes(1)}
                        unifyDocs={() => {
                            const pageIds = savedInvisibleDocs.map(dIdx => documents[dIdx - 1].pageIds[0]);
                            if (nextDoc) {
                                pageIds.push(nextDoc.pages[0].id);
                            }
                            actions.unifyDocuments(pageIds);
                        }}
                    />
                );
            }
            return null;
        }
        invisibleDocs = [];
        invisiblePages = [];
        const docStyle: CSSProperties = {
            marginTop: docIdx === 0 || !prevDoc?.visible ? 0 : "10px",
        };
        return (
            <div className="pdf-tools-SegmentFrame" style={docStyle} key={document.id}>
                {document.pages.map((page, pageIdx) => {
                    globalIndex++;
                    if (!page) {
                        return null;
                    }
                    const nextPage = document.pages[pageIdx + 1];
                    const prevPage = document.pages[pageIdx - 1];
                    if (page.deleted) {
                        invisiblePages.push(globalIndex);
                        if (!nextPage || !nextPage.deleted) {
                            return <InvisiblePage key={page.id} tooltip={invisiblePages.join(", ")} />;
                        }
                        return null;
                    }
                    invisiblePages = [];
                    return (
                        <PageItem
                            key={page.id}
                            page={page}
                            pageNumber={globalIndex}
                            docNumber={page.id === firstVisiblePage.id ? docIdx + 1 : undefined}
                            active={activePageId === page.id}
                            scrollBtn={visiblePages.length > 1}
                            splitBtn={pageIdx !== 0}
                            unifyBtn={docIdx !== 0 && pageIdx === 0 && prevDoc?.visible}
                            topBordered={pageIdx !== 0 && !prevPage?.deleted}
                        />
                    );
                })}
            </div>
        );
    });
};

export const ScrollInvisiblePages: FC = () => {
    return (
        <Droppable droppableId={DroppableIds.InvisibleDnd}>
            {provided => {
                return (
                    <>
                        <div
                            className="pdf-tools-ScrollBlock__Content"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <InnerInvisiblePagesList />
                            {provided.placeholder}
                        </div>
                    </>
                );
            }}
        </Droppable>
    );
};
