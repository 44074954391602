import React, { FC, useEffect, useState } from "react";
import { IDoesFilterPassParams } from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { Flex, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { ITableDocument } from "@dms/types";
import { GQL } from "@binale-tech/shared";

import styles from "../DocumentTable.module.scss";
import { TableRowColors } from "@dms/modules/DocumentTableModule/consts";
import cn from "classnames";

interface IFilterOptionProps {
    color: GQL.IDocumentColors;
    isSelected: boolean;
    onClick: (newColor: GQL.IDocumentColors) => void;
    showAll: boolean;
}

const FilterOption: FC<IFilterOptionProps> = ({ color, isSelected, onClick, showAll }) => {
    const borderColor = color === GQL.IDocumentColors.Yellow ? "SandyBrown" : color;

    return (
        <Flex
            align="center"
            gap={10}
            onClick={() => onClick(color)}
            className={showAll ? styles.showAllOption : styles.colorOption}
        >
            <Flex
                align="center"
                justify="center"
                className={styles.colorCircle}
                style={{ borderColor, backgroundColor: TableRowColors[color] }}
            >
                <div
                    className={cn({
                        [styles.innerCircle]: isSelected,
                        [styles.innerCircleColor]: isSelected && color !== GQL.IDocumentColors.Yellow && !showAll,
                        [styles.innerCircleYellow]: isSelected && color === GQL.IDocumentColors.Yellow,
                        [styles.innerCircleShowAllColor]: isSelected && showAll,
                    })}
                    style={{ color: showAll ? null : color }}
                />
            </Flex>
            {showAll ? (
                <FormattedMessage id="app.dms.showAll" />
            ) : (
                <FormattedMessage tagName="span" id={`app.button.color.${color}`} />
            )}
        </Flex>
    );
};

export const ColorFilter: FC<CustomFilterProps<ITableDocument>> = ({ onModelChange, api, model }) => {
    const [color, setColor] = useState<GQL.IDocumentColors>(model);

    const doesFilterPass = ({ node }: IDoesFilterPassParams<ITableDocument>) => {
        const nodeColor = node.data?.color;

        if (!color) {
            return true;
        }

        return color === nodeColor;
    };

    const onColorChange = (newColor: GQL.IDocumentColors) => {
        onModelChange(newColor);
        setColor(newColor);
    };

    useGridFilter({
        doesFilterPass,
    });

    useEffect(() => {
        setColor(model);
    }, [model]);

    return (
        <div className={styles.filterDropdown}>
            <Space direction="vertical">
                <FilterOption
                    color={null}
                    isSelected={color === null}
                    onClick={() => onColorChange(null)}
                    showAll={true}
                />
                {Object.values(GQL.IDocumentColors).map(itemColor => (
                    <FilterOption
                        key={itemColor}
                        color={itemColor}
                        isSelected={itemColor === color}
                        onClick={() => onColorChange(itemColor)}
                        showAll={false}
                    />
                ))}
            </Space>
        </div>
    );
};
