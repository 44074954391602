import { Checkbox, CheckboxRef, Form, type InputProps } from "antd";
import React, { forwardRef } from "react";

interface BaseInputIProps extends Omit<InputProps, "onChange" | "value"> {
    parentFieldName: string;
    onChange?: (val: boolean) => void;
    value?: boolean;
    setIsDisabled: (arg: boolean) => void;
}

export const FormItemCheckbox = forwardRef<CheckboxRef, BaseInputIProps>((props, ref) => {
    const { parentFieldName, checked, onChange, setIsDisabled, ...rest } = props;

    const form = Form.useFormInstance();

    const handleCheck = (value: boolean): void => {
        setIsDisabled(!value);
        onChange?.(value);

        if (!value) {
            form.resetFields([parentFieldName]);
        }
    };

    return (
        <div
            style={{
                position: "absolute",
                left: "-20px",
                top: 3,
            }}
        >
            <Checkbox
                {...rest}
                ref={ref}
                style={{ height: 16 }}
                checked={checked}
                onChange={e => {
                    handleCheck(e.target.checked);
                }}
            />
        </div>
    );
});
