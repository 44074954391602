export const getFileNameAndSuffix = (name: string): { fileName: string; suffix: string } => {
    const splittedValue = name.split(".");
    let fileName = name;
    let suffix = "";
    if (splittedValue.length > 1) {
        suffix = "." + splittedValue[splittedValue.length - 1];
        fileName = splittedValue.slice(0, -1).join(".");
    }
    return {
        fileName,
        suffix,
    };
};
