import React, { FC, memo, useContext } from "react";
import { PdfStatsView } from "./views/PdfStats";
import { UserCompaniesContext } from "scripts/context/UserProvider";

const PdfStatsContainer: FC = () => {
    const companies = useContext(UserCompaniesContext);

    return <PdfStatsView companies={companies} />;
};
export default memo(PdfStatsContainer);
