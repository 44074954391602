import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { DatePicker, Form, Input, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { Utils } from "@binale-tech/shared";

import BinaleForm from "appearance/components/shared/form/BinaleForm";
import Payment, { PaymentType } from "scripts/models/Payment";
import RecordSelector, { SelectionData } from "./Verrechnung/RecordSelector";
import { AntButtonBlock, BruttoInputBlock } from "../../../components/shared/form/FormBlocks";
import { CompanyContext } from "scripts/context/CompanyContext";
import { FlexColumn } from "../../../components/shared/appearance/page";
import { GenericRecord } from "scripts/models/GenericRecord";
import { InputPercents } from "../../../components/shared/form/baseComponents/InputPercents/InputPercents";
import { PaymentTypeSelector } from "./PaymentTypeSelector";
import { PaymentUtils } from "scripts/models/utils/PaymentUtils";
import { ProductKeys } from "scripts/models/Product";

interface Props {
    record: GenericRecord;
    openBetrag?: number;
    payment?: Payment;
    deletedPaymentKeys: string[];
    onSubmit?: (payments: Payment[]) => void;
    onClear?: () => void;
    disabled?: boolean;
}

export const ZahlungForm: React.FC<Props> = ({
    disabled,
    onSubmit,
    openBetrag,
    record = new GenericRecord(),
    onClear,
    payment,
    deletedPaymentKeys,
}) => {
    const { companyGQL, yearConfig, yearKbs, yearBanks } = React.useContext(CompanyContext);
    const maxAccountingYear = Math.max(...companyGQL.accountingYears);
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const recordBrutto = record?.getBrutto() || 0;
    const defaultPaymentMethod = React.useMemo(
        () => PaymentUtils.getDefaultPaymentMethod({ companyGQL, yearKbs, yearBanks }),
        [companyGQL, yearBanks, yearKbs]
    );
    const [innerPayment, setInnerPayment] = useState<Payment>(Payment.unserialize({}));
    const [showRecordSelectorModal, setShowRecordSelectorModal] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (payment) {
            setInnerPayment(Payment.unserialize(JSON.parse(JSON.stringify(payment))));
        } else {
            setInnerPayment(new Payment(new Date(), openBetrag || 0, defaultPaymentMethod, 0));
        }
        setSubmitted(false);
    }, [payment, openBetrag, companyGQL, yearKbs, yearBanks, yearConfig, defaultPaymentMethod]);

    const handleSkontoPercentsChange = (value: number) => {
        const newPayment = innerPayment;
        newPayment.setSkontoPercent(value, recordBrutto);
        setInnerPayment(Payment.unserialize(newPayment));
    };

    const handleSkontoBetragChange = (value: number) => {
        value = value || 0;
        const newPayment = innerPayment;
        newPayment.skontoBetrag = value;
        newPayment.zahlungsBetrag = recordBrutto - value;
        setInnerPayment(Payment.unserialize(newPayment));
    };

    const handleBetragChange = (value: number) => {
        const newPayment = innerPayment;
        newPayment.zahlungsBetrag = value;
        setInnerPayment(Payment.unserialize(newPayment));
    };

    const onRecordSelectorOk = (selectedRecords: SelectionData[]) => {
        if (selectedRecords.length > 0) {
            const recordPayments: Payment[] = [];
            selectedRecords.forEach(v => {
                const p = new Payment(new Date(record.date), v.brutto, PaymentType.VERRECHNUNG, 0);
                p.paymentSources = v.keys;
                recordPayments.push(p);
            });
            if (onSubmit) {
                onSubmit(recordPayments);
            }
        }
        setShowRecordSelectorModal(false);
    };

    const handleDateChange = (v: dayjs.Dayjs) => {
        if (!v) {
            return;
        }
        innerPayment.date = v.toDate();
        setInnerPayment(Payment.unserialize(innerPayment));
    };

    const handleAddClick = () => {
        setSubmitted(true);
        if (!innerPayment.zahlungsBetrag) {
            return;
        }
        if (onSubmit) {
            onSubmit([innerPayment]);
        }
    };

    const restBetrag = (openBetrag || 0) - innerPayment.getSum();
    const isEdit = Boolean(payment?.key);
    return (
        <FlexColumn>
            <Form layout="inline">
                <Form.Item label="Rechnungsbetrag">
                    <code>{Utils.CurrencyUtils.currencyFormat(record ? record.getBrutto() : 0)}</code>
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.fields.offner_betrag" />}>
                    <code>{Utils.CurrencyUtils.currencyFormat(openBetrag)}</code>
                </Form.Item>
                <Form.Item label="Restbetrag">
                    <code>{Utils.CurrencyUtils.currencyFormat(restBetrag)}</code>
                </Form.Item>
            </Form>

            <Form layout="inline">
                <Form.Item label={<FormattedMessage id="app.fields.date" />}>
                    {dayjs(record.date).format("DD.MM.YYYY")}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.fields.konto" />}>
                    {record.getRecordCategoryCreditor().getExtNumPrint(kontoExt)}{" "}
                    {record.getRecordCategoryCreditor().name}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="app.fields.rechnung_num" />}>{record.num}</Form.Item>
            </Form>

            <Form layout="inline">
                <Form.Item label={<FormattedMessage id="app.fields.skonto" />}>
                    <Space.Compact block>
                        <InputPercents
                            disabled={disabled}
                            value={innerPayment.skontoPercent}
                            onChange={handleSkontoPercentsChange}
                            data-testid="discount"
                        />
                        <Input
                            style={{
                                width: 30,
                                pointerEvents: "none",
                                backgroundColor: "white",
                            }}
                            placeholder="/"
                            disabled
                        />
                        <BruttoInputBlock
                            disabled={disabled}
                            value={innerPayment.skontoBetrag}
                            onChange={({ amount }) => handleSkontoBetragChange(amount)}
                            labelProps={{
                                label: null,
                                style: { marginBottom: 0 },
                            }}
                            data-testid="discountAmount"
                        />
                    </Space.Compact>
                </Form.Item>

                {record?.getProductKey() === ProductKeys.ER && (
                    <Form.Item label={<FormattedMessage id="app.fields.verrechnung" />}>
                        <AntButtonBlock
                            label={null}
                            key="record-selector-button"
                            icon={<PlusOutlined />}
                            disabled={disabled}
                            tooltip={<FormattedMessage id="app.payments.verrechnungen" />}
                            onClick={() => setShowRecordSelectorModal(true)}
                        >
                            <span>
                                <FormattedMessage id="app.fields.wählen" />
                            </span>
                        </AntButtonBlock>
                        <RecordSelector
                            key="record-selector-modal"
                            creditor={record.creditor}
                            deletedPaymentKeys={deletedPaymentKeys}
                            visible={showRecordSelectorModal}
                            onOk={onRecordSelectorOk}
                            onCancel={() => setShowRecordSelectorModal(false)}
                        />
                    </Form.Item>
                )}
            </Form>
            <BinaleForm>
                <Space>
                    <Form.Item label="Zahlungsdatum">
                        <DatePicker
                            disabledDate={current =>
                                current &&
                                (current.isBefore(dayjs(new Date(record.date.getFullYear(), 0, 1))) ||
                                    current.isAfter(dayjs(new Date(maxAccountingYear + 1, 0, 1, 0, 0, 0, -1))))
                            }
                            className="ZahlungenDatum"
                            value={dayjs(innerPayment.date)}
                            showToday={false}
                            disabled={disabled}
                            placeholder="Zahlungsdatum"
                            defaultValue={null}
                            onChange={handleDateChange}
                            data-testid="date"
                            format={"DD.MM.YYYY"}
                        />
                    </Form.Item>
                    <PaymentTypeSelector
                        style={{ width: 200 }}
                        value={innerPayment.type}
                        year={innerPayment?.date?.getFullYear()}
                        disabled={disabled}
                        onChange={paymentType => {
                            innerPayment.type = paymentType;
                            setInnerPayment(Payment.unserialize(innerPayment));
                        }}
                        data-testid="paymentType"
                    />
                    <BruttoInputBlock
                        labelProps={{
                            label: "Zahlungsbetrag",
                            fieldError:
                                !innerPayment.zahlungsBetrag && submitted ? { type: "error", message: " " } : undefined,
                        }}
                        disabled={disabled}
                        value={innerPayment.zahlungsBetrag}
                        onChange={({ amount }) => handleBetragChange(amount)}
                        data-testid="betrag"
                    />
                    <AntButtonBlock
                        icon={isEdit ? <CheckOutlined /> : <PlusOutlined />}
                        type="primary"
                        disabled={disabled}
                        onClick={handleAddClick}
                        data-testid="submitBtn"
                        htmlType="submit"
                    />
                    <AntButtonBlock
                        icon={<CloseOutlined />}
                        type="default"
                        disabled={disabled}
                        tooltip={<FormattedMessage id="app.components.form.clear" />}
                        onClick={() => onClear && onClear()}
                        data-testid="clearBtn"
                    />
                </Space>
            </BinaleForm>
        </FlexColumn>
    );
};
