import React, { FC, useCallback, useContext } from "react";

import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { RecordFormStateContext, RecordFormStateControlContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useFormHandlerDocument } from "../hooks/handlers/useFormHandlerDocument";
import { Badge, Button, Form, Tooltip } from "antd";
import { DeleteOutlined, PaperClipOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

export const FormButtonDocuments: FC = () => {
    const isDisabled = useFormIsRefDisabled();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { editableRecord } = useContext(RecordFormStateContext);
    const onDocumentsChange = useFormHandlerDocument();
    const { setIsDocumentsCardOpen } = useContext(RecordFormStateControlContext);

    const disabled = isDisabled(refsHtml.REF_cASSET);

    const onRemove = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            onDocumentsChange([]);
            setIsDocumentsCardOpen(false);
        },
        [onDocumentsChange, setIsDocumentsCardOpen]
    );

    const documentLen = editableRecord.recordDocuments?.length ?? 0;

    return (
        <Form.Item label={documentLen ? <DeleteOutlined className="DropzoneBlock__delete" onClick={onRemove} /> : " "}>
            <Tooltip placement="top" title={<FormattedMessage id="app.components.form.attachment" />}>
                <Badge
                    className="FileUploader FileUploader__Badge"
                    count={documentLen}
                    styles={{ indicator: { zIndex: 99 } }}
                >
                    <Button
                        ref={refsHtml.REF_cASSET}
                        disabled={disabled}
                        onClick={() => setIsDocumentsCardOpen(prev => !prev)}
                        role="button"
                        icon={<PaperClipOutlined />}
                    />
                </Badge>
            </Tooltip>
        </Form.Item>
    );
};
