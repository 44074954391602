import React, { FC, useContext } from "react";
import { BuchungstextInputBlock } from "../../shared/form/FormBlocks";
import { useProgramSettings } from "../hooks/useProgramSettings";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useIsItemFieldDisabledOutsideModal } from "../hooks/useIsItemFieldDisabledOutsideModal";
import { useFormHandlerItemText } from "../hooks/handlers/useFormHandlerItemText";

type Props = {
    isModalMode?: boolean;
};
export const FormItemText: FC<Props> = ({ isModalMode }) => {
    const isModalOnly = useIsItemFieldDisabledOutsideModal(isModalMode);
    const { hideBuchText } = useProgramSettings();
    const isDisabled = useFormIsRefDisabled();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { editableRecordItem, itemValidationStates } = useContext(RecordFormStateContext);
    const onTextChange = useFormHandlerItemText();
    if (hideBuchText) {
        return null;
    }

    const validation = itemValidationStates.get(refsHtml.REF_iTEXT);
    return (
        <BuchungstextInputBlock
            selectTextOnFocus
            ref={refsHtml.REF_iTEXT}
            labelProps={{
                style: { flexGrow: 1 },
                fieldError: validation ? { type: "error", message: " " } : undefined,
            }}
            value={isModalOnly ? undefined : editableRecordItem.itemText}
            onChange={onTextChange}
            disabled={isModalOnly || isDisabled(refsHtml.REF_iTEXT)}
        />
    );
};
