import React from "react";
import { Badge, Button, ButtonProps, Form, Tooltip } from "antd";

interface ButtonBlockProps extends ButtonProps {
    tooltip?: React.ReactNode;
    label?: React.ReactNode;
    badge?: Pick<React.ComponentProps<typeof Badge>, "color" | "dot" | "count">;
    tooltipPlacement?: React.ComponentProps<typeof Tooltip>["placement"];
}

export const AntButtonBlock = React.forwardRef<HTMLButtonElement, ButtonBlockProps>(function AntButtonBlock(
    { tooltip, badge, children, className, label = <span>&nbsp;</span>, tooltipPlacement, ...props },
    ref
) {
    let btn = (
        <Button ref={ref} {...props}>
            {children}
        </Button>
    );
    if (tooltip) {
        btn = (
            <Tooltip placement={tooltipPlacement || "top"} title={tooltip}>
                {btn}
            </Tooltip>
        );
    }
    if (badge) {
        btn = (
            <Badge {...badge} styles={{ indicator: { zIndex: 99 } }}>
                {btn}
            </Badge>
        );
    }
    if (label === null) {
        return btn;
    }
    return (
        <Form.Item label={label} className={className} data-testid="labeled">
            {btn}
        </Form.Item>
    );
});
