import { Component, ErrorInfo, ReactNode } from "react";

import { AppError } from "scripts/context/AppSettingsProvider";
import { logger } from "scripts/infrastructure/logger";

interface Props {
    children?: ReactNode;
    setError: (error: AppError | null) => void;
}

export class ErrorBoundary extends Component<Props, unknown> {
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logger.crit(error, errorInfo);
        this.props.setError(AppError.ReactRendering);
    }

    public render() {
        return this.props.children;
    }
}
