import React, { FC } from "react";
import { Divider } from "antd";
import { DividerProps } from "antd/es/divider";

import styles from "./divider.module.scss";

export const FormDivider: FC<DividerProps> = ({ children, orientation = "right" }) => {
    return (
        <Divider orientation={orientation} className={`${styles.formDivider} ${styles.formDividerText}`}>
            {children}
        </Divider>
    );
};
