import { Base, KontoNumUtils } from "@binale-tech/shared";
import { CategoryUtils } from "../../../../../../scripts/models/utils/CategoryUtils";
import { GenericRecord } from "scripts/models";
import { SHConverter } from "scripts/core/SollHaben";

export const getTaxMap = (records: GenericRecord[], converter: SHConverter, kontoExt: number) => {
    const taxMap = new Map<string, Base.IExtNum>();
    records.forEach(record => {
        converter
            .getAllItems(record)
            .filter(item => item.isTax)
            .forEach(buTax => {
                taxMap.set(buTax.konto.getExtNum(kontoExt), buTax.konto);
            });
    });

    return taxMap;
};

export const hasRecordTaxKonto = (record: GenericRecord, toolbarKonto: Base.IExtNum, converter: SHConverter) => {
    const suitableItem = converter
        .getAllItems(record)
        .find(shItem => shItem.isTax && shItem.konto.equalsTo(toolbarKonto));
    return Boolean(suitableItem);
};

export const isKontoMatchesFilter = (
    konto: Base.IExtNum,
    filterKonto: Base.IExtNum,
    yearConfig: { skr: number; kontoExt: number }
) => {
    if (!filterKonto) {
        return false;
    }
    if (konto.equalsTo(filterKonto)) {
        return true;
    }
    const { skr, kontoExt } = yearConfig;
    const isFilterKontoWildcardCreditor = CategoryUtils.isCategoryWildcardCreditor(filterKonto, skr);
    const isFilterKontoWildcardDebitor = CategoryUtils.isCategoryWildcardDebitor(filterKonto, skr);
    if (isFilterKontoWildcardCreditor) {
        return KontoNumUtils.isCreditor({ extNum: konto }, kontoExt);
    }
    if (isFilterKontoWildcardDebitor) {
        return KontoNumUtils.isDebitor({ extNum: konto }, kontoExt);
    }
    return false;
};
