import React, { FC, useState } from "react";
import { Alert, Button, Col, message, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { updatePassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { FirebaseAuthErrorCodes } from "scripts/api/firebase/firebaseErrorMessages";
import { NewPasswordForm } from "./form";
import { PageHeader } from "../../../components/shared/appearance/page";
import { auth } from "scripts/api/firebase/firebase";

export const ProfilePassword: FC = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [isNeedReLogin, setIsNeedReLogin] = useState<boolean>(false);

    const handleNewPasswordChange = async (newPassword: string) => {
        try {
            await updatePassword(auth.currentUser, newPassword);
            message.info(formatMessage({ id: "app.new_password.confirmation" }));
        } catch (error) {
            if (error.code === FirebaseAuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
                setIsNeedReLogin(true);
            }
        }
    };

    const handleRelogin = async () => {
        await auth.signOut(); // logout
        const pathWithReturnBack = `${AppRoutes.authLogin}?redirect=${encodeURIComponent(AppRoutes.profile)}`;
        navigate(pathWithReturnBack);
    };

    return (
        <>
            <Row>
                <Col span={6}>
                    <PageHeader>
                        <FormattedMessage id="app.profile.button.change_password" />
                    </PageHeader>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    {!isNeedReLogin && <NewPasswordForm onSubmit={handleNewPasswordChange} />}
                    {isNeedReLogin && (
                        <Alert
                            type="warning"
                            message={
                                <>
                                    <FormattedMessage id="app.firebase.error.auth_requires_recent_login" />
                                    <Button type="link" size="small" onClick={handleRelogin}>
                                        <FormattedMessage id="app.profile.button.re_login" />
                                    </Button>
                                </>
                            }
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};
