import React, { type FC, useContext, useEffect, useState } from "react";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { Col, Row, Segmented, Space, Tooltip } from "antd";
import Search from "antd/es/input/Search";
import { FormattedMessage } from "react-intl";

import { SelectedFilterSwitch } from "@dms/components/Toolbar/components/SelectedFilterSwitch";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { DmsAppContext, DmsAppControlContext } from "@dms/types/ContextTypes";

import { DraftSelectBtn } from "./components/DraftSelectBtn";
import { MonthSelectBtn } from "./components/MonthSelectBtn";
import { YearSelectBtn } from "./components/YearSelectBtn";
import { Notification } from "./Notifications/Notification";

export const Toolbar: FC = () => {
    const { view, searchValue } = useContext(DmsAppContext);
    const { viewToggle, setSearchValue } = useContext(DmsAppControlContext);

    const [inputValue, setInputValue] = useState(searchValue ?? "");
    const { selectedRowKeys } = useContext(DmsAppContext);

    const handleSearch = (value: string) => {
        if (value.startsWith("https://")) {
            const middleUrl = DocumentsApi.urlFormatter(value);
            const index = middleUrl.lastIndexOf("%2F");
            value = middleUrl.slice(index + 3, value.length - 1);
        }

        setSearchValue(value);
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        handleSearch(value);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            handleSearch(inputValue);
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    return (
        <Row justify={"space-between"}>
            <Col>
                <Space size={16}>
                    <YearSelectBtn />
                    <MonthSelectBtn />
                    <DraftSelectBtn />
                    <SelectedFilterSwitch />
                </Space>
            </Col>
            <Notification />
            <Col>
                <Space size={16}>
                    <Search
                        style={{ width: "230px" }}
                        onChange={handleInput}
                        value={inputValue}
                        disabled={selectedRowKeys.length > 0}
                    />
                    <Segmented
                        options={[
                            {
                                value: "list",
                                icon: (
                                    <Tooltip title={<FormattedMessage id="app.dms.list" />}>
                                        <BarsOutlined />
                                    </Tooltip>
                                ),
                            },
                            {
                                value: "grid",
                                icon: (
                                    <Tooltip title={<FormattedMessage id="app.dms.grid" />}>
                                        <AppstoreOutlined />
                                    </Tooltip>
                                ),
                            },
                        ]}
                        defaultValue={view}
                        onChange={(value: "list" | "grid") => {
                            viewToggle(value);
                        }}
                    />
                </Space>
            </Col>
        </Row>
    );
};
