import React, { FC } from "react";
import { Flex, Typography } from "antd";

interface IProps {
    title: React.ReactNode;
    button?: React.ReactNode;
}

export const PageHeader: FC<IProps> = ({ title, button }) => {
    return (
        <Flex align="center" justify="space-between" style={{ width: "100%", marginBottom: "10px" }}>
            <Typography.Title level={3}>{title}</Typography.Title>
            {button}
        </Flex>
    );
};
