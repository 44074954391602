import React, { type ReactNode, useContext, useEffect } from "react";
import styles from "./Notification.module.scss";
import { DmsAppContext, DmsAppControlContext } from "@dms/types/ContextTypes";
import { Button, Col, ConfigProvider, notification, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { type NotificationPlacement } from "antd/es/notification/interface";

const { Text } = Typography;

export const Notification = () => {
    const [api, contextHolder] = notification.useNotification();
    const { isTypeChangeMode, isDraggingRightNow, isDeleteMode } = useContext(DmsAppContext);
    const { resetSelectedRows, typeChangeModeToggle, deleteModeToggle } = useContext(DmsAppControlContext);

    useEffect(() => {
        if (isTypeChangeMode) {
            openNotification(
                "top",
                <FormattedMessage id="app.dms.changeModeEnabled" />,
                <FormattedMessage id="app.dms.moveSelectedDocuments" />
            );
        } else if (isDeleteMode) {
            openNotification(
                "top",
                <FormattedMessage id="app.dms.noticeOfDelete" />,
                <FormattedMessage id="app.dms.cantDelete" />
            );
            setTimeout(() => {
                deleteModeToggle(false);
                api.destroy();
            }, 3000);
        } else {
            api.destroy();
        }
    }, [isTypeChangeMode, isDraggingRightNow, isDeleteMode]);

    const handleClick = () => {
        typeChangeModeToggle(false);
        deleteModeToggle(false);
        resetSelectedRows();
        api.destroy();
    };

    const openNotification = (
        placement: NotificationPlacement,
        message: ReactNode | string,
        text: ReactNode | string
    ) => {
        api.info({
            message,
            description: (
                <Col>
                    <Text strong>{text}</Text>
                    <Button danger size="small" onClick={handleClick} className={styles.notificationButton}>
                        Cancel
                    </Button>
                </Col>
            ),
            placement,
            duration: null,
            closeIcon: null,
            className: `${styles.infoStyle}`,
        });
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Notification: {
                        width: 600,
                    },
                },
            }}
        >
            {contextHolder}
        </ConfigProvider>
    );
};
