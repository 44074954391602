import React, { Dispatch, FC, PropsWithChildren, SetStateAction, useState } from "react";

type TValue = { isDndFile: boolean };
type TControlValue = {
    setIsDndFile: Dispatch<SetStateAction<boolean>>;
};

/**
 Context for DMS Modal state
 */

export const DmsModalControlContext = React.createContext<TControlValue>({
    setIsDndFile: () => Promise.reject(),
});

/**
 Context for DMS Modal
 */
export const DmsModalContext = React.createContext({} as TValue);

/**
 Context Provider for DMS Modal
 */

export const DmsModalProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isDndFile, setIsDndFile] = useState(false);

    const value: TValue = React.useMemo(
        () => ({
            isDndFile,
        }),
        [isDndFile]
    );

    const controls = React.useMemo(
        () => ({
            setIsDndFile,
        }),
        [setIsDndFile]
    );

    return (
        <DmsModalContext.Provider value={value}>
            <DmsModalControlContext.Provider value={controls}>{children}</DmsModalControlContext.Provider>
        </DmsModalContext.Provider>
    );
};
