import React, { FC, useContext } from "react";
import { EditOutlined, SmileTwoTone } from "@ant-design/icons";
import { Badge, Button } from "antd";
import { GQL } from "@binale-tech/shared";
import { DatevContext } from "../../../scripts/context/DatevContext";

type Props = {
    company: GQL.ICompany;
    disabled: boolean;
    onClick: () => void;
};
export const EditButton: FC<Props> = ({ disabled, onClick, company }) => {
    const { datevUserInfo } = useContext(DatevContext);
    const isDatevConnectionAvailable = (datevUserInfo?.clients || []).some(
        c => c.clientNumber === company.datevNrCompany && c.consultantNumber === company.datevNrConsultant
    );
    return (
        <Badge count={isDatevConnectionAvailable ? <SmileTwoTone twoToneColor="#96d551" /> : 0}>
            <Button shape="circle" disabled={disabled} icon={<EditOutlined />} onClick={onClick} />
        </Badge>
    );
};
