import { DmsTableCols } from "@dms/modules/DocumentTableModule/consts";

export const discountColumns = [
    DmsTableCols.DISCOUNT_AMOUNT,
    DmsTableCols.DISCOUNT_DATE,
    DmsTableCols.DISCOUNT_PERCENT,
    DmsTableCols.DISCOUNT_DAYS,
];

export const currencyColumns = [
    DmsTableCols.CURRENCY,
    DmsTableCols.CURRENCY_RATE,
    DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT,
];

export const deadlineColumns = [DmsTableCols.DEADLINE_DAYS, DmsTableCols.DUE_DATE];

export const infoColumns = [
    DmsTableCols.FILE_NAME,
    DmsTableCols.CREATED_AT,
    DmsTableCols.CREATED_BY,
    DmsTableCols.UPDATED_AT,
    DmsTableCols.NUM_PAGES,
];
