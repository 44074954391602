import { useCallback, useContext } from "react";
import { Tag } from "../../../../../scripts/models";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { useFormValidators } from "../useFormValidators";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";

export const useFormHandlerItemTag = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setItemFieldErrorState } = useFormValidators();
    const { updateEditableItem } = useFormRecordStateUpdater();
    return useCallback(
        (itemTag: Tag) => {
            updateEditableItem({ itemTag });
            setItemFieldErrorState(refsHtml.REF_iTAG, false);
        },
        [refsHtml, updateEditableItem, setItemFieldErrorState]
    );
};
