import React, { useContext, useState } from "react";
import { Button, Col, Popconfirm, Row, Table, TableColumnType } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";

import { UserContext } from "scripts/context/UserProvider";
import { CompanyContext, CompanyControlContext } from "scripts/context/CompanyContext";
import { FlexColumn, Page, PageHeader } from "appearance/components/shared/appearance/page";
import { GlobalYearSelect } from "../../components/shared/Toolbar/YearSelect";
import { KassenbuchFormModal } from "appearance/components/kassenbuch/KassenbuchFormModal";
import { ProductKeys } from "scripts/models/Product";
import { useCanWrite } from "scripts/infrastructure/hooks/usePermissions";

interface TableRecord {
    nr: number;
    key: number;
    entity: GQL.ICompanyKasse;
}

export const KBManageView: React.FC = () => {
    const { companyGQL, yearKbs } = useContext(CompanyContext);
    const user = useContext(UserContext);
    const { onKBDelete } = React.useContext(CompanyControlContext);
    const canWrite = useCanWrite(ProductKeys.KB);
    const accountingYears = companyGQL?.accountingYears || [];

    const [showModal, setShowModal] = useState(false);
    const [kassenbuch, setKassenbuch] = useState<GQL.ICompanyKasse | null>(null);

    if (user.isLoading) {
        return null;
    }

    const dataSource: TableRecord[] = yearKbs.map((entity, key) => {
        return {
            entity,
            key,
            nr: key + 1,
        } as TableRecord;
    });

    const columns: TableColumnType<TableRecord>[] = [
        {
            title: <FormattedMessage id="app.fields.lfdnr" />,
            dataIndex: "nr",
            key: "nr",
            width: 75,
        },
        {
            title: <FormattedMessage id="app.fields.konto" />,
            key: "num",
            render: (cell, record) => record.entity.accountNum,
        },
        {
            title: <FormattedMessage id="app.fields.name" />,
            render: (cell, record) => record.entity.name,
            key: "name",
        },
        {
            title: <FormattedMessage id="app.fields.actions" />,
            key: "actions",
            width: 100,
            render: (cell, record) => (
                <span>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            setKassenbuch({ ...record.entity });
                            setShowModal(true);
                        }}
                    />{" "}
                    <Popconfirm
                        placement={"left"}
                        onConfirm={() => onKBDelete({ id: record.entity.id, num: String(record.entity.accountNum) })}
                        title={<FormattedMessage id="app.confirmation.header" />}
                        okText={<FormattedMessage id="app.button.confirm" />}
                        cancelText={<FormattedMessage id="app.button.cancel" />}
                    >
                        <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <Page style={{ padding: 24, paddingTop: 8 }}>
            <PageHeader>
                <FormattedMessage id="app.titles.KB.pl" />
            </PageHeader>

            <FlexColumn>
                <Row gutter={24}>
                    <Col>
                        <GlobalYearSelect />
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setKassenbuch(null);
                                setShowModal(true);
                            }}
                            style={{ marginBottom: 15, width: 250 }}
                            disabled={accountingYears.length === 0}
                        >
                            <FormattedMessage id="app.titles.KB.pl" tagName="span" />
                            &nbsp;
                            <FormattedMessage id="app.button.add" />
                        </Button>
                    </Col>
                </Row>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    size="middle"
                    pagination={{ pageSize: 10, hideOnSinglePage: true }}
                    bordered
                />
            </FlexColumn>
            <KassenbuchFormModal
                show={showModal}
                onHide={() => {
                    setKassenbuch(null);
                    setShowModal(false);
                }}
                disabled={!canWrite}
                entity={kassenbuch}
            />
        </Page>
    );
};
