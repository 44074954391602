import React, { FC, useContext, useMemo } from "react";
import { InvoiceFormContext } from "@inv/modules/InvoiceFormModule/context/InvoiceFormProvider";
import { themeConfig } from "@inv/theme";
import { FormattedMessage, useIntl } from "react-intl";
import { getLineItemNetto, InvoiceFormInputTranslate, TInvoicesValues } from "@inv/types";
import { Flex, Form } from "antd";
import { TotalItem } from "@inv/components/InvoiceTableBlock/components/InvoiceTotal/TotalItem";
import { decimalFormatter } from "@dms/scripts/helpers";

export const InvoiceTotal: FC = () => {
    const intl = useIntl();
    const form = Form.useFormInstance<TInvoicesValues>();
    const values = form.getFieldsValue();
    const { currencyCode, lineItems } = values;
    const { invoiceTotalView } = useContext(InvoiceFormContext);
    const totalState = useMemo(() => {
        const { netto, tax0, tax7, tax19, amount7, amount19 } = lineItems.reduce(
            (acc, row) => {
                const netto = getLineItemNetto(row) / 100;
                acc.netto += netto;
                const taxTotal = netto * (row.tax / 100);

                if (row.tax === 7) {
                    acc.tax7 += taxTotal;
                    acc.amount7 += netto;
                } else if (row.tax === 19) {
                    acc.tax19 += taxTotal;
                    acc.amount19 += netto;
                } else {
                    acc.tax0 += taxTotal;
                }

                return acc;
            },
            {
                netto: 0,
                tax0: 0,
                tax7: 0,
                tax19: 0,
                amount7: 0,
                amount19: 0,
            }
        );

        const total = netto + tax7 + tax19;

        return {
            subtotal: decimalFormatter(netto),
            tax0: tax0 ? decimalFormatter(tax0) : null,
            tax7: tax7 ? decimalFormatter(tax7) : null,
            tax19: tax19 ? decimalFormatter(tax19) : null,
            amount7: tax7 ? decimalFormatter(amount7) : null,
            amount19: tax19 ? decimalFormatter(amount19) : null,
            total: decimalFormatter(invoiceTotalView ? total : netto),
        };
    }, [invoiceTotalView, lineItems]);

    return (
        <Flex
            vertical
            align="start"
            style={{
                height: "100%",
                maxWidth: 420,
                padding: 2,
                width: "100%",
                border: themeConfig.border,
                borderRadius: 8,
            }}
        >
            {invoiceTotalView && (
                <>
                    <TotalItem
                        label={<FormattedMessage id={InvoiceFormInputTranslate.SUB_TOTAL} />}
                        value={totalState.subtotal}
                        currencyCode={currencyCode}
                        isBold={false}
                    />
                    {totalState.tax0 && (
                        <TotalItem
                            label={`${intl.formatMessage({ id: InvoiceFormInputTranslate.VAT })} 0%`}
                            value={totalState.tax0}
                            currencyCode={currencyCode}
                            isBold={false}
                        />
                    )}
                    {totalState.tax7 && (
                        <TotalItem
                            label={`zzgl. Umsatzsteuer 7%`}
                            value={totalState.tax7}
                            amount={totalState.amount7}
                            currencyCode={currencyCode}
                            isBold={false}
                        />
                    )}
                    {totalState.tax19 && (
                        <TotalItem
                            label={`zzgl. Umsatzsteuer 19%`}
                            value={totalState.tax19}
                            amount={totalState.amount19}
                            currencyCode={currencyCode}
                            isBold={false}
                        />
                    )}
                </>
            )}

            <TotalItem
                label={<FormattedMessage id={InvoiceFormInputTranslate.TOTAL_AMOUNT} />}
                value={totalState.total}
                currencyCode={currencyCode}
                isBold={true}
            />
        </Flex>
    );
};
