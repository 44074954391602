import { useCallback, useContext } from "react";
import { PdfToolsControlContext } from "../context";
import { OcrPage } from "../types";
import { createRotatedImageUrl } from "../tools/createRotatedImageUrl";
import { logger } from "../../scripts/infrastructure/logger";
import PQueue from "p-queue";

export const useImageRotation = (pages: OcrPage[]) => {
    const actions = useContext(PdfToolsControlContext);

    const rotateImage = useCallback(async () => {
        const promiseArr = pages.map(page => async () => {
            actions.pageCalculation(page.id, true);

            const rotateDegree = 90;
            const currentRotation = page.rotate || 0;
            const newRotation = (currentRotation + rotateDegree) % 360;

            let rotatedImageUrl = page.imageUrl;

            try {
                const rotatedUrl = await createRotatedImageUrl(page.imageUrl, rotateDegree);
                if (rotatedImageUrl) {
                    URL.revokeObjectURL(rotatedImageUrl);
                }
                rotatedImageUrl = rotatedUrl;
            } catch (error) {
                logger.error("Error generating rotated image URL:", error);
            } finally {
                actions.rotatePages([page.id], rotatedImageUrl, newRotation);
                actions.pageCalculation(page.id, false);
            }
        });

        const queue = new PQueue({ concurrency: 4 });
        await queue.addAll(promiseArr);
    }, [pages, actions]);

    return { rotateImage };
};
