import React, { useContext } from "react";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { CompanyContext } from "scripts/context/CompanyContext";

export const useProgramSettings = () => {
    const { programSettingsProvider } = React.useContext(CompanyContext);
    const { product } = useContext(TableViewContext);
    return React.useMemo(
        () => programSettingsProvider(product.productKey()),
        [programSettingsProvider, product.productKey()]
    );
};
