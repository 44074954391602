import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";

interface IProps {
    children: ReactNode;
}

export const OverflowTooltip: FC<IProps> = ({ children }) => {
    const [isChildrenOverflowing, setIsChildrenOverflowing] = useState(false);
    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const childrenElement = textRef.current;
        if (childrenElement) {
            setIsChildrenOverflowing(childrenElement.offsetWidth < childrenElement.scrollWidth);
        }
    }, [children]);
    return (
        <Tooltip placement="right" title={isChildrenOverflowing ? children : null}>
            <div
                ref={textRef}
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {children}
            </div>
        </Tooltip>
    );
};
