import { FBRef } from "@binale-tech/shared";
import { database } from "./firebase";
import { get, ref } from "firebase/database";

export type Reference = ReturnType<typeof ref>;
export type DataSnapshot = Awaited<ReturnType<typeof get>>;

export const refRecordsER = ref(database, FBRef.Records_ER);
export const refRecordsERLog = ref(database, FBRef.RecordsLogs_ER);
export const refRecordsErA = ref(database, FBRef.Records_ER_A);
export const refRecordsErALog = ref(database, FBRef.RecordsLogs_ER_A);
export const refRecordsFE = ref(database, FBRef.Records_FE);
export const refRecordsFELog = ref(database, FBRef.RecordsLogs_FE);
export const refRecordsLA = ref(database, FBRef.Records_LA);
export const refRecordsLALog = ref(database, FBRef.RecordsLogs_LA);
export const refRecordsDeb = ref(database, FBRef.Records_Deb);
export const refRecordsDebLog = ref(database, FBRef.RecordsLogs_Deb);
export const refRecordsKb = ref(database, FBRef.Records_KB);
export const refRecordsKbLogs = ref(database, FBRef.RecordsLogs_KB);
export const refRecordsBank = ref(database, FBRef.Records_Bank);
export const refRecordsBankLogs = ref(database, FBRef.RecordsLogs_Bank);
export const refRecordsPos = ref(database, FBRef.Records_Pos);
export const refRecordsPosLogs = ref(database, FBRef.RecordsLogs_Pos);

export const refPayments = ref(database, FBRef.Payments);

export const refCreditor = ref(database, FBRef.Creditors);
export const refDebitor = ref(database, FBRef.Debitors);
export const refCategory = ref(database, FBRef.Categories);
export const refTag = ref(database, FBRef.Tags);

export const refUsersSessions = ref(database, FBRef.UsersSessions);
export const refContacts = ref(database, FBRef.Contacts);
export const refDocuments = ref(database, FBRef.Documents);
export const refSettings = ref(database, FBRef.Settings);
export const refBanks = ref(database, FBRef.Banks);
export const refTemplates = ref(database, FBRef.Templates);
export const refInvoices = ref(database, FBRef.Invoices);
