export const transactionFieldsDict = {
    bookingDate: "app.fields.date", // buchungstag
    transactionType: "app.fields.transactionType", // umsatzart
    purpose: "app.fields.purpose", // verwendungszweck
    counterpartyName: "app.fields.counterpartyName", // beguenstigter/zahlungspflichtiger
    counterpartyIbanOrAccountNum: "app.fields.counterpartyIbanOrAccountNum", // kontonummer/iban
    counterpartyBicOrBlz: "app.fields.counterpartyBicOrBlz",
    amount: "app.fields.betrag", // betrag
    currencyCode: "app.fields.currency", // waehrung

    // myBankAccount: "app.fields.myBankAccount", // auftragskonto
    // myBankIban: "app.fields.myIban", // iban kontoinhaber
    // bookingText: "Booking text", // buchungstext
    // creditorId: "Creditor ID", // glaeubiger id
    // mandateReference: "Mandate reference", // mandatsreferenz
    // customerReference: "Customer reference (end-to-end)", // kundenreferenz (end-to-end)
    // collectorReference: "Collector reference", // sammlerreferenz
    // debitOriginalAmount: "Debit original amount", // lastschrift ursprungsbetrag
    // expenseReimbursementReturnDebit: "Expense reimbursement return debit", // auslagenersatz ruecklastschrift
};

export const transactionFieldsGroups = {
    transactionInfo: {
        bookingDate: transactionFieldsDict.bookingDate,
        amount: transactionFieldsDict.amount,
        currencyCode: transactionFieldsDict.currencyCode,
        purpose: transactionFieldsDict.purpose,
        transactionType: transactionFieldsDict.transactionType,
    },
    counterparty: {
        counterpartyName: transactionFieldsDict.counterpartyName,
        counterpartyIbanOrAccountNum: transactionFieldsDict.counterpartyIbanOrAccountNum,
        counterpartyBicOrBlz: transactionFieldsDict.counterpartyBicOrBlz,
    },
};

export const transactionFieldsGroupTitle: Required<Record<keyof typeof transactionFieldsGroups, string>> = {
    transactionInfo: "app.banks.transactionInfo",
    counterparty: "app.banks.counterparty",
};

export const autoMappingFields: Record<string, Partial<keyof typeof transactionFieldsDict>> = {
    buchungstag: "bookingDate",
    betrag: "amount",
    waehrung: "currencyCode",
    währung: "currencyCode",
};

export const excludedFields: Record<string, string> = {
    valutadatum: "valueDate",
    wertstellung: "valueDate",
    wert: "valueDate",
    auftragskonto: "auftragskonto",
};

export const requiredFields: readonly Partial<keyof typeof transactionFieldsDict>[] = Object.freeze([
    "bookingDate",
    "amount",
]);
