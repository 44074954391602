export const createRotatedImageUrl = (imageUrl: string, rotation: number) => {
    return new Promise<string>((resolve, reject) => {
        if (!imageUrl) {
            reject("Image URL is not provided.");
            return;
        }

        const image = new Image();
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        image.onload = () => {
            if (rotation === 90 || rotation === 270) {
                canvas.height = image.width;
                canvas.width = image.height;
            } else {
                canvas.height = image.height;
                canvas.width = image.width;
            }

            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate((Math.PI / 180) * rotation);
            context.drawImage(image, -image.width / 2, -image.height / 2);

            canvas.toBlob(blob => {
                if (!blob) {
                    reject("Failed to create a blob.");
                    return;
                }
                const rotatedUrl = URL.createObjectURL(blob);
                resolve(rotatedUrl);
            }, "image/jpeg");
        };

        image.onerror = () => {
            reject("Failed to load image.");
        };

        image.src = imageUrl;
    });
};
