import { GQL } from "@binale-tech/shared";
import dayjs from "dayjs";
import { ApolloClient, gql } from "@apollo/client";

const currencyRate = gql`
    query currencyRate($isoDate: String!, $code: CurrencyCode) {
        currencyRate(isoDate: $isoDate, code: $code)
    }
`;

export const retrieveCurrencyRate = async ({
    client,
    documentDate,
    currencyCode,
}: {
    client: ApolloClient<object>;
    documentDate: string | Date;
    currencyCode: GQL.ICurrencyCode;
}) => {
    try {
        const date = documentDate instanceof Date ? dayjs(documentDate) : dayjs(documentDate, "DD.MM.YYYY");
        const res = await client.query<Pick<GQL.IQuery, "currencyRate">, GQL.IQueryCurrencyRateArgs>({
            query: currencyRate,
            fetchPolicy: "network-only",
            variables: {
                isoDate: date.format("YYYY-MM-DD"),
                code: currencyCode,
            },
        });
        return res.data.currencyRate;
    } catch {
        return 1 as number;
    }
};
