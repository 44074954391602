import { Col, message } from "antd";
import { PdfPreviewBlock } from "../PdfPreviewBlock";
import { useIntl } from "react-intl";
import { SplitModeButtons } from "../SplitModeButtons";
import React, { FC, useCallback, useContext } from "react";
import { SplitMode } from "../../types";
import { PdfToolsControlContext } from "../../context";
import { DropFileZone } from "../DropFileZone/DropFileZone";
import { isFileEncrypted } from "../../context/helpers/helpersPdf";

type TProps = {
    children?: React.ReactNode;
    decodePdf: (mode: SplitMode) => Promise<void>;
};

export const PdfToolPreview: FC<TProps> = ({ children, decodePdf }) => {
    const actions = useContext(PdfToolsControlContext);
    const { formatMessage } = useIntl();

    const onDrop = useCallback(
        async (files: File[]) => {
            const isEncrypted = await isFileEncrypted(files[0]);
            if (isEncrypted) {
                message.error(formatMessage({ id: "app.ocr.error.encrypted" }));
            } else {
                await actions.addFiles(files);
            }
        },
        [formatMessage, actions]
    );

    return (
        <>
            <PdfPreviewBlock sizeRatio={[4, 13]} />

            <Col
                span={7}
                className="FlexColumn"
                style={{ position: "relative", height: "100%", padding: "5px 15px 5px 5px" }}
            >
                {children}
                <SplitModeButtons onDecode={decodePdf} />
                <div style={{ width: "100%", height: "100%", marginTop: "10px" }}>
                    <DropFileZone uploadFile={onDrop} multiple={true} />
                </div>
            </Col>
        </>
    );
};
