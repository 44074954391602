import { useCallback, useContext, useMemo } from "react";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { useFormValidators } from "../useFormValidators";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";
import { Bu } from "@binale-tech/shared";

export const useFormHandlerItemBu = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setItemFieldErrorState } = useFormValidators();
    const { updateEditableItem } = useFormRecordStateUpdater();

    const onUstChange = useCallback(
        (itemBu: Bu.Bu, ust13b?: Bu.USt13bOption) => {
            updateEditableItem({ itemBu, itemUSt13b: ust13b || undefined });
            setItemFieldErrorState(refsHtml.REF_iBU, false);
        },
        [refsHtml, updateEditableItem, setItemFieldErrorState]
    );
    const onChange13b = useCallback(
        (ust13b?: Bu.USt13bOption) => {
            updateEditableItem({ itemUSt13b: ust13b || undefined });
            setItemFieldErrorState(refsHtml.REF_iBU, false);
        },
        [refsHtml, updateEditableItem, setItemFieldErrorState]
    );
    return useMemo(() => ({ onUstChange, onChange13b }), [onUstChange, onChange13b]);
};
