import React, { FC } from "react";
import { POSProvider } from "../POSProvider";
import { TemplatesViewContent } from "@app/views/productSharedComponents/templates/TemplatesViewContent";

export const POSTemplatesView: FC = () => {
    return (
        <POSProvider>
            <TemplatesViewContent />
        </POSProvider>
    );
};
