import React, { useMemo, useState } from "react";
import { Card, Col, Drawer, Radio, Row } from "antd";
import { FormattedMessage } from "react-intl";

import { Base, GQL } from "@binale-tech/shared";
import { Category } from "scripts/models";
import { CategoryForm } from "./CategoryForm";
import { CategoryUtils } from "../../../../../scripts/models/utils/CategoryUtils";
import { CompanyContext } from "scripts/context/CompanyContext";
import { KontoContext, KontoControlContext } from "scripts/context/KontoEntitiesProvider";
import { GlobalYearSelect } from "../../../../components/shared/Toolbar/YearSelect";
import { NumNameTable } from "../NumNameTable";
import { ProductKeys } from "scripts/models/Product";
import { getFiltered, SearchField } from "../helpers";
import { gql } from "@apollo/client";
import { useCanWrite, useDefaultCategoriesByYearMonth } from "scripts/infrastructure/hooks";
import { useGqlMutator } from "../../../../../scripts/graphql/useGqlMutator";

enum Mode {
    all = "all",
    user = "user",
}

interface IFilter {
    mode: Mode;
    search: string;
}

const categoryMark = gql`
    mutation categoryMark($input: CategoryMarkInput!) {
        categoryMark(input: $input) {
            id
            marked
        }
    }
`;

const EditCategoryView: React.FC = () => {
    const { categoryOverrides } = React.useContext(KontoContext);
    const { yearConfig, yearBanks, yearKbs, companyGQL } = React.useContext(CompanyContext);
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const skr = yearConfig?.skr;
    const { categoryOverrideSave, categoryOverrideDelete } = React.useContext(KontoControlContext);
    const mutator = useGqlMutator();

    const [activeItem, setActiveItem] = React.useState<Base.INumName>();
    const handleClear = React.useCallback(() => setActiveItem(null), []);

    const defaultCategories = useDefaultCategoriesByYearMonth();
    const canWrite = useCanWrite(ProductKeys.AccountingCommon);

    const [filters, setFilters] = useState<IFilter>({ mode: Mode.user, search: "" });

    const items = useMemo(() => {
        const arr = filters.mode === Mode.all ? Array.from(defaultCategories.values()) : categoryOverrides;
        return getFiltered(arr, filters.search, kontoExt).map(entity => ({
            entity,
            disabled: CategoryUtils.isAggregationCategory(entity as Base.IExtNum, skr),
            defaultCategory: defaultCategories.get(Number(entity.num.substring(0, Base.KontoDefaultLen.Category))),
        }));
    }, [filters, categoryOverrides, defaultCategories, kontoExt, skr]);

    const isRemovable = React.useCallback(
        (v: Category) => {
            if (!yearConfig || !canWrite) {
                return false;
            }
            const ext = yearConfig.kontoExt;
            const isCompanyBank = yearBanks.findIndex(b => CategoryUtils.areEqual(String(b.accountNum), v.num)) > -1;
            if (isCompanyBank) {
                return false;
            }
            const isCompanyKasse = yearKbs.findIndex(b => CategoryUtils.areEqual(String(b.accountNum), v.num)) > -1;
            if (isCompanyKasse) {
                return false;
            }
            // we can not remove categories ending on `00` (not user created categories)
            // 0500 00 -> can't be removed, 0500 01 -> can
            return yearConfig.kontoExt > 0 && Number(v.getExtNum(ext).substring(Category.DefaultLen)) !== 0;
        },
        [canWrite, yearBanks, yearConfig, yearKbs]
    );

    return (
        <div className="ManageAccount__container">
            <Row align="middle" gutter={24} style={{ marginTop: 16, marginBottom: 16 }}>
                <Col>
                    <GlobalYearSelect />
                </Col>
                <Col>
                    <Radio.Group value={filters.mode} onChange={e => setFilters({ ...filters, mode: e.target.value })}>
                        <Radio.Button value={Mode.all}>
                            <FormattedMessage id="app.components.all" />
                        </Radio.Button>
                        <Radio.Button value={Mode.user}>
                            <FormattedMessage id="app.components.used" />
                        </Radio.Button>
                    </Radio.Group>
                </Col>
                <Col>
                    <SearchField onSearch={search => setFilters({ ...filters, search })} />
                </Col>
                <div style={{ flex: 1 }} />
                {/* <Col> */}
                {/*     <Button type={"primary"} icon={<PlusOutlined />} /> */}
                {/* </Col> */}
            </Row>

            <NumNameTable
                items={items}
                onClick={item => setActiveItem(filters.mode === Mode.user ? item : { name: item.name, num: item.num })}
                isRemovable={isRemovable}
                onDeleteClick={item => categoryOverrideDelete(item.key, item.num)}
                showDefaultName={filters.mode === Mode.user}
                onMarkClick={(v, marked) => {
                    mutator.mutate<"categoryMark", GQL.ICategoryMarkInput>({
                        mutation: categoryMark,
                        input: {
                            accountNumString: new Category(v.num, "").getExtNum(kontoExt),
                            name: v.name,
                            companyId: companyGQL.id,
                            year: yearConfig.year,
                            marked,
                        },
                    });
                }}
            />
            <Drawer open={Boolean(activeItem)} onClose={handleClear} destroyOnClose>
                <Card>
                    <CategoryForm
                        item={activeItem}
                        disabled={!activeItem || !canWrite}
                        onSave={categoryOverrideSave}
                        onClose={handleClear}
                    />
                </Card>
            </Drawer>
        </div>
    );
};
export default React.memo(EditCategoryView);
