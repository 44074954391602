import { useCallback, useContext } from "react";
import { Creditor } from "../../../../scripts/models";
import { PaymentBindingUtils } from "../../../../scripts/models/utils/PaymentBindingUtils";
import { PaymentUtils } from "../../../../scripts/models/utils/PaymentUtils";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { useFormConfig } from "./useFormConfig";
import { GQL } from "@binale-tech/shared";
import { useFormRecordComposer } from "./useFormRecordComposer";
import { useUpdatesOnPaymentConnection } from "./useFormIntegrations";
import { RecordFormStateControlContext } from "../context/RecordFormState";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { useFormRecordStateUpdater } from "./useFormRecordStateUpdater";

export const useCheckAutoPaymentConnection = () => {
    const { recordsDeb, recordsAZ, recordsER } = useContext(RecordsContext);
    const payments = useContext(PaymentsContext);
    const { refsHtml, refsData } = useContext(RecordFormPropsContext);
    const { setSelectedPayment } = useContext(RecordFormStateControlContext);
    const { product } = useContext(TableViewContext);
    const { composeFormRecord } = useFormRecordComposer();
    const productFormConfig = useFormConfig();
    const { updateEditableItem, updateEditableRecord } = useFormRecordStateUpdater();
    const getUpdatesOnPaymentConnection = useUpdatesOnPaymentConnection();

    return useCallback(() => {
        const { editableRecord, editableRecordItem, selectedPayment } = refsData.formStateRef.current;
        if (!productFormConfig.usePaymentBinding || selectedPayment) {
            return;
        }
        const { itemBrutto, itemCategoryCreditor, itemBelegfeld1 } = editableRecordItem;
        const { recordBrutto, recordCategoryCreditor, recordNum } = editableRecord;

        let allFilled = false;
        let hasSomethingChanged = false;
        if (product.productKey() === GQL.IProductKey.Fe) {
            allFilled =
                recordBrutto &&
                recordNum &&
                (recordCategoryCreditor instanceof Creditor || itemCategoryCreditor instanceof Creditor);
            hasSomethingChanged =
                refsData.savedItemsRef.current.brutto !== recordBrutto ||
                refsData.savedItemsRef.current.itemCreditorNum !== itemCategoryCreditor?.num ||
                refsData.savedItemsRef.current.recordCreditorNum !== recordCategoryCreditor?.num ||
                refsData.savedItemsRef.current.num !== recordNum;
        } else {
            allFilled = itemBrutto && itemBelegfeld1 && itemCategoryCreditor instanceof Creditor;
            hasSomethingChanged =
                refsData.savedItemsRef.current.brutto !== itemBrutto ||
                refsData.savedItemsRef.current.itemCreditorNum !== itemCategoryCreditor?.num ||
                refsData.savedItemsRef.current.num !== itemBelegfeld1;
        }

        if (allFilled && hasSomethingChanged) {
            const formPaymentRepresentationRecord = composeFormRecord();
            const matchedPaymentSourceRecords = PaymentBindingUtils.getMatchingPaymentSourceRecords({
                paymentRepresentationRecord: formPaymentRepresentationRecord,
                payments,
                recordsDeb,
                recordsER,
                recordsAZ,
            });
            if (matchedPaymentSourceRecords.matched && matchedPaymentSourceRecords.matched.length === 1) {
                const [matchedPaymentSourceRecord] = matchedPaymentSourceRecords.matched;
                // const isNumSame = this.props.productFormConfig.useItemBelegfeld1
                //     ? formPaymentRepresentationRecord.getBelegfeld1() === matchedPaymentSourceRecord.num
                //     : formPaymentRepresentationRecord.num === matchedPaymentSourceRecord.num;

                // with FE moving to useItemBelegfeld1, all representation records are now ItemBelegfeld1
                const isNumSame = formPaymentRepresentationRecord.getBelegfeld1() === matchedPaymentSourceRecord.num;
                if (isNumSame) {
                    const openBrutto = PaymentUtils.getOpenBruttoFromSourceForPaymentRepresentationRecord(
                        formPaymentRepresentationRecord,
                        product.productKey() as GQL.IProductKey,
                        matchedPaymentSourceRecord,
                        refsData.paymentsRecordRelationRef.current
                    );

                    if (openBrutto === itemBrutto) {
                        const { updatesRecord, updatesRecordItem } = getUpdatesOnPaymentConnection(
                            editableRecord,
                            editableRecordItem,
                            matchedPaymentSourceRecord
                        );
                        updateEditableRecord(updatesRecord);
                        updateEditableItem(updatesRecordItem);
                        setSelectedPayment({
                            sourceRecordKey: matchedPaymentSourceRecord.key,
                            skontoBetrag: 0,
                        });
                    } else {
                        refsHtml.REF_cBINDING.current.click();
                    }
                }
            }
        }

        refsData.savedItemsRef.current = {
            brutto: itemBrutto,
            itemCreditorNum: itemCategoryCreditor?.num,
            recordCreditorNum: recordCategoryCreditor?.num,
            num: itemBelegfeld1 || recordNum,
        };
    }, [
        refsHtml,
        refsData,
        productFormConfig.usePaymentBinding,
        product,
        composeFormRecord,
        payments,
        recordsDeb,
        recordsER,
        recordsAZ,
        getUpdatesOnPaymentConnection,
        updateEditableRecord,
        updateEditableItem,
        setSelectedPayment,
    ]);
};
