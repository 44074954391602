import React, { FC, useContext, useState } from "react";
import { BarsOutlined, HomeOutlined } from "@ant-design/icons";
import { Button, Drawer, Menu } from "antd";
import { FormattedMessage } from "react-intl";
import { ItemType } from "antd/es/menu/interface";
import { useLocation, useNavigate } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { AppSettingsContext } from "scripts/context/AppSettingsProvider";
import { CompanyContext } from "scripts/context/CompanyContext";
import { ReadinessContext } from "scripts/context/DataReadyness";
import { UserContext } from "scripts/context/UserProvider";
import { GQL } from "@binale-tech/shared";
import { GroupKey, ProductDetails, ProductGroups } from "scripts/models/Product";
import { ProductAccessUtils } from "scripts/models/utils/ProductAccessUtils";

const LinkWrapper: FC<{ link: string; title: React.ReactNode }> = ({ link, title }) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" onClick={e => e.preventDefault()}>
            {title}
        </a>
    );
};
export const AppsDrawer: React.FC = () => {
    const user = useContext(UserContext);
    const { isReady } = useContext(ReadinessContext);
    const { companyGQL, yearConfig } = useContext(CompanyContext);
    const { appError } = useContext(AppSettingsContext);

    const navigate = useNavigate();
    const { pathname = "" } = useLocation();
    const [open, setOpen] = useState(false);

    const generateUrl = (key: string) => location.origin + key;

    const getGroupListItems = (gk: GroupKey) => {
        return ProductDetails.getGroup(gk).map(d => {
            const disabled = !isReady || !ProductAccessUtils.canRead(d.pk, companyGQL, user);
            return {
                key: d.link,
                disabled,
                label: (
                    <LinkWrapper
                        link={generateUrl(d.link)}
                        title={
                            <>
                                {d.icon} <FormattedMessage id={`app.titles.${d.pk}`} tagName="span" />
                            </>
                        }
                    />
                ),
            };
        });
    };

    const menuItems: ItemType[] = [
        {
            key: AppRoutes.home,
            label: (
                <LinkWrapper
                    link={generateUrl(AppRoutes.home)}
                    title={
                        <>
                            <HomeOutlined />
                            <FormattedMessage id="app.header.workplace" tagName="span" />
                        </>
                    }
                />
            ),
        },
        {
            type: "divider",
        },
        ...getGroupListItems(ProductGroups.Buch),
        {
            type: "divider",
        },
        {
            key: AppRoutes.accountsView,
            label: (
                <LinkWrapper
                    link={generateUrl(AppRoutes.accountsView)}
                    title={<FormattedMessage id="app.titles.KA" />}
                />
            ),
        },
        {
            key: AppRoutes.SuSa,
            label: <LinkWrapper link={generateUrl(AppRoutes.SuSa)} title={<FormattedMessage id="app.titles.SuSa" />} />,
        },
        {
            key: AppRoutes.BWA,
            label: <LinkWrapper link={generateUrl(AppRoutes.BWA)} title={"BWA"} />,
        },
        yearConfig?.taxation !== GQL.ICompanyTaxation.Kust
            ? {
                  key: AppRoutes.UStVA + AppRoutes.UStVAOverview,
                  label: (
                      <LinkWrapper
                          link={generateUrl(AppRoutes.UStVA + AppRoutes.UStVAOverview)}
                          title={<FormattedMessage id="app.titles.Vat" />}
                      />
                  ),
              }
            : null,
        {
            key: AppRoutes.recordImportExport,
            label: (
                <LinkWrapper
                    link={generateUrl(AppRoutes.recordImportExport)}
                    title={<FormattedMessage id="app.titles.import_export" tagName="span" />}
                />
            ),
        },
        {
            key: AppRoutes.filtersAndSearchView,
            label: (
                <LinkWrapper
                    link={generateUrl(AppRoutes.filtersAndSearchView)}
                    title={<FormattedMessage id="app.titles.filtern" />}
                />
            ),
        },
        {
            key: AppRoutes.manageAccount,
            label: (
                <LinkWrapper
                    link={generateUrl(AppRoutes.manageAccount)}
                    title={<FormattedMessage id="app.titles.kontenverwaltung" />}
                />
            ),
        },
        {
            type: "divider",
        },
        ...getGroupListItems(ProductGroups.Contacts),
        ...getGroupListItems(ProductGroups.DMS),
        ...getGroupListItems(ProductGroups.Banks),
        ...getGroupListItems(ProductGroups.Invoices),
        ...getGroupListItems(ProductGroups.PDF),
    ].filter(Boolean) as ItemType[];

    const menu = (
        <Menu
            onClick={({ key }) => {
                setOpen(false);
                navigate(key.toString());
            }}
            selectedKeys={[pathname.split("/").slice(0, 2).join("/")]}
            mode="inline"
            items={menuItems}
        />
    );

    return (
        <>
            <Button
                shape="circle"
                className="Header--Button Header--Button__margin"
                icon={<BarsOutlined />}
                disabled={Boolean(appError) || !user.isAuthenticated}
                loading={user.isLoading}
                onClick={() => setOpen(true)}
            />
            <Drawer placement="left" open={open} onClose={() => setOpen(false)} closable={false}>
                {menu}
            </Drawer>
        </>
    );
};
