import { gray } from "@ant-design/colors";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import React, { type FC } from "react";
import { FormattedMessage } from "react-intl";
import { IDocumentEnriched } from "@dms/types";

import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { DmsTypeOptions } from "@dms/configs/constants";

const { getDocumentStatus } = DmsUtils;

interface IProps {
    fileData: IDocumentEnriched;
}

const { Text } = Typography;

export const HadBeenUploadedFileItem: FC<IProps> = ({ fileData }) => {
    const { fileName, fileUrl, type } = fileData;

    const finedTypeInfo = DmsTypeOptions.find(el => el.value === type);
    const status = getDocumentStatus(fileData);

    const fileInfo = () => {
        return (
            <div style={{ padding: "10px" }}>
                {finedTypeInfo && (
                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                        <Text style={{ color: gray[0] }} strong>
                            Type document:
                        </Text>
                        <Text style={{ color: gray[0] }} ellipsis>
                            {finedTypeInfo.translationKey ? (
                                <FormattedMessage id={finedTypeInfo.translationKey} />
                            ) : (
                                finedTypeInfo?.value
                            )}
                        </Text>
                    </div>
                )}

                <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                    <Text style={{ color: gray[0] }} strong>
                        Created by:
                    </Text>
                    <Text style={{ color: gray[0] }} ellipsis>
                        {fileData.createdBy?.email}
                    </Text>
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                    <Text style={{ color: gray[0] }} strong>
                        Created at:
                    </Text>
                    <Text style={{ color: gray[0] }}>
                        {fileData.createdAt} {fileData.updatedAt}
                    </Text>
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                    <Text style={{ color: gray[0] }} strong>
                        Status:
                    </Text>
                    <Text style={{ color: gray[0] }}>{status}</Text>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <a href={fileUrl} target="_blank" rel="noreferrer">
                    {fileName}
                </a>
                <Tooltip placement="leftBottom" title={fileInfo}>
                    <InfoCircleOutlined style={{ cursor: "pointer" }} />
                </Tooltip>
            </div>
        </div>
    );
};
