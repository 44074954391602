import React, { useMemo } from "react";
import { Col, Form, Input, Row, Typography } from "antd";
import { Contacts, GQL } from "@binale-tech/shared";
import { Controller, UseFieldArrayProps, useFormContext } from "react-hook-form";
import { FieldLabel } from "@ui-components/FieldLabel";
import { MinusOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

import { BaseInput } from "appearance/components/shared/form/baseComponents";
import { CommunicationIcons, getTypeName } from "./helpers";
import { RemoveButton } from "../RemoveButton";
import { removeEmpty } from "scripts/infrastructure/helpers";
import { useValidation } from "scripts/infrastructure/helpers/validation";

interface IProps {
    communicationIndex: number;
    contactPersonIndex?: number;
    onRemove: () => void;
}

export const CommunicationItem: React.FC<IProps> = ({ contactPersonIndex, communicationIndex, onRemove }) => {
    const intl = useIntl();
    const { register, watch, getValues } = useFormContext<Contacts.Contact>();
    const name: UseFieldArrayProps<Contacts.Contact>["name"] =
        contactPersonIndex === undefined ? "communications" : `contactPersons.${contactPersonIndex}.communications`;

    const communicationType = watch(`${name}.${communicationIndex}.type`);
    const validators = useValidation();

    const valueConfig = useMemo(() => {
        switch (communicationType) {
            case GQL.ICommunicationType.Email:
                return {
                    rules: { validate: validators.email },
                };
            case GQL.ICommunicationType.Telegram:
                return {
                    rules: { minLength: validators.minLength(5) },
                    pattern: "^[A-Za-z@0-9_]*$",
                };
            case GQL.ICommunicationType.Phone:
                return {
                    rules: { minLength: validators.minLength(9) },
                    pattern: "^[0-9+\\-() .]*$",
                };
        }
        return {};
    }, [communicationType, validators]);

    return (
        <Row data-testid={`communication-item-${communicationIndex}`}>
            <Typography.Text className="ContactForm__communicationLabel">
                {getTypeName(communicationType)}
            </Typography.Text>
            <Input {...register(`${name}.${communicationIndex}.type`)} hidden />
            <Col style={{ flexGrow: 1, flexBasis: 0, marginRight: 8 }}>
                <Controller
                    name={`${name}.${communicationIndex}.value`}
                    rules={valueConfig.rules}
                    shouldUnregister
                    render={({ field, fieldState }) => (
                        <FieldLabel style={{ marginBottom: 0 }} fieldError={fieldState.error}>
                            <BaseInput
                                prefix={
                                    <Typography.Text disabled>{CommunicationIcons[communicationType]}</Typography.Text>
                                }
                                data-testid="communication-input"
                                pattern={valueConfig.pattern}
                                {...field}
                            />
                        </FieldLabel>
                    )}
                />
            </Col>
            <Col style={{ flexGrow: 1, flexBasis: 0 }}>
                <Controller
                    name={`${name}.${communicationIndex}.comment`}
                    shouldUnregister
                    render={({ field }) => (
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Input
                                maxLength={35}
                                showCount
                                placeholder={intl.formatMessage({ id: "app.fields.comment" })}
                                data-testid="communication-comment"
                                {...field}
                            />
                        </Form.Item>
                    )}
                />
            </Col>
            <Col>
                <RemoveButton
                    buttonProps={{
                        shape: "circle",
                        size: "small",
                        icon: <MinusOutlined />,
                        style: { marginLeft: 8 },
                        tabIndex: -1,
                    }}
                    isItemEmpty={() => {
                        const type = getValues(`${name}.${communicationIndex}`);
                        const slimmed = removeEmpty(type);
                        return Object.keys(slimmed).length === 1;
                    }}
                    onRemove={onRemove}
                />
            </Col>
        </Row>
    );
};
