import { ApolloClient, gql } from "@apollo/client";
import { stringify } from "csv-stringify/browser/esm";
import dayjs from "dayjs";

import { GenericRecord } from "../../../../../scripts/models";
import { GenericRecordPeriodExport } from "../../../../../scripts/exporters/GenericRecordExport";
import { GQL, Periods, Utils } from "@binale-tech/shared";

import { messagesDe } from "../../../../../scripts/intl/translations/de";
import { DATEV_HEADER } from "../datevConversion/constants";
import { RecordsCtxData } from "../../../../../scripts/context/recordsContext/RecordsCtx";

import { IGenericItem } from "../../../../../scripts/models/Interfaces";

export const dmsDocuments = gql`
    query dmsDocuments($companyId: ID!, $ids: [ID!]!) {
        dmsDocuments(companyId: $companyId, ids: $ids) {
            key
            externalReference
        }
    }
`;

type ExportFile = {
    filename: string;
    csvString: string;
    pk: GQL.IProductKey;
};
type Config = {
    companyGQL: GQL.ICompany;
    yearConfig: GQL.ICompanyAccountingYear;
    periods: Set<number>;
    selectedProductKey: GQL.IProductKey | "";
    selectedGroup?: string;
    recordsCtx: RecordsCtxData;
};

const getPeriodStartEnd = (year: number, firstPeriod: number, lastPeriod: number) => {
    let periodStart = new Date(year, firstPeriod - 1, 1, 12);
    if (firstPeriod === Periods.Period.FirstDayOfYear) {
        periodStart = new Date(year, 0, 1, 12);
    } else if (firstPeriod === Periods.Period.LastDayOfYear) {
        periodStart = new Date(year, 11, 31, 12);
    }

    let periodEnd = new Date(year, lastPeriod, 1, -12);
    if (lastPeriod === Periods.Period.FirstDayOfYear) {
        periodEnd = new Date(year, 0, 1, 12);
    } else if (lastPeriod === Periods.Period.LastDayOfYear) {
        periodEnd = new Date(year, 12, 1, -12);
    }
    return { periodStart, periodEnd };
};

export class DatevGenerator {
    protected apolloClient: ApolloClient<any>;

    constructor(protected readonly config: Config) {}

    setApolloClient = (apolloClient: ApolloClient<any>) => {
        this.apolloClient = apolloClient;
        return this;
    };

    protected initialiseConfigLines = (pk: GQL.IProductKey, periodStart: Date, periodEnd: Date) => {
        const yearConfig = this.config.yearConfig;
        const kontoExt = yearConfig?.kontoExt ?? 0;
        const skr = yearConfig?.skr;
        const config = new Array(31).fill(undefined);
        const getName = () => {
            if (pk === GQL.IProductKey.Kb) {
                const kb = this.config.companyGQL.kasseList.find(
                    v => v.year === yearConfig.year && v.id === this.config.selectedGroup
                );
                if (kb) {
                    return kb.name;
                }
            }
            if (pk === GQL.IProductKey.Bank) {
                const bank = this.config.companyGQL.bankList.find(
                    v => v.year === yearConfig.year && v.id === this.config.selectedGroup
                );
                if (bank) {
                    return bank.name;
                }
            }
            return messagesDe[("app.titles." + pk) as keyof typeof messagesDe];
        };
        config[0] = "EXTF";
        config[1] = 700;
        config[2] = 21;
        config[3] = "Buchungsstapel";
        config[4] = 13;
        config[10] = this.config.companyGQL.datevNrConsultant;
        config[11] = this.config.companyGQL.datevNrCompany;
        config[12] = Number(dayjs(new Date(yearConfig.fiscalYearStart)).format("YYYYMMDD")); // fiscal year begins
        config[13] = Number(kontoExt + 4);
        config[14] = Number(dayjs(periodStart).format("YYYYMMDD"));
        config[15] = Number(dayjs(periodEnd).format("YYYYMMDD"));
        config[16] = [getName(), dayjs(periodStart).format("YYYY/MM")].join(" ");
        config[18] = 1;
        config[19] = 0;
        config[20] = 0; // this is festschreibung. Value in the header is a priority, if we specify 1 here, all record will be considered journaled
        config[21] = "EUR";
        config[26] = String(skr).padStart(2, "0");
        const lines: string[][] = [];
        lines.push(config);
        lines.push(DATEV_HEADER);
        return { lines, config };
    };
    protected getRecords = () => {
        switch (this.config.selectedProductKey) {
            case GQL.IProductKey.Er:
                return this.config.recordsCtx.recordsER.list;
            case GQL.IProductKey.ErA:
                return this.config.recordsCtx.recordsAZ.list;
            case GQL.IProductKey.Deb:
                return this.config.recordsCtx.recordsDeb.list;
            case GQL.IProductKey.La:
                return this.config.recordsCtx.recordsLA.list;
            case GQL.IProductKey.Fe:
                return this.config.recordsCtx.recordsFE.list;
            case GQL.IProductKey.Pos:
                return this.config.recordsCtx.recordsPOS.list;
            case GQL.IProductKey.Bank:
                return this.config.recordsCtx.recordsBank.groups.get(this.config.selectedGroup)?.list || [];
            case GQL.IProductKey.Kb:
                return this.config.recordsCtx.recordsKB.groups.get(this.config.selectedGroup)?.list || [];
            default:
                return this.config.recordsCtx.allRecords.list;
        }
    };
    getSelectedRecords = () => {
        return GenericRecordPeriodExport.getSelectedRecords({
            periods: this.config.periods,
            year: this.config.yearConfig.year,
            records: this.getRecords(),
        });
    };
    protected getSelectedMappedRecords = () => {
        const records = this.getSelectedRecords();
        const productMappedRecords = new Map<GQL.IProductKey, Record<string, GenericRecord[]>>();
        records.forEach(record => {
            const pk = record.getProductKey();
            if (!productMappedRecords.has(pk)) {
                productMappedRecords.set(pk, {});
            }
            const groupId = this.config.recordsCtx.allRecords.groupsReverseIndex.get(record.key) ?? "";
            if (!productMappedRecords.get(pk)[groupId]) {
                productMappedRecords.get(pk)[groupId] = [];
            }
            productMappedRecords.get(pk)[groupId].push(record);
        });
        return productMappedRecords;
    };
    protected getSortedPeriods = () => Array.from(this.config.periods).sort((a, b) => a - b);

    getExportFiles = async (useSeparatePeriodFiles?: boolean): Promise<ExportFile[]> => {
        const productMappedRecords = this.getSelectedMappedRecords();
        const files: ExportFile[] = [];
        for (const [pk, groupContainer] of Array.from(productMappedRecords.entries())) {
            for (const [groupId, records] of Object.entries(groupContainer)) {
                if (useSeparatePeriodFiles) {
                    const groupFiles = await this.getPeriodExport(records, pk, groupId ?? undefined);
                    files.push(...groupFiles);
                } else {
                    files.push(await this.generateLinesAndConfig(this.getSortedPeriods(), records, pk, groupId));
                }
            }
        }
        return files;
    };

    protected getPeriodExport = async (
        vs: GenericRecord[],
        pk: GQL.IProductKey,
        group?: string
    ): Promise<ExportFile[]> => {
        const files: ExportFile[] = [];
        for (const period of this.getSortedPeriods()) {
            const records = GenericRecordPeriodExport.getSelectedRecords({
                periods: new Set([period]),
                year: this.config.yearConfig.year,
                records: vs,
            });
            if (records.length) {
                files.push(await this.generateLinesAndConfig([period], records, pk, group));
            }
        }
        return files;
    };
    protected generateLinesAndConfig = async (
        periods: number[],
        records: GenericRecord[],
        pk: GQL.IProductKey,
        group?: string
    ): Promise<ExportFile> => {
        const [firstPeriod] = periods;
        const lastPeriod = periods[periods.length - 1];
        const { periodStart, periodEnd } = getPeriodStartEnd(this.config.yearConfig.year, firstPeriod, lastPeriod);
        const { lines } = this.initialiseConfigLines(pk, periodStart, periodEnd);
        const docIds = records.reduce((caret, v) => caret.concat(v.documents.map(d => d.id)), [] as string[]);
        const backendDocs =
            docIds.length && this.apolloClient
                ? await this.apolloClient
                      .query<Pick<GQL.IQuery, "dmsDocuments">, GQL.IQueryDmsDocumentsArgs>({
                          query: dmsDocuments,
                          fetchPolicy: "network-only",
                          variables: {
                              companyId: this.config.companyGQL.id,
                              ids: docIds,
                          },
                      })
                      .then(v => v.data.dmsDocuments)
                : [];
        records.forEach(record => {
            record.items.forEach(item => {
                const line = this.generateLine(record, item, pk, backendDocs);
                lines.push(line);
            });
        });
        const filename = this.generateFilename(pk, new Set(periods), group);
        const csvString = await this.generateCsvAsync(lines);
        return { csvString, filename, pk };
    };

    protected generateLine = (
        record: GenericRecord,
        item: IGenericItem,
        pk: GQL.IProductKey,
        docsList: GQL.IDocument[]
    ) => {
        const useBelegfeld1Split = Utils.ModuleUtils.useBelegfeld1Split(record.getProductKey());
        const kontoExt = this.config.yearConfig?.kontoExt ?? 0;
        const skr = this.config.yearConfig?.skr ?? 4;
        const line = new Array(DATEV_HEADER.length).fill(undefined);
        const hasCurrency = Boolean(record.currency);
        const itemBruttoStr = Utils.CurrencyUtils.currencyFormat(Math.abs(item.brutto)).replace(/\./gi, "");
        const singleItemRecord = GenericRecord.genegateSingleItemRecord(record, item);
        line[1] = Utils.SollHaben.isRecordKontoHaben(singleItemRecord.getBrutto(), pk) ? "H" : "S";
        if (!hasCurrency) {
            line[0] = itemBruttoStr;
        } else {
            line[0] = Utils.CurrencyUtils.currencyFormat(Math.abs(item.originalAmount)).replace(/\./gi, "");
            line[2] = record.currency.code;
            line[3] = record.currency.rate.toFixed(6).replace(".", ",");
            line[4] = itemBruttoStr;
        }
        line[6] = record.getRecordCategoryCreditor().getExtNum(kontoExt); // Konto // Record
        line[7] = item.getCategoryCreditor().getExtNum(kontoExt); // G.Konto // Item
        line[8] = item.getBu(record, skr, true) || undefined;
        line[9] = dayjs(record.date).format("DDMM");
        line[10] = useBelegfeld1Split ? item.belegfeld1 : record.num;
        line[11] = item.belegfeld2;
        line[13] = item.text;
        line[DATEV_HEADER.indexOf("Beleglink")] = record.documents?.length
            ? `BEDI "${docsList.find(v => v.key === record.documents[0].id).externalReference}"`
            : undefined;
        line[DATEV_HEADER.indexOf("KOST1 - Kostenstelle")] = item.tag?.num;
        line[DATEV_HEADER.indexOf("Sachverhalt L+L")] = item.USt13b; //42
        line[DATEV_HEADER.indexOf("Festschreibung")] = Number(Boolean(record.journaled)).toString(); //113
        line[DATEV_HEADER.indexOf("Generalumkehr (GU)")] = record.cancellation === "counterweight" ? "1" : "0"; //117
        return line;
    };

    generateFilename = (pk: GQL.IProductKey, periods: Set<number>, selectedGroup?: string) => {
        const sortedPeriods = Array.from(periods).sort((a, b) => a - b);
        const [firstPeriod] = sortedPeriods;
        const lastPeriod = sortedPeriods[sortedPeriods.length - 1];
        const { year } = this.config.yearConfig;
        const { periodStart, periodEnd } = getPeriodStartEnd(this.config.yearConfig.year, firstPeriod, lastPeriod);
        const { config: configLine } = this.initialiseConfigLines(pk, periodStart, periodEnd);
        const nameSegments = [
            configLine[0],
            dayjs(new Date()).format("YYYYMMDD"),
            configLine[3],
            messagesDe[("app.titles." + pk) as keyof typeof messagesDe],
        ];
        if (selectedGroup) {
            const configGroupAccount = [...this.config.companyGQL.bankList, ...this.config.companyGQL.kasseList].filter(
                v => v.id === selectedGroup && v.year === year
            );
            nameSegments.push(String(configGroupAccount[0].accountNum));
        }
        let periodSegment = `${year}_${String(firstPeriod).padStart(2, "0")}`;
        if (firstPeriod !== lastPeriod) {
            periodSegment += "-" + String(lastPeriod).padStart(2, "0");
        }
        nameSegments.push(periodSegment);
        return `${nameSegments.join("_").replaceAll(" ", "")}.csv`;
    };
    protected generateCsvAsync = (dataLines: any[]) => {
        return new Promise<string>((resolve, reject) => {
            stringify(dataLines, { delimiter: ";", quoted_string: true }, (e: Error, data: string) => {
                if (e) {
                    return reject(e);
                }
                resolve(data);
            });
        });
    };
}
