import React, { useRef, useState } from "react";
import { Button, ButtonProps, Popconfirm } from "antd";
import { FormattedMessage } from "react-intl";

interface RemoveButtonProps {
    onRemove: () => void;
    isItemEmpty: () => boolean;
    buttonProps?: ButtonProps;
}

export const RemoveButton: React.FC<RemoveButtonProps> = ({ onRemove, isItemEmpty, buttonProps }) => {
    const [showConfirmRemove, setShowConfirmRemove] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>();

    return (
        <Popconfirm
            title={<FormattedMessage id="app.global.confirmRemoveInfo" />}
            onConfirm={onRemove}
            placement="topRight"
            cancelButtonProps={{ autoFocus: true }}
            okText={<FormattedMessage id="app.button.confirm" />}
            cancelText={<FormattedMessage id="app.button.cancel" />}
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            overlayStyle={{ minWidth: 400 }}
            open={showConfirmRemove}
            data-testid="popconfirm"
            onCancel={() => {
                setShowConfirmRemove(false);
                buttonRef.current.focus();
            }}
            destroyTooltipOnHide
        >
            <Button
                danger
                data-testid="button-remove-item"
                ref={buttonRef}
                onClick={() => {
                    const isEmpty = isItemEmpty();
                    if (isEmpty) {
                        onRemove();
                    } else {
                        setShowConfirmRemove(true);
                    }
                }}
                {...buttonProps}
            >
                {buttonProps?.shape ? null : <FormattedMessage id="app.button.delete" />}
            </Button>
        </Popconfirm>
    );
};
