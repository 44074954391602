import { Currency, GQL } from "@binale-tech/shared";
import { Select } from "antd";
import React, { forwardRef, useEffect, useState } from "react";

type CurrencyInputBlockProps = {
    onChange?: (v: GQL.ICurrencyCode | undefined) => void;
    value?: GQL.ICurrencyCode;
    placeholder?: string;
    disabled?: boolean;
};

export const CurrencyCodeInput = forwardRef<any, CurrencyInputBlockProps>(function CurrencyInputBlock(
    { onChange, disabled, value, placeholder },
    ref
) {
    const [codeValue, setCodeValue] = useState<Partial<GQL.ICurrencyCode>>(value || GQL.ICurrencyCode.Eur);

    useEffect(() => {
        if (!onChange) {
            return;
        }
        onChange(codeValue);
    }, [codeValue]);

    const handleChange = (code?: GQL.ICurrencyCode) => {
        setCodeValue(code);
    };

    return (
        <Select
            ref={ref}
            value={codeValue}
            onChange={handleChange}
            onBlur={() => {}}
            options={Currency.CommonCurrencyList.map(code => ({
                label: code,
                value: code,
            }))}
            disabled={disabled}
            placeholder={placeholder}
        />
    );
});
