import React, { memo, useCallback, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Flex, Popconfirm, Tooltip } from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    EyeOutlined,
    VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { GridApi, ICellRendererParams, IRowNode } from "ag-grid-community";
import { InvoiceFormBlocksTranslate, InvoicesPaths, TInvoiceTableItem } from "@inv/types";
import { InvoicesApi } from "@inv/scripts/api";

import styles from "./actionList.module.scss";
import { InvoiceModalControlContext } from "@inv/context/InvoiceModalProvider";
import { InvoiceViewer } from "@inv/components/InvoiceViewer";
import { InvoicesListControlContext } from "@inv/context/InvoicesDataProvider";

export const ActionList = memo<ICellRendererParams<TInvoiceTableItem>>(({ api, data, node }) => {
    const intl = useIntl();
    const { openModal } = useContext(InvoiceModalControlContext);
    const { setExpandedRows } = useContext(InvoicesListControlContext);

    const toggleLineItems = useCallback((api: GridApi, data: TInvoiceTableItem, node: IRowNode) => {
        if (data?.lineItemsList?.length > 1 && !data.isChildRow) {
            const newRowData = data.lineItemsList.map((item, idx) => {
                const originalAmount = item.discount * item.quantity;
                const tax = item.tax / 100;
                const generalTax = originalAmount * tax;
                return {
                    ...data,
                    id: `${data.id}-${idx + 1}`,
                    parentId: data.id,
                    isChildRow: true,
                    serviceDescription: item.productsServices,
                    originalAmount,
                    generalTax,
                    generalTaxRate: item.tax.toString(),
                };
            });

            if (data.isExpanded) {
                node.setData({ ...data, isExpanded: false });
                api.applyTransaction({ remove: newRowData });
                setExpandedRows(prev => {
                    const newSet = new Set<string>(prev);
                    newSet.delete(data.id);
                    return newSet;
                });
            } else {
                node.setData({ ...data, isExpanded: true });
                api.applyTransaction({ add: newRowData });
                setExpandedRows(prev => new Set<string>(prev).add(data.id));
            }
        }
    }, []);

    if (node.rowPinned || node.data.isChildRow) {
        return null;
    }

    const editPath = `/${InvoicesPaths.INVOICES}/${InvoicesPaths.EDITING}/${node.data.id}`;

    const handleDeleteInvoice = async () => {
        await InvoicesApi.invoiceDelete(node.data.id);
    };

    const handleInvoiceView = () => {
        openModal({
            title: InvoiceFormBlocksTranslate.INVOICE_VIEWER,
            component: <InvoiceViewer invoiceId={node.data.id} />,
        });
    };

    const isBtnEnabled = data?.lineItemsList?.length > 1 && !data.isChildRow;

    return (
        <Flex
            className={styles.action}
            align="center"
            justify="space-between"
            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        >
            <Tooltip title={<FormattedMessage id="app.dms.view" />}>
                <Button
                    icon={<EyeOutlined />}
                    className={styles.actionsItem}
                    type={"text"}
                    onClick={handleInvoiceView}
                />
            </Tooltip>
            <Button
                className={styles.actionsItem}
                disabled={!isBtnEnabled}
                icon={<VerticalAlignMiddleOutlined />}
                type={"text"}
                onClick={() => toggleLineItems(api, data, node)}
            />
            <Tooltip title={<FormattedMessage id="app.button.send_to_dms" />}>
                <Button icon={<ExportOutlined />} className={styles.actionsItem} type="text" disabled={true} />
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.button.edit" />}>
                <Link to={editPath}>
                    <Button className={styles.actionsItem} type="text" icon={<EditOutlined />} />
                </Link>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.button.delete" />}>
                <Popconfirm
                    title={intl.formatMessage({ id: "app.confirmation.invoice_remove.body" })}
                    onConfirm={handleDeleteInvoice}
                    onCancel={null}
                    okText={intl.formatMessage({ id: "app.global.yes" })}
                    cancelText={intl.formatMessage({ id: "app.global.no" })}
                >
                    <Button icon={<DeleteOutlined />} className={styles.actionsItem} type="text" />
                </Popconfirm>
            </Tooltip>
        </Flex>
    );
});
