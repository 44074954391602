import React, { FC, memo, useContext } from "react";
import { InvoiceForm } from "@inv/components/InvoiceForm";
import { InvoiceFormContext, InvoiceFormProvider } from "./context/InvoiceFormProvider";

const Module: FC = () => {
    const { initialValues } = useContext(InvoiceFormContext);
    return <InvoiceForm initialValues={initialValues} />;
};

const CreateInvoiceModule: FC = () => {
    return (
        <InvoiceFormProvider>
            <Module />
        </InvoiceFormProvider>
    );
};
export default memo(CreateInvoiceModule);
