import React, { useMemo } from "react";
import { AutoComplete, AutoCompleteProps, Input, InputProps, RefSelectProps, Typography } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { DownOutlined } from "@ant-design/icons";

export interface ICountriesOption {
    value: string;
    name: string;
}

interface IProps extends AutoCompleteProps {
    optionsList: ICountriesOption[];
    onlyCountry?: boolean;
    inputProps?: InputProps;
}

export const CountriesCombobox = React.forwardRef<RefSelectProps, IProps>(function CountriesCombobox(
    { optionsList, onlyCountry = false, inputProps, value, ...props },
    ref
) {
    const dataValue: DefaultOptionType[] = optionsList.map(option => ({
        label: onlyCountry ? (
            <div>{option.name}</div>
        ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ marginRight: 16 }}>{option.value}</span>
                <Typography.Text
                    type="secondary"
                    style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                >
                    {option.name}
                </Typography.Text>
            </div>
        ),
        ...option,
    }));

    const filterFn = (inputValue: string, option: ICountriesOption | DefaultOptionType) => {
        if (onlyCountry) {
            return option.name.toString().toLowerCase().includes(inputValue.toLowerCase());
        }
        return (
            option.name.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
            option.value.toString().toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const handleOnBlur = () => {
        const selectedValue = optionsList.find(opt => opt.value === value);
        if (selectedValue || !value) {
            return;
        }
        const findValue = optionsList.filter(option => filterFn(value, option));
        const resultValue = findValue.length === 1 ? findValue[0].value.toUpperCase() : undefined;

        props.onChange(resultValue, null);
    };

    const selectedValue = useMemo(() => {
        if (!onlyCountry) {
            return value;
        }
        const foundValue = optionsList.find(item => item.value === value);
        return foundValue?.name || value;
    }, [optionsList, value, onlyCountry]);

    return (
        <AutoComplete
            getPopupContainer={trigger => trigger.parentNode}
            showSearch
            allowClear
            {...props}
            value={selectedValue}
            optionFilterProp="label"
            filterOption={(inputValue, option) => filterFn(inputValue, option)}
            onBlur={handleOnBlur}
            options={dataValue}
            ref={ref}
        >
            <Input suffix={<DownOutlined />} autoComplete="off" {...inputProps} />
        </AutoComplete>
    );
});
