import React from "react";
import { Base, GQL, Utils } from "@binale-tech/shared";
import { Button, Popover, Radio, Space, Table, TableColumnsType } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

import ProgramSettings from "scripts/models/ProgramSettings";
import classNames from "classnames";
import { BuTaxesSKR } from "scripts/models/BuTaxUtils";
import { BuTimeframe } from "scripts/context/BuContext";
import { GenericItem } from "scripts/models";
import { IGenericItem, ItemRecordContext } from "scripts/models/Interfaces";
import { ProductConfig } from "scripts/core/Product";
import { TAG_EMPTY } from "scripts/models/Tag";
import "./FormModalTable.css";

interface Props {
    onDelete: (i: number) => void;
    recordCurrency?: Base.CurrencyConfig;
    recordData: ItemRecordContext;
    items: IGenericItem[];
    onItemClick: (i: number) => void;
    yearConfig: GQL.ICompanyAccountingYear;
    programSettings: ProgramSettings;
    productFormConfig: ProductConfig;
    activeIdx?: number;
    buTimeframes: BuTimeframe[];
    showEuroBruttoWarning: { shouldAddCent: boolean; shouldSubtractCent: boolean };
    onShiftCent: (index: number, isPositive: boolean) => void;
}

export interface TableRecord {
    canDrop: boolean;
    canEdit: boolean;
    showActions?: boolean;
    key: number;
    nr?: number;
    item: IGenericItem;
}

export const FormModalTable: React.FC<Props> = props => {
    const kontoExt = props.yearConfig?.kontoExt || 0;
    const skr = props.yearConfig?.skr || 0;

    const dataSource: TableRecord[] = (props.items || []).map((item, idx) => ({
        key: idx,
        nr: idx + 1,
        item,
        canDrop: true,
        canEdit: true,
    }));

    const columns: TableColumnsType<TableRecord> = [
        {
            title: "#",
            dataIndex: "nr",
            key: "nr",
            width: 25,
        },
        props.productFormConfig.useItemBelegfeld1
            ? {
                  title: <FormattedMessage id="app.fields.rechnung_num" />,
                  key: "num",
                  width: 100,
                  render: (cell: string, record: TableRecord) => record.item.belegfeld1,
              }
            : null,
        props.recordCurrency
            ? {
                  title: (
                      <span>
                          <FormattedMessage id="app.fields.brutto" /> <br />
                          {props.recordCurrency.code}
                      </span>
                  ),
                  key: "originalAmount",
                  width: 80,
                  render: (cell: string, record: TableRecord) => (
                      <div style={{ textAlign: "right" }}>
                          {Utils.CurrencyUtils.currencyFormat(record.item.originalAmount)}
                      </div>
                  ),
              }
            : null,
        {
            title: (
                <span>
                    <FormattedMessage id="app.fields.brutto" />
                    <br />
                    EUR
                </span>
            ),
            key: "brutto",
            width: 95,
            render: (cell: string, record: TableRecord, index: number) => {
                const isWarning = props.showEuroBruttoWarning;
                const isFooter = index === props.items?.length;
                const isWarningFooter = isFooter && isWarning;
                const text = (
                    <div
                        className={classNames("FormModalTable__euroBrutto", {
                            "FormModalTable__euroBrutto--warning": isWarningFooter,
                        })}
                        style={{ textAlign: "right" }}
                    >
                        {Utils.CurrencyUtils.currencyFormat(record.item.brutto)}
                    </div>
                );
                const content = isWarning ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>{text}</div>
                        <Radio.Group size="small" style={{ display: "flex" }}>
                            <Radio.Button
                                value="-"
                                onClick={() => props.onShiftCent(index, false)}
                                disabled={props.showEuroBruttoWarning.shouldAddCent}
                            >
                                -
                            </Radio.Button>
                            <Radio.Button
                                value="+"
                                onClick={() => props.onShiftCent(index, true)}
                                disabled={props.showEuroBruttoWarning.shouldSubtractCent}
                            >
                                +
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                ) : (
                    text
                );
                return isWarningFooter ? (
                    <Popover
                        content={
                            <FormattedMessage
                                id="app.tooltip.form.correct"
                                values={{ amount: "0.01 ", currency: props.recordCurrency.code }}
                            />
                        }
                        open={true}
                        placement="left"
                    >
                        {text}
                    </Popover>
                ) : (
                    content
                );
            },
        },
        {
            title: <FormattedMessage id="app.fields.konto" />,
            key: "konto",
            width: 80,
            render: (cell: string, record: TableRecord) =>
                record.item.getCategoryCreditor().getExtNumPrint(kontoExt).replace(" ", `\xa0`),
        },
        {
            title: <FormattedMessage id="app.fields.bezeichnung" />,
            key: "name",
            width: 150,
            render: (cell: string, record: TableRecord) => record.item.getCategoryCreditor().name,
            ellipsis: true,
        },
        {
            title: <FormattedMessage id="app.fields.vat%" />,
            key: "vatp",
            width: 65,
            render: (cell: string, record: TableRecord) => {
                const buTax = BuTaxesSKR.getBuTaxYearPeriod(
                    record.item.bu,
                    skr,
                    props.recordData.year,
                    props.recordData.period,
                    props.buTimeframes
                );
                return buTax.text;
            },
        },
        {
            title: (
                <span>
                    <FormattedMessage id="app.fields.vat" /> <br /> EUR
                </span>
            ),
            key: "vat",
            width: 80,
            render: (cell: string, record: TableRecord) => (
                <div style={{ textAlign: "right" }}>
                    {Utils.CurrencyUtils.currencyFormat(record.item.getVatEuro(props.recordData, skr))}
                </div>
            ),
        },
        {
            title: (
                <span>
                    <FormattedMessage id="app.fields.netto" /> <br /> EUR
                </span>
            ),
            key: "netto",
            width: 90,
            render: (cell: string, record: TableRecord) => (
                <div style={{ textAlign: "right" }}>
                    {Utils.CurrencyUtils.currencyFormat(record.item.getNetto(props.recordData, skr))}
                </div>
            ),
        },
        props.programSettings.useKs
            ? {
                  title: "KS",
                  key: "tag",
                  width: 50,
                  render: (cell: string, record: TableRecord) => (record.item.tag || TAG_EMPTY).num,
                  ellipsis: true,
              }
            : null,
        !props.programSettings.hideBuchText
            ? {
                  title: <FormattedMessage id="app.fields.buchtext" />,
                  key: "buchtext",
                  render: (cell: string, record: TableRecord) => record.item.text,
                  ellipsis: true,
              }
            : null,
        props.programSettings.useBuchText2
            ? {
                  title: (
                      <span>
                          <FormattedMessage id="app.fields.buchtext2" />
                      </span>
                  ),
                  key: "buchtext2",
                  width: 150,
                  render: (cell: string, record: TableRecord) => record.item.text2,
                  ellipsis: true,
              }
            : null,
        props.programSettings.useBelegfeld2
            ? {
                  title: <FormattedMessage id="app.fields.internal_num" />,
                  key: "bf2",
                  width: 100,
                  render: (cell: string, record: TableRecord) => record.item.belegfeld2,
              }
            : null,
        {
            title: <FormattedMessage id="app.fields.actions" />,
            key: "actions",
            width: 90,
            render: (cell: string, record: TableRecord) => (
                <Space>
                    <Button
                        disabled={!record.canEdit || props.activeIdx === record.key}
                        hidden={record.showActions === false}
                        icon={<EditOutlined />}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            props.onItemClick(record.key);
                        }}
                    />
                    <Button
                        disabled={!record.canDrop || props.activeIdx === record.key}
                        hidden={record.showActions === false}
                        icon={<DeleteOutlined />}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            props.onDelete(record.key);
                        }}
                    />
                </Space>
            ),
        },
    ].filter(Boolean);

    const items = props.items || [];
    const summaryRow: TableRecord = {
        key: items.length,
        item: new GenericItem({
            brutto: props.items.reduce((sum, item) => sum + item.brutto, 0),
            originalAmount: props.items.reduce((sum, item) => sum + item.originalAmount, 0),
            getVatEuro: () => props.items.reduce((sum, item) => sum + item.getVatEuro(props.recordData, skr), 0),
            getNetto: () => props.items.reduce((sum, item) => sum + item.getNetto(props.recordData, skr), 0),
        }),
        canDrop: false,
        canEdit: false,
        showActions: false,
    };

    return (
        <Table<TableRecord>
            dataSource={dataSource}
            columns={columns}
            rowClassName={(record, idx) => (props.activeIdx === idx ? "Table__Row--active" : undefined)}
            size="small"
            pagination={{ pageSize: 100, hideOnSinglePage: true }}
            bordered
            className="FormModalTable"
            summary={() => {
                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            {columns.map(({ render }, index) => {
                                return (
                                    <Table.Summary.Cell index={index} key={index}>
                                        {render ? (render("", summaryRow, dataSource.length) as React.ReactNode) : null}
                                    </Table.Summary.Cell>
                                );
                            })}
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
        />
    );
};
