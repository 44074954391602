import { useContext, useMemo } from "react";
import { DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { DmsTableColumns } from "@dms/modules/DocumentTableModule/DmsTableColumns";
import { AppConfigUtils } from "@dms/scripts/utils/AppConfigUtils";

import { ALL_DOCUMENTS, DmsTypeOptions } from "@dms/configs/constants";
import { DmsType, tableModeEnum } from "@dms/types";

export const useDocumentTableConfig = (mode: tableModeEnum, activeType: string[]) => {
    const { userConfig } = useContext(DmsUserSettingsContext);

    const { type } = DmsUtils.getActiveTypeAndSubType(activeType);

    const modalConfig = useMemo(() => {
        switch (type) {
            case DmsType.new_documents:
                return "newDocumentsTableColumns";
            case ALL_DOCUMENTS:
                return "allDocumentsTableColumns";
        }
        return "defaultDocumentsTableColumns";
    }, [type]);

    const columnsTableConfig = useMemo(() => {
        if (mode === tableModeEnum.modal) {
            const isParentType = DmsTypeOptions.find(t => {
                return t.value === activeType.toString() && t.isGroup;
            });

            if (isParentType) {
                return DmsUtils.getColumnsTableConfig(userConfig.columnsTableConfig, [
                    `${activeType[0]}-all_subTypes`,
                    ...activeType,
                ]);
            }
        }

        return DmsUtils.getColumnsTableConfig(userConfig.columnsTableConfig, activeType);
    }, [mode, userConfig.columnsTableConfig, activeType]);

    const tableColsConfig = useMemo(() => {
        if (mode === tableModeEnum.modal) {
            const defaultConfig = AppConfigUtils.getInitColumns(type);
            const activeKeys = DmsUtils.getColKeys(defaultConfig);
            return DmsTableColumns.getColumns(mode, activeKeys, modalConfig).filter(Boolean);
        }

        if (!columnsTableConfig) {
            return [];
        }

        const activeKeys = DmsUtils.getKeysFromObjects(columnsTableConfig);
        return DmsTableColumns.getColumns(mode, activeKeys).filter(Boolean);
    }, [mode, columnsTableConfig, type, modalConfig]);

    return useMemo(() => {
        return {
            columnsConfig: columnsTableConfig,
            columns: tableColsConfig,
        };
    }, [columnsTableConfig, tableColsConfig]);
};
