import React, { FC, useContext } from "react";
import { Droppable } from "react-beautiful-dnd";

import { PdfToolsContext } from "../../context";
import { PageItem } from "./components/PageItem";
import { DroppableIds } from "../../types";

const InnerPagesList: FC = () => {
    const { viewDocuments, activePageId } = useContext(PdfToolsContext);

    let globalIndex = 0;

    return viewDocuments.map((item, docIdx) => (
        <div className="pdf-tools-SegmentFrame" style={{ marginTop: docIdx === 0 ? 0 : "10px" }} key={item.id}>
            {item.pages.map((page, pageIdx) => {
                globalIndex++;
                if (!page) {
                    return null;
                }
                return (
                    <PageItem
                        key={page.id}
                        page={page}
                        pageNumber={globalIndex}
                        docNumber={pageIdx === 0 ? docIdx + 1 : undefined}
                        active={activePageId === page.id}
                        scrollBtn={item.pages.length > 1}
                        splitBtn={pageIdx !== 0}
                        unifyBtn={docIdx !== 0 && pageIdx === 0}
                        topBordered={pageIdx !== 0}
                    />
                );
            })}
        </div>
    ));
};

export const ScrollAllPages: FC = () => {
    return (
        <Droppable droppableId={DroppableIds.AllPages}>
            {provided => {
                return (
                    <div
                        className="pdf-tools-ScrollBlock__Content"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <InnerPagesList />
                        {provided.placeholder}
                    </div>
                );
            }}
        </Droppable>
    );
};
