import { MouseEventHandler, MutableRefObject, useCallback, useEffect, useRef } from "react";

export const useMouseEvents = (containerRef: MutableRefObject<HTMLDivElement>, zoom: number) => {
    const isMouseDownInsideContainerRef = useRef(false);
    const lastMousePositionRef = useRef({ x: 0, y: 0 });

    const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
        event => {
            if (containerRef.current && containerRef.current.contains(event.currentTarget) && zoom > 1) {
                isMouseDownInsideContainerRef.current = true;
                lastMousePositionRef.current = { x: event.clientX, y: event.clientY };
                containerRef.current.style.cursor = "grabbing";
            }
        },
        [containerRef, zoom]
    );

    const handleMouseMove = useCallback(
        (event: MouseEvent) => {
            if (isMouseDownInsideContainerRef.current && lastMousePositionRef.current && zoom > 1) {
                const deltaX = event.clientX - lastMousePositionRef.current.x;
                const deltaY = event.clientY - lastMousePositionRef.current.y;
                containerRef.current.scrollLeft -= deltaX;
                containerRef.current.scrollTop -= deltaY;
                lastMousePositionRef.current = { x: event.clientX, y: event.clientY };
            }
        },
        [containerRef, zoom]
    );

    const handleMouseUp = useCallback(() => {
        isMouseDownInsideContainerRef.current = false;
        lastMousePositionRef.current = null;
        containerRef.current.style.cursor = "grab";
    }, [containerRef]);

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [containerRef, zoom]);

    return handleMouseDown;
};
