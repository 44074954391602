import React, { type FC, useContext } from "react";
import { FileUploaderContext } from "../../context/FileUploaderContext";
import { HadBeenUploadedFileItem } from "./HadBeenUploadedFileItem";
import { Typography } from "antd";
import { DmsDataContext } from "@dms/types/ContextTypes";

export const HadUploadedFiles: FC = () => {
    const { allFilesInfo } = useContext(FileUploaderContext);
    const { documentsKV } = useContext(DmsDataContext);

    if (!allFilesInfo.length) {
        return null;
    }

    const hadBeenUploadedFiles = allFilesInfo.filter(el => el.status === "alreadyUploaded");

    if (!hadBeenUploadedFiles.length) {
        return null;
    }

    return (
        <div style={{ marginBottom: "20px" }}>
            <Typography.Text type={"secondary"}>
                {hadBeenUploadedFiles.length} files were uploaded earlier
            </Typography.Text>
            {hadBeenUploadedFiles.map((el, i) => {
                return (
                    <div key={el.hash + i}>
                        <HadBeenUploadedFileItem fileData={documentsKV[el.hash]} />
                    </div>
                );
            })}
        </div>
    );
};
