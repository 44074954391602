import React, { ReactNode, useContext } from "react";
import { CompanyContext } from "scripts/context/CompanyContext";
import { Base, Utils } from "@binale-tech/shared";
import { ColumnsType } from "antd/es/table";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { ScrollbarDetection } from "../../../../../../scripts/infrastructure/helpers/browser";

export interface BWATableRecord {
    key: number;
    group?: ReactNode;
    subGroup?: ReactNode;
    konto?: Base.IExtNum;
    monthAmounts: Map<number, number>;
}
const formatNumber = (tableItem: BWATableRecord, amount: number, showZeroes?: boolean): string => {
    const formatted = Utils.CurrencyUtils.currencyFormat(amount);
    if (showZeroes) {
        return formatted;
    }
    if (tableItem.konto) {
        return amount !== 0 ? formatted : null;
    }
    return formatted;
};

const getterPeriod = (tableItem: BWATableRecord, month: number) => {
    const amount = tableItem.monthAmounts.get(month);
    return formatNumber(tableItem, amount);
};

/**
 *
 * @param tableItem
 * @param q 1,2,3,4
 */
const getterQuart = (tableItem: BWATableRecord, q: number) => {
    const amount = Array.from(tableItem.monthAmounts.values())
        .slice((q - 1) * 3, q * 3)
        .reduce((acc, cur) => acc + cur, 0);
    return formatNumber(tableItem, amount);
};

const getterYear = (tableItem: BWATableRecord) => {
    const amount = Array.from(tableItem.monthAmounts.values()).reduce((acc, cur) => acc + cur, 0);
    return formatNumber(tableItem, amount, true);
};

const getterBezeichnung = (tableItem: BWATableRecord) => tableItem.konto.name;

export const useBWAColumns = (period: number | null) => {
    const { yearConfig } = useContext(CompanyContext);
    const columns: ColumnsType<BWATableRecord> = React.useMemo(() => {
        const resultColumns: ColumnsType<BWATableRecord> = [
            {
                key: "category.num",
                title: <FormattedMessage id="app.fields.konto" />,
                width: 300,
                fixed: "left",
                render: (text: string, tableItem: BWATableRecord) => {
                    if (tableItem.konto) {
                        return tableItem.konto.getExtNumPrint(yearConfig.kontoExt);
                    }
                    if (tableItem.subGroup) {
                        return tableItem.subGroup;
                    }
                    if (tableItem.group) {
                        return tableItem.group;
                    }
                },

                onCell: data => {
                    if (data.group) {
                        return { align: "left" };
                    }
                    if (data.subGroup) {
                        return { align: "left", style: { paddingLeft: 50, whiteSpace: "break-spaces" } };
                    }
                    return { align: "right" };
                },
            },
            {
                key: "category.name",
                title: <FormattedMessage id="app.fields.bezeichnung" />,
                render: (text: string, tableItem: BWATableRecord) =>
                    tableItem.konto ? (
                        <Tooltip placement="right" title={getterBezeichnung(tableItem)} destroyTooltipOnHide>
                            <div style={{ width: 280 }}>{getterBezeichnung(tableItem)}</div>
                        </Tooltip>
                    ) : null,
                width: 290,
                fixed: "left",
                ellipsis: true,
            },
        ];
        if (Number.isFinite(period)) {
            resultColumns.push({
                title: period > 40 ? `Quartal ${period - 40}` : dayjs.months()[period - 1],
                key: "month." + period,
                render: (text: string, tableItem: BWATableRecord) => (
                    <span>
                        {period > 40 ? getterQuart(tableItem, period - 40) : getterPeriod(tableItem, period - 1)}
                    </span>
                ),
                width: 100,
                align: "right",
            });
        } else {
            dayjs.months().forEach((name, month) => {
                resultColumns.push({
                    title: name,
                    key: "month." + month,
                    render: (text: string, tableItem: BWATableRecord) => <span>{getterPeriod(tableItem, month)}</span>,
                    width: 100,
                    align: "right",
                });
            });
        }

        resultColumns.push({
            key: "sum.j",
            title: (
                <span>
                    <FormattedMessage id="app.fields.date.year" />
                </span>
            ),
            className: "BWATable--filled-column",
            render: (text: string, tableItem: BWATableRecord) => (
                <span
                    style={{
                        paddingRight: ScrollbarDetection.getWidth(),
                    }}
                >
                    {getterYear(tableItem)}
                </span>
            ),
            width: 125 + ScrollbarDetection.getWidth(),
            align: "right",
        });

        return resultColumns;
    }, [period, yearConfig]);
    return columns;
};
