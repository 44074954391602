import React, { FC, useEffect, useState } from "react";
import { Button, message } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { sendEmailVerification } from "firebase/auth";

import { AuthLayout } from "./AuthLayout";
import { auth } from "scripts/api/firebase/firebase";
import { getFirebaseErrorMessageByError } from "scripts/api/firebase/firebaseErrorMessages";

const addZeros = (value: number) => (value > 9 ? value.toString() : `0${value}`);

const getInitialTime = () => {
    const lStime = localStorage.getItem("confirmAgainAfter");
    if (lStime) {
        const timeLeft = Math.floor((+lStime - new Date().getTime()) / 1000);
        if (timeLeft > 0) {
            return timeLeft;
        }
        localStorage.removeItem("confirmAgainAfter");
    }
    return 0;
};

export const NotConfirmed: FC = () => {
    const [sending, setSending] = useState(false);
    const timeLeft = getInitialTime();
    const [timer, setTimer] = useState<number>(timeLeft);
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        } else {
            localStorage.removeItem("confirmAgainAfter");
        }
    }, [timer]);

    const handleSendEmailConfirmation = async () => {
        try {
            setSending(true);
            await sendEmailVerification(auth.currentUser);
            setTimer(59);
            localStorage.setItem("confirmAgainAfter", (new Date().getTime() + 59000).toString());
            message.info(formatMessage({ id: "app.registration.message.send_verification_email" }));
        } catch (error) {
            message.error(formatMessage({ id: getFirebaseErrorMessageByError(error) }));
        }
        setSending(false);
    };

    return (
        <AuthLayout title={<FormattedMessage id="app.registration.almost_done" />}>
            <FormattedMessage id="app.registration.almost_done_description" tagName="p" />

            {timer > 0 && (
                <p>
                    <FormattedMessage id="app.registration.resend_again_in" /> 00:{addZeros(timer)}
                </p>
            )}

            <Button
                type="primary"
                size="large"
                block
                onClick={handleSendEmailConfirmation}
                disabled={sending || timer > 0}
            >
                <FormattedMessage id="app.registration.send_again" />
            </Button>
        </AuthLayout>
    );
};
