import Category from "./Category";
import Creditor, { Debitor } from "./Creditor";
import Tag from "./Tag";
import { IGenericItem } from "./Interfaces";

export class UnserializeItemMixin {
    static preUnserialize(v: Partial<IGenericItem>) {
        v.belegfeld1 = v.belegfeld1 || "";
        v.belegfeld2 = v.belegfeld2 || "";
        v.brutto = typeof v.brutto === "string" ? parseInt(v.brutto, 10) : v.brutto;
        v.brutto = v.brutto || 0;
        if (v.category) {
            v.category = Category.unserialize(v.category);
        }
        if (v.creditor) {
            v.creditor = Creditor.unserialize(v.creditor);
        }
        if (v.debetor) {
            v.debetor = Debitor.unserialize(v.debetor);
        }
        if (v.tag) {
            v.tag = Tag.unserialize(v.tag);
        }
        return v;
    }
}
