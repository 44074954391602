import React, { FC, useContext } from "react";
import { BruttoInputBlock } from "../../shared/form/FormBlocks";
import { FormattedMessage } from "react-intl";
import { focusNextFieldFromRef } from "../../../../scripts/infrastructure/helpers/focus";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useProgramSettings } from "../hooks/useProgramSettings";
import { useCheckAutoPaymentConnection } from "../hooks/useCheckAutoPaymentConnection";
import { useFormHandlerBruttoTotal } from "../hooks/handlers/useFormHandlerBruttoTotal";

export const FormTotalBrutto: FC = () => {
    const isDisabled = useFormIsRefDisabled();
    const programSettings = useProgramSettings();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { recordDate, editableRecord, recordValidationStates } = useContext(RecordFormStateContext);
    const onBruttoTotalChange = useFormHandlerBruttoTotal();
    const checkAutoBinding = useCheckAutoPaymentConnection();

    const validation = recordValidationStates.get(refsHtml.REF_rBRUTTO);
    const { recordBrutto, recordCurrency, recordOriginalAmount } = editableRecord;
    return (
        <BruttoInputBlock
            labelProps={{
                label: <FormattedMessage id="app.fields.betrag" />,
                fieldError: validation ? { type: "error", message: "" } : undefined,
            }}
            ref={refsHtml.REF_rBRUTTO}
            value={recordBrutto}
            currency={recordCurrency}
            originalAmount={recordOriginalAmount}
            onChange={onBruttoTotalChange}
            withCurrencyControl={Boolean(recordCurrency) || programSettings.useCurrency}
            disabled={isDisabled(refsHtml.REF_rBRUTTO)}
            focusNextElement={() => focusNextFieldFromRef(refsHtml.REF_rBRUTTO)}
            onBlur={checkAutoBinding}
            recordDate={recordDate?.date}
        />
    );
};
