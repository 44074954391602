import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { type FC, useContext } from "react";
import { FormattedMessage } from "react-intl";

import { DmsAppContext, DmsAppControlContext } from "@dms/types/ContextTypes";
import styles from "./SidebarMenuCreateSubTypeBtn.module.scss";
import { SubTypeActions } from "@dms/types";

interface IProps {
    typeKeyPath: string[];
}

export const SidebarMenuCreateSubTypeBtn: FC<IProps> = ({ typeKeyPath }) => {
    const { isTypeChangeMode, isDraggingRightNow } = useContext(DmsAppContext);
    const { editTypeModalDataAction } = useContext(DmsAppControlContext);

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        if (isTypeChangeMode) {
            return;
        }

        editTypeModalDataAction({
            isOpen: true,
            typeKeyPath,
            action: SubTypeActions.createSubType,
        });
    };

    return (
        <Button
            type={"text"}
            size={"large"}
            onClick={e => handleClick(e)}
            disabled={isTypeChangeMode || isDraggingRightNow}
            style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, fontSize: "14px" }}
        >
            <div className={styles.btnInner}>
                <PlusOutlined size={14} />
                <span>
                    <FormattedMessage id="app.dms.add_subtype" />
                </span>
            </div>
        </Button>
    );
};
