import React, { FC, useContext } from "react";
import { Typography } from "antd";
import { PreviewParseDataContext } from "@banks/modules/ParseTransactionModule/context/PreviewParseDataProvider";

const { Title } = Typography;

export const SelectBankTitle: FC = () => {
    const { bankTitleValue } = useContext(PreviewParseDataContext);
    return (
        <Title style={{ marginBottom: 0, width: "60vw", flexGrow: 1 }} level={5} ellipsis>
            {bankTitleValue ?? "Bank not defined. Select Bank or match IBAN field"}
        </Title>
    );
};
