import GenericTableViewCtxWrapper from "../../productSharedComponents/GenericTableViewCtxWrapper";
import React, { memo } from "react";
import { RechnungenViewClass } from "./ERView";
import { ERAnzProvider } from "./ERAnzProvider";

class AnzahlungenViewClass extends RechnungenViewClass {
    protected getHeader(): React.ReactNode {
        return null;
    }

    render(): React.ReactNode {
        return this.getFormPageWithoutLeftTable();
    }
}

export const ERAnzView = memo(function AnzahlungenView() {
    return (
        <ERAnzProvider>
            <GenericTableViewCtxWrapper Component={AnzahlungenViewClass} />
        </ERAnzProvider>
    );
});
