import React, { FC } from "react";
import { getIBANcountryCode, InputIBANLabel, InputIbanToBanks } from "@app/components/shared/form/baseComponents";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { useApolloClient } from "@apollo/client";
import { validation } from "../../../scripts/infrastructure/helpers/validation";
import styles from "./FormFields.module.scss";

// TODO temporary solution
import { getCountrySpecifications } from "@banks/scripts/ibantools";

const feedbackIcons = () => {
    let isValidated = false;
    return ({ status }: { status: "" | "success" | "warning" | "error" | "validating" }) => {
        if (status === "validating") {
            isValidated = true;
            return {};
        }
        if (!isValidated) {
            return { success: <span></span> };
        }
        isValidated = false;
        return {};
    };
};

type TProps = {
    value: string;
};

export const IbanField: FC<TProps> = ({ value }) => {
    const client = useApolloClient();
    const countryCode = getIBANcountryCode(value);
    const countrySpec = getCountrySpecifications()[countryCode];
    const maxLength = countrySpec?.chars;

    return (
        <FieldLabel
            className={styles.bankFormField}
            label={<InputIBANLabel countryCode={countryCode} />}
            name={"iban"}
            rules={[validation.iban(client)]}
            validateTrigger={["onBlur"]}
            hasFeedback={{
                icons: feedbackIcons(),
            }}
        >
            <InputIbanToBanks maxLength={maxLength} showCount></InputIbanToBanks>
        </FieldLabel>
    );
};
