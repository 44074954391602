import React from "react";
import { deepEqual } from "fast-equals";
import { Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";

import Category from "scripts/models/Category";
import Payment from "scripts/models/Payment";
import { BuContext } from "scripts/context/BuContext";
import { BuTaxesSKR } from "scripts/models/BuTaxUtils";
import { CompanyContext } from "scripts/context/CompanyContext";
import { GenericRecord } from "scripts/models/GenericRecord";
import { Layout } from "../../../components/shared/Layout";
import { ZahlungForm } from "./ZahlungForm";
import { ZahlungenTable } from "./ZahlungenTable";
import { logger } from "scripts/infrastructure/logger";
import "./ZahlungenModal.css";

interface Props {
    onHide: () => void;
    onPaymentsSave: (payments: Payment[], defaultCategories: Map<number, Category>) => void;
    payments: Payment[];
    show: boolean;
    record: GenericRecord;
    disabledSave: boolean;
    disabledDelete: boolean;
    defaultCategories: Map<number, Category>;
}

interface State {
    payments: Payment[];
    activeIdx?: number;
}

class ZahlungenModalInternal extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            payments: props.payments || [],
        };
    }
    componentDidUpdate(prevProps: Readonly<Props>) {
        if (!prevProps.show && this.props.show) {
            this.setState({ payments: this.props.payments || [] });
        }
    }

    private getModalBody() {
        let openBrutto = this.props.record ? this.props.record.getOpenBrutto(this.state.payments) : 0;
        const activePayment = Number.isFinite(this.state.activeIdx) ? this.state.payments[this.state.activeIdx] : null;
        if (activePayment) {
            openBrutto += activePayment.getSum();
        }
        const statePaymentKeys = new Set((this.state.payments || []).map(({ key }) => key).filter(Boolean));
        const deletedPaymentKeys = (this.props.payments || [])
            .map(({ key }) => key)
            .filter(key => !statePaymentKeys.has(key));
        return (
            <Layout direction="row" spaceBetween={20}>
                <ZahlungForm
                    record={this.props.record}
                    openBetrag={openBrutto}
                    disabled={this.props.disabledSave}
                    payment={activePayment}
                    deletedPaymentKeys={deletedPaymentKeys}
                    onSubmit={this.handleAddMany}
                    onClear={this.handleClear}
                />
                <ZahlungenTable
                    onDelete={this.handleDelete}
                    disabledDelete={this.props.disabledDelete}
                    disabledSave={this.props.disabledSave}
                    onEdit={this.handleEdit}
                    activeIdx={this.state.activeIdx}
                    payments={this.state.payments}
                />
            </Layout>
        );
    }

    handleAddMany = (ps: Payment[]) => {
        const payments = [...this.state.payments];
        const newPayments: Payment[] = [];
        if (Number.isFinite(this.state.activeIdx)) {
            if (ps.length !== 1) {
                throw new Error("you can edit only one payment at once");
            }
            payments[this.state.activeIdx] = ps[0];
        } else {
            // newPayments = differenceWith(ps, payments, deepEqual);
            ps.forEach(payment => {
                const exists = payments.find(v => deepEqual(v, payment));
                if (!exists) {
                    newPayments.push(payment);
                }
            });
        }
        this.updatePayments([...payments, ...newPayments]);
    };

    handleClear = () => {
        this.updatePayments([...this.state.payments]);
    };

    handleDelete = (paymentIdx: number) => {
        const payments = this.state.payments.filter((v, idx) => idx !== paymentIdx);
        this.updatePayments(payments);
    };

    protected updatePayments(payments: Payment[]) {
        this.setState({
            payments,
            activeIdx: null,
        });
    }

    handleEdit = (paymentIdx: number) => {
        this.setState({
            activeIdx: paymentIdx,
        });
    };

    handleSave = () => {
        logger.log(this.state.payments);
        this.props.onPaymentsSave(this.state.payments, this.props.defaultCategories);
        this.props.onHide();
    };

    handleHide = () => {
        this.handleClear();
        this.props.onHide();
    };

    render() {
        return (
            <Modal
                open={this.props.show}
                onCancel={this.handleHide}
                className="ZahlungenModal"
                width={900}
                style={{ top: 20 }}
                destroyOnClose
                title={<FormattedMessage id="app.titles.payments" />}
                footer={
                    <>
                        <Button type="primary" onClick={this.handleSave} disabled={this.props.disabledSave}>
                            <FormattedMessage id="app.button.save" />
                        </Button>
                        <Button onClick={this.handleHide}>
                            <FormattedMessage id="app.button.cancel" />
                        </Button>
                        ,
                    </>
                }
            >
                {this.getModalBody()}
            </Modal>
        );
    }
}
const defaultMap = new Map();
export const ZahlungenModal: React.FC<Omit<Props, "defaultCategories">> = props => {
    const { onHide } = props;
    const { companyBuTimeframes } = React.useContext(BuContext);
    const { companyGQL, yearConfig } = React.useContext(CompanyContext);
    React.useEffect(() => onHide(), [companyGQL?.id, onHide]);
    let dc = defaultMap;
    if (props.show) {
        const buTimeframe = BuTaxesSKR.getBuTimeframeYearPeriod(
            yearConfig?.skr,
            companyBuTimeframes,
            props.record.year,
            props.record.period
        );
        dc = buTimeframe?.defaultCategories || defaultMap;
    }
    return <ZahlungenModalInternal {...props} defaultCategories={dc} />;
};
