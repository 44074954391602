import React, { useContext, useMemo } from "react";
import dayjs from "dayjs";
import { ColumnGroupType, ColumnType } from "antd/es/table";
import { FormattedMessage, useIntl } from "react-intl";
import { Periods, Utils } from "@binale-tech/shared";

import tableStyles from "./VatTable.module.css";
import { BruttoInput } from "@ui-components/AmountInput/BruttoInput";
import { SelectorPeriods } from "../../../../components/shared/Toolbar/PeriodSelect";
import { VATControlContext } from "./VatContext";
import { YearPeriodContext } from "scripts/context/CompanyContext";
import { IVatRowData, RowSegment } from "./types";

const { Period, ExtraPeriod } = Periods;

export const titleColumns: (Omit<ColumnType<IVatRowData>, "render"> & {
    render: (value: IVatRowData) => React.ReactNode;
})[] = [
    {
        title: "Kurzbeschreibung",
        width: 300,
        ellipsis: true,
        fixed: "left",
        render: v => v.title,
    },
    {
        title: "BMG",
        width: 50,
        fixed: "left",
        render: v => v.titleBmg,
    },
    {
        title: "St.",
        width: 50,
        fixed: "left",
        render: v => v.titleSt,
    },
];

export const useColumns = (period: number): (ColumnGroupType<IVatRowData> & { title: React.ReactNode })[] => {
    const { year } = useContext(YearPeriodContext);
    const intl = useIntl();

    const getPeriodName = React.useCallback((periodNum: SelectorPeriods) => {
        switch (periodNum) {
            case Period.FirstDayOfYear:
                return "01.01.";
            case Period.LastDayOfYear:
                return "31.12.";
            case ExtraPeriod.AllYear: // All Year
                return <FormattedMessage id={"app.labels.allYear"} />;
            default:
                return dayjs.months()[periodNum - 1];
        }
    }, []);

    const renderBmg = (data: IVatRowData, segment: RowSegment) => {
        if (!data.titleBmg) {
            return null;
        }
        return <>{Utils.CurrencyUtils.currencyFormat(segment.bmg)}</>;
    };
    const renderSt = (data: IVatRowData, segment: RowSegment, onChange?: (v: number) => void) => {
        if (!data.titleSt) {
            return null;
        }
        if (onChange && !data.titleSt.startsWith("X")) {
            return (
                <BruttoInput key={data.key + ":input"} value={segment.st} onChange={({ amount }) => onChange(amount)} />
            );
        }
        return <>{Utils.CurrencyUtils.currencyFormat(segment.st)}</>;
    };

    const result: (ColumnGroupType<IVatRowData> & { title: React.ReactNode })[] = useMemo(() => {
        const periodColumns = (periods: number[]): (ColumnGroupType<IVatRowData> & { title: React.ReactNode })[] => {
            return periods.map(pidx => {
                return {
                    title: `${getPeriodName(pidx)} ${year}`,
                    children: [
                        {
                            width: 120,
                            title: <div style={{ textAlign: "center" }}>Bemessung</div>,
                            render: (_, data) => renderBmg(data, data.periods[pidx]),
                        },
                        {
                            title: <div style={{ textAlign: "center" }}>Steuer</div>,
                            width: 120,
                            render: (_, data) => (
                                <VATControlContext.Consumer>
                                    {({ editItem }) =>
                                        renderSt(data, data.periods[pidx], v => {
                                            editItem(pidx, data.titleSt, v);
                                        })
                                    }
                                </VATControlContext.Consumer>
                            ),
                        },
                    ],
                };
            });
        };

        if (period === null) {
            // all year
            return [
                {
                    title: intl.formatMessage({ id: "app.labels.allYear" }),
                    children: [
                        {
                            title: <div style={{ textAlign: "center" }}>Bemessung</div>,
                            width: 100,
                            className: tableStyles.cellBold,
                            render: (_, data) => renderBmg(data, data.year),
                        },
                        {
                            title: <div style={{ textAlign: "center" }}>Steuer</div>,
                            width: 100,
                            className: tableStyles.cellBold,
                            render: (_, data) => renderSt(data, data.year),
                        },
                    ],
                },
                ...periodColumns([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
            ];
        }

        if (period >= 0 && period <= 12) {
            return periodColumns([period]);
        }
        if (period > 40) {
            const quartalNum = period - 40;
            const periods: number[] = [];
            periods.push(3 * quartalNum - 2);
            periods.push(3 * quartalNum - 1);
            periods.push(3 * quartalNum);
            const qidx = quartalNum - 1;

            return [
                {
                    title: `${quartalNum}. Quartal ${year}`,
                    children: [
                        {
                            title: <div style={{ textAlign: "center" }}>Bemessung</div>,
                            width: 100,
                            className: tableStyles.cellBold,
                            render: (_, data) => renderBmg(data, data.quartals[qidx]),
                        },
                        {
                            title: <div style={{ textAlign: "center" }}>Steuer</div>,
                            width: 100,
                            className: tableStyles.cellBold,
                            render: (_, data) => renderSt(data, data.quartals[qidx]),
                        },
                    ],
                },
                ...periodColumns(periods),
            ];
        }

        return [];
    }, [period, year, getPeriodName, intl]);

    return result;
};
