import { useContext, useMemo } from "react";
import { InvoiceTableContext } from "@inv/modules/InvoiceTableModule/context/InvoiceTableProvider";
import { InvoiceFormColumnsConfig } from "@inv/modules/InvoiceTableModule/config/invoiceFormColumnsConfig";
import { TInvoiceFormColumnsConfig } from "@inv/types";

export const useColumnConfig = () => {
    const { columnsConfig } = useContext(InvoiceTableContext);
    const activeKeys = useMemo(
        () => Object.keys(columnsConfig).filter((key: keyof TInvoiceFormColumnsConfig) => columnsConfig[key]),
        [columnsConfig]
    );

    return useMemo(() => InvoiceFormColumnsConfig.getColumns(activeKeys), [activeKeys]);
};
