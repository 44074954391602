import React, { FC } from "react";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { Typography } from "antd";

import styles from "./FormFields.module.scss";
import { UppercaseAndNumberInput } from "@app/components/shared/form/baseComponents/UppercaseLatinLettersInput";

const { Text } = Typography;

export const SwiftBicField: FC = () => {
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    BIC
                </Text>
            }
            name={"swiftBic"}
            rules={[{ min: 8, message: "" }]}
        >
            <UppercaseAndNumberInput maxLength={11} showCount></UppercaseAndNumberInput>
        </FieldLabel>
    );
};
