import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Form, Input, Popconfirm, Row } from "antd";
import { Contacts, GQL } from "@binale-tech/shared";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import BinaleForm from "appearance/components/shared/form/BinaleForm";
import { Addresses } from "./Addresses";
import { BankDetails } from "./BankDetails";
import { CollapsePanelKey, ContactCollapsePanel } from "../components/ContactCollapsePanel";
import { Communication } from "./Communication";
import { ContactBasicInfo } from "./ContactBasicInfo";
import { ContactPerson } from "./ContactPerson";
import { LegalInformation } from "./LegalInformation";
import { PaymentTerms } from "./PaymentTerms";

export interface IContactProps {
    item: Contacts.Contact | null;
    onSave: (v: Contacts.Contact) => Promise<void>;
    onCancel?: () => void;
    defaultInternalName: string;
}

export const defaultContactFormValue: Partial<Contacts.Contact> = {
    addresses: [{}],
    banks: [{}],
    communications: [{ type: GQL.ICommunicationType.Email }, { type: GQL.ICommunicationType.Phone }],
    relations: [{}, {}, {}, {}, {}] as any,
    defaultCurrencyCode: GQL.ICurrencyCode.Eur,
};

const focusFirstRef = async (errors: Record<string, any>) => {
    if (errors?.ref) {
        await new Promise(r => setTimeout(r, 10));
        errors.ref.focus();
        return;
    }
    if (errors.type && errors.message) {
        return;
    }
    const keys = Object.keys(errors);
    if (keys.length) {
        focusFirstRef(errors[keys[0]]);
    }
};

const getDefaultValue = (item?: Contacts.Contact) => {
    if (!item) {
        return { ...defaultContactFormValue };
    }
    const relations = Contacts.ContactConstants.relationTypeKontoOrder.map(type =>
        item.relations.find(r => r.type === type)
    );
    return { ...defaultContactFormValue, ...item, relations };
};

export const ContactForm: React.FC<IContactProps> = React.memo(function ContactForm({
    item,
    onCancel,
    onSave,
    defaultInternalName,
}) {
    const intl = useIntl();
    const [showConformCancel, setShowConfirmCancel] = useState(false);

    const methods = useForm({ defaultValues: getDefaultValue(item), mode: "all" });
    const [activeKeys, setActiveKeys] = useState<CollapsePanelKey[]>([]);

    const errors = useMemo(() => methods.formState.errors, [methods.formState]);
    const isValid = useMemo(() => methods.formState.isValid, [methods.formState]);
    const dirtyFields = useMemo(() => methods.formState.dirtyFields, [methods.formState]);

    useEffect(() => {
        if (Object.keys(errors).length) {
            const openKeys: CollapsePanelKey[] = [];
            Object.values(CollapsePanelKey)
                .filter(key => errors[key as keyof Contacts.Contact])
                .forEach(key => {
                    if (!activeKeys.includes(key)) {
                        openKeys.push(key);
                    }
                });
            if (openKeys.length) {
                setActiveKeys([...activeKeys, ...openKeys]);
                focusFirstRef(errors);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.formState.submitCount]);

    const isDirty = Boolean(Object.keys(dirtyFields).length);

    return (
        <FormProvider {...methods}>
            <BinaleForm onSubmit={methods.handleSubmit(onSave)} className="ContactForm ContactForm__condensed">
                <Card bordered>
                    <ContactBasicInfo item={item} defaultInternalName={defaultInternalName} />
                </Card>

                <ContactCollapsePanel
                    title={<FormattedMessage id="app.fields.address" />}
                    panelId={CollapsePanelKey.Addresses}
                    activeKeys={activeKeys}
                    onChange={setActiveKeys}
                >
                    <Addresses />
                </ContactCollapsePanel>

                <ContactCollapsePanel
                    title={<FormattedMessage id="app.titles.legalInformation" />}
                    panelId={CollapsePanelKey.LegalInfo}
                    activeKeys={activeKeys}
                    onChange={setActiveKeys}
                    forceRender
                >
                    <LegalInformation item={item} />
                </ContactCollapsePanel>

                <ContactCollapsePanel
                    title={<FormattedMessage id="app.titles.contacts.bankDetails" />}
                    panelId={CollapsePanelKey.Banks}
                    activeKeys={activeKeys}
                    onChange={setActiveKeys}
                    forceRender
                >
                    <BankDetails item={item} />
                </ContactCollapsePanel>

                <ContactCollapsePanel
                    title={<FormattedMessage id="app.titles.communication" />}
                    panelId={CollapsePanelKey.Communication}
                    activeKeys={activeKeys}
                    onChange={setActiveKeys}
                >
                    <Communication />
                </ContactCollapsePanel>

                <ContactCollapsePanel
                    title={<FormattedMessage id="app.titles.contactPerson" />}
                    panelId={CollapsePanelKey.ContactPerson}
                    activeKeys={activeKeys}
                    onChange={setActiveKeys}
                >
                    <ContactPerson />
                </ContactCollapsePanel>

                <ContactCollapsePanel
                    title={<FormattedMessage id="app.titles.termsOfPayment" />}
                    panelId={CollapsePanelKey.TermsOfPayment}
                    activeKeys={activeKeys}
                    onChange={setActiveKeys}
                >
                    <PaymentTerms />
                </ContactCollapsePanel>

                <Controller
                    name="notes"
                    render={({ field }) => (
                        <Form.Item style={{ marginTop: 12 }}>
                            <Input.TextArea
                                rows={4}
                                placeholder={intl.formatMessage({ id: "app.fields.buchtext2" })}
                                {...field}
                            />
                        </Form.Item>
                    )}
                />

                <Row justify="space-between">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="wide-button"
                        loading={methods.formState.isSubmitting}
                        data-testid="submitBtn"
                        disabled={!isValid || !isDirty || !!Object.keys(errors).length}
                    >
                        <FormattedMessage id="app.button.save" tagName="span" />
                    </Button>
                    <Popconfirm
                        title={<FormattedMessage id="app.global.saveChanges" />}
                        onConfirm={() => {
                            setShowConfirmCancel(false);
                            methods.handleSubmit(onSave)();
                        }}
                        placement="topRight"
                        cancelButtonProps={{ autoFocus: true }}
                        okText={<FormattedMessage id="app.global.yes" />}
                        cancelText={<FormattedMessage id="app.global.no" />}
                        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                        overlayStyle={{ minWidth: 400 }}
                        open={showConformCancel}
                        data-testid="popconfirm"
                        onCancel={onCancel}
                        destroyTooltipOnHide
                    >
                        <Button
                            type="default"
                            className="wide-button"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (isDirty) {
                                    setShowConfirmCancel(true);
                                } else {
                                    onCancel();
                                }
                            }}
                        >
                            <FormattedMessage id="app.button.close" />
                        </Button>
                    </Popconfirm>
                </Row>
            </BinaleForm>
        </FormProvider>
    );
});
