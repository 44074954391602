import { CategoryCreditorMode, CategoryCreditorModes, GenericRecordProperties } from "scripts/core/Product";

export class DisabledFieldsResolver {
    protected readonly credDebDisabledFields: string[];
    protected readonly categoryDisabledFields: string[];
    protected readonly taxCatDisabledFields: string[];

    constructor() {
        const simpleSchemeDisabled = [
            GenericRecordProperties.ComputedKASoll,
            GenericRecordProperties.ComputedKAHaben,
            GenericRecordProperties.ComputedSaldo,
        ];
        const extendedSchemeDisabled = [
            GenericRecordProperties.ComputedNetto,
            GenericRecordProperties.ComputedKABruttoSoll,
            GenericRecordProperties.ComputedKABruttoHaben,
            GenericRecordProperties.ComputedKASaldoBrutto,
        ];

        this.categoryDisabledFields = [...extendedSchemeDisabled];
        this.credDebDisabledFields = [...simpleSchemeDisabled];

        this.taxCatDisabledFields = [
            ...this.categoryDisabledFields,
            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ComputedUSt,
        ];
    }

    getDisabledFields(accountType: CategoryCreditorMode, isTaxCat: boolean) {
        if (accountType !== CategoryCreditorModes.CAT) {
            return this.credDebDisabledFields;
        }
        if (isTaxCat) {
            return this.taxCatDisabledFields;
        }
        return this.categoryDisabledFields;
    }
}
