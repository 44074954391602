import React, { type FC, useContext, useMemo } from "react";
import { FileUploaderContext } from "../../context/FileUploaderContext";
import { UploadFileItem } from "./UploadFileItem";

export const UploadFileList: FC = () => {
    const { allFilesInfo } = useContext(FileUploaderContext);

    const uploadFiles = useMemo(() => {
        return allFilesInfo.filter(el => el.status === "progress" || el.status === "ready" || el.status === "done");
    }, [allFilesInfo]);

    if (!uploadFiles.length) {
        return null;
    }

    return (
        <div style={{ width: "100%" }}>
            {uploadFiles.map(file => {
                return (
                    <UploadFileItem
                        key={file.hash}
                        hash={file.hash}
                        file={file.file}
                        status={file.status}
                        error={file.error}
                    />
                );
            })}
        </div>
    );
};
