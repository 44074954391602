import { useCallback, useContext } from "react";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { useFormValidators } from "../useFormValidators";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";

export const useFormHandlerRecordBF1 = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setRecordFieldErrorState } = useFormValidators();
    const { updateEditableRecord } = useFormRecordStateUpdater();
    return useCallback(
        (recordNum: string) => {
            updateEditableRecord({
                recordNum,
            });
            setRecordFieldErrorState(refsHtml.REF_rBELEGFELD1, false);
        },
        [refsHtml, setRecordFieldErrorState, updateEditableRecord]
    );
};
