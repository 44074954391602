import React from "react";
import { Base } from "@binale-tech/shared";
import { onValue, ref } from "firebase/database";

import { CompanyContext } from "./CompanyContext";
import { PaymentsContext } from "./PaymentsProvider";
import { RecordsContext, RecordsCtxData } from "./recordsContext/RecordsCtx";
import { UserContext } from "./UserProvider";
import { database } from "../api/firebase/firebase";

export const ReadinessContext = React.createContext({
    isConnected: false,
    isReady: false,
    percent: 0,
    loadingTexts: [] as React.ReactNode[],
});
const isUserCompanyDataReady = (user: Base.UserInterface, isCompanyLoaded: boolean) => {
    // not authenticated user
    if (!user.isLoading && !user.fireUser) {
        return true;
    }

    // logged in user and data is loaded
    return !user.isLoading && user.isAuthenticated && isCompanyLoaded;
};
const EMPTY_ARRAY: string[] = [];
const getLoadingStatus = (
    user: Base.UserInterface,
    recordsData: RecordsCtxData,
    paymentsData: React.ContextType<typeof PaymentsContext>
) => {
    // user is loading
    if (user.isLoading) {
        return { percent: 0, loadingTexts: EMPTY_ARRAY };
    }
    // not authenticated user
    if (!user.isLoading && !user.fireUser) {
        return { percent: 100, loadingTexts: EMPTY_ARRAY };
    }
    const loadingStatuses: boolean[] = [];
    const loadingTexts: React.ReactNode[] = [];
    loadingStatuses.push(paymentsData.isLoaded);
    loadingTexts.push(paymentsData.isLoaded ? "Loaded payments" : "Loading payments...");

    const { allRecords, ...cleanedRecordsData } = recordsData;
    Object.keys(cleanedRecordsData).forEach((key: Exclude<keyof RecordsCtxData, "allRecords">) => {
        const entry = cleanedRecordsData[key] as RecordsCtxData["recordsKB"];
        if ("isGroupListLoaded" in entry) {
            const groupEntry = entry;
            if (!groupEntry.isGroupListLoaded) {
                loadingStatuses.push(false);
                loadingTexts.push(`Loading ${key}...`);
            } else {
                const groupsAmount = groupEntry.groups.size;
                const groupsDataLoadedCount = [...groupEntry.groups.values()].reduce(
                    (acc, { isLoaded }) => acc + Number(isLoaded),
                    0
                );
                const groupsDataLen = [...groupEntry.groups.values()].reduce((acc, { list }) => acc + list.length, 0);
                const isGroupsDataLoaded = groupsDataLoadedCount === groupsAmount;
                loadingStatuses.push(isGroupsDataLoaded);
                const middleText = `${key} ${groupsDataLoadedCount}/${groupsAmount} (${groupsDataLen})`;
                loadingTexts.push(isGroupsDataLoaded ? `Loaded ${middleText}` : `Loading ${middleText}...`);
            }
        } else {
            const { isLoaded, list } = entry as RecordsCtxData["recordsER"];
            loadingStatuses.push(isLoaded);
            loadingTexts.push(isLoaded ? `Loaded ${key} (${list.length})` : `Loading ${key}...`);
        }
    });

    loadingTexts.push(`Aggregated list: ${allRecords.list.length}`);

    const loadingPerc = loadingStatuses.reduce((a, i) => a + Number(i), 0) / loadingStatuses.length;
    let percent = Math.floor(loadingPerc * 100);
    if (percent >= 99) {
        percent = 100;
    }

    return {
        percent,
        loadingTexts,
    };
};

const ReadinessProvider: React.FC<React.PropsWithChildren> = props => {
    const [isConnected, setIsConnected] = React.useState(false);
    const user = React.useContext(UserContext);
    const { isLoaded } = React.useContext(CompanyContext);
    const payments = React.useContext(PaymentsContext);
    const records = React.useContext(RecordsContext);
    const isUserCompanyReady = isUserCompanyDataReady(user, isLoaded);
    const { percent, loadingTexts } = getLoadingStatus(user, records, payments);
    const isReady = isUserCompanyReady && percent === 100;

    React.useEffect(() => {
        onValue(ref(database, ".info/connected"), snap => {
            setIsConnected(snap.val());
        });
    }, []);

    const value: React.ContextType<typeof ReadinessContext> = React.useMemo(
        () => ({
            isReady,
            isConnected,
            percent,
            loadingTexts,
        }),
        [isReady, isConnected, percent, loadingTexts]
    );
    return <ReadinessContext.Provider value={value}>{props.children}</ReadinessContext.Provider>;
};

export default ReadinessProvider;
