import { Base } from "@binale-tech/shared";

export interface ILogItem extends Base.IKey {
    date: Date;
}

export interface LogChanges {
    deepDiff: any[];
}

export class LogItem implements ILogItem {
    date: Date;
    key: string;

    constructor(
        public readonly itemKey: string,
        public readonly userEmail: string,
        public readonly changes: LogChanges
    ) {
        this.date = new Date();
    }

    static unserialize(v: any): LogItem {
        v.date = v.date instanceof Date ? v.date : new Date(v.date);
        return Object.assign(new LogItem(null, null, null), v);
    }
}
