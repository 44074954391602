import React, { useCallback, useContext } from "react";
import { focusFirstFormField, focusRef } from "../../../../scripts/infrastructure/helpers/focus";
import { useFormRecordLifecycle } from "../hooks/useFormRecordLifecycle";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";

export const useFormKeyDown = () => {
    const { onClearForm, validateAndSave } = useFormRecordLifecycle();
    const { refsHtml } = useContext(RecordFormPropsContext);
    return useCallback(
        (e: React.KeyboardEvent<HTMLElement>) => {
            if (e.key === "Escape") {
                onClearForm();
                return;
            }
            let prevent = false;
            if (e.key === "Home") {
                prevent = true;
                focusFirstFormField();
            } else if (e.key === "End") {
                prevent = true;
                focusRef(refsHtml.REF_cBUCHEN);
            } else if (e.key === "+" && !e.metaKey) {
                prevent = true;
                validateAndSave();
            }
            if (prevent) {
                e.stopPropagation();
                e.preventDefault();
            }
        },
        [onClearForm, refsHtml.REF_cBUCHEN, validateAndSave]
    );
};
