import Category from "../models/Category";
import React from "react";
import { BuTaxDB } from "../models/Bu";

export type BuTimeframe = {
    id: string;
    version: number;
    from: Date;
    to: Date;
    skr: number;
    items: BuTaxDB[];
    defaultCategories: Map<number, Category>;
};

export type DbEntryVersion = {
    tfid: string;
    version: number;
};

type BuContextValue = {
    companyBuTimeframes: BuTimeframe[];
    allBuTimeframes: Omit<BuTimeframe, "defaultCategories">[];
};

export const defaultBuContextValue: BuContextValue = { companyBuTimeframes: [], allBuTimeframes: [] };

export const BuContext = React.createContext<BuContextValue>(defaultBuContextValue);
