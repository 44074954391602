import { GenericRecord } from "../../models";

export const preprocessPriorities = (vs: GenericRecord[]) => {
    const prioList: { key: string; priority: number }[] = vs.map(({ key, priority }) => ({
        key,
        priority,
    }));
    prioList.forEach((value, index, list) => {
        if (index > 0) {
            if (value.priority <= list[index - 1].priority) {
                value.priority = list[index - 1].priority + 1;
            }
        }
    });
    return prioList;
};
