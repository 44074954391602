import React, { FC, useContext, useState } from "react";
import { Alert, Button, Form, Input, message, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import { httpsCallable } from "firebase/functions";
import { signInWithEmailAndPassword } from "firebase/auth";

import { BinaleErrorCodes } from "scripts/api/firebase/firebaseErrorMessages";
import { auth, FirebaseFunctions, functions } from "scripts/api/firebase/firebase";
import { LoginFormValues } from "./LoginForm";
import { UserContext } from "scripts/context/UserProvider";
import { logger } from "scripts/infrastructure/logger";
import { validation } from "scripts/infrastructure/helpers/validation";

interface Login2faFormValues {
    token: string;
}

interface IProps {
    hide2fa: () => void;
    credentials: LoginFormValues | null;
}

export const Login2faForm: FC<IProps> = ({ hide2fa, credentials }) => {
    const intl = useIntl();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const { isLoading: isUserLoading } = useContext(UserContext);

    const handleSubmit = async (values: Login2faFormValues) => {
        setLoading(true);
        const authorize2fa = httpsCallable(functions, FirebaseFunctions.authorize2fa);
        try {
            await authorize2fa({ ...values, ...credentials });
            await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
        } catch (e) {
            if (e.message === BinaleErrorCodes.AUTH_INVALID_ID_TOKEN) {
                setShowError(true);
            } else {
                logger.crit(new Error("Error with checking 2fa token"), { values, credentials, e });
                message.error(intl.formatMessage({ id: "app.global.error" }));
            }
            setLoading(false);
        }
    };

    return (
        <>
            {showError && <Alert type="error" message={intl.formatMessage({ id: "app.2fa.token_error" })} />}

            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item
                    required={false}
                    name="token"
                    rules={[...validation.commonNumber({ intl, max: 6, min: 6 }), validation.required(intl)]}
                    label={<FormattedMessage id="app.2fa.input_label" />}
                >
                    <Input suffix={<SafetyCertificateOutlined />} size="large" onChange={() => setShowError(false)} />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={loading || isUserLoading}
                        loading={loading || isUserLoading}
                        block
                    >
                        <FormattedMessage id="app.button.login" tagName="span" />
                    </Button>
                </Form.Item>
            </Form>
            <Row justify="space-between" align="middle">
                <Button type="text" size="large" onClick={hide2fa}>
                    <FormattedMessage id="app.global.previous_step" />
                </Button>
                <div className="auth__link" onClick={() => setShowHint(!showHint)}>
                    <FormattedMessage id="app.login.loose_2fa_app" />
                </div>
            </Row>
            {showHint && (
                <Row>
                    <FormattedMessage
                        id="app.login.loose_2fa_app_instructions"
                        values={{ email: <a href="mailto:support@binale.de">support@binale.de</a> }}
                    />
                </Row>
            )}
        </>
    );
};
