import React from "react";
import { AuswertungFalligkeitViewClass } from "../../productSharedComponents/auswertung/AuswertungViews";
import { AuswertungWrapper } from "../../productSharedComponents/auswertung/AuswertungWrapper";
import { CompanyContext } from "scripts/context/CompanyContext";

import { ProductAuswertungFalligkeitER, ProductAuswertungFalligkeitERAnz } from "scripts/core/Product";
import { useFalligkeitDefaultFilters } from "../../productSharedComponents/auswertung/auswertungDefaultFilters";

export const AuswertungFalligkeitER = React.memo(function AuswertungFalligkeitER() {
    const defaultFilters = useFalligkeitDefaultFilters();
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const product = React.useMemo(
        () => new ProductAuswertungFalligkeitER(yearConfig.skr, companyGQL),
        [yearConfig, companyGQL]
    );
    return (
        <AuswertungWrapper
            Component={AuswertungFalligkeitViewClass}
            view="erFalligkeit"
            defaultFilters={defaultFilters}
            product={product}
        />
    );
});
export const AuswertungFalligkeitViewErA = React.memo(function AuswertungFalligkeitViewErA() {
    const defaultFilters = useFalligkeitDefaultFilters();
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const product = React.useMemo(
        () => new ProductAuswertungFalligkeitERAnz(yearConfig.skr, companyGQL),
        [yearConfig, companyGQL]
    );
    return (
        <AuswertungWrapper
            Component={AuswertungFalligkeitViewClass}
            view="erAnzahalunenFalligkeit"
            defaultFilters={defaultFilters}
            product={product}
        />
    );
});
