import React, { FC } from "react";
import { FEProvider } from "../FEProvider";
import { TemplatesViewContent } from "@app/views/productSharedComponents/templates/TemplatesViewContent";

export const FETemplatesView: FC = () => {
    return (
        <FEProvider>
            <TemplatesViewContent />
        </FEProvider>
    );
};
