import React, { type FC } from "react";
import { NotValidFileItem } from "./NotValidFileItem";
import { Typography } from "antd";

interface IProps {
    notValidFiles: File[];
}

export const NotValidFiles: FC<IProps> = ({ notValidFiles }) => {
    return (
        <div style={{ marginBottom: "20px" }}>
            <Typography.Text type={"secondary"}>{notValidFiles.length} files is not valid</Typography.Text>
            {notValidFiles.map((el, i) => {
                return (
                    <div key={i}>
                        <NotValidFileItem fileName={el.name} />
                    </div>
                );
            })}
        </div>
    );
};
