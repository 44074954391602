import React, { useImperativeHandle, useRef, useState } from "react";
import cn from "classnames";
import { Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";

import ConnectionModal from "./ConnectionModal";
import { GenericRecord } from "scripts/models";
import { PaymentPrototype } from "scripts/models/Payment";
import { useGetConnectedPaymentSourceRecord } from "./hooks/useGetConnectedPaymentSourceRecord";

interface Props extends Pick<React.ComponentProps<typeof Button>, "size"> {
    isButtonDisabled: boolean;
    isEditDisabled?: boolean;
    isDeleteDisabled?: boolean;
    paymentRepresentationRecord: GenericRecord | null;
    formSelectedPayment?: PaymentPrototype;
    mode: "form" | "table";
    onSave: (v: { paymentSourceRecord: GenericRecord; payment: PaymentPrototype } | null) => void;
}

const BindingButton = React.forwardRef<HTMLButtonElement, Props>(function BindingButton(
    {
        paymentRepresentationRecord,
        isButtonDisabled,
        isEditDisabled: isEditDisabledPermission,
        isDeleteDisabled: isDeleteDisabledPermission,
        formSelectedPayment,
        size,
        onSave,
        mode,
    },
    ref
) {
    const [showModal, setShowModal] = useState(false);
    const { record: connectedPaymentSourceRecord } = useGetConnectedPaymentSourceRecord(
        paymentRepresentationRecord?.key
    );
    const buttonRef = useRef<HTMLButtonElement>();

    useImperativeHandle(ref, () => buttonRef.current, [buttonRef]);

    const handleOk: React.ComponentProps<typeof ConnectionModal>["onOk"] = value => {
        onSave(value);
        setShowModal(false);
        buttonRef.current?.focus();
    };

    const handleCancel = () => {
        setShowModal(false);
        buttonRef.current?.focus();
    };

    // const itemKontos = (record?.items || []).filter(v => v.creditor || v.debetor).map(v => v.getCategoryCreditor());
    // const isNotFound = itemKontos.length && !matchedRecords.matched.length;
    // void isNotFound;
    // todo Tooltip overrides ref in the button so buttonRef.current === null and auto click doesn't work in the form

    const isEditDisabled = isEditDisabledPermission || paymentRepresentationRecord?.items.length > 1;
    const isConnected = () => {
        if (mode === "table") {
            return Boolean(connectedPaymentSourceRecord);
        } else if (mode === "form") {
            return Boolean(formSelectedPayment);
        }
        throw new Error("unexpected mode: " + mode);
    };
    return (
        <>
            {showModal && (
                <ConnectionModal
                    mode={mode}
                    paymentRepresentationRecord={paymentRepresentationRecord}
                    formSelectedPayment={formSelectedPayment}
                    onCancel={handleCancel}
                    onOk={handleOk}
                    isEditDisabled={isEditDisabled}
                    isDeleteDisabled={isDeleteDisabledPermission}
                />
            )}
            {/* <Tooltip title={isNotFound ? <FormattedMessage id="app.tooltip.noOpenItems" /> : null}> */}
            <Button
                type="primary"
                data-testid="binding-button"
                className={cn({
                    "antd-button-success": isConnected(),
                })}
                size={size}
                style={{ marginBottom: size === "small" ? 0 : 2 }}
                onClick={() => setShowModal(true)}
                icon={<LinkOutlined />}
                disabled={isButtonDisabled}
                ref={buttonRef}
            />
            {/* </Tooltip> */}
        </>
    );
});

export default BindingButton;
