import Category from "../Category";
import { Base, Bu, KontoNumUtils } from "@binale-tech/shared";
import { IGenericItem, IGenericRecord } from "../Interfaces";
import { logger } from "../../infrastructure/logger";

export class CategoryUtils {
    static areEqual(num1: string, num2: string) {
        return new Category(num1, "").equalsTo(new Category(num2, ""));
    }

    static isAggregationCategory(v: Base.IExtNum, skr: number) {
        return this.isCategoryWildcardCreditor(v, skr) || this.isCategoryWildcardDebitor(v, skr);
    }

    static isCategoryWildcardCreditor(v: Base.IExtNum, skr: number) {
        const shortNum = Number(v.num.substring(0, 4));
        return skr === 3 ? shortNum === KontoNumUtils.CAT_CRED_3 : shortNum === KontoNumUtils.CAT_CRED_4;
    }

    static isCategoryWildcardDebitor(v: Base.IExtNum, skr: number) {
        const shortNum = Number(v.num.substring(0, 4));
        return skr === 3 ? shortNum === KontoNumUtils.CAT_DEB_3 : shortNum === KontoNumUtils.CAT_DEB_4;
    }

    static enrichCategory(kb: Base.INumName, defaultCategories: Map<number, Category>): Category {
        const num = kb.num.toString();
        const { name } = kb;
        const cat = defaultCategories.get(Number(num.substring(0, Category.DefaultLen)));
        if (!cat) {
            throw new Error(
                `unable to enrich category, num: ${num}, ${name}, size ${defaultCategories.size}, lastkey ${Array.from(
                    defaultCategories.keys()
                ).pop()}, proto ${JSON.stringify(kb)}`
            );
        }
        return Category.unserialize({ ...cat, name, num });
    }

    static getCCListFromRecords(records: IGenericRecord[], ext: number) {
        const ccMap = new Map();
        const set = (v: IGenericRecord | IGenericItem) => {
            if (v.category && v.category.num) {
                if (!v.category.getExtNum) {
                    logger.log(v.category);
                }
                ccMap.set(v.category.getExtNum(ext), v.category);
            }
            if (v.creditor && v.creditor.num) {
                ccMap.set(v.creditor.getExtNum(ext), v.creditor);
            }
            if (v.debetor && v.debetor.num) {
                ccMap.set(v.debetor.getExtNum(ext), v.debetor);
            }
        };
        records.forEach(v => {
            set(v);
            v.items.forEach(i => {
                set(i);
            });
        });
        return ccMap;
    }

    private static azCategoriesMap = new Map([
        [
            3,
            new Map([
                [Bu.Bu.BU1, 1510],
                [Bu.Bu.BU8, 1511],
                [Bu.Bu.BU9, 1518],
            ]),
        ],
        [
            4,
            new Map([
                [Bu.Bu.BU1, 1180],
                [Bu.Bu.BU8, 1181],
                [Bu.Bu.BU9, 1186],
            ]),
        ],
    ]);

    /**
     * Returns category to be used to create automatic FE record based on Anzahlung (AZ)
     *
     * @param {Bu} bu
     * @param {CompanySKR} skr
     * @param {Map<string, Category>} categories
     * @returns {Category}
     */
    static getAZCategory(bu: Bu.Bu, skr: number, categories: Map<number, Category>): Category {
        const categoryId = this.azCategoriesMap.get(skr).get(bu);
        return categories.get(categoryId);
    }
}
