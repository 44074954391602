import React from "react";
import { ArrowsAltOutlined, LeftOutlined, RightOutlined, RollbackOutlined } from "@ant-design/icons";
import { Periods } from "@binale-tech/shared";

import { ContextMenu, ContextMenuItem, ContextMenuSeparator } from "./ContextMenu";
import { GenericRecordTableItem } from "../../../components/shared/Table/Table";
import { ProductKey, ProductKeys } from "scripts/models/Product";

interface Props {
    selectedProductKey: ProductKey;

    onMoveToFE: (v: GenericRecordTableItem[], isPos: boolean) => void;
    onMoveToPeriod: (v: GenericRecordTableItem[], period: Periods.Period) => void;
    onUnsplit: (v: GenericRecordTableItem) => void;
    targetItems?: GenericRecordTableItem[];
    acquireHeight?: (h: number) => void;
}

const TableContextMenuImport: React.FC<Props> = props => {
    const { selectedProductKey, targetItems = [], acquireHeight } = props;

    const getItemsText = (num: number, enabled = true) => (targetItems.length > 1 && enabled ? ` (${num})` : "");

    const isPeriod0Available = targetItems.every(ti => ti.item.date.getMonth() === 0 && ti.item.date.getDate() === 1);
    const isPeriod13Available = targetItems.every(
        ti => ti.item.date.getMonth() === 11 && ti.item.date.getDate() === 31
    );
    return (
        <ContextMenu acquireHeight={acquireHeight}>
            <ContextMenuItem
                onClick={() => props.onMoveToFE(targetItems, false)}
                enabled={selectedProductKey !== ProductKeys.FE}
                glyphicon={<RollbackOutlined />}
            >
                Move to FE
                {getItemsText(targetItems.length)}
            </ContextMenuItem>
            <ContextMenuItem
                onClick={() => props.onMoveToFE(targetItems, true)}
                enabled={selectedProductKey !== ProductKeys.POS}
                glyphicon={<RollbackOutlined />}
            >
                Move to POS
                {getItemsText(targetItems.length)}
            </ContextMenuItem>
            <ContextMenuItem
                onClick={() => props.onUnsplit(targetItems[0])}
                enabled={targetItems.length === 1}
                glyphicon={<ArrowsAltOutlined />}
            >
                Unsplit
                {getItemsText(targetItems.length)}
            </ContextMenuItem>
            <ContextMenuSeparator />
            <ContextMenuItem
                onClick={() => props.onMoveToPeriod(targetItems, Periods.Period.FirstDayOfYear)}
                enabled={isPeriod0Available}
                glyphicon={<LeftOutlined />}
            >
                To period 0 {getItemsText(targetItems.length)}
            </ContextMenuItem>
            <ContextMenuItem
                onClick={() => props.onMoveToPeriod(targetItems, Periods.Period.LastDayOfYear)}
                enabled={isPeriod13Available}
                glyphicon={<RightOutlined />}
            >
                To period 13
                {getItemsText(targetItems.length)}
            </ContextMenuItem>
        </ContextMenu>
    );
};
export default React.memo(TableContextMenuImport);
