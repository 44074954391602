import React, { FC, useContext, useEffect, useState } from "react";
import { Draggable, DraggableProvidedDraggableProps } from "react-beautiful-dnd";
import { OcrPage } from "../../../types";

import styles from "./UploadDocumentItem.module.scss";
import { PdfToolsContext, PdfToolsControlContext } from "../../../context";
import { FormattedMessage } from "react-intl";
import { Button, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { sizeCalcFromParent } from "../../../tools/helpers";

interface IProps {
    pages: OcrPage[];
    draggableId: string;
    idx: number;
    onClick?: (id: string) => void;
}

export const UploadedDocumentItem: FC<IProps> = ({ draggableId, pages, idx, onClick }) => {
    const [size, setSize] = useState({ width: 0, height: 0 });
    const { activePageId } = useContext(PdfToolsContext);
    const { removeUploadedDocument } = useContext(PdfToolsControlContext);

    useEffect(() => {
        function onResize() {
            const { width, height } = sizeCalcFromParent("carouselWrapper", 0.8);
            setSize({ width, height });
        }

        onResize();
        addEventListener("resize", onResize);

        return () => {
            removeEventListener("resize", onResize);
        };
    }, []);

    useEffect(() => {
        function onResize() {
            const { width, height } = sizeCalcFromParent("carouselWrapper", 0.8);
            setSize({ width, height });
        }

        onResize();
        addEventListener("resize", onResize);

        return () => {
            removeEventListener("resize", onResize);
        };
    }, []);

    const isActiveFrontDocument = pages.map(el => el.id).includes(activePageId);
    const boxShadow = isActiveFrontDocument ? "0 0 2px 2px rgb(64, 169, 255)" : "unset";

    const draggablePropsTransform = (draggableProps: DraggableProvidedDraggableProps) => {
        return {
            ...draggableProps,
            style: {
                ...draggableProps.style,
                width: size.width,
                height: size.height,
                boxShadow,
            },
        };
    };

    return (
        <Draggable draggableId={draggableId} index={idx}>
            {provided => {
                const draggableProps = draggablePropsTransform(provided.draggableProps);
                return (
                    <div
                        ref={provided.innerRef}
                        {...draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.uploadDocumentItemWrapper}
                        id={draggableId}
                        onClick={() => onClick(pages[0].id)}
                    >
                        <div
                            className={styles.uploadDocumentItemImg}
                            style={{
                                backgroundImage: `url(${pages[0].imageUrl})`,
                            }}
                        ></div>

                        <Button
                            icon={<CloseOutlined />}
                            style={{ position: "absolute", right: 5, top: 5 }}
                            size={"small"}
                            shape="circle"
                            onClick={e => {
                                e.stopPropagation();
                                removeUploadedDocument(draggableId);
                            }}
                        />

                        <Tooltip
                            destroyTooltipOnHide
                            placement="top"
                            className={styles.uploadDocumentItemTotalPagesWrapper}
                            title={
                                <>
                                    <FormattedMessage id="app.ocr.totalPages" /> {pages.length}
                                </>
                            }
                        >
                            {" "}
                            <div className={styles.uploadDocumentItemTotalPages}>
                                <FormattedMessage id="app.dms.pages" values={{ count: pages.length }} />
                            </div>
                        </Tooltip>
                    </div>
                );
            }}
        </Draggable>
    );
};
