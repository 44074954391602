export enum SplitMode {
    qr = 1,
    every = 2,
    second = 3,
    manual = 4,
    put = 5,
}
export enum DroppableIds {
    ParkedPages = "parked-pages",
    UploadedPages = "uploaded-pages",
    InvisibleDnd = "invisible-dnd",
    AllPages = "all-pages",
}

export enum PageSource {
    PARKED = "PARKED",
    UPLOADED = "UPLOADED",
    LISTED = "LISTED",
}
