/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
import { captureException, captureMessage } from "@sentry/react";

enum LoggerLevels {
    crit = 0,
    error = 1,
    warning = 2,
    log = 3,
    debug = 4,
}

const checkLevel = (level: LoggerLevels) => (target: Logger, propName: string, descriptor: PropertyDescriptor) => {
    const method = descriptor.value;
    const loggerLevel = process.env.NODE_ENV === "production" ? LoggerLevels.log : LoggerLevels.debug;

    descriptor.value = (...args: any) => {
        if (level <= loggerLevel) {
            method.apply(target, args);
        }
    };
};

class Logger {
    protected sendErrorToSentry(error: Error) {
        captureException(error);
    }

    protected sendMessageToSentry(...message: any) {
        captureMessage(JSON.stringify(message));
    }

    @checkLevel(LoggerLevels.crit)
    public crit(error: Error, ...rest: any) {
        console.error(...rest, error);
        this.sendErrorToSentry(error);
    }

    @checkLevel(LoggerLevels.error)
    public error(...message: any): void {
        console.error(...message);
    }

    @checkLevel(LoggerLevels.warning)
    public warning(...message: any): void {
        console.warn(...message);
    }

    @checkLevel(LoggerLevels.log)
    public logToSentry(...message: any): void {
        console.log(...message);
        this.sendMessageToSentry(...message);
    }

    @checkLevel(LoggerLevels.log)
    public log(...message: any): void {
        console.log(...message);
    }

    // won't be executed in production mode
    @checkLevel(LoggerLevels.debug)
    public debug(...message: any): void {
        console.log(...message);
    }
}

export const logger = new Logger();
