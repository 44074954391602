import React from "react";
import { FieldLabelProps } from "@ui-components/FieldLabel";

import { Category, Creditor, Debitor } from "scripts/models";
import {
    CategoryComboboxBlock,
    CombinedComboboxBlock,
    CreditorComboboxBlock,
    DebitorComboboxBlock,
} from "appearance/components/shared/form/FormBlocks";
import { CategoryCreditorMode, CategoryCreditorModes } from "scripts/core/Product";
import { ExtNumComboboxProps } from "appearance/components/shared/form/baseComponents";
import { useCombinedCCDList } from "scripts/infrastructure/hooks";
import { Base, KontoNumUtils } from "@binale-tech/shared";

interface Props extends Omit<ExtNumComboboxProps<Creditor | Debitor | Category>, "items"> {
    mode: CategoryCreditorMode;
    labelProps?: FieldLabelProps;
    itemKonto?: Base.IExtNum;
    debug?: boolean;
}

const CCDComponent: React.FC<Props> = ({ mode, labelProps, itemKonto, ...props }) => {
    const { combinedCCDList, defaultCategoriesFiltered } = useCombinedCCDList();

    const allLabelProps: FieldLabelProps = {
        style: { width: 120, minWidth: 100 },
        ...labelProps,
    };

    if (mode === CategoryCreditorModes.CRED) {
        return <CreditorComboboxBlock {...props} labelProps={allLabelProps} />;
    }
    if (mode === CategoryCreditorModes.DEB) {
        return <DebitorComboboxBlock {...props} labelProps={allLabelProps} />;
    }
    if (mode === CategoryCreditorModes.CAT) {
        return <CategoryComboboxBlock {...props} value={props.value as Category} labelProps={allLabelProps} />;
    }
    if (mode === CategoryCreditorModes.CCD) {
        return (
            <CombinedComboboxBlock
                {...props}
                items={combinedCCDList}
                altItems={defaultCategoriesFiltered}
                labelProps={allLabelProps}
            />
        );
    }
    if (mode === CategoryCreditorModes.RecordKontoFE) {
        props.disabled = props.disabled || !itemKonto;
        const itemKontoNum = Number(itemKonto?.getExtNum(0));
        let disableAutoCategories = true;
        if (itemKontoNum >= KontoNumUtils.SALDO_MIN && itemKontoNum <= KontoNumUtils.SALDO_MAX) {
            disableAutoCategories = false;
        }
        return (
            <CombinedComboboxBlock
                {...props}
                items={combinedCCDList}
                altItems={defaultCategoriesFiltered}
                labelProps={allLabelProps}
                disableAutoCategories={disableAutoCategories}
                disableSaldoCategories
            />
        );
    }
    return null;
};

export default CCDComponent;
