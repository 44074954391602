import React, { ComponentProps, FC } from "react";
import { DmsModal } from "./DmsModal";
import { DmsModalProvider } from "./context/DmsModalContext";

export const DmsModalModule: FC<ComponentProps<typeof DmsModal>> = props => {
    return (
        <DmsModalProvider>
            <DmsModal {...props} />
        </DmsModalProvider>
    );
};
