import Category, { CategoryBuScope, CategoryDB } from "../../models/Category";
import Dexie from "dexie";
import { Bu, GQL } from "@binale-tech/shared";
import { BuTimeframe, DbEntryVersion } from "../BuContext";
import { Converters } from "./converters";
import { collection, getDocsFromServer } from "firebase/firestore";
import { firestore } from "../../api/firebase/firebase";
import { logger } from "../../infrastructure/logger";
import { message } from "antd";

type PartialBuTf = Pick<BuTimeframe, "id" | "version">;
type ReFetchedRes = { doc: PartialBuTf; categories: (CategoryDB & { key: string })[] }[];

type DbEntryCat = {
    key: string;
    tfid: string;
    version: number;
    json: string;
};

class DexieDatabase extends Dexie {
    // Declare implicit table properties.
    categories: Dexie.Table<DbEntryCat, string>; // string = type of the primary key
    versions: Dexie.Table<DbEntryVersion, string>;

    constructor() {
        super("skrdb");
        this.version(1).stores({
            categories: "&key, tfid, version, json",
            versions: "&tfid, version",
        });
        // The following line is needed if your typescript
        // is compiled using babel instead of tsc:
        this.categories = this.table("categories");
        this.versions = this.table("versions");
    }
}

export class SKRRetriever {
    protected messageKey: string;
    protected db: DexieDatabase;
    protected repository: SKRIndexDBRepository;

    constructor() {
        this.db = new DexieDatabase();
        this.repository = new SKRIndexDBRepository(this.db, progress => {
            // this.onProgress(progress);
            // console.log(progressOffset, progressStep, progress);
        });
    }

    onProgress = (p: number) => {
        p = Math.ceil(p);
        if (!this.messageKey) {
            this.messageKey = "skr_loader" + Math.random().toFixed(5);
            message.loading({ content: `Daten werden geladen... 1%`, key: this.messageKey, duration: 0 });
            return;
        }

        if (p < 100) {
            message.loading({ content: `Daten werden geladen... ${p}%`, key: this.messageKey, duration: 0 });
        } else {
            message.success({ content: "Loaded", key: this.messageKey });
            setTimeout(() => {
                message.destroy(this.messageKey);
                this.messageKey = undefined;
            }, 500);
        }
    };

    async fetch(
        configs: GQL.ICompanyAccountingYear[]
    ): Promise<{ companyBuTimeframes: BuTimeframe[]; allBuTimeframes: Omit<BuTimeframe, "defaultCategories">[] }> {
        this.onProgress(1);
        const allDocs = await this.fetchTimeframesBaseConfig();
        this.onProgress(5);
        const buItems = await this.fetchAllBuItems(allDocs);
        allDocs.forEach((doc, i) => {
            const items = buItems[i];
            items.sort((a, b) => a.bu - b.bu);
            doc.items = items;
        });
        this.onProgress(10);
        const map = new Map<string, BuTimeframe>();
        const skr = configs?.[0]?.skr ?? 4; //in case there is no configs, we load skr 4 just to allow to create year
        const buTfs = await this.fetchSkr(skr, allDocs);
        buTfs.forEach(v => {
            map.set(v.id, v);
        });

        const buTimeframes: BuTimeframe[] = Array.from(map.values()).sort((a, b) => (a.from as any) - (b.from as any));

        this.onProgress(100);
        return { companyBuTimeframes: buTimeframes, allBuTimeframes: allDocs };
    }

    async fetchSkr(skr: number, allDocs: BuTimeframe[]): Promise<BuTimeframe[]> {
        const docs = allDocs.filter(v => v.skr === skr);
        const resMap = await this.retrieveDefaultCategories(docs, [5, 90]);
        this.onProgress(95);
        for (const doc of docs) {
            doc.defaultCategories = resMap.get(doc.id);
        }

        return docs;
    }

    protected async retrieveDefaultCategories(buTfs: BuTimeframe[], [progressOffset, progressStep]: [number, number]) {
        const ids = buTfs.map(d => d.id);
        const timeframesToRefetch: PartialBuTf[] = [];
        for (const buTimeframe of buTfs) {
            const localVersionEntry = await this.db.versions.get(buTimeframe.id);
            const localVersion = localVersionEntry ? localVersionEntry.version : 0;
            const count = await this.db.categories.where("tfid").equals(buTimeframe.id).limit(1).count();
            if (buTimeframe.version > localVersion || count === 0) {
                timeframesToRefetch.push(buTimeframe);
            }
        }
        const reFetched: ReFetchedRes = [];
        let counter = 0;
        for (const toReFetch of timeframesToRefetch) {
            const dbCategories = await this.fetchDefaultCategories(toReFetch.id);
            this.onProgress(progressOffset + Math.floor(progressStep / 3 / timeframesToRefetch.length) * ++counter);
            reFetched.push({ categories: dbCategories, doc: toReFetch });
        }
        await this.repository.persistFromRemote(reFetched);

        return this.repository.readFromDb(ids);
    }

    protected async fetchTimeframesBaseConfig() {
        for (;;) {
            try {
                const tfc = await getDocsFromServer(
                    collection(firestore, "configuration/bu/timeframes").withConverter(Converters.buTfConverter)
                );
                const docs: BuTimeframe[] = tfc.empty ? [] : tfc.docs.map(doc => doc.data());
                return docs;
            } catch (e) {
                logger.warning("error fetching base config, retrying", e.message);
                await new Promise(r => setTimeout(r, 5000));
            }
        }
    }

    protected async fetchDefaultCategories(id: string) {
        for (;;) {
            try {
                const data = await getDocsFromServer(
                    collection(firestore, `configuration/bu/timeframes/${id}/categories`).withConverter(
                        Converters.categoryConverter
                    )
                );
                const docs: (CategoryDB & { key: string })[] = data.empty ? [] : data.docs.map(doc => doc.data());
                return docs;
            } catch (e) {
                logger.warning("error fetching default categories, retrying", id, e.message);
                await new Promise(r => setTimeout(r, 5000));
            }
        }
    }

    protected fetchAllBuItems(docs: Pick<BuTimeframe, "id">[]) {
        return Promise.all(docs.map(doc => this.fetchBuItems(doc.id)));
    }

    protected async fetchBuItems(id: string) {
        for (;;) {
            try {
                return await getDocsFromServer(
                    collection(firestore, `configuration/bu/timeframes/${id}/items`).withConverter(
                        Converters.buTaxConverter
                    )
                ).then(list => list.docs.map(v => v.data()));
            } catch (e) {
                logger.warning("error fetching bu items, retrying", id, e.message);
                await new Promise(r => setTimeout(r, 5000));
            }
        }
    }
}

class SKRIndexDBRepository {
    protected categoryDbCache: Map<string, BuTimeframe["defaultCategories"]> = new Map();

    constructor(
        protected readonly db: DexieDatabase,
        protected readonly onProgress: (v: number) => void
    ) {}

    protected readonly hfMap = new Map<number, Category["buScope"]>([
        [1, "AV"],
        [2, "AM"],
        [6, "SAV"], // Sammelfunktion automatische Vorsteuer (erhaltene Skonti)
        [7, "SAM"], // Sammelfunktion automatische Mehrwertsteuer (gewährte Skonti)
    ]);

    getBuScope(cdb: Partial<CategoryDB>): CategoryBuScope {
        if (cdb.ZF === 1) {
            return "KU";
        }
        if (this.hfMap.has(cdb.HF)) {
            return this.hfMap.get(cdb.HF);
        }
        switch (cdb.ZF) {
            case 3:
                return "V";
            case 8:
                return "M";
        }
        return undefined;
    }

    async persistFromRemote(input: ReFetchedRes) {
        let counter = 0;
        for (const item of input) {
            const { id, version } = item.doc;
            await this.db.categories.where("tfid").equals(id).delete();
            const dbCategories = item.categories;
            logger.log("loaded from remote", id, dbCategories.length, { dbCategories });
            if (dbCategories.length) {
                const bulkData: DbEntryCat[] = dbCategories.map(catProcessed => {
                    const key = `skr-${id}-${catProcessed.Konto}`;
                    const data = JSON.stringify(catProcessed);
                    return { key, tfid: id, json: data, version };
                });
                const promRes = await this.db.categories.bulkPut(bulkData);
                logger.log("bulkPut promRes", id, promRes);
                await this.db.versions.put({ tfid: id, version });
            }
            this.onProgress(30 + Math.floor((29 / input.length) * ++counter));
        }
    }

    async readFromDb(ids: string[]) {
        const res: Map<string, BuTimeframe["defaultCategories"]> = new Map();
        let counter = 0;
        for (const id of ids) {
            const t = Date.now();
            if (this.categoryDbCache.has(id)) {
                res.set(id, this.categoryDbCache.get(id));
                logger.log(`read from cache ${id} in ${Date.now() - t}`);
                continue;
            }
            const categoryList: Category[] = [];
            await this.db.categories
                .where("tfid")
                .equals(id)
                .each(entry => {
                    const catLS = JSON.parse(entry.json);
                    const { Kontenbeschrifung, name: dbCatName, ...catDB }: CategoryDB = catLS;
                    const name = dbCatName ?? Kontenbeschrifung;
                    const buScope = this.getBuScope(catDB);
                    const catProcessed: Record<string, any> = {
                        ...catDB,
                        name,
                        buScope,
                    };
                    if (catDB.ZF === 1) {
                        catProcessed.buAuto = Bu.Bu.KU;
                    } else if (this.hfMap.has(catDB.HF)) {
                        catProcessed.buAuto = catDB.BU;
                    }
                    const category = Category.unserialize(catProcessed);
                    categoryList.push(category);
                });
            categoryList.sort((a, b) => Number(a.num) - Number(b.num));
            logger.log(`read from db ${id} in ${Date.now() - t}`, categoryList.length, { categoryList });
            const map = new Map();
            categoryList.forEach(v => {
                const vt: Category & Pick<CategoryDB, "Konto"> = v as any;
                map.set(vt.Konto, v);
            });
            res.set(id, map);
            this.categoryDbCache.set(id, map);
            this.onProgress(Math.floor(60 + (39 / ids.length) * ++counter));
        }
        return res;
    }
}
