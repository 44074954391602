import { IDocumentEnriched } from "@dms/types";
import { Col, Row } from "antd";
import React, { type FC, useContext, useEffect, useRef, useState } from "react";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormModule } from "@dms/modules/GroupFormModules/GroupFormModule";
import { SingleFormModule } from "@dms/modules/SingleFormModule/SingleFormModule";
import { DocumentData } from "../DocumentData/DocumentData";
import { PdfViewModule } from "@app/components/shared/PdfViewer/PdfViewModule";
import { DmsDataContext } from "@dms/types/ContextTypes";

export const GroupDocumentLayout: FC = () => {
    const [carouselDocuments, setCarouselDocuments] = useState<IDocumentEnriched[]>([]);
    const [documentData, setDocumentData] = useState<IDocumentEnriched>();
    const [activeDocumentsId, setActiveDocumentsId] = useState<string>();
    const { documents } = useContext(DmsDataContext);
    const { isGroupEditor, carouselKeys } = useContext(FormWrapperContext);

    const initialActiveDocumentId = useRef<string>();

    useEffect(() => {
        const carouselDocumentsArr = documents.filter(el => carouselKeys.includes(el.key));

        setCarouselDocuments(carouselDocumentsArr);

        if (!initialActiveDocumentId.current && carouselDocumentsArr.length) {
            initialActiveDocumentId.current = carouselDocumentsArr[0].key;
        }
    }, [documents, carouselKeys]);

    useEffect(() => {
        setActiveDocumentsId(initialActiveDocumentId.current);
    }, []);

    useEffect(() => {
        const foundDocument = carouselDocuments.find(el => el.key === activeDocumentsId);
        if (!foundDocument) {
            return;
        }
        setDocumentData({ ...foundDocument });
    }, [carouselDocuments, activeDocumentsId]);

    return carouselDocuments.length ? (
        <Row>
            <PdfViewModule
                documentData={documentData}
                carouselDocuments={carouselDocuments}
                activeDocumentsId={activeDocumentsId}
                setActiveDocumentsId={setActiveDocumentsId}
                sizeRatio={[5, 13]}
            />
            <Col span={6} style={{ paddingRight: 5 }}>
                <DocumentData>
                    {isGroupEditor ? (
                        <GroupFormModule documentsData={carouselDocuments} />
                    ) : (
                        <SingleFormModule key={documentData?.key} documentInitialValue={documentData} />
                    )}
                </DocumentData>
            </Col>
        </Row>
    ) : null;
};
