import { GQL } from "@binale-tech/shared";
import { GenericRecord } from "scripts/models";
import { GenericRecordColumns } from "./ColumnConfig";
import { TableColumn } from "../components/shared/Table/Table";

export class RecordColumnsEnriched {
    protected footers: Record<string, TableColumn<GenericRecord>["footer"]> = {};
    protected hiddenColumns: Set<string> = new Set();
    protected columnWidths: Map<string, number> = new Map();
    protected sortColumn: TableColumn<GenericRecord> = null;

    protected constructor(
        protected company: GQL.ICompany,
        protected tableColumns: GenericRecordColumns
    ) {}

    static from(company: GQL.ICompany, tableColumns: GenericRecordColumns) {
        return new RecordColumnsEnriched(company, tableColumns);
    }

    withFooters(footers: Record<string, TableColumn<GenericRecord>["footer"]> = {}) {
        this.footers = footers;
        return this;
    }

    withHiddenColumns(v: string[] = []) {
        this.hiddenColumns = new Set(v);
        return this;
    }

    withCustomWidth(columnWidths: Map<string, number> = new Map()) {
        this.columnWidths = columnWidths;
        return this;
    }

    withSortColumn(sortColumn: TableColumn<GenericRecord> = null) {
        this.sortColumn = sortColumn;
        return this;
    }

    getProcessor() {
        return new DynamicGenericRecordColumnsProcessor(
            this.company,
            this.tableColumns,
            this.footers,
            this.hiddenColumns,
            this.columnWidths,
            this.sortColumn
        );
    }
}

class DynamicGenericRecordColumnsProcessor {
    protected readonly filters: ((v: TableColumn<GenericRecord>[]) => TableColumn<GenericRecord>[])[] = [];

    constructor(
        protected company: GQL.ICompany,
        protected _tableColumns: GenericRecordColumns,
        protected _footers: Record<string, TableColumn<GenericRecord>["footer"]>,
        protected _disabledColumns: Set<string>,
        protected _columnWidths: Map<string, number>,
        protected _sortColumn: TableColumn<GenericRecord>
    ) {
        this.filters.push(tc => this.updateColumns(tc));
        this.filters.push(tc => this.filterColumns(tc));
        this.filters.push(tc => this.withSortColumn(tc));
        this.filters.push(tc => this.resizeColumns(tc));
    }

    getColumnConfig(): TableColumn<GenericRecord>[] {
        return this.filters.reduce((caret, fn) => fn(caret), this._tableColumns.getColumnConfig() as any);
    }

    protected updateColumns(tableColumns: TableColumn<GenericRecord>[]): TableColumn<GenericRecord>[] {
        return tableColumns.map(v => {
            if (this._footers && this._footers[v.key]) {
                v.footer = this._footers[v.key];
            }
            return v;
        });
    }

    protected filterColumns(columns: TableColumn<GenericRecord>[]): TableColumn<GenericRecord>[] {
        return this._doFilterColumns(columns, this._disabledColumns);
    }

    protected withSortColumn(columns: TableColumn<GenericRecord>[]): TableColumn<GenericRecord>[] {
        if (!this._sortColumn) {
            return columns;
        }
        return this._doWithSortColumn(columns, this._sortColumn);
    }

    protected resizeColumns(columns: TableColumn<GenericRecord>[]): TableColumn<GenericRecord>[] {
        return columns.map(c => {
            if (this._columnWidths.has(c.key)) {
                c.width = this._columnWidths.get(c.key) || c.width;
            }
            return c;
        });
    }

    protected _doFilterColumns(
        columns: TableColumn<GenericRecord>[],
        settingsDisabled: Set<string>
    ): TableColumn<GenericRecord>[] {
        const m = new Map(
            columns.map(({ key }) => {
                return [key, !settingsDisabled.has(key)];
            })
        );
        return columns.filter(v => !m.has(v.key) || m.get(v.key));
    }

    protected _doWithSortColumn(
        columns: TableColumn<GenericRecord>[],
        column: TableColumn<GenericRecord>
    ): TableColumn<GenericRecord>[] {
        const sortColumns = [...columns];
        columns.forEach((value, index) => {
            if (value.key === column.key) {
                value.sortDirection = column.sortDirection;
            } else {
                sortColumns[index].sortDirection = null;
            }
        });
        return sortColumns;
    }
}
