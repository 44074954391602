import React from "react";
import { CompanyContext } from "scripts/context/CompanyContext";
import { BuContext } from "scripts/context/BuContext";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { Category, Creditor, Debitor, GenericRecord } from "scripts/models";
import { CategoryUtils } from "scripts/models/utils/CategoryUtils";
import { KontoNumUtils } from "@binale-tech/shared";
import { SHConverter } from "scripts/core/SollHaben";
import { getTaxMap } from "./kaFunctions";

type Props = {
    records: GenericRecord[];
};
type Result = {
    numNameList: (Creditor | Category | Debitor)[];
};
const emptyState: Result["numNameList"] = [];
export const useKAToolbarNumNameList = ({ records }: Props): Result => {
    const { yearConfig, isLoaded } = React.useContext(CompanyContext);
    const { companyBuTimeframes: buTimeframes } = React.useContext(BuContext);
    const payments = React.useContext(PaymentsContext);

    const numNameList = React.useMemo(() => {
        if (!isLoaded || !yearConfig) {
            return emptyState;
        }
        const { kontoExt: ext, skr } = yearConfig;
        const res = CategoryUtils.getCCListFromRecords(records, ext);
        const converter = new SHConverter(yearConfig, buTimeframes, payments);
        const taxMap = getTaxMap(records, converter, ext);
        taxMap.forEach((v, k) => {
            if (!res.has(k)) {
                res.set(k, v);
            }
        });

        const toolbarNumNameList = Array.from(res.values());
        toolbarNumNameList.push(
            Category.unserialize({
                num: String(skr === 4 ? KontoNumUtils.CAT_CRED_4 : KontoNumUtils.CAT_CRED_3),
                name: "Kreditoren",
            }).fixNum(ext)
        );
        toolbarNumNameList.push(
            Category.unserialize({
                num: String(skr === 4 ? KontoNumUtils.CAT_DEB_4 : KontoNumUtils.CAT_DEB_3),
                name: "Debitoren",
            }).fixNum(ext)
        );
        toolbarNumNameList.sort((a, b) => Number(a.getExtNum(ext)) - Number(b.getExtNum(ext)));
        return toolbarNumNameList;
    }, [buTimeframes, isLoaded, payments, records, yearConfig]);

    return { numNameList };
};
