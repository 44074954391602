import React, { useContext, useMemo } from "react";
import { DeleteOutlined, EditOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Popconfirm } from "antd";

import { Base } from "@binale-tech/shared";
import { CompanyContext } from "scripts/context/CompanyContext";
import { Container } from "../../../components/shared/appearance/page";
import { FlexRow } from "../../../components/shared/appearance/page/Scaffold";
import { TableCellAlignment } from "../../../components/shared/Table/TableCell";
import { TableColumn, TableItemExtra, TableStateless } from "../../../components/shared/Table/Table";

import { Category } from "../../../../scripts/models";
import { gray, yellow } from "@ant-design/colors";
import "./styles.css";

interface Props {
    items: { entity: Base.INumName; disabled?: boolean; defaultCategory?: Base.INumName }[];
    onClick?: (v: Base.INumName) => void;
    onDeleteClick?: (v: Base.INumName) => void;
    isRemovable?: (v: Base.INumName) => boolean;
    showDefaultName?: boolean;
    onMarkClick?: (v: Base.INumName, marked: boolean) => void;
}

type TableItemType = TableItemExtra<Base.INumName, { disabled?: boolean; defaultCategory?: Base.INumName }> & {
    _key: string;
};

export const NumNameTable: React.FC<Props> = ({
    items,
    isRemovable,
    onClick,
    onDeleteClick,
    showDefaultName,
    onMarkClick,
}) => {
    const { yearConfig } = useContext(CompanyContext);

    const getNum = (i: any, kontoExt: number) => {
        if (typeof i.getExtNumPrint === "function") {
            return i.getExtNumPrint(kontoExt);
        }
        return i.num;
    };

    const getterNumber = (tableItem: TableItemType) => tableItem.key;
    const getterKonto = (tableItem: TableItemType) => getNum(tableItem.item, yearConfig?.kontoExt ?? 0);
    const getterBezeichnung = (tableItem: TableItemType) => {
        const override = tableItem.extra.defaultCategory?.name;
        const { name } = tableItem.item;
        if (showDefaultName && override === tableItem.item.name) {
            return <span style={{ color: gray[1] }}>{override}</span>;
        }
        return name;
    };
    const getterDefaultName = (tableItem: TableItemType) => tableItem.extra.defaultCategory?.name;

    const itemsCache: TableItemType[] = useMemo(() => {
        return items.map((i, idx) => {
            return {
                key: idx + 1,
                item: i.entity,
                _key: i.entity.key,
                children: [],
                extra: { disabled: i.disabled, defaultCategory: i.defaultCategory },
            };
        });
    }, [items]);

    const columns: TableColumn<Base.INumName>[] = [
        {
            width: 60,
            header: <FormattedMessage id="app.fields.lfdnr" />,
            getter: getterNumber,
            alignment: TableCellAlignment.right,
        },
        {
            width: 100,
            header: <FormattedMessage id="app.fields.konto" />,
            getter: getterKonto,
        },
        showDefaultName
            ? {
                  width: 550,
                  header: (
                      <>
                          Original <FormattedMessage id="app.fields.bezeichnung" />
                      </>
                  ),
                  getter: getterDefaultName,
              }
            : null,
        {
            width: 550,
            header: <FormattedMessage id="app.fields.bezeichnung" />,
            getter: getterBezeichnung,
        },
        {
            width: 90,
            header: "",
            alignment: TableCellAlignment.left,
            getter: (record: TableItemType) => {
                if (record.extra.disabled) {
                    return undefined;
                }
                const style = { cursor: "pointer", margin: "0 5px", color: yellow[5] };
                return (
                    <FlexRow>
                        {onMarkClick &&
                            ((record.item as Category).marked ? (
                                <StarFilled style={style} onClick={() => onMarkClick(record.item, false)} />
                            ) : (
                                <StarOutlined style={style} onClick={() => onMarkClick(record.item, true)} />
                            ))}

                        <EditOutlined
                            style={{ cursor: "pointer", margin: "0 5px" }}
                            onClick={() => {
                                onClick(record.item);
                            }}
                        />
                        {isRemovable && isRemovable(record.item) && (
                            <Popconfirm
                                title={<FormattedMessage id="app.confirmation.header" />}
                                onConfirm={e => {
                                    e.stopPropagation();
                                    onDeleteClick(record.item);
                                }}
                                okText={<FormattedMessage id="app.button.confirm" />}
                                cancelText={<FormattedMessage id="app.button.cancel" />}
                            >
                                <DeleteOutlined style={{ cursor: "pointer", margin: "0 5px" }} />
                            </Popconfirm>
                        )}
                    </FlexRow>
                );
            },
        },
    ].filter(Boolean);

    return (
        <Container style={{ height: "100%", position: "relative", maxWidth: 1350 }}>
            {(w, h) => (
                <TableStateless<Base.INumName>
                    columns={columns}
                    items={itemsCache}
                    containerHeight={h}
                    showFooter={false}
                    withExpandColumn={false}
                    containerClassName="NumNameTable-container"
                    rowClassName={(ti: TableItemType) => {
                        return ti.extra.disabled ? "NumNameTable__Row--disabled" : undefined;
                    }}
                />
            )}
        </Container>
    );
};
