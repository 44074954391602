import React, { FC } from "react";
import { Flex } from "antd";
import { Periods } from "@binale-tech/shared";
import { GlobalYearSelect } from "../../../../components/shared/Toolbar/YearSelect";
import { PeriodQuarterSelect } from "../../../../components/shared/Toolbar/PeriodQuarterSelect";
import { FormattedMessage } from "react-intl";
import { Switch } from "@nextui-org/switch";

interface Props {
    period: Periods.Period;
    showAllMonths: boolean;
    onShowAllMonths: (v: boolean) => void;
    onChangePeriod: (v: number) => void;
}

export const BWAToolbar: FC<Props> = props => {
    const { period } = props;

    return (
        <Flex align="center" gap={"small"} style={{ paddingBottom: 8 }}>
            <GlobalYearSelect />
            <PeriodQuarterSelect period={period} onChange={props.onChangePeriod} />
            {/* {filter.period === null && ( */}
            <Switch onValueChange={props.onShowAllMonths} checked={props.showAllMonths}>
                <FormattedMessage id="app.components.all" />
            </Switch>
            {/* )} */}
        </Flex>
    );
};
