import { useCallback, useContext } from "react";
import { Category, Creditor } from "../../../../../scripts/models";
import RecordFormUtils from "@app/components/recordform/utils/RecordFormUtils";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { useFormValidators } from "../useFormValidators";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";
import { useFormHandlerItemBu } from "./useFormHandlerItemBu";

export const useFormHandlerItemAccount = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setItemFieldErrorState } = useFormValidators();
    const { updateEditableItem } = useFormRecordStateUpdater();
    const { onUstChange } = useFormHandlerItemBu();
    return useCallback(
        (itemCategoryCreditor: Category | Creditor) => {
            updateEditableItem({ itemCategoryCreditor });
            setItemFieldErrorState(refsHtml.REF_iCATEGORYCREDITOR, false);

            const ustData = RecordFormUtils.getUstFromAccountChange(itemCategoryCreditor);
            if (ustData) {
                onUstChange(ustData.itemBu, ustData.itemUSt13b);
            }
        },
        [onUstChange, refsHtml.REF_iCATEGORYCREDITOR, setItemFieldErrorState, updateEditableItem]
    );
};
