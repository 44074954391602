import React, { useCallback, useEffect, useState } from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { Category } from "scripts/models";
import { CategoryUtils } from "scripts/models/utils/CategoryUtils";
import { CompanyContext } from "scripts/context/CompanyContext";
import { ExtNumCombobox, ExtNumComboboxProps } from "./ExtNumCombobox";
import { useCategoriesByYearMonth } from "scripts/infrastructure/hooks";
import { KontoNumUtils } from "@binale-tech/shared";

const filterByNum = (item: Category, term: string = ""): boolean => {
    const searchNum = term.substring(0, item.num.length);
    if (item.num.startsWith("0")) {
        return item.num.includes(searchNum);
    }
    return item.num.startsWith(searchNum);
};
const filterByNumName = (item: Category, val: string): boolean => {
    val = (val || "").toLowerCase();
    return filterByNum(item, val) || (item.name || "").toLowerCase().includes(val);
};

export const CategoryCombobox: React.FC<
    Omit<ExtNumComboboxProps<Category>, "items"> & { disableAutoAndSaldoCategories?: boolean }
> = ({ value, onChange, disableAutoAndSaldoCategories, ...props }) => {
    const { yearConfig } = React.useContext(CompanyContext);
    const { userCategories, defaultCategories } = useCategoriesByYearMonth();

    const categoryFilter = useCallback(
        (item: Category) => {
            if (CategoryUtils.isAggregationCategory(item, yearConfig?.skr)) {
                return false;
            }
            if (disableAutoAndSaldoCategories) {
                if (item.buScope !== "KU" && item.isAutoBu()) {
                    return false;
                }
                const num = Number(item.getExtNum(0));
                if (num >= KontoNumUtils.SALDO_MIN && num <= KontoNumUtils.SALDO_MAX) {
                    return false;
                }
            }
            return true;
        },
        [disableAutoAndSaldoCategories, yearConfig?.skr]
    );

    const [innerValue, setInnerValue] = useState<Category>(undefined);
    const [innerItems, setInnerItems] = useState(userCategories.filter(categoryFilter));

    const handleSearch = (query: string) => {
        if (!value && query) {
            const foundInItems = userCategories.filter(category => filterByNumName(category, query)).length > 0;

            if (!foundInItems && defaultCategories?.length && query.length > 1) {
                const globalFilter = defaultCategories.filter(
                    item => filterByNumName(item, query) && categoryFilter(item)
                );
                setInnerItems(globalFilter.slice(0, 100));
            } else {
                setInnerItems(userCategories.filter(categoryFilter));
            }
        }
    };

    useEffect(() => {
        if (value !== innerValue) {
            setInnerValue(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <ExtNumCombobox<Category>
            items={innerItems}
            value={innerValue}
            onChange={val => {
                setInnerValue(val);
                onChange(val);
            }}
            onSearch={handleSearch}
            notFoundContent={
                <span>
                    <p>
                        <FormattedMessage id="app.messages.no_elements_found" />
                    </p>
                    <Link to={AppRoutes.manageAccountCategories} target="_blank">
                        <Button type="primary" tabIndex={-1}>
                            <FormattedMessage id="app.items.create_category" />
                        </Button>
                    </Link>
                </span>
            }
            {...props}
        />
    );
};
