import { useCallback, useEffect, useRef } from "react";

const useIsMounted = (): (() => boolean) => {
    const ref = useRef(false);
    const cb = useCallback(() => ref.current, []);

    useEffect(() => {
        ref.current = true;
        return () => {
            ref.current = false;
        };
    }, []);

    return cb;
};

export { useIsMounted };
