import React, { MouseEventHandler } from "react";
import { Button, Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { ItemType } from "antd/es/menu/interface";
import { Switch } from "@nextui-org/switch";

import "./Multiselect.css";

const stopPropagation: MouseEventHandler = e => {
    e.stopPropagation();
};

export interface MultiselectValue {
    value: string;
    label?: string;
    selected?: boolean;
    disabled?: boolean;
}

interface MultiselectProps {
    data: MultiselectValue[];
    onChange: (v: MultiselectValue[]) => void;
    includeSelectAllOption?: boolean;
    disabled?: boolean;
    rightSide?: boolean;
    labelStatic?: React.ReactNode;
}

export const Multiselect: React.FC<MultiselectProps> = ({
    data,
    onChange = () => {},
    disabled,
    includeSelectAllOption,
    labelStatic,
    rightSide,
}) => {
    const intl = useIntl();

    const buttonText = () => {
        const selected = data.filter(v => v.selected).length;

        if (data.length === 0 || selected === 0) {
            return intl.formatMessage({ id: "app.components.not_selected" });
        } else if (selected === data.length) {
            return intl.formatMessage({ id: "app.components.all_selected" });
        } else {
            return `${selected} ${intl.formatMessage({ id: "app.components.nselected" })}`;
        }
    };

    const handleSwitchOne = (v: string) => {
        const copy: MultiselectValue[] = JSON.parse(JSON.stringify(data));
        copy.forEach(item => {
            if (item.value === v) {
                item.selected = !item.selected;
            }
        });
        onChange(copy);
    };

    const handleSwitchAll = (enabled: boolean) => {
        const copy: MultiselectValue[] = JSON.parse(JSON.stringify(data));
        copy.forEach(item => {
            item.selected = enabled;
        });
        onChange(copy);
    };

    const itemClick: React.ComponentProps<typeof Menu.Item>["onClick"] = e => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
    };

    const menuItems = data.map<ItemType>(v => ({
        key: v.value,
        label: (
            <div className="Multiselect__ListItem">
                <Switch
                    size="sm"
                    isSelected={v.selected}
                    isDisabled={disabled || v.disabled}
                    onValueChange={() => handleSwitchOne(v.value)}
                    onClick={stopPropagation}
                />
                {v.label}
            </div>
        ),
        "data-label": v.label,
    }));

    if (includeSelectAllOption) {
        menuItems.unshift({
            key: "all",
            onClick: itemClick,
            label: (
                <div className="Multiselect__ListItem">
                    <Switch
                        size="sm"
                        checked={data.every(v => v.selected)}
                        isDisabled={disabled}
                        onValueChange={handleSwitchAll}
                        onClick={stopPropagation}
                    />
                    <FormattedMessage id="app.components.all" />
                </div>
            ),
        });
    }

    return (
        <Dropdown
            menu={{ selectedKeys: [], items: menuItems, className: "Menu--HalfHeight" }}
            placement={rightSide ? "bottomRight" : "bottomLeft"}
            arrow
            trigger={["click"]}
        >
            <Button>
                {labelStatic || buttonText()} <DownOutlined />
            </Button>
        </Dropdown>
    );
};
