import Receipt from "scripts/models/Receipt";
import dayjs from "dayjs";

import { GenericRecord } from "scripts/models/GenericRecord";
import { TableItem, TableItemExtra } from "../../../components/shared/Table/Table";

export class KassenbuchDomain {
    sortByBruttoWithinDay(vs: GenericRecord[]) {
        const dateGroups: Record<string, GenericRecord[]> = {};
        vs.forEach(v => {
            const date = dayjs(v.date).format("YYYY.MM.DD");
            if (!dateGroups[date]) {
                dateGroups[date] = [];
            }
            dateGroups[date].push(v);
        });
        const sortedKeys = Object.keys(dateGroups).sort((a, b) => a.localeCompare(b));
        const dateGroupsMap = new Map<string, Receipt[]>();
        sortedKeys.forEach(k => {
            dateGroupsMap.set(k, dateGroups[k]);
        });

        const finalList: GenericRecord[] = [];
        dateGroupsMap.forEach((group, k) => {
            if (group.length === 1) {
                finalList.push(group[0]);
                return;
            }
            const prioList = group.map(v => v.priority);

            prioList.sort((a, b) => a - b); // just in case
            group.sort((a, b) => b.getBrutto() - a.getBrutto());
            group.forEach((v, idx) => {
                v.priority = prioList[idx];
            });
            finalList.push(...group);
        });
        return finalList;
    }

    getSelectedToJournal(
        vs: TableItem<GenericRecord>[],
        allRecords: GenericRecord[],
        allowNegativeSaldo: boolean
    ): TableItem<GenericRecord>[] {
        const selectedToJournal = vs.filter(v => !v.item.journaled && !v.item.draft).filter(v => v.selected);
        if (!allowNegativeSaldo) {
            const hasNegativeSaldo = selectedToJournal.some(
                (v: TableItemExtra<GenericRecord>) => v.extra && v.extra.saldo < 0
            );
            if (hasNegativeSaldo) {
                return [];
            }
        }
        const hasDraft = selectedToJournal.some(v => v.item.draft);
        if (hasDraft) {
            return [];
        }
        if (this._filterSequentional(selectedToJournal).length === 0) {
            return [];
        }
        let firstNonJournaledKey: string = null;
        if (allRecords.length === 1) {
            if (!allRecords[0].journaled) {
                firstNonJournaledKey = allRecords[0].key;
            }
        } else if (allRecords.length > 1) {
            let lastJournaledIdx = -1;
            for (let i = 0; i < allRecords.length; i++) {
                if (!allRecords[i].journaled) {
                    break;
                }
                lastJournaledIdx = i;
            }
            if (lastJournaledIdx >= -1 && lastJournaledIdx < allRecords.length - 1) {
                // console.log(allRecords[lastJournaledIdx + 1]);
                firstNonJournaledKey = allRecords[lastJournaledIdx + 1].key;
            }
        }
        if (firstNonJournaledKey === null) {
            return [];
        }

        if (selectedToJournal.length === 0) {
            return [];
        }

        if (selectedToJournal[0].item.key === firstNonJournaledKey) {
            return selectedToJournal;
        }
        return [];
    }

    getSelectedToMove(vs: TableItem<GenericRecord>[]): TableItem<GenericRecord>[] {
        let selectedToMove = vs.filter(v => v.selected);
        for (const ti of selectedToMove) {
            if (ti.item.journaled || Boolean(ti.item.cancellation)) {
                return [];
            }
        }
        selectedToMove = this._filterSequentional(selectedToMove);

        return selectedToMove;
    }

    getSelectedToMoveNext(
        tableItems: TableItem<GenericRecord>[],
        selectedToMove: TableItem<GenericRecord>[]
    ): GenericRecord {
        if (selectedToMove.length === 0) {
            return null;
        }
        const item = selectedToMove[selectedToMove.length - 1];
        if (item.key === tableItems.length - 1) {
            return null;
        }
        const next = tableItems[item.key + 1];
        if (!next) {
            return null;
        }
        const currentDayEnd = new Date(
            item.item.date.getFullYear(),
            item.item.date.getMonth(),
            item.item.date.getDate() + 1
        );
        if (currentDayEnd <= next.item.date) {
            return null;
        }
        return next.item;
    }

    getSelectedToMovePrev(
        tableItems: TableItem<GenericRecord>[],
        selectedToMove: TableItem<GenericRecord>[]
    ): GenericRecord {
        if (selectedToMove.length === 0) {
            return null;
        }
        const item = selectedToMove[0];
        if (item.key === 0) {
            return null;
        }
        const previous = tableItems[item.key - 1];
        if (!previous) {
            return null;
        }
        if (previous.item.journaled) {
            return null;
        }
        const currentDayStart = new Date(
            item.item.date.getFullYear(),
            item.item.date.getMonth(),
            item.item.date.getDate()
        );
        if (currentDayStart > previous.item.date) {
            return null;
        }
        return previous.item;
    }

    getItemsToSort(tableItems: TableItem<GenericRecord>[]) {
        const l = tableItems.length;
        if (l === 0) {
            return [];
        }
        if (tableItems[l - 1].item.journaled) {
            return [];
        }
        const journaled = tableItems.filter(v => v.item.journaled);
        const startIdx = journaled.length ? journaled[journaled.length - 1].key + 1 : 0;
        const sortingCandidates = tableItems.slice(startIdx, l);
        let needToSort = false;
        let prevDate = this._getDayFixedDate(sortingCandidates[0].item);
        sortingCandidates.slice(1).forEach((v: TableItemExtra<Receipt>) => {
            const currentFixDate = this._getDayFixedDate(v.item);
            if (!needToSort && (prevDate > currentFixDate || v.extra.saldo < 0)) {
                needToSort = true;
            }
            prevDate = currentFixDate;
        });
        if (!needToSort) {
            return [];
        }
        return sortingCandidates;
    }

    protected _filterSequentional(selectedToJournal: TableItem<GenericRecord>[]): TableItem<GenericRecord>[] {
        let isSeqentional = false;
        if (selectedToJournal.length === 0) {
            return [];
        } else if (selectedToJournal.length === 1) {
            isSeqentional = true;
            // check if selected items are sequential
        } else if (selectedToJournal.length > 1) {
            isSeqentional = true;
            let prevIdx = selectedToJournal[0].key;
            selectedToJournal.slice(1).forEach(v => {
                if (isSeqentional && prevIdx !== v.key - 1) {
                    isSeqentional = false;
                }
                prevIdx = v.key;
            });
        }
        if (!isSeqentional) {
            return [];
        }
        return selectedToJournal;
    }

    protected _getDayFixedDate(v: GenericRecord) {
        const d = new Date();
        d.setFullYear(v.date.getFullYear(), v.date.getMonth(), v.date.getDate());
        return d;
    }
}
