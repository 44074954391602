import { PDFDocument } from "pdf-lib";
import { extractAttachments } from "@pdf-tools/tools/extractAttachments";
import { convertXML } from "simple-xml-to-json";
import { Bu, GQL, Periods, Utils } from "@binale-tech/shared";
import { GenericItem, GenericRecord } from "../../../scripts/models";
import { IDocumentSuggestion } from "@dms/types";
import { DmsAccountingConverter } from "../../../scripts/models/converters/DmsAccountingConverter";

const findNodes = (node: Record<string, any>[], key: string): Record<string, any>[] => {
    return node?.filter((node: Record<string, any>) => Object.keys(node ?? {})[0] === key).map(v => v?.[key]) ?? [];
};
const findNode = (node: Record<string, any>[], key: string): Record<string, any> => {
    return findNodes(node, key)[0];
};
const getChild = (node: Record<string, any>[], key: string): Record<string, any>[] =>
    findNode(node, key)?.children ?? [];
const getChilds = (node: Record<string, any>[], key: string): Record<string, any>[][] =>
    findNodes(node, key).map(v => v.children);

export type XRechnungResponse = {
    record: GenericRecord;
    document: IDocumentSuggestion;
    raw: {
        currency: string;
        vatId?: string;
        date?: { year: number; period: number; day: number };
        taxItems: { tax: number; amount: number }[];
        lines: { tax: number; bu: number; amount: number; text: string }[];
    };
};
export const decodePdfInvoiceData = async (
    pdfBytes: Uint8Array | ArrayBuffer,
    pk: GQL.IProductKey
): Promise<XRechnungResponse> => {
    const pdfDocText = await PDFDocument.load(pdfBytes);
    const attachments = extractAttachments(pdfDocText);
    const invoice = attachments.filter(a => a.name.toLowerCase().endsWith(".xml"));
    if (invoice.length !== 1) {
        return undefined;
    }

    const useSplitBF1 = Utils.ModuleUtils.useBelegfeld1Split(pk);
    const decodedXml = convertXML(new TextDecoder().decode(invoice[0].data));

    const decodedInv = decodedXml?.["rsm:CrossIndustryInvoice"]?.children;
    const exchangedDocument = getChild(decodedInv, "rsm:ExchangedDocument");
    const invoiceDate: string = findNode(
        getChild(exchangedDocument, "ram:IssueDateTime"),
        "udt:DateTimeString"
    )?.content;
    const invoiceNumber = findNode(exchangedDocument, "ram:ID")?.content;

    const invoiceDateParsed = invoiceDate
        ? {
              year: Number(invoiceDate.substring(0, 4)),
              period: Number(invoiceDate.substring(4, 6)),
              day: Number(invoiceDate.substring(6, 8)),
          }
        : undefined;

    const transaction = getChild(decodedInv, "rsm:SupplyChainTradeTransaction");

    const settlment = getChild(transaction, "ram:ApplicableHeaderTradeSettlement");
    const getBu = (tax: number): Bu.Bu => {
        // todo reverse charge
        if (tax === 7) {
            if (pk === GQL.IProductKey.Deb) {
                return Bu.Bu.BU2;
            }
            return Bu.Bu.BU8;
        }
        if (tax === 19) {
            if (pk === GQL.IProductKey.Deb) {
                return Bu.Bu.BU3;
            }
            return Bu.Bu.BU9;
        }
        return Bu.Bu.KU;
    };
    const currency = findNode(settlment, "ram:InvoiceCurrencyCode")?.content ?? "EUR";
    const taxes = getChilds(settlment, "ram:ApplicableTradeTax");
    const taxItems = taxes.map(t => {
        const tax = Number(findNode(t, "ram:RateApplicablePercent")?.content ?? "0");
        const nettoAmount = Number(findNode(t, "ram:BasisAmount")?.content ?? "0") * 100;
        const taxAmount = Number(findNode(t, "ram:CalculatedAmount")?.content ?? "0") * 100;
        return { tax, amount: nettoAmount + taxAmount, bu: getBu(tax) };
    });
    const genericItems = taxItems.map(({ bu, amount }) => {
        return new GenericItem({
            brutto: amount,
            bu,
            originalAmount: currency !== GQL.ICurrencyCode.Eur ? amount : undefined,
            belegfeld1: useSplitBF1 ? invoiceNumber : undefined,
        });
    });
    const lineItems = getChilds(transaction, "ram:IncludedSupplyChainTradeLineItem");
    const agreement = getChild(transaction, "ram:ApplicableHeaderTradeAgreement");
    const seller = getChild(agreement, "ram:SellerTradeParty");
    const tax = getChild(seller, "ram:SpecifiedTaxRegistration");
    const vatId = findNode(tax, "ram:ID")?.content;

    const lines = lineItems.map(v => {
        const settlement = getChild(v, "ram:SpecifiedLineTradeSettlement");
        const tax = getChild(settlement, "ram:ApplicableTradeTax");
        const percent = Number(findNode(tax, "ram:RateApplicablePercent")?.content ?? "0");
        const summation = getChild(settlement, "ram:SpecifiedTradeSettlementLineMonetarySummation");
        const amount = Number(findNode(summation, "ram:LineTotalAmount")?.content ?? "0") * 100;
        const text = findNode(getChild(v, "ram:SpecifiedTradeProduct"), "ram:Name")?.content;
        return { text, amount, tax: percent, bu: getBu(percent) };
    });
    // console.log(decodedInv);

    const text1 = genericItems.length === 1 ? lines[0].text : undefined;
    if (text1) {
        genericItems[0].text = text1;
    }
    const date = invoiceDateParsed
        ? Periods.constructDate(invoiceDateParsed.year, invoiceDateParsed.period, invoiceDateParsed.day)
        : undefined;

    const record = new GenericRecord({
        date,
        num: useSplitBF1 ? undefined : invoiceNumber,
        year: invoiceDateParsed.year,
        period: invoiceDateParsed.period,
        day: invoiceDateParsed.day,
        items: genericItems,
        currency: currency !== GQL.ICurrencyCode.Eur ? { rate: 1, code: currency } : undefined,
    });
    record.originalAmount = record.getOriginalAmount();

    const suggestion = DmsAccountingConverter.convertRecordToDms(record, pk);

    return { record, document: suggestion, raw: { taxItems, lines, date: invoiceDateParsed, currency, vatId } };
};
