import { IDecoderResponse, OcrDocument, OcrPage, PdfParkingState } from "../../types";
import { DropResult } from "react-beautiful-dnd";

export const fileToArrayBuffer = (f: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(f);
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = error => reject(error);
    });
};

export const toImageUrl = (imageData: ImageData) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    ctx.putImageData(imageData, 0, 0);
    return canvas.toDataURL("image/jpeg", 0.75);
};

// pageIds - only first pages of document will be picked and upper document would be unified.
export const unifyDocuments = (pageIds: string[], documents: OcrDocument[]): OcrDocument[] => {
    const newDocs: OcrDocument[] = [...documents];
    pageIds.forEach(pageId => {
        const docIndex = newDocs.findIndex(doc => doc.pageIds.includes(pageId) && doc.pageIds.indexOf(pageId) === 0);
        const document = newDocs[docIndex];
        if (document && docIndex !== 0) {
            const previousDoc = newDocs[docIndex - 1];
            newDocs[docIndex - 1] = {
                ...previousDoc,
                pageIds: [...previousDoc.pageIds, ...document.pageIds],
            };
            newDocs.splice(docIndex, 1);
        }
    });
    return newDocs;
};

// pageIds - lower pages would be picked. If I pick 2 page (while doc contains 1,2,3,4 pages), the cut will pass between 1 and 2 pages)
export const splitDocuments = (pageIds: string[], documents: OcrDocument[]): OcrDocument[] => {
    const newDocs = [...documents];
    pageIds.forEach(pageId => {
        const docIndex = newDocs.findIndex(doc => doc.pageIds.includes(pageId) && doc.pageIds.indexOf(pageId) !== 0);
        const document = newDocs[docIndex];
        if (document) {
            const pageIndex = document.pageIds.indexOf(pageId);
            newDocs.splice(docIndex, 0, {
                id: crypto.randomUUID(),
                pageIds: document.pageIds.slice(0, pageIndex),
            });

            newDocs[docIndex + 1] = {
                ...document,
                pageIds: document.pageIds.slice(pageIndex, document.pageIds.length),
            };
        }
    });
    return newDocs;
};

class DocumentsList {
    docs: OcrDocument[];

    constructor(docs: OcrDocument[]) {
        this.docs = docs;
    }

    getAllPagesCount() {
        return this.docs.reduce((acc, item) => acc + item.pageIds.length, 0);
    }

    getDocByPageId(id: string) {
        return this.docs.find(doc => doc.pageIds.includes(id));
    }

    getDocIndex(id: string) {
        return this.docs.findIndex(doc => doc.id === id);
    }

    getPageIdByOrderNumber(index: number) {
        const pageIds = this.docs.reduce((acc, item) => acc.concat(item.pageIds), []);
        return pageIds[index];
    }

    getPageIndexByOrderNumber(index: number) {
        const pageId = this.getPageIdByOrderNumber(index);
        let pageIdx: number | null = null;
        this.docs.forEach(doc => {
            const idx = doc.pageIds.indexOf(pageId);
            if (idx !== -1) {
                pageIdx = idx;
            }
        });
        return pageIdx;
    }

    insertToDoc(docId: string, pageId: string, idx: number) {
        this.docs = this.docs.map(doc => {
            if (doc.id === docId) {
                doc.pageIds.splice(idx, 0, pageId);
                return { ...doc };
            } else {
                return doc;
            }
        });
    }

    insertDocToDoc(docId: string, pageIds: string[], idx: number) {
        const pageIdx = this.getPageIndexByOrderNumber(idx);
        this.docs = this.docs.map(doc => {
            if (doc.id === docId) {
                const arr1 = doc.pageIds.slice(0, pageIdx);
                const arr2 = doc.pageIds.slice(pageIdx);
                doc.pageIds = [...arr1, ...pageIds, ...arr2];
                return { ...doc };
            } else {
                return doc;
            }
        });
    }

    removeFromDoc(pageId: string) {
        this.docs = this.docs
            .map(doc => ({
                ...doc,
                pageIds: doc.pageIds.filter(id => id !== pageId),
            }))
            .filter(doc => doc.pageIds.length);
    }

    removeDoc(docId: string) {
        this.docs = this.docs.filter(doc => doc.id !== docId);
    }

    insertDoc(doc: OcrDocument, index: number) {
        this.docs.splice(index, 0, doc);
    }

    isInsertInsideDocument(prevDoc: OcrDocument, prevPageId: string) {
        if (prevDoc.pageIds.length <= 1) {
            return false;
        }

        return prevDoc && prevDoc.pageIds.indexOf(prevPageId) !== prevDoc.pageIds.length - 1;
    }
}

export const reorderPages = (oldIndex: number, newIndex: number, documents: OcrDocument[]): OcrDocument[] => {
    const docList = new DocumentsList(documents.map(doc => ({ ...doc })));
    const oldPageId = docList.getPageIdByOrderNumber(oldIndex);
    const newPageId = docList.getPageIdByOrderNumber(newIndex);
    const oldDoc = docList.getDocByPageId(oldPageId);
    const newDoc = docList.getDocByPageId(newPageId);

    if (oldDoc.id === newDoc.id) {
        const indexToInsert = docList.getPageIndexByOrderNumber(newIndex);
        if (indexToInsert !== null) {
            docList.removeFromDoc(oldPageId);
            docList.insertToDoc(oldDoc.id, oldPageId, indexToInsert);
        }
        return docList.docs;
    }

    const isFirstPage = newDoc.pageIds.indexOf(newPageId) === 0 && newIndex < oldIndex;
    const isLastPage = newDoc.pageIds.indexOf(newPageId) === newDoc.pageIds.length - 1 && newIndex > oldIndex;

    if (oldDoc.pageIds.length === 1 && (isFirstPage || isLastPage)) {
        const newDocIndex = docList.getDocIndex(newDoc.id);
        docList.removeDoc(oldDoc.id);
        docList.insertDoc(oldDoc, newDocIndex);
        return docList.docs;
    }

    if (isFirstPage || isLastPage) {
        const newDocIndex = docList.getDocIndex(newDoc.id);
        docList.removeFromDoc(oldPageId);
        const newDocument: OcrDocument = {
            id: crypto.randomUUID(),
            pageIds: [oldPageId],
        };
        docList.insertDoc(newDocument, newIndex > oldIndex ? newDocIndex + 1 : newDocIndex);
        return docList.docs;
    }

    const indexToInsert = docList.getPageIndexByOrderNumber(newIndex);
    if (indexToInsert !== null) {
        docList.removeFromDoc(oldPageId);
        docList.insertToDoc(newDoc.id, oldPageId, newIndex > oldIndex ? indexToInsert + 1 : indexToInsert);
    }

    return docList.docs;
};

export const moveToList = ({
    e,
    documents,
    currentParkingState,
}: {
    e: DropResult;
    documents: OcrDocument[];
    currentParkingState: PdfParkingState | IDecoderResponse;
}) => {
    const uploadedDoc = currentParkingState.parsedDocuments.find(el => el.id === e.draggableId);
    const newIndex = e.destination.index - 1;

    const docList = new DocumentsList(documents.map(doc => ({ ...doc })));
    const parkedDocumentArr = currentParkingState.parsedDocuments.filter(el => el.id !== e.draggableId);

    const pageToList: OcrPage[] = [];
    const pageToParking: OcrPage[] = [];

    currentParkingState.parsedPages.filter(el => {
        if (uploadedDoc.pageIds.includes(el.id)) {
            pageToList.push(el);
        } else {
            pageToParking.push(el);
        }
    });

    if (newIndex >= docList.getAllPagesCount() || newIndex === 0) {
        docList.insertDoc(uploadedDoc, newIndex);
        return {
            documentList: docList.docs,
            parsedPages: pageToList,
            parkedDocuments: {
                parsedDocuments: parkedDocumentArr,
                parsedPages: pageToParking,
            },
        };
    }

    const prevPageIndex = newIndex - 1;
    const prevPageId = docList.getPageIdByOrderNumber(prevPageIndex);
    const prevDoc = docList.getDocByPageId(prevPageId);

    const isInsertInsideDocument = docList.isInsertInsideDocument(prevDoc, prevPageId);

    if (isInsertInsideDocument) {
        docList.insertDocToDoc(prevDoc.id, uploadedDoc.pageIds, newIndex);
    } else {
        const newDocIndex = docList.getDocIndex(prevDoc.id) + 1;
        docList.insertDoc(uploadedDoc, newDocIndex);
    }

    const documentList = docList.docs;

    return {
        documentList,
        parsedPages: pageToList,
        parkedDocuments: {
            parsedDocuments: parkedDocumentArr,
            parsedPages: pageToParking,
        },
    };
};

export const parkPages = ({
    e,
    documents,
    pages,
    currentParkingState,
}: {
    e: DropResult;
    documents: OcrDocument[];
    pages: OcrPage[];
    currentParkingState: PdfParkingState;
}) => {
    const docList = new DocumentsList(documents.map(doc => ({ ...doc })));

    const newDoc = {
        id: crypto.randomUUID(),
        pageIds: [e.draggableId],
    };

    const parsedPages = currentParkingState?.parsedPages ?? [];
    const parsedDocuments = currentParkingState?.parsedDocuments ?? [];

    const newParkingState: PdfParkingState = {
        parsedPages: [...parsedPages, ...pages.filter(el => el.id === e.draggableId)],
        parsedDocuments: [...parsedDocuments, newDoc],
        newFile: currentParkingState?.newFile,
    };

    docList.removeFromDoc(e.draggableId);

    const documentList = docList.docs;
    const pageArr = pages.filter(el => el.id !== e.draggableId);

    return { docs: { documentList, parsedPages: pageArr }, parkingDocs: newParkingState };
};

export const parkNewPages = ({
    e,
    uploadedState,
    currentParkingState,
}: {
    e: DropResult;
    uploadedState: IDecoderResponse;
    currentParkingState: PdfParkingState;
}) => {
    const parsedPages = currentParkingState?.parsedPages ?? [];
    const parsedDocuments = currentParkingState?.parsedDocuments ?? [];

    const newPages = uploadedState?.parsedPages ?? [];
    const newDocuments = uploadedState?.parsedDocuments ?? [];

    const newParkingState: PdfParkingState = {
        parsedPages: [...parsedPages, ...newPages],
        parsedDocuments: [...parsedDocuments, ...newDocuments],
        newFile: currentParkingState?.newFile
            ? [...currentParkingState?.newFile, uploadedState?.newFile]
            : [uploadedState?.newFile],
    };

    return { parkingDocs: newParkingState };
};

export const removeDocumentFromParking = ({
    id,
    currentParkingState,
}: {
    id: string;
    currentParkingState: PdfParkingState;
}) => {
    const removedDocument = currentParkingState.parsedDocuments.find(el => el.id === id);
    const removedPageIds = removedDocument ? removedDocument?.pageIds : [];

    const parkingDocumentArr = currentParkingState.parsedDocuments.filter(el => el.id !== id);
    const parkingPageArr = currentParkingState.parsedPages.filter(el => !removedPageIds.includes(el.id));
    const parkingFileIds = parkingPageArr.map(el => el.fileId);

    const newFileArr = currentParkingState?.newFile.filter(el => parkingFileIds.includes(el.id));

    const newFile = newFileArr.length ? newFileArr : null;

    return { parsedPages: parkingPageArr, parsedDocuments: parkingDocumentArr, newFile };
};
