import React, { type FC, type Key, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { notification } from "antd";

import { GroupDocumentLayout } from "@dms/components/DocumentLayout/GroupDocumentLayout";
import { SingleDocumentLayout } from "@dms/components/DocumentLayout/SingleDocumentLayout";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";

import { FormWrapperProvider } from "../DocumentFormModule/context/FormWrapperContext";
import { CarouselProvider } from "@dms/modules/DocumentFormModule/context/CarouselContext";
import { IDocumentEnriched } from "@dms/types";

import "./style.scss";

export const DocumentModule: FC = () => {
    const [documentId, setDocumentId] = useState<Key | null>(null);
    const [documentData, setDocumentData] = useState<IDocumentEnriched>();
    const { selectedRowKeys } = useContext(DmsAppContext);
    const { setFocusedRow } = useContext(DmsAppControlContext);
    const { documentsKV } = useContext(DmsDataContext);

    const [api, contextHolder] = notification.useNotification();

    const documentDataLoaded = useRef(false);

    const location = useLocation();
    const isGroup = location?.state?.isGroup;
    const singleDocumentId = location?.state?.documentId;

    useEffect(() => {
        if (isGroup) {
            return setDocumentId(null);
        }
        if (singleDocumentId) {
            const foundDocument = documentsKV[singleDocumentId];

            setDocumentId(singleDocumentId);
            setFocusedRow(singleDocumentId);
            setDocumentData(foundDocument);
        }
    }, [singleDocumentId, documentsKV, isGroup, setFocusedRow]);

    useEffect(() => {
        if (selectedRowKeys.length !== 0) {
            setDocumentId(selectedRowKeys[0]);
        }
    }, [selectedRowKeys]);

    useEffect(() => {
        if (documentData) {
            documentDataLoaded.current = true;
        }
    }, [documentData]);

    useEffect(() => {
        if (!documentData && documentId && documentDataLoaded.current) {
            api.info({
                message: "The file has been deleted",
                description: "This file has been deleted by another user",
                placement: "top",
            });
        }
    }, [documentId, documentData, api]);

    return (
        <div>
            {contextHolder}
            <FormWrapperProvider isGroup={isGroup}>
                {documentData && !isGroup ? <SingleDocumentLayout documentData={documentData} /> : null}
                {isGroup ? (
                    <CarouselProvider>
                        <GroupDocumentLayout />
                    </CarouselProvider>
                ) : null}
            </FormWrapperProvider>
        </div>
    );
};
