import React from "react";
import { MenuProps } from "antd/es/menu";
import { Route, Routes } from "react-router-dom";

import ModulePDFLinks from "./ModulePDFLinks";
import { AppRoutes, getRoute, ModuleSubGroups } from "scripts/routing/routeConstants";
import { ModuleBankLinks } from "./ModuleBankLinks";
import { ModuleCommonImportExportLinks } from "./ModuleCommonImportExportLinks";
import { ModuleCommonKontoControlLinks } from "./ModuleCommonKontoControlLinks";
import { ModuleDebLinks } from "./ModuleDebLinks";
import { ModuleERLinks } from "./ModuleERLinks";
import { ModuleFELinks } from "./ModuleFELinks";
import { ModuleKBLinks } from "./ModuleKBLinks";
import { ModuleLALinks } from "./ModuleLALinks";
import { ModulePOSLinks } from "./ModulePOSLinks";
import ModuleUStVALinks from "./ModuleUStVALinks";

const menuProps: MenuProps = { triggerSubMenuAction: "click", mode: "horizontal" };

const Index: React.FC = () => {
    return (
        <Routes>
            <Route
                path={getRoute(AppRoutes.invoiceModule, { subGroup: ModuleSubGroups.ER }) + "/*"}
                element={<ModuleERLinks menuProps={menuProps} isAZ={false} />}
            />
            <Route
                path={getRoute(AppRoutes.invoiceModule, { subGroup: ModuleSubGroups.ErA }) + "/*"}
                element={<ModuleERLinks menuProps={menuProps} isAZ={true} />}
            />
            <Route path={AppRoutes.debitor + "/*"} element={<ModuleDebLinks menuProps={menuProps} />} />
            <Route path={AppRoutes.kassenbuch + "/*"} element={<ModuleKBLinks menuProps={menuProps} />} />
            <Route path={AppRoutes.bank + "/*"} element={<ModuleBankLinks menuProps={menuProps} />} />
            <Route path={AppRoutes.freieErfassung + "/*"} element={<ModuleFELinks menuProps={menuProps} />} />
            <Route path={AppRoutes.pos + "/*"} element={<ModulePOSLinks menuProps={menuProps} />} />
            <Route path={AppRoutes.paySlip + "/*"} element={<ModuleLALinks menuProps={menuProps} />} />
            <Route
                path={AppRoutes.manageAccount + "/*"}
                element={<ModuleCommonKontoControlLinks menuProps={menuProps} />}
            />
            <Route
                path={AppRoutes.recordImportExport + "/*"}
                element={<ModuleCommonImportExportLinks menuProps={menuProps} />}
            />
            <Route path={AppRoutes.ocr + "/*"} element={<ModulePDFLinks menuProps={menuProps} />} />
            <Route path={AppRoutes.UStVA + "/*"} element={<ModuleUStVALinks menuProps={menuProps} />} />
        </Routes>
    );
};

export default React.memo(Index);
