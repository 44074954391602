import React, { useMemo, useState } from "react";
import { Button, TableColumnType, Tag, Tooltip } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Contacts, GQL } from "@binale-tech/shared";
import { FormattedMessage, useIntl } from "react-intl";
import { getName } from "i18n-iso-countries";

import { ClassificationField, CountriesField, StringField, TypeField } from "./columnsFilters";
import { getContactVatPrint } from "@app/views/productContacts/form/utils";

export enum ContactColumns {
    InternalName = "InternalName",
    Type = "Type",
    TypeName = "TypeName",
    Classification = "Classification",
    CustomerNumber = "CustomerNumber",
    Countries = "Countries",
    Cities = "Cities",
    IBAN = "IBAN",
    VAT = "VAT",
    ContactPersons = "ContactPersons",
    Actions = "Actions",
}

export class ContactChild {
    constructor(
        readonly uuid: string,
        readonly relations: GQL.IContactRelationItem[]
    ) {}
}

export interface Filters {
    [ContactColumns.InternalName]?: string;
    [ContactColumns.Type]?: GQL.IContactType;
    [ContactColumns.TypeName]?: string;
    [ContactColumns.Classification]?: GQL.IContactRelation[];
    [ContactColumns.CustomerNumber]?: string;
    [ContactColumns.Countries]?: string;
    [ContactColumns.Cities]?: string;
    [ContactColumns.IBAN]?: string;
    [ContactColumns.VAT]?: string;
    [ContactColumns.ContactPersons]?: string;
}

const TypeColors: Record<GQL.IContactRelation, React.ComponentProps<typeof Tag>["color"]> = {
    [GQL.IContactRelation.Client]: "cyan",
    [GQL.IContactRelation.Employee]: "purple",
    [GQL.IContactRelation.Other]: "green",
    [GQL.IContactRelation.Partner]: "red",
    [GQL.IContactRelation.Shareholder]: "geekblue",
};

const renderFromAddress = (
    renderer: (address: GQL.IContactAddress) => React.ReactNode,
    addresses?: GQL.IContactAddress[]
) => {
    const addressToShow =
        addresses?.find(address => address.isPrimary) || (addresses?.length === 1 ? addresses[0] : null);

    return addressToShow ? renderer(addressToShow) : addresses?.length > 1 ? "diverse" : null;
};

export const useContactHeader = () => {
    const [filters, setFilters] = useState<Filters>({});
    const intl = useIntl();

    const contactColumns: Record<ContactColumns, TableColumnType<Contacts.Contact>> = useMemo(() => {
        return {
            [ContactColumns.InternalName]: {
                key: ContactColumns.InternalName,
                title: (
                    <StringField
                        value={filters.InternalName}
                        onChange={value => setFilters({ ...filters, [ContactColumns.InternalName]: value })}
                        minWidth={150}
                        label={<FormattedMessage id="app.fields.internalDesignation" />}
                    />
                ),
                dataIndex: "internalName",
                fixed: "left",
                width: 250,
            },
            [ContactColumns.Type]: {
                key: ContactColumns.Type,
                title: (
                    <TypeField
                        value={filters.Type}
                        onChange={value => setFilters({ ...filters, [ContactColumns.Type]: value })}
                    />
                ),
                width: 170,
                render: (record: Contacts.Contact) =>
                    record.type ? <FormattedMessage id={`app.contacts.type.${record.type}`} /> : "",
            },
            [ContactColumns.TypeName]: {
                key: ContactColumns.TypeName,
                title: (
                    <StringField
                        value={filters.TypeName}
                        onChange={value => setFilters({ ...filters, [ContactColumns.TypeName]: value })}
                        minWidth={150}
                        label={<FormattedMessage id="app.fields.name" />}
                    />
                ),
                width: 200,
                render: record => {
                    if (record instanceof ContactChild) {
                        return null;
                    }
                    return <div>{Contacts.getLabelName(record)}</div>;
                },
            },
            [ContactColumns.Classification]: {
                key: ContactColumns.Classification,
                title: (
                    <ClassificationField
                        value={filters.Classification}
                        onChange={value => setFilters({ ...filters, [ContactColumns.Classification]: value })}
                    />
                ),
                width: 170,
                render: ({ relations }: Contacts.Contact) => {
                    const tagRenderer = (relation: GQL.IContactRelationItem, renderWithName = false) => {
                        const title = intl.formatMessage({ id: `app.titles.contacts.${relation.type}` });
                        return (
                            <span key={relation.type} className="ContactTable__Column__Classification">
                                <Tag color={TypeColors[relation.type]}>
                                    <b>{title.substring(1, 0)}</b>
                                </Tag>
                                {renderWithName && title}
                            </span>
                        );
                    };
                    if (relations.length === 1) {
                        return tagRenderer(relations[0], true);
                    }
                    return relations.map(r => tagRenderer(r));
                },
            },
            [ContactColumns.CustomerNumber]: {
                key: ContactColumns.CustomerNumber,
                title: (
                    <StringField
                        value={filters.CustomerNumber}
                        onChange={value => setFilters({ ...filters, [ContactColumns.CustomerNumber]: value })}
                        minWidth={150}
                        label={<FormattedMessage id="app.fields.customerNumber" />}
                    />
                ),
                width: 200,
                render: ({ relations }: Contacts.Contact) => {
                    if (relations.length === 1) {
                        return <div key={relations[0].type}>{relations[0].internalNumber}</div>;
                    }
                    return relations.length > 1 ? "diverse" : "";
                },
            },
            [ContactColumns.Countries]: {
                key: ContactColumns.Countries,
                title: (
                    <CountriesField
                        value={filters.Countries}
                        onChange={value => setFilters({ ...filters, [ContactColumns.Countries]: value })}
                    />
                ),
                width: 150,
                render: ({ addresses }: Contacts.Contact) =>
                    renderFromAddress(address => getName(address.countryCode, intl.locale), addresses),
            },
            [ContactColumns.Cities]: {
                key: ContactColumns.Cities,
                title: (
                    <StringField
                        value={filters.Cities}
                        onChange={value => setFilters({ ...filters, [ContactColumns.Cities]: value })}
                        minWidth={145}
                        label={<FormattedMessage id="app.fields.city" />}
                    />
                ),
                width: 160,
                render: ({ addresses }: Contacts.Contact) => renderFromAddress(address => address.city, addresses),
            },
            [ContactColumns.VAT]: {
                key: ContactColumns.VAT,
                title: (
                    <StringField
                        value={filters.VAT}
                        onChange={value => setFilters({ ...filters, [ContactColumns.VAT]: value })}
                        minWidth={150}
                        label={<FormattedMessage id={"app.fields.vatNb"} />}
                    />
                ),
                width: 200,
                render: ({ legalInfo }: Contacts.Contact) => getContactVatPrint(legalInfo),
            },
            [ContactColumns.IBAN]: {
                key: ContactColumns.IBAN,
                title: (
                    <StringField
                        value={filters.IBAN}
                        onChange={value => setFilters({ ...filters, [ContactColumns.IBAN]: value })}
                        minWidth={200}
                        label="IBAN"
                    />
                ),
                width: 250,
                render: ({ banks }: Contacts.Contact) => {
                    const allIBANs =
                        banks?.reduce((acc, bank) => {
                            if (bank?.IBAN) {
                                acc.push(bank.IBAN);
                            }
                            return acc;
                        }, [] as string[]) || [];

                    return allIBANs?.length > 1 ? "diverse" : allIBANs[0] || "";
                },
            },
            [ContactColumns.ContactPersons]: {
                key: ContactColumns.ContactPersons,
                width: 250,
                title: (
                    <StringField
                        value={filters.ContactPersons}
                        onChange={value => setFilters({ ...filters, [ContactColumns.ContactPersons]: value })}
                        minWidth={200}
                        label={<FormattedMessage id="app.titles.contactPerson" />}
                    />
                ),
                render: ({ contactPersons }: Contacts.Contact) => {
                    const filtered = contactPersons?.filter(person => person.firstName || person.lastName) || [];
                    return filtered?.map((person, index) => (
                        <div key={index}>
                            {person.appeal && Contacts.ContactConstants.GenderTranslate[person.appeal]}
                            &nbsp;
                            {person.firstName || ""} {person.lastName || ""}
                        </div>
                    ));
                },
            },
            [ContactColumns.Actions]: {
                key: ContactColumns.Actions,
                title: (
                    <>
                        <br />
                        <Tooltip title="Clear filters">
                            <Button
                                type="text"
                                shape="circle"
                                onClick={() => {
                                    setFilters({});
                                }}
                            >
                                <CloseCircleOutlined />
                            </Button>
                        </Tooltip>
                    </>
                ),
                width: 100,
                render: () => null,
            },
        };
    }, [intl, filters, setFilters]);

    return useMemo(
        () => ({
            filters,
            contactColumns,
        }),
        [filters, contactColumns]
    );
};
