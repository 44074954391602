import React, { useContext } from "react";
import { Button, Result, ResultProps } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { AppError, AppSettingsContext } from "scripts/context/AppSettingsProvider";
import "./ErrorViews.css";

export const ErrorView: React.FC = () => {
    const { appError } = useContext(AppSettingsContext);

    const commonError = {
        title: <FormattedMessage id="app.error_page.title.problem_with_db" />,
        subTitle: (
            <div className="tos-result-page--list">
                <div>
                    1. <FormattedMessage id="app.error_page.fix_problems_with_db.1" />
                </div>
                <div>
                    2. <FormattedMessage id="app.error_page.fix_problems_with_db.2" />
                </div>
                <div>
                    3.{" "}
                    <FormattedMessage
                        id="app.error_page.fix_problems_with_db.3"
                        values={{ email: <a href="mailto:support@binale.de">support@binale.de</a> }}
                    />
                </div>
            </div>
        ),
    };

    const resultProps: Record<AppError, ResultProps> = {
        [AppError.FirebaseConnectionError]: commonError,
        [AppError.IndexedDB]: commonError,
        [AppError.ReactRendering]: {
            title: <FormattedMessage id="app.error_page.title.something_went_wrong" />,
            subTitle: <FormattedMessage id="app.error_page.we_already_fix_it" />,
        },
    };
    return (
        <Result
            status="500"
            className="tos-result-page"
            extra={
                <Button type="primary" onClick={() => location.reload()}>
                    <FormattedMessage id="app.error_page.button.reload_page" />
                </Button>
            }
            {...resultProps[appError]}
        />
    );
};

export const NotFoundView = () => (
    <Result
        status="404"
        title={<FormattedMessage id="app.error_page.not_found" />}
        extra={
            <Link to="/">
                <Button type="primary">
                    <FormattedMessage id="app.error_page.button.back_home" />
                </Button>
            </Link>
        }
    />
);
