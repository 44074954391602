import { useCallback, useContext } from "react";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { useFormValidators } from "../useFormValidators";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";

export const useFormHandlerItemText2 = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setItemFieldErrorState } = useFormValidators();
    const { updateEditableItem } = useFormRecordStateUpdater();
    return useCallback(
        (itemText2: string) => {
            updateEditableItem({ itemText2 });
            setItemFieldErrorState(refsHtml.REF_iTEXT2, false);
        },
        [refsHtml, updateEditableItem, setItemFieldErrorState]
    );
};
