import { useCallback, useContext } from "react";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";
import { RecordFormStateControlContext } from "../../context/RecordFormState";
import { useFormValidators } from "../useFormValidators";

export const useFormHandlerNetto = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setRecordVirtualNetto } = useContext(RecordFormStateControlContext);
    const { setRecordFieldErrorState } = useFormValidators();

    return useCallback(
        (amount: number) => {
            setRecordVirtualNetto(amount);
            setRecordFieldErrorState(refsHtml.REF_rNETTO, false);
        },
        [setRecordVirtualNetto, setRecordFieldErrorState, refsHtml.REF_rNETTO]
    );
};
