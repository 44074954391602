import { useCallback, useContext, useMemo } from "react";
import RecordFormState from "../types/RecordFormState";
import { RecordFormStateControlContext } from "../context/RecordFormState";

export const useFormRecordStateUpdater = () => {
    const { setEditableRecord, setEditableRecordItem } = useContext(RecordFormStateControlContext);
    const updateEditableRecord = useCallback(
        (update: Partial<RecordFormState["editableRecord"]>) => {
            setEditableRecord(prevState => {
                return { ...prevState, ...update };
            });
        },
        [setEditableRecord]
    );
    const updateEditableItem = useCallback(
        (update: Partial<RecordFormState["editableRecordItem"]>) => {
            setEditableRecordItem(prevState => ({
                ...prevState,
                ...update,
            }));
        },
        [setEditableRecordItem]
    );
    return useMemo(() => ({ updateEditableRecord, updateEditableItem }), [updateEditableRecord, updateEditableItem]);
};
