import { GQL } from "@binale-tech/shared";
import { convertGroupActions, RecordActions, RecordsController } from "../context/recordsContext/RecordsControlCtx";
import { IViewsKeys } from "../models/User";
import {
    Product,
    ProductBank,
    ProductDeb,
    ProductER,
    ProductERAnzahlungen,
    ProductFE,
    ProductKB,
    ProductLA,
    ProductPOS,
} from "./Product";

export class ProductFactory {
    static getProductConfig(pk: GQL.IProductKey) {
        return this.getAccountingProductConfig(pk, { skr: 4 } as any, {} as any, {} as any).product.getConfig();
    }

    static getAccountingProductConfig(
        pk: GQL.IProductKey,
        yearConfig: GQL.ICompanyAccountingYear,
        company: GQL.ICompany,
        actions: RecordsController,
        groupId?: string
    ): { product: Product; view: keyof IViewsKeys; actions: RecordActions } | null {
        if (!yearConfig) {
            return null;
        }
        switch (pk) {
            case GQL.IProductKey.Er:
                return {
                    view: "erErfassung",
                    product: new ProductER(yearConfig.skr, company),
                    actions: actions.actionsER,
                };
            case GQL.IProductKey.ErA:
                return {
                    view: "erAnzahlungen",
                    product: new ProductERAnzahlungen(yearConfig.skr, company),
                    actions: actions.actionsAZ,
                };
            case GQL.IProductKey.Deb:
                return {
                    view: "debOverview",
                    product: new ProductDeb(yearConfig.skr, company),
                    actions: actions.actionsDeb,
                };
            case GQL.IProductKey.La:
                return {
                    view: "laOverview",
                    product: new ProductLA(yearConfig.skr, company),
                    actions: actions.actionsLA,
                };
            case GQL.IProductKey.Fe:
                return {
                    view: "feOverview",
                    product: new ProductFE(yearConfig.skr, company),
                    actions: actions.actionsFE,
                };
            case GQL.IProductKey.Pos:
                return {
                    view: "posOverview",
                    product: new ProductPOS(yearConfig.skr, company),
                    actions: actions.actionsPOS,
                };
            case GQL.IProductKey.Kb:
                return {
                    view: "kbOverview",
                    product: new ProductKB(yearConfig.skr, company),
                    actions: convertGroupActions(actions.actionsKB, groupId),
                };
            case GQL.IProductKey.Bank:
                return {
                    view: "bankOverview",
                    product: new ProductBank(yearConfig.skr, company),
                    actions: convertGroupActions(actions.actionsBank, groupId),
                };
        }
        return null;
    }
}
