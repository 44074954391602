import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./DocumentThumbnail.module.scss";
import { Button, Popconfirm, Spin, Tooltip } from "antd";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { FormattedMessage } from "react-intl";
import { PdfUtils } from "@dms/scripts/utils/PdfUtils";

type TProps = {
    fileId?: string;
    title?: string;
    onDelete?: () => void;
    onAccept?: (file: File[]) => void;
    onClick?: () => void;
    className?: string;
    selected?: boolean;
    urlFallback?: string;
};

export const DocumentThumbnail: FC<TProps> = ({ fileId, onClick, onDelete, selected }) => {
    const [url, setUrl] = useState<string>();
    const [tooltipTitle, setTooltipTitle] = useState(<FormattedMessage id="app.dms.copyId" />);

    const { documentsKV } = useContext(DmsDataContext);

    useEffect(() => {
        if (documentsKV[fileId] && documentsKV[fileId].previewUrl) {
            return setUrl(documentsKV[fileId].previewUrl);
        }

        const fileUrl: string = documentsKV[fileId]?.fileUrl;
        if (fileUrl) {
            PdfUtils.previewUrlGenerate(fileUrl).then(blobUrl => setUrl(blobUrl));
        }
    }, [documentsKV, fileId]);

    const copyId = async (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        await navigator.clipboard.writeText(fileId);
        setTooltipTitle(<FormattedMessage id="app.dms.idCopied" />);
        setTimeout(() => {
            setTooltipTitle(<FormattedMessage id="app.dms.copyId" />);
        }, 3000);
    };

    return (
        <div
            onClick={onClick}
            className={styles.documentThumbnailContainer}
            style={{
                outline: selected ? "2px solid #1890ff" : undefined,
            }}
            key={fileId}
        >
            <div className={styles.thumbnailImgWrapper}>
                {url ? (
                    <div
                        className={styles.img}
                        style={{
                            backgroundImage: `url(${url})`,
                        }}
                        id={fileId}
                    />
                ) : (
                    <div className={`${styles.img} ${styles.thumbnailSpin}`}>
                        <Spin spinning />
                    </div>
                )}
            </div>
            <div className={styles.thumbnailFooter}>
                <Tooltip title={tooltipTitle}>
                    <Button onClick={e => copyId(e)} size="small" icon={<CopyOutlined />} disabled={!fileId} />
                </Tooltip>

                {onDelete ? (
                    <Popconfirm
                        key="delete"
                        placement="right"
                        title={<FormattedMessage id="app.confirmation.header" />}
                        onConfirm={onDelete}
                    >
                        <Button size="small" icon={<DeleteOutlined style={{ color: "red" }} />} disabled={!fileId} />
                    </Popconfirm>
                ) : null}
            </div>
        </div>
    );
};

export const DocumentIcon: FC<Pick<TProps, "onClick" | "fileId" | "selected">> = ({ onClick, fileId, selected }) => {
    return (
        <div
            onClick={onClick}
            className={styles.documentThumbnailContainer}
            style={{
                outline: selected ? "2px solid #1890ff" : undefined,
            }}
            key={fileId}
        >
            <div className={styles.thumbnailImgWrapper}></div>
        </div>
    );
};
