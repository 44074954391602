import React, { useState } from "react";
import { InputNumber } from "../InputNumber/InputNumber";

export const InputPercents: React.FC<React.ComponentProps<typeof InputNumber>> = props => {
    const { max, value, onChange, ...rest } = props;
    const [innerValue, setInnerValue] = useState(0);

    React.useEffect(() => {
        setInnerValue((value && value) > 100 ? 100 : value);
    }, [value]);
    const handleChange = (val: number) => {
        const newVal = !val || val < 100 ? val : 100;
        setInnerValue(newVal);
        onChange && onChange(newVal);
    };

    return (
        <InputNumber
            {...rest}
            onChange={handleChange}
            value={innerValue}
            style={{ width: 90 }}
            precision={2}
            negative={false}
            suffix="%"
        />
    );
};
