import React, { FC } from "react";
import { Flex } from "antd";
import { InvoicesListModule } from "@inv/modules/InvocesListModule";
import { InvoiceListHeader } from "@inv/components/InvoiceListHeader";

export const InvoicesListLayout: FC = () => {
    return (
        <Flex
            vertical
            style={{
                height: "100%",
            }}
        >
            <InvoiceListHeader />
            <InvoicesListModule />
        </Flex>
    );
};
