import { Col } from "antd";
import { Carousel } from "../DocumentCarousel/Carousel";
import { DocumentPdfViewer } from "./DocumentPdfViewer";
import React, { Dispatch, FC, SetStateAction } from "react";
import { IDocumentEnriched, TCarouselMode } from "@dms/types";
import { OcrLoadedFile } from "@pdf-tools/types";

interface IProps<T> {
    documentData: T;
    carouselDocuments?: T[];
    reorderingFiles?: (files: OcrLoadedFile[]) => void;
    activeDocumentsId?: string;
    setActiveDocumentsId?: Dispatch<SetStateAction<string>>;
    sizeRatio: number[];
    mode?: TCarouselMode;
}

export const PdfViewModule: FC<IProps<IDocumentEnriched | OcrLoadedFile>> = ({
    documentData,
    carouselDocuments,
    reorderingFiles,
    activeDocumentsId,
    setActiveDocumentsId,
    sizeRatio,
    mode,
}) => {
    return (
        <>
            {carouselDocuments ? (
                <Col span={sizeRatio[0]}>
                    <Carousel
                        documents={carouselDocuments}
                        id={activeDocumentsId}
                        setDocumentId={(id: string) => {
                            setActiveDocumentsId(id);
                        }}
                        reorderingFiles={reorderingFiles}
                        mode={mode}
                    />
                </Col>
            ) : null}

            <Col span={sizeRatio[1]} style={{ padding: "0 10px 0 15px", display: "flex", justifyContent: "center" }}>
                <DocumentPdfViewer
                    url={documentData?.fileUrl}
                    filename={documentData?.fileName}
                    type="application/pdf"
                />
            </Col>
        </>
    );
};
