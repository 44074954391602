import React from "react";
import { Button, Form, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { GenericRecord } from "../../../../../scripts/models";

type Props = {
    onExport: () => void;
    recordsFS: GenericRecord[];
    isLoading: boolean;
};
const ExportJournaled: React.FC<Props> = ({ onExport, recordsFS, isLoading }) => (
    <Form layout="vertical">
        <Space className="DatenExportView__buttonGroup">
            <Form.Item label={<span>&nbsp;</span>}>
                <Button disabled type="primary" onClick={onExport} loading={isLoading}>
                    <FormattedMessage id="app.button.download" />
                    &nbsp;({recordsFS.length})
                </Button>
            </Form.Item>
        </Space>
    </Form>
);
export default React.memo(ExportJournaled);
