import { BuTaxDB } from "../../models/Bu";
import { BuTimeframe } from "../BuContext";
import { CategoryDB } from "../../models/Category";
import { FirestoreDataConverter } from "firebase/firestore";

export class Converters {
    public static readonly buTfConverter: FirestoreDataConverter<BuTimeframe> = {
        toFirestore: v => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { items, defaultCategories, ...rest } = v;
            return {
                ...rest,
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            const [fromY, fromM] = data.from.split(".");
            const [toY, toM] = data.to.split(".");
            const res = { ...data } as BuTimeframe;
            res.from = new Date(fromY, fromM);
            res.to = new Date(toY, toM);
            res.id = snapshot.id;
            return res;
        },
    };

    public static readonly buTaxConverter: FirestoreDataConverter<BuTaxDB> = {
        toFirestore: v => {
            const { scopes, ...rest } = v;
            return {
                ...rest,
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            const buTaxDB = { ...data } as BuTaxDB;
            buTaxDB.scopes = new Set([buTaxDB.scope]);
            return buTaxDB;
        },
    };

    public static readonly categoryConverter: FirestoreDataConverter<CategoryDB & { key: string }> = {
        toFirestore: v => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { key, ...rest } = v;
            return {
                ...rest,
            };
        },
        fromFirestore: (snapshot, options) => {
            const catDB: CategoryDB = snapshot.data(options) as any;
            const res: CategoryDB & { key: string } & Record<string, any> = {
                ...catDB,
                key: snapshot.id,
            };
            delete res.ANLAG;
            delete res.Kontenzweck;
            delete res.FE;
            delete res.KapGes;
            delete res.PersGes;
            delete res.EZU;
            delete res.Kontenart;
            delete res.Kontenart1;
            delete res.Kontenart2;
            // will be needed soon
            delete res.Bericht;
            delete res.Abschlussposten;
            return res;
        },
    };
}
