import React from "react";
import Select, { LabeledValue, RefSelectProps, SelectProps, SelectValue } from "antd/es/select";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";

export interface SelectBlockProps {
    label?: JSX.Element | string;
    options?: SelectValue[];
    value?: SelectValue;
    onChange: SelectProps["onChange"];
    disabled?: boolean;
    className?: string;
}

export const SelectBlockAnt = React.forwardRef<RefSelectProps, SelectBlockProps>(function SelectBlockAnt(props, ref) {
    const items = (props.options || []).map((v: SelectValue, i) => {
        const value = typeof v === "object" ? (v as LabeledValue).key : v;
        const label = typeof v === "object" ? (v as LabeledValue).label : v;
        return (
            <Select.Option key={"option-" + i} value={value}>
                {label}
            </Select.Option>
        );
    });

    return (
        <Form.Item label={props.label} className={[props.className, "SelectBlockAnt"].join(" ")}>
            <Select
                onChange={props.onChange}
                ref={ref}
                value={"" + props.value}
                disabled={props.disabled}
                notFoundContent={<FormattedMessage id="app.components.table.no_items" />}
            >
                {items}
            </Select>
        </Form.Item>
    );
});
