import React, { KeyboardEvent } from "react";

import QuickLRU from "quick-lru";
import SelectionArea from "@viselect/vanilla";
import classNames from "classnames";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Checkbox, Empty } from "antd";
import { FormattedMessage } from "react-intl";
import { Subject, Subscription } from "rxjs";
import { Utils } from "@binale-tech/shared";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";

import LazyRender from "../LazyRender";
import Payment from "scripts/models/Payment";
import TableCell, { TableCellAlignment } from "./TableCell";
import TableHeaderCell from "./TableHeaderCell";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { GenericRecord } from "scripts/models/GenericRecord";
import { GenericRecordProperties } from "scripts/core/Product";
import "./Table.css";

export interface TableItem<E> {
    key: any;
    item: E;
    parent?: TableItem<E>;
    children: E[];
    expanded?: boolean;
    selected?: boolean;
    extra?: unknown;
}

export interface TableItemExtraContainer {
    payments?: Map<GenericRecord, Payment[]>;
    saldo?: number;
    isVirtualRecord?: boolean;
}

export interface TableItemExtra<I, E = Record<string, any>> extends TableItem<I> {
    extra?: TableItemExtraContainer & E;
}

export type GenericRecordTableItem = TableItemExtra<GenericRecord>;

export interface TableColumn<E> {
    key?: string;
    width: number;
    widthConstraints?: {
        min: number;
        max: number;
    };
    header: string | React.ReactNode;
    footer?: {
        mainFooter: React.ReactNode;
        selectedFooter: React.ReactNode;
    };
    getter: (tableItem: TableItem<E>) => any;
    sortable?: boolean;
    sortFunc?: (a: TableItem<E>, b: TableItem<E>) => number;
    sortDirection?: "asc" | "desc" | null;
    alignment?: TableCellAlignment;
    className?: string;
    isMoney?: boolean;
    resizable?: boolean;
    onResize?: (width: number) => void;
}

export interface TableContextMenu<E> {
    targetItems?: TableItem<E>[];
    scrollTop?: number;
}

export interface TableStatelessProps<E> {
    columns: TableColumn<E>[];
    items: TableItem<E>[];
    containerClassName?: string;
    tableClassName?: string;
    selectable?: boolean;
    withExpandColumn?: boolean;
    rowClassName?: (tableItem: TableItem<E>) => string;
    scrollTop?: number;
    // scrollToBottom?: boolean;
    onSort?: (column: TableColumn<E>) => void;
    scrollLeft?: number;
    containerHeight: number;
    onChangeScrollTop?: (v: number) => void;
    onChangeScrollLeft?: (v: number) => void;
    onUpdateItems?: (vs: TableItem<E>[]) => void;
    onChangeContextMenu?: (v: TableStatelessContextMenu<E>) => void;
    onEnterItem?: (tableItem: TableItem<E>) => void;
    onCopyItem?: (tableItem: TableItem<E>) => void;
    showFooter?: boolean;
    focused?: boolean;
    focusIndex?: number;
    onSetFocus?: (key: number) => void;
    onColumnResize?: (column: TableColumn<E>) => void;
    saldoHeader?: number;
}

export interface TableStatelessContextMenu<E> {
    top: number;
    left: number;
    targetItems: TableItem<E>[];
}

interface ItemCache {
    hasChildrenItems?: Set<number>;
    expandedItems?: Set<number>;
    selectedKeys?: Set<number>;
    bodyRows?: (React.ReactNode | undefined | any)[][];
    tableWidth?: number;
}

export class TableStateless<E> extends React.Component<TableStatelessProps<E>, null> {
    protected tableContainerRef = React.createRef<HTMLDivElement>();
    protected fakeInputRef = React.createRef<HTMLInputElement>();
    protected tableRef = React.createRef<HTMLTableElement>();
    protected saldoHeaderRef = React.createRef<HTMLTableSectionElement>();
    public static defaultProps = {
        selectable: false,
        scrollTop: 0,
        scrollLeft: 0,
        onChangeBodyHeight: () => {},
        onUpdateItems: () => {},
        onChangeContextMenu: () => {},
        onChangeScrollTop: () => {},
        onChangeScrollLeft: () => {},
        onSort: () => {},
        onEnterItem: () => {},
        onSetFocus: () => {},
        onCopyItem: () => {},
        showFooter: true,
        withExpandColumn: true,
        // scrollToBottom: false,
        focused: false,
    };
    private _scrollTopSubject = new Subject<number>();
    private _scrollLeftSubject = new Subject<number>();
    private _scrollTopSubscription: Subscription;
    private _scrollLeftSubscription: Subscription;

    protected columnsCache: TableColumn<E>[];
    protected itemCache: ItemCache = {};
    // TODO make it LRU
    protected rowCache = new QuickLRU<TableItem<E>, React.ReactNode>({ maxSize: 10000 });

    protected itemHeight: number;
    protected maximalScrollTop: number;
    protected scrollTopQuick = 0;
    protected selection: SelectionArea;

    protected lazyRenderRef: LazyRender;

    constructor(props: TableStatelessProps<E>) {
        super(props);
        this.calculateItemCache(props.items);
        this.calculateColumnCache(props.columns);
        this.calculateItemBodyRows(props.items);
    }

    UNSAFE_componentWillReceiveProps(nextProps: TableStatelessProps<E>) {
        if (this.props.columns !== nextProps.columns) {
            this.rowCache.clear();
        }
        if (this.props.items !== nextProps.items || this.props.columns !== nextProps.columns) {
            this.calculateItemCache(nextProps.items);
            this.calculateColumnCache(nextProps.columns);
            this.calculateItemBodyRows(nextProps.items);
        } else if (this.props.focusIndex !== nextProps.focusIndex) {
            this.updateRowCacheFocus(nextProps);
        }
    }
    shouldComponentUpdate(nextProps: Readonly<TableStatelessProps<E>>): boolean {
        if (this.props.scrollTop !== nextProps.scrollTop) {
            return true;
        }
        if (this.props.columns) {
            const keys = Object.keys(nextProps) as (keyof TableStatelessProps<E>)[];
            const diff = new Set();
            keys.forEach(k => {
                if (this.props[k] !== nextProps[k]) {
                    diff.add(k);
                }
            });
            if (diff.size === 1 && diff.has("columns")) {
                if (this.props.columns.length === nextProps.columns.length) {
                    return false;
                }
            }
        }
        return true;
    }

    componentDidUpdate() {
        this.setScrollLeft(this.props.scrollLeft);
    }

    componentDidMount() {
        this._scrollTopSubscription = this._scrollTopSubject
            .pipe(
                tap(v => (this.scrollTopQuick = v)),
                debounceTime(100),
                distinctUntilChanged()
            )
            .subscribe((v: number) => {
                if (this.props.scrollTop !== v) {
                    this.props.onChangeScrollTop(v);
                }
            });
        this._scrollLeftSubscription = this._scrollLeftSubject
            .pipe(debounceTime(100), distinctUntilChanged())
            .subscribe((v: number) => {
                if (this.props.scrollLeft !== v) {
                    this.props.onChangeScrollLeft(v);
                }
            });

        this.setScrollLeft(this.props.scrollLeft);

        this.selection = new SelectionArea({
            selectionAreaClass: "selection-area",
            selectionContainerClass: "selection-area-container",
            selectables: ["tbody > tr"],
            boundaries: [".AbstractBillTableView-table > tbody"],
            startAreas: ["tbody"],
            behaviour: {
                overlap: "keep",
                startThreshold: { x: 30, y: 20 },
                scrolling: {
                    speedDivider: 5,
                    manualSpeed: 750,
                    startScrollMargins: { x: 0, y: 0 },
                },
            },
            features: {
                touch: false,
                singleTap: {
                    allow: false,
                    intersect: "native",
                },
            },
        })
            // https://github.com/Simonwep/selection/issues/20
            .on("beforestart", ({ event: mouseEvent }: { event: any }) => {
                if (mouseEvent instanceof MouseEvent) {
                    return mouseEvent.button !== 2;
                }
                return false;
            })
            .on("stop", (event: any) => {
                const selectedElements = event.store.selected;

                const { items } = this.props;

                const rangeSelectedIndex: Set<string> = new Set();
                selectedElements.forEach((item: any) => {
                    if (item instanceof HTMLElement) {
                        rangeSelectedIndex.add(item.dataset.tiKey);
                    }
                });

                const selection = items.filter(item => rangeSelectedIndex.has(String(item.key)));
                const selected = selection.map(item => ({ ...item, selected: true }));
                this._updateSome(selected);
            });
    }

    componentWillUnmount() {
        this._scrollTopSubscription.unsubscribe();
        this._scrollLeftSubscription.unsubscribe();
        this.rowCache.clear();
        this.columnsCache = null;
        try {
            this.selection.destroy();
        } catch (e) {}
    }

    protected updateRowCacheFocus(nextProps: TableStatelessProps<E>) {
        if (Number.isFinite(nextProps.focusIndex)) {
            this.rowCache.delete(nextProps.items[nextProps.focusIndex]);
        }
        this.calculateItemBodyRows(nextProps.items);
    }

    protected getBodyHeight = () => {
        const table = this.tableRef.current;
        if (!table) {
            return 0;
        }
        const tableHeight = table.getBoundingClientRect().height;
        if (tableHeight === 0) {
            setTimeout(() => {
                this.forceUpdate();
            }, 100);
        }
        const theadClientHeight = table.tHead.getBoundingClientRect().height;
        const tSaldoHeadHeight = Number.isFinite(this.props.saldoHeader)
            ? this.saldoHeaderRef.current?.getBoundingClientRect()?.height
            : 0;
        const tFootClientHeight = this.props.showFooter ? table.tFoot.getBoundingClientRect().height : 0;
        const bodyHeight = tableHeight - theadClientHeight - tFootClientHeight - tSaldoHeadHeight;
        if (bodyHeight < 0) {
            return 0;
        }
        return bodyHeight;
    };

    protected setScrollLeft(scrollLeft: number) {
        this.tableContainerRef.current.scrollLeft = scrollLeft;
    }

    protected setItemHeight = (v: number) => {
        this.itemHeight = v;
    };
    protected setMaximalScrollTop = (v: number) => {
        this.maximalScrollTop = v;
    };

    private _updateOne(tableItem: TableItem<E>) {
        this._updateSome([tableItem]);
    }

    private _updateSome(tableItems: TableItem<E>[]) {
        const updates = new Map();
        tableItems.forEach(v => {
            updates.set(v.key, v);
        });
        const newIt = this.props.items.map(v => {
            if (updates.has(v.key)) {
                return { ...updates.get(v.key) };
            }
            return v;
        });
        this.props.onUpdateItems(newIt);
    }

    private handleSelectClick(tableItem: TableItem<E>, e?: any) {
        if (!tableItem) {
            return;
        }
        e && e.stopPropagation();
        this._updateOne({ ...tableItem, selected: !tableItem.selected });
    }

    private handleSelectAllClick = (e: CheckboxChangeEvent) => {
        const { items, onUpdateItems } = this.props;
        const { checked } = e.target;

        const someItemsChecked = items.some(i => i.selected);
        const newItems = items.map((tableItem: TableItem<E>) => ({
            ...tableItem,
            selected: someItemsChecked ? false : checked,
        }));
        // we will have to rewrite everything, so let's clear in advance
        this.rowCache.clear();
        onUpdateItems(newItems);
    };

    private handleExpandClick(tableItem: TableItem<E>, expanded?: boolean) {
        // console.log("handleExpandClick", { tableItem, expanded });
        this._updateOne({ ...tableItem, expanded: typeof expanded === "boolean" ? expanded : !tableItem.expanded });
    }

    private handleExpandAllClick = (expandAll: boolean) => {
        const newItems = this.props.items
            .filter(v => v.children && v.children.length > 0)
            .map((tableItem: TableItem<E>) => {
                tableItem.expanded = expandAll;
                return tableItem;
            });

        this._updateSome(newItems);
    };

    protected get selectedItems() {
        return this.props.items.filter((tableItem: TableItem<E>) => tableItem.selected);
    }

    private handleContextMenu(targetTableItem: TableItem<E>, e: React.MouseEvent<HTMLTableRowElement>) {
        e.preventDefault();
        let contextMenuTargetTableItems;
        const selectedItems: TableItem<E>[] = this.selectedItems;
        if (selectedItems.includes(targetTableItem)) {
            contextMenuTargetTableItems = [...selectedItems];
        } else {
            contextMenuTargetTableItems = [targetTableItem];
            this.onSetFocus(targetTableItem.key);
        }

        this.props.onChangeContextMenu({
            top: e.clientY,
            left: e.clientX,
            targetItems: contextMenuTargetTableItems,
        });
    }

    // getters for render

    protected getTableWidth() {
        return this.getColumns().reduce((caret: number, column: TableColumn<E>) => {
            const resizerWidth = column.resizable ? 5 : 0;
            return caret + column.width + resizerWidth;
        }, 0);
    }

    protected getExpandAllComponent() {
        return (props: any) => {
            if (props.expanded) {
                return (
                    <CaretDownOutlined
                        style={{ cursor: "pointer" }}
                        onClick={props.onClick.bind(null, !props.expanded)}
                    />
                );
            } else {
                return (
                    <CaretRightOutlined
                        style={{ cursor: "pointer" }}
                        onClick={props.onClick.bind(null, !props.expanded)}
                    />
                );
            }
        };
    }

    protected calculateItemCache(items: TableItem<E>[]) {
        const hasChildrenItems = new Set<number>();
        const expandedItems = new Set<number>();
        const selectedKeys = new Set<number>();
        items.forEach(tableItem => {
            if (tableItem.children && tableItem.children.length > 0) {
                hasChildrenItems.add(tableItem.key);
            }
            if (tableItem.expanded) {
                expandedItems.add(tableItem.key);
            }
            if (tableItem.selected) {
                selectedKeys.add(tableItem.key);
            }
        });
        this.itemCache.hasChildrenItems = hasChildrenItems;
        this.itemCache.expandedItems = expandedItems;
        this.itemCache.selectedKeys = selectedKeys;
    }

    /**
     * depends on `itemCache`
     * @param {TableItem<E>[]} cols
     */
    protected calculateColumnCache(cols: TableColumn<E>[]) {
        const { withExpandColumn, selectable, items } = this.props;
        const { hasChildrenItems, expandedItems, selectedKeys } = this.itemCache;

        const columns = [...cols];
        const hasChildren = hasChildrenItems.size > 0;
        const expandAll = expandedItems.size === hasChildrenItems.size;
        if (withExpandColumn) {
            const ExpandAllComponent = this.getExpandAllComponent();
            const header = hasChildren ? (
                <ExpandAllComponent expanded={expandAll} onClick={this.handleExpandAllClick} />
            ) : (
                <CaretRightOutlined type="caret-right" />
            );
            columns.unshift({
                width: 30,
                header,
                getter: (tableItem: TableItem<E>) => {
                    if (tableItem.children && tableItem.children.length > 0) {
                        const handleClickIcon = (e: React.SyntheticEvent) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.handleExpandClick(tableItem);
                        };
                        if (tableItem.expanded) {
                            return <CaretDownOutlined style={{ cursor: "pointer" }} onClick={handleClickIcon} />;
                        } else {
                            return <CaretRightOutlined style={{ cursor: "pointer" }} onClick={handleClickIcon} />;
                        }
                    }
                    return null;
                },
                alignment: TableCellAlignment.center,
            });
        }
        if (selectable) {
            const selectedAll = items.length > 0 && items.length === selectedKeys.size;
            const isIndeterminate = selectedKeys.size > 0 && items.length > selectedKeys.size;

            columns.unshift({
                width: 30,
                header: (
                    <Checkbox
                        tabIndex={-1}
                        checked={selectedAll}
                        onChange={this.handleSelectAllClick}
                        indeterminate={isIndeterminate}
                    />
                ),
                getter: (tableItem: TableItem<E>) => {
                    if (tableItem.key === null) {
                        return null;
                    }
                    return (
                        <Checkbox
                            tabIndex={-1}
                            className={"key_" + (tableItem.item as any).key}
                            onChange={() => this.handleSelectClick(tableItem)}
                            checked={tableItem.selected || false}
                        />
                    );
                },
                alignment: TableCellAlignment.center,
            });
        }
        this.columnsCache = columns;
    }

    protected calculateItemBodyRows(items: TableItem<E>[]) {
        this.itemCache.tableWidth = this.getTableWidth();
        const columns = this.getColumns();
        const tableWidth = this.getTableWidth();
        const s = Date.now();
        const bodyRows = items.map((tableItem: TableItemExtra<E>, idx) => {
            // TODO: here is some strange bug - if columns are sorted then select does not affects table
            if (!this.rowCache.has(tableItem)) {
                this.calculateRowCache(tableItem);
            }
            const row = this.rowCache.get(tableItem);
            const rowChildren =
                tableItem.expanded &&
                tableItem.children.map((child: E, bidx) => {
                    const rowChildrenCells = columns.map((column: TableColumn<E>, bcidx) => {
                        const fakeItem: TableItemExtra<E> = {
                            key: null,
                            item: child,
                            parent: tableItem,
                            children: [],
                            extra: tableItem.extra,
                        };
                        const resizerWidth = column.resizable ? 5 : 0;
                        return (
                            <TableCell
                                key={"td-child-" + bidx + "-" + bcidx}
                                width={column.width + resizerWidth}
                                className={column.className}
                                alignment={column.alignment}
                            >
                                {column.getter(fakeItem)}
                            </TableCell>
                        );
                    });
                    return (
                        <tr className="child" key={"tr-child-" + idx + "-" + bidx} style={{ width: tableWidth }}>
                            {rowChildrenCells}
                        </tr>
                    );
                });
            return [row, rowChildren];
        });
        // const testrow = bodyRows[0][0]["props"].children;
        // console.log("bodyRows", testrow[0].props.children.props, testrow[2].props.children);
        void s;
        // console.log("rows", i, Date.now() - s);
        // console.log("len", this.props.items.length);
        // console.groupEnd();
        this.itemCache.bodyRows = bodyRows;
    }

    protected calculateRowCache(tableItem: TableItem<E>) {
        const rowCells = this.columnsCache.map((column: TableColumn<E>, cidx) => {
            const resizerWidth = column.resizable ? 5 : 0;
            return (
                <TableCell
                    key={"td-" + tableItem.key + "-" + cidx}
                    className={column.className}
                    width={column.width + resizerWidth}
                    alignment={column.alignment}
                >
                    {column.getter(tableItem)}
                </TableCell>
            );
        });
        const row = (
            <tr
                data-key={tableItem.key}
                key={"tr-row-" + tableItem.key}
                data-ti-key={tableItem.key}
                style={{ width: this.itemCache.tableWidth }}
                className={this.props.rowClassName ? this.props.rowClassName(tableItem) : undefined}
                onContextMenu={this.handleContextMenu.bind(this, tableItem)}
                onClick={this.onTableCellClick(tableItem)}
            >
                {rowCells}
            </tr>
        );
        this.rowCache.set(tableItem, row);
    }

    protected getColumns() {
        return this.columnsCache;
    }

    protected onColumnResize = (column: TableColumn<E>) => {
        if (column.resizable) {
            return (width: number) => {
                column.width = width;
                this.rowCache.clear();
                this.props.onColumnResize && this.props.onColumnResize(column);
            };
        }
        return null;
    };

    protected get header() {
        const headerCells = this.getColumns().map((column: TableColumn<E>, idx) => {
            const resizerWidth = column.resizable ? 5 : 0;
            return (
                <TableHeaderCell
                    key={"th-" + idx}
                    width={column.width + resizerWidth}
                    widthConstraints={column.widthConstraints}
                    alignment={TableCellAlignment.center}
                    direction={column.sortDirection}
                    onClick={column.sortable ? this.props.onSort.bind(this, column) : null}
                    onResize={this.onColumnResize(column)}
                    className={column.className}
                >
                    {column.header}
                </TableHeaderCell>
            );
        });
        const tableWidth = this.getTableWidth();
        return (
            <thead style={{ width: tableWidth }}>
                <tr style={{ width: tableWidth }}>{headerCells}</tr>
            </thead>
        );
    }

    protected get headerSaldo() {
        if (!Number.isFinite(this.props.saldoHeader)) {
            return null;
        }
        const headerCells = this.getColumns().map((column: TableColumn<E>, idx) => {
            const resizerWidth = column.resizable ? 5 : 0;
            return (
                <TableCell
                    headerTag
                    key={"ths-" + idx}
                    className="saldo-cell"
                    width={column.width + resizerWidth}
                    alignment={column.alignment}
                >
                    {column.key === GenericRecordProperties.ComputedSaldo
                        ? Utils.CurrencyUtils.currencyFormat(this.props.saldoHeader)
                        : null}
                </TableCell>
            );
        });
        const tableWidth = this.getTableWidth();
        return (
            <thead style={{ width: tableWidth }} ref={this.saldoHeaderRef}>
                <tr style={{ width: tableWidth }}>{headerCells}</tr>
            </thead>
        );
    }

    private onTableCellClick = (tableItem: TableItem<E>) => (e: React.MouseEvent<HTMLTableRowElement>) => {
        const element = e.target as HTMLElement;
        if (
            element.tagName.toUpperCase() === "INPUT" ||
            (typeof element.className === "string" && element.className.includes("checkbox"))
        ) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        this.focusFakeInput();
        if (e.ctrlKey || e.metaKey) {
            this.handleSelectClick(tableItem);
        }
        this.onSetFocus(tableItem.key);
    };

    scrollToBottom() {
        if (this.lazyRenderRef) {
            this.lazyRenderRef.scrollToBottom();
        } else {
            // try fallback
            setTimeout(() => this.lazyRenderRef && this.lazyRenderRef.scrollToBottom(), 50);
        }
    }

    private lrRefFn = (i: any) => (this.lazyRenderRef = i);

    protected get body() {
        const tableWidth = this.getTableWidth();
        if (this.props.items.length > 0) {
            return (
                <LazyRender
                    scrollTop={this.props.scrollTop}
                    height={this.getBodyHeight()}
                    width={tableWidth}
                    useTbody
                    itemPadding={5}
                    onAcquireItemHeight={this.setItemHeight}
                    onAcquireMaximalScrollTop={this.setMaximalScrollTop}
                    onScroll={this.onVerticalScroll}
                    ref={this.lrRefFn}
                >
                    {this.itemCache.bodyRows}
                </LazyRender>
            );
        } else {
            let trWidth = tableWidth;
            const tableWrapper = document.getElementsByClassName("FlexFillBlock");

            if (tableWrapper.length === 1) {
                const wrapperWidth = tableWrapper[0].clientWidth;
                trWidth = trWidth > wrapperWidth ? wrapperWidth : trWidth;
            }

            return (
                <tbody style={{ width: tableWidth }}>
                    <tr style={{ width: tableWidth, display: "table-row" }}>
                        <td style={{ borderBottom: "none", position: "relative" }}>
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={<FormattedMessage id="app.components.table.no_items" />}
                                style={{ position: "fixed", left: `${Math.floor(trWidth / 2)}px` }}
                            />
                        </td>
                    </tr>
                </tbody>
            );
        }
    }

    protected get footer() {
        const { selectedKeys } = this.itemCache;
        const defaultContent = <span>&nbsp;</span>;
        const tableWidth = this.getTableWidth();
        const mainFooterCells = this.getColumns().map((column: TableColumn<E>, idx) => {
            const resizerWidth = column.resizable ? 5 : 0;
            return (
                <TableCell
                    headerTag
                    key={"th-" + idx}
                    className="footer-cell"
                    width={column.width + resizerWidth}
                    alignment={column.alignment}
                >
                    {column.footer?.mainFooter || defaultContent}
                </TableCell>
            );
        });
        const selectedFooterCells = this.getColumns().map((column: TableColumn<E>, idx) => {
            const resizerWidth = column.resizable ? 5 : 0;
            return (
                <TableCell
                    headerTag
                    key={"th-" + idx}
                    className="footer-cell"
                    width={column.width + resizerWidth}
                    alignment={column.alignment}
                >
                    {column.footer?.selectedFooter || defaultContent}
                </TableCell>
            );
        });
        return (
            <tfoot style={{ width: tableWidth + 2 }}>
                {selectedKeys.size > 1 && <tr style={{ width: tableWidth + 2 }}>{selectedFooterCells}</tr>}
                <tr style={{ width: tableWidth + 2 }}>{mainFooterCells}</tr>
            </tfoot>
        );
    }

    protected onHorizontalScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.target === this.tableContainerRef.current) {
            this._scrollLeftSubject.next(e.currentTarget.scrollLeft);
        }
    };

    protected onVerticalScroll = (e: HTMLElement) => {
        this._scrollTopSubject.next(e.scrollTop);
    };

    protected onSetFocus(idx: number) {
        this.rowCache.delete(this.props.items[this.props.focusIndex]);
        this.rowCache.delete(this.props.items[idx]);
        this.props.onSetFocus(idx);
    }

    protected handleKeyDown = (e: KeyboardEvent) => {
        const { items, focusIndex, onChangeScrollTop } = this.props;

        const currentScrollTop = this.scrollTopQuick; // this.props.scrollTop would be cleaner but it us updating not so quick
        if (!this.lazyRenderRef) {
            return;
        }
        const { startVisible: startIndexOfTable, endVisible: endIndexOfTable } = this.lazyRenderRef.getCounters();

        let itemsToScroll = 1;
        let currentIndex = focusIndex;
        const currentItem = items[currentIndex];

        if (e.key === "ArrowUp") {
            const prevItem = items[focusIndex - 1];

            if (e.ctrlKey || e.metaKey) {
                if (Number.isFinite(currentIndex)) {
                    this.handleExpandClick(currentItem, false);
                }
                return;
            }
            if (focusIndex > 0) {
                e.preventDefault();
                currentIndex = focusIndex - 1;
                this.onSetFocus(currentIndex);
            }
            if (!prevItem) {
                return;
            }
            if (prevItem.expanded) {
                itemsToScroll = 1 + prevItem.children.length;
            }
            if (startIndexOfTable >= currentIndex) {
                const newScrollTop = currentScrollTop - this.itemHeight * itemsToScroll;
                onChangeScrollTop(newScrollTop);
                this._scrollTopSubject.next(newScrollTop);
            }
            if (e.shiftKey) {
                this.handleSelectClick(currentItem);
            }
        } else if (e.key === "ArrowDown") {
            if (e.ctrlKey || e.metaKey) {
                if (Number.isFinite(currentIndex)) {
                    this.handleExpandClick(currentItem, true);
                }
                return;
            }
            if (focusIndex < items.length - 1) {
                e.preventDefault();
                currentIndex = focusIndex + 1;
                this.onSetFocus(currentIndex);
            }

            if (endIndexOfTable - 1 <= currentIndex) {
                const newScrollTop = currentScrollTop + this.itemHeight * itemsToScroll;
                onChangeScrollTop(newScrollTop);
                this._scrollTopSubject.next(newScrollTop);
            }
            if (e.shiftKey) {
                this.handleSelectClick(currentItem);
            }
        } else if (e.key === "Enter") {
            e.stopPropagation();
            e.preventDefault();
            this.onEnterItem();
        } else if (e.key === " ") {
            // Space bar
            this.handleSelectClick(currentItem);
        } else if (e.key === "F8") {
            this.onF8Item();
        }
    };

    protected onEnterItem() {
        if (Number.isFinite(this.props.focusIndex)) {
            this.props.onEnterItem(this.props.items[this.props.focusIndex]);
        }
    }

    protected onF8Item() {
        if (Number.isFinite(this.props.focusIndex)) {
            this.props.onCopyItem(this.props.items[this.props.focusIndex]);
        }
    }

    protected focusFakeInput = () => {
        this.fakeInputRef.current?.focus();
    };

    render() {
        const className = classNames("Table", {
            [this.props.containerClassName]: Boolean(this.props.containerClassName),
            focused: this.props.focused,
        });

        return (
            <div
                ref={this.tableContainerRef}
                className={className}
                style={{ height: this.props.containerHeight }}
                onScroll={this.onHorizontalScroll}
                onKeyDown={this.handleKeyDown}
            >
                {/* {this.contextMenu}*/}
                <input className="Table--fake_input" ref={this.fakeInputRef} />
                <table
                    className={this.props.tableClassName}
                    ref={this.tableRef}
                    style={{ width: this.getTableWidth() }}
                >
                    {this.header}
                    {this.headerSaldo}
                    {this.body}
                    {this.props.showFooter && this.footer}
                </table>
            </div>
        );
    }
}
