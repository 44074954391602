import { IDocumentEnriched } from "@dms/types";
import React, { useContext } from "react";

import { DmsItem } from "@dms/components/DocumentCarouselItems/components/DmsItem";
import { CarouselContext } from "@dms/modules/DocumentFormModule/context/CarouselContext";

import styles from "./DmsViewList.module.scss";

interface IProps {
    documents: IDocumentEnriched[];
    id: string | undefined;
    handleClick: (i: string) => void;
}

export const DmsViewList: React.FC<IProps> = ({ documents, handleClick, id }) => {
    const { handleCheckedItems } = useContext(CarouselContext);

    return (
        <div>
            {documents.map((document, index) => {
                return (
                    <div
                        className={styles.dmsViewListItem}
                        style={{
                            outline: document.key === id ? "2px solid #1890ff" : undefined,
                        }}
                        key={document.key}
                        onClick={() => handleClick(document.key)}
                    >
                        <DmsItem
                            document={document}
                            handleCheckedItems={() => handleCheckedItems(documents)}
                            index={index}
                            key={document.key}
                        />
                    </div>
                );
            })}
        </div>
    );
};
