import { Input, InputProps } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { decimalFormatter, floatInputBlocker, parseNumber } from "@dms/scripts/helpers";

interface IProps extends Omit<InputProps, "placeholder" | "onChange" | "type"> {
    onChange?: (val?: number | null) => void;
    value?: number;
    placeholder?: string;
}

export const DocumentDiscountPercentInput: FC<IProps> = ({ onChange, value, placeholder, disabled, ...restProps }) => {
    const { formatMessage } = useIntl();
    const [inputValue, setInputValue] = useState("");
    const [placeholderText, setPlaceholderText] = useState(placeholder);

    useEffect(() => {
        if (value === undefined) {
            return setInputValue("");
        }

        setInputValue(decimalFormatter(value));
    }, [value]);

    const sendBack = () => {
        if (onChange) {
            if (inputValue === "") {
                return onChange(undefined);
            }

            onChange(parseNumber(inputValue));
        }
    };

    useEffect(() => {
        if (placeholder === "Diverse" && !disabled && onChange) {
            onChange(null);
            setPlaceholderText(undefined);
        }
    }, [disabled, placeholder]);

    return (
        <Input
            {...restProps}
            value={inputValue}
            onChange={e =>
                setInputValue(prev => {
                    if (e.target.value.split(",")[1]?.length > 2) {
                        return prev;
                    }

                    return e.target.value;
                })
            }
            onBlur={sendBack}
            onKeyDown={e => floatInputBlocker(e, true)}
            suffix={"%"}
            placeholder={placeholderText ?? formatMessage({ id: "app.fields.discountPercent" })}
            disabled={disabled}
        />
    );
};
