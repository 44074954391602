import React, { FC, useContext } from "react";
import { Checkbox, Col, Form, Row, Select } from "antd";
import { FormattedMessage } from "react-intl";

import { AppSettingsContext } from "scripts/context/AppSettingsProvider";

export const ProfileSettings: FC = () => {
    const { intl, changeLang, useClassicImage, changeImage } = useContext(AppSettingsContext);

    return (
        <>
            <Row>
                <Col span={6}>
                    <Form layout="vertical">
                        <Form.Item label={<FormattedMessage id="app.profile.select.title" />}>
                            <Select
                                value={intl.locale}
                                onChange={changeLang}
                                options={[
                                    { label: "Deutsch", value: "de" },
                                    { label: "English", value: "en" },
                                    { label: "Русский", value: "ru" },
                                ]}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Form layout="vertical">
                        <Form.Item label="Classic background">
                            <Checkbox checked={useClassicImage} onChange={e => changeImage(e.target.checked)} />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
