export function removeEmpty<T>(obj: T): T {
    const isObject = (value: T) => typeof value === "object" && !Array.isArray(value) && value !== null;
    if (!isObject(obj)) {
        return obj;
    }

    const isEmpty = (value: any) =>
        value === null ||
        value === undefined ||
        value === "" ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "object" && Object.keys(value).length === 0);

    const result = Object.fromEntries(
        Object.entries(obj)
            .filter(([, value]) => value !== undefined)
            .map(([key, value]) => {
                let changedValue = value;
                if (Array.isArray(value)) {
                    changedValue = value.map(removeEmpty).filter(val => !isEmpty(val));
                    changedValue = changedValue.length ? changedValue : undefined;
                }

                if (isObject(value)) {
                    changedValue = removeEmpty(value);
                    changedValue = isEmpty(changedValue) ? undefined : changedValue;
                }
                return [key, changedValue];
            })
            .filter(([key, value]) => !isEmpty(value))
    );
    return isEmpty(result) ? undefined : result;
}
