import { useCallback, useContext } from "react";
import { GenericRecord } from "scripts/models/GenericRecord";
import { RecordFormPropsContext } from "@app/components/recordform/context/RecordFormPropsContext";
import { useFormRecordStateUpdater } from "@app/components/recordform/hooks/useFormRecordStateUpdater";
import { useFormHandlerRecordTemplate } from "@app/components/recordform/hooks/handlers/useFormHandlerRecordTemplate";
import { TableViewContext } from "../../../../../scripts/context/tableViewContext/tableViewContext";
import { RecordFormStateControlContext } from "@app/components/recordform/context/RecordFormState";

export const useFormHandlerRecordContact = () => {
    const { moduleTemplates } = useContext(TableViewContext);
    const { isTemplate } = useContext(RecordFormPropsContext);
    const { setIsModalTemplateOpen } = useContext(RecordFormStateControlContext);
    const { updateEditableRecord } = useFormRecordStateUpdater();

    const onTemplateChange = useFormHandlerRecordTemplate();

    return useCallback(
        (recordContact: GenericRecord["partner"]) => {
            updateEditableRecord({ recordContact });
            if (recordContact && !isTemplate) {
                const templates = moduleTemplates.filter(({ partner }) => partner.id === recordContact.id);
                if (templates.length === 1) {
                    onTemplateChange(templates[0]);
                } else if (templates.length > 1) {
                    setIsModalTemplateOpen(true);
                }
            }
        },
        [setIsModalTemplateOpen, updateEditableRecord, isTemplate, moduleTemplates, onTemplateChange]
    );
};
