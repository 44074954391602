import RecordFormUtils from "../utils/RecordFormUtils";
import { useContext, useMemo } from "react";
import { RecordFormStateContext } from "../context/RecordFormState";
import { CompanyContext } from "scripts/context/CompanyContext";
import { Base, GQL } from "@binale-tech/shared";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";

export const useFormStateDetails = () => {
    const { product } = useContext(TableViewContext);
    const { yearConfig } = useContext(CompanyContext);
    const formState = useContext(RecordFormStateContext);
    const { skr } = yearConfig;

    const formStateDetails = useMemo(() => RecordFormUtils.getFormStateDetails(formState), [formState]);
    const restNetto = useMemo(() => {
        const { editableRecord, recordDate, recordItems, recordTmpNetto } = formState;

        const { hasCurrency, rest, restEuro } = RecordFormUtils.getRestNetto(
            editableRecord,
            recordDate,
            skr as Base.CompanySKR,
            recordTmpNetto,
            product.productKey() as GQL.IProductKey,
            recordItems
        );
        return hasCurrency ? rest : restEuro;
    }, [formState, product, skr]);
    return { ...formStateDetails, restNetto };
};
