import React, { useEffect, useState } from "react";
import { IHeaderParams } from "ag-grid-community";
import { Badge, Flex, Tooltip } from "antd";
import { LinkOutlined, MenuOutlined } from "@ant-design/icons";
import { IColDocument } from "@dms/modules/DocumentTableModule/interfaces";
import { FormattedMessage } from "react-intl";

export const HeaderAttached: React.FC<IHeaderParams<IColDocument>> = props => {
    const { showColumnMenu, api } = props;
    const [isFilterActive, setFilterActive] = useState(false);
    const [tooltipText, setTooltipText] = useState(<FormattedMessage id="app.dms.showAll" />);

    const switchTooltip = (value: any) => {
        if (value) {
            return <FormattedMessage id="app.dms.isAttached" />;
        }
        return <FormattedMessage id="app.dms.isn'tAttached" />;
    };

    useEffect(() => {
        const handleFilterChanged = () => {
            const newModel = api.getFilterModel()?.isAttached;

            if (newModel === "all" || typeof newModel !== "boolean") {
                setTooltipText(<FormattedMessage id="app.dms.showAll" />);
                return setFilterActive(false);
            }
            setTooltipText(switchTooltip(newModel));
            setFilterActive(true);
        };

        api.addEventListener("filterChanged", handleFilterChanged);

        if (api.isDestroyed()) {
            api.removeEventListener("filterChanged", handleFilterChanged);
        }
    }, [api]);

    return (
        <Flex align="center" justify="space-between" style={{ width: "100%" }}>
            <LinkOutlined />
            <Tooltip title={tooltipText}>
                <Badge dot={isFilterActive}>
                    <MenuOutlined onClick={e => showColumnMenu(e.currentTarget)} />
                </Badge>
            </Tooltip>
        </Flex>
    );
};
