import React, { type FC, memo, useContext } from "react";
import { UploadFileStatus } from "@dms/types";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { StatusIcon } from "@dms/modules/DocumentUploader/components/IsUploadingFiles/StatusIcon";
import { Flex } from "antd";

interface IProps {
    file: File;
    status: UploadFileStatus;
    hash: string;
    error?: string | false;
}

export const UploadFileItem: FC<IProps> = memo(({ file, status, hash, error }) => {
    const { documentsKV } = useContext(DmsDataContext);

    return (
        <Flex justify={"space-between"} style={{ width: "100%" }}>
            <div>
                {status === UploadFileStatus.DONE ? (
                    <a href={documentsKV[hash]?.fileUrl} target="_blank" rel="noreferrer">
                        {file.name}
                    </a>
                ) : (
                    <div>{file.name}</div>
                )}
                {UploadFileStatus.ERROR && error && <div style={{ color: "red" }}>Error: {error}</div>}
            </div>
            <div>{StatusIcon[status] ?? null}</div>
        </Flex>
    );
});
