import React, { FC } from "react";
import { Button, Col, Row } from "antd";
import { CheckOutlined, CloseOutlined, RedoOutlined } from "@ant-design/icons";

interface ControlButtonsProps {
    isHidden: boolean;
    onHide: () => void;
    onRotate: () => void;
    isDisabled: boolean;
    className?: string;
}

export const ControlButtons: FC<ControlButtonsProps> = ({ isHidden, isDisabled, className, onHide, onRotate }) => {
    return (
        <Row gutter={8} className={className}>
            <Col>
                <Button
                    icon={isHidden ? <CheckOutlined /> : <CloseOutlined />}
                    shape="circle"
                    type={isHidden ? "default" : "dashed"}
                    onClick={e => {
                        e.stopPropagation();
                        onHide();
                    }}
                    disabled={isDisabled}
                />
            </Col>
            <Col>
                <Button
                    icon={<RedoOutlined />}
                    shape="circle"
                    onClick={e => {
                        e.stopPropagation();
                        onRotate();
                    }}
                    disabled={isDisabled}
                />
            </Col>
        </Row>
    );
};
