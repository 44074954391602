import React from "react";
import { Bu } from "@binale-tech/shared";
import { RefSelectProps, Select, SelectProps } from "antd";

interface Props extends SelectProps {
    value?: Bu.USt13bOption;
    onChange?: (value: Bu.USt13bOption) => void;
    bu?: Bu.Bu;
}

export const Selection13b = React.forwardRef<RefSelectProps, Props>(function Selection13b(
    { value, onChange, bu, ...props },
    ref
) {
    const options13b = Bu.getUst13bData(bu).options;
    return (
        <Select
            {...props}
            allowClear
            ref={ref}
            value={value}
            onChange={onChange}
            getPopupContainer={trigger => trigger.parentNode}
            disabled={props.disabled || !options13b}
            options={(options13b ?? []).map(o => ({ label: o.title, value: o.value }))}
        />
    );
});
