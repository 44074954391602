import React, { useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { Button, Col, Form, Modal, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormattedMessage } from "react-intl";
import { GQL, Utils } from "@binale-tech/shared";

import { BruttoInputBlock } from "../../../shared/form/FormBlocks";
import { CompanyContext } from "scripts/context/CompanyContext";
import { GenericRecord } from "scripts/models/GenericRecord";
import { PaymentPrototype } from "../../../../../scripts/models/Payment";
import { PaymentUtils } from "../../../../../scripts/models/utils/PaymentUtils";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { TableViewContext } from "../../../../../scripts/context/tableViewContext/tableViewContext";
import { useGetConnectedPaymentSourceRecord } from "./hooks/useGetConnectedPaymentSourceRecord";
import { useGetMatchingPaymentSourceRecords } from "./hooks/useGetMatchingPaymentSourceRecords";
import "./ConnectionModal.css";

const getColumns = (
    payments: React.ContextType<typeof PaymentsContext>,
    kontoExt: number
): ColumnsType<GenericRecord> => [
    {
        title: "Modul",
        key: "module",
        width: 170,
        render: (cell, record) => <FormattedMessage id={"app.titles." + record.getProductKey()} />,
    },
    {
        title: <FormattedMessage id="app.fields.date" />,
        key: "date",
        width: 90,
        render: (cell, record) => dayjs(record.date).format("DD.MM.YYYY"),
    },
    {
        title: <FormattedMessage id="app.fields.rechnung_num" />,
        key: "num",
        width: 110,
        render: (cell, record) => record.num,
    },
    {
        title: <FormattedMessage id="app.fields.betrag" />,
        key: "brutto",
        width: 100,
        render: (cell, record) => Utils.CurrencyUtils.currencyFormat(record.getBrutto()),
    },
    {
        title: <FormattedMessage id="app.fields.offner_betrag" />,
        key: "rest",
        width: 70,
        render: (cell, record) =>
            Utils.CurrencyUtils.currencyFormat(record.getOpenBrutto(payments.recordRelation.get(record.key))),
    },
    {
        title: (
            <span>
                <FormattedMessage id="app.fields.creditor" />/<br />
                <FormattedMessage id="app.fields.debitor" />
            </span>
        ),
        key: "rkonto",
        width: 100,
        render: (cell, record) => {
            return (
                <small>
                    {record.getRecordCategoryCreditor().getExtNumPrint(kontoExt).replace(" ", `\xa0`)}
                    <br />
                    {record.getRecordCategoryCreditor().name}
                </small>
            );
        },
    },
];
interface Props {
    paymentRepresentationRecord: GenericRecord;
    mode: "form" | "table";
    formSelectedPayment: PaymentPrototype | null;
    onCancel: () => void;
    onOk: (value: { paymentSourceRecord: GenericRecord; payment: PaymentPrototype } | null) => void;
    isEditDisabled: boolean;
    isDeleteDisabled?: boolean;
}

const ConnectionModal: React.FC<Props> = ({
    paymentRepresentationRecord,
    formSelectedPayment,
    onCancel,
    onOk,
    isEditDisabled,
    isDeleteDisabled,
    mode,
}) => {
    const { yearConfig } = useContext(CompanyContext);
    const payments = useContext(PaymentsContext);
    const { allRecords } = useContext(RecordsContext);
    const { productKey } = useContext(TableViewContext);

    const [showAllOpenRecords, setShowAllOpenRecords] = useState(false);
    const { record: defaultPaymentSourceRecord, payment: connectedPayment } = useGetConnectedPaymentSourceRecord(
        paymentRepresentationRecord?.key
    );
    const connectedPaymentSourceRecordFormOverride = formSelectedPayment
        ? allRecords.map.get(formSelectedPayment.sourceRecordKey)
        : undefined;
    const connectedPaymentSourceRecord =
        mode === "form" ? connectedPaymentSourceRecordFormOverride : defaultPaymentSourceRecord;
    const matchedRecords = useGetMatchingPaymentSourceRecords({
        paymentRepresentationRecord,
        useInvoiceNum: !showAllOpenRecords,
    });
    const paymentReresentationRecordProductKey = paymentRepresentationRecord?.getProductKey() || productKey;

    const [initialPaymentPrototype] = useState(
        mode === "form"
            ? formSelectedPayment
            : PaymentUtils.toPrototype(
                  connectedPayment,
                  paymentReresentationRecordProductKey as GQL.IProductKey,
                  connectedPaymentSourceRecord?.getProductKey()
              )
    );
    const [paymentPrototype, setPaymentPrototype] = useState<PaymentPrototype | null>(initialPaymentPrototype);
    const [skontoBetrag, setSkontoBetrag] = useState(initialPaymentPrototype?.skontoBetrag || 0);
    const closeBtnRef = useRef<HTMLButtonElement>();
    const modalBodyRef = useRef<HTMLDivElement>();
    const skontoRef = useRef<HTMLInputElement>();

    const dataSource = React.useMemo(() => {
        if (!connectedPaymentSourceRecord) {
            return matchedRecords.matched;
        }
        if (!showAllOpenRecords) {
            return [connectedPaymentSourceRecord];
        }
        return [
            connectedPaymentSourceRecord,
            ...matchedRecords.matched.filter(matchedRecord => connectedPaymentSourceRecord.key !== matchedRecord.key),
        ];
    }, [matchedRecords.matched, connectedPaymentSourceRecord, showAllOpenRecords]);

    const columns = getColumns(payments, yearConfig.kontoExt);

    const handleSaveAssignment = () => {
        onOk({
            paymentSourceRecord: allRecords.map.get(paymentPrototype?.sourceRecordKey),
            payment: { ...paymentPrototype, skontoBetrag },
        });
        setPaymentPrototype(null);
    };
    const handleDeleteAssignment = () => {
        onOk(null);
        setPaymentPrototype(null);
    };

    const handleCancelButton = () => {
        onCancel();
        setPaymentPrototype(null);
    };

    useEffect(() => {
        modalBodyRef.current?.focus();
        setTimeout(() => {
            if (formSelectedPayment) {
                closeBtnRef.current?.focus();
            } else {
                skontoRef.current?.focus();
            }
        }, 50);
    }, [formSelectedPayment]);

    const isBindingChanged =
        initialPaymentPrototype?.sourceRecordKey !== paymentPrototype?.sourceRecordKey ||
        (paymentPrototype && initialPaymentPrototype?.skontoBetrag !== skontoBetrag);

    const isDeleteBtnEnabled =
        !isDeleteDisabled &&
        Boolean(initialPaymentPrototype) &&
        ((isBindingChanged && !paymentPrototype) || !isBindingChanged);

    const isSaveBtnEnabled = !isEditDisabled && isBindingChanged && Boolean(paymentPrototype);

    const hideButton =
        showAllOpenRecords ||
        matchedRecords.matched.length === 0 ||
        (!connectedPaymentSourceRecord && !matchedRecords.filteredByInvoiceNum);

    return (
        <Modal
            open
            width={1200}
            style={{ top: 20 }}
            className="ConnectionModal"
            title={<FormattedMessage id="app.paymentConnections" />}
            destroyOnClose
            data-testid="payment-binding-modal"
            closable={false}
            maskClosable={false}
            footer={
                <Row justify="space-between">
                    <Col>
                        <Space>
                            <Button danger disabled={!isDeleteBtnEnabled} onClick={handleDeleteAssignment}>
                                <FormattedMessage id="app.paymentConnections.deleteAssignment" />
                            </Button>
                            <Button
                                onClick={handleSaveAssignment}
                                disabled={!isSaveBtnEnabled}
                                className="antd-button-success"
                            >
                                <FormattedMessage id="app.paymentConnections.saveAssignment" />
                            </Button>
                        </Space>
                    </Col>
                    <Col>
                        <Button onClick={handleCancelButton} ref={closeBtnRef} type="primary">
                            <FormattedMessage id="app.button.close" />
                        </Button>
                    </Col>
                </Row>
            }
        >
            <div
                ref={modalBodyRef}
                onKeyDown={e => {
                    if ((e.target as HTMLElement)?.tagName?.toUpperCase() !== "INPUT") {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                }}
            >
                <FormattedMessage id="app.paymentConnections.record" tagName="h3" />
                {paymentRepresentationRecord && (
                    <Form layout="inline" style={{ marginBottom: 15 }}>
                        <Form.Item label={<FormattedMessage id="app.fields.konto" />}>
                            <code data-testid="baseRecord">
                                {paymentRepresentationRecord
                                    .getItemCategoryCreditor()
                                    .getExtNumPrint(yearConfig.kontoExt)}{" "}
                                - {paymentRepresentationRecord.getItemCategoryCreditor().name}
                            </code>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id="app.fields.betrag" />}>
                            <code>{Utils.CurrencyUtils.currencyFormat(paymentRepresentationRecord.getBrutto())}</code>
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id="app.fields.skonto" />}>
                            <BruttoInputBlock
                                ref={skontoRef}
                                disabled={isEditDisabled}
                                value={skontoBetrag}
                                onChange={value => {
                                    setSkontoBetrag(value.amount || 0);
                                }}
                                labelProps={{
                                    label: null,
                                    style: { marginBottom: 0 },
                                }}
                                data-testid="discountAmount"
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    <FormattedMessage id="app.global.total" />{" "}
                                    <FormattedMessage id="app.fields.betrag" />
                                </>
                            }
                        >
                            <code>
                                {Utils.CurrencyUtils.currencyFormat(
                                    paymentRepresentationRecord.getBrutto() + skontoBetrag
                                )}
                            </code>
                        </Form.Item>
                    </Form>
                )}
                <FormattedMessage id="app.paymentConnections.helperText" tagName="p" />
                <Table
                    size="small"
                    rowSelection={{
                        type: "radio",
                        selectedRowKeys: paymentPrototype ? [paymentPrototype.sourceRecordKey] : [],
                        onChange: (selectedRowKeys: string[], selectedRows: GenericRecord[]) => {
                            setPaymentPrototype(
                                selectedRows?.[0]
                                    ? {
                                          skontoBetrag,
                                          sourceRecordKey: selectedRows[0].key,
                                      }
                                    : null
                            );
                        },
                        getCheckboxProps: () => {
                            return { disabled: isEditDisabled };
                        },
                    }}
                    onRow={record => {
                        return {
                            onClick: () => {
                                if (isEditDisabled) {
                                    return;
                                }
                                if (paymentPrototype?.sourceRecordKey === record.key) {
                                    setPaymentPrototype(null);
                                } else {
                                    setPaymentPrototype({
                                        skontoBetrag,
                                        sourceRecordKey: record.key,
                                    });
                                }
                            },
                        };
                    }}
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={!matchedRecords}
                    footer={
                        hideButton
                            ? null
                            : () => (
                                  <Row justify="center">
                                      <Button type="text" onClick={() => setShowAllOpenRecords(true)}>
                                          <FormattedMessage id="app.paymentConnections.showAllOpenItems" />
                                      </Button>
                                  </Row>
                              )
                    }
                />
            </div>
        </Modal>
    );
};

export default ConnectionModal;
