import { RefSelectProps, Select } from "antd";
import React, { forwardRef, useState } from "react";

import { TOptions } from "../types";

type TProps = {
    onChange?: (v: any) => void;
    value?: any;
    options: TOptions;
    placeholder?: string;
    disabled?: boolean;
};
export const TypeInput = forwardRef<RefSelectProps, TProps>(
    ({ value, onChange, disabled, options, ...restProps }, ref) => {
        const [openOptions, setOpenOptions] = useState(false);

        const handleOnChange = (arg: string): void => {
            onChange?.(arg);
        };

        return (
            <Select
                {...restProps}
                value={value}
                style={{ alignSelf: "flex-end" }}
                onChange={handleOnChange}
                options={options}
                onBlur={e => setOpenOptions(false)}
                onDropdownVisibleChange={setOpenOptions}
                open={openOptions}
                disabled={disabled}
                ref={ref}
            />
        );
    }
);
