import { useCallback, useContext } from "react";
import RecordFormUtils from "@app/components/recordform/utils/RecordFormUtils";
import { RecordFormStateControlContext } from "@app/components/recordform/context/RecordFormState";
import { RecordFormPropsContext } from "@app/components/recordform/context/RecordFormPropsContext";
import { useFormValidators } from "@app/components/recordform/hooks/useFormValidators";
import dayjs from "dayjs";
import { useFormRecordStateUpdater } from "@app/components/recordform/hooks/useFormRecordStateUpdater";

export const useFormHandlerDueDate = () => {
    const { updateEditableRecord } = useFormRecordStateUpdater();
    return useCallback(
        (v: dayjs.Dayjs) => {
            updateEditableRecord({ recordFalligkeit: v ? v.toDate() : null });
        },
        [updateEditableRecord]
    );
};
