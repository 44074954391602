import React, { FC, useContext, useEffect, useState } from "react";
import { DocumentPdfViewer } from "@app/components/shared/PdfViewer/DocumentPdfViewer";
import { useGenerateInvoicePdf } from "@inv/hooks/useGenerateInvoicePdf";
import { invoiceXRechnung } from "@inv/components/ActionList/query";
import { useApolloClient } from "@apollo/client";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { GQL } from "@binale-tech/shared";
import { useInvoiceGoBL } from "@inv/hooks/useInvoiceGoBL";
import { AFRelationship, PDFDocument } from "pdf-lib";
import { Button, Flex, Space, Spin, Tooltip } from "antd";
import { FileExcelOutlined, FilePdfOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { saveAs } from "file-saver";

interface IProps {
    invoiceId: string;
}

export const InvoiceViewer: FC<IProps> = ({ invoiceId }) => {
    const client = useApolloClient();
    const { companyGQL } = useContext(CompanyContext);
    const [documentData, setDocumentData] = useState<{
        url: string;
        filename: string;
        type: string;
        blob: Blob;
        xmlBlob: Blob;
    }>(null);
    const createInvoicePdf = useGenerateInvoicePdf(invoiceId);
    const data = useInvoiceGoBL(invoiceId);

    const createPdf = async () => {
        console.log("createPdf", data);
        if (documentData) {
            return;
        }
        const pdfBuffer = await createInvoicePdf();
        const xmlUint8Array = await client
            .query<Pick<GQL.IQuery, "invoiceXRechnung">, GQL.IQueryInvoiceXRechnungArgs>({
                query: invoiceXRechnung,
                fetchPolicy: "network-only",
                variables: {
                    input: {
                        companyId: companyGQL.id,
                        invoiceId,
                        json: JSON.stringify(data),
                    },
                },
            })
            .then(res => {
                const data = res.data.invoiceXRechnung.replace("#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0", "");
                // .replace("<ram:ApplicableHeaderTradeDelivery></ram:ApplicableHeaderTradeDelivery>", "");
                const enc = new TextEncoder();
                return enc.encode(data);
            });
        const pdfDoc = await PDFDocument.load(pdfBuffer);
        await pdfDoc.attach(xmlUint8Array, "factur-x.xml", {
            mimeType: "application/xml",
            creationDate: new Date(),
            modificationDate: new Date(),
            afRelationship: AFRelationship.Alternative,
            description: "Factur-x invoice",
        });

        const pdfBytes = await pdfDoc.save();

        const type = "application/pdf";
        const blob = new Blob([pdfBytes], { type });
        const url = URL.createObjectURL(blob);
        const filename = [data.issue_date, invoiceId].filter(Boolean).join("_");
        setDocumentData({ url, filename, type, blob, xmlBlob: new Blob([xmlUint8Array], { type: "application/xml" }) });
    };

    useEffect(() => {
        if (!data) {
            return;
        }
        createPdf();
    }, [data, invoiceId]);

    return (
        <Flex justify={"center"} style={{ height: "100%", width: "100%" }} vertical>
            {documentData ? (
                <>
                    <Flex style={{ marginBottom: 5 }} justify={"space-between"}>
                        <Space.Compact>
                            <Button
                                onClick={() => saveAs(documentData.blob, documentData.filename)}
                                icon={<FilePdfOutlined />}
                            >
                                <FormattedMessage id={"app.button.download"} />
                                PDF
                            </Button>
                            <Tooltip title={"PDF mit E-Rechnung (ZUGFeRD 2.2 / Factur-X)"}>
                                <Button icon={<InfoCircleOutlined />} />
                            </Tooltip>
                        </Space.Compact>
                        <Button
                            onClick={() => saveAs(documentData.xmlBlob, documentData.filename.replace(".pdf", ".xml"))}
                            icon={<FileExcelOutlined />}
                        >
                            <FormattedMessage id={"app.button.download"} />
                            ZUGFeRD XML
                        </Button>
                    </Flex>
                    <DocumentPdfViewer
                        style={{ height: "100%", width: "100%" }}
                        url={documentData.url}
                        type={documentData.type}
                        filename={documentData.filename}
                    />
                </>
            ) : (
                <Spin />
            )}
        </Flex>
    );
};
