import { UploadFileStatus } from "@dms/types";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import React from "react";
import { blue, green, red } from "@ant-design/colors";

export const StatusIcon: Partial<Record<UploadFileStatus, React.ReactNode>> = {
    [UploadFileStatus.READY]: <LoadingOutlined />,
    [UploadFileStatus.PROGRESS]: <LoadingOutlined style={{ color: blue.primary }} />,
    [UploadFileStatus.DONE]: <CheckCircleOutlined style={{ color: green.primary }} />,
    [UploadFileStatus.ERROR]: (
        <Flex align={"center"} gap={15}>
            <ReloadOutlined
                // onClick={handleFileUploader}
                style={{ cursor: "pointer" }}
            />
            <CloseCircleOutlined style={{ color: red.primary }} />
        </Flex>
    ),
};
