import React, { useContext } from "react";
import { SHConverter } from "../../../../../../scripts/core/SollHaben";
import { BuTaxesSKR } from "../../../../../../scripts/models/BuTaxUtils";
import { Category } from "../../../../../../scripts/models";
import { logger } from "../../../../../../scripts/infrastructure/logger";
import { Base, KontoNumUtils, Periods } from "@binale-tech/shared";
import { BWATableRecord } from "./useBWAColumns";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { KontoContext } from "scripts/context/KontoEntitiesProvider";
import { BuContext } from "scripts/context/BuContext";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { PaymentsContext } from "scripts/context/PaymentsProvider";

const emptyMonthValues = new Map(new Array(12).fill(0).map((v, idx) => [idx, 0]));

export const useBWAAccountMap = () => {
    const { companyBuTimeframes } = useContext(BuContext);
    const { yearConfig } = useContext(CompanyContext);
    const { allRecords } = useContext(RecordsContext);
    const { year } = useContext(YearPeriodContext);
    const { categoryOverrides } = useContext(KontoContext);

    const yearRecords = React.useMemo(
        () => (allRecords.list || []).filter(record => record.date.getFullYear() === year && !record.draft),
        [allRecords, year]
    );
    const payments = useContext(PaymentsContext);
    const bwaData: Map<string, BWATableRecord> = React.useMemo(() => {
        if (!yearConfig || yearRecords.length === 0) {
            return new Map();
        }
        if (!companyBuTimeframes) {
            return new Map();
        }
        if (yearConfig.skr === 3) {
            return new Map();
        }
        const { skr, kontoExt } = yearConfig;
        const converter = new SHConverter(yearConfig, companyBuTimeframes, payments);
        const defaultCategories =
            BuTaxesSKR.getBuTimeframeYearPeriod(skr, companyBuTimeframes, year, 1)?.defaultCategories || new Map();

        const bwa = new Map<string, BWATableRecord>();

        // userSavedAccounts - collecting user data to retrieve later names for labels later on
        const userSavedAccounts = new Map<string, Category>();

        [...categoryOverrides].forEach((v: Base.IExtNum) => {
            userSavedAccounts.set(v.getExtNum(kontoExt), v as Category);
        });
        const categories = new Map<string, Category>();

        const bwaAdd = (item: { amount: number; isHaben: boolean }, month: number, accountNum: string) => {
            if (!bwa.has(accountNum)) {
                const record: BWATableRecord = {
                    key: Number(accountNum),
                    konto: categories.get(accountNum),
                    monthAmounts: new Map(emptyMonthValues),
                };
                bwa.set(accountNum, record);
            }
            const pa = bwa.get(accountNum).monthAmounts;

            if (item.isHaben) {
                pa.set(month, pa.get(month) - item.amount);
            } else {
                pa.set(month, pa.get(month) + item.amount);
            }
        };

        yearRecords.forEach(v => {
            converter.getAllItems(v).forEach(item => {
                if (!item.konto) {
                    logger.log("empty item, konto: ", item);
                }
                const key = item.konto.getExtNum(kontoExt);
                if (item.konto.num === "") {
                    logger.log("konto num is empty", item.konto.name, item, v);
                    return;
                }
                if (!item.konto.name) {
                    logger.log("konto name is empty", v);
                }
                if (KontoNumUtils.isCreditor({ extNum: item.konto }, kontoExt)) {
                    return;
                }
                if (KontoNumUtils.isDebitor({ extNum: item.konto }, kontoExt)) {
                    return;
                }
                // if it's one of saved categories, use it then
                if (userSavedAccounts.has(key)) {
                    categories.set(key, userSavedAccounts.get(key));
                } else {
                    // otherwise
                    // if it's a default category - use name from the latest default categories
                    const minLenNum = item.konto.getExtNum(0);
                    const konto = defaultCategories.get(Number(minLenNum));
                    categories.set(key, konto);
                }

                const getHRMonthFromPeriod = () => {
                    if (v.period === Periods.Period.FirstDayOfYear) {
                        return Periods.Period.January;
                    }
                    if (v.period === Periods.Period.LastDayOfYear) {
                        return Periods.Period.December;
                    }
                    return v.period;
                };
                bwaAdd(item, getHRMonthFromPeriod() - 1, key);
            });
        });
        return bwa;
    }, [yearConfig, yearRecords, companyBuTimeframes, payments, year, categoryOverrides]);
    return bwaData;
};
