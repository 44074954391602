import React, { FC, memo } from "react";
import { InvoiceTableBlock } from "@inv/components/InvoiceTableBlock";
import { InvoiceTableProvider } from "./context/InvoiceTableProvider";

const InvoiceTableModule: FC = () => {
    return (
        <InvoiceTableProvider>
            <InvoiceTableBlock />
        </InvoiceTableProvider>
    );
};
export default memo(InvoiceTableModule);
