import React, { type FC } from "react";

import { SingleDocumentForm } from "@dms/components/DocumentForm/SingleDocumentForm";
import { DocumentInfo } from "@dms/components/DocumentInfo/DocumentInfo";
import { IDocumentEnriched } from "@dms/types";
import { SingleFormProvider } from "./context/SingleFormContext";

interface IProps {
    documentInitialValue?: IDocumentEnriched;
    isReadOnly?: boolean;
}

export const SingleFormModule: FC<IProps> = React.memo(({ documentInitialValue, isReadOnly }) => {
    return documentInitialValue ? (
        <>
            <DocumentInfo document={documentInitialValue} />
            <SingleFormProvider documentInitialValue={documentInitialValue} isReadOnly={isReadOnly}>
                <SingleDocumentForm />
            </SingleFormProvider>
        </>
    ) : null;
});
