import React, { useContext, useEffect, useState } from "react";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { PdfViewer } from "@ui-components/PdfViewer/PdfViewer";
import { getAppCheckToken } from "../../../../scripts/api/firebase/firebase";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

interface Props {
    fileId: string;
}

const FileRender: React.FC<Props> = ({ fileId }) => {
    const [url, setUrl] = useState<string>();
    const [appCheckToken, setAppCheckToken] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const { documentsKV } = useContext(DmsDataContext);

    useEffect(() => {
        const doc = documentsKV[fileId];
        if (doc?.fileUrl) {
            getAppCheckToken().then(token => {
                setAppCheckToken(token);
                setUrl(doc.fileUrl);
                setFileName(DmsUtils.getDownloadName(doc));
            });
        }
    }, [documentsKV, fileId]);

    return url && <PdfViewer url={url} fileName={fileName} appCheckToken={appCheckToken} />;
};

export default React.memo(FileRender);
