import React, { FC, useContext } from "react";
import { BankModal } from "banks/components/BankModal";
import { BanksAppContext } from "@banks/scripts/context";
import { BankUpdateForm } from "banks/modules/BankUpdateModule/components/BankUpdateForm";
import { FormattedMessage } from "react-intl";

export const BankUpdateModule: FC = () => {
    const { editBankData } = useContext(BanksAppContext);

    return (
        <BankModal
            title={<FormattedMessage id={"app.banks.editBank"} />}
            open={editBankData.isModalOpen}
            footer={false}
            closable={false}
            style={{ maxWidth: "95vw" }}
            width={"800px"}
            destroyOnClose
        >
            <BankUpdateForm />
        </BankModal>
    );
};
