import React from "react";
import { useIntl } from "react-intl";
import { handleCopyField } from "../functions";

export enum TableCellAlignment {
    left = "left",
    center = "center",
    right = "right",
}

interface TableCellProps {
    headerTag?: boolean;
    width?: number;
    alignment?: TableCellAlignment;
    className?: string;
}

export const TableCell: React.FC<React.PropsWithChildren<TableCellProps>> = props => {
    const intl = useIntl();
    const style: React.CSSProperties = {
        width: props.width,
        maxWidth: props.width,
        textAlign: props.alignment,
    };

    if (props.headerTag) {
        return (
            <th className={props.className} style={style}>
                {props.children}
            </th>
        );
    }

    const title =
        typeof props.children === "string" && Number(style.width) < props.children.length * 6 ? props.children : null;

    return (
        <td
            onDoubleClick={e => handleCopyField(e, intl.formatMessage({ id: "app.notification.copiedToClipboard" }))}
            className={props.className}
            style={style}
            title={title}
        >
            {props.children}
        </td>
    );
};
export default React.memo(TableCell);
