import React, { useEffect, useState } from "react";
import { GQL, Utils } from "@binale-tech/shared";
import { Button, Form, Input, Select, Space } from "antd";
import { CurrencyValue } from "./BruttoInput";
import { DownloadOutlined } from "@ant-design/icons";
import { currencyOptions } from "./CurrencySelect";
import { useApolloClient } from "@apollo/client";
import { retrieveCurrencyRate } from "./retrieveCurrencyRate";
import { FieldError } from "react-hook-form";

export type CurrencyInputBlockProps = {
    currencyValue: CurrencyValue;
    allowConfigEdit?: boolean;
    label?: React.ReactNode;
    fieldError?: FieldError;
    onChange?: (value: CurrencyValue) => void;
    recordDate?: Date;
};

const formatDefault = (v: number, precision = Utils.CurrencyUtils.CurrencyFormatter.PRECISION) =>
    Utils.CurrencyUtils.currencyFormat(v || 0, precision);
const parseNumber = (v: string) => {
    return Number(v.replaceAll(".", "").replace(",", "."));
};

const numberInputHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const val: string = e.currentTarget.value;
    if (!/[0-9,-]|(Backspace|ArrowRight|ArrowLeft)/.test(e.key)) {
        e.preventDefault();
    }
    if (val.includes(",") && e.key === ",") {
        e.preventDefault();
    }
};
const composeCurrencyValue = (
    euroValue: number | null,
    code: GQL.ICurrencyCode,
    originalAmountStr: string,
    rateStr: string
): CurrencyValue => {
    const value: CurrencyValue = {
        amount: euroValue,
    };
    if (code !== GQL.ICurrencyCode.Eur) {
        value.originalAmount = Math.round(parseNumber(originalAmountStr) * 100);
        value.currency = {
            rate: parseNumber(rateStr),
            code,
        };
    }
    return value;
};

const getEuroAmount = (originalAmountStr: string, rateStr: string) => {
    const originalAmountNum = Math.round(parseNumber(originalAmountStr) * 100);
    return Utils.CurrencyUtils.getEuroFromCurrency(originalAmountNum, parseNumber(rateStr) ?? 1);
};
export const CurrencyInputBlock = React.forwardRef<any, CurrencyInputBlockProps>(
    function CurrencyInputBlock(props, ref) {
        const client = useApolloClient();
        const [isRateLoading, setIsRateLoading] = useState(false);
        const { allowConfigEdit, label, currencyValue, fieldError } = props;
        const ccCode = currencyValue.currency?.code || GQL.ICurrencyCode.Eur;
        const ccRate = currencyValue.currency?.rate ?? 1;

        const onRetrieveCurrencyRate = async (currencyCode: GQL.ICurrencyCode) => {
            return retrieveCurrencyRate({
                client,
                currencyCode,
                documentDate: props.recordDate,
            });
        };

        const [originalAmountStr, setOriginalAmountStr] = useState(
            formatDefault(currencyValue.originalAmount ?? currencyValue.amount ?? 0)
        );
        const [rateStr, setRateStr] = useState(Utils.CurrencyUtils.numberFormat(ccRate, 4));
        const [euroValueRes, setEuroValueRes] = useState(currencyValue.amount);

        useEffect(() => {
            setOriginalAmountStr(formatDefault(currencyValue.originalAmount ?? currencyValue.amount ?? 0));
            setRateStr(Utils.CurrencyUtils.numberFormat(currencyValue.currency?.rate ?? 1, 4));
            setEuroValueRes(currencyValue.amount);
        }, [currencyValue]);

        useEffect(() => {
            setEuroValueRes(getEuroAmount(originalAmountStr, rateStr));
        }, [rateStr, originalAmountStr]);

        const sendBack = (code?: GQL.ICurrencyCode) => {
            const codeNow = code || ccCode;
            const value = composeCurrencyValue(euroValueRes, codeNow, originalAmountStr, rateStr);
            props.onChange && props.onChange(value);
        };
        const handleCurrencyClick = () => {
            setIsRateLoading(true);
            onRetrieveCurrencyRate(ccCode)
                .then(rate => {
                    const stringRate = rate.toFixed(4).replace(".", ",");
                    setRateStr(stringRate);

                    const euroAmount = getEuroAmount(originalAmountStr, stringRate);
                    const value = composeCurrencyValue(euroAmount, ccCode, originalAmountStr, stringRate);
                    props.onChange && props.onChange(value);
                })
                .finally(() => setIsRateLoading(false));
        };

        return (
            <Form.Item label={label} style={{ marginBottom: 0 }} validateStatus={fieldError ? "error" : ""}>
                <Space.Compact block>
                    <Select
                        value={currencyValue.currency?.code || GQL.ICurrencyCode.Eur}
                        onChange={sendBack}
                        style={{ width: 75 }}
                        disabled={!allowConfigEdit}
                        data-testid="select-currency"
                        options={currencyOptions}
                    />
                    <Input
                        ref={ref}
                        style={{ width: 110, textAlign: "center" }}
                        value={originalAmountStr}
                        placeholder="Amount"
                        onChange={e => {
                            setOriginalAmountStr(e.target.value);
                        }}
                        onBlur={() => sendBack()}
                        onFocus={e => e.target.select()}
                        onKeyPress={numberInputHandler}
                        data-testid="amount"
                    />
                    <div style={{ position: "relative" }}>
                        <Input
                            style={{ width: 100, textAlign: "center" }}
                            placeholder="Rate"
                            disabled={ccCode === GQL.ICurrencyCode.Eur || !allowConfigEdit}
                            value={rateStr}
                            onChange={e => {
                                setRateStr(e.target.value);
                            }}
                            onBlur={() => sendBack()}
                            onKeyPress={numberInputHandler}
                            data-testid="rate"
                        />
                        {ccCode !== GQL.ICurrencyCode.Eur && (
                            <div style={{ position: "absolute", top: -5, right: -5, zIndex: 100, borderRadius: "50%" }}>
                                <Button
                                    tabIndex={-1}
                                    size="small"
                                    icon={<DownloadOutlined />}
                                    style={{ borderRadius: "50%" }}
                                    onClick={handleCurrencyClick}
                                    loading={isRateLoading}
                                />
                            </div>
                        )}
                    </div>
                    <Input
                        style={{
                            width: 136,
                            textAlign: "center",
                        }}
                        value={formatDefault(euroValueRes ?? 0)}
                        disabled
                        suffix="EUR"
                        data-testid="final-amount"
                    />
                </Space.Compact>
            </Form.Item>
        );
    }
);
