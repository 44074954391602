import React from "react";
import TableCell, { TableCellAlignment } from "./TableCell";
import cn from "classnames";
import { ResizableBox } from "react-resizable";

import "./TableHeaderCell.css";
import "react-resizable/css/styles.css";

interface TableHeaderCellProps {
    width?: number;
    widthConstraints?: {
        min: number;
        max: number;
    };
    alignment?: TableCellAlignment;
    direction?: "asc" | "desc" | null;
    onClick?: () => void;
    onResize?: (width: number) => void;
    className?: string;
}

const TableHeaderCell: React.FC<React.PropsWithChildren<TableHeaderCellProps>> = ({
    alignment = TableCellAlignment.left,
    width = null,
    ...props
}) => {
    const handleResize: React.ComponentProps<typeof ResizableBox>["onResize"] = (e, data) => {
        props.onResize && props.onResize(data.size.width);
    };

    const handleClick = () => {
        props.onClick && props.onClick();
    };

    const caretClassName = cn("rw-i", {
        "rw-i-caret-up": props.direction === "asc",
        "rw-i rw-i-caret-down": props.direction === "desc",
    });

    let content = (
        <div onClick={handleClick}>
            {props.children}
            {!!props.direction && <span className={caretClassName} />}
        </div>
    );

    if (props.onResize) {
        const c = props.widthConstraints || {
            min: 0,
            max: 10000,
        };
        content = (
            <ResizableBox
                width={width}
                height={20}
                axis="x"
                onResize={handleResize}
                minConstraints={[c.min, 20]}
                maxConstraints={[c.max, 20]}
                className="resizable"
            >
                {content}
            </ResizableBox>
        );
    }
    return (
        <TableCell
            headerTag
            width={width}
            alignment={alignment}
            className={cn(props.className, { "Table__HeaderCell--sorted": !!props.direction })}
        >
            {content}
        </TableCell>
    );
};

export default React.memo(TableHeaderCell);
