export const sizeCalcFromParent = (parentElementId: string, scale: number) => {
    try {
        const parentWidth = document.getElementById(parentElementId).offsetWidth;
        const parentHeight = document.getElementById(parentElementId).offsetHeight;
        let width = parentWidth * scale;
        let height = width * 1.4;

        if (height > parentHeight) {
            height = parentHeight * scale;
            width = height / 1.4;
        }
        return { width, height };
    } catch {
        return { width: 0, height: 0 };
    }
};

export const getElemSize = (elemId: string) => {
    try {
        const elem = document.getElementById(elemId);
        return { width: elem.offsetWidth, height: elem.offsetHeight };
    } catch {
        return { width: 0, height: 0 };
    }
};

export const getChildWidth = (elemId: string) => {
    try {
        const element = document.getElementById(elemId);
        const childEl = element.children[0] as HTMLElement;
        return childEl.offsetWidth;
    } catch {
        return 0;
    }
};
