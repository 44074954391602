import React from "react";

export const ImpressumView = () => (
    <div style={{ padding: 20 }}>
        <h1>Impressum</h1>
        <p>Angaben gem&auml;&szlig; &sect; 5 TMG</p>
        <h2>Binale GmbH & Co. KG</h2>
        <p>
            Industriestr. 5 <br />
            77955 Ettenheim <br />
            Deutschland
        </p>
        <p>
            Telefon: +49 7822 417 33 46 <br />
            E-Mail: info@binale.de
        </p>
        <p>
            Umsatzsteuer-Identifikationsnummer: DE 323 312 041 <br />
            Registergericht: Amtsgericht Freiburg im Breisgau <br />
            Registernummer: HRA 705921
        </p>
        <p>
            Komplement&auml;rin: LETRIX Verwaltung GmbH (Sitz in Berlin) <br />
            Gesch&auml;ftsf&uuml;hrer: Oliver Weigel <br />
            Registergericht: Amtsgericht Charlottenburg <br />
            Registernummer: HRB 145143 B
        </p>
        <p>
            Verantwortlich f&uuml;r den Inhalt nach &sect; 55 Abs. 2 RStV ist <br />
            Oliver Weigel <br />
            Binale GmbH & Co. KG <br />
            Industriestr. 5 <br />
            77955 Ettenheim <br />
            Deutschland
        </p>
    </div>
);
