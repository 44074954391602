import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Flex, Typography } from "antd";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../scripts/routing/routeConstants";
import { InvoicesPaths } from "@inv/types";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import Search from "antd/es/input/Search";
import { InvoicesDataContext, InvoicesListControlContext } from "@inv/context/InvoicesDataProvider";

export const InvoiceListHeader: FC = () => {
    const { searchValue } = useContext(InvoicesDataContext);
    const { setSearchValue } = useContext(InvoicesListControlContext);
    const [inputValue, setInputValue] = useState(searchValue ?? "");

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            setSearchValue(inputValue);
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    return (
        <Flex align="center" justify="space-between" style={{ width: "100%", marginBottom: "10px" }}>
            <Typography.Title level={3}>
                <FormattedMessage id={"app.invoice.invoicesList"} />
            </Typography.Title>
            <Flex align="center" justify="flex-end" gap={10}>
                <Search style={{ width: "230px" }} onChange={handleInput} value={inputValue} />
                <Link to={AppRoutes.invoices + `/${InvoicesPaths.FORM}`}>
                    <Button type={"primary"} icon={<PlusOutlined />}>
                        <FormattedMessage id={"app.invoice.createInvoice"} />
                    </Button>
                </Link>
            </Flex>
        </Flex>
    );
};
