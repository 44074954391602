import React, { useContext } from "react";
import { DmsAppContext } from "@dms/types/ContextTypes";
import { DiffOutlined } from "@ant-design/icons";

const ItemsTemplate = () => {
    const { selectedRowKeys } = useContext(DmsAppContext);

    if (selectedRowKeys.length > 0) {
        return (
            <div>
                <DiffOutlined /> Move {selectedRowKeys.length} documents
            </div>
        );
    } else {
        return (
            <div>
                <DiffOutlined /> Move 1 document
            </div>
        );
    }
};

export default ItemsTemplate;
