import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Button, Flex, Popconfirm, Progress } from "antd";
import { CloseOutlined, DownloadOutlined, ExperimentOutlined, ExportOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

import { PdfToolsContext } from "../../context";
import { OcrLoadedFile } from "../../types";
import { GQL } from "@binale-tech/shared";
import { useCanWrite } from "../../../scripts/infrastructure/hooks";

interface ProgressProps {
    file: OcrLoadedFile;
    onDownloadClick: () => Promise<void>;
    onExport: () => void;
    onClear: () => void;
    setShowDebug: (show: boolean) => void;
}

export const ButtonsBlock: FC<ProgressProps> = ({ file, onDownloadClick, onExport, onClear, setShowDebug }) => {
    const { decodeProgress, isExportProgress } = useContext(PdfToolsContext);
    const [downloading, setDownloading] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [btnMiniatures, setBtnMiniatures] = useState(false);

    const ref = useRef(null);

    const handleDownload = async () => {
        setDownloading(true);
        await onDownloadClick();
        setDownloading(false);
    };

    const handleClear = () => {
        setConfirmVisible(false);
        onClear();
    };

    const handleClose = () => setConfirmVisible(false);
    const handleOpen = () => setConfirmVisible(true);

    useEffect(() => {
        const resize = () => {
            const width =
                window.getComputedStyle && parseFloat(window.getComputedStyle(ref.current).getPropertyValue("width"));
            setBtnMiniatures(width < 450);
        };
        resize();
        addEventListener("resize", resize);
        return () => removeEventListener("resize", resize);
    }, []);

    const canWriteToDMS = useCanWrite(GQL.IProductKey.Dms);

    return (
        <Flex ref={ref} className="pdf-tools-ProcessBlock-wrapper" justify="end" align="center" gap={10}>
            <Flex
                gap={10}
                justify={btnMiniatures ? "end" : "space-between"}
                align="center"
                style={{ width: "100%", zIndex: 20 }}
            >
                <Flex gap={10} justify={"start"} align="center">
                    {file?.decoded ? (
                        <>
                            <Button
                                size="middle"
                                type="primary"
                                onClick={handleDownload}
                                shape={btnMiniatures ? "circle" : "round"}
                                icon={<DownloadOutlined />}
                                loading={downloading}
                                disabled={downloading}
                            >
                                {btnMiniatures || <FormattedMessage id="app.button.download" tagName="span" />}
                            </Button>
                            {canWriteToDMS && (
                                <Button
                                    size="middle"
                                    shape={btnMiniatures ? "circle" : "round"}
                                    loading={isExportProgress}
                                    disabled={isExportProgress}
                                    icon={<ExportOutlined />}
                                    onClick={onExport}
                                >
                                    {btnMiniatures || <FormattedMessage id="app.button.send_to_dms" />}
                                </Button>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </Flex>

                <Popconfirm
                    title={<FormattedMessage id="app.confirmation.header" />}
                    description={<FormattedMessage id="app.confirmation.pdf.finish" />}
                    okText={<FormattedMessage id="app.global.yes" />}
                    cancelText={<FormattedMessage id="app.global.no" />}
                    open={confirmVisible}
                    onConfirm={handleClear}
                    onCancel={handleClose}
                >
                    <Button
                        size="middle"
                        onClick={handleOpen}
                        icon={<CloseOutlined />}
                        shape={btnMiniatures ? "circle" : "round"}
                    >
                        {btnMiniatures || <FormattedMessage id="app.button.finish" tagName="span" />}
                    </Button>
                </Popconfirm>
            </Flex>
            {decodeProgress ? (
                <Progress percent={decodeProgress || 0} type="circle" size={32} />
            ) : (
                <Button
                    icon={<ExperimentOutlined />}
                    size="middle"
                    shape={btnMiniatures ? "circle" : "round"}
                    style={{ zIndex: 200 }}
                    onClick={() => setShowDebug(true)}
                />
            )}
        </Flex>
    );
};
