import React, { useContext } from "react";
import { ReadinessContext } from "scripts/context/DataReadyness";
import { Tooltip } from "antd";

const states = {
    online: {
        state: "green",
        stateDesc: "Online. You are connected to the remote database.",
    },
    offline: {
        state: "red",
        stateDesc: "Offline. All changes will be saved when you connect to the internet.",
    },
    initializing: {
        state: "yellow",
        stateDesc: "Initializing. We are connecting to the database.",
    },
};

export const Indicator: React.FC = () => {
    const { isReady, isConnected, loadingTexts } = useContext(ReadinessContext);

    const state = React.useMemo(() => {
        if (!isConnected) {
            return states.offline;
        }
        return isReady ? states.online : states.initializing;
    }, [isConnected, isReady]);

    const lampClass = "led-lamp led-lamp-" + state?.state;
    const packageJson = JSON.parse(import.meta.env.VITE_PACKAGE_JSON || "{}");
    const binalePackages = Object.keys(packageJson.dependencies ?? {}).filter(key => key.startsWith("@binale"));

    return (
        <div className="Header--IndicatorContainer">
            <Tooltip
                placement="bottomRight"
                title={
                    <>
                        <div>{state.stateDesc}</div>
                        <div>{import.meta.env.VITE_VERSION || "local"}</div>
                        {binalePackages.map(key => (
                            <div key={key}>
                                {key}:&nbsp;{packageJson.dependencies[key]}
                            </div>
                        ))}
                        <div>
                            {loadingTexts.map((text, idx) => {
                                return <p key={idx}>{text}</p>;
                            })}
                        </div>
                    </>
                }
            >
                <i className={lampClass} />
            </Tooltip>
        </div>
    );
};

export default React.memo(Indicator);
