import { gql } from "@apollo/client";

/* ---------------- Categories ------------------- */

export const categorySave = gql`
    mutation categorySave($input: CategorySaveInput!) {
        categorySave(input: $input) {
            id
            name
        }
    }
`;
export const categoryDelete = gql`
    mutation categoryDelete($input: CompanyNumEntityDeleteInput!) {
        categoryDelete(input: $input)
    }
`;

/* ---------------- Creditors ------------------- */

export const creditorCreate = gql`
    mutation creditorCreate($input: CredDebCreateInput!) {
        creditorCreate(input: $input) {
            id
            name
        }
    }
`;

export const creditorUpdate = gql`
    mutation creditorUpdate($input: CredDebUpdateInput!) {
        creditorUpdate(input: $input) {
            id
            name
        }
    }
`;

export const creditorDelete = gql`
    mutation creditorDelete($input: CompanyNumEntityDeleteInput!) {
        creditorDelete(input: $input)
    }
`;

/* ---------------- Debitors ------------------- */

export const debitorCreate = gql`
    mutation debitorCreate($input: CredDebCreateInput!) {
        debitorCreate(input: $input) {
            id
            name
        }
    }
`;

export const debitorUpdate = gql`
    mutation debitorUpdate($input: CredDebUpdateInput!) {
        debitorUpdate(input: $input) {
            id
            name
        }
    }
`;

export const debitorDelete = gql`
    mutation debitorDelete($input: CompanyNumEntityDeleteInput!) {
        debitorDelete(input: $input)
    }
`;

/* ---------------- Tags ------------------- */

export const tagCreate = gql`
    mutation tagCreate($input: TagCreateInput!) {
        tagCreate(input: $input) {
            id
            name
        }
    }
`;

export const tagUpdate = gql`
    mutation tagUpdate($input: TagUpdateInput!) {
        tagUpdate(input: $input) {
            id
            name
        }
    }
`;

export const tagDelete = gql`
    mutation tagDelete($input: CompanyNumEntityDeleteInput!) {
        tagDelete(input: $input)
    }
`;
