import { blue } from "@ant-design/colors";
import { ConfigProvider, Switch } from "antd";
import React, { type FC, useContext } from "react";
import { FormattedMessage } from "react-intl";

import { DmsAppContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";

import styles from "./selectFilters.module.scss";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

export const SelectedFilterSwitch: FC = () => {
    const { selectedRowKeys, activeType } = useContext(DmsAppContext);

    const { userConfig, setFilterConfig } = useContext(DmsUserSettingsContext);

    const typeConfig = DmsUtils.getFilterConfig(userConfig.filterConfig, activeType);

    const handleSelectDraft = () => {
        if (!typeConfig) {
            return;
        }

        const selectedFilter = typeConfig.selectedFilter ?? false;

        setFilterConfig(activeType, {
            selectedFilter: !selectedFilter,
        });
    };

    return (
        <div>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: blue[5],
                        colorPrimaryHover: blue[4],
                        colorTextQuaternary: blue[2],
                        colorTextTertiary: blue[1],
                    },
                }}
            >
                <Switch
                    className={styles.switchWidth}
                    unCheckedChildren={<FormattedMessage id="app.components.nselected" />}
                    checkedChildren={<FormattedMessage id="app.components.nselected" />}
                    onChange={handleSelectDraft}
                    checked={typeConfig?.selectedFilter ?? false}
                    disabled={selectedRowKeys.length === 0}
                />
            </ConfigProvider>
        </div>
    );
};
