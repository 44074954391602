import React, { useRef, useState } from "react";
import { AutoComplete, AutoCompleteProps, RefSelectProps, Select, SelectProps } from "antd";

export const BaseAutocomplete = React.forwardRef<RefSelectProps, AutoCompleteProps>(
    function BaseAutocomplete(props, ref) {
        const [openList, setOpenList] = useState(false);
        const enterPressed = useRef(false);

        return (
            <AutoComplete
                ref={ref}
                {...props}
                onBlur={e => {
                    if (openList) {
                        setOpenList(false);
                    }
                    props.onBlur && props.onBlur(e);
                }}
                onDropdownVisibleChange={open => {
                    if (!enterPressed.current) {
                        setOpenList(open);
                    }
                }}
                open={openList}
                onInputKeyDown={e => {
                    if (e.key === "Enter") {
                        enterPressed.current = true;
                    }
                    props.onInputKeyDown && props.onInputKeyDown(e);
                }}
                onKeyUp={e => {
                    if (e.key === "Enter") {
                        enterPressed.current = false;
                    }
                    props.onKeyUp && props.onKeyUp(e);
                }}
            />
        );
    }
);

export const BaseSelect = React.forwardRef<RefSelectProps, SelectProps>(function BaseSelect(props, ref) {
    const [openList, setOpenList] = useState(false);
    const enterPressed = useRef(false);

    return (
        <Select
            ref={ref}
            {...props}
            onDropdownVisibleChange={open => {
                if (!enterPressed.current) {
                    setOpenList(open);
                }
            }}
            open={openList}
            onInputKeyDown={e => {
                if (e.key === "Enter") {
                    enterPressed.current = true;
                }
                props.onInputKeyDown && props.onInputKeyDown(e);
            }}
            onKeyUp={e => {
                if (e.key === "Enter") {
                    enterPressed.current = false;
                }
                props.onKeyUp && props.onKeyUp(e);
            }}
        />
    );
});
