import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import style from "./TypeSelectBtn.module.scss";
import { ALL_DOCUMENTS, DmsTypeOptions } from "@dms/configs/constants";
import { Divider, Flex, Select } from "antd";
import { FormattedMessage } from "react-intl";
import { getSubTypeOptions } from "@dms/scripts/helpers";
import { DmsType } from "@dms/types";
import { DmsDataContext } from "@dms/types/ContextTypes";
import type { BaseSelectRef } from "rc-select";

type TProps = {
    handleTypeSelect: (value: string) => void;
    selectedType: string | undefined;
    setSelectedType: Dispatch<SetStateAction<string>>;
    selectedSubType: string | undefined;
    setSelectedSubType: Dispatch<SetStateAction<string | undefined>>;
};

interface IOption {
    value?: string;
    label?: React.ReactNode;
    disabled?: boolean;
    options?: TOptions;
    className?: string;
}

type TOptions = IOption[];

export const TypeSelectBtn: FC<TProps> = ({
    handleTypeSelect,
    selectedType,
    setSelectedType,
    selectedSubType,
    setSelectedSubType,
}) => {
    const [disableSubtype, setDisableSubtype] = useState(true);
    const [subtypeOptions, setSubtypeOptions] = useState<TOptions>();

    const { documentTypes } = useContext(DmsDataContext);

    const subtypeRef = useRef<BaseSelectRef>(null);

    const docTypeOptions: TOptions = DmsTypeOptions.map(({ value, translationKey, isDivider }) => {
        if (isDivider) {
            return {
                label: <Divider className={style.selectorDivider} />,
                disabled: true,
                className: `${style.selectorOption} ${style.selectorOptionDisabled}`,
            };
        }

        return {
            label: <FormattedMessage id={translationKey} />,
            value,
        };
    });

    const options: TOptions = [
        {
            label: <FormattedMessage id="app.dms.allDocuments" />,
            value: ALL_DOCUMENTS,
        },
        ...docTypeOptions,
    ];

    useEffect(() => {
        const finedType = DmsTypeOptions.find(({ value }) => value === selectedType);
        if (finedType && finedType.value && finedType.isGroup) {
            setDisableSubtype(false);

            const subTypeOptions = getSubTypeOptions(documentTypes, selectedType as DmsType);

            if (finedType.defaultSubtypes) {
                const defaultSubtypesArr = finedType.defaultSubtypes
                    .filter(el => el.value)
                    .map(defaultSubtype => {
                        return {
                            value: defaultSubtype.value,
                            label: defaultSubtype.translationKey ? (
                                <>
                                    <FormattedMessage id={defaultSubtype.translationKey} />
                                </>
                            ) : (
                                `${defaultSubtype.value} / ${defaultSubtype.value}`
                            ),
                            disabled: false,
                        };
                    });

                const subType: string | undefined = defaultSubtypesArr[0].value;
                setSelectedSubType(subType);
                return setSubtypeOptions([...defaultSubtypesArr, ...subTypeOptions]);
            }
            setSelectedSubType(subTypeOptions[0].value);
            return setSubtypeOptions(subTypeOptions);
        }

        setDisableSubtype(true);
        setSelectedSubType(undefined);
    }, [documentTypes, selectedType, setSelectedSubType]);

    const handleChangeType = (value: string) => {
        handleTypeSelect(value);
        setSelectedType(value);
        setSelectedSubType(undefined);
    };

    useEffect(() => {
        if (!disableSubtype) {
            subtypeRef.current.focus();
        }
    }, [disableSubtype]);

    const secondOnSelect = (value: string) => {
        setSelectedSubType(value);
    };

    return (
        <Flex align={"center"} justify={"flex-start"}>
            <Select value={selectedType} onChange={handleChangeType} options={options} style={{ minWidth: "200px" }} />
            <Select
                value={selectedSubType}
                onChange={value => secondOnSelect(value)}
                options={subtypeOptions}
                disabled={disableSubtype}
                style={{ minWidth: "200px", marginLeft: "20px" }}
                ref={subtypeRef}
            />
        </Flex>
    );
};
