import React, { type FC, useCallback } from "react";
import { DmsViewList } from "@dms/components/DocumentCarouselItems/DmsViewList";
import { CarouselHeader } from "./components/CarouselHeader";
import { IDocumentEnriched, TCarouselMode } from "@dms/types";
import styles from "./documentCarousel.module.scss";
import { PdfViewList } from "@pdf-tools/components/PdfPreviewBlock/components/PdfViewList";
import { OcrLoadedFile } from "@pdf-tools/types";

interface IProps<T> {
    documents: T[];
    id: string | undefined;
    setDocumentId: (arg: string) => void;
    reorderingFiles?: (files: OcrLoadedFile[]) => void;
    mode?: TCarouselMode;
}

export const Carousel: FC<IProps<IDocumentEnriched | OcrLoadedFile>> = ({
    documents,
    id,
    setDocumentId,
    reorderingFiles,
    mode,
}) => {
    const handleClick = useCallback(
        (i: string) => {
            setDocumentId(i);
        },
        [id]
    );

    const renderList = () => {
        if (mode === "PDF_Module") {
            return (
                <div className={styles.pdfToolsViewListWrapper}>
                    <PdfViewList
                        documents={documents as OcrLoadedFile[]}
                        reorderingFiles={reorderingFiles}
                        handleClick={handleClick}
                        id={id}
                    />
                </div>
            );
        }

        return (
            <div className={styles.dmsViewListWrapper}>
                <DmsViewList documents={documents as IDocumentEnriched[]} handleClick={handleClick} id={id} />
            </div>
        );
    };
    return (
        <div style={{ width: "100%" }}>
            <CarouselHeader documents={documents as IDocumentEnriched[]} mode={mode} />
            <>{renderList()}</>
        </div>
    );
};
