import React, { type FC } from "react";
import { Typography } from "antd";

interface IProps {
    fileName: string;
}

const { Text } = Typography;

export const NotValidFileItem: FC<IProps> = ({ fileName }) => {
    return (
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Text type={"danger"}>{fileName}</Text>
        </div>
    );
};
