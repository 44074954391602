import React, { FC } from "react";
import { DebProvider } from "../DebProvider";
import { TemplatesViewContent } from "@app/views/productSharedComponents/templates/TemplatesViewContent";

export const DebTemplatesView: FC = () => {
    return (
        <DebProvider>
            <TemplatesViewContent />
        </DebProvider>
    );
};
