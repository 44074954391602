import { gql } from "@apollo/client";

export const paymentsSave = gql`
    mutation paymentsSave($input: PaymentsSaveInput!) {
        paymentsSave(input: $input) {
            status
            summary {
                productId
                action
                id
            }
        }
    }
`;
