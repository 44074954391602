import { RefSelectProps, Select } from "antd";
import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { TOptions } from "@dms/components/DocumentForm/types";

interface IProps {
    onChange?: (v: any) => void;
    value?: any;
    options: TOptions;
    placeholder?: string;
    disabled?: boolean;
}

export const CountryCodeInput = forwardRef<RefSelectProps, IProps>(
    ({ value, onChange, options, ...restProps }, ref) => {
        const vatNbEl = document.getElementById("vatNbId") as HTMLInputElement | null;
        const [openOptions, setOpenOptions] = useState(false);
        const isNextFocus = useRef<boolean>(false);
        const handleOnChange = (arg: string): void => {
            onChange?.(arg);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
            if (e.shiftKey && e.key === "Enter") {
                return;
            }

            if (e.key === "Enter" && vatNbEl) {
                isNextFocus.current = true;
                return;
            }

            isNextFocus.current = false;
            return;
        };

        useEffect(() => {
            if (!vatNbEl) {
                return;
            }

            vatNbEl.disabled = Boolean(!value);

            if (isNextFocus.current && Boolean(value)) {
                vatNbEl.focus();
                isNextFocus.current = false;
            }
        }, [vatNbEl, value]);

        const handleClear = useCallback(() => {
            onChange?.(undefined);
        }, [onChange]);

        return (
            <Select
                {...restProps}
                id="euCountrySelectID"
                value={value}
                showSearch
                onChange={handleOnChange}
                options={options}
                onBlur={() => setOpenOptions(false)}
                onDropdownVisibleChange={setOpenOptions}
                onClear={handleClear}
                open={openOptions}
                onKeyDown={handleKeyDown}
                allowClear={value ?? false}
                ref={ref}
            />
        );
    }
);
