import React, { FC, useEffect, useState } from "react";
import { Badge, Button, Col, Descriptions, Flex, Form, message, Popconfirm, Row, Spin } from "antd";
import { datevTokenRefresh, datevTokenRevoke, redirectToDatevOAuth } from "scripts/infrastructure/oauth/oauth";
import { ExportOutlined, ReloadOutlined } from "@ant-design/icons";
import { useApolloClient } from "@apollo/client";
import { Datev, GQL } from "@binale-tech/shared";
import { DatevContext, DatevControlContext } from "scripts/context/DatevContext";
import { UserContext } from "scripts/context/UserProvider";
import { FormattedMessage } from "react-intl";
import { DatevButton } from "../../../components/oauth/DatevButton";
import { DatevDescriptions } from "../../../components/oauth/DatevDescriptions";
import { DatevClients } from "../../../components/oauth/DatevClients";

export const DatevSettings: FC = () => {
    const { fireUser } = React.useContext(UserContext);
    const { isLoading, token, datevUserInfo } = React.useContext(DatevContext);
    const { triggerRefetch } = React.useContext(DatevControlContext);
    const client = useApolloClient();
    const [authLoading, setAuthLoading] = React.useState(false);
    const [refreshLoading, setRefreshLoading] = React.useState(false);
    const [, setTick] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTick(i => ++i);
        }, 500);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let handleVisibilityChange = () => {};
        if (fireUser?.uid) {
            handleVisibilityChange = () => {
                if (document.visibilityState === "visible") {
                    triggerRefetch();
                }
            };
        }
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [client, fireUser?.uid, triggerRefetch]);

    const handleDatevAuth = async () => {
        setAuthLoading(true);
        await redirectToDatevOAuth(client, fireUser.uid);
        setAuthLoading(false);
    };
    const handleRefresh = () => {
        setRefreshLoading(true);
        client
            .mutate<Pick<GQL.IMutation, "datevTokenRefresh">, GQL.IMutationDatevTokenRefreshArgs>({
                mutation: datevTokenRefresh,
                variables: { input: { forceRefresh: true } },
            })
            .then(() => new Promise(resolve => setTimeout(resolve, 200)))
            .then(triggerRefetch)
            .catch(e => message.error(e.message))
            .finally(() => setRefreshLoading(false));
    };
    const handleDatevTokenRevocation = async () => {
        setRefreshLoading(true);
        client
            .mutate<Pick<GQL.IMutation, "datevTokenRevoke">>({
                mutation: datevTokenRevoke,
            })
            .then(() => new Promise(resolve => setTimeout(resolve, 200)))
            .then(triggerRefetch)
            .catch(e => message.error(e.message))
            .finally(() => setRefreshLoading(false));
    };
    const canRefresh = Datev.getRefreshTokenValidity(token) > 0;
    const canAccess = Datev.getAccessTokenValidity(token) > 0;
    return (
        <Spin spinning={isLoading}>
            <Row>
                <Col span={6}>
                    <Form layout="vertical">
                        {!datevUserInfo && (
                            <Form.Item>
                                <DatevButton onClick={handleDatevAuth} icon={<ExportOutlined />} loading={authLoading}>
                                    DATEV verbinden
                                </DatevButton>
                            </Form.Item>
                        )}

                        <Form.Item>
                            <Button
                                disabled={!canRefresh}
                                onClick={handleRefresh}
                                icon={<ReloadOutlined />}
                                loading={refreshLoading}
                                type={!canAccess && canRefresh ? "primary" : "default"}
                            >
                                Refresh Token
                            </Button>
                        </Form.Item>
                        {datevUserInfo && (
                            <Form.Item>
                                <Popconfirm
                                    onConfirm={handleDatevTokenRevocation}
                                    title={<FormattedMessage id="app.confirmation.header" />}
                                    okText={<FormattedMessage id="app.button.confirm" />}
                                    cancelText={<FormattedMessage id="app.button.cancel" />}
                                >
                                    <Button type="dashed" danger>
                                        DATEV {token ? "Verbindung" : "Cache"} löschen
                                    </Button>
                                </Popconfirm>
                            </Form.Item>
                        )}
                    </Form>
                </Col>

                <Col span={18}>
                    {datevUserInfo ? (
                        <>
                            <DatevDescriptions
                                title={
                                    <Flex align={"center"}>
                                        {token ? (
                                            <>
                                                <Badge status="processing" />
                                                <span>&nbsp;DATEV ist verbunden</span>
                                            </>
                                        ) : (
                                            <>
                                                <Badge status="success" />
                                                <span>&nbsp;DATEV (cache)</span>
                                            </>
                                        )}
                                    </Flex>
                                }
                                token={token}
                                datevUserInfo={datevUserInfo}
                            />
                            <DatevClients datevUserInfo={datevUserInfo} />
                        </>
                    ) : (
                        <Descriptions
                            title={
                                <Flex align={"center"}>
                                    <Badge status="error" />
                                    &nbsp;DATEV ist nicht verbunden
                                </Flex>
                            }
                        ></Descriptions>
                    )}
                </Col>
            </Row>
        </Spin>
    );
};
