import React from "react";
import { Contacts, GQL } from "@binale-tech/shared";
import { Controller, useFormContext } from "react-hook-form";
import { Divider, Row, Select, Space } from "antd";
import { FieldLabel } from "@ui-components/FieldLabel";
import { FormattedMessage } from "react-intl";

import { AppealCombobox, BaseInput, LegalFormSelect } from "appearance/components/shared/form/baseComponents";
import { RelationButtons } from "./RelationButtons";
import { useCustomValidation } from "../utils";
import { useValidation } from "scripts/infrastructure/helpers/validation";

interface Props {
    item: Contacts.Contact | null;
    defaultInternalName: string;
}
export const ContactBasicInfo = React.memo<Props>(function ContactBasicInfo({ item, defaultInternalName }) {
    const { register, setValue, watch } = useFormContext<Contacts.Contact>();
    const validation = useValidation();
    const customValidators = useCustomValidation(item);
    const contactType = watch("type");

    return (
        <>
            <input type="hidden" {...register("uuid")} data-testid="uuid" />
            <Space align="baseline">
                <Controller
                    defaultValue={defaultInternalName}
                    render={({ field, fieldState }) => (
                        <FieldLabel
                            label={<FormattedMessage id="app.fields.internalDesignation" />}
                            required
                            fieldError={fieldState.error}
                            style={{ minWidth: 250 }}
                        >
                            <BaseInput {...field} autoFocus data-testid="internalName" maxLength={30} showCount />
                        </FieldLabel>
                    )}
                    name="internalName"
                    rules={{ required: validation.required, validate: customValidators.internalName }}
                />
                <Controller
                    name="type"
                    rules={{ required: validation.required }}
                    render={({ field: { ref, ...restField }, fieldState }) => (
                        <FieldLabel
                            required
                            label={<FormattedMessage id="app.fields.type" />}
                            style={{ minWidth: 200 }}
                            fieldError={fieldState.error}
                        >
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                data-testid="contactType"
                                {...restField}
                                onChange={value => {
                                    restField.onChange(value);
                                    if ([GQL.IContactType.Organization, GQL.IContactType.Institution].includes(value)) {
                                        setValue("individualAppeal", undefined);
                                        setValue("individualFirstName", undefined);
                                        setValue("individualLastName", undefined);
                                        setValue("individualScienceDegree", undefined);
                                        setValue("legalInfo.IDNr", "");
                                    }
                                    if (value === GQL.IContactType.Individual) {
                                        setValue("organizationLegalForm", undefined);
                                        setValue("organizationName", undefined);
                                    }
                                }}
                                options={[
                                    {
                                        value: GQL.IContactType.Organization,
                                        label: <FormattedMessage id="app.contacts.type.organization" />,
                                    },
                                    {
                                        value: GQL.IContactType.Individual,
                                        label: <FormattedMessage id="app.contacts.type.individual" />,
                                    },
                                    {
                                        value: GQL.IContactType.Institution,
                                        label: <FormattedMessage id="app.contacts.type.institution" />,
                                    },
                                ]}
                            />
                        </FieldLabel>
                    )}
                />
            </Space>

            {[GQL.IContactType.Organization, GQL.IContactType.Institution].includes(contactType) && (
                <Space align="baseline">
                    <Controller
                        rules={{ required: validation.required }}
                        name="organizationName"
                        shouldUnregister
                        render={({ field, fieldState }) => (
                            <FieldLabel
                                required
                                label={<FormattedMessage id="app.fields.companyName" />}
                                style={{ minWidth: 400 }}
                                fieldError={fieldState.error}
                            >
                                <BaseInput data-testid="organizationName" {...field} maxLength={60} showCount />
                            </FieldLabel>
                        )}
                    />
                    <Controller
                        name="organizationLegalForm"
                        shouldUnregister
                        render={({ field: { ref, ...restField } }) => (
                            <FieldLabel
                                label={<FormattedMessage id="app.fields.legalForm" />}
                                style={{ minWidth: 350 }}
                            >
                                <LegalFormSelect data-testid="organizationLegalForm" allowClear {...restField} />
                            </FieldLabel>
                        )}
                    />
                </Space>
            )}

            {contactType === GQL.IContactType.Individual && (
                <>
                    <Row>
                        <Space align="baseline">
                            <Controller
                                name="individualAppeal"
                                shouldUnregister
                                render={({ field }) => (
                                    <FieldLabel
                                        label={<FormattedMessage id="app.fields.salutation" />}
                                        style={{ marginBottom: 0, minWidth: 120 }}
                                    >
                                        <AppealCombobox data-testid="individualAppeal" {...field} />
                                    </FieldLabel>
                                )}
                            />
                            <Controller
                                name="individualScienceDegree"
                                shouldUnregister
                                render={({ field }) => (
                                    <FieldLabel
                                        label={<FormattedMessage id="app.fields.title" />}
                                        style={{ marginBottom: 0, minWidth: 150 }}
                                    >
                                        <BaseInput
                                            data-testid="individualScienceDegree"
                                            {...field}
                                            maxLength={15}
                                            showCount
                                        />
                                    </FieldLabel>
                                )}
                            />
                        </Space>
                    </Row>
                    <Row>
                        <Space align="baseline">
                            <Controller
                                name="individualFirstName"
                                rules={{ required: validation.required }}
                                shouldUnregister
                                render={({ field, fieldState }) => (
                                    <FieldLabel
                                        required
                                        fieldError={fieldState.error}
                                        label={<FormattedMessage id="app.fields.firstName" />}
                                        style={{ minWidth: 200 }}
                                    >
                                        <BaseInput
                                            data-testid="individualFirstName"
                                            {...field}
                                            maxLength={20}
                                            showCount
                                        />
                                    </FieldLabel>
                                )}
                            />
                            <Controller
                                name="individualLastName"
                                rules={{ required: validation.required }}
                                shouldUnregister
                                render={({ field, fieldState }) => (
                                    <FieldLabel
                                        required
                                        fieldError={fieldState.error}
                                        label={<FormattedMessage id="app.fields.lastName" />}
                                        style={{ minWidth: 200 }}
                                    >
                                        <BaseInput
                                            data-testid="individualLastName"
                                            {...field}
                                            maxLength={20}
                                            showCount
                                        />
                                    </FieldLabel>
                                )}
                            />
                        </Space>
                    </Row>
                </>
            )}

            <Divider style={{ marginTop: 12, marginBottom: 12 }} />

            <RelationButtons />
        </>
    );
});
