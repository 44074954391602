import { GQL } from "@binale-tech/shared";
import { Input, InputProps, type InputRef } from "antd";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { floatInputBlocker, formatDefault, parseNumber } from "@dms/scripts/helpers";

interface IProps extends Omit<InputProps, "placeholder" | "onChange" | "disabled"> {
    placeholder?: string;
    disabled?: boolean;
    suffix?: GQL.ICurrencyCode;
    onChange?: (val: number | null) => void;
    value?: number;
}

export const DiscountCurrencyInput = forwardRef<InputRef, IProps>(function CurrencyInputBlock(props, ref) {
    const { value: currencyValue, suffix, placeholder, onChange, disabled } = props;
    const { formatMessage } = useIntl();

    const [inputValue, setInputValue] = useState<string>(() => formatDefault(currencyValue ?? 0));
    const [placeholderText, setPlaceholderText] = useState<string>(placeholder);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    }, []);

    const sendBack = useCallback(() => {
        if (onChange) {
            onChange(Math.round(parseNumber(inputValue) * 100));
        }
    }, [inputValue, onChange]);

    const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    }, []);

    useEffect(() => {
        if (!currencyValue) {
            setInputValue("");
        } else {
            setInputValue(formatDefault(currencyValue));
        }
    }, [currencyValue]);

    useEffect(() => {
        if (placeholder === "Diverse" && !disabled && onChange) {
            onChange(null);
            setPlaceholderText("");
        }
    }, [disabled, onChange, placeholder]);

    return (
        <Input
            ref={ref}
            value={inputValue}
            onChange={handleChange}
            onBlur={sendBack}
            onFocus={handleFocus}
            disabled={disabled}
            onKeyDown={e => floatInputBlocker(e, true)}
            placeholder={placeholderText || formatMessage({ id: "app.fields.discountPercent" })}
            suffix={suffix || GQL.ICurrencyCode.Eur}
        />
    );
});
