import { CompanyProductKontoPathOpts, CompanyProductPathOpts } from "./Opts";
import { LogItemRepository } from "./Abstract";
import {
    refRecordsBankLogs,
    refRecordsDebLog,
    refRecordsErALog,
    refRecordsERLog,
    refRecordsFELog,
    refRecordsKbLogs,
    refRecordsLALog,
    refRecordsPosLogs
} from "../../api/firebase/firebaseRootRefs";

// Logs
const AZLogsRepositoryInstance = new LogItemRepository<CompanyProductPathOpts>(refRecordsErALog);
const ERLogsRepositoryInstance = new LogItemRepository<CompanyProductPathOpts>(refRecordsERLog);
const FELogsRepositoryInstance = new LogItemRepository<CompanyProductPathOpts>(refRecordsFELog);
const POSLogsRepositoryInstance = new LogItemRepository<CompanyProductPathOpts>(refRecordsPosLogs);
const LALogsRepositoryInstance = new LogItemRepository<CompanyProductPathOpts>(refRecordsLALog);
const KBLogRepositoryInstance = new LogItemRepository<CompanyProductKontoPathOpts>(refRecordsKbLogs);
const BankLogsRepositoryInstance = new LogItemRepository<CompanyProductKontoPathOpts>(refRecordsBankLogs);
const DebLogsRepositoryInstance = new LogItemRepository<CompanyProductPathOpts>(refRecordsDebLog);

export class RecordLogRepository {
    static readonly ER = ERLogsRepositoryInstance;
    static readonly AZ = AZLogsRepositoryInstance;
    static readonly FE = FELogsRepositoryInstance;
    static readonly POS = POSLogsRepositoryInstance;
    static readonly LA = LALogsRepositoryInstance;
    static readonly Deb = DebLogsRepositoryInstance;
    static readonly KB = KBLogRepositoryInstance;
    static readonly Bank = BankLogsRepositoryInstance;
}
