import React, { FC, useContext } from "react";
import { Button, Flex } from "antd";
import { CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { BanksAppControlContext } from "@banks/scripts/context";
import { FormattedMessage } from "react-intl";

export const BankListHeader: FC = () => {
    const { setAddBankData, openTransactionUploader } = useContext(BanksAppControlContext);

    const handleAddBank = () => {
        setAddBankData({ isModalOpen: true });
    };

    const handleUpdateBank = () => {
        openTransactionUploader();
    };

    return (
        <Flex gap={15}>
            <Button type={"primary"} icon={<PlusOutlined />} onClick={handleAddBank}>
                <FormattedMessage id={"app.banks.newBank"} />
            </Button>
            <Button onClick={handleUpdateBank} icon={<CloudUploadOutlined />}>
                <FormattedMessage id={"app.banks.uploadTransactions"} />
            </Button>
        </Flex>
    );
};
