import React from "react";
import ReactDOM from "react-dom";

interface IFocusNextElementOptions {
    goBack?: boolean;
    activeElement?: HTMLElement;
    parentSelector: string;
}

export const FormClassName = "GenericRecordForm__container";
const focusableElements = `button:not([tabIndex="-1"]), input:not([tabIndex="-1"]), textarea:not([tabIndex="-1"])`;

export const focusNextElement = ({ goBack = false, activeElement, parentSelector }: IFocusNextElementOptions) => {
    const activeEl = activeElement || (document.activeElement as HTMLElement);
    if (!activeEl) {
        return;
    }

    const section = activeEl.closest(parentSelector);
    if (!section) {
        return;
    }
    const allEls = Array.from(section.querySelectorAll<HTMLElement>(focusableElements)).filter(
        element => element.offsetWidth > 0 || element.offsetHeight > 0 || element === activeEl
    );
    const index = allEls.indexOf(activeEl);
    if (index === -1) {
        return;
    }

    let focusableEls: HTMLElement[] = [];

    if (goBack) {
        focusableEls = allEls.slice(0, index).reverse();
    } else {
        focusableEls = allEls.slice(index + 1, allEls.length);
    }
    focusableEls = focusableEls.filter(el => !(el as HTMLInputElement).disabled);
    if (focusableEls.length) {
        focusableEls[0].focus();
    }
};

export const focusFirst = (parent: HTMLElement) => {
    if (!parent) {
        return;
    }
    const allEls = Array.from(parent.querySelectorAll<HTMLElement>(focusableElements)).filter(
        element => (element.offsetWidth > 0 || element.offsetHeight > 0) && !(element as HTMLInputElement).disabled
    );

    if (allEls.length) {
        allEls[0].focus();
    }
};

export const focusFirstFormField = () => {
    focusFirst(document.querySelector(`.${FormClassName}`));
};

export const focusNextFieldFromRef = (item: React.RefObject<any>, goBack = false) => {
    const activeEl = (ReactDOM.findDOMNode(item.current) as HTMLElement).querySelector("input");

    focusNextElement({
        goBack,
        parentSelector: `.${FormClassName}`,
        activeElement: activeEl,
    });
};

export const focusRef = (ref: React.RefObject<any>) => {
    const r = ref?.current;
    if (r) {
        if (typeof r.focus === "function") {
            return r.focus();
        }
        const domRef = ref as React.RefObject<HTMLButtonElement>;
        if (!domRef.current.disabled && typeof domRef.current.focus === "function") {
            return domRef.current.focus();
        }
    }
};
