import React from "react";
import { Spin } from "antd";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import TableContextMenu from "./ContextMenu/TableContextMenu";
import { Base } from "@binale-tech/shared";
import { CompanyContext } from "scripts/context/CompanyContext";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { GenericRecord } from "scripts/models/GenericRecord";
import { GenericRecordColumns } from "../../columns/ColumnConfig";
import {
    GenericRecordTableItem,
    TableColumn,
    TableItem,
    TableStateless,
    TableStatelessContextMenu
} from "../../components/shared/Table/Table";
import { IViewsKeys } from "scripts/models/User";
import { Product } from "scripts/core/Product";
import { RecordColumnsEnriched } from "../../columns/RecordColumnsEnriched";
import { TableContextMenuPortal } from "./ContextMenu/TableContextMenuPortal";
import { UserSettingsContext } from "../../../scripts/context/UserSettingsProvider";

const getTableSum = (vs: GenericRecordTableItem[], skr: Base.CompanySKR): TableSum => {
    const mainFooter = {
        bruttoSum: 0,
        vatSum: 0,
        nettoSum: 0,
        originalAmountSum: 0,
        openSum: 0,
        skontoSum: 0,
        zahlungSum: 0,
        saldoSum: 0,

        soll: 0,
        haben: 0,
    };
    const selectedFooter = {
        bruttoSum: 0,
        vatSum: 0,
        nettoSum: 0,
        originalAmountSum: 0,
        openSum: 0,
        skontoSum: 0,
        zahlungSum: 0,
        saldoSum: 0,

        soll: 0,
        haben: 0,
    };

    vs.forEach(v => {
        const payments = v.extra.payments.get(v.item);
        const skontoSum = payments.reduce((p, c) => p + c.skontoBetrag, 0);
        const zahlungSum = payments.reduce((p, c) => p + c.zahlungsBetrag, 0);
        const addSums = (container: GenericRecordTableSum, tableItem: GenericRecordTableItem) => {
            container.bruttoSum += tableItem.item.getBrutto();
            container.originalAmountSum += tableItem.item.getOriginalAmount();
            container.vatSum += tableItem.item.getVatEuro(skr);
            container.nettoSum += tableItem.item.getNetto(skr);
            container.openSum += tableItem.item.getOpenBrutto(payments);

            container.skontoSum += skontoSum;
            container.zahlungSum += zahlungSum;
            container.saldoSum += tableItem.item.getBrutto() - zahlungSum - skontoSum;
        };
        addSums(mainFooter, v);
        if (v.selected) {
            addSums(selectedFooter, v);
        }
    });
    return { mainFooter, selectedFooter };
};

export interface TableConfigFooters {
    [key: string]: { mainFooter: React.ReactNode; selectedFooter: React.ReactNode };
}
interface GenericRecordTableSum {
    bruttoSum?: number;
    nettoSum?: number;
    originalAmountSum?: number;
    vatSum?: number;
    openSum?: number;

    skontoSum?: number;
    zahlungSum?: number;
    saldoSum?: number;

    soll: number;
    haben: number;
}

export interface TableSum {
    mainFooter: GenericRecordTableSum;
    selectedFooter: GenericRecordTableSum;
}

interface Props {
    tableRef: React.RefObject<TableStateless<GenericRecord>>;
    tableItems: TableItem<GenericRecord>[];
    tableHeight: number;
    tableSaldoHeader: number;
    tableRowClassFunc: (tableItem: GenericRecordTableItem) => string;
    onSort: (c: TableColumn<GenericRecord>) => void;
    sortColumn: TableColumn<GenericRecord>;
    containerClassName?: string;
    product: Product;
    canWrite: boolean;
    onPayments: (tableItem: GenericRecordTableItem) => void;
    focusIndex: number;
    onSetFocus: (v: number) => void;
    itemActions: RecordActions;
    view: keyof IViewsKeys;
    tableConfigFooters: (
        tableItems: TableItem<GenericRecord>[],
        tableSum: TableSum,
        saldoHeader?: number
    ) => TableConfigFooters;
    columnConfig: GenericRecordColumns;
    tableSumOverride?: (vs: GenericRecordTableItem[], skr: Base.CompanySKR) => TableSum;
    extraHiddenColumns?: string[];
    selectedPeriodEditBound: number;
    contextMenu?: React.ReactElement;
}

interface RecordActions {
    handleEditItem: (tableItem: TableItem<GenericRecord>, e?: React.SyntheticEvent) => void;
    handleCopyItem: (tableItem: TableItem<GenericRecord>, e?: React.SyntheticEvent) => void;
    handleDeleteItems: (tableItem: TableItem<GenericRecord>[], e?: React.SyntheticEvent) => void;
    handleCancelItems: (tableItem: TableItem<GenericRecord>[], e?: React.SyntheticEvent) => void;
    handleUpdateItems: (tableItem: TableItem<GenericRecord>[], e?: React.SyntheticEvent) => void;
    handleAvisTableItems: (tableItem: TableItem<GenericRecord>[], avis: boolean, e?: React.SyntheticEvent) => void;
    handleColorTableItems: (tableItem: TableItem<GenericRecord>[], color: string) => void;
    handleBulkEditItem: (tableItem: TableItem<GenericRecord>[], e?: React.SyntheticEvent) => void;
}

const retrieveColumnWidths = (view: keyof IViewsKeys): Map<string, number> => {
    const savedStr = localStorage.getItem(`column_widths:${view}`) || "[]";
    const data = JSON.parse(savedStr);
    return new Map(data);
};
const persistColumnWidths = (view: keyof IViewsKeys, data: Map<string, number>) => {
    const key = `column_widths:${view}`;
    const dataStr = JSON.stringify(Array.from(data.entries()));
    localStorage.setItem(key, dataStr);
};

export const RecordsTableBlock: React.FC<Props> = props => {
    const { view, extraHiddenColumns, product, tableItems, sortColumn, columnConfig } = props;
    const payments = React.useContext(PaymentsContext);
    const { companyGQL, isLoaded, yearConfig } = React.useContext(CompanyContext);
    const skr = yearConfig?.skr;
    const { disabledColumns } = React.useContext(UserSettingsContext);
    const subjectRef = React.useRef(new Subject<Map<string, number>>());
    const [menu, setMenu] = React.useState<TableStatelessContextMenu<GenericRecord>>();
    const [columnWidths, setColumnWidths] = React.useState(retrieveColumnWidths(view));
    const [scrollTop, setScrollTop] = React.useState(0);
    const [scrollLeft, setScrollLeft] = React.useState(0);
    const [tableColumns, setTableColumns] = React.useState<TableColumn<GenericRecord>[]>([]);

    const onColumnResize = (column: TableColumn<GenericRecord>) => {
        const cc = tableColumns.find(col => col.key === column.key);
        if (cc) {
            columnWidths.set(column.key, column.width);
            cc.width = column.width;
            subjectRef.current.next(new Map(columnWidths));
        }
    };

    const hiddenColumns = React.useMemo(
        () => [...(disabledColumns.get(view) || []), ...(extraHiddenColumns || [])],
        [extraHiddenColumns, disabledColumns, view]
    );
    const footers = React.useMemo(() => {
        const tableSumCounter = props.tableSumOverride || getTableSum;
        const tableSum = tableSumCounter(tableItems, skr as Base.CompanySKR);
        const footersFn = props.tableConfigFooters || (() => ({}));
        return footersFn(tableItems, tableSum, props.tableSaldoHeader);
    }, [props.tableSumOverride, props.tableConfigFooters, tableItems, skr, props.tableSaldoHeader]);

    React.useEffect(() => {
        const subscription = subjectRef.current.pipe(debounceTime(100)).subscribe(value => {
            setColumnWidths(value);
            persistColumnWidths(view, value);
        });
        return () => subscription.unsubscribe();
    }, [view]);

    React.useEffect(() => {
        if (!companyGQL) {
            return;
        }
        const columns = RecordColumnsEnriched.from(companyGQL, columnConfig)
            .withFooters(footers)
            .withHiddenColumns(hiddenColumns)
            .withCustomWidth(columnWidths)
            .withSortColumn(sortColumn)
            .getProcessor()
            .getColumnConfig();

        setTableColumns(columns);
    }, [columnWidths, columnConfig, companyGQL, payments, tableItems, sortColumn, hiddenColumns, footers]);
    const onHide = React.useCallback(() => setMenu(null), []);
    return (
        <React.Fragment>
            <Spin spinning={!isLoaded}>
                <TableStateless<GenericRecord>
                    tableClassName="AbstractBillTableView-table table"
                    containerHeight={props.tableHeight}
                    items={tableItems}
                    selectable
                    scrollTop={scrollTop}
                    onChangeScrollTop={setScrollTop}
                    scrollLeft={scrollLeft}
                    onChangeScrollLeft={setScrollLeft}
                    onColumnResize={onColumnResize}
                    onChangeContextMenu={setMenu}
                    ref={props.tableRef}
                    columns={tableColumns}
                    containerClassName={props.containerClassName}
                    rowClassName={props.tableRowClassFunc}
                    saldoHeader={props.tableSaldoHeader}
                    focusIndex={props.focusIndex}
                    onSetFocus={props.onSetFocus}
                    onEnterItem={props.itemActions.handleEditItem}
                    onCopyItem={props.itemActions.handleCopyItem}
                    onUpdateItems={props.itemActions.handleUpdateItems}
                    onSort={props.onSort}
                />
            </Spin>
            <TableContextMenuPortal
                top={menu?.top || 0}
                left={menu?.left || 0}
                visible={!!menu}
                onHide={onHide}
                targetItems={menu?.targetItems || []}
            >
                {props.contextMenu || (
                    <TableContextMenu
                        canWrite={props.canWrite}
                        selectedPeriodEditBound={props.selectedPeriodEditBound}
                        product={product}
                        onEditItem={(v, e) => {
                            props.itemActions.handleEditItem(v, e);
                            onHide();
                        }}
                        onCopyItem={(v, e) => {
                            props.itemActions.handleCopyItem(v, e);
                            onHide();
                        }}
                        onDeleteItems={(v, e) => {
                            props.itemActions.handleDeleteItems(v, e);
                            onHide();
                        }}
                        onCancelItems={(v, e) => {
                            props.itemActions.handleCancelItems(v, e);
                            onHide();
                        }}
                        onAvis={(v, avis) => {
                            props.itemActions.handleAvisTableItems(v, avis);
                            onHide();
                        }}
                        onColor={(v, c) => {
                            props.itemActions.handleColorTableItems(v, c);
                            onHide();
                        }}
                        onBulkEdit={(v, c) => {
                            props.itemActions.handleBulkEditItem(v, c);
                            onHide();
                        }}
                        onPayments={props.onPayments}
                    />
                )}
            </TableContextMenuPortal>
        </React.Fragment>
    );
};
