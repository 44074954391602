import React, { FC, useContext, useState } from "react";
import { DocInput } from "../DocInput";
import { PdfToolsContext, PdfToolsControlContext } from "../../context";
import { OcrDocument } from "../../types";

import styles from "./ResultColumnFrame.module.scss";
import { getFilename } from "@pdf-tools/context/helpers/helpersPdf";

interface Props {
    showDeletedPages: boolean;
}

export const ResultColumnFrame: FC<Props> = ({ showDeletedPages }) => {
    const { documents, viewDocuments, files, activePageId, pages } = useContext(PdfToolsContext);
    const actions = useContext(PdfToolsControlContext);

    const [docEditing, setDocEditing] = useState<string | null>(null);

    const onStartEditDoc = (doc: OcrDocument) => {
        if (doc.pageIds.length && !doc.pageIds.includes(activePageId)) {
            actions.setActivePageId(doc.pageIds[0]);
        }
        setDocEditing(docEditing === doc.id ? null : doc.id);
    };

    const onFinishEditDoc = (doc: OcrDocument, newName: string, oldName: string) => {
        if (docEditing === doc.id) {
            setDocEditing(null);
            if (newName !== oldName) {
                actions.renameDocument(doc, newName);
            }
        }
    };

    const handleSelectDocument = (document: OcrDocument) => {
        if (docEditing !== null) {
            setDocEditing(document.id);
        }
        if (document.pageIds.length && !document.pageIds.includes(activePageId)) {
            if (showDeletedPages) {
                actions.setActivePageId(document.pageIds[0]);
            } else {
                const viewDoc = viewDocuments.find(doc => doc.id === document.id);
                const newActiveId = viewDoc.pages.find(page => !page.deleted);
                if (newActiveId) {
                    actions.setActivePageId(newActiveId.id);
                }
            }
        }
    };

    const onFinishAndSelectNext = (document: OcrDocument, newName: string, oldName: string) => {
        onFinishEditDoc(document, newName, oldName);
        const currentDocIdx = documents.findIndex(doc => doc.id === document.id);
        const nextVisibleDoc = documents.find((doc, docIdx) => docIdx > currentDocIdx && viewDocuments[docIdx].visible);
        if (nextVisibleDoc) {
            onStartEditDoc(nextVisibleDoc);
        }
    };

    return (
        <div className={styles.resultColumnWrapper}>
            {documents.map((doc, idx) => {
                if (!viewDocuments[idx].visible) {
                    return null;
                }
                const visualIndex = idx + 1;
                const docName = doc.name || `${getFilename({ docId: doc.id, files, documents, pages })}`;
                return (
                    <DocInput
                        key={doc.id}
                        visualIndex={visualIndex}
                        selected={doc.pageIds.includes(activePageId)}
                        editing={docEditing === doc.id}
                        name={docName}
                        onSelectDocument={() => handleSelectDocument(doc)}
                        onStartEdit={() => onStartEditDoc(doc)}
                        onFinishEdit={newName => onFinishEditDoc(doc, newName, docName)}
                        onFinishAndSelectNext={newName => onFinishAndSelectNext(doc, newName, docName)}
                        onDownload={() => actions.handleDownload(doc.id)}
                        onExportToDms={() => actions.exportFiles(doc.id)}
                    />
                );
            })}
        </div>
    );
};
