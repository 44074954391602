import React, { FC } from "react";
import { ERProvider } from "../erfassung/ERProvider";
import { ERAnzProvider } from "../erfassung/ERAnzProvider";
import { TemplatesViewContent } from "@app/views/productSharedComponents/templates/TemplatesViewContent";

export const ERTemplatesView: FC<{ isAZ?: boolean }> = ({ isAZ }) => {
    return isAZ ? (
        <ERAnzProvider>
            <TemplatesViewContent />
        </ERAnzProvider>
    ) : (
        <ERProvider>
            <TemplatesViewContent />
        </ERProvider>
    );
};
