import React, { FC, useContext, useRef, useState } from "react";
import { Alert, Avatar, Button, Flex, Form, Input, message, Space } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import { Link, useNavigate } from "react-router-dom";
import { GoogleOutlined, MailOutlined } from "@ant-design/icons";
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";

import { AppRoutes } from "scripts/routing/routeConstants";
import { FirebaseAuthErrorCodes } from "scripts/api/firebase/firebaseErrorMessages";
import { auth, FirebaseFunctions, functions } from "scripts/api/firebase/firebase";
import { UserContext } from "scripts/context/UserProvider";
import { logger } from "scripts/infrastructure/logger";
import { useLocationQuery } from "scripts/infrastructure/hooks";
import { validation } from "scripts/infrastructure/helpers/validation";
import datevLogo from "../../../assets/datev.png";
import elsterLogo from "../../../assets/elster.png";

export interface LoginFormValues {
    email: string;
    password: string;
}

interface IProps {
    show2fa: (values: LoginFormValues) => void;
}

export const LoginForm: FC<IProps> = ({ show2fa }) => {
    const intl = useIntl();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { isLoading: isUserLoading } = useContext(UserContext);
    const query = useLocationQuery();
    const redirectRef = useRef(query.get("redirect"));
    const navigate = useNavigate();

    const handleGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then(result => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log({ token, user });
        });
    };

    const handleSubmit = React.useCallback(
        async (values: LoginFormValues) => {
            setLoading(true);
            const authorize = httpsCallable(functions, FirebaseFunctions.authorize);
            try {
                const res: HttpsCallableResult<any> = await authorize(values);
                if (res.data?.use2fa) {
                    show2fa(values);
                } else {
                    await signInWithEmailAndPassword(auth, values.email, values.password);
                    const pushRedirectOrHome = redirectRef.current || AppRoutes.home;
                    setTimeout(() => navigate(pushRedirectOrHome), 250);
                }
            } catch (e) {
                if (e.message === FirebaseAuthErrorCodes.INVALID_EMAIL) {
                    setShowError(true);
                } else {
                    logger.crit(e, { values });
                    message.error(intl.formatMessage({ id: "app.global.error" }));
                }
                setLoading(false);
            }
        },
        [intl, navigate, show2fa]
    );

    const emailValidation = React.useMemo(() => [validation.required(intl), validation.email(intl)], [intl]);
    const passwordValidation = React.useMemo(() => [validation.required(intl)], [intl]);

    return (
        <>
            {showError && <Alert type="error" message={intl.formatMessage({ id: "app.login.error.fail" })} />}
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item name="email" rules={emailValidation}>
                    <Input
                        suffix={<MailOutlined />}
                        size="large"
                        placeholder={intl.formatMessage({ id: "app.global.email" })}
                        onChange={() => setShowError(false)}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={passwordValidation}
                    extra={
                        <Link to={AppRoutes.authPasswordReset}>
                            <FormattedMessage id="app.reset_password.caption" />
                        </Link>
                    }
                >
                    <Input.Password
                        size="large"
                        placeholder={intl.formatMessage({ id: "app.global.password" })}
                        onChange={() => setShowError(false)}
                    />
                </Form.Item>
                <Form.Item>
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            disabled={loading || isUserLoading}
                            loading={loading || isUserLoading}
                            data-testid="submit-btn"
                            block
                        >
                            <FormattedMessage id="app.button.login" tagName="span" />
                        </Button>
                    </div>
                </Form.Item>
            </Form>
            <Flex gap={"middle"} justify={"center"} style={{ margin: 8 }}>
                <FormattedMessage id="app.global.do_not_have_account" />
                &nbsp;&nbsp;
                <Link to={AppRoutes.authRegister} style={{ textDecoration: "underline" }}>
                    <FormattedMessage id="app.global.register_now" />
                </Link>
            </Flex>

            <hr />
            <Flex gap={"middle"} justify={"center"} style={{ marginTop: 16 }}>
                <Button onClick={handleGoogle} icon={<GoogleOutlined />}>
                    Google
                </Button>
            </Flex>
            <div style={{ position: "fixed", bottom: -150, left: "50%", transform: "translateX(-50%)" }}>
                <Space size={48} align="start">
                    <Flex vertical align="center">
                        <Avatar src={elsterLogo} size={100} alt={"ELSTER UStVA"} />
                        {/* <span style={{ textAlign: "center", color: "white" }}>ELSTER UStVA</span> */}
                    </Flex>
                    <Flex vertical align="center">
                        <Link to="https://www.datev.de/web/de/marktplatz/datev-schnittstellen-anbieter/#anchor_st_fold_out_section__datev_68">
                            <Avatar src={datevLogo} size={100} alt={"DATEV Buchungsdatenservice"} />
                        </Link>
                        {/* <span style={{ textAlign: "center", color: "white" }}>DATEV Buchungsdatenservice</span> */}
                    </Flex>
                </Space>
            </div>
        </>
    );
};
