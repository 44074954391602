import React from "react";
import {
    TInvoiceFormLineItem,
    InvoiceColumns,
    InvoiceFormInputTranslate,
    TSelectEditorValue,
    NavigationKeys,
    TInvoiceFormColumnsConfig,
    getLineItemNetto,
} from "@inv/types";
import styles from "@inv/modules/InvoiceTableModule/styles/InvoiceTable.module.scss";
import { calculateFormattedNum } from "@inv/scripts/utils/utils";
import { ColDef, ICellRendererParams, SuppressKeyboardEventParams, ValueGetterParams } from "ag-grid-community";
import { Utils } from "@binale-tech/shared";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { AbstractTableColumns } from "@app/components/shared/AgGridTable/columns/AbstractTableColumns";
import { decimalFormatter, formatDefault } from "@dms/scripts/helpers";

export class InvoiceFormColumnsConfig extends AbstractTableColumns {
    private static get taxOption(): TSelectEditorValue[] {
        return [
            { label: "19 %", value: 19 },
            { label: "7 %", value: 7 },
            { label: "0 %", value: 0 },
        ];
    }

    static get unitOption(): TSelectEditorValue[] {
        return [
            {
                label: this.intl?.formatMessage({ id: "app.dms.empty" }),
                value: "EMPTY",
            },
            {
                label: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.PIECE }),
                value: "PIECE",
            },
            {
                label: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.HOUR }),
                value: "HOUR",
            },
        ];
    }

    private static onRemoveRow({ api, node }: ICellRendererParams<TInvoiceFormLineItem>): void {
        api.forEachNode(row => {
            if (node.id === row.id) {
                api.applyTransaction({ remove: [row.data] });
            }
        });
    }

    private static get dragColumn(): Readonly<ColDef> {
        return {
            headerName: "",
            field: InvoiceColumns.DRAG,
            pinned: "left",
            editable: false,
            resizable: false,
            width: 52,
            rowDrag: true,
            cellClass: [styles.cell],
        };
    }

    private static get positionColumn(): Readonly<ColDef> {
        return {
            ...this.rowNumberColumn,
            field: InvoiceColumns.POSITION,
            cellClass: [styles.cell, styles.cellCentred],
        };
    }

    private static get productServiceColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.PRODUCTS_SERVICES }),
            field: InvoiceColumns.PRODUCTS_SERVICES,
            cellEditor: "nameEditor",
            wrapText: true,
            autoHeight: true,
            suppressKeyboardEvent: ({ editing, event }: SuppressKeyboardEventParams) =>
                editing && event.key === NavigationKeys.ENTER,
            flex: 1,
            cellStyle: { justifyContent: "flex-start", whiteSpace: "pre-wrap", lineHeight: 1.4 },
            cellClassRules: {
                [styles.cell]: () => true,
                [styles.cellNoValid]: params => !params.value,
            },
        };
    }

    private static get unitColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.UNIT }),
            field: InvoiceColumns.UNIT,
            cellEditor: "selectEditor",
            cellEditorPopup: false,
            cellEditorParams: {
                values: this.unitOption,
                openSelect: true,
            },
            suppressKeyboardEvent: ({ editing, event }: SuppressKeyboardEventParams) =>
                editing && event.key === NavigationKeys.ENTER,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceFormLineItem>) =>
                this.unitOption.find(option => option.value === data.unit)?.value,
            cellRenderer: ({ value }: ICellRendererParams<TInvoiceFormLineItem>) => {
                switch (value) {
                    case "PIECE":
                        return this.intl?.formatMessage({ id: InvoiceFormInputTranslate.PIECE });
                    case "HOUR":
                        return this.intl?.formatMessage({ id: InvoiceFormInputTranslate.HOUR });
                    default:
                        return "";
                }
            },
            cellClass: [styles.cell, styles.cellStarted],
        };
    }

    private static get quantityColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.QUANTITY }),
            field: InvoiceColumns.QUANTITY,
            cellEditor: "quantityEditor",
            suppressKeyboardEvent: ({ editing, event }: SuppressKeyboardEventParams) =>
                editing && event.key === NavigationKeys.ENTER,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceFormLineItem>) => formatDefault(data.quantity * 100),
            cellRenderer: ({ value }: ICellRendererParams<TInvoiceFormLineItem>) => value,
        };
    }

    private static get priceColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.PRICE }),
            field: InvoiceColumns.PRICE,
            cellEditor: "priceEditor",
            suppressKeyboardEvent: ({ editing, event }: SuppressKeyboardEventParams) =>
                editing && event.key === NavigationKeys.ENTER,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceFormLineItem>) => formatDefault(data.price),
            cellRenderer: ({ value }: ICellRendererParams<TInvoiceFormLineItem>) => value,
        };
    }

    private static get taxColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.TAX }),
            field: InvoiceColumns.TAX,
            cellEditorPopup: false,
            cellEditor: "selectEditor",
            cellEditorParams: {
                values: this.taxOption,
                openSelect: true,
            },
            suppressKeyboardEvent: ({ editing, event }: SuppressKeyboardEventParams) =>
                editing && event.key === NavigationKeys.ENTER,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceFormLineItem>) =>
                this.taxOption.find(opt => opt.value === data.tax).label,
        };
    }

    private static get discountColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: InvoiceFormInputTranslate.DISCOUNT }),
            field: InvoiceColumns.DISCOUNT,
            width: 100,
            cellEditor: "discountEditor",
            cellEditorParams: {
                max: 100,
            },
            suppressKeyboardEvent: ({ editing }: SuppressKeyboardEventParams) => editing,
            valueGetter: ({ data }: ValueGetterParams<TInvoiceFormLineItem>) => {
                const { price, discount } = data;
                if (!discount && price === discount) {
                    return decimalFormatter(0);
                }

                const discountValue = (price - discount) / price;
                return calculateFormattedNum(discountValue);
            },
            cellRenderer: ({ value }: ICellRendererParams<TInvoiceFormLineItem>) => {
                return `${value} %`;
            },
        };
    }

    private static get totalNettoColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: "app.fields.netto" }),
            field: InvoiceColumns.TOTAL_NETTO,
            editable: false,
            resizable: false,
            width: 100,
            cellRenderer: ({ data }: ICellRendererParams<TInvoiceFormLineItem>) => {
                return formatDefault(getLineItemNetto(data));
            },
            cellClass: [styles.cell, styles.cellEnd, styles.cellBold],
        };
    }

    private static get actionColumn(): Readonly<ColDef> {
        return {
            headerComponent: "settingsColumns",
            field: InvoiceColumns.ACTIONS,
            width: 60,
            pinned: "right",
            resizable: false,
            editable: false,
            cellRenderer: (params: ICellRendererParams<TInvoiceFormLineItem>) => {
                const renderRows = params.api.getRenderedNodes().length;
                return (
                    <Button
                        icon={<DeleteOutlined />}
                        disabled={renderRows <= 1}
                        type={"text"}
                        onClick={() => this.onRemoveRow(params)}
                    />
                );
            },
            cellClass: [styles.cell, styles.cellCentred],
        };
    }

    static get InitColumnConfig(): TInvoiceFormColumnsConfig {
        return {
            [InvoiceColumns.DRAG]: true,
            [InvoiceColumns.POSITION]: true,
            [InvoiceColumns.PRODUCTS_SERVICES]: true,
            [InvoiceColumns.UNIT]: true,
            [InvoiceColumns.QUANTITY]: true,
            [InvoiceColumns.PRICE]: true,
            [InvoiceColumns.DISCOUNT]: true,
            [InvoiceColumns.TOTAL_NETTO]: true,
            [InvoiceColumns.TAX]: true,
            [InvoiceColumns.ACTIONS]: true,
        };
    }

    static get RequiredTableCols(): string[] {
        return [InvoiceColumns.DRAG, InvoiceColumns.PRODUCTS_SERVICES, InvoiceColumns.ACTIONS];
    }

    private static getColumn = (colKey: InvoiceColumns): Readonly<ColDef> => {
        switch (colKey) {
            case InvoiceColumns.DRAG:
                return { ...this.dragColumn };
            case InvoiceColumns.POSITION:
                return { ...this.positionColumn };
            case InvoiceColumns.PRODUCTS_SERVICES:
                return { ...this.productServiceColumn };
            case InvoiceColumns.UNIT:
                return { ...this.unitColumn };
            case InvoiceColumns.QUANTITY:
                return { ...this.quantityColumn };
            case InvoiceColumns.PRICE:
                return { ...this.priceColumn };
            case InvoiceColumns.DISCOUNT:
                return { ...this.discountColumn };
            case InvoiceColumns.TAX:
                return { ...this.taxColumn };
            case InvoiceColumns.TOTAL_NETTO:
                return { ...this.totalNettoColumn };
            case InvoiceColumns.ACTIONS:
                return { ...this.actionColumn };
        }
    };

    public static getColumns = (activeKeys?: string[]): ColDef[] =>
        activeKeys.map(item => this.getColumn(item as InvoiceColumns));
}
