import { Form, RefSelectProps, Typography } from "antd";
import React, { forwardRef, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { CountryCodeInput } from "@dms/components/DocumentForm/DocumentInputs/CountryCodeInput";
import { ICountriesOption } from "../../../../appearance/views/productContacts/components/CountriesCombobox";
import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";

const { Text } = Typography;

interface IProps {
    options: ICountriesOption[];
    disabled?: boolean;
}

export const SingleCountryCodeField = forwardRef<RefSelectProps, IProps>(function SingleCountryCodeField(
    { options },
    ref
) {
    const { isReadOnly } = useContext(SingleFormContext);
    return (
        <Form.Item
            label={
                <Text strong ellipsis>
                    <FormattedMessage id="app.fields.euroCountryCode" />
                </Text>
            }
            name="landCode"
            style={{ marginBottom: 0 }}
        >
            <CountryCodeInput options={options} ref={ref} disabled={isReadOnly} />
        </Form.Item>
    );
});

export const GroupCountryCodeField = forwardRef<RefSelectProps, IProps>(function GroupCountryCodeField(
    { options, disabled },
    ref
) {
    const { notEqualField, requiredFields } = useContext(GroupFormContext);

    const placeholder = notEqualField?.includes("discountDays") ? "Diverse" : undefined;

    return (
        <Form.Item
            label={
                <Text strong ellipsis>
                    <FormattedMessage id="app.fields.euroCountryCode" />
                </Text>
            }
            name="landCode"
            rules={[{ required: requiredFields?.includes("landCode"), message: "" }]}
            style={{ marginBottom: 0 }}
        >
            <CountryCodeInput options={options} disabled={disabled} placeholder={placeholder} ref={ref} />
        </Form.Item>
    );
});

export const DocumentCountryCodeField = forwardRef<RefSelectProps, IProps>(function DocumentCountryCodeField(
    { options, disabled },
    ref
) {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return isGroupEditor ? (
        <GroupCountryCodeField options={options} disabled={disabled} ref={ref} />
    ) : (
        <SingleCountryCodeField options={options} ref={ref} />
    );
});
