import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Flex, Form, Spin, Tooltip } from "antd";
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    ExclamationCircleTwoTone,
    LoadingOutlined,
} from "@ant-design/icons";
import { InvoiceFormInputTranslate, TInvoicesValues } from "@inv/types";

interface IProps {
    success: boolean;
    isLoading: boolean;
    handleCancel: () => void;
}

export const ControlBlock: FC<IProps> = ({ success, isLoading, handleCancel }) => {
    const form = Form.useFormInstance<TInvoicesValues>();
    const lineItems = Form.useWatch(["lineItems"], form);
    const isSaveEnabled = useMemo(() => {
        if (lineItems?.length >= 1) {
            return lineItems.every(item => item.productsServices && item.price);
        }
        return false;
    }, [lineItems]);

    return (
        <Flex justify={"end"} gap={10} style={{ width: "100%", padding: 10 }}>
            {success && !isLoading && <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />}
            {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
            <Button onClick={handleCancel}>
                <FormattedMessage id={InvoiceFormInputTranslate.CANCEL} />
            </Button>
            <Button type="primary" htmlType="submit" disabled={!isSaveEnabled}>
                <FormattedMessage id={InvoiceFormInputTranslate.SAVE} />
            </Button>
            <Tooltip title={isSaveEnabled ? undefined : <FormattedMessage id={InvoiceFormInputTranslate.SAVE_HINT} />}>
                <Button
                    shape={"circle"}
                    icon={<ExclamationCircleTwoTone />}
                    type={"text"}
                    style={isSaveEnabled ? { opacity: 0, cursor: "default" } : undefined}
                />
            </Tooltip>
        </Flex>
    );
};
