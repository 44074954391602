import ProgramSettings from "../models/ProgramSettings";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { IViewsKeys } from "../models/User";
import { UserContext } from "./UserProvider";

export const UserSettingsContext = React.createContext({
    disabledColumns: new Map<keyof IViewsKeys, string[]>(),
    onChangeDisabledColumns: (view: keyof IViewsKeys, disabledColumns: string[]) => {},
});

export const UserSettingsProvider: React.FC<React.PropsWithChildren> = props => {
    const [disabledColumns, setDisabledColumns] = useState<Map<keyof IViewsKeys, string[]>>(new Map());
    const [documentVisibility, setDocumentVisibility] = useState(true);

    const { fireUser, selectedCompany } = useContext(UserContext);
    const userData = useMemo(() => {
        if (!fireUser || !selectedCompany) {
            return null;
        }

        return {
            userId: fireUser.uid,
            companyId: selectedCompany,
        };
    }, [fireUser, selectedCompany]);

    useEffect(() => {
        const handleVisibilityChange = function () {
            setDocumentVisibility(document.visibilityState === "visible");
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        if (!userData) {
            return;
        }

        if (!documentVisibility) {
            return;
        }

        ProgramSettings.getColumns(userData).then(setDisabledColumns);
    }, [userData, documentVisibility]);

    const settingsValue: React.ContextType<typeof UserSettingsContext> = useMemo(
        () => ({
            disabledColumns,
            onChangeDisabledColumns: (view: keyof IViewsKeys, columns: string[]) => {
                disabledColumns.set(view, columns);
                setDisabledColumns(new Map(disabledColumns));
                ProgramSettings.updateColumns(userData, view, columns).then(r => r);
            },
        }),
        [disabledColumns, userData]
    );

    return <UserSettingsContext.Provider value={settingsValue}>{props.children}</UserSettingsContext.Provider>;
};
