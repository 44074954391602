import Payment from "../../models/Payment";
import dayjs from "dayjs";

import { CsvConverter } from "../CsvConverter";
import { GenericRecord } from "../../models/GenericRecord";
import { TableItem } from "appearance/components/shared/Table/Table";
import { downloadCsv as csvDownload } from "../../infrastructure/downloader";

interface IExporter<E> {
    getFilename(): string;

    getColumns(): string[];

    convert(entities: E[], kontoExt: number, payments?: Map<string, Payment[]>): string[][];

    download(entities: E[], kontoExt: number, payments: Map<string, Payment[]>, filenameSuffix?: string): void;
}

export abstract class AbstractCsvTableExporter implements IExporter<TableItem<GenericRecord>> {
    getFilename(): string {
        return "CsvExport";
    }

    abstract getColumns(): string[];

    abstract convert(
        entity: TableItem<GenericRecord>[],
        kontoExt: number,
        payments?: Map<string, Payment[]>
    ): string[][];

    public download(
        tableItems: TableItem<GenericRecord>[],
        kontoExt: number,
        payments: Map<string, Payment[]>,
        filenameSuffix?: string
    ) {
        const data = this.convert(tableItems, kontoExt);
        const name =
            [dayjs().format("YYYY.MM.DD"), this.getFilename(), filenameSuffix].filter(Boolean).join("-") + ".csv";
        csvDownload(CsvConverter.getCsv([this.getColumns(), ...data]), name);
    }
}
