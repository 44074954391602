import React, { FC, memo, useContext } from "react";
import { InvoiceForm } from "@inv/components/InvoiceForm";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { useParams } from "react-router-dom";
import { TInvoicesValues } from "@inv/types";
import { InvoiceFormProvider } from "./context/InvoiceFormProvider";

const Module: FC = () => {
    const { id } = useParams();
    const { invoicesMap } = useContext(InvoicesDataContext);

    const initialValue = invoicesMap.get(id);

    if (!initialValue) {
        return null;
    }

    return (
        <InvoiceFormProvider>
            <InvoiceForm initialValues={initialValue as TInvoicesValues} />
        </InvoiceFormProvider>
    );
};

export default memo(Module);
