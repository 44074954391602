import React, { createContext, FC, ReactNode } from "react";
import { BanksApi } from "@banks/scripts/api";
import { GQL } from "@binale-tech/shared";

type TActionValue = {
    updateTransaction: (
        arg: GQL.ITransactionUpdateInput & {
            contact: {
                name: string;
                id: string;
            };
        }
    ) => Promise<true | undefined>;
};

export const TransactionEditControlContext = createContext<TActionValue>({
    updateTransaction: () => Promise.reject(),
});

type TProps = {
    children?: ReactNode;
};

export const EditBankContextProvider: FC<TProps> = ({ children }) => {
    const actions: TActionValue = {
        updateTransaction: async (
            arg: GQL.ITransactionUpdateInput & {
                contact: {
                    name: string;
                    id: string;
                };
            }
        ): Promise<true> => {
            const { contact, ...data } = arg;

            if (arg.contact) {
                data.contactId = contact.id;
            }
            return (await BanksApi.transactionUpdate([data])) as unknown as true | undefined;
        },
    };

    return <TransactionEditControlContext.Provider value={actions}>{children}</TransactionEditControlContext.Provider>;
};
