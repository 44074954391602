import { IMessagesType, messagesDe } from "./translations/de";
import { messagesEn } from "./translations/en";
import { messagesRu } from "./translations/ru";

export enum Lang {
    En = "en",
    De = "de",
    Ru = "ru",
}

export interface ILocaleMessages {
    messages: IMessagesType;
    locale: Lang;
}

const MessagesDe: ILocaleMessages = {
    locale: Lang.De,
    messages: messagesDe,
};
const MessagesRu: ILocaleMessages = {
    locale: Lang.Ru,
    messages: messagesRu,
};
const MessagesEn: ILocaleMessages = {
    locale: Lang.En,
    messages: messagesEn,
};

export const getLocaleMessages = (lang: Lang) => {
    let messages: ILocaleMessages = MessagesDe;
    switch (lang) {
        case Lang.En:
            messages = MessagesEn;
            break;
        case Lang.Ru:
            messages = MessagesRu;
            break;
    }
    return messages;
};
