import React from "react";
import { Contacts } from "@binale-tech/shared";
import { DefaultOptionType } from "antd/es/select";
import { RefSelectProps, Select, SelectProps, Typography } from "antd";

export const RegisterTypeCombobox = React.forwardRef<RefSelectProps, SelectProps>(
    function TypeOfRegisterCombobox(props, ref) {
        const dataValue: DefaultOptionType[] = Object.keys(Contacts.ContactConstants.RegisterList).map(
            (key: Contacts.ContactConstants.RegisterEnum) => ({
                label: (
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ marginRight: 16 }}>{Contacts.ContactConstants.RegisterList[key]}</span>
                        <Typography.Text type="secondary" style={{ minWidth: 40, textAlign: "right" }}>
                            {key}
                        </Typography.Text>
                    </div>
                ),
                value: key,
            })
        );

        return (
            <Select
                allowClear
                {...props}
                options={dataValue}
                onChange={(v, o) => {
                    props.onChange(v ?? null, o ?? null);
                }}
                ref={ref}
                getPopupContainer={trigger => trigger.parentNode}
                dropdownRender={menu => <div data-testid="legalInfo-typeOfRegister-dropdown">{menu}</div>}
            />
        );
    }
);
