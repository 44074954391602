import React, { FC, useContext } from "react";
import { Button, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { AppSettingsContext } from "scripts/context/AppSettingsProvider";
import { UserContext } from "scripts/context/UserProvider";
import { Lang } from "scripts/intl";

interface Props {
    onLangChange?: (v: string) => void;
}
const menuItems = [
    { key: Lang.De, label: "DE" },
    { key: Lang.En, label: "EN" },
    { key: Lang.Ru, label: "RU" },
];

export const LangDropdown: FC<Props> = () => {
    const { changeLang, intl } = useContext(AppSettingsContext);
    const user = useContext(UserContext);

    if (user.isAuthenticated) {
        return null;
    }
    return (
        <Dropdown menu={{ onClick: e => changeLang(e.key as Lang), items: menuItems }} trigger={["click"]}>
            <Button className="Header--Button Header--Button__margin">
                {intl.locale.toUpperCase()} <DownOutlined />
            </Button>
        </Dropdown>
    );
};
