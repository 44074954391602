import React, { FC, useCallback, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col, Row } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";

import { ControlButtons } from "../../ControlButtons";
import { OcrPage } from "../../../types";
import { PdfToolsControlContext } from "../../../context";
import { useImageRotation } from "../../../hooks/useImageRotation";

import styles from "../previewBox.module.scss";

type TProps = {
    activePage: OcrPage;
    orderedPages: string[];
    activePageIndex: number;
    zoom: number;
    zoomIn: () => void;
    zoomOut: () => void;
    isDisabled?: boolean;
};

export const PreviewBoxHeader: FC<TProps> = ({
    activePage,
    orderedPages,
    activePageIndex,
    zoom,
    zoomIn,
    zoomOut,
    isDisabled,
}) => {
    const actions = useContext(PdfToolsControlContext);
    const { rotateImage } = useImageRotation([activePage]);

    const handleHide = useCallback(() => {
        activePage?.deleted ? actions.restorePages([activePage.id]) : actions.deletePages([activePage.id]);
    }, [activePage, actions]);

    const handleScroll = useCallback(
        (diff: number) => {
            const nextPageId = orderedPages[activePageIndex + diff];

            if (!nextPageId) {
                return;
            }

            actions.setActivePageId(nextPageId);
        },
        [orderedPages, activePageIndex, actions]
    );
    return (
        <Row justify="space-between" align="middle" className={styles.previewBoxHeader}>
            <Col span={5}>
                <ControlButtons
                    isDisabled={isDisabled}
                    isHidden={activePage.deleted}
                    onHide={handleHide}
                    onRotate={rotateImage}
                />
            </Col>
            <Col span={14}>
                <Row align="middle" justify="center">
                    <Col>
                        <Button
                            icon={<ArrowLeftOutlined />}
                            shape="circle"
                            disabled={activePageIndex <= 0}
                            onClick={() => handleScroll(-1)}
                        />
                    </Col>
                    <Col offset={1} style={{ minWidth: "100px", textAlign: "center" }}>
                        <strong>
                            <FormattedMessage id="app.ocr.page" tagName="strong" /> {activePageIndex + 1}/
                            {orderedPages.length}
                        </strong>
                    </Col>
                    <Col offset={1}>
                        <Button
                            icon={<ArrowRightOutlined />}
                            shape="circle"
                            disabled={activePageIndex >= orderedPages.length - 1}
                            onClick={() => handleScroll(1)}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={5}>
                <Row gutter={4} justify="end">
                    <Col>
                        <Button icon={<ZoomOutOutlined />} shape="circle" disabled={zoom === 100} onClick={zoomOut} />
                    </Col>
                    <Col>
                        <Button icon={<ZoomInOutlined />} shape="circle" onClick={zoomIn} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
