import React from "react";
import { Input, InputProps, InputRef } from "antd";

export interface BaseInputProps extends Omit<InputProps, "onChange" | "value"> {
    onChange?: (val: string) => void;
    value?: string;
}

export const BaseInput = React.forwardRef<InputRef, BaseInputProps>(function BaseInput(props, ref) {
    const { value, onChange, pattern, ...rest } = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!pattern || !e.target.value || new RegExp(pattern, "g").test(e.target.value)) {
            props.onChange && props.onChange(e.target.value);
        }
    };

    return <Input {...rest} ref={ref} value={value || ""} onChange={handleChange} />;
});
