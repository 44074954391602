import React, { FC } from "react";
import styles from "@banks/components/FormFields/FormFields.module.scss";
import { Form, Typography } from "antd";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { AmountInput } from "@app/components/shared/form/baseComponents/AmountInput";
import { FormattedMessage } from "react-intl";

const { Text } = Typography;

export const OpeningBalanceField: FC = () => {
    const form = Form.useFormInstance();
    const code = form.getFieldValue("bankCurrency");
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id={"app.fields.openingBalance"} />
                </Text>
            }
            name={"openingBalance"}
        >
            <AmountInput code={code} />
        </FieldLabel>
    );
};
