import React, { Dispatch, FC, SetStateAction, useContext } from "react";
import { Col, Flex, Input, Row } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { TypeSelectBtn } from "./TypeSelectBtn";
import { DmsAppContext, DmsAppControlContext } from "@dms/types/ContextTypes";

const { Search } = Input;

type TProps = {
    handleTypeSelect: (value: string) => void;
    // searchValue: string | undefined;
    // setSearchValue: Dispatch<SetStateAction<string | undefined>>;
    selectedType: string | undefined;
    setSelectedType: Dispatch<SetStateAction<string>>;
    selectedSubType: string | undefined;
    setSelectedSubType: Dispatch<SetStateAction<string | undefined>>;
};

export const Toolbar: FC<TProps> = ({
    handleTypeSelect,
    selectedType,
    setSelectedType,
    selectedSubType,
    setSelectedSubType,
}) => {
    const { searchValue } = useContext(DmsAppContext);
    const { setSearchValue } = useContext(DmsAppControlContext);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        setSearchValue(value);
    };

    return (
        <Flex
            align={"center"}
            style={{
                width: "100%",
                fontSize: "16px",
            }}
        >
            <TypeSelectBtn
                handleTypeSelect={handleTypeSelect}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                selectedSubType={selectedSubType}
                setSelectedSubType={setSelectedSubType}
            />

            <Row style={{ width: "100%", marginLeft: 10 }} align={"middle"} gutter={20}>
                <Col sm={14} lg={18} xl={20}>
                    <Search style={{ width: "100%" }} onChange={handleSearch} value={searchValue} />
                </Col>
                <Col sm={10} lg={6} xl={4}>
                    <Link to={`/documents`} target="_blank">
                        Binale DMS <ExportOutlined />
                    </Link>
                </Col>
            </Row>
        </Flex>
    );
};
