import React, { useMemo } from "react";
import ReactDatePicker from "react-datepicker";
import { BaseSelectRef } from "rc-select";
import { InputRef, RefSelectProps } from "antd";

export const useFormRefsHtml = () => {
    const REF_rDATE = React.useRef<ReactDatePicker>();
    const REF_rCONTACT = React.useRef<BaseSelectRef>();
    const REF_rCATEGORYCREDITOR = React.useRef<InputRef>();
    const REF_rBELEGFELD1 = React.useRef<InputRef>();
    const REF_rBRUTTO = React.useRef<HTMLInputElement>();
    const REF_rNETTO = React.useRef<HTMLInputElement>();
    const REF_rFALLIGKEIT = React.useRef<any>();
    const REF_rDRAFT = React.useRef<HTMLInputElement>();

    const REF_cASSET = React.useRef<HTMLButtonElement>();
    const REF_cSPLIT = React.useRef<HTMLButtonElement>();
    const REF_cBINDING = React.useRef<HTMLButtonElement>();

    const REF_iBELEGFELD1 = React.useRef<InputRef>();
    const REF_iBELEGFELD2 = React.useRef<InputRef>();
    const REF_iBU = React.useRef<InputRef | RefSelectProps>();
    const REF_iCATEGORYCREDITOR = React.useRef<InputRef>();
    const REF_iBRUTTO = React.useRef<HTMLInputElement>();
    const REF_iTAG = React.useRef<InputRef>();
    const REF_iTEXT = React.useRef<RefSelectProps>();
    const REF_iTEXT2 = React.useRef<RefSelectProps>();

    const REF_cBUCHEN = React.useRef<HTMLButtonElement>();
    const REF_cADD = React.useRef<HTMLButtonElement>();
    const inlineFormRef = React.useRef<HTMLDivElement>();
    return useMemo(
        () => ({
            REF_rDATE,
            REF_rCONTACT,
            REF_rCATEGORYCREDITOR,
            REF_rBELEGFELD1,
            REF_rBRUTTO,
            REF_rNETTO,
            REF_rFALLIGKEIT,
            REF_rDRAFT,
            REF_cASSET,
            REF_cSPLIT,
            REF_cBINDING,
            REF_iBELEGFELD1,
            REF_iBU,
            REF_iCATEGORYCREDITOR,
            REF_iBRUTTO,
            REF_iBELEGFELD2,
            REF_iTAG,
            REF_iTEXT,
            REF_iTEXT2,
            REF_cBUCHEN,
            REF_cADD,
            inlineFormRef,
        }),
        []
    );
};
