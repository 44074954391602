import React, { FC, forwardRef } from "react";
import { StringInput } from "../StringInput/StringInput";
import { type InputProps, InputRef } from "antd";

interface IProps extends Omit<InputProps, "onChange" | "value"> {
    onChange?: (v: string) => void;
    value?: string;
}
export const DocumentNumberInput: FC<IProps> = forwardRef<InputRef, IProps>(({ onChange, ...restProps }, ref) => {
    const handleOnChange = (arg: string) => {
        let val = arg;
        val = val.replace(/[^a-zA-Z0-9\-/$&%+]/g, "");
        onChange?.(val);
    };

    return <StringInput {...restProps} onChange={handleOnChange} />;
});
