import React from "react";
import { Button, Descriptions, Flex, Tooltip } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, ExportOutlined, InfoCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Datev, GQL } from "@binale-tech/shared";

type Props = {
    datevUserInfo: GQL.IDatevUserInfo;
    token?: GQL.IOAuthTokenMeta;
} & React.ComponentProps<typeof Descriptions>;
export const DatevDescriptions: React.FC<Props> = ({ datevUserInfo, token, ...props }) => {
    const rtValidity = Datev.getRefreshTokenValidity(token);
    const atValidity = Datev.getAccessTokenValidity(token);

    return (
        <Descriptions {...props}>
            <Descriptions.Item label="Email">{datevUserInfo.user.email}</Descriptions.Item>
            <Descriptions.Item label="Email verified">
                {datevUserInfo.user.emailVerified ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
            </Descriptions.Item>
            <Descriptions.Item label="Name">{datevUserInfo.user.name}</Descriptions.Item>
            <Descriptions.Item label="Lastname">{datevUserInfo.user.familyName}</Descriptions.Item>
            {token && (
                <>
                    <Descriptions.Item label="Access Token">
                        {atValidity === 0 ? "Abgelaufen" : dayjs.duration(atValidity, "s").humanize()}
                    </Descriptions.Item>
                    <Descriptions.Item label="Refresh Token">
                        <Tooltip
                            title={dayjs((token.issuedAt + token.refreshTokenExpiresIn) * 1000).format(
                                "DD.MM.YYYY HH:mm"
                            )}
                        >
                            <Flex align={"center"}>
                                <span>
                                    {rtValidity === 0 ? "Abgelaufen" : dayjs.duration(rtValidity, "s").humanize()}
                                </span>
                                &nbsp;
                                <InfoCircleOutlined />
                            </Flex>
                        </Tooltip>
                    </Descriptions.Item>
                    <Descriptions.Item label="Verbindet am">
                        {dayjs((token.issuedAt ?? 0) * 1000).format("YYYY.MM.DD HH:mm")}
                    </Descriptions.Item>
                    <Descriptions.Item label="Überprüfen mit Datev">
                        <a href="https://apps.datev.de/tokrevui" target="_blank" rel="noreferrer">
                            <Button type={"link"} icon={<ExportOutlined />} style={{ paddingLeft: 0, marginTop: -5 }}>
                                Verbundene Anwendungen
                            </Button>
                        </a>
                    </Descriptions.Item>
                </>
            )}
        </Descriptions>
    );
};
