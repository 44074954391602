import { FieldLabel } from "@ui-components/FieldLabel";
import styles from "@banks/components/FormFields/FormFields.module.scss";
import React from "react";
import { Typography } from "antd";
import { CurrencyCodeInput } from "@banks/components/FormFields/components/CurrencyCodeInput";
import { FormattedMessage } from "react-intl";

const { Text } = Typography;

export const BankCurrencyField = () => {
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id={"app.fields.currency"} />
                </Text>
            }
            name={"bankCurrency"}
        >
            <CurrencyCodeInput />
        </FieldLabel>
    );
};
