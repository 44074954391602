import React, { FC, useContext } from "react";
import { ParkedDocumentList } from "./components/ParkedDocumentList";
import { PdfToolsContext } from "../../context";
import styles from "./ParkedDocumentContainer.module.scss";

export const ParkedDocumentContainer: FC = () => {
    const { viewParkedDocuments, draggableId } = useContext(PdfToolsContext);
    const isBordered = !viewParkedDocuments.length || !!draggableId;
    return (
        <div
            style={{
                borderColor: isBordered ? "#d9d9d9" : "#fff",
                transition: "border-color .3s ease",
            }}
            className={styles.parkedDocumentListWrapper}
        >
            <ParkedDocumentList />
        </div>
    );
};
